import React, { Component } from 'react';
import OrderManagements from './orderManagements';
import OrderDetailsPage from './orderDetailsPage';
import GiftCardRangePage from './giftCardRangePage';

/** Main class for OrderManagement*/ 
class OrderManagement extends Component {

    render() {

        let OrderManagement = ''
        let pathParam = this.props.location.pathname.split("/").pop()
        if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
            this.props.history.push("/ng-admin");
        }
       
        
        if (this.props.location.state == undefined) {
            OrderManagement = <OrderManagements componentID={this.props} pathParam={pathParam} />
        }
        else if ((this.props.location.state.totalOrders != undefined || this.props.location.state.totalOrders != null) && localStorage.getItem('loggedIn') != null) {
            OrderManagement = <OrderManagements componentID={this.props} totalOrders={this.props.location.state.totalOrders}
                pathParam={pathParam}
            />
        }
        else if ((this.props.location.state.displayOrderInfo != undefined || this.props.location.state.displayOrderInfo != null) && localStorage.getItem('loggedIn') != null) {
            var orderInfo = this.props.location.state.displayOrderInfo.orderId
            OrderManagement = <OrderDetailsPage orderInfo={orderInfo} orderDetailsProps={this.props}
                pathParam={pathParam}
            />
        }
        else if (this.props.location.state.pageType == 'rangePage' && localStorage.getItem('loggedIn') != null) {
            OrderManagement = <GiftCardRangePage orderDetailsProps={this.props.location.state.orderDetails} orderId={this.props.location.state.orderId} pin={this.props.location.state.pin} pageProp={this.props}
                pathParam={pathParam}
            />
        }
        else if (this.props.location.state.pageType == 'orderManagement' && localStorage.getItem('loggedIn') != null) {
            OrderManagement = <GiftCardRangePage orderDetailsProps={this.props.location.state.orderDetails} orderId={this.props.location.state.orderId} pin={this.props.location.state.pin} pageProp={this.props}
                pathParam={pathParam} usedProductQuantity={this.props.location.state.usedProductQuantity} dbExistRangeData={this.props.location.state.dbExistRangeData}
            />
        }
        else {
        return OrderManagement;
        }
        return OrderManagement;

    }
}
export default OrderManagement;
