import React, { Component } from 'react';
import { unLockAccountAction } from '../../../Actions/Admin/customerManagementAction';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { Alert } from 'reactstrap';

/**Class to unlock the locked user when attempted mutiple wrong credentials  */
class UnlockUser extends Component {

    constructor() {
        super();
        this.state = {
            flashMessageError: '',
            flashMessageSuccess: '',
            unlockModal: false,
            data: ''
        }
    }

    /**
     * Function for call lyfe cycel and handel props response
     * @param {object} props get from reducer
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.unlockCustomerResponse) {
            if (nextProps.unlockCustomerResponse.data && nextProps.unlockCustomerResponse.data.data.code == 200) {
                setTimeout(function () {
                    this.props.redirectPage('unlocked');
                }.bind(this), 5000)
            }
        }
        if (nextProps && nextProps.unlockCustomerResponse.data) {
            if (nextProps.unlockCustomerResponse.data.data.hasOwnProperty('status') && (nextProps.unlockCustomerResponse.data.data.status === true)) {
                let domElementPayment = document.getElementById("unlockCustomer");
                domElementPayment.setAttribute("disabled", "disabled")
                domElementPayment.classList.add("disableEvent");
                this.setState({ flashMessageSuccess: nextProps.unlockCustomerResponse.data.data.message });
            } else {
                this.setState({ flashMessageError: nextProps.unlockCustomerResponse.data.data.message });
            }
        }
    }

    /**
     * Function for close modal
     * @param null 
     * @return null
    */
    onCloseModal = () => {
        this.props.closeModal();
    };

    /**
     * Function for unlock account for user
     * @param {object}
     * @returns {object}
    */
    unLockAccount() {
        let userId = { customerId: this.props.customerData.id };
        this.props.unLockAccountAction(userId);
    }

    render() {
        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
        }
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
        }
        return (
            <div>
                <Modal open={this.props.modalOpen} onClose={this.onCloseModal} center classNames={{ overlay: 'addUseroverlay', modal: 'AddUserModelform' }}
                 closeOnOverlayClick={false}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header from-header">
                                <h3>Unlock {this.props.customerData.firstName + ' ' + this.props.customerData.lastName}</h3>
                            </div>
                            {flashSuceessMessageSpan}
                            {flashErrorMessageSpan}
                            <div className="modal-body">
                                <p>Are you sure you want to unlock {this.props.customerData.firstName + ' ' + this.props.customerData.lastName}?</p>
                                <div className="formbtn" align-right>
                                    <button type="button" id="unlockCustomer" className='giftcard_button mgr10' onClick={this.unLockAccount.bind(this)}>Unlock</button>
                                    <button type="button" className='giftcard_button cardnone_button' onClick={this.onCloseModal}>Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        unlockCustomerResponse: state.unlockCustomer
    }
}
export default connect(mapStateToProps, { unLockAccountAction })(UnlockUser);