import React, { Component } from 'react';
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import { addDiscountAction, editDiscountAction, paymentTypeAction, discountListAction } from '../../../Actions/Admin/discountManagementAction';
import { Alert } from 'reactstrap';
import InputMask from 'react-input-mask'
import ReactLoading from 'react-loading'
import * as constant from '../../../Constants/';
import _ from 'lodash';


/**
 * Class for AddEdit Discount for Admin
 */
class AddEditDiscount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            discountObj: {
                paymentType: 'Card',
                amountFrom: '',
                amountTo: '',
                percent: '',
                status: '',
                id: ''
            },
            validTill: new Date(),
            newDiscountDateValid: new Date(),
            paymentTypes: {},
            discountList: [],
            flashMessageError: '',
            flashMessageSuccess: '',
            discountObjValue: {
                paymentType: '',
                amountFrom: '',
                amountTo: '',
                percent: '',
                status: '',
                id: ''
            },
            Loading: false,
            checkPointUpdateState: false,
            validFrom: new Date(),
            newDiscountDateValidFrom: new Date(),
        }
    }

    /**
     * Method for close model
     */
    onCloseModal = () => {
        this.setState({
            flashMessageError: '',
            flashMessageSuccess: '',
            validTill: new Date(),
            validFrom: new Date(),
            newDiscountDateValid: new Date(),
            newDiscountDateValidFrom: new Date()
        })
        this.props.modalClose();
    }

    /**
     * React Built in method 
     * invoked just before component about to mount 
     * 
     */
    componentWillMount() {
        this.props.discountListAction(sessionStorage.userID)
        this.setState({
            discountList: this.props.discountList
        })
        this.props.paymentTypeAction();//API call
    }

    /**
  * Method for set discount details on change
  * @param object e
  * @return object state
  */
    setValue(e) {
        this.setState({
            flashMessageError: ''
        })
        let val = e.target.value;
        let key = e.target.name;
        let valid = {};
        if (val === '') {
            valid[key] = '';
        } else {
            valid[key] = val;
        }
        let discountObj = Object.assign(this.state.discountObj, valid);
        this.setState({ discountObjValue: discountObj });
    }

    /**
     * Method for set discount details on blur
     * @param object e
     * @return object state
     */
    setdiscountObjValue(e) {
        let { discountObjValue } = this.state
        discountObjValue.amountFrom = parseInt(discountObjValue.amountFrom)
        discountObjValue.amountTo = parseInt(discountObjValue.amountTo)
        if (discountObjValue.amountFrom > 0 && discountObjValue.amountTo > 0) {
            if (discountObjValue.amountFrom > discountObjValue.amountTo) {
                this.setState({
                    flashMessageError: 'Not a valid range'
                })
            } else {
                this.setState({ discountObj: discountObjValue });
            }
        }
    }

    /**
     * Method to set the discount status
     * 
     */
    setActiveStatus() {
        this.setState({
            discountObj: {
                status: true
            },
            discountObjValue: {
                status: true
            }
        })
    }

    /**
     * Method to set the discount inactive status
     * 
     */
    setInActiveStatus() {
        this.setState({
            discountObj: {
                status: false
            },
            discountObjValue: {
                status: false
            }
        })
    }

    /**
     * Method to submit the data or save the discount details 
     */
    async discountSubmitHandler() {
        let { discountObj } = this.state
        let { discountList } = this.state

        discountObj["paymentType"] = "Card"
        let newDiscountList = []
        let inactive = false

        let listTest = []

        listTest = discountList.filter(list => {

            return list.status === true && (moment(new Date()).format('MM/DD/YY') <= (moment(list.validTill).format('MM/DD/YY'))) && (discountObj.paymentType.toUpperCase() === list.paymentType.toUpperCase())
        })

        console.log(listTest);

        if (discountObj.id == '') {
            let validTill = ''
            let validFrom = ''
            validTill = moment(this.state.newDiscountDateValid).format('MM/DD/YYYY')
            validFrom = moment(this.state.newDiscountDateValidFrom).format('MM/DD/YYYY')
            let data = {
                "amountFrom": discountObj.amountFrom,
                "amountTo": discountObj.amountTo ? discountObj.amountTo : 0,
                "validTill": validTill,
                "validFrom": validFrom,
                "percent": Number(discountObj.percent),
                "paymentType": discountObj.paymentType,
                "status": discountObj.status == 'active' ? "1" : 0,
                "userId": sessionStorage.userID,
                "loggedInUser": sessionStorage.userID,
                "language": "en"
            }

            let valid = []

            let max = data.amountTo
            // listTest.map(list=>{
            //     if(list.amountTo >= max) {
            //         max = list.amountTo
            //     }
            // })
            debugger;
            listTest.map(list => {
                if (list.amountTo !== 0) {
                    // if(data.amountTo ===0 ) {
                    //     if(data.amountFrom < max) {
                    //         valid.push(false)
                    //     }
                    // }
                    debugger;
                    let result1 = data.amountFrom >= list.amountFrom && data.amountFrom <= list.amountTo
                    let result2 = data.amountTo >= list.amountFrom && data.amountTo <= list.amountTo
                    let result3 = data.amountFrom <= list.amountFrom && data.amountTo >= list.amountTo
                    let result4 = data.validFrom >= moment(list.validFrom).format('MM/DD/YY') && data.validFrom <= moment(list.validTill).format('MM/DD/YY')
                    let result5 = data.validTill >= moment(list.validFrom).format('MM/DD/YY') && data.validTill <= moment(list.validTill).format('MM/DD/YY')
                    let result6 = data.validFrom <= moment(list.validFrom).format('MM/DD/YY') && data.validTill >= moment(list.validTill).format('MM/DD/YY')

                    if (discountObj.status !== "inactive" && (result1 || result2 || result3) && (result4 || result5 || result6)) {
                        valid.push(false)
                    }
                } else if (data.amountTo !== 0) {
                    debugger;
                    let amountTo = Infinity
                    let result1 = data.amountFrom >= list.amountFrom && data.amountFrom <= amountTo
                    let result2 = data.amountTo >= list.amountFrom && data.amountTo <= amountTo
                    let result3 = data.amountFrom <= list.amountFrom && data.amountTo >= amountTo
                    let result4 = data.validFrom >= moment(list.validFrom).format('MM/DD/YY') && data.validFrom <= moment(list.validTill).format('MM/DD/YY')
                    let result5 = data.validTill >= moment(list.validFrom).format('MM/DD/YY') && data.validTill <= moment(list.validTill).format('MM/DD/YY')
                    let result6 = data.validFrom <= moment(list.validFrom).format('MM/DD/YY') && data.validTill >= moment(list.validTill).format('MM/DD/YY')
                    if (discountObj.status !== "inactive" && (result1 || result2 || result3) && (result4 || result5 || result6)) {
                        valid.push(false)
                    }
                } else {
                    valid.push(false)

                }

                return 0
            })
            if (data.amountFrom === 0) {
                this.setState({
                    flashMessageError: "Amount can't be 0"
                })

            } else if (data.amountFrom == '' || isNaN(data.amountFrom)) {
                this.setState({
                    flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
                })
            } else if (data.percent < 1) {
                this.setState({
                    flashMessageError: 'Discount should be between 1-99 %'
                })
            } else if (valid.includes(false)) {
                this.setState({
                    flashMessageError: 'Not a valid range'
                })
                return 0
            } else if (data.amountTo != 0 && data.amountFrom > data.amountTo) {
                this.setState({
                    flashMessageError: 'Not a valid range'
                })
                return 0
            }
            else if (this.compareDate(data.validFrom, data.validTill)) {
                this.setState({
                    flashMessageError: 'Valid From Date should be less than or equal to the Valid Till Date.'
                })
            }
            else {
                this.setState({
                    Loading: true,
                    checkPointUpdateState: true,
                })

                await this.props.addDiscountAction(data) //API call 
            }
        } else if (discountObj.id != '') {
            var validTill = moment(this.state.validTill).format('MM/DD/YYYY')
            var validFrom = moment(this.state.validFrom).format('MM/DD/YYYY')
            let data = {
                "id": discountObj.id,
                "amountFrom": discountObj.amountFrom,
                "amountTo": discountObj.amountTo ? discountObj.amountTo : 0,
                "validTill": validTill,
                "validFrom": validFrom,
                "percent": Number(discountObj.percent),
                "paymentType": discountObj.paymentType,
                "status": discountObj.status == 'active' ? "1" : 0,
                "userId": sessionStorage.userID,
                "loggedInUser": sessionStorage.userID,
                "language": "en"
            }

            let valid = []
            listTest = discountList.filter(list => {

                return list.status === true && (moment(new Date()).format('MM/DD/YY') <= (moment(list.validTill).format('MM/DD/YY'))) && (data.paymentType.toUpperCase() === list.paymentType.toUpperCase()) && (data.id !== list.id)
            })
            if (data.amountFrom === 0) {
                this.setState({
                    flashMessageError: "Amount can't be 0"
                })
                return 0
            }


            // let filteredList = []
            // if (discountList.length) {
            //     filteredList = _.filter(discountList, (o) => {
            //         if (o.paymentType.toUpperCase() === data.paymentType.toUpperCase()) {
            //             return o.amountTo === 0
            //         }
            //     })
            // }
            // let maxDiscountAmount = filteredList.length ? filteredList[0].amountFrom : 0
            // if (!inactive) {
            //     discountList.length ? discountList.map((discount) => {
            //         if ((discount.paymentType.toUpperCase() === data.paymentType.toUpperCase()) && (discountObj.id != discount.id)) {
            //             if (discount.amountTo == 0 && maxDiscountAmount != 0) {
            //                 if (data.amountFrom <= maxDiscountAmount && maxDiscountAmount <= data.amountTo || data.amountTo == 0) {
            //                     valid.push(false)
            //                 }
            //                 else if (maxDiscountAmount <= data.amountFrom) {
            //                     valid.push(false)
            //                 }
            //             }
            //             else if (maxDiscountAmount == 0 && data.amountTo == 0 && data.amountFrom <= discount.amountFrom) {
            //                 valid.push(false)
            //             }
            //             valid.push(this.isValidRange(discount.amountFrom, discount.amountTo, data.amountFrom, discountObj.amountTo ? discountObj.amountTo : data.amountFrom))
            //         }
            //     }) : valid = []
            // }

            let max = data.amountTo
            listTest.map(list => {
                if (list.amountTo >= max) {
                    max = list.amountTo
                }
            })
            listTest.map(list => {
                if (list.amountTo !== 0) {
                    if (data.amountTo === 0) {
                        if (data.amountFrom < max) {
                            valid.push(false)
                        }
                    }
                    let result1 = data.amountFrom >= list.amountFrom && data.amountFrom <= list.amountTo
                    let result2 = data.amountTo >= list.amountFrom && data.amountTo <= list.amountTo
                    let result3 = data.amountFrom <= list.amountFrom && data.amountTo >= list.amountTo
                    if (discountObj.status !== "inactive" && (result1 || result2 || result3)) {
                        valid.push(false)
                    }
                } else if (data.amountTo !== 0) {
                    let amountTo = Infinity
                    let result1 = data.amountFrom >= list.amountFrom && data.amountFrom <= amountTo
                    let result2 = data.amountTo >= list.amountFrom && data.amountTo <= amountTo
                    let result3 = data.amountFrom <= list.amountFrom && data.amountTo >= amountTo
                    if (discountObj.status !== "inactive" && (result1 || result2 || result3)) {
                        valid.push(false)
                    }
                } else {
                    valid.push(false)

                }

                return 0
            })

            if (valid.includes(false)) {
                this.setState({
                    flashMessageError: 'Not a valid range'
                })
                return 0
            } else if (data.amountTo != 0 && data.amountFrom > data.amountTo) {
                this.setState({
                    flashMessageError: 'Not a valid range'
                })
                return 0
            } else if (data.percent == 0) {
                this.setState({
                    flashMessageError: "Discount should be between 1-99 %"
                })
            } else if (data.amountFrom == '' || data.amountTo == null || isNaN(data.amountTo) || isNaN(data.amountFrom)) {
                this.setState({
                    flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
                })
            }
            else if (this.compareDate(data.validFrom, data.validTill)) {
                this.setState({
                    flashMessageError: 'Valid From Date should be less than or equal to the Valid Till Date.'
                })
            } else {
                this.setState({
                    Loading: true,
                    checkPointUpdateState: true
                })

                await this.props.editDiscountAction(data)
            }
        }
    }

    /**
     * Method to check the range 
     * @param {*} x existing from range
     * @param {*} y existing to range
     * @param {*} a entered from range
     * @param {*} b existing to range
     */
    isValidRange(x, y, a, b) {
        if (a < x && b < x) {
            return true
        } else if (a > y && b > y) {
            return true
        } else {
            return false
        }
    }

    /**
     * Method to set the valid till date 
     * 
     */
    setValidTillDate(date) {
        let { id } = this.state.discountObj
        if (id !== '') {
            this.setState({
                flashMessageError: '',
                validTill: date
            })
        } else {
            this.setState({
                flashMessageError: '',
                newDiscountDateValid: date
            })
        }
    }

    /**
     * React Built method 
     * keep waiting for change in props 
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        try {
            let self = this
            if (nextProps.paymentTypes && nextProps.paymentTypes.data) {
                this.setState({
                    paymentTypes: nextProps.paymentTypes.data.data.data
                })
            }
            if (nextProps.addDiscount && nextProps.addDiscount.data && nextProps.addDiscount.data.error === false) {
                if (nextProps.addDiscount.data.data.hasOwnProperty('status') && nextProps.addDiscount.data.data.status === true) {
                    if (this.state.checkPointUpdateState) {
                        self.setState({
                            flashMessageSuccess: "",
                            flashMessageError: ""
                        })
                        self.setState({ flashMessageSuccess: nextProps.addDiscount.data.data.message })
                        setTimeout(async function () {
                            self.setState({
                                checkPointUpdateState: false,
                                flashMessageSuccess: "",
                                Loading: false,
                                validTill: new Date(),
                                newDiscountDateValid: new Date(),
                                validFrom: new Date(),
                                newDiscountDateValidFrom: new Date()
                            })
                            self.props.modalClose();
                            await nextProps.discountListAction()
                            nextProps.locationProps.history.push("/ng-admin/discountmanagement")
                            self.props.modalClose();
                        }, 5000);
                    } else {
                        self.setState({
                            flashMessageError: '',
                            flashMessageSuccess: '',
                            checkPointUpdateState: false
                        })
                    }
                    nextProps.addDiscount.data = null
                } else {
                    // self.setState({
                    //     flashMessageError: nextProps.addDiscount.data.data.message
                    // })
                    setTimeout(() => {
                        self.setState({
                            flashMessageError: "",
                            checkPointUpdateState: false,
                            Loading: false,
                        })

                    }, 5000)
                }
            } else {
                self.setState({
                    checkPointUpdateState: false
                })
            }
            if (nextProps.editDiscount && nextProps.editDiscount.data && nextProps.editDiscount.data.error === false) {
                if (nextProps.editDiscount.data.data.hasOwnProperty('status') && nextProps.editDiscount.data.data.status === true) {
                    self.setState({ flashMessageSuccess: nextProps.editDiscount.data.data.message })
                    if (self.state.checkPointUpdateState) {
                        setTimeout(async function () {
                            self.setState({
                                checkPointUpdateState: false,
                                flashMessageSuccess: "",
                                Loading: false,
                            })
                            self.props.modalClose();
                            await nextProps.discountListAction()
                            nextProps.locationProps.history.push("/ng-admin/discountmanagement")
                            self.props.modalClose();
                            nextProps.editDiscount.data.data.status = false
                        }, 5000);
                    } else {
                        self.setState({
                            flashMessageErro: '',
                            flashMessageSuccess: ''
                        })
                    }
                    nextProps.editDiscount.data.data.message = ""
                } else {
                    if (self.state.checkPointUpdateState && nextProps.editDiscount.data.data.message !== "") {
                        self.setState({
                            flashMessageError: nextProps.editDiscount.data.data.message,
                            checkPointUpdateState: false,
                            Loading: false,
                        })
                        setTimeout(function () {
                            self.setState({
                                flashMessageError: "",
                                checkPointUpdateState: false
                            })
                            nextProps.editDiscount.data.data.message = ""
                        }, 5000)
                    } else {
                        self.setState({
                            flashMessageError: '',
                        })
                    }
                }
            } else {
                self.setState({
                    checkPointUpdateState: false
                })
            }
            if (nextProps.discountList && nextProps.discountList.data) {
                self.setState({
                    discountList: nextProps.discountList.data.data.data,
                })
            }
            if (nextProps.discountDetails) {
                const discountDetails = nextProps.discountDetails
                self.setState({
                    discountObj: {
                        paymentType: discountDetails.paymentType == 'card' || discountDetails.paymentType == 'Card' ? 'Card' : 'ACH',
                        amountFrom: discountDetails.amountFrom,
                        amountTo: discountDetails.amountTo == 0 ? '' : discountDetails.amountTo,
                        percent: discountDetails.percent,
                        status: discountDetails.status == true ? 'active' : 'inactive',
                        id: discountDetails.id
                    },
                    validTill: new Date(discountDetails.validTill),
                    validFrom: discountDetails.validFrom !== null ? new Date(discountDetails.validFrom) : null
                })
            }
            else {
                self.setState({
                    discountObj: {
                        paymentType: '',
                        amountFrom: '',
                        amountTo: '',
                        percent: '',
                        status: 'active',
                        id: ''
                    },
                    discountObjValue: {
                        paymentType: '',
                        amountFrom: '',
                        amountTo: '',
                        percent: '',
                        status: 'active',
                        id: ''
                    },
                    validTill: new Date(),
                    validFrom: new Date(),
                })
            }
        } catch (e) { }
    }

    /**
     * React Built in method 
     * Invoked Just before, component about to unmount 
     */
    componentWillUnmount() {
        this.setState({
            discountObj: {
                paymentType: '',
                amountFrom: '',
                amountTo: '',
                percent: '',
                status: ''
            },
            discountObjValue: {
                paymentType: '',
                amountFrom: '',
                amountTo: '',
                percent: '',
                status: '',
                id: ''
            },
            validTill: new Date(),
            validFrom: new Date(),
            paymentTypes: {},
            discountList: []
        })
    }

    /**
     * Method to set the valid From date 
     * 
     */
    setValidFromDate(date) {
        let { id } = this.state.discountObj
        if (id !== '') {
            this.setState({
                flashMessageError: '',
                validFrom: date
            })
        } else {
            this.setState({
                flashMessageError: '',
                newDiscountDateValidFrom: date
            })
        }
    }

    /**
     * Method to compare Discount Date 
     * 
     */
    compareDate(validFrom, validTill) {
        let dateOne = new Date(validFrom); //Year, Month, Date
        let dateTwo = new Date(validTill); //Year, Month, Date
        return dateOne > dateTwo ? true : false
    }


    render() {
        let currentDate = new Date();
        let datePicker = new Date(currentDate.getTime() + 60 * 60 * 24 * 1000)
        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
        }
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
        }
        let isActive = this.state.discountObj.id !== "" ?
            this.state.discountObj.status : this.state.discountObjValue.status
        return (
            <Modal open={this.props.checkModal} onClose={this.onCloseModal} center classNames={{ overlay: 'discountOverlay', modal: 'discountModel' }}

                closeOnOverlayClick={false}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        {
                            this.state.Loading === true && <div className="loader-react">
                                <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                            </div>
                        }
                        <div className="modal-header giftcart_title_header">
                            <h3 className="modal-title giftcart_all_header"> {(this.state.discountObj.id === '') ? 'Add Discount' : 'Edit Discount'}</h3>
                        </div>
                        <div className="modal-body">
                            <form>
                                {flashSuceessMessageSpan}
                                {
                                    this.state.flashMessageSuccess !== this.state.flashMessageError ?
                                        flashErrorMessageSpan : void 0
                                }
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Payment Type</label>
                                        <div className="styled-select slate">
                                            <select name='paymentType' className='form-control select-dropdown' ref={(e) => this.payment = e} onChange={this.setValue.bind(this)} onBlur={this.setdiscountObjValue.bind(this)}>

                                                {
                                                    Object.values(this.state.paymentTypes) ? Object.values(this.state.paymentTypes).map((paymentType) => {
                                                        return <option value={paymentType} selected={
                                                            !isNaN(this.state.discountObj.id) ?
                                                                paymentType === this.state.discountObj.paymentType
                                                                :
                                                                paymentType === this.state.discountObjValue.paymentType
                                                        }

                                                            style={{ 'display': paymentType === 'ACH' ? 'none' : '' }}
                                                        >{paymentType}</option>
                                                    }) : ''
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div container className="form-group">
                                                    <label>Amount ($)</label>
                                                    <InputMask mask={'99999'}
                                                        maskChar={null}
                                                        className='form-control' name="amountFrom"
                                                        value={!isNaN(this.state.discountObj.id) ? this.state.discountObj.amountFrom :
                                                            this.state.discountObjValue.amountFrom}
                                                        type="text"
                                                        ref={(e) => this.amountFrom = e}
                                                        onChange={this.setValue.bind(this)}
                                                        onBlur={this.setdiscountObjValue.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2 to-text discount_to">
                                                <label>TO</label>
                                            </div>
                                            <div className="col-md-5">
                                                <div container className="form-group">
                                                    <label>&nbsp;</label>
                                                    <InputMask
                                                        mask={'99999'}
                                                        maskChar={null}
                                                        className='form-control'
                                                        name="amountTo"
                                                        value={
                                                            !isNaN(this.state.discountObj.id) ? this.state.discountObj.amountTo :
                                                                this.state.discountObjValue.amountTo
                                                        }
                                                        type="text"
                                                        ref={(e) => this.amountTo = e}
                                                        onChange={this.setValue.bind(this)}
                                                        onBlur={this.setdiscountObjValue.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        {/* validFrom Input */}
                                        <div container className="form-group">
                                            <label>Valid From</label>
                                            {
                                                this.state.discountObj.id !== "" ?
                                                    <DatePicker className='form-control calendar-icon'
                                                        selected={
                                                            this.state.validFrom
                                                        }
                                                        onChange={this.setValidFromDate.bind(this)}
                                                        dateFormat="MM/dd/yyyy"
                                                        minDate={currentDate}
                                                    /> :
                                                    <DatePicker className='form-control calendar-icon'
                                                        selected={
                                                            this.state.newDiscountDateValidFrom ? this.state.newDiscountDateValidFrom :
                                                                currentDate
                                                            // new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
                                                        }
                                                        onChange={this.setValidFromDate.bind(this)}
                                                        dateFormat="MM/dd/yyyy"
                                                        minDate={currentDate}
                                                    />
                                            }
                                        </div>

                                        {/* <div container className="form-group">
                                            <label>Discount (%)</label>
                                            <InputMask
                                                mask={'99'}
                                                maskChar={null}
                                                className='form-control'
                                                value={
                                                    !isNaN(this.state.discountObj.id) ? this.state.discountObj.percent :
                                                        this.state.discountObjValue.percent
                                                }
                                                type="text"
                                                ref={(e) => this.percent = e}
                                                name='percent'
                                                onChange={this.setValue.bind(this)}
                                                onBlur={this.setdiscountObjValue.bind(this)}
                                            />
                                        </div> */}

                                    </div>
                                    <div className="col-md-6">
                                        <div container className="form-group">
                                            <label>Valid Till</label>
                                            {
                                                this.state.discountObj.id !== "" ?
                                                    <DatePicker className='form-control calendar-icon'
                                                        selected={
                                                            this.state.validTill
                                                        }
                                                        onChange={this.setValidTillDate.bind(this)}
                                                        dateFormat="MM/dd/yyyy"
                                                        minDate={currentDate}
                                                    /> :
                                                    <DatePicker className='form-control calendar-icon'
                                                        selected={
                                                            this.state.newDiscountDateValid ? this.state.newDiscountDateValid :
                                                                currentDate
                                                            // new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
                                                        }
                                                        onChange={this.setValidTillDate.bind(this)}
                                                        dateFormat="MM/dd/yyyy"
                                                        minDate={currentDate}
                                                    />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div container className="form-group">
                                            <label>Discount (%)</label>
                                            <InputMask
                                                mask={'99'}
                                                maskChar={null}
                                                className='form-control'
                                                value={
                                                    !isNaN(this.state.discountObj.id) ? this.state.discountObj.percent :
                                                        this.state.discountObjValue.percent
                                                }
                                                type="text"
                                                ref={(e) => this.percent = e}
                                                name='percent'
                                                onChange={this.setValue.bind(this)}
                                                onBlur={this.setdiscountObjValue.bind(this)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className=" checkBoxArea">
                                            <div container className="form-group">
                                                <label>Status</label>
                                            </div>
                                            <label className="form-check-label radioBox1">
                                                <input className="form-check-input radio-inline" type="radio" name="status" value="active" checked={
                                                    isActive == 'active' ? true : false} onClick={this.setValue.bind(this)} onBlur={this.setdiscountObjValue.bind(this)} />
                                                Active</label>
                                            <label className="form-check-label radioBox2">
                                                <input className="form-check-input" type="radio" name="status" id="gridRadios3" value="inactive" checked={isActive == 'inactive'} onClick={this.setValue.bind(this)} onBlur={this.setdiscountObjValue.bind(this)} />
                                                Inactive</label>
                                            <div className="formbtn">
                                                <button type="button" className="giftcard_button mgr10" onClick={this.discountSubmitHandler.bind(this)}>SAVE</button>
                                                <button type="button" className="giftcard_button cardnone_button" onClick={this.onCloseModal.bind(this)}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        paymentTypes: state.paymentTypes,
        editDiscount: state.editDiscount,
        addDiscount: state.addDiscount,
        discountList: state.discountList
    }
}

export default connect(mapStateToProps, { addDiscountAction, editDiscountAction, paymentTypeAction, discountListAction })(AddEditDiscount);