import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import UnlockUser from './unlockUser';
import ResetPassword from './resetPassword';
import activeimg from '../../../public/img/activeAddress.png';
import { getCustomerOrderListAction } from '../../../Actions/Admin/orderManagementAction';
import * as constant from '../../../Constants/index';
import noImage from '../../../public/img/noImageShipPage.png'
import moment from "moment"
import ReactLoading from 'react-loading'
import { customerProfileAction } from '../../../../src/Actions/Customer/customerProfileAction'
import { trackingUrl } from '../../../../../client/src/Constants'
import Helmet from 'react-helmet';

//Class for editing Customer details 

class CustomerManagementViewPage extends React.Component {
    constructor() {
        super();
        this.state = {
            customerDetails: {
                name: '',
                email: '',
                accountType: '',
                primaryPhone: '',
                secondaryPhone: '',
                companyName: '',
                status: '',
                activateDate: '',
                federalTax: '',
                lock: '',
                addressArray: []
            },
            resetLinkModal: false,
            unlockModal: false,
            lock: '',
            address: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                country: '',
                zip: '',
                id: '',
                addressType: ''
            },
            customerId: '',
            customerOrderList: [],
            Loading: false,
            activeClassName: '',
            fontSize: {
                header2: 24,
                addressmyprofile: 14,
                addressdetails: 16,
                profilelabel: 16,
                customerhistory: 15,
                customerhistorydetails: 13,
                customerpricetab: 14,
                allOrderdetails: 12,
            },
            consent: false,

        };
    }

    /***
     * React Built-in Method called just before DOM is about to mounted
     */
    componentWillMount() {
        try {
            if (localStorage.loggedIn == '' || sessionStorage.length == 0) {
              this.props.componentID.history.push("/ng-admin");
            }
          } catch (err) {
      
          }
        this.setState({ activeClassName: this.props.pathParam })
        if (this.props && this.props.customerInfo) {
            this.props.customerProfileAction(this.props.customerInfo.id); //API call
        }
    }

    /***
     * React Built-in Method called after DOM is mounted
     */
    async  componentDidMount() {
        this.setState({ Loading: true })
        let dataToSendServer = {
            "loggedInUser": this.props.customerInfo.id,
            "language": "en",
            "customerId": this.props.customerInfo.id
        }
        await this.props.getCustomerOrderListAction(dataToSendServer) //API Call
    }

    /**
     * React Built-in Method invoke after compare between virtual DOM and Real DOM
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.getCustomerOrderListResponse && nextProps.getCustomerOrderListResponse.data) {
            if (nextProps.getCustomerOrderListResponse.data.error === false) {
                this.setState({
                    customerOrderList: nextProps.getCustomerOrderListResponse.data.data.data,
                    Loading: false
                })
            }
        }
        if (nextProps.customerProfileInfo && nextProps.customerProfileInfo.data) {
            console.log("####new",nextProps.customerProfileInfo.data.data.data)
            var customerInfo = ''
            customerInfo = nextProps.customerProfileInfo.data.data.data
            this.setState({
                customerDetails: {
                    name: customerInfo.firstName + ' ' + customerInfo.lastName,
                    email: customerInfo.email,
                    accountType: customerInfo.accountType,
                    primaryPhone: customerInfo.mobile,
                    title: customerInfo.title,
                    secondaryPhone: customerInfo.phone,
                    companyName: customerInfo.companyName,
                    status: customerInfo.status,
                    activateDate: customerInfo.dateActivated != null ? moment(customerInfo.dateActivated).format('MM/DD/YYYY') : '',
                    federalTax: customerInfo.federalTax,
                    lock: customerInfo.lock,
                    addressArray: customerInfo.addresses
                },
                lock: customerInfo.lock,
                customerId: customerInfo.id,
                consent: customerInfo.consent,

            })
            var customerAddress = []
            var addresses = []
            this.props.customerInfo.addresses.length > 0 ? this.props.customerInfo.addresses.map((address) => {
                if (address.status == true)
                    customerAddress = address
                addresses.push(address)
            }) : customerAddress = []

            this.setState({
                address: {
                    address1: customerAddress.address1 ? customerAddress.address1 : "",
                    address2: customerAddress.address2 ? customerAddress.address2 : "",
                    city: customerAddress.city ? customerAddress.city : "",
                    state: customerAddress.state ? customerAddress.state : "",
                    country: customerAddress.country ? customerAddress.country : "",
                    zip: customerAddress.zip ? customerAddress.zip : "",
                    id: customerAddress.id ? customerAddress.id : "",
                    addressType: customerAddress.addressType ? customerAddress.addressType : ""
                },
            })
        }

    }

    /** Method for open password model*/
    onOpenResetPWDModal = () => {
        this.setState({ resetLinkModal: true });
    };

    /** Method for close password model */
    resetPWDCloseModal = () => {
        this.setState({ resetLinkModal: false });
    };

    /* Method for redirect page */
    userRedirect = (data) => {
        let self = this
        if (data == 'sent' || data == 'unlocked') {
            window.location.reload();
        }
    }

    /* Method to open the unlock user modal */
    onOpenModal = () => {
        this.setState({ unlockModal: true });
    };

    /*Method to close the unlock modal */
    unlockCloseModal = () => {
        this.setState({ unlockModal: false });
    };

    render() {
        if (sessionStorage.loggedIn === '') {
            this.props.history.push("/ng-admin");
        }

        let { customerDetails } = this.state
        let address1 = this.state.customerDetails.addressArray.length > 0 ? this.state.customerDetails.addressArray[0] : ''
        let address2 = this.state.customerDetails.addressArray.length > 1 ? this.state.customerDetails.addressArray[1] : ''
        let defaultAddress = "home"
        let secondaryAddress = "sec";
        let customerOrderLists = this.state.customerOrderList
        const { fontSize } = this.state
        return (
            <div className="admin_top">
                {
                    this.state.Loading === true && <div className="loader-react">
                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                    </div>
                }

                <Helmet>
                    <title>CustomerDetails</title>
                </Helmet>
                <UnlockUser modalOpen={this.state.unlockModal} redirectPage={this.userRedirect} customerData={this.props.customerInfo} closeModal={this.unlockCloseModal} />
                <ResetPassword resetModalOpen={this.state.resetLinkModal} customerData={this.props.customerInfo} redirectPage={this.userRedirect} closeResetPWDModal={this.resetPWDCloseModal}
                    locationProps={this.props.componentID}
                />
                <div className="content-wrapper" className="d-flex flex-column">
                    <Header />
                    <div className="main">
                        <Navigation activeClassName={this.state.activeClassName} />
                        <div className="dashboardContent">
                            <div className="container-fluid">
                                <div className="user-dashboard" id="">
                                    <div className="row mar0">
                                        <div className="col-md-12">
                                            <div>
                                                <ul className="breadcrumb">
                                                    <li className="breadcrumb-item"><a href="#" className="active">Customer Management</a></li>
                                                    <li className="breadcrumb-item"><a href="javascript:void(0)">Customer Detail</a></li>
                                                </ul>
                                                <div className="customer-details-heading">
                                                    <h2>{customerDetails.name}</h2>
                                                </div>

                                                <div className="customer-details-section">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-6 p-0">
                                                                <div className="email-section">
                                                                    <span>Email Address</span>
                                                                    <p>{customerDetails.email}</p>
                                                                </div>
                                                                <div className="company-section">
                                                                    <span>Company Name</span>
                                                                    <p>{customerDetails.companyName}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 p-0 ">
                                                                <div className={`address-section-${address1.status === true ? defaultAddress : secondaryAddress} cust-border `}>
                                                                    <span>Address #1</span>
                                                                    <h4>{address1.addressType} <img src={activeimg} alt="" /></h4>
                                                                    <p>{address1 != '' && address1.address1 != "" ? address1.address1 + ',' + address1.city + ',' + address1.state + ',' + address1.country + ',' + address1.zip : ''}</p>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 p-0">
                                                                <div className="row">
                                                                    <div className="col-md-6 phone-section-right">
                                                                        <div className="phone-section customer_details">
                                                                            <span>Primary Phone</span>
                                                                            <p>{customerDetails.primaryPhone} </p>
                                                                        </div>
                                                                        <div className="active-date-section customer_details">
                                                                            <span>Activation Date</span>
                                                                            <p>{customerDetails.activateDate} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 federal-section-left">
                                                                        <div className="secondary-section customer_details">
                                                                            <span>Secondary Phone</span>
                                                                            <p>{customerDetails.secondaryPhone}</p>
                                                                        </div>
                                                                        <div className="federal-section customer_details">
                                                                            <span>Federal Tax</span>
                                                                            <p>{customerDetails.federalTax} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6  p-0">
                                                                <div className={`address-section-${address2.status === true ? defaultAddress : secondaryAddress} bornone `}>
                                                                    {/* <div className="address-section-sec"> */}
                                                                    <span>Address #2</span>
                                                                    <h4>{address2.addressType}<img src={activeimg} alt="" /></h4>
                                                                    <p>{address2 != '' && address2.address1 != "" ? address2.address1 + ',' + address2.city + ',' + address2.state + ',' + address2.country + ',' + address2.zip : ''}</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 p-0">

                                                                <div className="checkbox_section">
                                                                    <span></span>
                                                                    <input type="checkbox" className="checkbox-btn " checked={this.state.consent === true ? true : false} readOnly />

                                                                    <span>I consent to receiving promotional and marketing emails from Northgate Gonzalez, LLC and its affiliates and agree to their Privacy Policy.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="button-section admin-btn">
                                                    <button className="giftcard_button cardnone_button add_btnfont mgr10" onClick={(e) => this.onOpenResetPWDModal()}>SEND PASSWORD RESET LINK</button>
                                                    {this.state.lock != '' ? <button className="giftcard_button add_btnfont" onClick={(e) => this.onOpenModal()}>UNLOCK ACCOUNT</button> : ''}
                                                </div>

                                                <div className="customer-order-heading">
                                                    <h2>order history</h2>
                                                </div>

                                                <div className="customer-order-history">
                                                    {customerOrderLists.length > 0 ?
                                                        customerOrderLists.map(function (orderlist,i) {

                                                            return (
                                                                <div className="" key={`customer_h${i}`}>
                                                                    <div className="header">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <b><p className="date-text">ORDER NO#{orderlist.orderNumber} </p></b>
                                                                            </div>

                                                                            <div className="col-md-4 text-center">
                                                                                <p className="date-field order-date">{
                                                                                    moment(orderlist.orderDate).format('"MM/DD/YYYY"').replace(/^"(.*)"$/, '$1')}

                                                                                </p>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                               <b> <p className="total-text">
                                                                                    ${Number(parseFloat(orderlist.total).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}</p> </b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        orderlist.orderedProduct.length > 0 ?
                                                                            orderlist.orderedProduct.map(function (product, index) {
                                                                                return (
                                                                                    <div className="row" key={`orderlist__${index}`}>

                                                                                        <div className="col-md-6">
                                                                                            <div className="media order-section">
                                                                                                <div className="media-left-img">
                                                                                                    <img src={product.imagePath == "" ? noImage : constant.APIURL + product.imagePath + product.imageName} />
                                                                                                </div>
                                                                                                <div className="media-body">
                                                                                                    <h4 className="media-heading">
                                                                                                        {
                                                                                                            `${product.productType}
                                                                                                        ($${Number(parseFloat(+product.productPrice).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}) `}
                                                                                                    </h4>
                                                                                                    <span>Qty : {product.noOfCards}</span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="row">
                                                                                                <div className="col-md-4">
                                                                                                    <div className="price-section-tab">
                                                                                                        <p>${Number(parseFloat(product.productPrice * +product.noOfCards).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    0 === index ?
                                                                                                        orderlist.cancelStatus === null || orderlist.cancelStatus === false ?
                                                                                                            <div className="col-md-8">
                                                                                                                <div className={orderlist.orderedProduct.length === 1 ? "delivery-section" : "delivery-section-all"}>


                                                                                                                    {orderlist.activationStatus === true ?
                                                                                                                        <p className="font-change"> Order Status : Activated</p> :
                                                                                                                        orderlist.trackingUrl !== "" ?
                                                                                                                            <div>
                                                                                                                                <p className="font-change" className="font-change " style={{ fontSize: fontSize.allOrderdetails + 'px' }}>Order Status:shipped </p>
                                                                                                                                <p>
                                                                                                                                    <a className="font-change order-url" href={`${trackingUrl}${orderlist.orderTracking}&cntry_code=us&locale=en_US`} style={{ fontSize: fontSize.allOrderdetails + 'px', }}><span style={{ color: '#adadad' }}>Tracking Number :</span> {orderlist.orderTracking}</a></p>
                                                                                                                            </div> :

                                                                                                                        <p className="font-change">Expected Delivery on {moment(orderlist.eta).format('"MM/DD/YYYY"').replace(/^"(.*)"$/, '$1')}</p>
                                                                                                                    }


                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="col-md-8">
                                                                                                                <div className={orderlist.orderedProduct.length === 1 ? "delivery-section" : "delivery-section-all"}>
                                                                                                                    <p className="font-change" className="font-change"> Order Status : Cancelled </p>

                                                                                                                    <div className="font-change " className="font-change capchanges reason-left"><span className="cap30">Reason :</span>
                                                                                                                        <span className="capchanges">{orderlist.cancelReason}</span></div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        : ""
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }) : ""}
                                                                </div>
                                                            )
                                                        }) : <div className="ordernot_foud"><span> No orders found </span>  </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        getCustomerOrderListResponse: state.getCustomerOrderList,
        customerProfileInfo: state.customerProfile,

    };
}

export default connect(mapStateToProps, { getCustomerOrderListAction, customerProfileAction })(CustomerManagementViewPage);