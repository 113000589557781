const moment = require('moment');
let count = 0

module.exports = {
	_getAmountCommaSeparator(amount) {
		if (amount) {
			var parts = amount.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		}
		else {
			return '';
		}
	},

	// method for filtering order list by date
	_getFilteredOrderListByDate(modifedate, list, eDateToCompare) {
		
		if (list != undefined && list != '') {
			var filtered;
			if (eDateToCompare == '') {
				filtered = list.filter(data => modifedate <= data.orderDate);
			}
			else {
				filtered = list.filter(data => {
					
					if (new Date(modifedate).toDateString() === new Date(eDateToCompare).toDateString()) {
						
						return new Date(modifedate).toDateString() === new Date(data.orderDate).toDateString()
					} else {
						
						return ((moment(modifedate).format('YYYY-MM-DD') <= moment(data.orderDate).format('YYYY-MM-DD')) && (moment(data.orderDate).format('YYYY-MM-DD') <= eDateToCompare))
					}

				}
				)


			}
			return filtered;

		}
		else
			return []
	},

	// method for filtering creation list by date
	_getFilteredCustomerListByDate(modifedate, list, eDateToCompare) {
		if (list != undefined && list != '') {
			var filtered;
			if (eDateToCompare == '') {
				filtered = list.filter(data => modifedate <= moment(data.creationDate * 1000).format('YYYY-MM-DD HH:mm'));
			} else {
				filtered = list.filter(data => {
					
				
					if (new Date(modifedate).toDateString() === new Date(eDateToCompare).toDateString()) {
						return new Date(modifedate).toDateString() ===  new Date(data.creationDate * 1000).toDateString()


					}
					return (moment(new Date(data.creationDate * 1000)).format('YYYY-MM-DD') <= moment(new Date(eDateToCompare)).format('YYYY-MM-DD ')) && (moment(new Date(modifedate)).format('YYYY-MM-DD') <= moment(new Date(data.creationDate * 1000)).format('YYYY-MM-DD'))

				});

			}
			return filtered;

		}
		else
			return []
	},

	// method for sorting the dataList
	_getSortedByKey(name, dataList) {
		var queryStr = '', propName = "";
		switch (name) {
			//Shipping Management
			case "shippingType":
				name = "shippingType";
				return dataList.sort(stringCompare, name)
			case "shippingRate":
				return dataList.sort(numberCompare, name)
			case "startRange":
				return dataList.sort(numberCompare, name)
			case "startTime":
				return dataList.sort(numberCompare, name)
			case "description":
				name = "description"
				return dataList.sort(stringCompare, name)
			//Order Management
			case "orderNumber":
				name = "orderNumber"
				return dataList.sort(stringCompare, name)
			case "orderDate":
				name = "orderDate"
				return dataList.sort(dateCompare, name)
			case "customerName":
				propName = "customerName"
				return dataList.sort(stringCompare, name)
			case "customerType":
				propName = "customerType"
				return dataList.sort(stringCompare, name)
			case "billingAddress1":
				return dataList.sort(stringCompare, name)
			case "eta":
				name = "eta"
				return dataList.sort(dateCompare, name)
			case "shippingType":
				return dataList.sort(stringCompare, name)
			case "total":
				return dataList.sort(numberCompare, name)
			case "paymentType":
				return dataList.sort(stringCompare, name)
			//GiftCard Management
			case "rangeArray[0].from":
				name = "rangeArray[0].from"
				return dataList.sort(numberCompare, name)
			case "pin":
				return dataList.sort(stringCompare, name)
			case "orderTracking":
				return dataList.sort(stringCompare, name)
			//Customer Management
			case "firstName":
				propName = 'firstName'
				return dataList.sort(stringCompare, name)
			case "addresses[0].address1":
				return dataList.sort(stringCompare, name)
			case "creationDate":
				return dataList.sort(dateCompare, name)
			//User Management
			case "name":
				return dataList.sort(stringCompare, name)
			case "email":
				return dataList.sort(stringCompare, name)
			case "role":
				return dataList.sort(stringCompare, name)
			//Denomination Management
			case "price":
				return dataList.sort(numberCompare, name)
			case "typeName":
				return dataList.sort(stringCompare, name)
			//Discount Management
			case "percent":
				return dataList.sort(numberCompare, name)
			case "amountFrom":
				return dataList.sort(numberCompare, name)
			case "validTill":
				
				return dataList.sort(dateCompare, name)
			case "currentStatus":
				return dataList.sort(stringCompare, name)
			default:
				return ""
		}

		function stringCompare(a, b) {
			var genreA = '';
			var genreB = '';
			if (name === 'RelatedTo') {
				Object.keys(a[name][0]).map((key) => {
					if (key === 'AccountName') {
						propName = "AccountName";
					} else if (key === 'OpprtunityName') {
						propName = 'OpprtunityName';
					} else if (key === ' ProjectName') {
						propName = 'ProjectName';
					} else if (key === 'FirstName') {
						propName = 'FirstName'
					} else {
						propName = "FirstName"
					}
				})
			}
			if (a[name] !== undefined && typeof a[name] === 'string') {
				genreA = a[name] === null ? '' : a[name].toLowerCase();
				genreB = b[name] === null ? '' : b[name].toLowerCase();
			} else if (a[name] === null) {
				genreA = '';
				genreB = '';
			} else if (Array.isArray(a[name])) {
				genreA = a[name].length > 0 && a[name][0][propName] !== undefined && a[name][0][propName] !== null ? a[name][0][propName].toLowerCase() : '';
				genreB = b[name].length > 0 && b[name][0][propName] !== undefined && b[name][0][propName] !== null ? b[name][0][propName].toLowerCase() : '';
			} else if (a[name] === undefined || a[name] === null) {
				genreA = '';
				genreB = '';

			} else {
				genreA = a[name][propName] !== undefined || a[name][propName] !== null ? a[name][propName].toLowerCase() : '';
				genreB = b[name][propName] !== undefined || b[name][propName] !== null ? b[name][propName].toLowerCase() : '';

			}



			if (genreA === genreB) {
				if (count === 0) {
					count = 1
					return 1;
				} else {
					count = 0;
					return -1;
				}

			}
			if (genreA > genreB) {
				return 1;
			} else if (genreA < genreB) {
				return -1;
			}
			return 0;
		}
		function dateCompare(a, b) {

			if (name === "creationDate") {

				return a[name] - b[name]
			}
			var dateA = (new Date(a[name])).getTime()
			var dateB = (new Date(b[name])).getTime()
			return dateA - dateB
		}
		function numberCompare(a, b) {
			return a[name] - b[name];
		}


	},

	_currentDateTime() {
		let today = new Date();
		let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
		let dateTime = date + ' ' + time;
		return dateTime
	},

	/*get Card type name by card number
	* Example ============
	* MAESTRO 6703444444444449
	* AMEX(AMERICAN EXPRESS): American Express 370000000000002
	* Visa 4035501000000008
	*/
	_getCardType(cardNum) {

		if(!this.luhnCheck(cardNum)){
		return "";
		}
		//Disvor old regex replace by Rajesh
		// "/^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/ig"
		var payCardType = "";
		var regexMap = [
		  {regEx: /^4[0-9]{5}/ig,cardType: "VISA"},
		  {regEx: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/ig, cardType: "MASTERCARD"},
		  {regEx: /^3[47][0-9]{3}/ig,cardType: "AMERICAN EXPRESS"},
		  {regEx: /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})$/ig,cardType: "DISCOVER"},
		  {regEx: /^(5[06-8]\d{4}|6\d{5})/ig,cardType: "MAESTRO"}
		];
		
		for (var j = 0; j < regexMap.length; j++) {
		  if (cardNum.match(regexMap[j].regEx)) {
			payCardType = regexMap[j].cardType;
			break;
		  }
		}
	
		if (cardNum.indexOf("50") === 0 || cardNum.indexOf("60") === 0 || cardNum.indexOf("65") === 0) {
		  var g = "508500-508999|606985-607984|608001-608500|652150-653149";
		  var i = g.split("|");
		  for (var d = 0; d < i.length; d++) {
			var c = parseInt(i[d].split("-")[0], 10);
			var f = parseInt(i[d].split("-")[1], 10);
			if ((cardNum.substr(0, 6) >= c && cardNum.substr(0, 6) <= f) && cardNum.length >= 6) {
			 payCardType = "RUPAY";
			  break;
			}
		  }
		}
		return payCardType;
		
	},
		
		
	luhnCheck(cardNum){
		// Luhn Check Code from https://gist.github.com/4075533
		// accept only digits, dashes or spaces
		var numericDashRegex = /^[\d\-\s]+$/
		if (!numericDashRegex.test(cardNum)) return false;
	
		// The Luhn Algorithm. It's so pretty.
		var nCheck = 0, nDigit = 0, bEven = false;
		var strippedField = cardNum.replace(/\D/g, "");
	
		for (var n = strippedField.length - 1; n >= 0; n--) {
			var cDigit = strippedField.charAt(n);
			nDigit = parseInt(cDigit, 10);
			if (bEven) {
				if ((nDigit *= 2) > 9) nDigit -= 9;
			}
	
			nCheck += nDigit;
			bEven = !bEven;
		}
	
		  return (nCheck % 10) === 0;
	},

	_getDateTimeForReport(){
		let d = new Date()
		let m = d.getMinutes() > 9 ? d.getMinutes() : "0"+d.getMinutes()
		let s = d.getSeconds() > 9 ? d.getSeconds() : "0"+d.getSeconds()
		let time = d.getHours() + "" +m +""+s
		let date = moment(new Date()).format('MMDDYYYY')
		return `${date}_${time}`
	}
}