import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
   isSubmitting: false,
   data: null,
   login: false
}

/**
 * Function for register customer
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function customerRegister(state = data, action) {
   switch (action.type) {
      case constants.CUSTOMER_REGISTER_DATA:
         return { ...state, data: action.payload };
      case constants.CUSTOMER_REGISTER_DATA_ERROR:
         return { ...state, data: action.payload };
      default:
         return state;
   }
}

/**
 * Function for login customer
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function customerLogin(state = data, action) {
   switch (action.type) {
      case constants.CUSTOMER_LOGIN_DATA:
         return { ...state, data: action.payload };
      case constants.CUSTOMER_LOGIN_DATA_ERROR:
         return { ...state, data: action.payload };
      default:
         return state;
   }
}

/**
* Function to seaarch and create user in CIAM and giftcard application
* @param {object} state set initial state in default case
* @param {object} action 
*/
export function searchAndCreateUser(state = data, action) {
   switch (action.type) {
      case constants.SEARCH_USER:
         return { ...state, data: action.payload };
      case constants.SEARCH_USER_ERROR:
         return { ...state, data: action.payload };
      default:
         return state;
   }
}

/**
 * Function to send otp 
 * @param {*} state 
 * @param {*} action 
 */
export function sendOtp(state = data, action) {
   switch (action.type) {
      case constants.SEND_OTP:
         return { ...state, data: action.payload };
      case constants.SEND_OTP_ERROR:
         return { ...state, data: action.payload };
      default:
         return state;
   }
}

/**
 * function to verify otp
 * @param {*} state 
 * @param {*} action 
 */
export function verifyOtp(state = data, action) {
   switch (action.type) {
      case constants.VERIFY_OTP:
         return { ...state, data: action.payload };
      case constants.VERIFY_OTP_ERROR:
         return { ...state, data: action.payload };
      default:
         return state;
   }
}

/**
 * function to view home settings
 * @param {*} state 
 * @param {*} action 
 */
export function viewHomeSettings(state = data, action) {
   switch (action.type) {
      case constants.VIEW_HOME_SETTING:
         return { ...state, data: action.payload };
      case constants.VIEW_HOME_SETTING_ERROR:
         return { ...state, data: action.payload };
      default:
         return state;
   }
}



/**
 * Function to update customer number for otp 
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function updateNumberForOTP(state = data, action) {
   switch (action.type) {
     case constants.UPDATE_NUMBER_DATA:
       return { ...state, data: action.payload };
     case constants.UPDATE_NUMBER_DATA_ERROR:
       return { ...state, data: action.payload };
     default:
       return state;
   }
 }


 /**
 * function to verify otp
 * @param {*} state 
 * @param {*} action 
 */
export function otpVerify(state = data, action) {
   switch (action.type) {
      case constants.OTP_VERIFY:
         return { ...state, data: action.payload };
      case constants.OTP_VERIFY_ERROR:
         return { ...state, data: action.payload };
      default:
         return state;
   }
}