import * as constants from '../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';

/*
* Function for denominationListAction and get denomination list
  @param NULL
  @return object object of api resoponse
*/
export function denominationListAction() {
  return (dispatch) => {
    axios.post(serverRoutes.DENOMINATION_LIST)
      .then(function (response) {
        dispatch({
          type: constants.DENOMINATION_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.DENOMINATION_LIST_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for denominationListAction and get  all active and inactive denomination list
  @param NULL
  @return object object of api resoponse
*/
export function denominationAllListAction() {
  return (dispatch) => {
    axios.post(serverRoutes.DENOMINATION_LIST_ALL)
      .then(function (response) {
        dispatch({
          type: constants.DENOMINATION_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.DENOMINATION_LIST_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for getCardTypeAction and get gift-card type list
  @param NULL
  @return object object of api resoponse
*/
export function getCardTypeAction() {
  return (dispatch) => {
    axios.post(serverRoutes.CARD_TYPE_LIST)
      .then(function (response) {
        dispatch({
          type: constants.CARD_TYPE_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CARD_TYPE_LIST_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for getCardTypeAction and get gift-card type list
  @param NULL
  @return object object of api resoponse
*/
export function denominationAddAction(denominationData) {
  return (dispatch) => {
    axios.post(serverRoutes.ADD_DENOMINATION, denominationData)
      .then(function (response) {
        dispatch({
          type: constants.ADD_DENOMINATION,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.ADD_DENOMINATION_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for getCardTypeAction and get gift-card type list
  @param NULL
  @return object object of api resoponse
*/
export function denominationUpdateAction(denominationData) {
  return (dispatch) => {
    return axios.post(serverRoutes.UPDATE_DENOMINATION, denominationData)
      .then(function (response) {
        dispatch({
          type: constants.UPDATE_DENOMINATION,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.UPDATE_DENOMINATION_ERROR,
          payload: response.data
        })
      })
  }
}

// export function denominationUpdateAction(denominationData) {

//     return  axios.post(serverRoutes.UPDATE_DENOMINATION, denominationData)
//         .then(function (response) {
//         console.log(response)
//         })
//         .catch(function (response) {
//           console.log(response)
//         })

//   }

/*
* Function for getCardTypeAction and get gift-card type list
  @param NULL
  @return object object of api resoponse
*/
export function denominationDeleteAction(denominationData) {
  return (dispatch) => {
    axios.post(serverRoutes.DELETE_DENOMINATION, denominationData)
      .then(function (response) {
        dispatch({
          type: constants.DELETE_DENOMINATION,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.DELETE_DENOMINATION_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for getCardTypeAction and get gift-card type list
  @param NULL
  @return object object of api resoponse
*/
export function denominationImageDeleteAction(denominationData) {
  return (dispatch) => {
    axios.post(serverRoutes.DELETE_DENOMINATION_IMAGE, denominationData)
      .then(function (response) {
        dispatch({
          type: constants.DELETE_DENOMINATION_IMAGE,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.DELETE_DENOMINATION_IMAGE_ERROR,
          payload: response.data
        })
      })
  }
}
export function resetAddEditDenomintion(){
return (dispatch)=>{
dispatch({
  type: constants.ADD_DENOMINATION,
  payload: {}
})}}