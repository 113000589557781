import React, { Component } from 'react';
import Header from '../Layout/Header';
import { getFAQListAction } from '../../../Actions/Admin/faqManagementAction';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import _ from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import * as language from '../../../Constants/translations' /**  ../../../Constants/translations */

/** Main class for displaying FAQ list */
class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: sessionStorage.language!==undefined ? sessionStorage.language === 'spa' ?  'spa':   'en'  :'en',
      faqDataList: [],
      Loading: false,
      faqLangDataList: [],
      fontSize: {
        breadcrumb: 12,
        titleFont: 24,
        accordionFont: 18
      },
      increaseCounter: 0,
      decreaseCounter: 0,
    }
  }

  /** Function to select the language */
  setLanguage(data) {
    this.setState({
      lang: data,
    })
  }

  /** Function for setting the text size */
  changeSize(data) {
    if (data === 'increase') {
      var increaseCounter = this.state.increaseCounter
      increaseCounter = increaseCounter + 1
      if (increaseCounter <= 4) {
        this.setState({
          fontSize: {
            breadcrumb: Number(this.state.fontSize.breadcrumb) + 1,
            titleFont: Number(this.state.fontSize.titleFont) + 1,
            accordionFont: Number(this.state.fontSize.accordionFont) + 1,
          },
          increaseCounter: increaseCounter
        })
      }
    }
    else if (data === 'decrease') {
      var decreaseCounter = this.state.decreaseCounter
      decreaseCounter = decreaseCounter + 1
      if (decreaseCounter <= 4) {
        this.setState({
          fontSize: {
            breadcrumb: Number(this.state.fontSize.breadcrumb) - 1,
            titleFont: Number(this.state.fontSize.titleFont) - 1,
            accordionFont: Number(this.state.fontSize.accordionFont) - 1,
          },
          decreaseCounter: decreaseCounter,
          increaseCounter: 0
        })
      }
    }
    else if (data === 'default') {
      this.setState({
        fontSize: {
          breadcrumb: 12,
          titleFont: 24,
          accordionFont: 18
        },
        increaseCounter: 0,
        decreaseCounter: 0
      })
    }
  }

/**
* React Built in method 
* invoked just before component about to mount 
* 
*/
  async componentWillMount() {
    var json = { "language": "en" }
    this.setState({ Loading: true })
    await this.props.getFAQListAction(json)
  }

/**
* React Life Cycle method 
* Invoke just after chenge in dom 
*
* @param {*} nextProps 
*/
  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.getFAQList !== undefined && nextProps.getFAQList.data.error !== true) {
        this.setState({
          faqLangDataList: nextProps.getFAQList.data.data.data,
          checkPointForUpdateState: false,
          Loading: false,
        })
      } else {
        this.setState({ Loading: false })
      }
    } catch (e) { }
  }

  /***
   * Function for getting sorted list as per language specific
   * 
   */
  getFaqList = () => {
    try {
      let faqList = this.state.faqLangDataList;
      let lang = this.state.lang === "en" ? "English" : this.state.lang === "spa" ? "Spanish" : "English"
      let langSpecificFaqList = _.filter(faqList, function (list) {
        if (list.lang !== null && list.lang !== undefined) {
          return list.lang.toUpperCase() === lang.toUpperCase()
        }
      })
      return langSpecificFaqList
    } catch (e) { }
  }

  /** Function to redirect to buy gift card page*/ 
  redirectToFaq = (event) => {
    try {
      if (this.props.history !== undefined) {
        if (sessionStorage.emailID === '' || sessionStorage.emailID == undefined) {
          this.props.history.push('/')
        } else {
          this.props.history.push("/ordermanagement")
        }

      }
    } catch (e) { }
  }

  render() {
    let filtredFaqList = []
    filtredFaqList = this.getFaqList()
    const { fontSize } = this.state
    
    return (
      <div id="myaccount">
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }
        <div className="wrapper page customerHome" >

          <Header language={this.setLanguage.bind(this)} changeSize={this.changeSize.bind(this)} locationProps={this.props} />
          <section id="main" className="shipping-page">
            <div className="container-fluid">
              <div className="row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item "><a style={{ fontSize: fontSize.breadcrumb + 'px' }} href='javascript:void(0);' onClick={(e) => { this.redirectToFaq(e) }} className="active">{language.language.home[0][this.state.lang]}</a></li>
                  <li className="breadcrumb-item"><a style={{ fontSize: fontSize.breadcrumb + 'px' }} href="#">{language.language.FAQ[0][this.state.lang]}</a></li>
                </ul>
              </div>
            </div>  
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="account-section">
                    <h2 style={{ fontSize: fontSize.titleFont + 'px' }}>{language.language.FAQ_HEADING[0][this.state.lang]}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <Accordion allowZeroExpanded="true">
                  {
                    filtredFaqList !== undefined && filtredFaqList.length > 0 ? filtredFaqList.map((list,i) => (
                      <AccordionItem style={{ fontSize: fontSize.accordionFont + 'px' }} key={i}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {list.question}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p style={{ fontSize: fontSize.accordionFont + 'px' }}>
                            {list.answer}
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))
                      : <p>No record Found ! </p>
                  }
                </Accordion>
              </div>
            </div>
          </section>
        </div>
        <footer>
          <p>© 2019 Northgate Gonzalez Market®</p>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getFAQList: state.getFAQList,
  };
}

export default connect(mapStateToProps, { getFAQListAction })(FAQ);
