import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import AddDenomination from './addEditDenomination';
import { denominationListAction,denominationAllListAction, denominationDeleteAction,denominationUpdateAction } from '../../../Actions/Admin/denominationManagementAction';
import { confirmAlert } from 'react-confirm-alert';
import * as constant from '../../../Constants/';
import Pagination from "react-js-pagination";
import noImage from '../../../public/img/denomination-noimage.png';
import ReactLoading from 'react-loading'
import moment from "moment"
import * as HelperUtil from "../../../utils/common-helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datetime from 'node-datetime';
import Helmet from 'react-helmet';
import Modal from 'react-modal';
//import showSuccessToast from '../../../utils/toastMessgaes';


/** Classs for displaying Gift card design */
class DenominationManagements extends Component {

    constructor() {
        super();
        this.editDenomination = this.editDenomination.bind(this);
        this.state = {
            open: false,
            denominationDetails: '',
            denominationList: [],
            pageactive: 1,
            count: 0,
            startDate: new Date(),
            endDate: new Date(),
            sDateToCompare: '',
            eDateToCompare: '',
            filteredDataList: [],
            filterBy: '',
            listToFilter: '',
            Loading: false,
            activeClassName: '',
            filterData: '',
            checkPointUpdateState: false,
            sortOrder: '',
            fliterStartDate: 'From',
            fliterEndDate: 'To',
            sortField: '',
            checkboxval:[{name:'',checked:''}],
            ischecked:{},
            openModal:false,
            statusType:'',
            openToast:'',
            openSuccessToast:'',
            openValToast:false
        };
    }

    /**
    * Function for update state for close modal
    */
    status = () => {
        this.setState({
            open: false,
            filterBy: 'Select',
            filterData: '',
            filteredDataList: this.state.denominationList,
            startDate: new Date(),
            endDate: new Date()
        });
    }

    /**
     * React built method 
     * invoked just before when component is about to mount in DOM
     */
    componentWillMount() {
        this.setState({ activeClassName: this.props.pathParam })
        this.setState({ Loading: true })
        this.props.denominationAllListAction();
    }

    /**
     * React Built in method 
     * keep waiting for changes in props 
     * @param {*} nextProps 
     */
    async componentWillReceiveProps(nextProps) {
        if (nextProps.denominationListData && nextProps.denominationListData.data && nextProps.denominationListData.data.error === false) {
            this.setState({
                denominationList: nextProps.denominationListData.data.data.data,
                filteredDataList: nextProps.denominationListData.data.data.data,
                listToFilter: nextProps.denominationListData.data.data.data,
                Loading: false,
                checkPointUpdateState: false,
            })
        } else {
            this.setState({ Loading: false, checkPointUpdateState: false })
        }
        document.getElementById('user-dashboard').style.height = window.innerHeight - 0 + 'px';
        let reso_height = document.getElementById('user-dashboard').style.height
        // document.getElementsByClassName('res_height')[0].style.height = reso_height.slice(0, -2) - 240 + 'px';
        if (nextProps.deleteDenominationData && nextProps.deleteDenominationData.data && nextProps.deleteDenominationData.data.error === false) {
            if (this.state.checkPointUpdateState) {
                this.setState({
                    checkPointUpdateState: false,
                    Loading: true
                })
                await this.props.denominationAllListAction()
                this.props.componentID.history.push("/ng-admin/denomination")
            }
        } else {
            this.setState({ Loading: false, checkPointUpdateState: false })
        }
    }

    /**
     * Function to change the active page
     * @param {*} page 
     */
    handlePageChange = (pageNumber) => {
        var count = pageNumber == 1 ? 0 : (pageNumber - 1) * 10;
        this.setState({
            count: count,
            pageactive: pageNumber
        })
    }

    /**
     * Function to set the filter value
     * @param {event}
     * @return {undefined}
     */
    setFilterBy(e) {
        var val = e.target.value
        this.setState({
            filterBy: val,
            filterData: '',
            filteredDataList: this.state.listToFilter,
            startDate: moment(new Date()).format('MM/DD/YYYY'),
            endDate: moment(new Date()).format('MM/DD/YYYY'),
            fliterStartDate: 'From',
            fliterEndDate: 'To',
        })
    }

    /**
     * Function for auto search 
     * @param {*} event 
     */
    filterSearch(event) {
        let self = this
        var newDataList = []
        newDataList = this.state.listToFilter
        var val = event.target.value
        this.setState({
            filterData: val
        })
        var newArr = newDataList.filter(function (e) {
            if (self.state.filterBy == 'Card Type') {
                if (
                    ((typeof e.typeName != "undefined" && e.typeName != null && e.typeName !== "") && e.typeName.toLowerCase().includes(val.toLowerCase()))
                ) {
                    return e
                }
            } else {
                return e
            }
        })
        this.setState({
            filteredDataList: newArr,
            pageactive: 1
        })
    }

    /** Function to set the start date*/
    handleChangeStartDate(date) {
        try {
            let date_ = date.target !== undefined ? date.target.value :false
            
            let data = /^[0-9-/]*$/
            if( !data.test(date_) && date_!==false) { 
              
              date.target.value = ''
                  
            } else {
                var pastDateTime = datetime.create(date);
                var modifedate = moment(pastDateTime.now()).format('YYYY-MM-DD HH:mm');
                let endDate = this.state.endDate != '' ? moment(this.state.endDate).format('YYYY-MM-DD HH:mm') : ''
                const result = HelperUtil._getFilteredCustomerListByDate(moment(date).format('YYYY-MM-DD HH:mm'), this.state.denominationList, endDate)
                this.setState({
                    startDate: date,
                    sDateToCompare: modifedate,
                    filteredDataList: result,
                    fliterStartDate: moment(date).format('MM/DD/YYYY'),
                    filterBy: '',
                    filterData: '',
                    // filteredDataList: this.state.listToFilter,
                });
            }
        } catch(e) { console.log("errror in ",e) }    
     
    }

    /** Function to set the end date */
    handleChangeEndDate(date) {
        try {
            let date_ = date.target !== undefined ? date.target.value :false
            
            let data = /^[0-9-/]*$/
            if( !data.test(date_) && date_!==false) { 
              
              date.target.value = ''
                  
            } else { 
                var pastDateTime = datetime.create(date);
                var modifedate = moment(pastDateTime.now());
                const result = HelperUtil._getFilteredCustomerListByDate(moment(this.state.startDate).format('YYYY-MM-DD HH:mm'), this.state.denominationList, moment(date).format('YYYY-MM-DD HH:mm'))
                this.setState({
                    endDate: date,
                    eDateToCompare: modifedate,
                    filteredDataList: result,
                    fliterEndDate: moment(date).format('MM/DD/YYYY'),
                    filterBy: '',
                    filterData: '',
                    // filteredDataList: this.state.listToFilter,
                });}
        } catch(e) {console.log("erorr in ",e)}
      
    }

    /** Function to reset the date */
    resetList(e) {
        var val = e.target.value
        this.setState({
            filterBy: '',
            filterData: '',
            startDate: moment(new Date()).format('MM/DD/YYYY'),
            endDate: moment(new Date()).format('MM/DD/YYYY'),
            fliterStartDate: 'From',
            fliterEndDate: 'To',
            filteredDataList: this.state.listToFilter,
        })
    }

    /**
     * Function to open Add gift card design modal 
     * @param {event}
     * @param {undefined}
     */
    onOpenModal = (event) => {
        this.setState({ open: true, denominationDetails: '' });
    }

    /**
     * Function to redirect to the gift card design page 
     * @param {data}
     * @return {undefined}
     */
    userRedirect = (data) => {
        if (data === 'sent') {
            window.location.reload();
        }
    }

    /**
     * Function for edit denomination details
     * @param{key}
     * @return {undefined}
     */
    editDenomination(key) {
        this.setState({ open: true, denominationDetails: key });
    }

    /**
     * Function to delete the denomination
     * @param{event}
     * @return {undefined}
     */
    deleteDenomination(e) {
        let self = this
        var data = {
            "id": e.variantId,
            "typeId": e.typeId,
            "loggedInUser": sessionStorage.userID,
            "language": "en",
            "userId": sessionStorage.userID
        }

        /** Function to set the alerts on adding and updating*/
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm-popup AddUserModelform">
                        <div className="modal-header modal-header giftcart_title_header"><h3 className="modal-title giftcart_all_header"> Are you sure?</h3></div>
                        <div className="modal-body">
                            <p>You want to delete the Gift Card Design</p>
                            <div className="formbtn">
                                <button type="button" className="giftcard_button mgr10"
                                    onClick={() => {
                                        self.setState({
                                            checkPointUpdateState: true,
                                            Loading: true
                                        })
                                        this.props.denominationDeleteAction(data)
                                        onClose();
                                    }}
                                >
                                    Confirm
                                </button>
                                <button type="button" onClick={onClose} className="giftcard_button cardnone_button">No</button></div>
                        </div>
                    </div>
                );
            }//denominationDeleteAction
        })
    }

    /**
     * Function for sorting data onclick
     * @param {dataList}
     * @param{Name}
     */
    sortBy(dataList, Name) {
        this.setState({
            sortField: Name
        })

        let response = constant.SORT_BY(dataList, Name, this.state.sortOrder)
        this.setState({
            filteredDataList: response.data,
            sortOrder: response.sortOrder
        })
    }

    handleCheckboxChange(e){
        let val1 = e.target.name
        let val2=e.target.checked
this.setState(prevState=>{ 
    return {checkboxval:[...prevState.checkboxval,{name:val1 ,checked:val2}]}
})

this.setState({ ischecked:{...this.state.ischecked,[val1]:!this.state.ischecked[val1]
}})

    }

    handleActivateDeactivateConfirm(){
        let { filteredDataList } = this.state
        var list = filteredDataList != undefined ? filteredDataList.slice((this.state.pageactive - 1) * 10, (this.state.pageactive) * 10) : ''
       
            let checkboxvalClone = this.state.checkboxval
    let arr=[]
    checkboxvalClone.forEach((unique,i) => {
        
       if( i== checkboxvalClone.findLastIndex(obj=>obj.name==unique.name))
       { arr.push(unique)}
      }, []);
      arr.splice(0,1)
      let finalArrClone=[]
      let finalArr=[]
       arr.map(item=>{  
            let updatedVal=  list.find(j=>j.variantId==item.name )
            updatedVal.variantStatus=item.checked
            finalArrClone.push(updatedVal)          
    })
      finalArr = finalArrClone.filter(item=>item.variantStatus==true)
    
         if(this.state.statusType=='Deactivate')
       { 
        finalArr.forEach(x=>x.variantStatus=false
            )
    
       }
    let p1 = finalArr.map( item=>{
        let denominationParams = {
            typeId: item.typeId,
            price: item.price,
            loggedInUser: sessionStorage.userID,
            language: 'en',
            userId: sessionStorage.userID,
            id: item.variantId,
            status:item.variantStatus ? 1:0
          }
          this.setState({Loading:true})
        return   this.props.denominationUpdateAction(denominationParams); //API Call
    })
     Promise.all (
      p1
    ).then( x=>{         
           this.props.denominationAllListAction()

    this.setState({ischecked:{},checkboxval:[{name:'',checked:''}],Loading:false,openSuccessToast:true})
         setTimeout(() => {
            this.setState({
                openSuccessToast:false
        })
         }, 2000); 
})
           .catch(err=>{
            this.setState({ischecked:{},checkboxval:[{name:'',checked:''}],Loading:false})
            this.setState(pre=>{return{
            openToast:true
           }
        })
        setTimeout(() => {
            this.setState(pre=>{return{
                openToast:true
        }})
        }, 2000);


        })
    
    }

    handleActivateDeactivate(val){
   if(Object.values(this.state.ischecked).includes(true))
   {
   this.setState({openModal:true,statusType:val})
   }else{
  this.setState({openValToast:true})
  setTimeout(() => {
    this.setState({openValToast:false})
  }, 2000);
   }
    }

    render() {
        let { filteredDataList } = this.state
        var list = filteredDataList != undefined ? filteredDataList.slice((this.state.pageactive - 1) * 10, (this.state.pageactive) * 10) : ''
        return (
            <div className="admin_top">
                {
                    this.state.Loading === true && <div className="loader-react">
                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                    </div>
                }
                <Helmet>
                    <title>Gift Card  Design</title>
                </Helmet>
                <div className="content-wrapper d-flex flex-column">
                    <Header />
                   {this.state.openToast ? <div className='toast'>
                      Gift card is not successfully updated. Please try again. 
                    </div>: ""}
                    {this.state.openSuccessToast ? <div className='toast successful'>
                      Gift card is successfully updated.
                    </div>: ""}
                    {this.state.openValToast ? <div className='toast'>
                     Please select atleast one Gift card.
                    </div>: ""}
                    <div className="main"></div>
                    <Navigation activeClassName={this.state.activeClassName} />
                    <div className="dashboardContent">
                        <div className="container-fluid">
                            <div className="admin-header">
                                <div className="user-dashboard user-management paddingBottom" id="user-dashboard">
                                    <div className="row mar0">
                                        <div className="col-md-12">
                                            <h2>Gift Card Design</h2>
                                            <div className="row">
                                                <div className="pull-left col-md-9 pr-0 shipping-filter">
                                                    <div className="row">
                                                        <div className="form-group filter-input">
                                                            <div className="styled-select slate">
                                                                <select className="select-dropdown" name="test" onChange={(e) => this.setFilterBy(e)} value={this.state.filterBy}>
                                                                    <option value="Select">Select</option>
                                                                    <option value="Card Type">Card Type</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group filter-input">
                                                            <div className="inner-addon search-input">
                                                                <input type="text" className="form-control input-style search-icon" placeholder="Search" value={this.state.filterData} onChange={this.filterSearch.bind(this)} />
                                                            </div>
                                                        </div>
                                                        <div className="datePicker">
                                                            <div className="form-group filter-calendar datePickerReact">
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <DatePicker 
                                                                            className='form-control calendar-icon'
                                                                            placeholderText={this.state.fliterStartDate}
                                                                            maxDate={new Date()} disabled={this.state.filter} 
                                                                            inputProps={{ readOnly: true }}
                                                                            onChange={this.handleChangeStartDate.bind(this)}
                                                                            onChangeRaw = {this.handleChangeStartDate.bind(this)}
                                                                            />
                                                                    </div>
                                                                    <div className="col-sm-6 secondDatePikker">
                                                                        <DatePicker 
                                                                            className='form-control calendar-icon' 
                                                                            placeholderText={this.state.fliterEndDate} 
                                                                            disabled={this.state.filter} maxDate={new Date()}
                                                                            inputProps={{ readOnly: true }}
                                                                            onChange={this.handleChangeEndDate.bind(this)} 
                                                                          
                                                                          />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="refresh-btn">
                                                            <i onClick={this.resetList.bind(this)} title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="pull-right col-md-3 pl-0 text-center mbl-scn">
                                                    <button className="giftcard_button add_btnfont pull-right button-padding" onClick={this.onOpenModal}>ADD Gift card design</button>
                                                </div>
                                            </div>
                                            <div className="table-responsive res_height ">
                                                <table id="example" className="table table-striped table-bordered m-0 denomination" cellSpacing="0">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Thumbnail</th>
                                                            <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'typeName')} >
                                                                {this.state.sortField !== '' ?
                                                                    this.state.sortField === 'typeName' ?
                                                                        <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                Card Type</th>
                                                            <th className="action-td">Actions</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            list.length > 0 ? list.map((key, index) => {
                                                                return <tr key={index}>
                                                                    <td ><a
                                                                        onClick={(e) => this.editDenomination(key)}>{(index + 1) + this.state.count}<li> </li></a></td>
                                                                    <td><img className="denominationImg" src={key.imagePath == "" ? noImage : constant.APIURL + key.imagePath + key.imageName} height="30" width="30" /></td>
                                                                    <td>{key.typeName}</td>
                                                                    <td className="action-td">
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}><a onClick={(e) => this.editDenomination(key)} href="#"><i className="fa fa-pencil" aria-hidden="true"></i></a>
                                                                        <a className={(key.active == true ? 'green' : 'red')} onClick={(e) => this.deleteDenomination(key)} href='javascript:;'><i className="fa fa-trash"></i></a> <span className="checkbox_section">
                                                                 
                                                                    <input type="checkbox" className="checkbox-btn "  name={key.variantId} checked={this.state.ischecked[key.variantId] || false} onChange={this.handleCheckboxChange.bind(this)} /></span>
                                                                    </div>
                                                                    </td> 
                                                                    <td>{key.variantStatus ? 'Activated' : 'Deactivated'}</td>
                                                                    </tr>
                                                            }) :  <tr><td className="recordNotFound" colSpan="10">No record found</td></tr>
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                            <div style={{ textAlign: 'right', marginTop:'10px'}}>
                                            <button className="giftcard_button add_btnfont  button-padding" onClick={this.handleActivateDeactivate.bind(this,'Activate')}>Activate</button>
                                            <button style={{marginLeft: '10px'}} className="giftcard_button add_btnfont  button-padding" onClick={this.handleActivateDeactivate.bind(this,'Deactivate')}>Deactivate</button>
                                            </div>
                                            {
                                               <Modal className='activatePopUp'
                                             isOpen={this.state.openModal} >
 <div className="confirm-popup AddUserModelform">
                        <div className="modal-header modal-header giftcart_title_header"><h3 className="modal-title giftcart_all_header"> Are you sure?</h3></div>
                        <div className="modal-body">
                            <p>You want to {this.state.statusType} the Gift Card Design</p>
                            <div className="formbtn">
                                <button type="button" className="giftcard_button mgr10"
                                    onClick={() => {
                                        this.setState({openModal:false})
                                        this.handleActivateDeactivateConfirm()
                                    }}
                                >
                                    Confirm
                                </button>
                                <button type="button" onClick={()=>{this.setState({openModal:false})}}  className="giftcard_button cardnone_button">No</button></div>
                        </div>
                    </div>
                                                </Modal> 
                                            }
                                            {<ul className="pagination pull-right my-1 simaple">
                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.pageactive}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.filteredDataList.length}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange}
                                                    firstPageText='First'
                                                    lastPageText='Last'
                                                    prevPageText="Prev"
                                                    nextPageText="Next"
                                                />
                                            </ul>}
                                           

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddDenomination checkModal={this.state.open} modalClose={this.status} redirectPage={this.userRedirect} denominationDetails={this.state.denominationDetails}
                    locationProps={this.props.componentID}
                    denominationListAction={this.props.denominationAllListAction} 
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        denominationListData: state.denominationList,
        deleteDenominationData: state.deleteDenomination
    }
}

export default connect(mapStateToProps, { denominationListAction,denominationAllListAction, denominationDeleteAction,denominationUpdateAction })(DenominationManagements);