import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import * as language from '../../../Constants/translations';
import '../../../public/css/customer-style.css';
import successimg from '../../../public/img/success.png';
import errorimg from '../../../public/img/error.png';
import { customerRegisterAction, customerLoginAction, sendOTPAction, searchUserAction } from '../../../Actions/Customer/CustomerLoginAction';
import * as constants from '../../../Constants';
import { customerListAction, customerResetPWDAction } from '../../../Actions/Admin/customerManagementAction';
import { denominationListAction, getCardTypeAction } from '../../../Actions/Admin/denominationManagementAction';
import { activateCustomerAction } from '../../../Actions/Customer/customerProfileAction';
import ReactLoading from 'react-loading'
import ReactModal from 'react-modal'
import InputMask from 'react-input-mask';
import _ from 'lodash'
import base64 from 'base-64'
import * as HelperUtil from "../../../utils/common-helper"
import Consent from './consent'


class LoginModal extends Component {
  constructor(props) {
    super();
    this.state = {
      validObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      invalidObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      emailErrorSpan: "",
      passwordErrorSpan: "",
      regsFirstNameErrorSpan: "",
      regsLastNameErrorSpan: "",
      regsCompanyNameErrorSpan: "",
      regsEmailErrorSpan: "",
      regsPhoneErrorSpan: "",
      regsPasswordErrorSpan: "",
      regsConfirmPasswordErrorSpan: "",
      forgetModalopen: false,
      regiModalOpen: false,
      loginStateUpdate: false,
      lang: 'en',
      Loading: false,
      isFieldsEmpty: false,

      modalIsOpen: false,
      signupModalIsOpen: false,
      check: 1,
      modalForget: false,
      policyModalopen: false,
      forgetvalidObj: {
        email: ""
      },
      forgetinvalidObj: {
        email: ""
      },
      nameInputMask: 'aaaaaaaaaaaaaaa',
      customerList: [],
      checkUpdateState: false,
      isInputFieldEmpty: false,
      newsLetterModalopen: false,
      newsLetterStatus: true,
     
    }
  }


  /**
     * Method for close model
     */
  onCloseModal = () => {
    this.props.modalClose();
    this.setState({
      isFieldsEmpty: false,
      validObj: {},
      emailErrorSpan: "",
      passwordErrorSpan: "",
    })
  }

  /**
   * function to redirect to buygiftcard "All cards"
   */
  openOrderManagement() {
    this.onCloseModal()
    sessionStorage.userID = 0
    sessionStorage.cardType = 'all'
    try {
      if (this.props.locationProps.hasOwnProperty("orderManagementProps") && this.props.locationProps.orderManagementProps !== undefined) {
        this.props.locationProps.orderManagementProps.history.push('/ordermanagement')
      } else if (this.props.locationProps.hasOwnProperty("history") && this.props.locationProps.history !== undefined) {
        this.props.locationProps.history.push('/ordermanagement')
      }
    } catch (e) { }


  }

  /**
   * function for validate user details
   * @param object event
   * @return object state
  */

  setValue = (event) => {
    this.setState({
      emailErrorSpan: "",
      flashMessageSuccess: '',
      flashMessageError: "",
      passwordErrorSpan: ""
    })
    let key = event.target.name
    let value = event.target.value
    let valid = {}
    let invalid = {}
    if (value === "") {
      invalid[key] = language.language.required_field[0]
      valid[key] = ""
    } else {
      invalid[key] = ""
      valid[key] = value
    }
     //Check for first space 
    if(value !== "" && value.charCodeAt() === 32) {
     	return 0;
  	}
     //end
    let inValidaData = Object.assign(this.state.invalidObj, invalid)
    let validaData = Object.assign(this.state.validObj, valid)

    this.setState({
      invalidObj: inValidaData, validObj: validaData,
      emailErrorSpan: "",
      passwordErrorSpan: "",
      regsFirstNameErrorSpan: "",
      regsLastNameErrorSpan: "",
      regsCompanyNameErrorSpan: "",
      regsEmailErrorSpan: "",
      regsPhoneErrorSpan: "",
      regsPasswordErrorSpan: "",
      regsConfirmPasswordErrorSpan: ""
    })
  }


  /**
  * function for authenticate user is valid or not on submit
  * @param object event
  * @return object userParams
 */
  authenticateLogin = async (event) => {
    let email = this.state.validObj.email;
    let password = this.state.validObj.password;
    if (!this.validateMail(email) && email !== '') {
     
      Object.assign(this.state.validObj, { emailError: true })
      this.setState({
        emailErrorSpan: language.language.Invalid_Email[0][this.state.lang],
        isFieldsEmpty: true

      })
    }
    if (password == "") {
      this.setState({
        passwordErrorSpan: language.language.Empty_Password[0][this.state.lang], isFieldsEmpty: true
      })
    } 
    
    if (email == "") {
      this.setState({
        emailErrorSpan: language.language.Empty_Email_Address[0][this.state.lang], isFieldsEmpty: true
      })
    }
    else if (password == "") {
      this.setState({
        passwordErrorSpan: language.language.Empty_Password[0][this.state.lang], isFieldsEmpty: true
      })
    } else if (email != "" && password != "") {
      if (this.validateMail(email)) {
        let userParams = {
          email: email,
          password: password
        }
        this.setState({ Loading: true, loginStateUpdate: true })
        await this.props.customerLoginAction(userParams)
      } else {
        Object.assign(this.state.validObj, { emailError: true })
        this.setState({
          emailErrorSpan: language.language.Invalid_Email[0][this.state.lang],
          isFieldsEmpty: true

        })
      }
    } else {

      let invalid = {}
      if (this.state.validObj.username == "") {
        invalid['username'] = "This Field is required"
      }
      if (this.state.validObj.password == "") {
        invalid['password'] = "This Field is required"
      }
      let inValidaData = Object.assign(this.state.invalidObj, invalid)
      this.setState({
        invalidObj: inValidaData,
        flashMessageError: "Fields can't be null"
      })
    }
  }

  /**
  * function for validate Email
  * * @return boolean
 */
  validateMail = (email) => {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());

  }

  /**
       * function to submit form on enter Keypress
       */
  submitOnEnter(event) {
    if (event.which == 13 || event.keyCode == 13) {
      //customer LogIn Form
      if (event.target.id == 'pwd') {
        this.authenticateLogin();
      }
      //customer SignUp Form
      else if (event.target.id == 'cnf-pwd-signup') {
        this.authenticate()
      }
      //customer forget Password
      else if (event.target.id == 'email-forget-password') {
        this.resetPWDLink()
      }
    }
  }

  // Function for call lyfe cycle and handel props response
  componentWillReceiveProps(nextProps) {
    
    var lang = this.state.lang
    let self = this

    if (nextProps.stateData) {

      this.setState({
        lang: nextProps.stateData.lang,
        flashMessageSuccess:nextProps.stateData.flashMessageSuccess
      })
    }

    if (nextProps.searchAndCreateUser && nextProps.searchAndCreateUser.data) {
      try {
        if (nextProps.searchAndCreateUser.data.hasOwnProperty('status') && nextProps.searchAndCreateUser.data.status === true) {
          self.setState({
            flashMessageError: '',
            flashMessageSuccess: language.language.activation_link[0][lang],
          })
          setTimeout(() => {
            this.resetStates()
            this.props.regModalClose()
            this.props.modalClose();
            this.setState({ signupModalIsOpen: false, modalIsOpen: false });
          }, 3000);
          
          if (this.state.checkUpdateState === true) {
            setTimeout(async function () {
              self.setState({
                Loading: false,
                flashMessageSuccess: '',
                flashMessageError: '',
                checkUpdateState: false
              })
              await nextProps.regModalClose();
              await self.resetStates();
            }, 3000);
          } else {
            self.setState({
              Loading: false,
              flashMessageSuccess: '',
              flashMessageError: '',
              checkUpdateState: false
            })
          }

        } else {
          self.setState({ flashMessageError: JSON.stringify(nextProps.searchAndCreateUser.data.message), Loading: false })
        }
      }
      catch (err) {
        console.log("error",err)
      }
    }
    if (nextProps.customerList && nextProps.customerList.data) {
      try {
        if (nextProps.customerList.data.data && nextProps.customerList.data.data.data != null) {
          self.setState({
            customerList: nextProps.customerList.data.data.data,
          })

        }
      } catch (err) {
        console.log("err",err)
      }
    }
    if (nextProps.customerLoginInfo && nextProps.customerLoginInfo.data) {
      try {
        if (nextProps.customerLoginInfo.data.status) {
          localStorage.removeItem('guestUserDetails')
          sessionStorage.loggedIn = true;
          sessionStorage.customerName = nextProps.customerLoginInfo.data.data.firstName + " " + nextProps.customerLoginInfo.data.data.lastName;
          sessionStorage.userID = nextProps.customerLoginInfo.data.data.id;
          sessionStorage.emailID = nextProps.customerLoginInfo.data.data.email
          if (nextProps.customerLoginInfo.data.data.addresses.length > 0) {
            //alert('login')
            //  window.localStorage.clear();
            sessionStorage.cardType = 'all'
            // self.onCloseModal();
            var Data = []
            Data = JSON.parse(localStorage.getItem('productCardDetailsData'))

            if (self.state.loginStateUpdate === true) {
              setTimeout(async function () {
                self.setState({
                  loginStateUpdate: false
                })
                self.onCloseModal()
                self.setState({ Loading: false })
                nextProps.locationProps.history.push({
                  pathname: '/ordermanagement',
                  data: Data,
                  customerLoginInfo: nextProps.customerLoginInfo.data.data,
                });
              }, 3000)

            }


          }
          else
            self.props.history.push("/dashboard");
        } else {
          self.setState({
            passwordErrorSpan: nextProps.customerLoginInfo.data.message,
            Loading: false
          })
        }
      }
      catch (err) {
        console.log("new data  erorr login ", err)
      }

    }



    if (nextProps.resetCustomerPWDResponse && nextProps.resetCustomerPWDResponse.data) {
      try {
        if (nextProps.resetCustomerPWDResponse.data.hasOwnProperty('status') && (nextProps.resetCustomerPWDResponse.data.status === true)) {
          self.setState({ flashMessageError: '', flashMessageSuccess: language.language.reset_password_link[0][this.state.lang] })
          setInterval(function () {
            let redirect = window.location.href.substring(window.location.href.lastIndexOf('?'), 0)
            window.location = redirect;
          }, 5000);
        } else {
          self.setState({ flashMessageError: nextProps.resetCustomerPWDResponse.data.message })
        }
      }
      catch (err) {
        console.log("error",err)
      }

    }


    // if (nextProps && nextProps.activateCustomerResponse) {

    //   try {
    //     if (nextProps.activateCustomerResponse.data.data.hasOwnProperty('status') && nextProps.activateCustomerResponse.data.data.status === true) {
    //       self.setState({ flashMessageSuccess: nextProps.activateCustomerResponse.data.data.message })
    //       setInterval(function () {
    //         self.setState({
    //           loginModalopen: true,
    //           openOtpModal: false,
    //           validObj: {
    //             firstName: "",
    //             lastName: "",
    //             email: "",
    //             password: "",
    //             companyName: "",
    //             phone: "",
    //             confirmPassword: ""
    //           },
    //           otpErrorSpan: ''
    //         }
    //         );
    //       }, 3000);
    //     } else {
    //       self.setState({
    //         otpErrorSpan: nextProps.activateCustomerResponse.data.data.message,
    //         loginModalopen: true,
    //         openOtpModal: false,
    //         validObj: {
    //           firstName: "",
    //           lastName: "",
    //           email: "",
    //           password: "",
    //           companyName: "",
    //           phone: "",
    //           confirmPassword: ""
    //         }
    //       })
    //     }
    //   } catch (err) {

    //   }
    // }
  }

  openModal = () => {
    this.resetStates()
    this.props.changeLinkColorLogin()
    this.props.regModalClose()
    this.setState({ modalIsOpen: true, signupModalIsOpen: false });
  };

  closeModal = () => {
    this.resetStates()
    this.props.modalClose();
    // this.props.regModalClose()
    this.setState({ modalIsOpen: false, signupModalIsOpen: false });
  };

  openSecondModal = () => {
    this.resetStates()
    this.props.changeLinkColorSignUp()
    this.props.modalClose();
    this.setState({ signupModalIsOpen: true, modalIsOpen: false });
  };

  closeSecondModal = () => {
    this.resetStates()
    this.props.regModalClose()
    this.props.modalClose();
    this.setState({ signupModalIsOpen: false, modalIsOpen: false });
  };

  /**
     * function for enable registration button
     */
  enableRegistrationButton = (e) => {
    if (e.target.checked) {
      this.setState({ check: 1 })
    } else {
      this.setState({ check: 0 })
    }
  }

  closeModalForget = () => {
    this.resetStates()
    this.props.modalClose();
    this.setState({ modalForget: false });
  };

  openModalForget = () => {
    this.resetStates()
    this.props.modalClose();
    this.setState({ modalForget: true, modalIsOpen: false })
  }
  /**
      * function to open policyModal
      */
  openModalPolicy = () => {
    this.setState({
      policyModalopen: true
    });
  };
  /**
* function to close policyModal
*/
  closeModalPolicy = () => {
    this.setState({
      policyModalopen: false
    });
    if (document.getElementsByClassName("modalPolicy")[0]) {
      document.getElementsByClassName("modalPolicy")[0].classList.add("exit");
    }
  };

  /**
    * function for authenticate user is valid or not on submit of registration
    * @param object event
    * @return object userParams
   */
  authenticate = (status) => {
    let { newsLetterStatus } = this.state
    let phoneRegx = /^(a1-z9){7,15}$/;

    if (status !== undefined) {
      newsLetterStatus = false
    }
   
    this.setState({ newsLetterModalopen: false })
    let firstName = this.state.validObj.firstName
    var lang = this.state.lang
    if (firstName == '') {

      this.setState({
        regsFirstNameErrorSpan: language.language.enter_first_name[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.lastName == '') {
      this.setState({
        regsLastNameErrorSpan: language.language.enter_last_name[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.companyName == '') {
      this.setState({
        regsCompanyNameErrorSpan: language.language.enter_company_name[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.email == '') {
      this.setState({
        regsEmailErrorSpan: language.language.enter_email[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.phone == '' || this.state.validObj.phone.length===0) {
      this.setState({
        regsPhoneErrorSpan: "Please enter your Phone Number", isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.password == '') {
      this.setState({
        regsPasswordErrorSpan: language.language.set_password_msg[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.confirmPassword == '') {
      this.setState({
        regsConfirmPasswordErrorSpan: language.language.confirm_password_msg[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.password != this.state.validObj.confirmPassword) {
      Object.assign(this.state.validObj, { passwordMatchError: true })
      this.setState({
        regsConfirmPasswordErrorSpan: language.language.password_match_error[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.email != '' && !(this.validateMail(this.state.validObj.email))) {
      Object.assign(this.state.validObj, { emailError: true })
      this.setState({
        regsEmailErrorSpan: language.language.Invalid_Email[0][lang], isInputFieldEmpty: true
      })
    } else { Object.assign(this.state.validObj, { emailError: false }) }
    if (this.state.validObj.password != '' && ((this.state.validObj.password).length < 8)) {
      Object.assign(this.state.validObj, { passwordLengthError: true })
      this.setState({
        regsPasswordErrorSpan: language.language.customer_password_length[0][lang]
      })
    } else { Object.assign(this.state.validObj, { passwordLengthError: false }) }
    if ((this.state.validObj.phone).length < 14 && this.state.validObj.phone.length!==0) {
      this.setState({
        regsPhoneErrorSpan: language.language.Invalid_Mobile[0][lang], isInputFieldEmpty: true
      })
      Object.assign(this.state.validObj, { phoneError: true })
    } else { Object.assign(this.state.validObj, { phoneError: false }) }

    if(!this.state.validObj.password.match(phoneRegx) && this.state.validObj.confirmPassword === "") {
      this.setState({regsConfirmPasswordErrorSpan :language.language.Password_Validation[0][this.state.lang] })
      return 0;
  }
    if (this.state.validObj.firstName != "" && this.state.validObj.lastName != "" && this.state.validObj.companyName != "" && this.state.validObj.email != "" && this.state.validObj.phone !== "" && (this.state.validObj.password).length >= 8 && (this.state.validObj.password == this.state.validObj.confirmPassword) && this.validateMail(this.state.validObj.email) && (this.state.validObj.phone).length == 14) {
      let url = window.location.protocol + "//" + window.location.host + "?activate=";
      let userParams = {
        firstName: this.state.validObj.firstName,
        lastName: this.state.validObj.lastName,
        email: this.state.validObj.email,
        password: this.state.validObj.password,
        mobile: this.state.validObj.phone,
        companyName: this.state.validObj.companyName,
        activateUrl: url,
        consent: this.state.check
      }
      if (this.state.check === 1 || newsLetterStatus === false) {
        this.setState({ newsLetterModalopen: false, Loading: true, checkUpdateState: true })
        
        this.props.searchUserAction(userParams)
      } else if (this.state.check === 0 && newsLetterStatus === true) {
        this.setState({
          newsLetterModalopen: true
        })
      }
    } else {
      let invalid = {}
      if (this.state.validObj.username == "") {
        invalid['username'] = language.language.required_field[0]
      }
      if (this.state.validObj.password == "") {
        invalid['password'] = language.language.required_field[0]
      }
      let inValidaData = Object.assign(this.state.invalidObj, invalid)
      this.setState({ invalidObj: inValidaData })
    }
  }

  /**
    * function for sending customer password reset link
   */
  resetPWDLink() {
    if (this.state.forgetvalidObj.email != "") {
      if (this.validateMail(this.state.forgetvalidObj.email)) {
        let userParams = {
          email: this.state.forgetvalidObj.email,
        }

        var list = this.state.customerList
        let resList = _.filter(list, (v) => _.includes(userParams.email, v.email))
        if (resList != "") {
          // let timeSamp = this.currentDateTime();
          let timeSamp = HelperUtil._currentDateTime()
          let encodedData = base64.encode(userParams.email + "&&&" + timeSamp)
          let url = constants.CUSTOMER_PASSWORD_RESET + "?forgetId=" + encodedData
          let userDetails = { url: url, email: userParams.email };
          this.setState({ Loading: true })
          this.props.customerResetPWDAction(userDetails);

        } else {

          this.setState({ flashMessageSuccess: "", flashMessageError: constants.EMAIL_NOT_FOUND })
        }

      } else {
        this.setState({ flashMessageSuccess: "", flashMessageError: language.language.Invali_Email[0][this.state.lang] })
      }

    }
    else {
      let invalid = {}

      this.setState({ flashMessageError: language.language.email_address_req[0][this.state.lang] })
      invalid['password'] = "This Field is required"
      let inValidaData = Object.assign(this.state.forgetinvalidObj, invalid)
      this.setState({ forgetinvalidObj: inValidaData })

    }
  }

  /**
    * function for forgetPassword Email input value
    * @param object event
    * @return object State
   */
  forgetEmail = (event) => {
    event.preventDefault();
    let key = event.target.name
    let value = event.target.value
    let valid = {}
    let invalid = {}
    if (value === "") {
      invalid[key] = "This Field is required"
      valid[key] = ""
    } else {
      invalid[key] = ""
      valid[key] = value
    }
    let inValidaData = Object.assign(this.state.forgetinvalidObj, invalid)
    let validaData = Object.assign(this.state.forgetvalidObj, valid)
    this.setState({ forgetinvalidObj: inValidaData, forgetvalidObj: validaData, flashMessageError: "" })
  }

  resetStates = () => {
    this.setState({
      flashMessageError: '',
      flashMessageSuccess: '',
      isInputFieldEmpty: false,
      isFieldsEmpty: false,
      validObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      invalidObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      emailErrorSpan: "",
      passwordErrorSpan: "",
      regsFirstNameErrorSpan: "",
      regsLastNameErrorSpan: "",
      regsCompanyNameErrorSpan: "",
      regsEmailErrorSpan: "",
      regsPhoneErrorSpan: "",
      regsPasswordErrorSpan: "",
      regsConfirmPasswordErrorSpan: "",
      check: 1,
      newsLetterStatus: true,
    })
  }

  async componentDidMount() {
    if (this.props.searchAndCreateUser.data !== null && this.state.checkUpdateState === true) {
      this.props.searchAndCreateUser.data = null
    }
  }

  closeModalNewsLetter = () => {
    this.setState({
      newsLetterModalopen: false
    })
  }
  


  render() {
    let open = this.props.open || this.state.modalIsOpen
    let openSignup = this.props.openSignup || this.state.signupModalIsOpen
    const { fontSize } = this.props.stateData  
    var validObj = this.state.validObj
    var lang = this.props.stateData.lang
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <p >{this.state.flashMessageSuccess}</p>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <p color='danger'>{this.state.flashMessageError}</p>;

    }
    //error messages for email validations
    let emailErrorSpan = "";
    if (this.state.emailErrorSpan) {
      emailErrorSpan = <span className='validMessage'>{this.state.emailErrorSpan}</span>
    }
    let passwordErrorSpan = "";
    if (this.state.passwordErrorSpan) {
      passwordErrorSpan = <span className='validMessage'>{this.state.passwordErrorSpan}</span>
    }


    let regsFirstNameErrorSpan = <span className='validMessage'>{this.state.regsFirstNameErrorSpan}</span>
    let regsLastNameErrorSpan = <span className='validMessage'>{this.state.regsLastNameErrorSpan}</span>
    let regsCompanyNameErrorSpan = <span className='validMessage'>{this.state.regsCompanyNameErrorSpan}</span>
    let regsEmailErrorSpan = <span className='validMessage'>{this.state.regsEmailErrorSpan}</span>
    let regsPhoneErrorSpan = <span className='validMessage'>{this.state.regsPhoneErrorSpan}</span>
    let regsPasswordErrorSpan = <span className='validMessage'>{this.state.regsPasswordErrorSpan}</span>
    let regsConfirmPasswordErrorSpan = <span className='validMessage'>{this.state.regsConfirmPasswordErrorSpan}</span>

    return (
      <div className="wrapper customerHome">
        {this.state.Loading === true && <div className="loader-react">
          <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
        </div>
        }

        {/* LoginModal */}
        <div className="modal-box " id="">
          <ReactModal 
            isOpen={open} 
            onRequestClose={this.closeModal} 
            closeTimeoutMS={500}
            className="modal-dialog-box " center 
            classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}
            shouldCloseOnOverlayClick={false}
            >
            <div className="modal-content landingcustomemodal ">
              <div className="modal-header giftcart_title_header">
                <h4 className="modal-title giftcart_all_header" style={{ fontSize: fontSize.loginModelButton + 'px' }} >{language != undefined ? language.language.Login[0][lang] : ''}</h4>
                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModal}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
              </div>
              <div className="modal-body ">
                <form>
                  <div className="form-group">
                    {flashSuceessMessageSpan !== "" ?
                      <div className="alert alert-success alert_succesfull clearfix" role="alert">
                        <div className="successbtn"><img src={successimg} alt="" /></div>
                        <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                      </div>

                      : ''}
                    {flashErrorMessageSpan ?
                      <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                        <span><img src={errorimg} alt="" /></span>
                        <p className="clearfix">{flashErrorMessageSpan}</p>
                      </div>
                      : ''}
                    <label htmlFor="email" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language != undefined ? language.language.email_id[0][lang] : ''} </label>
                    <input
                      type="email"
                      className={`form-control ${(validObj.email === "" || validObj.emailError) && this.state.isFieldsEmpty ? "red" : ''}`}
                      id="email"
                      onFocus={(e) => e.target.placeholder = ""}
                      onBlur={(e) => e.target.placeholder = language != undefined ? language.language.email_id[0][lang] : ''}
                      onChange={(e) => this.setValue(e)}
                      required name="email"
                      value={validObj.email ? validObj.email : ''}
                      ref={(e) => this.email = e}
                      placeholder={language != undefined ? language.language.email_id[0][lang] : ''}
                      style={{ fontSize: fontSize.loginModelButton + 'px' }}
                    />
                    {emailErrorSpan ? emailErrorSpan : ""}
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language != undefined ? language.language.Password[0][lang] : ''}</label>
                    <input
                      type="password"
                      className={`form-control ${validObj.password === "" && this.state.isFieldsEmpty ? "red" : ''}`}
                      id="pwd"
                      onFocus={(e) => e.target.placeholder = ""}
                      onBlur={(e) => e.target.placeholder = language != undefined ? language.language.Password[0][lang] : ''}
                      onChange={(e) => this.setValue(e)}
                      required name="password"
                      value={validObj.password ? validObj.password : ''}
                      ref={(e) => this.password = e} 
                      placeholder={language != undefined ? language.language.Password[0][lang] : ''}
                      onKeyPress={(e) => this.submitOnEnter(e)}
                      style={{ fontSize: fontSize.loginModelButton + 'px' }}

                    />
                    {passwordErrorSpan ? passwordErrorSpan : ""}
                  </div>
                  <div className="buttons">
                    <button type="button" 
                      className="giftcard_button mgr10" 
                      onClick={(e) => this.authenticateLogin(e)}
                      style={{ fontSize: fontSize.loginModelButton + 'px' }}

                      >{language != undefined ? language.language.Login[0][lang] : ''}</button>
                    <button 
                      type="button" 
                      className="giftcard_button cardnone_button "
                      onClick={this.openSecondModal} 
                      data-toggle="modal" data-target="#registerModal"
                      style={{ fontSize: fontSize.loginModelButton + 'px' }}

                      >{language != undefined ? language.language.REGISTER[0][lang] : ''}</button>
                  </div>
                  <ul className="forgot-guest-links">
                    <li><a href="javascript:void(0)" 
                      style={{ fontSize: fontSize.loginModelButton + 'px' }}

                     onClick={this.openModalForget}>{language != undefined ? language.language.Forgot_Password[0][lang] : ''}</a></li>
                    <li><a href="javascript:void(0)"
                      style={{ fontSize: fontSize.loginModelButton + 'px' }}
                     onClick={this.closeModal.bind(this)}>{language != undefined ? language.language.Continue_as_a_guest[0][lang] : ''}</a></li>
                  </ul>
                </form>
              </div>
            </div>
          </ReactModal>
        </div>

        {/* SignUp Modal */}
        <div id="">
          <ReactModal
            closeTimeoutMS={500}
            isOpen={openSignup}
            onRequestClose={this.closeSecondModal}
            className="modal-dialog-box " center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}
            shouldCloseOnOverlayClick={false}
          >

            <div className="modal-dialog ">

              <div className="modal-content">

                <div className="modal-header giftcart_title_header">
                  <h4 className="modal-title giftcart_all_header"  style={{ fontSize: fontSize.loginModelButton + 'px' }}>{language.language.REGISTER[0][lang]}</h4>
                  <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeSecondModal}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                </div>
                <div className="modal-body signUp_model">
                  <div className="row">
                    <div className="col-md-12 form-group text-right">
                      <p className="noteplus30 pb-0 m-0 red"  style={{ fontSize: fontSize.loginModelButton + 'px' }}>{language.language.all_fields_mandatory[0][lang]}</p>
                    </div>
                  </div>
                  {flashSuceessMessageSpan !== "" ?
                    <div className="alert alert-success alert_succesfull clearfix" role="alert">
                      <div className="successbtn"><img src={successimg} alt="" /></div>
                      <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                    </div>

                    : ''}
                  {flashErrorMessageSpan ?
                    <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                      <span><img src={errorimg} alt="" /></span>
                      <p className="clearfix">{flashErrorMessageSpan}</p>
                    </div>
                    : ''}
                  <form>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="firstName" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language.language.First_Name[0][lang]}</label>
                          <InputMask
                            id="firstName"
                            type="text"
                            className={`form-control ${validObj.firstName === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                            mask={this.state.nameInputMask}
                            onChange={(e) => this.setValue(e)}
                            maskChar={null}
                            name="firstName"
                            value={validObj.firstName}
                            style={{ fontSize: fontSize.loginModelButton + 'px' }}
                          />
                          {regsFirstNameErrorSpan ? regsFirstNameErrorSpan : ''}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="lastName" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language.language.Last_Name[0][lang]}</label>
                          <InputMask
                            id="lastName"
                            type="text"
                            className={`form-control ${validObj.lastName === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                            mask={this.state.nameInputMask}
                            onChange={(e) => this.setValue(e)}
                            maskChar={null}
                            name="lastName"
                            value={validObj.lastName}
                            style={{ fontSize: fontSize.loginModelButton + 'px' }}
                          />

                          {regsLastNameErrorSpan ? regsLastNameErrorSpan : ''}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="company-name" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language.language.company_name[0][lang]}</label>
                      <input
                        type="text"
                        className={`form-control ${validObj.companyName === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                        id="company-name"
                        onChange={(e) => this.setValue(e)}
                        name="companyName"
                        value={validObj.companyName}
                        ref={(e) => this.companyName = e}
                        maxLength={30}
                        style={{ fontSize: fontSize.loginModelButton + 'px' }}
                      />
                      {regsCompanyNameErrorSpan ? regsCompanyNameErrorSpan : ''}
                    </div>
                    <div className="form-group">
                      <label htmlFor="email-signup" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language.language.Email_ID[0][lang]}</label>
                      <input
                        type="email"
                        className={`form-control ${(validObj.email === "" || validObj.emailError) && this.state.isInputFieldEmpty ? 'red' : ''}`}
                        id="email-signup"
                        onChange={(e) => this.setValue(e)}
                        name="email" value={validObj.email}
                        ref={(e) => this.email = e}
                        maxLength={40}
                        style={{ fontSize: fontSize.loginModelButton + 'px' }}
                      />

                      {regsEmailErrorSpan ? regsEmailErrorSpan : ''}
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language.language.mobile_number[0][lang]}</label>
                      <InputMask
                        className={`form-control ${validObj.phone === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                        id="phone"
                        onChange={(e) => this.setValue(e)}
                        mask="(999) 999-9999"
                        maskChar={null}
                        name="phone"
                        value={validObj.phone}
                        style={{ fontSize: fontSize.loginModelButton + 'px' }}
                      />
                      {regsPhoneErrorSpan ? regsPhoneErrorSpan : ''}
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="pwd-signup" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language.language.Password[0][lang]}</label>
                          <input
                            type="password"
                            className={`form-control ${(validObj.password === "" || validObj.passwordMatchError || validObj.passwordLengthError) && this.state.isInputFieldEmpty ? 'red' : ''}`}
                            id="pwd-signup"
                            onChange={(e) => this.setValue(e)}
                            name="password" value={validObj.password}
                            ref={(e) => this.password = e}
                            maxLength={15}
                            style={{ fontSize: fontSize.loginModelButton + 'px' }}
                          />
                          {regsPasswordErrorSpan ? regsPasswordErrorSpan : ''}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="cnf-pwd-signup" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language.language.confirm_password[0][lang]}</label>
                          <input
                            type="password"
                            className={`form-control ${(validObj.confirmPassword === "" || validObj.passwordMatchError || validObj.passwordLengthError) && this.state.isInputFieldEmpty ? 'red' : ''}`}
                            id="cnf-pwd-signup"
                            onChange={(e) => this.setValue(e)}
                            name="confirmPassword"
                            value={validObj.confirmPassword}
                            ref={(e) => this.confirmPassword = e}
                            onKeyPress={(e) => this.submitOnEnter(e)}
                            style={{ fontSize: fontSize.loginModelButton + 'px' }}
                            
                          />
                          {regsConfirmPasswordErrorSpan ? regsConfirmPasswordErrorSpan : ''}
                        </div>
                      </div>
                    </div>

                    <div className="form-group checkBoxWrapper ">
                      <input type="checkbox" className="checkbox-btn" checked={this.state.check === 1 ? true : false} onClick={this.enableRegistrationButton.bind(this)} />
                      <label className="checkbox-inline-policy label-text"  style={{ fontSize: fontSize.loginModelButton + 'px' }} >
                      {language.language.consent[0][lang]} <span className="purple-color"  style={{ fontSize: fontSize.loginModelButton + 'px' }} onClick={(e) => this.openModalPolicy(e)}>{language.language.consent[1][lang]}</span>
                      </label>
                       {/* <label className="checkbox-inline-policy label-text">
                        I consent to receiving promotional and marketing emails from Northgate Gonzalez, LLC and its affiliates and agree to their <Link to={{pathname:'/privacy-policy', state:{lang:lang}}} target="_blank"><span className="purple-color">Privacy Policy</span></Link>
                      </label> */}
                    </div>

                    <div className="form-group buttonArea">
                      <div className="buttons">
                        <button type="button" style={{ fontSize: fontSize.loginModelButton + 'px' }} className="giftcard_button cardnone_button" onClick={() => this.authenticate()}>{language.language.REGISTER[0][lang]} </button>


                      </div>
                    </div>

                    <ul className="guest-links">
                      <li style={{ fontSize: fontSize.loginModelButton + 'px' }}>{language.language.already_account[0][lang]}<a href="javascript:void(0)"   style={{ fontSize: fontSize.loginModelButton + 'px' }} data-toggle="modal" onClick={(e) => this.openModal(e)} data-target="#myModal"> {language.language.login_here[0][lang]}</a></li>
                    </ul>
                  </form>
                </div>

              </div>
            </div>

          </ReactModal>
        </div>

        {/* ForgetPassword Modal */}
        <div className="modal-box" id="">
          <ReactModal 
            closeTimeoutMS={500} 
            isOpen={this.state.modalForget} 
            onRequestClose={this.closeModalForget}
            className="modal-dialog-box" 
            center 
            classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}
            shouldCloseOnOverlayClick={false}
            >
            <div className="modal-content landingcustomemodal">
              <div className="modal-header giftcart_title_header">
                <h4 className="modal-title giftcart_all_header" style={{ fontSize: fontSize.loginModelButton + 'px' }}>{language.language.Forgot_Password[0][lang]}</h4>
                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModalForget}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
              </div>
              <div className="modal-body">
                <form>
                  {flashSuceessMessageSpan !== "" ?
                    <div className="alert alert-success alert_succesfull clearfix " role="alert">
                      <div className="successbtn"><img src={successimg} alt="" /></div>
                      <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                    </div>

                    : ''}
                  {flashErrorMessageSpan ?
                    <div className="alert alert-success alert_succesfull adpadding30 clearfix " role="alert">
                      <span><img src={errorimg} alt="" /></span>
                      <p className="clearfix">{flashErrorMessageSpan}</p>
                    </div>
                    : ''}
                  <div className="form-group">
                    <label htmlFor="email" style={{ fontSize: fontSize.loginModalLabel + 'px' }}>{language.language.email_id[0][lang]}</label>
                    <input  style={{ fontSize: fontSize.loginModelButton + 'px' }}
                    type="email" className="form-control" id="email-forget-password" onChange={(e) => this.forgetEmail(e)} name="email" required onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = language.language.email_id[0][lang]} ref={(e) => this.email = e} placeholder={language.language.email_id[0][lang]} onKeyPress={(e) => this.submitOnEnter(e)} />
                  </div>
                  <div className="buttons">
                    <button type="button"
                    style={{ fontSize: fontSize.loginModalLabel + 'px' }}
                    onClick={(e) => this.resetPWDLink()} className="btn btn-default login-btn" >{language.language.Resend_Password[0][lang]}</button>
                    <button type="button" 
                    style={{ fontSize: fontSize.loginModalLabel + 'px' }}
                    className="btn btn-default register-btn" onClick={this.closeModalForget}>{language.language.CANCEL[0][lang]}</button>
                  </div>

                </form>
              </div>
            </div>
          </ReactModal>
        </div>

     
                {/**Policy modal open */}
                <div className="modal-box" id="">
          <ReactModal 
            closeTimeoutMS={500} 
            isOpen={this.state.policyModalopen} 
            onRequestClose={this.closeModalPolicy} 
            className="modal-dialog-box fullModal"
            center 
            classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel modalPolicy' }}
            shouldCloseOnOverlayClick={false}
            >
            <div className="modal-content">
            <div className="modal-header polcy-header">
                <h4 className="modal-title"></h4>
                <button className="styles_closeButton__20ID4 closemodalbtn pol-close" onClick={this.closeModalPolicy}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
              </div>
              <div className="modal-body newsseventall container privacy-body">
                <form>
                  <div className="row">
                    {/* <p> Consent to receiving promotional and marketing emails from Northgate Gonzalez, LLC and its affiliates and agree to their privacy policy</p> */}
                   <Consent lang={lang} /> 
                    
                    <div className="formbtn pol-btn">
                      <button type="button" className="giftcard_button mgr10 pull-right" onClick={this.closeModalPolicy}>{language.language.CLOSE[0][lang]}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ReactModal>
        </div>
        {/**Policy modal end */}


        {/**News Letter modal open */}
        <div className="modal-box" id="">
		  <ReactModal 
		  	closeTimeoutMS={500} isOpen={this.state.newsLetterModalopen} onRequestClose={this.closeModalNewsLetter} className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel modalPolicy' }}
			  shouldCloseOnOverlayClick={false}
			>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Confirm</h4>
                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModalNewsLetter}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
              </div>
              <div className="modal-body News-letter">
                <form>

                  <div className="row">
                    <div className="newsletter-text">
                      <p>Are you sure, you do not want to hear from us?</p>
                    </div>
                  </div>

                  <hr id="full-hr" />
                  <div className="newsletter-button">
                    <button type="button" className="giftcard_button mgr10" onClick={this.authenticate.bind(true)}>I Agree</button>
                    <button type="button" className="giftcard_button mgr10" onClick={this.closeModalNewsLetter.bind(this)}>Cancel</button>

                  </div>
                </form>
              </div>
            </div>
          </ReactModal>
        </div>
        {/**News Letter modal end */}
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    customerLoginInfo: state.customerLogin,
    customerRegisterInfo: state.customerRegister,
    customerList: state.customerList,
    resetCustomerPWDResponse: state.resetCustomerPWD,
    activateCustomerResponse: state.activateCustomer,
    searchAndCreateUser: state.searchAndCreateUser,
    sendOtp: state.sendOtp,
    cardTypeListData: state.cardTypeList,
    viewHomeSettings: state.viewHomeSettings,
  }
}

export default connect(mapStateToProps, { customerListAction, customerResetPWDAction, customerRegisterAction, customerLoginAction, activateCustomerAction, denominationListAction, sendOTPAction, getCardTypeAction, searchUserAction })(LoginModal);