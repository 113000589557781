import * as constants from './../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';

/*
* Function for resettingthe user password
  @param NULL
  @return object object of api resoponse
*/
export function customerPasswordResetAction(userData) {
  return (dispatch) => {
    let url = serverRoutes.SET_RESET_PASSWORD;
    axios.post(url, userData)
      .then(function (response) {
        dispatch({
          type: constants.CUSTOMER_RESET_PASSWORD_DATA,
          payload: response.data.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CUSTOMER_RESET_PASSWORD_DATA_ERROR,
          payload: response.data
        })
      });
  }
}
