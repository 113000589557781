
import React from 'react';
import Modal from 'react-modal';
import * as HelperUtil from "../../../utils/common-helper";
import moment from "moment";

import ReactToPrint from "react-to-print";
import { Button } from 'react-bootstrap/lib/InputGroup';
import printIcon from '../../../public/img/print-icon.png';
import noImage from '../../../public/img/no-image.png';
import Close from '../../../public/img/close-button.png';

import * as constant from "../../../Constants/";
//import { signedCookie } from 'cookie-parser';
const Invoice=({openInvoiceModal,onPrintClick,orderDetails})=>{
  const invoiceRef = React.useRef(null);
return(
    <div>
     
    <Modal
      isOpen={openInvoiceModal}
      //onAfterOpen={afterOpenModal}
      onRequestClose={()=>{onPrintClick(false)}}
    >
      <div  ref={invoiceRef}  style={{ background: '#fff', padding: '10px 20px'}}>
        {/* <a className='closed noPrint' onClick={()=>{onPrintClick(false)}}><img src={Close} /></a> */}
        <div  style={{backgroundColor: '#0a503a', padding:'16px'}}>
         <img src="/static/media/logo.2eca5742.png" alt="" />
        </div>

        <h1 style={{ margin:'10px 0', padding:'10px 0', fontSize: '24px', fontWeight: '900', alignItems:'center', display: 'flex', justifyContent:'space-between', borderBottom: '1px solid #eee', }}>
        Invoice
        <ReactToPrint
        content={()=>invoiceRef.current}
        onAfterPrint={()=>{onPrintClick(false)}}
        trigger={()=><a className='noPrint' style={{fontSize: '16px',}}><img src={printIcon} height="20px" width="20px" /> <span style={{color: '#007bff'}}>Print</span></a>}
      />
        </h1>

        <div>
        <h3 style={{ fontSize: '16px', fontWeight: '900'}}>INVOICE NUMBER</h3>
        <p>{orderDetails.orderNumber}</p>
        <h3 style={{ fontSize: '16px', fontWeight: '900'}}>INVOICE DATE</h3>
        <p>
          {moment(
            new Date(orderDetails.orderDate)
          ).format("MM/DD/YYYY")}
        </p>
        </div>

        <table width='100%' className='tabled'>
          <thead>
            <th width="100px"></th>
            <th>Item Name</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th align='right' style={{textAlign: 'right'}}>Amount</th>
          </thead>
          <tbody>
          {
            orderDetails.orderedProduct.length>0 ? orderDetails.orderedProduct.map(item=>{
              return(
                <>
                <tr>
                  <td>   <img
                                            src={
                                              (constant.APIURL +
                                              item.imagePath +
                                              item.imageName) ? constant.APIURL +
                                              item.imagePath +
                                              item.imageName : noImage
                                            }
                                            className="media-object"
                                            alt=""
                                            style={{width: '80px', height: 'auto'}}
                                          />
                                          </td>

                    <td>{item.productType}</td>
                  <td>{item.noOfCards}</td>
                  <td>${item.productPrice}</td>
                  <td align='right'><b>${item.noOfCards * item.productPrice}</b></td>
                </tr>
                  
                </>
              )
            }): ""
          }
          <tr>
                  <td colSpan="2" className='bordered'></td>
                  <td colSpan="2"><b>Sub Total</b></td>
                  <td align='right'><b>${" "}
                                      {orderDetails.subTotal != ""
                                        ? HelperUtil._getAmountCommaSeparator(
                                            Number(
                                              orderDetails.subTotal
                                            ).toFixed(2)
                                          ).toString()
                                        : "0.00"}</b>
                  </td>
          </tr>
          <tr>
                  <td colSpan="2" className='bordered'></td>
                  <td colSpan="2"><b>Discount</b></td>
                  <td align='right'><b>-${" "}
                                      {orderDetails.discountAmount != ""
                                        ? HelperUtil._getAmountCommaSeparator(
                                            orderDetails.discountAmount.toFixed(
                                              2
                                            )
                                          ).toString()
                                        : "0.00"}</b>
                  </td>
          </tr>
          <tr>
                  <td colSpan="2" className='bordered'></td>
                  <td colSpan="2"><b>Shipping Charges</b></td>
                  <td align='right'><b>+${" "}
                                      {orderDetails.shippingAmount != "" &&
                                      orderDetails.shippingAmount !== null
                                        ? HelperUtil._getAmountCommaSeparator(
                                            orderDetails.shippingAmount.toFixed(
                                              2
                                            )
                                          ).toString()
                                        : "0.00"}</b>
                  </td>
          </tr>
          <tr>
                  <td colSpan="2" className='bordered'></td>
                  <td colSpan="2"><b>Total Amount</b></td>
                  <td align='right'><b style={{fontSize: '30px'}}>${" "}
                                  {HelperUtil._getAmountCommaSeparator(
                                    Number(orderDetails.totalAmount).toFixed(2)
                                  ).toString()}</b>
                  </td>
          </tr>
          </tbody>
        </table>
        <table width="100%" className='tableer'>
          <tr>
            <td style={{borderRight: '1px solid #eee'}}>
            <h3 style={{textTransform: 'uppercase'}}>{orderDetails.customerName}</h3>
                            <address>
                              {orderDetails.shippingAddress1 != "" ||
                              orderDetails != undefined
                                ? orderDetails.shippingAddress1 +
                                  ", " +
                                  orderDetails.shippingCity +
                                  ", " +
                                  orderDetails.shippingState +
                                  ", " +
                                  orderDetails.shippingCountry +
                                  ", " +
                                  orderDetails.shippingZip
                                : ""}
                            </address>
            </td>
            <td style={{borderRight: '1px solid #eee'}}>
            <h3 style={{textTransform: 'uppercase'}}>Shipment Type </h3>
                            <p>
                              {orderDetails.shippingType != ""
                                ? orderDetails.shippingType
                                : "Normal"}
                            </p>
            </td>
            <td>
            <h3 style={{textTransform: 'uppercase'}}>Expected Delivery Date</h3>
                            <p>
                              {moment(
                                new Date(orderDetails.expectedShippingDate)
                              ).format("MM/DD/YYYY")}
                            </p>
            </td>
          </tr>
        </table>

        <table width="100%" style={{fontSize: '12px'}}>
          <tr>
            <td>&copy;2024 | Northgate Gonzalez, All Rights Reserved</td>
            <td align='right'>This is a system generated invoice. No signature required.</td>
          </tr>
        </table>

        
      </div>
      <table width="100%">
          <tr>
            <td align='right'><button style={{ marginRight:'5px', fontSize: '14px', marginTop: '20px'}}  className="giftcard_button add_btnfont pull-right button-padding" onClick={()=>{onPrintClick(false)}}>Close</button></td>
          </tr>
        </table>                
    </Modal>
  </div>
)
}

export default Invoice;