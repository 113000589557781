
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal';
import { shippmentListAction,removePropsAction, shippmentTypeAction, addShippmentAction, editShippmentAction, deleteShippmentAction } from '../../../Actions/Admin/shippingManagementAction';
import { Alert } from 'reactstrap';
import 'react-input-range/lib/css/index.css';
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert';
import InputMask from 'react-input-mask'
import ReactLoading from 'react-loading'
import * as constant from '../../../Constants/';


/**
 * Class for AddEdit shipment for Admin
 */
class AddEditShipment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            shipmentObj: {
                shipmentType: '',
                shipmentRangeFrom: '1',
                shipmentRangeTo: '2',
                shipmentTimeFrom: '1',
                shipmentTimeTo: '2',
                price: '',
                description: '',
                id: ''
            },
            shipmentRange: { min: '', max: '' },
            shipmentTime: { min: '', max: '' },
            shippmentTypes: {},
            shippmentList: [],
            flashMessageError: '',
            flashMessageSuccess: '',
            showTable: false,
            tableList: [],
            shipmentData: [],
            editObj: {
                check: false,
                shipmentDataIndex: ''
            },
            shipmentRangeValue: { min: '', max: '' },
            shipmentTimeValue: { min: '', max: '' },
            Loading: false,
            checkUpdateState: false,
            disableSaveButton: false,
            otherShipmentType: 'Other',
            otherShipmentInput: false,
            OtherTypeExist: false
        }
    }

    /* Function for recieve props after add user */
    componentWillReceiveProps(nextProps) {
        try {
            let self = this
            if (nextProps.shippmentType && nextProps.shippmentType.data) {
                this.setState({
                    shippmentTypes: nextProps.shippmentType.data.data.data
                })
            }
            console.log('--------nextProps-----', nextProps);
            if (nextProps.addShippment && nextProps.addShippment.data) {
                
                if (nextProps.addShippment.data.data.hasOwnProperty('status') && nextProps.addShippment.data.data.status === true) {
                    this.setState({
                        flashMessageSuccess: nextProps.addShippment.data.data.message,
                        shipmentObj: {
                            shipmentType: this.state.shipmentObj.shipmentType
                        },
                        shipmentData: [],
                        addShippment:{}
                    })
                    if (self.state.checkUpdateState) {
                        setTimeout(async function () {
                            self.setState({
                                Loading: false,
                                flashMessageSuccess: '',
                                flashMessageError: '',
                                checkUpdateState: false,
                                otherShipmentInput: false
                            })
                            await nextProps.getShipmentList(sessionStorage.userID);
                            await nextProps.shippmentTypeAction();
                            nextProps.modalClose();
                            nextProps.locationProps.history.push("/ng-admin/shipmanagement")
                        }, 5000);
                    } else {
                        self.setState({
                            Loading: false,
                            flashMessageSuccess: '',
                            flashMessageError: '',
                            checkUpdateState: false
                        })
                    }
                } else {
                    this.setState({ flashMessageError: nextProps.addShippment.data.data.message })
                }
                this.props.removePropsAction();
            }
            if (nextProps.shippmentList && nextProps.shippmentList.data) {
                this.setState({
                    shippmentList: nextProps.shippmentList.data.data.data,
                })
            }
            if (nextProps.shippingDetails) {
                const shippingDetails = nextProps.shippingDetails
                if (this.state.editObj.check) {
                } else {
                    this.setState({
                        shipmentObj: {
                            shipmentType: shippingDetails.shippingTypeId,
                            price: shippingDetails.shippingRate,
                            description: shippingDetails.description,
                            id: shippingDetails.id
                        },
                        shipmentRange: {
                            min: shippingDetails.startRange,
                            max: shippingDetails.endRange,
                        },
                        shipmentRangeValue: {
                            min: shippingDetails.startRange,
                            max: shippingDetails.endRange,
                        },
                        shipmentTime: {
                            min: shippingDetails.startTime,
                            max: shippingDetails.endTime,
                        },
                        shipmentTimeValue: {
                            min: shippingDetails.startTime,
                            max: shippingDetails.endTime,
                        },
                        flashMessageError: '',
                        flashMessageSuccess: '',
                        showTable: false
                    })
                }
            }
            else {
                if (this.state.editObj.check) {
                } else {
                    this.setState({
                        shipmentObj: {
                            shipmentType: '',
                            price: '',
                            description: '',
                            id: ''
                        },
                        shipmentRange: {
                            min: '',
                            max: '',
                        },
                        shipmentTime: {
                            min: '',
                            max: '',
                        },
                        flashMessageError: '',
                        showTable: false
                    })
                }
            }
            if (nextProps.editShippment && nextProps.editShippment.data) {
                if (nextProps.editShippment.data.data.hasOwnProperty('status') && nextProps.editShippment.data.data.status === true) {
                    if (self.state.checkUpdateState) {
                        self.setState({ flashMessageSuccess: nextProps.editShippment.data.data.message })
                        setTimeout(async function () {
                            self.setState({
                                Loading: false,
                                checkUpdateState: false,
                                flashMessageSuccess: "",
                                flashMessageError: '',
                                otherShipmentInput: false
                            })
                            await nextProps.getShipmentList(sessionStorage.userID)
                            nextProps.locationProps.history.push("/ng-admin/shipmanagement")
                            nextProps.shippmentTypeAction();
                            nextProps.modalClose();
                        }, 5000);
                    } else {
                        self.setState({
                            Loading: false,
                            checkUpdateState: false,
                            flashMessageSuccess: "",
                            flashMessageError: ''
                        })
                    }
                } else {
                    this.setState({ Loading: false, flashMessageError: nextProps.editShippment.data.data.message })
                }
            }
        } catch (e) { }
    }

    componentWillMount() {
        this.props.shippmentListAction(sessionStorage.userID);
        this.props.shippmentTypeAction();
    }

    /**
     * Function for closing Addeditshipment Modal
    * 
    */
    onCloseModal = () => {
        this.props.modalClose();
        this.state.shipmentData.length = ''
        this.setState({
            editObj: {
                check: false,
                shipmentDataIndex: ''
            },
            tableList: [],
            otherShipmentInput: false,
            OtherTypeExist: false
        })
    }

    /**
    * function for add/edit shipment details
    * @param object e
    * @return object state
    */
    setValue(e) {
        let val = e.target.value;
        let key = e.target.name;
        let valid = {};
        if (key == "price" && !this.validate(val)) {
            return;
        }
        else if (val === '') {
            valid[key] = '';
        } else {
            valid[key] = val;
        }
        let shipmentObj = Object.assign(this.state.shipmentObj, valid);
        this.setState({
            shipmentObj: shipmentObj,
            flashMessageError: ''
        });
    }

    /**
     * function to submit the data or save the shipment details 
     */
    async shipmentSubmitHandler(e) {
        let self = this;
        debugger;
        if (this.state.shipmentObj.id == '' || this.state.shipmentObj.id == undefined) {
            if (this.showList(e,'save') && this.state.shipmentData.length > 0) {
                let shippingDataArray = [];
                this.state.shipmentData.map((shipmentObj) => {
                    var data = {
                        "shippingTypeId": shipmentObj.shipmentType,
                        "startRange": shipmentObj.startRange,
                        "endRange": Boolean(shipmentObj.endRange) ? shipmentObj.endRange : 0,
                        "startTime": shipmentObj.startTime,
                        "endTime": shipmentObj.endTime,
                        "shippingRate": parseFloat(shipmentObj.price),
                        "description": shipmentObj.description,
                        "status": 1,
                        "userId": sessionStorage.userID,
                        "loggedInUser": sessionStorage.userID,
                        "language": "en",
                    }
                    shippingDataArray.push(data);
                })
                var dataToSubmit = {
                    "loggedInUser": sessionStorage.userID,
                    "language": "en",
                    "shippingData": shippingDataArray
                }
                this.setState({
                    Loading: true,
                    checkUpdateState: true,
                })
                await this.props.addShippmentAction(dataToSubmit)
            }
        } else if (this.state.shipmentObj.id != '') {

            let { shipmentObj } = this.state
            let { shipmentRange } = this.state
            let { shipmentTime } = this.state
            let filteredList = []
            if (shipmentObj.description != '' && shipmentObj.price !== '' && shipmentObj.shipmentType !== '' && !isNaN(shipmentRange.min) && !isNaN(shipmentTime.max) && !isNaN(shipmentTime.min)) {
                filteredList = _.filter(this.state.shippmentList, (o) => {
                    if (shipmentObj.id !== o.id && o.shippingTypeId === Number(this.state.shipmentObj.shipmentType)) {
                        return o
                    }
                })
                if (shipmentObj.description != '' && !isNaN(shipmentObj.price) != '' && shipmentObj.shipmentType != '' && !isNaN(shipmentRange.min) && !isNaN(shipmentTime.max) && !isNaN(shipmentTime.min)) {
                    var data = {
                        "id": shipmentObj.id,
                        "shippingTypeId": shipmentObj.shipmentType,
                        "startRange": shipmentRange.min,
                        "endRange": Boolean(shipmentRange.max) ? shipmentRange.max : 0,
                        "startTime": shipmentTime.min,
                        "endTime": shipmentTime.max,
                        "shippingRate": parseFloat(shipmentObj.price),
                        "description": shipmentObj.description,
                        "status": 1,
                        "loggedInUser": sessionStorage.userID,
                        "language": "en",
                        "userId": sessionStorage.userID
                    }
                    let valid = true;
                    filteredList.map((shipmentData) => {
                        if (
                            (shipmentRange.max !== 0 && shipmentData.endRange !== 0 && shipmentData.startRange <= shipmentRange.min && shipmentData.endRange >= shipmentRange.min)
                            || (shipmentRange.max !== 0 && shipmentData.endRange !== 0 && shipmentData.startRange <= shipmentRange.max && shipmentData.endRange >= shipmentRange.max)
                            || (shipmentRange.max !== 0 && shipmentData.endRange !== 0 && shipmentData.startRange > shipmentRange.min && shipmentData.endRange < shipmentRange.max)
                            || ((shipmentRange.max === "" || isNaN(shipmentRange.max)) && shipmentData.startRange > shipmentRange.min)
                            || (shipmentData.endRange === 0 && shipmentRange.min > shipmentData.startRange)
                            || ((shipmentRange.max === "" || isNaN(shipmentRange.max)) && shipmentData.endRange > shipmentRange.min)
                            || (shipmentData.endRange === 0 && shipmentRange.max > shipmentData.startRange)
                        ) {
                            self.setState({
                                flashMessageError: 'Not a valid range'
                            })
                            valid = false;
                            return 0;

                        }
                    })
                    if (shipmentTime.min > shipmentTime.max) {
                        this.setState({
                            flashMessageError: "Not a valid time range"
                        })
                        valid = false;
                        return 0;
                    }
                    if (valid) {
                        this.setState({
                            Loading: true,
                            checkUpdateState: true,
                        })
                        await this.props.editShippmentAction(data)
                    }
                } else {
                    alert('dkvbhdb');
                    this.setState({
                        flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
                    })
                    return 0;
                }
            }
        }
    }


    /**
    * function to check the range 
    * @param {*} x existing from range
    * @param {*} y existing to range
    * @param {*} a entered from range
    * @param {*} b existing to range
    */
    isValidRange(x, y, a, b) {
        if (a < x && b < x) {
            return true
        }
        else
            if (a > y && b > y) {
                return true
            }
            else
                return false
    }

    /**
     * Function for showList through Add button
    * 
    */
    showList(e,action = 'Add') {
        var addShippmentList = {
            description: '',
            endRange: '',
            endTime: '',
            shippingRate: '',
            startRange: '',
            startTime: '',
            shippingTypeId: this.state.shipmentObj.shipmentType
        }
        let { shipmentObj } = this.state
        let { editObj } = this.state
        let { shipmentRange } = this.state
        let { shipmentTime } = this.state
        let self = this;
        var filteredList = _.filter(this.state.shippmentList, (o) => {
            if (o.shippingTypeId === Number(this.state.shipmentObj.shipmentType)) {
                return o
            }
        })
        console.log('-----filteredList----', filteredList)
        if (this.state.shipmentObj.shipmentType.toLocaleLowerCase() !== "other") {
            if (shipmentObj.description !== '' && shipmentObj.price !== '' && shipmentObj.shipmentType !== '' && !isNaN(shipmentRange.min) && !isNaN(shipmentTime.max) && !isNaN(shipmentTime.min)) {
                addShippmentList['description'] = shipmentObj.description
                addShippmentList['endRange'] = Boolean(shipmentRange.max) ? shipmentRange.max : 0
                addShippmentList['endTime'] = shipmentTime.max
                addShippmentList['shippingRate'] = Number(shipmentObj.price)
                addShippmentList['startRange'] = shipmentRange.min
                addShippmentList['startTime'] = shipmentTime.min
                addShippmentList['shipmentType'] = shipmentObj.shipmentType
                addShippmentList['price'] = shipmentObj.price
                let notValid = true;
                filteredList.map((shipmentData, index) => {
                    if (
                        (shipmentData.endRange !== 0 && shipmentRange.max !== "" && shipmentData.startRange <= shipmentRange.min && shipmentData.endRange >= shipmentRange.min)
                        || (shipmentData.endRange !== 0 && shipmentRange.max !== "" && shipmentData.startRange <= shipmentRange.max && shipmentData.endRange >= shipmentRange.max)
                        || (shipmentData.endRange !== 0 && shipmentRange.max !== "" && shipmentData.startRange > shipmentRange.min && shipmentData.endRange < shipmentRange.max)
                        || ((shipmentRange.max === "" || isNaN(shipmentRange.max)) && shipmentData.startRange >= shipmentRange.min)
                        || (shipmentData.endRange === 0 && shipmentRange.min >= shipmentData.startRange)
                        || ((shipmentRange.max === "" || isNaN(shipmentRange.max)) && shipmentData.endRange >= shipmentRange.min)
                        || (shipmentData.endRange === 0 && shipmentRange.max >= shipmentData.startRange)
                    ) {
                        self.setState({
                            flashMessageError: 'Not a valid range'
                        })
                        notValid = false;
                        return 0;

                    }
                })
                this.state.shipmentData.map((shipmentData, index) => {
                    console.log('------------shipmentData-------', shipmentData)
                    console.log('------------shipmentRange-------', shipmentRange)
                    if (!editObj.check || index !== editObj.shipmentDataIndex) {
                        if (
                            (shipmentData.endRange !== 0 && shipmentRange.max !== "" && shipmentData.startRange <= shipmentRange.min && shipmentData.endRange >= shipmentRange.min)
                            || (shipmentData.endRange !== 0 && shipmentRange.max !== "" && shipmentData.startRange <= shipmentRange.max && shipmentData.endRange >= shipmentRange.max)
                            || (shipmentData.endRange !== 0 && shipmentRange.max !== "" && shipmentData.startRange > shipmentRange.min && shipmentData.endRange < shipmentRange.max)
                            || ((shipmentRange.max === "" || isNaN(shipmentRange.max)) && shipmentData.startRange >= shipmentRange.min)
                            || (shipmentData.endRange === 0 && shipmentRange.min >= shipmentData.startRange)
                            || ((shipmentRange.max === "" || isNaN(shipmentRange.max)) && shipmentData.endRange >= shipmentRange.min)
                            || (shipmentData.endRange === 0 && shipmentRange.max >= shipmentData.startRange)

                        ) {
                            self.setState({
                                flashMessageError: 'Not a valid range'
                            })
                            notValid = false;
                            return 0;

                        }
                    }
                })
                if (shipmentTime.min > shipmentTime.max) {
                    this.setState({
                        flashMessageError: "Not a valid time range"
                    })
                    return 0;
                }
                if (notValid) {
                    if (editObj.check) {
                        this.state.shipmentData.splice(editObj.shipmentDataIndex, 1)
                    }
                    console.log('--------this.state.shipmentData-----------',this.state.shipmentData);
                    this.state.shipmentData.push(addShippmentList)
                    var shippmentList = this.state.shipmentData
                    var shippmentType = this.state.shipmentObj.shipmentType
                    this.setState({
                        showTable: true,
                        tableList: shippmentList,
                        flashMessageSuccess: '',
                        flashMessageError: '',
                        shipmentObj: {
                            price: '',
                            description: '',
                            id: '',
                            shipmentType: shippmentType,
                        },
                        shipmentTime: {
                            min: '',
                            max: ''
                        },
                        shipmentRange: {
                            min: '',
                            max: ''
                        },
                        editObj: {
                            check: false,
                            shipmentDataIndex: ''
                        },
                        disableSaveButton: false
                    })
                    return 1;
                }
            } else {
                if (action !== 'Add') {
                    if (this.state.shipmentData.length==0 || shipmentObj.description !== '' || shipmentObj.price !== '' || (!isNaN(shipmentRange.min) && shipmentRange.min!=='') || (!isNaN(shipmentTime.max) && shipmentTime.max !== '') || (!isNaN(shipmentTime.min) && shipmentTime.min !== '')) {
                        this.setState({
                            flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
                        })
                        return 0;
                    }
                }else{
                    this.setState({
                        flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
                    })
                    return 0;
                }
                return 1;
            }
        } else {
            this.setState({
                flashMessageError: "Shipment type name \"Other\" not allowed, Please enter a different name."
            })
            setTimeout(() => {
                this.setState({
                    flashMessageError: ""
                })
            }, 3000);
            return 0
        }
    }

    /**
    * Function to delete the shippment
    */
    deleteShipping(e) {
        var deleteIndex = ''
        this.state.shipmentData.map((shipmentData, index) => {
            if (e.startRange == shipmentData.startRange) {
                var i = index
                this.state.shipmentData[i].startRange = e.startRange
                deleteIndex = i
            }
        })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm-popup AddUserModelform addUserModelformDelete">
                        <div className="modal-header giftcart_title_header"><h3 className="modal-title giftcart_all_header"> Are you sure?</h3></div>
                        <div className="modal-body">
                            <p>You want to delete the shipping</p>
                            <div className="formbtn">
                                <button type="button" className="giftcard_button mgr10"
                                    onClick={() => {
                                        (this.state.shipmentData).splice(deleteIndex, 1)
                                        if ((this.state.shipmentData).length) {
                                            var shippmentList = this.state.shipmentData
                                            var shippmentType = this.state.shipmentObj.shipmentType
                                            var filteredList = _.filter(shippmentList, function (o) { return o.shippingTypeId == shippmentType })
                                            this.setState({
                                                tableList: filteredList,
                                            })
                                        } else {
                                            this.setState({
                                                showTable: false,
                                            })
                                        }
                                        onClose();
                                    }}
                                >
                                    Confirm
                                </button>
                                <button type="button" onClick={onClose} className="giftcard_button cardnone_button">No</button></div>
                        </div>
                    </div>
                );
            }
        })
    }

    /**
     * Function to Edit the shippment
     */
    editShipping(e) {
        let self = this
        this.setState({
            disableSaveButton: true
        })
        console.log('----------start-------------',self.state.shipmentData);
        self.state.shipmentData.map((shipmentData, index) => {
            if (e.startRange === shipmentData.startRange) {
                var i = index
                self.setState({
                    editObj: {
                        check: true,
                        shipmentDataIndex: i
                    },
                    shipmentObj: {
                        shipmentType: e.shippingTypeId,
                        price: e.shippingRate,
                        description: e.description,
                        id: e.shippingId
                    },
                    shipmentRange: {
                        min: e.startRange,
                        max: e.endRange,
                    },
                    shipmentTime: {
                        min: e.startTime,
                        max: e.endTime,
                    },
                })
            }
        })
        console.log('----------end-------------',self.state.shipmentData)
    }

    /**
     * Function to Shipment range input slider onchange
     */
    setShipmentRange(e) {
        this.setState({
            flashMessageError: ""
        })
        let val = e.target.value;
        let key = e.target.name;
        let data = {}
        if (key == 'min') {
            data[key] = parseInt(val)
        }
        else {
            data[key] = parseInt(val)
        }
        let validData = Object.assign(this.state.shipmentRange, data)
        this.setState({ shipmentRangeValue: validData })
    }

    /**
     * Function for Shipment range input slider onblur
     */
    setShipmentRangeValue(e) {
        let { shipmentRangeValue } = this.state
        if (shipmentRangeValue.min > 0 && shipmentRangeValue.max > 0) {
            if (shipmentRangeValue.min > shipmentRangeValue.max) {
                this.setState({
                    flashMessageError: "Not a valid range"
                })
            }
        }
        else if (isNaN(shipmentRangeValue.min) && isNaN(shipmentRangeValue.max)) {
            this.setState({
                flashMessageError: "Range can't be blank"
            })

        } else {
            this.setState({ shipmentRange: shipmentRangeValue })
        }
    }

    /**
     * Function for Shipment time input slider onChange
     * @param {*} e 
     */
    setShipmentTime(e) {
        this.setState({
            flashMessageError: ""
        })
        let val = e.target.value;
        let key = e.target.name;
        let data = {}
        if (key == 'min') {
            data[key] = parseInt(val)
        }
        else {
            data[key] = parseInt(val)
        }
        let validData = Object.assign(this.state.shipmentTime, data)
        this.setState({ shipmentTimeValue: validData })
    }

    /**
     * Function for Shipment time input slider onBlue
     * @param {*} e 
     */
    setShipmentTimeValue(e) {
        let { shipmentTimeValue } = this.state
        if (shipmentTimeValue.min > 0 && shipmentTimeValue.max > 0) {
            if (shipmentTimeValue.min > shipmentTimeValue.max) {
                this.setState({
                    flashMessageError: "Not a valid time range"
                })
            }
        }
        else if (isNaN(shipmentTimeValue.min) && isNaN(shipmentTimeValue.max)) {
            this.setState({
                flashMessageError: "Shipment Time can't be blank"
            })

        } else {
            this.setState({ shipmentTime: shipmentTimeValue })
        }
    }

    /**
     * Function for validation for price input
     */
    validate(val) {
        var rgx = /^[0-9]*\.?[0-9]*$/;
        if (val.match(rgx)) {
            return true
        }
    }

    /**
     * Function for validate 0 input for endRange
     */
    isValidEndRange(maxAmount, startRange, endRange, listStartRange, listEndRange) {
        let valid = []
        if (listEndRange == 0 && maxAmount != 0) {
            if (startRange <= maxAmount && maxAmount <= endRange || endRange == 0) {
                valid.push(false)
                valid.push(1)
            }
            else if (maxAmount <= startRange) {
                valid.push(false)
                valid.push(2)
            }
        }
        else if (maxAmount == 0 && endRange == 0 && startRange <= listStartRange) {
            valid.push(false)
            valid.push(3)
        }
        valid.push(this.isValidRange(listStartRange, listEndRange, startRange, endRange ? endRange : startRange < listEndRange ? listEndRange : startRange))
        valid.push(4)
        return valid;
    }

    /**
    * function to add Other ShipmentType
    * @param object e
    * @return object state
    */
    addOtherShipmentType = (e) => {
        try {
            this.setState({ flashMessageError: '' })
            let { shipmentObj, shippmentTypes } = this.state
            let typcheck = shippmentTypes.length ? shippmentTypes.map((type) => {
                return type.shippingType === e.target.value ? true : false
            }) : false
            if (typcheck.includes(true)) {
                this.setState({ flashMessageError: 'Shipment type already exist', OtherTypeExist: true })
                return;
            } else {
                this.setState({
                    shipmentObj: {
                        description: shipmentObj.description,
                        id: shipmentObj.id,
                        price: shipmentObj.price,
                        shipmentType: e.target.value.trim(),
                    },
                    OtherTypeExist: false
                })
            }

        } catch (err) { console.log("error in add Other Shippement ", err) }

    }

    /**
    * function for add/edit shipment Type
    * @param object e
    * @return object state
    */
    setShipmentType(e) {
        let val = e.target.value;
        let key = e.target.name;
        let valid = {};
        if (val == 'Other') {
            this.setState({
                otherShipmentInput: true
            })
        } else {
            this.setState({
                otherShipmentInput: false,
                OtherTypeExist: false
            })
        }
        if (val === '') {
            valid[key] = '';
        } else {
            valid[key] = val;
        }
        let shipmentObj = Object.assign(this.state.shipmentObj, valid);
        this.setState({
            shipmentObj: shipmentObj,
            flashMessageError: ''
        });
    }

    async componentDidUpdate() {
        if (this.state.disableSaveButton) {
            if (this.state.editObj.check === false) {
                await this.setState({
                    disableSaveButton: false
                })
            }
        }
    }

    render() {
        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
        }
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
        }
        console.log('--------this.state.shipmentData-----------',this.state.shipmentData);
        return (
            <Modal open={this.props.checkModal} onClose={this.onCloseModal} center classNames={{ overlay: 'discountOverlay', modal: 'discountModel' }}
                closeOnOverlayClick={false}
            >
                {
                    this.state.Loading === true && <div className="loader-react">
                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                    </div>
                }
                <div className="modal-dialog  shipp-form-content">
                    <div className="modal-content">
                        <div className="modal-header giftcart_title_header">
                            <h3 className="modal-title giftcart_all_header"> {(this.state.shipmentObj.id === '') ? 'Add Shipment' : 'Edit Shipment'}</h3>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group shippingTypeField boxinput_custome newadd30">
                                    {flashSuceessMessageSpan}
                                    {flashErrorMessageSpan}
                                    <label>Shipment Type</label>
                                    <select name='shipmentType' className='form-control select-dropdown' ref={(e) => this.shipmentType = e} onChange={this.setShipmentType.bind(this)}>
                                        <option>Please select shipment type</option>
                                        {
                                            Object.values(this.state.shippmentTypes) ? Object.values(this.state.shippmentTypes).map((shippmentTypes) => {
                                                return <option value={shippmentTypes.id} selected={shippmentTypes.id == this.state.shipmentObj.shipmentType}>{shippmentTypes.shippingType}</option>

                                            }) : ''
                                        }
                                        <option id="Other" key="Other" name="Other" value={this.state.otherShipmentType}>Other</option>

                                    </select>
                                    {this.state.otherShipmentInput ?

                                        <div className="row pt-2">
                                            <div className="col-md-6 ">
                                                <input type="test" className="form-control otherinput" onChange={(e) => this.addOtherShipmentType(e)} maxLength={30} />
                                            </div>
                                        </div>


                                        : ''}

                                </div>
                                <div>
                                    {
                                        this.state.showTable ?
                                            <table id="example" className="addshipTable table-bordered table table-striped table-hover" cellSpacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Quantity Range</th>
                                                        <th>Shipping Time</th>
                                                        <th>Price</th>
                                                        <th>Description</th>
                                                        <th className="action-td">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.tableList.length > 0 ? this.state.tableList.map((key, index) => {
                                                            return <tr key={index}>
                                                                <td>{key.startRange + " - " + key.endRange}</td>
                                                                <td>{key.startTime + " - " + key.endTime + " days"}</td>
                                                                <td>{key.shippingRate}</td>
                                                                <td>{key.description}</td>
                                                                <td className="action-td"><a href="#" onClick={(e) => this.editShipping(key)}><i className="fa fa-pencil" aria-hidden="true"></i></a>
                                                                    <a href='javascript:;' onClick={(e) => this.deleteShipping(key)}><i className="fa fa-trash"></i></a></td> </tr>
                                                        }) : ''
                                                    }
                                                </tbody>
                                            </table>
                                            : ''}
                                </div>


                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <fieldset className="the-fieldset quan_value">
                                                <legend className="the-legend">Quantity Range</legend>
                                                <div className="inputSlider row">
                                                    <div className="col-md-6">
                                                        <InputMask mask={'99999'} maskChar={null} className='form-control minInput' type="text" name='min' value={this.state.shipmentRange.min ? this.state.shipmentRange.min : ''} onChange={this.setShipmentRange.bind(this)} onBlur={this.setShipmentRangeValue.bind(this)} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputMask mask={'99999'} maskChar={null} className='form-control maxInput' type="text" name='max' value={this.state.shipmentRange.max ? this.state.shipmentRange.max : ''} onChange={this.setShipmentRange.bind(this)} onBlur={this.setShipmentRangeValue.bind(this)} />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <fieldset className="the-fieldset quan_value">
                                                <legend className="the-legend">Shipping Time</legend>
                                                <div className="inputSlider row">
                                                    <div className="col-md-6">
                                                        <InputMask mask={'999'} maskChar={null} className='form-control minInput' type="text" name='min' value={typeof this.state.shipmentTime.min === 'number' ? this.state.shipmentTime.min : ''} onChange={this.setShipmentTime.bind(this)} onBlur={this.setShipmentTimeValue.bind(this)} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputMask mask={'999'} maskChar={null} className='form-control maxInput' type="text" name='max' value={typeof this.state.shipmentTime.max === 'number' ? this.state.shipmentTime.max : ''} onChange={this.setShipmentTime.bind(this)} onBlur={this.setShipmentTimeValue.bind(this)} />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Price</label>
                                            {/* <InputMask mask={'99999'} maskChar={null} type="text" className="form-control" name="price" value={this.state.shipmentObj.price} type="text" ref={(e) => this.shipmentTimeFrom = e} onChange={this.setValue.bind(this)} /> */}
                                            <input type="text" className="form-control" name="price" value={this.state.shipmentObj.price} type="text" ref={(e) => this.shipmentTimeFrom = e} onChange={this.setValue.bind(this)} maxLength="8" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">

                                        <div className="form-group">
                                            <label htmlFor="dec">Description</label>
                                            <textarea className="form-control" rows="5" name="description" value={this.state.shipmentObj.description} type="text" ref={(e) => this.description = e} onChange={this.setValue.bind(this)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="shipButton pull-right">
                                        {this.state.shipmentObj.id == '' || this.state.shipmentObj.id == undefined ? <button type="button" className="giftcard_button mgr10" onClick={this.showList.bind(this)}>ADD</button> : ''}
                                        {this.state.disableSaveButton ?
                                            <button type="button" className="giftcard_button disableEvent">SAVE</button>
                                            :
                                            <button type="button" className="giftcard_button " onClick={this.shipmentSubmitHandler.bind(this)}>SAVE</button>
                                        }


                                        <button type="button" className="giftcard_button cardnone_button " onClick={this.onCloseModal}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        addUserResponse: state.addUser,
        shippmentList: state.shippmentList,
        shippmentType: state.shippmentTypes,
        addShippment: state.addShippment,
        editShippment: state.editShippment
    }
}

export default connect(mapStateToProps, { shippmentListAction, shippmentTypeAction, removePropsAction,addShippmentAction, editShippmentAction, deleteShippmentAction })(AddEditShipment);