import React from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import { shippmentListAction, deleteShippmentAction } from '../../../Actions/Admin/shippingManagementAction';
import AddEditShipment from './addEditShipment'
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";
import ReactLoading from 'react-loading';
import * as HelperUtil from "../../../utils/common-helper";
import "react-datepicker/dist/react-datepicker.css";
import * as constant from '../../../Constants/';
import Helmet from 'react-helmet';


/**
 * Class for ShipmentPage for Admin
 */
class ShippmentManagements extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
            shippmentList: [],
            pageactive: 1,
            filteredDataList: [],
            filterBy: '',
            shippingDetails: '',
            Loading: false,
            activeClassName: '',
            filterData: '',
            navigationClass: '',
            checkPointUpdateState: false,
            sortOrder: '',
            sortField: '',
        };
    }

    /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
    componentWillMount() {
        this.setState({ activeClassName: this.props.pathParam })
        this.setState({ Loading: true })
        this.props.shippmentListAction(sessionStorage.userID);
    }

    /**
* React Life Cycle method 
* Invoke just after chenge in dom 
*
* @param {*} nextProps 
*/
    componentWillReceiveProps(nextProps) {
        try {
            if (nextProps.shippmentList && nextProps.shippmentList.data && nextProps.shippmentList.data.error === false) {
                this.setState({
                    shippmentList: nextProps.shippmentList.data.data.data,
                    filteredDataList: nextProps.shippmentList.data.data.data,
                    listToFilter: nextProps.shippmentList.data.data.data,
                    Loading: false,
                })
            } else {
                this.setState({ Loading: false })
            }
            // document.getElementById('user-dashboard').style.height = window.innerHeight - 100 + 'px';
            // let reso_height = document.getElementById('user-dashboard').style.height
            // document.getElementsByClassName('res_height')[0].style.height = reso_height.slice(0, -2) - 300 + 'px';
            if (nextProps.deleteShippment && nextProps.deleteShippment.data) {
                if (nextProps.deleteShippment.data.data.hasOwnProperty('status') && nextProps.deleteShippment.data.data.status === true) {
                    if (this.state.checkPointUpdateState) {
                        this.setState({
                            Loading: false,
                            checkPointUpdateState: false,

                        })
                        this.props.shippmentListAction(sessionStorage.userID)
                    }
                }
            } else {
                this.setState({ Loading: false, checkPointUpdateState: false })
            }
            if (nextProps.navigationClass && nextProps.navigationClass.data) {
                this.setState({ navigationClass: nextProps.navigationClass.data });
            }
        } catch (e) { console.log("shippment erorr ", e) }
    }

    /**
     * Function for opennig Addeditshipment Modal
    * 
    */
    onOpenModal = (event) => {
        this.setState({ open: true, shippingDetails: '' });
    };

    /**
     * Function for update state for close modal
 
    */
    status = () => {
        this.setState({
            open: false,
            filterBy: 'Select',
            filterData: '',
            filteredDataList: this.state.shippmentList,
        });
    }

    /**
     * Function to delete the shippment
     */
    deleteShipping(e) {
        var data = {
            "id": e.id,
            "loggedInUser": sessionStorage.userID,
            "language": "en"
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm-popup AddUserModelform">
                        <div className="modal-header giftcart_title_header"><h3 className="modal-title giftcart_all_header"> Are you sure?</h3></div>
                        <div className="modal-body">
                            <p>You want to delete the shipping</p>
                            <div className="formbtn">
                                <button type="button" className="giftcard_button mgr10"
                                    onClick={async () => {
                                        this.setState({
                                            Loading: true,
                                            checkPointUpdateState: true,
                                            filterData:""
                                        })
                                        await this.props.deleteShippmentAction(data)
                                        onClose();
                                    }}
                                >
                                    Confirm
                                </button>
                                <button type="button" onClick={onClose} className="giftcard_button cardnone_button">No</button></div>

                        </div>

                    </div>
                );
            }
        })
    }

    /**
     * Function to change the active page
     * @param {*} page 
     */
    handlePageChange = (pageNumber) => {
        this.setState({
            pageactive: pageNumber
        })
    }
    /**
    * Function for edit shipping details
    * 
   */
    editShipping(key) {
        this.setState({ open: true, shippingDetails: key });
    }


    /**
     * function for auto search 
     * @param {*} event 
     */
    filterSearch(event) {
        let self = this
        var newDataList = []
        newDataList = this.state.listToFilter
        var val = event.target.value
        this.setState({
            filterData: val
        })
        var newArr = newDataList.filter(function (e) {
            if (self.state.filterBy == 'Shippment Type') {
                if (
                    (typeof e.shippingType != "undefined" && e.shippingType != null && e.shippingType !== "") && e.shippingType.toLowerCase().includes(val.toLowerCase())
                ) {
                    return e
                }
            }
            else if (self.state.filterBy == 'Price') {
                if (
                    ((typeof e.shippingRate != "undefined" && e.shippingRate != null && e.shippingRate !== "") && e.shippingRate.toString().includes(val))

                ) {

                    return e
                }
            }
            else if (self.state.filterBy == 'Description') {
                if (
                    ((typeof e.description != "undefined" && e.description != null && e.description !== "") && e.description.toLowerCase().includes(val.toLowerCase()))

                ) {
                    return e
                }
            }
            else if (self.state.filterBy == 'Quantity Range') {
                if (
                    ((typeof e.startRange != "undefined" && e.startRange != null && e.startRange !== "") && e.startRange.toString().includes(val)) ||
                    ((typeof e.endRange != "undefined" && e.endRange != null && e.endRange !== "") && e.endRange.toString().includes(val))
                ) {
                    return e
                }
            }

            else if (self.state.filterBy == 'Shipping Time') {
                if (
                    ((typeof e.startTime != "undefined" && e.startTime != null && e.startTime !== "") && e.startTime.toString().includes(val)) ||
                    ((typeof e.endTime != "undefined" && e.endTime != null && e.endTime !== "") && e.endTime.toString().includes(val))
                ) {
                    return e
                }
            }
            else {
                return e

            }
        })
        this.setState({
            filteredDataList: newArr,
            pageactive: 1,
        })

    }

    /****
     *Function for filter serch result by user input key
     * @param{date}
     */
    setFilterBy(e) {
        var val = e.target.value
        this.setState({
            filterBy: val,
            filterData: '',
            filteredDataList: this.state.listToFilter
        })

    }

    /** Function to reset the date*/
    resetList(e) {
        var val = e.target.value
        this.setState({
            filterBy: '',
            filterData: '',
            filteredDataList: this.state.listToFilter,
        })
    }

    /****
     *Function for sorting data onclick
     */
    sortBy(dataList, Name) {
        this.setState({
            sortField: Name
        })

        let response = constant.SORT_BY(dataList, Name, this.state.sortOrder)
        this.setState({
            filteredDataList: response.data,
            sortOrder: response.sortOrder
        })
    }

    render() {
        let { filteredDataList } = this.state
        var list = filteredDataList.slice((this.state.pageactive - 1) * 10, (this.state.pageactive) * 10)

        return (
            <div className="admin_top">
                {
                    this.state.Loading === true && <div className="loader-react">
                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                    </div>
                }
                <Helmet>
                    <title>Shipping Management</title>
                </Helmet>
                <div className="content-wrapper" className="d-flex flex-column">
                    <Header />
                    <div className="main">
                        <Navigation activeClassName={this.state.activeClassName} />
                        <div className={this.state.navigationClass == '' || this.state.navigationClass == 'abc' ? "dashboardContent" : "dashboardContent main_container"}>
                            <div className="container-fluid">
                                <div className="admin-header">
                                    <div id="user-dashboard" className="user-dashboard user-management clearfix paddingBottom">
                                        <div className="row mar0">
                                            <div className="col-md-12">
                                                <h2>Shipping Management</h2>
                                                <div className="row">
                                                    <div className="pull-left col-md-8 shipping-filter">
                                                        <div className="row">
                                                            <div className="form-group filter-input filter_group">
                                                                <div className="styled-select slate">
                                                                    <select className="select-dropdown" name="test" onChange={(e) => this.setFilterBy(e)} value={this.state.filterBy}>
                                                                        <option value="Select">Select</option>
                                                                        <option value="Shippment Type">Shippment Type</option>
                                                                        <option value="Price">Price</option>
                                                                        <option value="Description">Description</option>
                                                                        <option value="Quantity Range">Quantity Range</option>
                                                                        <option value="Shipping Time">Shipping Time</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group filter-input filter_group">
                                                                <div className="inner-addon search-input">
                                                                    <input type="text" className="form-control input-style search-icon" placeholder="Search" value={this.state.filterData} onChange={this.filterSearch.bind(this)} />

                                                                </div>
                                                            </div>
                                                            <div className="refresh-btn">
                                                                <i onClick={this.resetList.bind(this)} title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="pull-right col-md-4  mbl-scn">
                                                        <button className="giftcard_button add_btnfont pull-right button-padding" onClick={this.onOpenModal}>ADD Shipment</button>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table id="example" className="table table-striped table-bordered" cellSpacing="0" width="100%">
                                                        <thead>
                                                            <tr>
                                                                <th className=" all_cursorpointer pricedata" onClick={(e) => this.sortBy(filteredDataList, 'shippingType')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'shippingType' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Shipment Type</th>
                                                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'shippingRate')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'shippingRate' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Price</th>
                                                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'description')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'description' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Description</th>
                                                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'startRange')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'startRange' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Quantity Range</th>
                                                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'startTime')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'startTime' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Shipping Time</th>
                                                                <th className="action-td">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                list.length > 0 ? list.map((key, index) => {
                                                                    return <tr key={index}>
                                                                        <td><span onClick={(e) => this.editShipping(key)}>{key.shippingType}</span></td>
                                                                        <td className="pricedata">$ {HelperUtil._getAmountCommaSeparator(Number(key.shippingRate).toFixed(2)).toString()}</td>
                                                                        <td >{key.description}</td>
                                                                        <td className="pricedata">{key.startRange + (key.endRange == 0 ? " and above " : " - " + key.endRange)}</td>
                                                                        <td >{key.startTime + " - " + key.endTime + " Days"}</td>
                                                                        <td className="action-td"><a href="#" onClick={(e) => this.editShipping(key)}><i className="fa fa-pencil" style={{ "margin-left": '5px' }}></i></a>
                                                                            <a href="#" onClick={(e) => this.deleteShipping(key)} ><i className="fa fa-trash" style={{ "margin-left": '5px' }}></i></a>

                                                                        </td>

                                                                    </tr>
                                                                }) :  <tr><td className="recordNotFound" colSpan="10">No record found</td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <ul className="pagination pull-right my-1 simaple">
                                                    <Pagination
                                                        hideDisabled
                                                        activePage={this.state.pageactive}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={filteredDataList.length}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChange.bind(this)}
                                                        firstPageText='First'
                                                        lastPageText='Last'
                                                        prevPageText="Prev"
                                                        nextPageText="Next"
                                                    />
                                                </ul>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddEditShipment checkModal={this.state.open} modalClose={this.status} redirectPage={this.userRedirect} shippmentList={this.state.shippmentList} shippingDetails={this.state.shippingDetails}
                    locationProps={this.props.componentID}
                    getShipmentList={this.props.shippmentListAction}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        shippmentList: state.shippmentList,
        deleteShippment: state.deleteShippment,
        navigationClass: state.navigationToggleClass
    };
}

export default connect(mapStateToProps, { shippmentListAction, deleteShippmentAction })(ShippmentManagements);