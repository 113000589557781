import React, { Component } from 'react';
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal';
import "react-datepicker/dist/react-datepicker.css";
import { getFAQListAction, createFAQAction, updateFAQAction, getFAQDetailsAction, getFAQTotalRecordsAction } from '../../../Actions/Admin/faqManagementAction';
import _ from 'lodash'
import { Alert } from 'reactstrap';
import InputMask from 'react-input-mask'
import ReactLoading from 'react-loading'
import * as constant from '../../../Constants';


// Class for Add and Update FAQ
class AddEditFAQ extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            faqObj: {
                faqId: '',
                question: '',
                answer: '',
                sequence: '',
                language: 'en',
                lang: '',
            },
            validTill: new Date(),
            newDiscountDateValid: new Date(),
            faqList: [],
            flashMessageError: '',
            flashMessageSuccess: '',
            Loading: false,
            checkPointUpdateState: false,
            englishSequence: 0,
            spanishSequence: 0
        }
    }

    //method for close model
    onCloseModal = () => {
        this.setState({
            flashMessageError: '',
            flashMessageSuccess: '',
            faqObj: {
                question: '',
                answer: '',
                sequence: '',
                language: 'en',
                lang: '',
                faqId:''
            }
        })
        this.props.modalClose();
    }

    componentWillMount() {
        try {
            this.props.getFAQListAction({ "language": "en" })
            this.props.getFAQTotalRecordsAction()
            this.setState({
                faqList: this.props.getFAQList
            })
            if (this.props.updateFAQ != undefined && this.props.updateFAQ != null) {
                this.props.updateFAQ = null
            }
            if (this.props.createFAQ != undefined && this.props.createFAQ.data != null) {
                this.props.createFAQ = null
            }
        } catch (e) {
        }
    }

    /**
  * function for set discount details on change
  * @param object e
  * @return object state
  */
    setValue(e) {
        let sequenceValue
        let val = e.target.value;
        let key = e.target.name;
        try{
            setTimeout(()=>{
                let sequence  = document.getElementById("sequenceID")
                sequenceValue = sequence.options[sequence.selectedIndex].value;
                if(key === "lang") {
                    valid[key] = val;
                    valid["sequence"] = sequenceValue;
                }
                let faqObj = Object.assign(this.state.faqObj, valid);
                this.setState({ faqObj: faqObj });
            },10)
            
        }catch(e){}
        this.setState({
            flashMessageError: '',
            flashMessageSuccess: ''
        })
        let valid = {};
        if (val === '') {
            valid[key] = '';
        } else {
            valid[key] = val;
        }
        let faqObj = Object.assign(this.state.faqObj, valid);
        this.setState({ faqObj: faqObj });
    }

    /**
     * function to submit the data or save the FAQ details 
     */
    async faqSubmitHandler() {
        let { faqObj } = this.state
        if(faqObj.lang == '') {
            faqObj.lang = this.props.lang
        }
        let sequence 
        if (faqObj.sequence === '' && faqObj.lang === "English"){
            sequence = Number(this.state.totalFAQRecords.English)+1
        }  else if(faqObj.sequence === '' && faqObj.lang === "Spanish") {
            sequence = Number(this.state.totalFAQRecords.Spanish)+1
        } else {
            sequence =  this.state.faqObj.sequence
        }
        let data = { "question": faqObj.question, "answer": faqObj.answer, "sequence": sequence, "lang": faqObj.lang,"status": 1, "language": "en" }
        if (faqObj.answer != '' && faqObj.lang != '' && faqObj.question != '') {
    
           if (faqObj.faqId === '') {
                 this.setState({ Loading: true, checkPointUpdateState: true })
                 await this.props.createFAQAction(data)
            } else {
                this.setState({ Loading: true, checkPointUpdateState: true })
                data.faqId = faqObj.faqId
                await this.props.updateFAQAction(data)
            }
        } else {
            this.setState({
                flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        try {
            let self = this
            if (nextProps.getFAQList && nextProps.getFAQList.data) {
                self.setState({
                    faqList: nextProps.getFAQList.data.data.data,
                    checkPointUpdateState: false,
                })
            }
            if (nextProps.createFAQ && nextProps.createFAQ.data && nextProps.createFAQ.data.error === false) {
                if (nextProps.createFAQ.data.data.hasOwnProperty('status') && nextProps.createFAQ.data.data.status === true && self.state.checkPointUpdateState) {
                    self.setState({ flashMessageSuccess: nextProps.createFAQ.data.data.message })
                    setTimeout(async function () {
                        self.setState({
                            flashMessageSuccess: "",
                            Loading: false,
                            faqObj:{
                                faqId: '',
                                question: '',
                                answer: '',
                                sequence: '',
                                language: 'en',
                                lang: '',
                            },
                            validTill: new Date()
                        })
                        self.props.modalClose();
                        await self.props.getFAQListAction({ "language": "en" })
                        nextProps.locationProps.history.push("/ng-admin/faq")
                        self.props.modalClose();
                    }, 5000);
                } else {
                    this.setState({
                        flashMessageError: '',
                        flashMessageSuccess: ''
                    })
                }
            }
            if (nextProps.updateFAQ && nextProps.updateFAQ.data && nextProps.updateFAQ.data.error === false) {
                if (nextProps.updateFAQ.data.data.hasOwnProperty('status') && nextProps.updateFAQ.data.data.status === true && self.state.checkPointUpdateState) {
                    this.setState({ flashMessageSuccess: nextProps.updateFAQ.data.data.message })
                    setTimeout(async function () {
                        self.setState({
                            flashMessageSuccess: "",
                            Loading: false,
                            faqObj:{
                                faqId: '',
                                question: '',
                                answer: '',
                                sequence: '',
                                language: 'en',
                                lang: '',
                            },
                        })
                        self.props.modalClose();
                        await nextProps.getFAQListAction({ "language": "en" })
                        nextProps.locationProps.history.push("/ng-admin/faq")
                        self.props.modalClose();
                        nextProps.updateFAQ.data.data.status = false
                    }, 5000);
                } else {
                    this.setState({ flashMessageError : "", Loading: false})
                }
            }
                       if (nextProps.faqObj  !== '') {
                const faqDetails = nextProps.faqObj
                this.setState({
                    faqObj: {
                        answer: faqDetails.answer,
                        lang: faqDetails.lang,
                        question: faqDetails.question,
                        sequence: faqDetails.sequence,
                        status: faqDetails.status,
                        faqId: faqDetails.id,
                        language: 'en'
                    }
                })
            }
            
            if(nextProps.faqTotalRecordsResponse !== undefined && nextProps.faqTotalRecordsResponse.data.error !== true) {
              
                this.setState({
                    englishSequence : nextProps.faqTotalRecordsResponse.data.data.data.English !== undefined ? Number(nextProps.faqTotalRecordsResponse.data.data.data.English) : 0,
                    spanishSequence : nextProps.faqTotalRecordsResponse.data.data.data.Spanish !== undefined ? Number(nextProps.faqTotalRecordsResponse.data.data.data.Spanish) : 0,
                })
                   self.setState({totalFAQRecords: nextProps.faqTotalRecordsResponse.data.data.data})
             }
            else {
                this.setState({
                    faqObj: {
                        lang: '',
                        question: '',
                        sequence: '',
                        status: '',
                        faqId: '',
                        language: 'en'
                    },
                    Loading : false
                })
            }
        } catch (e) { }
    }

    render() {
        let lang = this.state.faqObj.lang === '' ? this.props.lang : this.state.faqObj.lang
        let sequence = lang === "English" ? this.state.englishSequence : this.state.spanishSequence
        sequence = this.props.faqObj === '' ? sequence + 1 : this.props.faqObj.lang === this.state.faqObj.lang ? sequence : sequence + 1
        let selectedSequence = this.props.faqObj !== '' ? this.props.faqObj.sequence : ''
        let sequenceOptions = []
        for (var i=0; i < sequence ; i++) {
            if(selectedSequence !== '') {
                if(this.props.faqObj.lang === this.state.faqObj.lang){
                    // if (sequence !== i+1) {
                    //     sequenceOptions.push(<option value={i+1} selected>{i+1}</option>);
                    // }
                    // else 
                    if(selectedSequence === i+1) {
                        sequenceOptions.push(<option value={i+1} selected>{i+1}</option>);
                    }
                    else {
                        sequenceOptions.push(<option value={i+1} >{i+1}</option>);
                    }
                } else if (this.props.faqObj.lang !== this.state.faqObj.lang) {
                     if(sequence == i+1) {
                        sequenceOptions.push(<option value={i+1} selected>{i+1}</option>);
                    }
                    else {
                        sequenceOptions.push(<option value={i+1} >{i+1}</option>);
                    }
                } 
            }
            else {
                if (sequence === i + 1) {
                    sequenceOptions.push(<option value={i + 1} selected>{i + 1}</option>);
                }

                else {
                    sequenceOptions.push(<option value={i + 1} >{i + 1}</option>);
                }
            }
        }
    
        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
        }
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
        }
        let { faqObj } = this.state
        return (
            <Modal open={this.props.checkModal} onClose={this.onCloseModal} center classNames={{ overlay: 'discountOverlay', modal: 'discountModel' }}
            closeOnOverlayClick={false}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        {
                            this.state.Loading === true && <div className="loader-react">
                                <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                            </div>
                        }
                        <div className="modal-header giftcart_title_header">
                            <h3 className="modal-title giftcart_all_header"> {(faqObj.faqId === '') ? 'Add FAQ' : 'Edit FAQ'}</h3>
                        </div>
                        <div className="modal-body">
                            <form>
                                {flashSuceessMessageSpan}
                                {
                                    this.state.flashMessageSuccess !== this.state.flashMessageError ?
                                        flashErrorMessageSpan : void 0
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <div container className="form-group">
                                            <label>Question</label>
                                            <textarea name="question" className="form-control" onChange={this.setValue.bind(this)} value={faqObj.question == '' ? '' : faqObj.question} maxLength="250"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div container className="form-group">
                                            <label>Answer</label>
                                            <textarea name="answer" className="form-control" onChange={this.setValue.bind(this)} value={faqObj.answer == '' ? '' : faqObj.answer}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-9">
                                        <label>Language</label>
                                        <div className="styled-select slate">
                                            {lang == "English" ?
                                                <select name='lang' className='form-control select-dropdown' onChange={this.setValue.bind(this)} value={faqObj.lang == '' ? '' : faqObj.lang}>
                                                    <option value="English">English</option>
                                                    <option value="Spanish">Spanish</option>
                                                </select>
                                                :
                                                <select name='lang' className='form-control select-dropdown' onChange={this.setValue.bind(this)} value={faqObj.lang == '' ? '' : faqObj.lang}>
                                                    <option value="Spanish">Spanish</option>
                                                    <option value="English">English</option>
                                                </select>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Sequence</label>
                                        <select name="sequence" id='sequenceID' className='form-control select-dropdown se' onChange={this.setValue.bind(this)} >
                                            {sequenceOptions}
                                        </select>
                                        {/* {/ <InputMask mask={'999'} maskChar={null} type="text" name="sequence" className="form-control" value={faqObj.sequence == '' ? '' : faqObj.sequence} onChange={this.setValue.bind(this)} /> /} */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className=" checkBoxArea">
                                            <div className="formbtn">
                                                <button type="button" className="giftcard_button mgr10" onClick={() => this.faqSubmitHandler(this)}>SAVE</button>
                                                <button type="button" className="giftcard_button cardnone_button" onClick={() => this.onCloseModal(this)}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getFAQList: state.getFAQList,
        createFAQ: state.createFAQ,
        updateFAQ: state.updateFAQ,
        getFAQDetails: state.getFAQDetails,
        faqTotalRecordsResponse: state.getFAQTotalRecords
    }
}

export default connect(mapStateToProps, { getFAQListAction, createFAQAction, updateFAQAction, getFAQDetailsAction, getFAQTotalRecordsAction})(AddEditFAQ);