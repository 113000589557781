import * as constants from "../../Constants";
import * as serverRoutes from "../../Routes/serverRoutes";
import axios from "axios";
import * as language from "../../Constants/translations";
import CIAMJSON from "./ciamJsonData";
import CryptoJS from "crypto-js";
/**
 * function for add cart details
 * @param object productData
 * @return object object of api response
 */
export function addToCartAction(productData) {
  return (dispatch) => {
    dispatch({
      type: constants.ADD_TO_CART_PRODUCT,
      payload: productData,
    });
  };
}

export function buyGiftCardAction(orderData, paymentData) {
  console.log("orderData:: ", orderData);
  console.log("paymentData:: ", paymentData);
  let transactionRequest = {
    requestData: paymentData,
    cardPaymentURL: constants.CARD_PAYMENT_URL,
    achPaymentURL: constants.ACH_PAYMENT_URL,
  };
  let transactionResponse = "";
  // orderData.transactionRequest = transactionRequest
  let responseData = "";
  let declinedReason = "";
  let historyIndex = "";
  let orderIndexIndex = "";
  let historyText = "";
  let historyId = "";
  let declinedObj = "";
  let acceptanceObj = "";
  let orderId = "";
  let orderNumber = "";
  let updateObj = "";
  return (dispatch) => {
    axios
      .post(serverRoutes.CREATE_ORDER, orderData)
      .then(async function(response) {
        console.log("-------response----------:: /api/buyGiftCard", response);
        if (response.data.data.status) {
          //   try {
          // if (orderData.userType !== undefined) {
          //   let pimId = response.data.data.data.customerId;
          //   await axios
          //     .get(`${serverRoutes.SEARCH_USER}?email=${orderData.email}`)
          //     .then(async (response) => {
          //       console.log(
          //         "-------response----------:: /api/searchUserCIAM",
          //         response
          //       );
          //       if (response.data.error === false) {
          //         if (response.data.data.data.ciam.length > 0) {
          //           let prosperId =
          //             response.data.data.data.ciam[0].prosperaId;
          //           await axios
          //             .get(`${serverRoutes.SEARCH_USER_BY_ID}`, {
          //               params: {
          //                 prosperId: prosperId,
          //               },
          //             })
          //             .then(async (response) => {
          //               console.log(
          //                 "-------response----------:: /api/searchByProsperID",
          //                 response
          //               );
          //               if (response.data.error === false) {
          //                 let ciamCreateUserJson = "";
          //                 let { customer } = response.data.data.data; //customerSources
          //                 ciamCreateUserJson = CIAMJSON(
          //                   orderData,
          //                   prosperId,
          //                   pimId
          //                 );
          //                 if (
          //                   customer !== null &&
          //                   customer.sourceMapping !== null
          //                 ) {
          //                   let isSourceGiftcard =
          //                     customer.sourceMapping.customerSources.filter(
          //                       (src) => src.source === "gift_card"
          //                     ).length > 0
          //                       ? true
          //                       : false;
          //                   if (isSourceGiftcard) {
          //                     delete ciamCreateUserJson.sourceMapping;
          //                   }
          //                 }
          //                 await axios
          //                   .post(
          //                     serverRoutes.REGISTER_AND_UPDATE,
          //                     ciamCreateUserJson
          //                   )
          //                   .then(async function(createUserResponse) {
          //                     console.log(
          //                       "-------response----------:: /api/createUserCIam",
          //                       response
          //                     );
          //                     if (
          //                       createUserResponse.data.error === false &&
          //                       createUserResponse.data.data.success ===
          //                         true &&
          //                       createUserResponse.data.data.msg ==
          //                         "Customer Updated Successfully."
          //                     ) {
          //                       // update pimcore guest customer
          //                       let activateCustomer = {
          //                         customerId: pimId,
          //                         language: "en",
          //                         ngId: prosperId,
          //                         consent: orderData.consent,
          //                         otpVerified: 0, //data.skipFlag===true ? 0 : 1
          //                       };
          //                       axios
          //                         .post(
          //                           serverRoutes.CUSTOMER_UPDATE_PROFILE,
          //                           activateCustomer
          //                         )
          //                         .then((res) =>
          //                           console.log("resonse user update", res)
          //                         )
          //                         .catch((erro) => console.log(erro));
          //                     }
          //                   })
          //                   .catch((error) => console.log("Some erorr"));
          //               }
          //             })
          //             .catch((err) => {});
          //         } else {
          //           let prosperId = "";
          //           let ciamCreateUserJson = CIAMJSON(
          //             orderData,
          //             prosperId,
          //             pimId
          //           );
          //           delete ciamCreateUserJson.prosperaId;
          //           delete ciamCreateUserJson.isUpdateAllKeys;
          //           ciamCreateUserJson.sourceMapping.customerSources[0].isSource = true;
          //           await axios
          //             .post(
          //               serverRoutes.REGISTER_AND_UPDATE,
          //               ciamCreateUserJson
          //             )
          //             .then(async (createUserResponse) => {
          //               let prosperId = 0;
          //               if (
          //                 createUserResponse.data.error === false &&
          //                 createUserResponse.data.data.success === true &&
          //                 createUserResponse.data.data.msg !==
          //                   "Customer Updated Successfully."
          //               ) {
          //                 prosperId =
          //                   createUserResponse.data.data.data.customer.id;
          //               }
          //               var activateCustomer = {
          //                 customerId: pimId,
          //                 language: "en",
          //                 ngId: prosperId,
          //                 otpVerified: 0, //data.skipFlag===true ? 0 : 1
          //               };
          //               axios
          //                 .post(
          //                   serverRoutes.CUSTOMER_UPDATE_PROFILE,
          //                   activateCustomer
          //                 )
          //                 .then((res) =>
          //                   console.log("resonse user update", res)
          //                 )
          //                 .catch((erro) => console.log(erro));
          //             });
          //         }
          //       }
          //     });
          // }
          // } catch (ciam) {
          //   console.log("ciam error", ciam);
          // }
          orderId = response.data.data.data.orderId;
          orderNumber = response.data.data.data.orderNumber;

          //Rajes code start for payeezy-api
          // console.log('keyExists',paymentData.hasOwnProperty("credit_card"));
          // console.log('method',paymentData.method);
          // console.log('paymentData',paymentData);

          if (paymentData.hasOwnProperty("credit_card")) {
            let paymenntString = JSON.stringify(paymentData);
            console.log("payeezecall", paymenntString);
            var CryptoData = CryptoJS.AES.encrypt(
              paymenntString,
              constants.ENCRYPT_DECRYPT_KEY
            ).toString();
            // console.log('CryptoData',CryptoData);
            axios
              .post(serverRoutes.MAKING_PAYEEZE_PAYMENT, {
                data: `${CryptoData}`,
              })
              .then((responsee) => {
                responseData = responsee.data.data;

                console.log("/paymentPage", responseData);
                // return false;
                if (responsee.data == "") {
                  dispatch({
                    type: constants.BUY_GIFT_CARD_ERROR,
                    payload: {
                      message: "CORS WARNING",
                      status: false,
                    },
                  });
                } else if (
                  responseData.validation_status == "failed" ||
                  responseData.transaction_status == "declined" ||
                  responseData.transaction_status == "Not Processed"
                ) {
                  let errReason;
                  if (responseData.Error) {
                    errReason = JSON.stringify(
                      responseData.Error.messages[0].description
                    );
                  } else if (responseData.bank_message) {
                    errReason = `Payment declined from bank: ${responseData.bank_message}`;
                  } else {
                    errReason =
                      "Payment declined from bank, please validate your payment details";
                  }
                  declinedReason = errReason;
                  transactionResponse = {
                    responseData: responseData,
                    cardPaymentURL: constants.PAYEEZY_CARD_PAYMENT_URL,
                    achPaymentURL: constants.PAYEEZY_ACH_PAYMENT_URL,
                  };
                  updateObj = {
                    orderId: orderId,
                    orderNumber: orderNumber,
                    historyId: historyId,
                    loggedInUser: sessionStorage.userID,
                    language: "en",
                    transactionResponse: transactionResponse,
                    status: true,
                    paymentStatus: false,
                  };
                  axios
                    .post(serverRoutes.COMPLETE_TRANSACTION, updateObj)
                    .then((respons) => {
                      console.log("completetxnFailure:: ", respons);
                      declinedObj = {
                        message: declinedReason,
                        status: false,
                      };
                      dispatch({
                        type: constants.BUY_GIFT_CARD_ERROR,
                        payload: declinedObj,
                      });
                    })
                    .catch((respons) => {
                      dispatch({
                        type: constants.BUY_GIFT_CARD_ERROR,
                        payload: {
                          message: "denied Transaction completion failed",
                          status: false,
                        },
                      });
                    });
                } else if (
                  responseData.validation_status == "success" &&
                  responseData.transaction_status == "approved"
                ) {
                  historyId =
                    responseData.transaction_id +
                    "_" +
                    responseData.transaction_tag +
                    "_" +
                    responseData.amount;
                  acceptanceObj = {
                    message:
                      orderData.lang === "en"
                        ? language.language.ORDER_Placed[0].en
                        : language.language.ORDER_Placed[0].spa,
                    status: true,
                  };
                  //updating order transaction
                  transactionResponse = {
                    responseData: responseData,
                    cardPaymentURL: constants.PAYEEZY_CARD_PAYMENT_URL,
                    achPaymentURL: constants.PAYEEZY_ACH_PAYMENT_URL,
                  };
                  updateObj = {
                    orderId: orderId,
                    orderNumber: orderNumber,
                    historyId: historyId,
                    loggedInUser: sessionStorage.userID,
                    language: "en",
                    transactionResponse: transactionResponse,
                    status: true,
                    paymentStatus: true,
                  };
                  axios
                    .post(serverRoutes.COMPLETE_TRANSACTION, updateObj)
                    .then((respons) => {
                      console.log("completetxnSuccess:: ", respons);
                      dispatch({
                        type: constants.BUY_GIFT_CARD,
                        payload: acceptanceObj,
                      });
                    })
                    .catch((respons) => {
                      dispatch({
                        type: constants.BUY_GIFT_CARD_ERROR,
                        payload: {
                          message: "Transaction completion failed",
                          status: false,
                        },
                      });
                    });
                  //transaction complete
                } else {
                  dispatch({
                    type: constants.BUY_GIFT_CARD_ERROR,
                    payload: {
                      message:
                        "Validate your payment details and Please try again",
                      status: false,
                    },
                  });
                }
              })
              .catch((responsee) => {
                console.log(responsee, "responsee from catch");
                dispatch({
                  type: constants.BUY_GIFT_CARD_ERROR,
                  payload: {
                    message: responsee.message
                      ? responsee.message
                      : "Payment Failed, Please validate your payment",
                    status: false,
                  },
                });
              });
          }
          //Rajes code end for payeezy-api old code commented below

          // if (paymentData[0] === "<") {
          //   //ACH payment API Starts //
          //   axios.post(serverRoutes.MAKING_ACH_PAYMENT, paymentData).then((responsee) => {

          //     responseData = responsee.data.data.data
          //     historyId = responseData.elements[0].elements[0].elements[0].elements[0].elements[4].elements[0].text
          //     let result = responseData.elements[0].elements[0].elements[0].elements[0].elements[2].elements[0].text
          //     let status = responseData.elements[0].elements[0].elements[0].elements[0].elements[0].elements[0].text
          //     if (status == "Declined") {
          //       declinedObj = {
          //         message: result,
          //         status: false
          //       }
          //       transactionResponse = {
          //         responseData: responsee.data.data.response,
          //         cardPaymentURL: constants.CARD_PAYMENT_URL,
          //         achPaymentURL: constants.ACH_PAYMENT_URL
          //       }
          //       updateObj = { "orderId": orderId, "orderNumber": orderNumber, "historyId": historyId, "loggedInUser": sessionStorage.userID, "language": "en", "transactionResponse": transactionResponse, "status": false }
          //       axios.post(serverRoutes.COMPLETE_TRANSACTION, updateObj).then((respons) => {
          //         dispatch({
          //           type: constants.BUY_GIFT_CARD_ERROR,
          //           payload: declinedObj
          //         })
          //       }).catch((respons) => {
          //         dispatch({
          //           type: constants.BUY_GIFT_CARD_ERROR,
          //           payload: {
          //             message: 'ACH denied Transaction completion failed',
          //             status: false
          //           }
          //         })
          //       });
          //     }
          //     else if (responseData == '') {
          //       dispatch({
          //         type: constants.BUY_GIFT_CARD_ERROR,
          //         payload: {
          //           message: 'CORS WARNING',
          //           status: false
          //         }
          //       })
          //     }
          //     else {
          //       acceptanceObj = {
          //         message: orderData.lang === 'en' ? language.language.ORDER_Placed[0].en : language.language.ORDER_Placed[0].spa,
          //         status: true
          //       }
          //       //updating order transaction
          //       transactionResponse = {
          //         responseData: responsee.data.data.response,
          //         cardPaymentURL: constants.CARD_PAYMENT_URL,
          //         achPaymentURL: constants.ACH_PAYMENT_URL
          //       }
          //       updateObj = { "orderId": orderId, "orderNumber": orderNumber, "historyId": historyId, "loggedInUser": sessionStorage.userID, "language": "en", "transactionResponse": transactionResponse, "status": true }
          //       axios.post(serverRoutes.COMPLETE_TRANSACTION, updateObj).then((respons) => {
          //         dispatch({
          //           type: constants.BUY_GIFT_CARD,
          //           payload: acceptanceObj
          //         })
          //       }).catch((respons) => {
          //         dispatch({
          //           type: constants.BUY_GIFT_CARD_ERROR,
          //           payload: {
          //             message: 'Transaction completion failed',
          //             status: false
          //           }
          //         })
          //       });
          //       //transaction complete
          //     }
          //   }).catch((responsee) => {
          //     dispatch({
          //       type: constants.BUY_GIFT_CARD_ERROR,
          //       payload: {
          //         message: responsee.message,
          //         status: false
          //       }
          //     })
          //   });
          //   //ACH payment API End
          // } else {
          //   //creditCard payment API Starts //
          //   var CryptoData = CryptoJS.AES.encrypt(paymentData,'payemtpimcorekey').toString();
          //   axios.post(serverRoutes.MAKING_PAYMENT, { data:`${CryptoData}`}).then((responsee) => {
          //     responseData = responsee.data.data
          //     if (responseData.includes('Declined')) {
          //       let respdata = responseData.split("Reason=")[1].split(":")[2]
          //       // if (responseData.includes('1102440001')) {
          //       //   declinedReason = constants.CARD_PAYMENT_ERROR_1102440001
          //       // } else if (responseData.includes('1102350009')) {
          //       //   declinedReason = constants.CARD_PAYMENT_ERROR_1102350009
          //       // } else if (responseData.includes('1102420002')) {
          //       //   declinedReason = constants.CARD_PAYMENT_ERROR_1102420002
          //       // } else if (responseData.includes('1102410001')) {
          //       //   declinedReason = constants.CARD_PAYMENT_ERROR_1102410001
          //       // } else if (responseData.includes('3102460009')) {
          //       //   declinedReason = constants.CARD_PAYMENT_ERROR_3102460009
          //       // }
          //       // else {
          //       //   declinedReason = constants.CARD_PAYMENT_ERROR
          //       // }
          //       declinedReason = respdata
          //       let resData = responseData.replace(/(\r\n|\n|\r)/gm, "&&&&&");
          //       transactionResponse = {
          //         responseData: resData,
          //         cardPaymentURL: constants.CARD_PAYMENT_URL,
          //         achPaymentURL: constants.ACH_PAYMENT_URL
          //       }
          //       updateObj = { "orderId": orderId, "orderNumber": orderNumber, "historyId": historyId, "loggedInUser": sessionStorage.userID, "language": "en", "transactionResponse": transactionResponse, "status": false }
          //       axios.post(serverRoutes.COMPLETE_TRANSACTION, updateObj).then((respons) => {
          //         declinedObj = {
          //           message: declinedReason,
          //           status: false
          //         }
          //         dispatch({
          //           type: constants.BUY_GIFT_CARD_ERROR,
          //           payload: declinedObj
          //         })
          //       }).catch((respons) => {
          //         dispatch({
          //           type: constants.BUY_GIFT_CARD_ERROR,
          //           payload: {
          //             message: 'denied Transaction completion failed',
          //             status: false
          //           }
          //         })
          //       });
          //     }
          //     else if (responseData == '') {
          //       dispatch({
          //         type: constants.BUY_GIFT_CARD_ERROR,
          //         payload: {
          //           message: 'CORS WARNING',
          //           status: false
          //         }
          //       })
          //     }
          //     else {
          //       historyIndex = responseData.indexOf('historyid=')
          //       orderIndexIndex = responseData.indexOf('orderid')
          //       historyText = responseData.slice(historyIndex, orderIndexIndex)
          //       historyId = (historyText.slice(10)).trim()
          //       acceptanceObj = {
          //         message: orderData.lang === 'en' ? language.language.ORDER_Placed[0].en : language.language.ORDER_Placed[0].spa,
          //         status: true
          //       }
          //       //updating order transaction
          //       let resData = responseData.replace(/(\r\n|\n|\r)/gm, "&&&&&");
          //       transactionResponse = {
          //         responseData: resData,
          //         cardPaymentURL: constants.CARD_PAYMENT_URL,
          //         achPaymentURL: constants.ACH_PAYMENT_URL
          //       }
          //       updateObj = { "orderId": orderId, "orderNumber": orderNumber, "historyId": historyId, "loggedInUser": sessionStorage.userID, "language": "en", "transactionResponse": transactionResponse, "status": true }
          //       axios.post(serverRoutes.COMPLETE_TRANSACTION, updateObj).then((respons) => {
          //         dispatch({
          //           type: constants.BUY_GIFT_CARD,
          //           payload: acceptanceObj
          //         })
          //       }).catch((respons) => {
          //         dispatch({
          //           type: constants.BUY_GIFT_CARD_ERROR,
          //           payload: {
          //             message: 'Transaction completion failed',
          //             status: false
          //           }
          //         })
          //       });
          //       //transaction complete
          //     }
          //   }).catch((responsee) => {
          //     dispatch({
          //       type: constants.BUY_GIFT_CARD_ERROR,
          //       payload: {
          //         message: responsee.message,
          //         status: false
          //       }
          //     })
          //   });
          //   //creditCard payment API End
          // }
        } else {
          dispatch({
            type: constants.BUY_GIFT_CARD_ERROR,
            payload: {
              message: response.data.data,
              status: false,
            },
          });
        }
      })
      .catch(function(response) {
        declinedObj = {
          message: response.message,
          status: false,
        };
        dispatch({
          type: constants.BUY_GIFT_CARD_ERROR,
          payload: declinedObj,
        });
      });
  };
}
