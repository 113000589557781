import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
  isSubmitting: false,
  data: null,
  login: false
}

/**
 * Function for get dashboardOrderList
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function dashboardOrderList(state = data, action) {
  switch (action.type) {
    case constants.DASHBOARD_ORDER_LIST:
      return { ...state, data: action.payload };
    case constants.DASHBOARD_ORDER_LIST_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for get pendingOrderList
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function pendingOrderList(state = data, action) {
  switch (action.type) {
    case constants.PENDING_ORDER_LIST:
      return { ...state, data: action.payload };
    case constants.PENDING_ORDER_LIST_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for get cancelledOrderList
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function cancelledOrderList(state = data, action) {
  switch (action.type) {
    case constants.CANCELLED_ORDER_LIST:
      return { ...state, data: action.payload };
    case constants.CANCELLED_ORDER_LIST_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for get activatedGiftCard
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function activatedGiftCardList(state = data, action) {
  switch (action.type) {
    case constants.ACTIVATED_GIFTCARD_LIST:
      return { ...state, data: action.payload };
    case constants.ACTIVATED_GIFTCARD_LIST_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for get pendingGiftCard
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function pendingGiftCardList(state = data, action) {
  switch (action.type) {
    case constants.PENDING_GIFTCARD_LIST:
      return { ...state, data: action.payload };
    case constants.PENDING_GIFTCARD_LIST_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}