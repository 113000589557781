import * as constants from './../../Constants';
import axios from 'axios';
import * as serverRoute from '../../Routes/serverRoutes';
import CUSTOMERCIAMJSON from './customerJsonData'

/**
 * function for register new customer
 * @param object userdata
 * @return object object of api response
*/
export function customerRegisterAction(userdata) {
  return (dispatch) => {
    axios.post(serverRoute.CUSTOMER_REGISTER, userdata)
      .then(function (response) {
        dispatch({
          type: constants.CUSTOMER_REGISTER_DATA,
          payload: response.data.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CUSTOMER_REGISTER_DATA_ERROR,
          payload: response.data
        })
      });
  }
}

/**
 * function for register new customer
 * @param object userdata
 * @return object object of api response
*/
export function customerLoginAction(loginDta) {
  return (dispatch) => {
    let url = serverRoute.CUSTOMER_LOGIN;
    axios.post(url, loginDta)
      .then(function (response) {
        dispatch({
          type: constants.CUSTOMER_LOGIN_DATA,
          payload: response.data.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CUSTOMER_LOGIN_DATA_ERROR,
          payload: response.data
        })
      });
  }
}


/**
 * Function to search user in CIAM
 */
export function searchUserAction(userData) {
  return (dispatch) => {
    var prosperId = ''
    var data = {}
    var mobile = userData.mobile
    mobile = mobile.replace(/[^a-zA-Z0-9]/g, '');
    var customerDetails = ''
    axios.get(`${serverRoute.SEARCH_USER}?email=${userData.email}`)
      .then(function (response) {
        
        //now get the prosper id from the response if data found
        if (response.data.error === false) {

          if (response.data.data.data.ciam.length > 0) {
          
            prosperId = response.data.data.data.ciam[0].prosperaId
            //now get the complete detail by prosper ID
            axios.get(`${serverRoute.SEARCH_USER_BY_ID}`,{
              params:{
                prosperId: prosperId
              }})
            .then(function (details) {
              
              if (details.data.data.success === true) {
                customerDetails = details.data.data.data.customer
                data = {
                  ngId: prosperId,
                  otpVerified: customerDetails.primaryPhoneNumber === Number(mobile) ? 1 : 0,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  email: userData.email,
                  password: userData.password,
                  mobile: mobile,
                  companyName: userData.companyName,
                  activateUrl: userData.activateUrl,
                  consent: userData.consent
                }
                //call crate user api after appending the address
                axios.post(serverRoute.CUSTOMER_REGISTER, data)
                  .then(function (response1) {
                    if (response1.data.data.status === true) {
                      /******Getting user id from registration****/
                      let customerId = response1.data.data.data.id
                      let loggedInUser = response1.data.data.data.id
                    
                      dispatch({
                        type: constants.SEARCH_USER,
                        payload: response1.data.data
                      })
                    } else {
                      dispatch({
                        type: constants.SEARCH_USER,
                        payload: response1.data.data
                      })
                    }
                  })
                  .catch(function (response1) {
                    dispatch({
                      type: constants.SEARCH_USER_ERROR,
                      payload: response1.data.data
                    })
                  });

              }
              else {
              }


            }).catch(function (details) {

            })
          } else {
            
            data = {
              ngId: '',
              otpVerified: 0,
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              password: userData.password,
              mobile: userData.mobile,
              companyName: userData.companyName,
              activateUrl: userData.activateUrl,
              consent: userData.consent,
            }
           
            //call create user api
            axios.post(serverRoute.CUSTOMER_REGISTER, data)
              .then(function (response1) {
                dispatch({
                  type: constants.SEARCH_USER,
                  payload: response1.data.data
                })
              })
              .catch(function (response1) {
                dispatch({
                  type: constants.SEARCH_USER_ERROR,
                  payload: response1.data.data
                })
              });
          }

        } else {
         
          data = {
            ngId: '',
            otpVerified: 1,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            password: userData.password,
            mobile: userData.mobile,
            companyName: userData.companyName,
            activateUrl: userData.activateUrl,
            consent: userData.consent,
          }
          //call create user api
          axios.post(serverRoute.CUSTOMER_REGISTER, data)
            .then(function (response1) {
              dispatch({
                type: constants.SEARCH_USER,
                payload: response1.data.data
              })
            })
            .catch(function (response1) {
              dispatch({
                type: constants.SEARCH_USER_ERROR,
                payload: response1.data.data
              })
            });
          // dispatch({
          //   type: constants.SEARCH_USER_ERROR,
          //   payload: response.data
          // })
        }
      }).catch(function (response1) {
        data = {
          ngId: '',
          otpVerified: 1,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          password: userData.password,
          mobile: userData.mobile,
          companyName: userData.companyName,
          activateUrl: userData.activateUrl,
          consent: userData.consent,
        }
        //call create user api
        axios.post(serverRoute.CUSTOMER_REGISTER, data)
          .then(function (response1) {
            dispatch({
              type: constants.SEARCH_USER,
              payload: response1.data.data
            })
          })
          .catch(function (response1) {
            dispatch({
              type: constants.SEARCH_USER_ERROR,
              payload: response1.data.data
            })
          });

      })
  }
}

/**
 * function for send otp on registered mobile number
 * @param object userdata
 * @return object object of api response
*/
export function sendOTPAction(userData) {
  return (dispatch) => {
    let url = serverRoute.SEND_OTP;
    axios.post(url, userData)
      .then(function (response) {
        dispatch({
          type: constants.SEND_OTP,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.SEND_OTP_ERROR,
          payload: response.data
        })
      });
  }
}


/**
 * function for send otp on registered mobile number
 * @param object userdata
 * @return object object of api response
*/
export function veriftOTPAction(userData) {
  return async (dispatch) => {
    var ciamCreateUserJson;
    let url = serverRoute.VERIFY_OTP;
    let prosperId = ''
    var verifyOtpJson = {
      "phoneNumber": "+1" + userData.phoneNumber,
      "emailId": userData.email,
      "otp": userData.otp
    }
    var veriftOTP = false
    var customerData = {}
    var consent=false
    axios.post(url, verifyOtpJson).then(async function (response) {
      if (response.data.data.success === true)
        veriftOTP = true
      if (veriftOTP === true) {
        //search user in CIAM start
		await axios.get(`${serverRoute.SEARCH_USER}?email=${userData.email}`)
		.then( async function (CIAMresponse) {
          //now get the prodper id from the response if data found
          if (CIAMresponse.data.error === false) {
			await axios.post(serverRoute.CUSTOMER_PROFILE, {
				userId:userData.pimId
			  })
			.then(response=>{
				if(response.data.error===false && response.data.data.status === true){
					consent =  response.data.data.data.consent
				}})
			.catch(e=>console.log("error",e))
            if (CIAMresponse.data.data.data.ciam.length > 0) {
              prosperId = CIAMresponse.data.data.data.ciam[0].prosperaId
			  //if there is user change data as
				
				await axios.get(`${serverRoute.SEARCH_USER_BY_ID}`,{
					params:{
					  prosperId: prosperId
					}})
				.then(response=>{
				
					if(response.data.error===false) {
						let {customer} = response.data.data.data  //customerSources
						if(customer !==null && customer.sourceMapping !== null) {
							 ciamCreateUserJson = CUSTOMERCIAMJSON(userData,prosperId,consent)
							if(customer !==null && customer.sourceMapping !== null) {
								let isSourceGiftcard = customer.sourceMapping.customerSources.filter(src=>src.source==="gift_card").length >0 ? true :  false
							
								if(isSourceGiftcard) {
									delete ciamCreateUserJson.sourceMapping
								} else {
									ciamCreateUserJson.sourceMapping.customerSources[0].isSource = false
								}
							}
							
						}
					}
					console.log("data")
				})
				.catch(err=>{})
				return 0

             
            }
            else {
			  ciamCreateUserJson = CUSTOMERCIAMJSON(userData,prosperId,consent)
			  delete ciamCreateUserJson.isUpdateAllKeys
			  delete ciamCreateUserJson.prosperaId
            }
          }

          else {
            console.log('error from CIAM')
          }

        })
		//search user in CIAM end
		
        // var
        await axios.post(serverRoute.REGISTER_AND_UPDATE, ciamCreateUserJson)
          .then(function (createUserResponse) {
          
            if (createUserResponse.data.error === false && createUserResponse.data.data.success === true && createUserResponse.data.data.msg !== 'Customer Updated Successfully.') {
              prosperId = createUserResponse.data.data.data.customer.id
            }
            //   var ngID=createUserResponse.data.data.data.customer!=undefined?createUserResponse.data.data.data.customer.id:0
            var createUserData = {
              'customerId': userData.customerData.customerId,
              'language': 'en',
              'ngId': prosperId,
              'otpVerified': 1
            }
          
            axios.post(serverRoute.ACTIVATE_CUSTOMER, createUserData)
              .then(function (activateCustomer) {
                dispatch({
                  type: constants.VERIFY_OTP,
                  payload: activateCustomer.data
                })
              })
          })
      }
      else {
      
        dispatch({
          type: constants.VERIFY_OTP,
          payload: response.data
        })
      }
    })
      .catch(function (response) {
        // dispatch({
        //   type: constants.VERIFY_OTP_ERROR,
        //   payload: response.data
        // })
      });
  }
}

/**
 * Reducer Action for update customer number for otp
 * @param object userdata
 * @return object object of api response
*/
export function updateNumberAction(data) {
  return (dispatch) => {
    let url = serverRoute.UPDATE_NUMBER_FOR_OTP;
    axios.post(url, data)
      .then(function (response) {
        dispatch({
          type: constants.UPDATE_NUMBER_DATA,
          payload: response.data.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.UPDATE_NUMBER_DATA_ERROR,
          payload: response.data
        })
      });
  }
}


export function searchUserCIAMbyEmail(data) {
  return (dispatch) => {
    axios.get(`${serverRoute.SEARCH_USER}?email=${data}`).then(function (CIAMresponse) {
      dispatch({
        type: constants.CIAM_SEARCH,
        payload: CIAMresponse.data.data
      })
    })
      .catch(function (CIAMresponse) {
        dispatch({
          type: constants.CIAM_SEARCH_ERROR,
          payload: CIAMresponse.data
        })
      });
  }
}


export function otpVerifyAction(data) {
  return (dispatch) => {
    //var data=data
    axios.post(serverRoute.VERIFY_OTP, data).then(function (CIAMresponse) {
     
      dispatch({
        type: constants.OTP_VERIFY,
        payload: CIAMresponse.data.data
      })
    })
      .catch(function (CIAMresponse) {
        dispatch({
          type: constants.OTP_VERIFY,
          payload: CIAMresponse.data
        })
      });
  }
}


export  function createUpdateUserCIAM(data) {
  return  (dispatch) => {
  
    var prosperId = ''
	var createUpdateJson = data.create
	let {pimId} = data
	let jsonCIAM  = {}
	 axios.post(serverRoute.CUSTOMER_PROFILE, {
		userId:pimId
	  })
	.then(async(response)=>{
		if(response.data.error===false && response.data.data.status === true){
			delete data.create.optin;

			data.create.optin = [
				{
				"optinCategoryId": constants.OPTION_CATEGORY_ID,
				"updatedsource": "gift_card",
				"subscribe": response.data.data.data.consent
				}
			  ]
			  if(createUpdateJson.prosperaId !== undefined) {
				await axios.get(`${serverRoute.SEARCH_USER_BY_ID}`,{
					params:{
					prosperId:createUpdateJson.prosperaId
					}})
				.then(response=>{
				
					if(response.data.error===false) {
					let {customer} = response.data.data.data  //customerSources
					if(customer !==null && customer.sourceMapping !== null) {
						let isSourceGiftcard = customer.sourceMapping.customerSources.filter(src=>src.source==="gift_card").length >0 ? true :  false
						if(isSourceGiftcard) {
							delete data.create.sourceMapping
						} else {
							data.create.sourceMapping.customerSources= [
								{
									"firstName": createUpdateJson.firstName,
									"lastName": createUpdateJson.lastName,
									"sourceId": `${pimId}`,
									"source": "gift_card",
									"isSource": false
								}
							]
						}
					}
				}
			})
			.catch(e=>console.log("Error",e))
		}
			
		axios.post(serverRoute.REGISTER_AND_UPDATE, data.create)
      .then(function (createUserResponse) {
      
        if (createUserResponse.data.error === false && createUserResponse.data.data.success === true && createUserResponse.data.data.msg !== 'Customer Updated Successfully.') {
          prosperId = createUserResponse.data.data.data.customer.id
        }
        else {
          prosperId = data.create.prosperaId
        }
        var activateCustomer = {
          'customerId': data.customerID,
          'language': 'en',
          'ngId': prosperId,
          'otpVerified':data.skipFlag===true ? 0 : 1//data.skipFlag===true ? 0 : 1
        }

        axios.post(serverRoute.ACTIVATE_CUSTOMER, activateCustomer)
          .then(function (activateCustomer) {

            dispatch({
              type: constants.ACTIVATE_CUSTOMER_DATA,
              payload: activateCustomer.data
            })
          })
          .catch(function (response) {
            dispatch({
              type: constants.ACTIVATE_CUSTOMER_DATA_ERROR,
              payload: response.data
            })
          })

      })
      .catch(function (response) {
        var activateCustomer = {
          'customerId': data.customerID,
          'language': 'en'
        }

        axios.post(serverRoute.ACTIVATE_CUSTOMER, activateCustomer)
          .then(function (activateCustomer) {

            dispatch({
              type: constants.ACTIVATE_CUSTOMER_DATA,
              payload: activateCustomer.data
            })
          })
          .catch(function (response) {
            dispatch({
              type: constants.ACTIVATE_CUSTOMER_DATA_ERROR,
              payload: response.data
            })
          })
      })
		}
	})
	.catch(error=> console.log(error))
	
    



  }
}
