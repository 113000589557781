/**File for defined server(node) routes */
/*Base URL for local */
// const API_BASE_URL = "http://localhost:3001"

/*Base URL for DEV */
// const API_BASE_URL = "http://giftcardapinew.pimcoreclients.com"

/**Base URl for production */
const API_BASE_URL = 'https://api.northgategiftcard.com'

/* User Management */
export const UNLOCK_USER_ACCOUNT = API_BASE_URL + '/api/unlock/customer';
export const CUSTOMER_RESET_PASSWORD = API_BASE_URL + '/api/reset/password';
export const USER_LOGIN = API_BASE_URL + '/api/login';
export const GET_API_ROLES = API_BASE_URL + '/api/getRoles';
export const GET_USERS = API_BASE_URL + '/api/getusers';
export const CREATE_USER = API_BASE_URL + '/api/createuser';
export const UPDATE_STATUS = API_BASE_URL + '/api/updateUserStatus';
export const UPDATE_USER = API_BASE_URL + '/api/updateUser';

/* Customer Management */
export const CUSTOMER_REGISTER = API_BASE_URL + '/api/customer/register';
export const CUSTOMER_LOGIN = API_BASE_URL + '/api/customer/login';
export const CHANGE_PASSWORD = API_BASE_URL + '/api/changePassword';
export const CUSTOMER_PROFILE = API_BASE_URL + '/api/getCustomerProfileData';
export const CUSTOMER_CHANGE_PASSWORD = API_BASE_URL + '/api/changeCustomerPassword';
export const CUSTOMER_UPDATE_PROFILE = API_BASE_URL + '/api/updateCustomerProfile';
export const SET_RESET_PASSWORD = API_BASE_URL + '/api/setReset/password';
export const ACTIVATE_CUSTOMER = API_BASE_URL + '/api/activateCustomer';

/* Denomination Management */
export const CARD_TYPE_LIST = API_BASE_URL + '/api/getCardTypeList';
export const DENOMINATION_LIST = API_BASE_URL + '/api/getDenominationList';
export const DENOMINATION_LIST_ALL = API_BASE_URL + '/api/getAllDenominationList';
export const ADD_DENOMINATION = API_BASE_URL + '/api/addDenomination';
export const UPDATE_DENOMINATION = API_BASE_URL + '/api/updateDenomination';
export const DELETE_DENOMINATION = API_BASE_URL + '/api/deleteDenomination';
export const DELETE_DENOMINATION_IMAGE = API_BASE_URL + '/api/deleteDemoninationImage';

/* CUSTOMER ADDRESS */
export const ADD_CUSTOMER_ADDRESS = API_BASE_URL + '/api/add-address';
export const UPDATE_CUSTOMER_ADDRESS = API_BASE_URL + '/api/edit-address';
export const DELETE_CUSTOMER_ADDRESS = API_BASE_URL + '/api/delete-address';
export const UPDATE_ADDRESS_STATUS = API_BASE_URL + '/api/default-address';

/* Discount Management */
export const DISCOUNT_LIST = API_BASE_URL + '/api/getDiscountList';
export const ADD_DISCOUNT = API_BASE_URL + '/api/addDiscount';
export const EDIT_DISCOUNT = API_BASE_URL + '/api/editDiscount';
export const DELETE_DISCOUNT = API_BASE_URL + '/api/deleteDiscount';
export const PAYMENT_TYPES = API_BASE_URL + '/api/getPaymentTypes';

/* Shippment Management */
export const SHIPPMENT_LIST = API_BASE_URL + '/api/getShippingList';
export const ADD_SHIPPMENT = API_BASE_URL + '/api/addShippment';
export const EDIT_SHIPPMENT = API_BASE_URL + '/api/editShippment';
export const DELETE_SHIPPMENT = API_BASE_URL + '/api/deleteShippment';
export const SHIPPMENT_TYPES = API_BASE_URL + '/api/getShippmentTypes';

/* Customer management */
export const GET_CUSTOMER_LIST = API_BASE_URL + '/api/getCustomersList';
export const UPDATE_CUSTOMER_DETAILS = API_BASE_URL + '/api/updateCustomerDetails';

/* Buy Gift card */
export const CREATE_ORDER=API_BASE_URL +'/api/buyGiftCard';

/* Order Management */
export const ORDER_LIST=API_BASE_URL +'/api/getOrderList';
export const ORDER_DETAILS=API_BASE_URL +'/api/orderDetails';
export const ACKNOWLEDGE_ORDER=API_BASE_URL +'/api/acknowledgeOrder';
export const SEND_SHIPPING_DETAILS=API_BASE_URL +'/api/sendShippingDetails';
export const CUSTOMER_ORDER_LIST=API_BASE_URL+'/api/get-order-list';
export const CANCEL_ORDER=API_BASE_URL +'/api/cancelOrder';

/* Gift Card Management */
export const GIFT_CARD_LIST=API_BASE_URL +'/api/giftCardList';
export const ACTIVATE_GIFT_CARDS=API_BASE_URL+'/api/activateGiftCards';

/* Dashboard */
export const DASHBOARD_ORDER_LIST = API_BASE_URL + '/api/dashboard/order/list';
export const PENDING_ORDER_LIST = API_BASE_URL + '/api/pending/order/list';
export const CANCELLED_ORDER_LIST = API_BASE_URL + '/api/cancelled/order/list';
export const ACTIVATED_GIFT_CARD_LIST = API_BASE_URL + '/api/activated/giftcards/list';
export const PENDING_GIFT_CARD_LIST = API_BASE_URL + '/api/pending/giftcards/list';

/* CIAM api */
const CIAM_URL = 'https://stage.treciondigital.com/services/webservice'
export const REGISTER_AND_UPDATE = API_BASE_URL + '/api/createUserCIAM';
export const SEARCH_USER_BY_ID=API_BASE_URL+'/api/searchByProsperId';
export const SEARCH_USER=API_BASE_URL+'/api/searchUserCIAM';
export const SEND_OTP=API_BASE_URL+'/api/sendOTP';
export const VERIFY_OTP=API_BASE_URL+'/api/verifyOTP';
export const SERACH_USER_BY_PROSPERID=API_BASE_URL+'/api/serachByProsperId';

/* Lost password admin */
export const SEND_RESET_PWD_LINK=API_BASE_URL+'/api/sendResetPwdLink';
export const UPDATE_USER_PWD=API_BASE_URL+'/api/updateUserPwd';

/* Home setting */
export const VIEW_HOME_SETTING=API_BASE_URL+'/api/viewHomeSetting';
export const UPDATE_HOME_SETTING=API_BASE_URL+'/api/updateHomeSetting';

/* PAYMENT Management */
export const MAKING_PAYMENT = API_BASE_URL + '/making/payment';
export const CAPTURE_PAYMENT = API_BASE_URL + '/api/capture/payment';
export const CANCEL_ORDER_PAYMENT_FROM_MERCHANT = API_BASE_URL + '/api/cancel/order/payment';
export const COMPLETE_TRANSACTION = API_BASE_URL + '/api/complete/transaction';
export const MAKING_ACH_PAYMENT = API_BASE_URL + '/making/ach/payment';

/* Payeeze Payment */
export const MAKING_PAYEEZE_PAYMENT = API_BASE_URL + '/making/payeezee/payment';
export const CAPTURE_PAYEEZE_PAYMENT = API_BASE_URL + '/api/capture/payeezepayment';
export const VOID_PAYEEZE_PAYMENT = API_BASE_URL + '/api/void/payeezepayment';

/* FAQ */
export const GET_FAQ_LIST = API_BASE_URL +'/api/getFAQList';
export const CREATE_FAQ = API_BASE_URL +'/api/createFAQ';
export const UPDATE_FAQ = API_BASE_URL +'/api/updateFAQ';
export const GET_FAQ_DETAILS = API_BASE_URL +'/api/getFAQDetails';
export const DELETE_FAQ = API_BASE_URL +'/api/deleteFAQ';
export const GET_TOTAL_FAQ_RECORDS = API_BASE_URL + '/api/getFAQTotalRecords';

/**Update number for OTP */
export const UPDATE_NUMBER_FOR_OTP = API_BASE_URL +'/api/updateNumberForOTP';
