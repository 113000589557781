import React, { Component } from 'react';
import { customerResetPWDAction } from '../../../Actions/Admin/customerManagementAction';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import * as constants from '../../../Constants'
import { Alert } from 'reactstrap';
import base64 from 'base-64'
import ReactLoading from 'react-loading'

/**Class for setting the new password */
class ResetPassword extends Component {

    constructor() {
        super();
        this.state = {
            resetLinkModal: false,
            email: '',
            flashMessageError: '',
            flashMessageSuccess: '',
            Loding: false,
            checkUpdateState: false,
        }
    }

    /**
     * Function for call lyfe cycel and handel props response
     * @param {object} props get from reducer
     */
    componentWillReceiveProps(nextProps) {
        let self = this
        if (nextProps && nextProps.resetCustomerPWDResponse.data) {
            if (nextProps.resetCustomerPWDResponse.data.hasOwnProperty('status') && (nextProps.resetCustomerPWDResponse.data.status === true)) {
                if (this.state.checkUpdateState) {
                    this.setState({ flashMessageSuccess: nextProps.resetCustomerPWDResponse.data.message })
                    setTimeout(function () {
                        self.setState({
                            checkUpdateState: false,
                            flashMessageSuccess: '',
                            flashMessageError: '',
                            Loding: false
                        })
                        nextProps.locationProps.history.push("/ng-admin/customermanagement")
                    }, 5000)
                } else {
                    self.setState({
                        checkUpdateState: false,
                        flashMessageSuccess: '',
                        flashMessageError: '',
                        Loding: false
                    })
                }
            } else {
                this.setState({ flashMessageError: nextProps.resetCustomerPWDResponse.data.message })
                setTimeout(function () {
                    self.setState({
                        checkUpdateState: false,
                        flashMessageSuccess: '',
                        flashMessageError: '',
                        Loding: false
                    })
                    nextProps.locationProps.history.push("/ng-admin/customermanagement")
                }, 5000)
            }
        }
    }

    /**
     * Function for close modal
     * @param null 
     * @return null
    */
    onCloseModal = () => {
        this.props.closeResetPWDModal();
    };

    /** Funrtion for creating current date & time */
    currentDateTime() {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date + ' ' + time;
        return dateTime
    }

    /**
     * Function for unlock account for user
     * @param {object}
     * @returns {object}
    */
    async resetPWDLink() {
        let email = this.props.customerData.email
        let rem = (window.location.href).indexOf("ng-admin")
        let url = (window.location.href).slice(0, rem)
        let timeSamp = this.currentDateTime();
        let encodedData = base64.encode(email + "&&&" + timeSamp)
        url = url + "?forgetId=" + encodedData
        let userDetails = { url: url, email: email };
        this.setState({
            Loding: true,
            checkUpdateState: true,
        })
        await this.props.customerResetPWDAction(userDetails);
    }

    /** Function for setting the email for sending the reset password link  */
    setEmailValue(event) {
        let email = event.target.value;
        this.setState({ email: email });
    }

    render() {
        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
        }
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
        }
        return (
            <div>
                <Modal open={this.props.resetModalOpen} onClose={this.onCloseModal} center classNames={{ overlay: 'addUseroverlay', modal: 'AddUserModelform' }}
                 closeOnOverlayClick={false}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header giftcart_title_header">
                                <h3 className="modal-title giftcart_all_header">Reset Password</h3>
                            </div>
                            <div className="modal-body">
                                {flashSuceessMessageSpan}
                                {flashErrorMessageSpan}
                                <form>
                                    <div container className="form-group">
                                        <label>Email Address</label>
                                        <input className='form-control' type='text' disabled='disabled' name='email' value={this.props.customerData.email} onChange={(e) => this.setEmailValue(e)} />
                                    </div>
                                    <div className="formbtn" align-right>
                                        <button type="button" className='giftcard_button mgr10' onClick={this.resetPWDLink.bind(this)}>Send Password Reset Link</button>
                                        <button type="button" className='giftcard_button cardnone_button' onClick={this.onCloseModal}>Back</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        resetCustomerPWDResponse: state.resetCustomerPWD
    }
}
export default connect(mapStateToProps, { customerResetPWDAction })(ResetPassword);