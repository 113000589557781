import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import { orderDetailsAction, orderListAction, sendShippingDetailsAction, cancelOrderAction } from '../../../Actions/Admin/orderManagementAction';
import moment from "moment"
import * as HelperUtil from "../../../utils/common-helper"

import { Alert } from 'reactstrap';
import ReactLoading from 'react-loading'
import bigInt from 'big-integer';
import InputMask from 'react-input-mask';
import Modal from 'react-responsive-modal';
import * as constant from '../../../Constants';
import Helmet from 'react-helmet';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import { changePasswordAction } from '../../../Actions/Admin/userManagementAction';
import Invoice from './invoice';

/** Class for setting Gift card range*/
class GiftCardRangePage extends React.Component {
    constructor(props) {
        super(props);
        this.rangeFrom = React.createRef();
        this.state = {
            orderDetails: {
                customerName: '',
                orderNumber: '',
                orderDate: '',
                orderedProduct: [],
                subTotal: '',
                discountAmount: '',
                shippingAmount: '',
                totalAmount: '', 
                shippingAddress1: "",
                shippingAddress2: "",
                shippingAddressId: "",
                shippingCity: "",
                shippingCountry: "",
                shippingState: "",
                shippingZip: "",
                historyId: "",
                paymentType: "",
                status: "",
                cancelReason: "", 
                shippingType: "",
                expectedShippingDate: "",
            },
            rangeJson: {
                rangeFrom: 0,
                rangeTo: 0,
                orderProductId: 0
            },
            rangeDiffQuantity: 0,
            rangeQuantityTaken: 0,
            rangeArray: [],
            rangeFrom: '',
            rangeTo: '',
            orderProductId: '',
            pin: '',
            clickCount: 0,
            activeClassName: '',
            giftCardExistRange: [],
            flashRangeValidationErrorMsg: '',
            productRangeCountValidation: [],
            divErrorClass: {},
            productRangeQuantityValidation: [],
            dbExistRange: [],
            numericDigitValidation: '',
            Loading: true,
            usedProductQuantity: '',
            trackingDetails: {
                orderTracking: '',
                trackingUrl: ''
            },
            trackingLengthValidation: '****************',
            flashMessageSuccess: '',
            flashMessageError: '',
            flashErrorMessagetrackingNumber: '',
            dbExistRangeErrorMessage: '',
            disableEventPlusButton: false,
            rangeJsonList: [],
            flashMessageRangeError: "",
            isAutoPlusButton: true,
            existRangeError: false,
            editRangeData: [],
            isURLEditable: false,
            trackingNumber: '',
            rangePageEditMode: false,
            openCancelOrderModal: false,
            cancellationReason: '',
            jsonId: [],
            editDbExistRange: [],
            editPageFlag: false,
            toInputState:false,
            isEditRange:false ,
            isAutoAllow:true,
            isRangeExist:false,
            duplicatearray:[],
            cellError:false,
            toRangeErrorMessage:"",
            errorRangeMessageArray :[],
            indexZeroRange :[],
            checkPoint:true,
            dbContinueButtonRange :[],
            isRangeEdit:'',
            checkDigitWarnning :"",
            cancelReasonError : '',
            openInvoiceModal:false




        }
    }

    /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
    componentWillMount() {
        try {
            if (localStorage.loggedIn == '' || sessionStorage.length == 0) {
                this.props.componentID.history.push("/ng-admin");
            }
        } catch (err) {

        }

        let tempJosnArray = []
        this.setState({
            orderDetails: {
                customerName: this.props.orderDetailsProps.customerName,
                orderNumber: this.props.orderDetailsProps.orderNumber,
                orderDate: this.props.orderDetailsProps.orderDate,
                orderedProduct: this.props.orderDetailsProps.orderedProduct,
                subTotal: this.props.orderDetailsProps.subTotal,
                discountAmount: this.props.orderDetailsProps.discountAmount,
                shippingAmount: this.props.orderDetailsProps.shippingRate != null ? this.props.orderDetailsProps.shippingRate : 0,
                totalAmount: this.props.orderDetailsProps.totalAmount != undefined ? this.props.orderDetailsProps.totalAmount : this.props.orderDetailsProps.total,
                shippingAddress1: this.props.orderDetailsProps.shippingAddress1,
                shippingAddress2: this.props.orderDetailsProps.shippingAddress2,
                shippingAddressId: this.props.orderDetailsProps.shippingAddressId,
                shippingCity: this.props.orderDetailsProps.shippingCity,
                shippingCountry: this.props.orderDetailsProps.shippingCountry,
                shippingState: this.props.orderDetailsProps.shippingState,
                shippingZip: this.props.orderDetailsProps.shippingZip,
                historyId: this.props.orderDetailsProps.historyId,
                paymentType: this.props.orderDetailsProps.paymentType,
                status: this.props.orderDetailsProps.status,
                cancelReason: this.props.orderDetailsProps.cancelReason, 
                shippingType: this.props.orderDetailsProps.shippingType,
                expectedShippingDate: this.props.orderDetailsProps.expectedShippingDate,
            },
            pin: this.props.pin,
            activeClassName: this.props.pathParam,
            usedProductQuantity: (this.props.usedProductQuantity == 0 && this.props.usedProductQuantity != undefined) ? 0 : 'abc',
            dbExistRange: (this.props.dbExistRangeData != undefined && this.props.dbExistRangeData.length > 0) ? this.props.dbExistRangeData : [],
            dbContinueButtonRange: (this.props.dbExistRangeData != undefined && this.props.dbExistRangeData.length > 0) ? this.props.dbExistRangeData : [],

        })
        this.props.orderDetailsProps.orderedProduct.map(order => {
            let jsonDetail = {
                jsonid: order.id,
                count: 0
            }
            tempJosnArray.push(jsonDetail)
        })
        this.setState({ jsonId: tempJosnArray })
        this.props.orderListAction(sessionStorage.userID);

    }

    /**
     * React Life Cycle method 
     * Invoke just after recieve new props 
     *
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        try {
            let self = this
            let data = []

            if (nextProps.hasOwnProperty('pageProp') && nextProps.pageProp.hasOwnProperty('location')) {
                let compareRanges = []
                let filteredDbRange = []
                let dbRange = this.props.dbExistRangeData
                if (nextProps.pageProp.location.state.isEdit) {
                    let rangeArray = []
                    let tempJosnArray = []
                    self.setState({
                        trackingNumber: nextProps.orderDetailsProps.orderTracking,
                        isURLEditable: true,
                        rangePageEditMode: nextProps.pageProp.location.state.isEdit,
                        orderId_edit: nextProps.orderDetailsProps.orderId,
                        editPageFlag: true
                    })
                    nextProps.orderDetailsProps.orderedProduct.map(order => {
                        let jsonDetail = {
                            jsonid: order.id,
                            count: 0
                        }
                        tempJosnArray.push(jsonDetail)
                    })
                    this.setState({ jsonId: tempJosnArray })
                    nextProps.orderDetailsProps.orderedProduct.map(order => {

                        compareRanges = [...compareRanges, ...order.productRange]
                        order.productRange.map(ranges => {
                            let usedQty = Number(bigInt(ranges.rangeTo.substring(0,18)).minus(ranges.rangeFrom.substring(0,18))) + 1
                            let jsonid = this.state.jsonId.filter(json => json.jsonid == order.id)
                            let tempCount = jsonid[0].count
                            let rangeDetail = {
                                ...ranges,
                                cardQty: order.noOfCards,
                                usedCardQty: usedQty,
                                productId: order.id,
                                id: ranges.id,
                                isreadOnly: true,
                                jsonId: `${order.id}_${tempCount}`
                            }
                            tempCount = tempCount + 1
                            jsonid[0]['count'] = tempCount
                            rangeArray.push(rangeDetail)
                        })
                        self.setState({ rangeJsonList: rangeArray })

                        let htmlCollections = document.getElementsByClassName(`hideRange_${order.id}`)
                        let domElementArray = Array.prototype.slice.call(htmlCollections)
                        domElementArray.map(element => {
                            element.style.visibility = "hidden"
                            return 0
                        })
                        return 0
                    })
                }
                this.setState({ editDbExistRange: compareRanges })

            } else { console.log("elses range ") }

            self.setState({ Loading: false })
            if (nextProps.orderDetailsProps) {
                this.setState({
                    orderDetails: {
                        customerName: nextProps.orderDetailsProps.customerName,
                        orderNumber: nextProps.orderDetailsProps.orderNumber,
                        orderDate: nextProps.orderDetailsProps.orderDate,
                        orderedProduct: nextProps.orderDetailsProps.orderedProduct,
                        subTotal: nextProps.orderDetailsProps.subTotal,
                        discountAmount: nextProps.orderDetailsProps.discountAmount,
                        shippingAmount: nextProps.orderDetailsProps.shippingRate ? nextProps.orderDetailsProps.shippingRate :0,
                        totalAmount:   nextProps.orderDetailsProps.totalAmount != undefined ? nextProps.orderDetailsProps.totalAmount : nextProps.orderDetailsProps.total,
                        Loading: false,
                        shippingAddress1: nextProps.orderDetailsProps.shippingAddress1,
                        shippingAddress2: nextProps.orderDetailsProps.shippingAddress2,
                        shippingAddressId: nextProps.orderDetailsProps.shippingAddressId,
                        shippingCity: nextProps.orderDetailsProps.shippingCity,
                        shippingCountry: nextProps.orderDetailsProps.shippingCountry,
                        shippingState: nextProps.orderDetailsProps.shippingState,
                        shippingZip: nextProps.orderDetailsProps.shippingZip,
                        historyId: nextProps.orderDetailsProps.historyId,
                        paymentType: nextProps.orderDetailsProps.paymentType,
                        status: nextProps.orderDetailsProps.status,
                        cancelReason: nextProps.orderDetailsProps.cancelReason, 
                        shippingType: nextProps.orderDetailsProps.shippingType,
                        expectedShippingDate: nextProps.orderDetailsProps.eta,
                    },
                })
            }
            if (nextProps && nextProps.orderList) {
                if (nextProps.orderList.data && nextProps.orderList.data.data) {
                    this.setState({ giftCardExistRange: nextProps.orderList.data.data.data, Loading: false });
                    nextProps.orderList.data.data.data.map(product => {
                        product.orderedProduct.map(pro => {
                            if (pro.productRange.length > 0) {
                                pro.productRange.map(range => {
                                    data.push(range)
                                    return 0
                                })
                            }
                            return 0
                        })
                        return 0
                    })
                    nextProps.orderList.data.data = null
                }
                self.setState({
                    dbExistRange: data,
                    dbContinueButtonRange:data
                })
            }
            if (nextProps && nextProps.sendShippingDetails.data != null && nextProps.sendShippingDetails.data.data !== null) {
                if (nextProps.sendShippingDetails.data.data.hasOwnProperty('status') && nextProps.sendShippingDetails.data.data.status === true) {
                    this.setState({
                        flashMessageError: '',
                        flashMessageSuccess: nextProps.sendShippingDetails.data.data.message,
                    })
                    nextProps.sendShippingDetails.data.data = null
                    setTimeout(function () {
                        self.setState({ Loading: false })
                        self.redirectToListPage()
                    }, 3000);
                }
                if (nextProps && nextProps.sendShippingDetails.data != null && nextProps.sendShippingDetails.data.data !== null) {
                    if (nextProps.sendShippingDetails.data.data.hasOwnProperty('status') && nextProps.sendShippingDetails.data.data.status === true) {
                        this.setState({
                            flashMessageError: '',
                            flashMessageSuccess: nextProps.sendShippingDetails.data.data.message,
                        })

                        setTimeout(function () {
                            self.setState({ Loading: false })
                            self.redirectToListPage()
                        }, 3000);
                    }
                    else {
                        if (nextProps.sendShippingDetails.data.data.hasOwnProperty('status') && nextProps.sendShippingDetails.data.data.status === false) {
                            this.setState({
                                flashMessageError: JSON.stringify(nextProps.sendShippingDetails.data.data.message), Loading: false
                            })
                        }
                    }
                }
            }

            if (nextProps.hasOwnProperty('cancelOrder')) {
                self.setState({ Loading: false })
                if (nextProps.cancelOrder.data !== null && nextProps.cancelOrder.data.status) {
                    nextProps.cancelOrder.data = null
                    nextProps.pageProp.history.push({
                        pathname: "/ng-admin/ordermanagement",

                    })
                }
                nextProps.cancelOrder.data = null
            }
        } catch (e) { console.log("error ", e) }
    }



/** 
 * Method for 
 * Handle page navigation 
 */
    redirectToListPage() {
        if (this.props.pageProp.history != undefined)
            this.props.pageProp.history.push({
                pathname: '/ng-admin/ordermanagement'
            })
    }


    /** Method for Scroll top  */
    onTop = () => {
        try {
            window.scrollTo(0,0)
        } catch(e) {console.log("erorr",e)}
       
    }


    /** Method for Open Shipping Detail page*/
   async  openShippingDetailPage() {
        try  {
            var data = []
            let rangeList = this.state.rangeJsonList;
            let productList = this.state.orderDetails.orderedProduct;
            let buttonDisabled = true
            let tempButtonDisabled = []
            let isValidLength = true
            let result = []
            let error = this.state.errorRangeMessageArray
            let isRangeExist = false 
            let isRangeExistAllList = false 
            let isRangeExistAllListEdit = false
            this.state.rangeJsonList.map(function (list) {
                let json = {
                    "id": list.id,
                    "orderProductId": list.productId,
                    "rangeFrom": list.rangeFrom,
                    "rangeTo": list.rangeTo,
                    "referenceNumber": "",
                    "createdBy": sessionStorage.userID,
                    "updatedBy": ""
                }
                data.push(json)
                return 0
            })

        
            if(this.state.rangeJsonList.length === 0) {
                this.state.orderDetails.orderedProduct.length > 0 ?
                this.state.orderDetails.orderedProduct.map((order, index) => {
                    document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 
                    document.getElementById(`rangeInputTo_${order.id}`).style = "border: red solid 1px;" 

                    return 0
                }) :void 0
            }


            /**** Check Range Redundancy  */
            if( Array.isArray(this.state.rangeJsonList) && this.state.rangeJsonList.length > 0  ) {
                this.state.rangeJsonList.map((list,innerX)=>{
                    this.state.rangeJsonList.map((listZ,indexZ)=> {
                        let min  = list.rangeFrom.substring(0,18)
                        let max = list.rangeTo.substring(0,18)
                        if(innerX !== indexZ) {
                            let cond1 = bigInt(min).greaterOrEquals(listZ.rangeFrom.substring(0,18)) && bigInt(min).lesserOrEquals(listZ.rangeTo.substring(0,18))
                            let cond2 = bigInt(max).lesserOrEquals(listZ.rangeTo.substring(0,18)) && bigInt(max).greaterOrEquals(listZ.rangeFrom.substring(0,18))
                            let cond3 = bigInt(min).lesserOrEquals(listZ.rangeFrom.substring(0,18)) && bigInt(max).greaterOrEquals(listZ.rangeTo.substring(0,18))
                            
                            if(min.length===18 && max.length===18) {
                                if(cond1 || cond2 || cond3) { 
                                    isRangeExist = true
                                }
                            } else {
                                this.onTop();
                                this.setState({flashMessageRangeError:"Please fill up the ranges  "})
                                setTimeout(()=>{this.setState({flashMessageRangeError:""})},3000)   
                            }
                        }

                        return 0
                    })
            
                return 0
                })
                if(isRangeExist) {
                    this.onTop();

                    this.setState({flashMessageRangeError:"Please fill up the ranges  "})
                    setTimeout(()=>{this.setState({flashMessageRangeError:""})},3000) 
                    return 0
                } else if(this.state.rangePageEditMode === false) {
                    
                    let compareRangeList = this.state.dbContinueButtonRange
                    this.state.rangeJsonList.map(listX => {
                        let min  = listX.rangeFrom.substring(0,18)
                        let max = listX.rangeTo.substring(0,18)
                        compareRangeList.map(listInner=>{
                            let cond1 = bigInt(min).greaterOrEquals(listInner.rangeFrom.substring(0,18)) && bigInt(min).lesserOrEquals(listInner.rangeTo.substring(0,18))
                            let cond2 = bigInt(max).lesserOrEquals(listInner.rangeTo.substring(0,18)) && bigInt(max).greaterOrEquals(listInner.rangeFrom.substring(0,18))
                            let cond3 = bigInt(min).lesserOrEquals(listInner.rangeFrom.substring(0,18)) && bigInt(max).greaterOrEquals(listInner.rangeTo.substring(0,18))
                            if(min.length===18 && max.length===18) {
                                if(cond1 || cond2 || cond3) { 
                                    isRangeExistAllList = true
                                }
                            }
                            return 0
                        })
                        return 0
                    })
                    if(isRangeExistAllList) {
                        this.onTop();

                        this.setState({flashMessageRangeError:"Please fill up the ranges "})
                        setTimeout(()=>{this.setState({flashMessageRangeError:""})},3000) 
                        return 0
                    }

                    

                } else {
                    let compareRangeList = this.state.dbContinueButtonRange.filter(list=>{
                        let isList = true
                        rangeList.map(innerList=>{
                            if(list.orderProductId === innerList.orderProductId) {
                                isList = false
                            }
                            return 0
                        })
                        return isList 
                    })
                   
                    this.state.rangeJsonList.map(listX => {
                        let min  = listX.rangeFrom.substring(0,18)
                        let max = listX.rangeTo.substring(0,18)
                        
                        compareRangeList.map(listInner=>{
                            let cond1 = bigInt(min).greaterOrEquals(listInner.rangeFrom.substring(0,18)) && bigInt(min).lesserOrEquals(listInner.rangeTo.substring(0,18))
                            let cond2 = bigInt(max).lesserOrEquals(listInner.rangeTo.substring(0,18)) && bigInt(max).greaterOrEquals(listInner.rangeFrom.substring(0,18))
                            let cond3 = bigInt(min).lesserOrEquals(listInner.rangeFrom.substring(0,18)) && bigInt(max).greaterOrEquals(listInner.rangeTo.substring(0,18))
                            
                            if(min.length===18 && max.length===18) {
                                if(cond1 || cond2 || cond3) { 
                                    isRangeExistAllListEdit = true
                                }
                            }
                            return 0
                        })
                        return 0
                    })
                    if(isRangeExistAllListEdit) {
                        this.onTop();
                        
                        this.setState({flashMessageRangeError:"Please fill up the ranges  "})
                        setTimeout(()=>{this.setState({flashMessageRangeError:""})},3000) 
                        return 0
                    }
                    
                
                }
            }

            /***End  */

            

            error = []
            

            /***Product Quantity Test  */
          
            if (productList.length > 0) {
                result = productList.map((product) => {
                   
                    let totalUsedQty = 0
                    if (rangeList.length > 0) { 
                        rangeList.map((range) => {
                           
                        
                            if(range.rangeFrom.length<19 || range.rangeTo.length < 19) {
                                isValidLength=false
                            } else {
                                isValidLength=true
                            }
                            if (product.id === range.productId) {
                                totalUsedQty += +range.usedCardQty
                            }
                            return 0
                        })
                    }
                  
                    return +totalUsedQty === +product.noOfCards && isValidLength
                })
                tempButtonDisabled = result.filter((validResult) => validResult === true)

                if (tempButtonDisabled.length === result.length) {
                    buttonDisabled = false
                }
            }
            
            if(result.length > 0) {
            
                if(result.includes(false)) {
                    this.onTop();

                    this.setState({flashMessageRangeError:"Please fill up the ranges"})
                    setTimeout(()=>{ this.setState({flashMessageRangeError:""})},5000)

                    return 0

                } 
            }
        
            /*** Product Quantity Test End  code Here */



            //Edit case for range page 
            if (this.state.rangePageEditMode) {
                let self = this
                if (self.state.trackingNumber.length !== 12 || self.state.trackingNumber==="") {
                    self.setState({ flashErrorMessagetrackingNumber: 'Tracking number can not be blank and should be 12 digit' })
                    setTimeout(() => { self.setState({ flashErrorMessagetrackingNumber: "" }) }, 3000)
                    return
                }
                let sendJson = {
                    "loggedInUser": "6",
                    "language": "en",
                    "trackingDetail": {
                        "orderId": this.state.orderId_edit,
                        "trackingUrl": 'https://www.fedex.com/',
                        "orderTracking": `${self.state.trackingNumber}`
                    },
                    "orderRange": data
                }
               
                self.setState({ Loading: true })
                self.props.sendShippingDetailsAction(sendJson)
                return 0
            }
            let val = document.getElementById('trackingNumberInput').value;
            if (val == '' || val.length != 12) {
                this.setState({ flashErrorMessagetrackingNumber: 'Tracking number can not be blank and should be 12 digit' })
                return;
            } else {
                var jsonToaddTrackingDetails = {
                    "loggedInUser": sessionStorage.userID,
                    "language": "en",
                    "orderRange": data,
                    "trackingDetail": {
                        "orderId": this.props.orderId,
                        "trackingUrl": 'https://www.fedex.com/',
                        "orderTracking": this.state.trackingDetails.orderTracking
                    },
                }
                this.setState({ flashErrorMessagetrackingNumber: '', Loading: true })
            
            
                this.props.sendShippingDetailsAction(jsonToaddTrackingDetails) //API call
                this.onTop()
            }
        } catch(e) {console.log("error in opening shippment page ",e)}
    }

    /**
     * Function for create range object
     * 
     */
    createObjectRange() {
        try {
            let rangeExistArray = this.state.giftCardExistRange;
                    let rangeObject = []
                    if (rangeExistArray.length > 0) {
                        rangeExistArray.map(function (orderedProducts) {
                            orderedProducts.orderedProduct.map(function (productRanges) {
                                productRanges.productRange.map(function (ranges) {
                                    rangeObject.push(ranges);
                                })
                            })
                        })
                    }
        } catch (e) {console.log("#createObjectRange",e)}
       
    }

    componentDidUpdate() {
        if (this.state.dbExistRange.length <= 0) {
            this.createObjectRange();
        }
        try {
            let keyCodeArray = []
            let inputHandleLength = document.getElementsByClassName("inputHandle").length
            for (let i = 0; i < inputHandleLength; i++) {
                document.getElementsByClassName('inputHandle')[i].addEventListener('keydown', function (e) {
                    var key = e.keyCode ? e.keyCode : e.which;
                    e.ctrlKey ?
                        keyCodeArray.push(1) : keyCodeArray.pop()
                    if (!([8, 9, 13, 27, 46, 110, 190].indexOf(key) !== -1 ||
                        (key == 65 && (e.ctrlKey || e.metaKey)) ||
                        (key >= 35 && key <= 40) ||
                        (keyCodeArray.length > 0 && e.keyCode === 86) ||
                        (keyCodeArray.length > 0 && e.keyCode === 67) ||
                        (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
                        (key >= 96 && key <= 105)
                    )) {
                        e.preventDefault();
                    }
                    keyCodeArray.length > 0 ? keyCodeArray.pop() : void 0
                });
            }
        } catch (err) { }

        if (this.state.dbExistRange && this.state.editPageFlag) {
            let renageDb = this.state.dbExistRange
            let filteredDbRange = []
            if (this.state.rangePageEditMode) {
                renageDb.length > 0 ?
                    filteredDbRange = renageDb.filter(
                        ranges => {
                        let res = true
                        this.state.editDbExistRange.map(innerRange => {
                            if (ranges.id === innerRange.id) {
                                res = false
                            }
                        })
                        return res
                    }) : void 0
            }
            this.setState({ dbExistRange: filteredDbRange, editPageFlag: false })
        }
    }

    /** Method to validate the number range*/
    validateNumber(event, orderId, value) {
        try {
            if (value == '' || isNaN(value) || value == 0) {
                document.getElementById('rangeNumericErrorAlert').style.display = 'block';
                document.getElementById('addRowButton_' + orderId).style.pointerEvents = "none";
                window.setTimeout(() => {
                    document.getElementById('rangeNumericErrorAlert').style.display = 'none';
                }, 1000)
                return false;
            } else {
                document.getElementById('rangeBlankErrorAlert').style.display = 'none';
                document.getElementById('rangeQuantityErrorAlert').style.display = 'none';
                document.getElementById('rangeErrorAlert').style.display = 'none';
                document.getElementById('rangeNumericErrorAlert').style.display = 'none';
                document.getElementById('addRowButton_' + orderId).style.pointerEvents = "";
                return true;
            }
        } catch (e) { }
    }

    /** Method for setting tracking detail*/
    setTrackindDetails(e) {
        try {
            let val = e.target.value;
            let key = e.target.name;
            let valid = {};
            if (val === '') {
                valid[key] = '';
            } else {
                valid[key] = val;
            }
            let validData = Object.assign(this.state.trackingDetails, valid)

            this.setState({
                flashErrorMessagetrackingNumber: '',
                trackingNumber: validData.orderTracking
            })
        } catch (e) { }
    }

    /**
     * Method for allowed only nuber
     * @param {click event}
     */
    onlyNumber = function (event, id) {
        try {
            
            let digitRegx = /^[0-9]*$/;
            let valToShow = ''
            if (!digitRegx.test(event.target.value)) {
                let len = event.target.value.length;
                for (let i = 0; i < len; i++) {
                    if (digitRegx.test(event.target.value[i])) {
                        valToShow = valToShow + event.target.value[i];
                    }
                }
                event.target.value = valToShow;
                if(event.target.name==="rangeTo") {
                    this.setState({toInputState:true})
                }
            }
        } catch (e) { }
    }

    /***
     * @param {event}
     * @pram {id}
     * Method for checking Range exist 
     */
    isExistRange = (event,order,id) => {
        try {
            let self = this
            let isRangeExist = false
            let rangeFrom = document.getElementById(`rangeInputFrom_${id}`).value;
            let rangeTo = document.getElementById(`rangeInputTo_${id}`).value;
            let plusButton = document.getElementById(`range_add_${id}`)
            let compareRangeList  =  [...self.state.dbExistRange ,...this.state.rangeJsonList]
            
            if (rangeFrom !== "" && rangeTo !== "") {
               
                if (bigInt(rangeFrom).lesserOrEquals(rangeTo)) {
                    compareRangeList.map(ranges => {
                        let resultOne = bigInt(rangeFrom).greaterOrEquals(ranges.rangeFrom) && bigInt(rangeFrom).lesserOrEquals(ranges.rangeTo)
                        let resultSecond = (bigInt(rangeTo).greaterOrEquals(ranges.rangeFrom)) && bigInt(rangeTo).lesserOrEquals(ranges.rangeTo)
                        let thordCond = rangeFrom == ranges.rangeFrom || rangeTo == ranges.rangeTo
                        if (resultOne || resultSecond || thordCond) {
                            
                            isRangeExist = true
                           
                        }
                        return 0;
                    })
                    if (isRangeExist) {
                        
                        self.setState({
                            dbExistRangeErrorMessage: "Range is already used !",
                            isAutoPlusButton: false,
                            existRangeError: false,
                            isAutoAllow:false
                        })
                    } else {
                        self.setState({
                            isAutoPlusButton: true,
                            existRangeError: false,
                            isAutoAllow:true
                        })
                    }
                } else {
                    if(rangeFrom.length !==19 && rangeTo.length !==19) {
                        self.setState({ dbExistRangeErrorMessage: "Start range must be less than end range" })
                    }
                    // self.setState({ dbExistRangeErrorMessage: "Start range must be less than end range" })
                    plusButton.setAttribute('disabled', 'disabled')
                }
            }
            setTimeout(() => { self.setState({ dbExistRangeErrorMessage: "" }) }, 5000)

        } catch (e) { console.log("error from is existRange ", e) }
    }

    /**
     * Method for adding Range and creating Json 
     * @param {event}
     * @param {order}
     * @param {id}
     */
    addRaneInput = (e, order, id) => {
        try {
            let self = this
            let targateId = e.target.id
            let arr = this.state.rangeJsonList
            let tempRangeList = []
            let rangeFrom = document.getElementById(`rangeInputFrom_${order.id}`).value
            let rangeTo = document.getElementById(`rangeInputTo_${order.id}`).value
            let isRangeExist = false
            let plusButton = document.getElementById(`range_add_${id}`)
            let compareRangeList  =  [...self.state.dbExistRange ,...this.state.rangeJsonList]
            
            if (rangeFrom !== "" && rangeTo !== "") {
               
                if (bigInt(rangeFrom).lesserOrEquals(rangeTo)) {
                    
                    compareRangeList.map(ranges => {
                        let resultOne = bigInt(rangeFrom).greaterOrEquals(ranges.rangeFrom) && bigInt(rangeFrom).lesserOrEquals(ranges.rangeTo)
                        let resultSecond = (bigInt(rangeTo).greaterOrEquals(ranges.rangeFrom)) && bigInt(rangeTo).lesserOrEquals(ranges.rangeTo)
                        let thirdCond = bigInt(rangeFrom).lesserOrEquals(ranges.rangeFrom) && bigInt(rangeTo).greaterOrEquals(ranges.rangeTo)
                        if (resultOne || resultSecond || thirdCond) {
                            
                            isRangeExist = true
                            
                        }
                        return 0;
                    })
                    if (isRangeExist) {
                        // plusButton.setAttribute('disabled', true)
                        self.setState({
                            dbExistRangeErrorMessage: "Range is already used !",
                            isAutoPlusButton: false,
                            existRangeError: false,
                            isAutoAllow:false
                        })
                    } else {
                        self.setState({
                            isAutoPlusButton: true,
                            existRangeError: false,
                            isAutoAllow:true
                        })
                    }
                } else {
                    self.setState({ dbExistRangeErrorMessage: "Start range must be less than end range" })
                    plusButton.setAttribute('disabled', 'disabled')
                }
            }
            if (rangeFrom !== '' && rangeTo !== '' && isRangeExist===false) {
                if (rangeFrom.length === 19 && rangeTo.length === 19) {
                    if (targateId === `range_add_${order.id}` || order.id === id) {
                        let jsonid = this.state.jsonId.filter(json => json.jsonid == order.id)
                        let tempCount = jsonid[0].count
                        let rangeJson = {
                            id: 0,
                            rangeFrom: rangeFrom,
                            rangeTo: rangeTo,
                            cardQty: order.noOfCards,
                            usedCardQty: Number(bigInt(rangeTo).minus(rangeFrom).value) + 1,
                            productId: order.id,
                            jsonId: `${order.id}_${tempCount}`
                        }
                        tempCount = tempCount + 1
                        jsonid[0]['count'] = tempCount
                        arr.push(rangeJson);
                        tempRangeList.push(rangeJson)
                        this.setState({ rangeJsonList: arr })
                    }
                    let tempDbRange = this.state.dbExistRange
                    let XtempDbRange = [...tempRangeList, ...tempDbRange]
                   
                    this.setState({ dbExistRange: XtempDbRange })
                    this.handleInputFiled(order)
                    // Clear range from and Range To inputs 
                    if (+order.noOfCards !== 1) {
                        document.getElementById(`rangeInputFrom_${order.id}`).value = '';
                        document.getElementById(`rangeInputTo_${order.id}`).value = ''
                        document.getElementById(`cards_${order.id}`).value = ''
                
                    } else {
                        document.getElementById(`rangeInputFrom_${order.id}`).style.display = 'none';
                        document.getElementById(`rangeInputTo_${order.id}`).style.display = 'none';
                    }
                } else {
                    this.setState({ flashMessageRangeError: "Range must  be 19 digits " })
                    setTimeout(() => { self.setState({ flashMessageRangeError: '' }) }, 5000)
                }
            } else {
                if(rangeFrom === "" && rangeTo === "") {
                    this.setState({ flashMessageRangeError: "Range  must be 19 digits" })
                    setTimeout(() => { this.setState({ flashMessageRangeError: '' ,dbExistRangeErrorMessage:''}) }, 5000)

                }
                    
                setTimeout(() => { self.setState({ flashMessageRangeError: '' ,dbExistRangeErrorMessage:''}) }, 5000)
            }
           
            
            

        } catch (e) { console.log("hi ", e) }
    }

    /******
     *
     * Add Box For Range 
     *  
     */
    addRangeBox = (e,order,index) => {
       
        let self = this
        let targateId = e.target.id
        let arr = this.state.rangeJsonList
        let tempRangeList = []
        let rangeFrom = document.getElementById(`rangeInputFrom_${order.id}`).value
        let rangeTo = document.getElementById(`rangeInputTo_${order.id}`).value
        let isRangeExist = false
        let usedqty = 0
        let isEmptyCell  =  false 

        // let plusButton = document.getElementById(`range_add_${id}`)
        let compareRangeList  =  this.state.rangeJsonList.filter((list)=>list.productId === order.id)
        usedqty = compareRangeList.reduce((accom,list)=> +list.usedCardQty+accom,0) 
       
        // Prevent From adding extra box withought entring data in empty box
        compareRangeList.map(list => {
            if(list.rangeFrom === "" || list.rangeTo === "") {
                isEmptyCell  = true 
            }
        })
        if(isEmptyCell) {
            return 0
        } //end 
       
        if(usedqty === +order.noOfCards) {
            
            setTimeout(()=>{this.setState({flashMessageRangeError:""})},3000)
            return 0
        } else if (usedqty > +order.noOfCards) {
            this.setState({flashMessageRangeError:"Please check all ranges "})
            setTimeout(()=>{this.setState({flashMessageRangeError:""})},3000)
            return 0;

        } else if(Number(bigInt(rangeTo.substring(0,18)).minus(rangeFrom.substring(0,18))) >=  +order.noOfCards ){
            this.setState({flashMessageRangeError:"Please check all ranges "})
            setTimeout(()=>{this.setState({flashMessageRangeError:""})},3000)
            return 0;
        } 
        else if(usedqty===0) {
           
            return 0
        }
        let jsonid = this.state.jsonId.filter(json => json.jsonid == order.id)
        let tempCount = jsonid[0].count
        compareRangeList.map(list => {
            
            let str = list.jsonId;
            let n = str.lastIndexOf('_');
            let result = str.substring(n + 1);
            if(+result === tempCount) {
                tempCount+=1
            }
            
        })

        let rangeJson = {
            id: 0,
            rangeFrom:'',
            rangeTo: '',
            cardQty: order.noOfCards,
            usedCardQty:0,
            productId: order.id,
            jsonId: `${order.id}_${tempCount}`
        }

        tempCount = tempCount + 1
        jsonid[0]['count'] = tempCount
        arr.push(rangeJson);
        tempRangeList.push(rangeJson)
        this.setState({ rangeJsonList: arr })
        let tempDbRange = this.state.dbExistRange
        let XtempDbRange = [...tempRangeList, ...tempDbRange]
    
        this.setState({ dbExistRange: XtempDbRange })

        


    }




    /**
     * Method for Change in exxisting Json 
     * @param {event}
     * @param {productId}
     * @param {index}
     */
    changeJson = (event, productId, index,order) => {
        try {   
               
                this.onlyNumber(event)
                let rangeList = this.state.rangeJsonList;
                let dbRange = this.state.dbExistRange
                let rangeCompareList = []
                let filtredZeroIndexRange = []
                let targetList = []
                let usedQty = 0 
                let targateId  =  ''
                let fieldName = event.target.name 
                let totalCards = +order.noOfCards
                let min = ''
                let max = ''
                let isRange = false
                let clipboardData, pastedData;
                // Stop data actually being pasted into div
                event.stopPropagation();
                event.preventDefault();
                let isIE = /*@cc_on!@*/false || !!document.documentMode;
                // Edge 20+
                let  isEdge = !isIE && !!window.StyleMedia;
                // Get pasted data via clipboard API
            
                clipboardData = event.clipboardData || window.clipboardData;
                if(clipboardData!==undefined) {
                    pastedData = clipboardData.getData('Text');

                } else  {
                    pastedData =  event.target.value
                }
               
               
                if(fieldName.includes('rangeTo_')) {
                    targateId = fieldName.substr(8)
                    max = event.target.value    
                    min = document.getElementsByName(`rangeFrom_${targateId}`)[0].value
                } else {
                    targateId = fieldName.substr(10)
                    min = event.target.value          
                    max = document.getElementsByName(`rangeTo_${targateId}`)[0].value
                }
                document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: #000 solid 1px;" 
                document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: #000 solid 1px;" 

                filtredZeroIndexRange = this.state.indexZeroRange.filter(list=>productId === list.productId)
                rangeCompareList    =  rangeList.filter(list=>list.jsonId!==targateId && productId === list.productId)
                targetList          =  rangeList.filter(list=>list.jsonId===targateId && productId === list.productId)
                usedQty = [...rangeCompareList].reduce((acc,list)=> +(list.usedCardQty)+ acc,0) 
                let rem = totalCards-usedQty



                //From Range check digit validation     
                if(fieldName.includes('rangeFrom_') && min.length === 19) {
                    let fromRangeCheckDigit = String(min).charAt(18);
                    let checkSumFrom = this.luhn_checksum(min.substring(0,18))
                    if(+fromRangeCheckDigit !==  +checkSumFrom ) {
                        document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: red solid 1px;" 

                        this.setState({checkDigitWarnning : "Check digit you entered is different than the one calculated using LUHN-CALCULATE algorithm" });
                        setTimeout(()=>{
                            document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: #000 solid 1px;" 

                            this.setState({checkDigitWarnning : "" });
                        },3000);
                    }
                }
                //


                if(fieldName.includes('rangeFrom_')) {
                    let firstDigit = pastedData[0]
                    let traillingZeros = 0
                    let zero = ''
                    let predictedTo = ''
                 
                    if(+firstDigit === 0) {
                        
                        let predictedTo = String(bigInt(pastedData).add(rem).subtract(1).value).substring(0,18)
                        traillingZeros = 18-predictedTo.length
                        
                        for(let i = 1;i<=traillingZeros;i++) {
                            zero += '0'
                        }
                        
                        
                        if(predictedTo.length < 18) {
                            if(pastedData.length ===18) {
                                targetList[0].rangeTo       = ''
                                targetList[0].rangeFrom     = pastedData
                                targetList[0].rangeTo       = zero + String(bigInt(pastedData.substring(0,18)).add(rem).minus(1).value) + this.luhn_checksum(String(bigInt(pastedData.substring(0,18)).add(rem).minus(1).value))
                                targetList[0].usedCardQty   = rem
                            } else {
                                targetList[0].rangeTo       = ''
                                targetList[0].usedCardQty    = 0
                                targetList[0].rangeFrom     = pastedData
                            }
                        
                        } else {
                            if(pastedData.length ===18) {
                                targetList[0].rangeTo       = ''
                                targetList[0].rangeFrom     = pastedData
                                targetList[0].rangeTo       = String(predictedTo.substring(0,18)) + String(this.luhn_checksum(predictedTo.substring(0,18)))
                                targetList[0].usedCardQty    = rem
                            } else {
                                targetList[0].rangeTo       = ''
                                targetList[0].usedCardQty    = 0
                                targetList[0].rangeFrom     = pastedData
                            }
                        
                        }
                        
                    } else {
                        
                      /**** Support For MS Browsers  ******************************/
                       
                        let toRange__ = ''
                        
                        if(isEdge && (pastedData.length === 18 || pastedData.length === 19  ) ) {
                            
                            let coreData = String(bigInt(pastedData.substring(0,18)).add(rem).minus(1)).replace(/,/g, '');
                            
                            let checkSum =  this.luhn_checksum(String(coreData))
                            toRange__ = String(coreData) + String(checkSum)
                           
                        } else  {
                            toRange__ = String(bigInt(pastedData.substring(0,18)).add(rem).minus(1)) + String(this.luhn_checksum(String(bigInt(pastedData.substring(0,18)).add(rem).minus(1).value)))
                        }
                    /**************************End  ***************** */
                      
                        if(toRange__.substring(0,18).length > 18) {
                            this.setState({toRangeErrorMessage :"To Range should not be more than 19 digits"})
                            document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: red solid 1px;" 
                            document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: red solid 1px;" 
                            setTimeout(()=>{this.setState({toRangeErrorMessage:''})},3000)                        
                        }
                      
                       
                        if(pastedData.length ===19) {
                          
                            targetList[0].rangeTo       = ''
                            targetList[0].rangeFrom     = pastedData
                            targetList[0].rangeTo       = toRange__
                            targetList[0].usedCardQty    = rem
                        } else {
                            targetList[0].rangeTo       = ''
                            targetList[0].usedCardQty    = 0
                            targetList[0].rangeFrom     = pastedData
                        }
                    }   

                   
                   
                } else {
                    targetList[0].rangeTo = pastedData     
                    let diff = ''
                    if(isEdge &&  pastedData.length === 19 ) { 
                        let toRange_ =  String(pastedData).substring(0,18);
                        let fromRange_ =  String(targetList[0].rangeFrom).substring(0,18)
                        diff = bigInt(toRange_).minus(fromRange_).add(1).value                                                                  
                         
                    } else {
                        diff = Number(bigInt(pastedData.substring(0,18)).minus(targetList[0].rangeFrom.substring(0,18) ).value) + 1; 
                    }
                    if(pastedData.length===19 ){
                        targetList[0].rangeTo =  pastedData
                        targetList[0].usedCardQty = diff
                    } else {
                        targetList[0].usedCardQty = ''
                    }
            
                }
               
                /***Test for Range duplication */
                let tempListToCompare = dbRange.filter(list=>list.jsonId !== targateId)
                let listToCompare = [...tempListToCompare,...rangeList.filter(list=>list.jsonId!==targateId)]
                min = targetList[0].rangeFrom.substring(0,18)
                max = targetList[0].rangeTo.substring(0,18)
               
                tempListToCompare.map(list => {
                    let cond1 = bigInt(min).greaterOrEquals(list.rangeFrom.substring(0,18)) && bigInt(min).lesserOrEquals(list.rangeTo.substring(0,18))
                    let cond2 = bigInt(max).lesserOrEquals(list.rangeTo.substring(0,18)) && bigInt(max).greaterOrEquals(list.rangeFrom.substring(0,18))
                    let cond3 = bigInt(min).lesserOrEquals(list.rangeFrom.substring(0,18)) && bigInt(max).greaterOrEquals(list.rangeTo.substring(0,18))
                  
                    if(pastedData.length===19 ) {
                        if(cond1 || cond2 || cond3) {
                            isRange = true
                          
                            return 0
                        } else {
                           
                            this.setState({ existRangeError:false,isRangeExist:false})

                        } 
                    } else {
                       
                        this.setState({ existRangeError:true})
                    }
                      
                    return 0
                })
                
                if(pastedData.length===19) {
                    if(isRange) {
                        this.setState({ 
                            dbExistRangeErrorMessage: "Range is already used! ",
                            isRangeExist:true,
                            existRangeError:true
                        })
                        setTimeout(() => { this.setState({ dbExistRangeErrorMessage: '' }) }, 5000)
                        document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: red solid 1px;" 
                        document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: red solid 1px;" 
                    } else  {
                        this.setState({ existRangeError:false,isRangeExist:false})
                        
                    }
                }

                /***Range Duplication in case of Edit page  */
                if(this.state.rangePageEditMode && pastedData.length===19) {
                    min = targetList[0].rangeFrom.substring(0,18)
                    max = targetList[0].rangeTo.substring(0,18)
                    
                    let filterRanges_ = rangeList.filter(list=>list.jsonId!==targateId )
                    
                    filterRanges_.map(list => {
                        let cond1 = bigInt(min).greaterOrEquals(list.rangeFrom.substring(0,18)) && bigInt(min).lesserOrEquals(list.rangeTo.substring(0,18))
                        let cond2 = bigInt(max).lesserOrEquals(list.rangeTo.substring(0,18)) && bigInt(max).greaterOrEquals(list.rangeFrom.substring(0,18))
                        let cond3 = bigInt(min).lesserOrEquals(list.rangeFrom.substring(0,18)) && bigInt(max).greaterOrEquals(list.rangeTo.substring(0,18))
                      
                        if(pastedData.length===19) {
                            if(cond1 || cond2 || cond3) {
                                isRange = true
                              
                                return 0
                            } else {
                               
                                this.setState({ existRangeError:false,isRangeExist:false})
    
                            } 
                        } else {
                           
                            this.setState({ existRangeError:true})
                        }
                          
                        return 0
                    })
                    
                    if(pastedData.length===19) {
                        if(isRange) {
                            this.setState({ 
                                dbExistRangeErrorMessage: "Range is already used! ",
                                isRangeExist:true,
                                existRangeError:true
                            })
                            setTimeout(() => { this.setState({ dbExistRangeErrorMessage: '' }) }, 5000)
                            document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: red solid 1px;" 
                            document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: red solid 1px;" 
                        } else  {
                            this.setState({ existRangeError:false,isRangeExist:false})
                            
                        }
                    }
                } 


                /**End  */
  
                // this.isRangeExistInJsonEdit(event, productId, index)
                
                this.setState({ rangeJsonList: rangeList })
                let tempDbRange = [...this.state.dbExistRange , ...targetList]
                // tempDbRange.push(...this.state.rangeJsonList)
                this.setState({ dbExistRange: tempDbRange })
              
                let eraceCell = this.state.rangeJsonList.filter(list=>list.productId===order.id).length
                
                if(targetList[0].rangeFrom.length ===0 && targetList[0].rangeTo.length===0 && +order.noOfCards!==1 && eraceCell> 1) {
                    this.deleteJson(event, productId, index)
                } 
                // this.handleInputFiled(productId)
                return 0



        }  catch(e){ console.log("Error code ",e)}
       
    }


    


    /**
     * Method for checking continue button to enable 
     * 
     */
    checkContinue = () => {
        try {
            let rangeList = this.state.rangeJsonList;
            let productList = this.state.orderDetails.orderedProduct;
            let buttonDisabled = true
            let tempButtonDisabled = []
            let isValidLength = true
            if (productList.length > 0) {
                let result = productList.map((product) => {
                    let totalUsedQty = 0
                    if (rangeList.length > 0) {
                        rangeList.map((range) => {
                        
                            if(range.rangeFrom.length<19 || range.rangeTo.length < 19) {
                                isValidLength=false
                            }
                            if (product.id === range.productId) {
                                totalUsedQty += +range.usedCardQty
                            }
                            return 0
                        })
                    }
                    return +totalUsedQty === +product.noOfCards && isValidLength
                })
                tempButtonDisabled = result.filter((validResult) => validResult === true)

                if (tempButtonDisabled.length === result.length) {
                    buttonDisabled = false
                }
            }
            return buttonDisabled
        } catch (e) { console.log("hi this error in checkContinue", e) }
    }

    /**
     * Method for validate Ranges 
     * @param {event}
     * @param { order }
     * 
     */
    validateRanges = (event, order,ind) => {
       
        try {
           
            if(event.key === 'a' || event.key === 'Control' || event.key === 'c' || event.metaKey === '⌘' || event.key === 'C') {
                return 0
            }
            this.onlyNumber(event, order.id, order.noOfCards, order)
            let self = this 
            // let plusButton = document.getElementById(`range_add_${order.id}`)
            let isAutoPlusbtn = this.state.isAutoPlusButton
            let value = event.target.value
            
            let arr = this.state.rangeJsonList
            let zeroIndex = this.state.indexZeroRange
            let tempRangeList = []
            
            let isRangeExistArray = []
            // let plusButton = document.getElementById(`range_add_${id}`)
            let compareRangeList  =  [...self.state.dbExistRange ,...this.state.rangeJsonList].filter(list=>list.jsonId !== `${order.id}_0`)
            let jsonid = this.state.jsonId.filter(json => json.jsonid == order.id)
            let tempCount = jsonid[0].count
            let min =''
            let max = ''
            let isRange = false

            // Making Default input cell boarder color 
            document.getElementById(`rangeInputFrom_${order.id}`).style = "border: #000 solid 1px;" 
            document.getElementById(`rangeInputTo_${order.id}`).style = "border: #000  solid 1px;" 
            let clipboardData, pastedData;
            // Stop data actually being pasted into div
            event.stopPropagation();
            event.preventDefault();
            // Get pasted data via clipboard API
            clipboardData = event.clipboardData || window.clipboardData;
            if(clipboardData!==undefined) {
                pastedData = clipboardData.getData('Text');

            } else  {
                pastedData =  event.target.value
            }
            if(event.target.id.includes('rangeInputFrom_')) {
                document.getElementById(`rangeInputFrom_${order.id}`).value = pastedData
            } else {
                document.getElementById(`rangeInputTo_${order.id}`).value = pastedData; // value Range to input
            }
            let formValue = document.getElementById(`rangeInputFrom_${order.id}`).value; //value Range from input 
            let toValue = document.getElementById(`rangeInputTo_${order.id}`).value
            // Method for Predict Range in To Range Box
            this.predictRange(event,order)

            toValue = document.getElementById(`rangeInputTo_${order.id}`).value;
            
            
            // this.isDuplicateRanges(event, order.id, order.noOfCards,ind)
            if ((formValue !== '' && toValue !== '') && (formValue.length === 19 && toValue.length === 18 || toValue.length === 19)) {
                // document.getElementById(`rangeInputTo_${order.id}`).value = String(toValue.substring(0,18))+String(this.luhn_checksum(String(toValue.substring(0,18)))) == 0? '': String(toValue.substring(0,18))+String(this.luhn_checksum(String(toValue.substring(0,18))))
                
                //From Range check digit validation     
                if(event.target.id.includes('rangeInputFrom_')&&formValue.length === 19) {
                    let fromRangeCheckDigit = String(formValue).charAt(18);
                    let checkSumFrom = this.luhn_checksum(formValue.substring(0,18))
                    if(+fromRangeCheckDigit !==  +checkSumFrom ) {
                        document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 

                        this.setState({checkDigitWarnning : "Check digit you entered is different than the one calculated using LUHN-CALCULATE algorithm" });
                        setTimeout(()=>{
                            document.getElementById(`rangeInputFrom_${order.id}`).style = "border: #000 solid 1px;" 

                            this.setState({checkDigitWarnning : "" });
                        },3000);
                    }
                }

               
                //


                // this.isExistRange(event,order, order.id)
                if (bigInt(toValue.substring(0,18)).greaterOrEquals(formValue.substring(0,18))) {
                    min = formValue.substring(0,18)
                    max = toValue.substring(0,18)
                    compareRangeList.map(list => {
                        let cond1 = bigInt(min).greaterOrEquals(list.rangeFrom.substring(0,18)) && bigInt(min).lesserOrEquals(list.rangeTo.substring(0,18))
                        let cond2 = bigInt(max).lesserOrEquals(list.rangeTo.substring(0,18)) && bigInt(max).greaterOrEquals(list.rangeFrom.substring(0,18))
                        let cond3 = bigInt(min).lesserOrEquals(list.rangeFrom.substring(0,18)) && bigInt(max).greaterOrEquals(list.rangeTo.substring(0,18))
                        
                        if(event.target.value.length===18 || event.target.value.length===19) {
                            if(cond1 || cond2 || cond3) {
                                isRange = true
                              
                                return 0
                            } else {
                               
                                this.setState({ existRangeError:false,isRangeExist:false})
    
                            } 
                        } else {
                            this.setState({ existRangeError:true})
                        }
                          
                        return 0
                    })
                 
                    if(isRange) {
                       
                        this.setState({flashMessageRangeError:"Range is already used !"})
                        document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 
                        document.getElementById(`rangeInputTo_${order.id}`).style = "border: red  solid 1px;" 
                        setTimeout(()=>{this.setState({flashMessageRangeError:''})},3000)
                    }
                
                    if(tempCount === 0 ) {       
                        let rangeJson = {
                            id: 0,
                            rangeFrom:formValue,
                            rangeTo: toValue,
                            cardQty: order.noOfCards,
                            usedCardQty:Number(bigInt(toValue.substring(0,18)).minus(formValue.substring(0,18)))+1,
                            productId: order.id,
                            jsonId: `${order.id}_${tempCount}`
                        }
                        tempCount = tempCount + 1
                        jsonid[0]['count'] = tempCount
                        arr.push(rangeJson);
                        
                        // tempRangeList.push(rangeJson)
                        zeroIndex.push(rangeJson)
                        // this.setState({ rangeJsonList: arr })
                        this.setState({zeroIndex:zeroIndex})
                        let tempDbRange = this.state.dbExistRange
                        let XtempDbRange = [...zeroIndex, ...tempDbRange]
                    
                        this.setState({ dbExistRange: XtempDbRange })
                    } else {
                        let dbExistRange = this.state.indexZeroRange
                        let targetRangeList =  dbExistRange.filter(list =>  list.jsonId === `${order.id}_0`)
                        targetRangeList[0].rangeFrom = formValue
                        targetRangeList[0].rangeTo = toValue //document.getElementById(`cards_${order.id}`).value
                        targetRangeList[0].usedCardQty = document.getElementById(`cards_${order.id}`).value
                        

                    }
                
                } else {

                }
            } else {
                let formValue = document.getElementById(`rangeInputFrom_${order.id}`).value; //value Range from input 
                let toValue = document.getElementById(`rangeInputTo_${order.id}`).value
                
            }
            if(formValue === '' || formValue.length === 0) {
                document.getElementById(`rangeInputTo_${order.id}`).value = ''  
            }

            
        } catch (e) { console.log("some error ", e) }
    }
    
    luhn_caclulate(code) {
        var len = code.length
        var parity = len % 2
        var sum = 0
        for (var i = len-1; i >= 0; i--) {
            var d = +(code.charAt(i))
            if (i % 2 == parity) { d *= 2 }
            if (d > 9) { d -= 9 }
            sum += d
        }
        return sum % 10
    }

    luhn_checksum(partcode) { //luhn_caclulate
        var checksum = this.luhn_caclulate(partcode + "0")
    
        return checksum == 0 ? 0 : 10 - checksum
    }    


    /**
     * Method for Predict Range 
     */
    predictRange =(event,order) =>{

        let { indexZeroRange } = this.state
        let formValue = document.getElementById(`rangeInputFrom_${order.id}`).value; //value Range from input 
        // value Range to input 
        let plusButton = document.getElementById(`range_add_${order.id}`)
        let isAutoPlusbtn = this.state.isAutoPlusButton
        let value = event.target.value
        let currentProduct = []
        let usedQty = +order.noOfCards
        currentProduct = this.state.rangeJsonList.filter(list=>list.productId === order.id && list.jsonId !== `${list.productId}_0`)
        
        if(formValue.length === 19 && event.target.name==="rangeFrom") {
            if(0) {     
                let usedQty_ = currentProduct.reduce((acc,list)=> acc+ +(list.usedCardQty),0)
                usedQty = +order.noOfCards - usedQty_
                if(usedQty===0) {
                    usedQty = usedQty_   
                }
                document.getElementById(`rangeInputTo_${order.id}`).value= ''
                document.getElementById(`rangeInputTo_${order.id}`).value = bigInt(formValue).add(usedQty).subtract(1)
            } else {
                let usedQty_ = currentProduct.reduce((acc,list)=> acc+ +(list.usedCardQty),0)
                usedQty = +order.noOfCards - usedQty_
                if(usedQty===0) {
                    usedQty = usedQty_   
                } if(usedQty<0) {
                    usedQty = 1
                }
                let firstDigit = formValue[0]
                let traillingZeros = 0
                let zero = ''
                if(+firstDigit === 0) {
                
                    let predictedTo = bigInt(formValue.substring(0,18)).add(usedQty).subtract(1)
                    
                    traillingZeros = 18-String(predictedTo).length
                    
                    for(let i = 1;i<=traillingZeros;i++) {
                        zero += '0'
                    }
                 
                    if(String(predictedTo).length < 18) {
                        document.getElementById(`rangeInputTo_${order.id}`).value = zero + String(bigInt(formValue.substring(0,18)).add(usedQty).subtract(1).value) + `${this.luhn_checksum(String(bigInt(formValue.substring(0,18)).add(usedQty)))}`
                    } else if(String(predictedTo).length===18)  {
                       
                        document.getElementById(`rangeInputTo_${order.id}`).value =  String(bigInt(formValue.substring(0,18)).add(usedQty).subtract(1).value) + `${this.luhn_checksum(String(bigInt(formValue.substring(0,18)).add(usedQty)))}`
                    } else {
                        document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 
                        document.getElementById(`rangeInputTo_${order.id}`).style = "border: red solid 1px;" 
                        this.setState({toRangeErrorMessage :"To Range should not be more than 19 digits"})
                        // document.getElementById(`rangeInputTo_${order.id}`).value =  bigInt(formValue).add(usedQty).subtract(1) 
                    }
    
                } else {
                        document.getElementById(`rangeInputTo_${order.id}`).value = String(bigInt(formValue.substring(0,18)).add(usedQty).subtract(1)) +`${this.luhn_checksum(String(bigInt(formValue.substring(0,18)).add(usedQty).subtract(1)))}`
                }
               
            }
            
        } else if(formValue.length  <= 19 && event.target.name==="rangeFrom")  {
            document.getElementById(`rangeInputTo_${order.id}`).value = ''

        }
        
        let toValue = document.getElementById(`rangeInputTo_${order.id}`).value; 
        if((toValue.length ===19||toValue.length ===18) && formValue.length ===19)  {
           
            let compare = bigInt(toValue.substring(0,18)).compare(formValue.substring(0,18))
           
            if(compare >=0 ) {
                let numberOfCards  = bigInt(toValue.substring(0,18)).minus(formValue.substring(0,18)).add(1)
                
                if(bigInt(+order.noOfCards).compare(numberOfCards) !== -1 ) {
                    if(Number(numberOfCards.value) >0 && +order.noOfCards >= Number(numberOfCards.value)) {
                        document.getElementById(`cards_${order.id}`).value = Number(numberOfCards.value)
                        
                        this.setState({toRangeErrorMessage:''}) //toRangeErrorMessage //dbExistRangeErrorMessage

                    } else {
                        document.getElementById(`cards_${order.id}`).value = ''
                        if(bigInt(toValue).compare(formValue) < 0) {
                            document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 
                            document.getElementById(`rangeInputTo_${order.id}`).style = "border: red solid 1px;" 
                            this.setState({toRangeErrorMessage: 'Range to should be greater than range from'})
                            setTimeout(()=>{ this.setState({toRangeErrorMessage: ''})},3000)

                        }
                    }
                } else {
                    document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 
                    document.getElementById(`rangeInputTo_${order.id}`).style = "border: red solid 1px;" 
                    this.setState({toRangeErrorMessage: 'Range difference should not be greater than the total number of cards. '})
                    setTimeout(()=>{this.setState({toRangeErrorMessage:""})},3000)
                }
                
            } else {
                document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 
                document.getElementById(`rangeInputTo_${order.id}`).style = "border: red solid 1px;" 
                this.setState({toRangeErrorMessage: 'Range to should be greater than range from '})
                setTimeout(()=>{ this.setState({toRangeErrorMessage: ' '})},3000)

            }
            
            
        } else {
            if(toValue.length >= 20) {
                document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 
                document.getElementById(`rangeInputTo_${order.id}`).style = "border: red solid 1px;" 
                this.setState({toRangeErrorMessage :" To Range should not be more than 19 digits  "})
                setTimeout(()=>{this.setState({toRangeErrorMessage:''})},3000) 
            }
            document.getElementById(`cards_${order.id}`).value = ''

        }
    }

    
      
    /**
     * Method for Checking duplicate Ranges in Input Box 
     * @param {event}
     * @param {orderId}
     * @param {noOfCards}
     */
    isDuplicateRanges = (event, orderId, noOfCards,indexId) => {
        try {
          
            let self = this;
            let rangeValue = event.target.value
            let finalResult = false;
            let plusButton = document.getElementById(`range_add_${orderId}`)
            let domElementsCollection = document.getElementsByClassName("maincollections")
            let domElements =  [].slice.call(domElementsCollection[0].children)
            let duplicatearray = this.state.duplicatearray
            let tempArray = duplicatearray
            let compare = []
            let targateId = ''
            if(event.target.id.includes('rangeInputFrom_')) {
                targateId = event.target.id.substr(15)
                
            } else {
                targateId =event.target.id.substr(13)
                
            }
            
            domElements.map((ele, i)=>{
                
                if(i!==0) {
                   
                    let finalDomElement = domElements[i].children.item(0).children.item(1).children.item(0).children.item(0).children.item(0).children

                    let fromValue = finalDomElement.item(0).children.item(0).value
                    let toValue = finalDomElement.item(1).children.item(0).value //rangeInputFrom_5819
                    let id = finalDomElement.item(0).children.item(0).id.substr(15)
                    if(fromValue.length===19) {
                    
                        compare.push({id:id,rangeFrom:fromValue,rangeTo :toValue})
                      
                    
                    }
                    compare.map((list,inde) => {
                        let targetRanegFrom = list.rangeFrom
                        let targetRanegTo = list.rangeTo

                        if(list.id!==targateId) {
                            let cond1 = bigInt(list.rangeFrom).greaterOrEquals(targetRanegFrom) && bigInt(list.rangeFrom).lesserOrEquals(targetRanegTo)
                            let cond2 = bigInt(list.rangeTo).lesserOrEquals(targetRanegTo) && bigInt(list.rangeTo).greaterOrEquals(targetRanegFrom)
                           if(cond1 || cond2) {
                            this.setState({
                                dbExistRangeErrorMessage: "Range already used !",
                                
                            })
                            this.setTimeout(()=>{this.setState({dbExistRangeErrorMessage:""})},5000)
                           }
                        }

                        return 0
                    })
                  
                }
             
            })
          
        } catch (e) { console.log("error from isDuplicateRanges", e) }
    }



    /**
     * Method for delete json
     * @param {event}
     * @param {order}
     * @param {index}
     * 
     */
    deleteJson = (event, id, index) => {
        try {
            let tempRangeListJson = this.state.rangeJsonList;
            let xObj = []
            let dbExistingRangeList = this.state.dbExistRange
            let jsonid = this.state.jsonId.filter(json => json.jsonid == id)

            let tempCount = jsonid[0].count
            tempCount = tempCount - 1
            jsonid[0]['count'] = tempCount
            tempRangeListJson.map((list, i) => {
                if ((list.productId === id) && (index === i)) {
                    xObj = tempRangeListJson.splice(i, 1)
                }
                return 0
            })
            this.setState({ rangeListJson: tempRangeListJson })
            dbExistingRangeList.map((list, index) => {

                if (bigInt(xObj[0].rangeFrom).equals(list.rangeFrom) && bigInt(xObj[0].rangeTo).equals(list.rangeTo)) {
                    dbExistingRangeList.splice(index, 1)
                }
                return 0
            })
            this.handleInputFiled(id)
        } catch (e) { }
    }

    handleInputFiled(order) {
        return 0
        try {

            let noOfCards = 0;
            let productId = 0

            if (!isNaN(order)) {

                let _order = this.state.orderDetails.orderedProduct.filter(orderList => orderList.id === order)

                noOfCards = +_order[0].noOfCards
                productId = _order[0].id
            } else {
                noOfCards = +order.noOfCards
                productId = order.id
            }

            let htmlCollections = document.getElementsByClassName(`hideRange_${productId}`)
            let domElementArray = Array.prototype.slice.call(htmlCollections)
            if (this.state.rangeJsonList.length > 0) {
                let total = 0

                let filteredList = this.state.rangeJsonList.filter(list => list.productId === productId)
                if (filteredList.length > 0) {
                    filteredList.map((list) => {
                        total += list.usedCardQty
                        return 0
                    })
                }

                if (total === noOfCards || noOfCards===1) {
                    domElementArray.map(element => {
                        element.style.visibility = "hidden"
                        return 0
                    })
                } else {

                    domElementArray.map(element => {
                        element.style.visibility = "visible"
                        return 0
                    })
                }
            } else {

            }

        } catch (e) { console.log("hanlde input filed ", e) }


    }
    /**
     * Method for enabling Edting input field 
     */
    enableEditing = (e) => {
        let newJsonList = []
        let tempJson = this.state.rangeJsonList
        this.setState({ isURLEditable: false,isRangeEdit:true })
        tempJson.map(list => {
            let tempJsonEditable = {
                ...list,
                isreadOnly: false
            }

            newJsonList.push(tempJsonEditable)

            return 0;
        })
        this.setState({ rangeJsonList: newJsonList })
    }

    /**
     * Method for editing url 
     */
    enableUrlEditing = (event) => {
        
        this.setState({ isURLEditable: false ,isRangeEdit:false})
    }


    /** Method for close model*/
    onCloseCancelModal = (e) => { this.setState({ openCancelOrderModal: false }) }

    /** Method for cancel order*/
    cancelOrder = (e) => {
        let data ={
            "orderId": this.state.orderId_edit, "loggedInUser": sessionStorage.userID, "language": "en", "cancelReason": this.state.cancellationReason, "cancelApiData": false,"amount":this.state.orderDetails.totalAmount
        }
        if(data.cancelReason === "") {
            this.setState(state=>({cancelReasonError:"Please provide the reason for cancellation"}));
            return 0
        }
	var historyId = this.state.orderDetails.historyId;
	//console.log("orderDetails:: ", this.state.orderDetails);
	var array = historyId.split("_");
	let newcancelApiData = {
	id: array[0],
	tag: array[1],
	amount: array[2],
};
data.cancelApiData=newcancelApiData
        
        this.setState({ Loading: true })
        this.props.cancelOrderAction(data)
    }

    /**
     * Method for handle onClick edit
     */

    onCancle = (e) => {
        this.setState({ openCancelOrderModal: true })
    }
    onPrintClick=(val)=>{
        this.setState({openInvoiceModal:val})
      }
    /**
     * Method for setting reason for cancellation 
     * @param {click event}  
     */
    setReason(e) {
        var val = e.target.value
        this.setState({
            flashMessageError: '',
            cancellationReason: val,
            cancelReasonError:""
        })
    }

    /**
     * Method for test Range 
     */
    testRange = (e,order,i) => {
        

        let fromValue = document.getElementById(`rangeInputFrom_${order.id}`).value; //value Range from input 
        let toValue = document.getElementById(`rangeInputTo_${order.id}`).value; //value Range from input 

        if(e.target.id.includes('rangeInputTo_') && toValue.length === 19) {
            let fromRangeCheckDigit = String(toValue).charAt(18);
            let checkSumFrom = this.luhn_checksum(toValue.substring(0,18))
            if(+fromRangeCheckDigit !==  +checkSumFrom ) {
                this.setState({checkDigitWarnning : "Check digit you entered is different than the one calculated using LUHN-CALCULATE algorithm" });
                document.getElementById(`rangeInputTo_${order.id}`).style = "border: red solid 1px;" 
               
                setTimeout(()=>{
                    document.getElementById(`rangeInputTo_${order.id}`).style = "border: #000 solid 1px;" 

                    this.setState({checkDigitWarnning : "" });
                },3000);
            }
        }
        if(fromValue.length !==19) {
            this.setState({flashMessageRangeError:"Range must be 19 digits "})
            setTimeout(()=>{
            this.setState({flashMessageRangeError:""})

            },3000)
            document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 
         } else if(fromValue.length !==19 && toValue.length !==19) {

            this.setState({flashMessageRangeError:"Range must be 19 digits "})
            setTimeout(()=>{
            this.setState({flashMessageRangeError:""})

            },3000)
            document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 
            document.getElementById(`rangeInputTo_${order.id}`).style = "border: red solid 1px;" 
            //"border: #000 solid 1px;"
        } else if(toValue.length === 18 && fromValue.length ===19) {
            let toRange = toValue + String(this.luhn_checksum(toValue));
            
            document.getElementById(`rangeInputTo_${order.id}`).value  = toRange
            this.validateRanges(e,order,i)

        } else if(fromValue.length ===19 && toValue.length !== 19) {
            
            this.setState({flashMessageRangeError:"Range must be 19 digits "})
            setTimeout(()=>{
            this.setState({flashMessageRangeError:""})

            },3000)
            document.getElementById(`rangeInputTo_${order.id}`).style = "border: red solid 1px;" 
            
            

        } else if(fromValue.length !==19 && toValue.length === 19) {
            this.setState({flashMessageRangeError:"Range must be 19 digits "})
            setTimeout(()=>{
            this.setState({flashMessageRangeError:""})

            },3000)
            document.getElementById(`rangeInputFrom_${order.id}`).style = "border: red solid 1px;" 

        }


    } 

  
    /**
     * OnBlur Method to check if cell is empty or less than 19 digits
     * @param event  
     * @param productId
     * @param index
     * @param order
     */    


    checkJson = (event, productId, index,order) => {
        try {
            let rangeList = this.state.rangeJsonList;
            let targateId  =  ''
            let fieldName = event.target.name 
            let min = ''
            let max = ''
            let clipboardData, pastedData;
        
            //stop event propagation
            event.stopPropagation();
            event.preventDefault();
            // Get pasted data via clipboard API
            clipboardData = event.clipboardData || window.clipboardData;
            if(clipboardData!==undefined) {
                pastedData = clipboardData.getData('Text');

            } else  {
                pastedData =  event.target.value
            }
        


            if(fieldName.includes('rangeTo_')) {
                targateId = fieldName.substr(8)
                max = pastedData   
                min = document.getElementsByName(`rangeFrom_${targateId}`)[0].value
            } else {
                targateId = fieldName.substr(10)
                min = pastedData         
                max = document.getElementsByName(`rangeTo_${targateId}`)[0].value
            }

            let compareList = rangeList.filter(list =>list.productId === order.id )
            
            // Check Digit Validation To in Other Rows 
            if(event.target.id.includes('rangeTo_') && max.length === 19) {
                let fromRangeCheckDigit = String(max).charAt(18);
                let checkSumFrom = this.luhn_checksum(max.substring(0,18))
                if(+fromRangeCheckDigit !==  +checkSumFrom ) {
                    this.setState({checkDigitWarnning : "Check digit entered is different than the one calculated using LUHN-CALCULATE algorithm" });
                    document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: red solid 1px;" 
                  
                    setTimeout(()=>{
                        document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: #000 solid 1px;" 

                        this.setState({checkDigitWarnning : "" });
                    },3000);
                } else {
                    // document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: red solid 1px;" 

                }
            }

            // End 



            if(min.length <19  && max.length< 19  && max.length!==18) {
                this.setState({flashMessageRangeError:"Range must be 19 digits "})
                setTimeout(()=>{
                this.setState({flashMessageRangeError:""}) },3000)
                document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: red solid 1px;" 
                document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: red solid 1px;" 
            } else if(max.length===18 && min.length === 19) {
               
                let xV = document.getElementsByName(`rangeTo_${targateId}`)[0].value + String(this.luhn_checksum(document.getElementsByName(`rangeTo_${targateId}`)[0].value))
                
                document.getElementsByName(`rangeTo_${targateId}`)[0].value = xV
                this.changeJson(event, productId, index,order)

            } else if(max.length >19)  {
                this.setState({flashMessageRangeError:"To Range should not be  greater than 19 digits "})
                setTimeout(()=>{
                this.setState({flashMessageRangeError:""})},3000)
                document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: red solid 1px;" 
                document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: red solid 1px;"  
            } if(min.length ===19  && max.length === 19) {
                let compare = bigInt(max.substring(0,18)).compare(min.substring(0,18)) 
                let totalQty = compareList.reduce((acc,list)=> +list.usedCardQty + acc,0)
                
                if(compare<0) {

                    document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: red solid 1px;" 
                    document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: red solid 1px;" 
                    this.setState({flashMessageRangeError:"To Range must be greater than From Range "})
                    setTimeout(()=>{
                    this.setState({flashMessageRangeError:""})

                    },3000)
                } 
                if(totalQty > +order.noOfCards) {
                    document.getElementsByName(`rangeTo_${targateId}`)[0].style = "border: red solid 1px;" 
                    document.getElementsByName(`rangeFrom_${targateId}`)[0].style = "border: red solid 1px;" 
                    this.setState({flashMessageRangeError:"Total card quantity should be equal to ordered card quantity"})
                    setTimeout(()=>{
                    this.setState({flashMessageRangeError:""})

                    },3000)
                }
            }
            

        } catch(e) {console.log("Error in checkJson method ",e)}
       
    }





    render() {
        
        if (localStorage.loggedIn == '' || sessionStorage.length == 0) {
           window.location.href =  "/ng-admin"
          }
        let flashMe = this.state.flashRangeValidationErrorMsg
        let dbErrorMessage = this.state.dbExistRangeErrorMessage
        let {toRangeErrorMessage} = this.state 
        let continueDisbaleButton;
        if (continueDisbaleButton === undefined) {
            continueDisbaleButton = 0;
        }
        let _this = this;
        const { orderDetails } = this.state
        var noOfCards = 0
        var totalCards = orderDetails.orderedProduct.length > 0 ? orderDetails.orderedProduct.map((data) => {
            return noOfCards += +(data.noOfCards)
        }) : 0
        let checkOrderProductQuantity
        if (this.state.orderDetails.orderedProduct.length > 0) {
            checkOrderProductQuantity = this.state.orderDetails.orderedProduct.length;
        }
        let countProductRangeQuantity = 0;
        let usedProductRangeQuantity = 0;
        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        let flashErrorMessagetrackingNumberSpan = '';
      
        // let isContinue = this.checkContinue()

        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <Alert className='alertFont' >{this.state.flashMessageSuccess}</Alert>;
        }
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
        }
        if (this.state.flashErrorMessagetrackingNumber) {
            flashErrorMessagetrackingNumberSpan = <Alert className='alertFont' color='danger'>{this.state.flashErrorMessagetrackingNumber}</Alert>;
        }
        let isDelHide = 0
        let isPlus = 0
        let isPluseButton = 0
        let orderIdCheck = 0
       
        return(
            <div className="admin_top">
                {
                    this.state.Loading === true && <div className="loader-react">
                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                    </div>
                }
                <Helmet>
                    <title>Gift Card Number Range</title>
                </Helmet>
                <div className="content-wrapper" className="d-flex flex-column">
                    <Header />
                    <div className="main">
                        <Navigation activeClassName={this.state.activeClassName} />
                        <div className="dashboardContent">
                            <div className="container-fluid">
                                <div className="user-dashboard" id="">
                                    <div className="row mar0">
                                        <div className="container-fluid">
                                            <div className="acknowledgeOrder-wrapper">
                                                <div className="row">
                                                    <div className="col-md-9 customer-section-left">
                                                        <h3 className="top_heading">Gift Card Number Range</h3>
                                                        <div className="printMe">
                         
                         <button className="giftcard_button add_btnfont pull-right button-padding"onClick={()=>this.onPrintClick(true)} >
                           Take a Print
                         </button>
                       </div>
                       {this.state.openInvoiceModal ? <Invoice openInvoiceModal={this.state.openInvoiceModal} onPrintClick={this.onPrintClick} orderDetails={this.state.orderDetails}/> : ""}
   
                                                        <div className="acknowledgeOrder">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="product">
                                                                        <h3>#{orderDetails.orderNumber}</h3>
                                                                        <p>{moment(new Date(orderDetails.orderDate)).format('MM/DD/YYYY')}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="nextOrder">
                                                                        <button type="button" className="btn btn-light">Pin Number</button>
                                                                        <input type="text" className="form-control" id="pin" placeholder={this.state.pin} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    {this.state.rangePageEditMode ?
                                                                        <div className="bitton editrange  text-right">
                                                                            <button
                                                                                className={'btn btn-light giftcard_button_1 mgr10 ml-1'}
                                                                                onClick={(e) => { this.enableEditing(e) }}>Edit Range</button>
                                                                            <button
                                                                                className={'btn btn-light giftcard_button_1 mgr10'}
                                                                                onClick={(e) => { this.enableUrlEditing(e) }}>Edit Tracking Url</button>
                                                                            <button
                                                                                className={'btn btn-light giftcard_button_1'} onClick={(e) => { this.onCancle(e) }} >Cancel Order </button>



                                                                        </div>
                                                                        : void 0}
                                                                </div>
                                                            </div>
                                                            {flashSuceessMessageSpan}
                                                        </div>
                                                        <div className="row maincollections">
                                                            <div className="col-md-12">
                                                                {this.state.errorRangeMessageArray.length > 0 ?
                                                                    this.state.errorRangeMessageArray.map(err => <Alert className="alertFont" color="danger">{err}</Alert> 
                                                                            
                                                                    )
                                                                :void 0
                                                                }
                                                                {
                                                                    dbErrorMessage !== '' ?
                                                                        <Alert className="alertFont" color="danger">{dbErrorMessage}</Alert> : ""
                                                                }
                                                                {
                                                                    toRangeErrorMessage !== "" ?
                                                                    <Alert className="alertFont" color="danger">{toRangeErrorMessage}</Alert> : ""


                                                                }
                                                                {
                                                                    flashMe !== "" ?
                                                                        <Alert className="alertFont" id='rangeErrorAlert' color="danger" >{flashMe}</Alert> : ""
                                                                }

                                                                {

                                                                    this.state.flashMessageRangeError !== '' ?
                                                                        <Alert className="alertFont" color="danger">{this.state.flashMessageRangeError}</Alert> : ""

                                                                }

                                                                {
                                                                this.state.checkDigitWarnning !== "" ?
                                                                    <Alert className="alertFont" color="danger">{this.state.checkDigitWarnning}</Alert> : ""

                                                                }



                                                            </div>
                                                            {
                                                                this.state.orderDetails.orderedProduct.length > 0 ?
                                                                    this.state.orderDetails.orderedProduct.map((order, index) => {
                                                                        isDelHide = 0
                                                                        isPlus = index

                                                                        continueDisbaleButton = (order.productRange.length > 0) ? continueDisbaleButton + 1 : 0;
                                                                        countProductRangeQuantity = +countProductRangeQuantity + +order.noOfCards;

                                                                        return <div className="col-md-12 dividers" key={`dividers${index}`}>
                                                                            <div className="row">
                                                                                <div className="col-md-4 ">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <div className="orderBoxSquare giftcardBox_New Custome_Order">
                                                                                                <img src={constant.APIURL + order.imagePath + order.imageName} className="media-object" alt="" />
                                                                                                <span className="giftOrderCircle">{order.noOfCards}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div id={order.productId}>
                                                                                                <div className="orderBoxText_giftcard">
                                                                                                    <h5 className="orderGift">{order.productType}</h5>
                                                                                                    <span>$  {order.productPrice != '' ? HelperUtil._getAmountCommaSeparator(Number(order.productPrice).toFixed(2)).toString() : 0} </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                                <div className="col-md-8 pb-3">
                                                                                    
                                                                                    <div className="acknowledgeOrderBox acknowledge_btm acknowledge_top ">
                                                                                        {/* <Alert className="alertFont" id='rangeNumericErrorAlert' style={{ display: 'none' }} color='danger'> Range should be 19 digits long</Alert>
                                                                                        <Alert className="alertFont" id='rangeUsedErrorAlert' style={{ display: 'none' }} color='danger'> Range already used</Alert>
                                                                                        <Alert className="alertFont" id='totalQuantityErrorAlert' style={{ display: 'none' }} color='danger'>  Total card quantity should be equal to ordered card quantity</Alert>
                                                                                        <Alert className="alertFont" id='rangeQuantityErrorAlert' style={{ display: 'none' }} color='danger'> Selected range can not be more than product quantity</Alert>
                                                                                        <Alert className="alertFont" id='rangeBlankErrorAlert' style={{ display: 'none' }} animated='true' color='danger'>  Range should be 19 digits long</Alert>
                                                                                        <Alert className="alertFont" id='rangeErrorAlert' style={{ display: 'none' }} color='danger'> Range to should be greater than range from</Alert> */}

                                                                                        <div className="col-md-12 rangeDiv mb-4">
                                                                                            <div className="row">

                                                                                                <div className="col-md-9">
                                                                                                    <div className="row">
                                                                                                <div className={`col-md-6 nopadding range pl-0  hideRange_${order.id}`}>
                                                                                                    <input type="text"
                                                                                                        maxLength={19}
                                                                                                        placeholder='Range from'
                                                                                                        className={`form-control inputHandle ${this.state.cellError ? 'red' :''}`}
                                                                                                        name="rangeFrom"
                                                                                                        onChange={(e) => { this.onlyNumber(e, order.id, order.noOfCards, order) }}
                                                                                                        id={`rangeInputFrom_${order.id}`}
                                                                                                        onKeyUp={(e) => { this.validateRanges(e, order ,index ) }}
                                                                                                        onPaste={(e)=>this.validateRanges(e,order,order.id,index)}
                                                                                                        onBlur = {e=>this.testRange(e,order,index)}
                                                                                                    />

                                                                                                </div>
                                                                                                <div className={`col-md-6 nopadding pl-2 hideRange_${order.id}`}>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className={`form-control inputHandle ${this.state.cellError ? 'red' :''}`}
                                                                                                        name="rangeTo"
                                                                                                        placeholder="Range to"
                                                                                                        maxLength={19}
                                                                                                        onChange={(e) => { this.onlyNumber(e, order.id, order.noOfCards, order) }}
                                                                                                        id={`rangeInputTo_${order.id}`}
                                                                                                        onKeyUp={(e) => { this.validateRanges(e, order ,index ) }}
                                                                                                        onPaste={e=>this.validateRanges(e,order,order.id ,index)}
                                                                                                        onBlur = {e=>this.testRange(e,order)}

                                                                                                    />

                                                                                                </div>
                                                                                                </div>
                                                                                                </div>
                                                                                                <div className="col-md-3">
                                                                                                    <div className="row">
                                                                                                <div className={`col-md-4 nopadding pl-2 hideRange_${order.id}`}>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="form-control inputHandle text-center danger" readOnly
                                                                                                        id = {`cards_${order.id}`}

                                                                                                    />

                                                                                                </div>
                                                                                               {
                                                                                                   this.state.rangeJsonList.map(list=>{
                                                                                                         
                                                                                                        let str = list.jsonId;
                                                                                                        let n = str.lastIndexOf('_');
                                                                                                        let result = str.substring(n + 1);
                                                                                                        if(+result > 0) {
                                                                                                            orderIdCheck = list.productId 
                                                                                                        }
                                                                                                       
                                                                                                   })

                                                                                               } 
                                                                                               {
                                                                                                   orderIdCheck!==order.id && this.state.rangePageEditMode ===false  ? 
                                                                                               
                                                                                                <div className={`col-md-4 newaddpadding hideRange_${order.id} `}>
                                                                                                <div className="giftcardall icon-set">
                                                                                                    <ul>
                                                                                                    
                                                                                                        <li>
                                                                                                            <button
                                                                                                                
                                                                                                                className="fa fa-plus range__add"
                                                                                                                aria-hidden="true"
                                                                                                                id={`range_add_${order.id}`}
                                                                                                                onClick={(e) => { this.addRangeBox(e, order) }}
                                                                                                            >
                                                                                                            </button>
                                                                                                        </li>
                                                                                                    
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                                : void 0 }


                                                                                            
                                                                                                
                                                                                                </div>
                                                                                                </div>
                                                                                
                                                                                                <div>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                            <div className="row">
                                                                                                
                                                                                            {   
                                                                                            this.state.rangePageEditMode ?
                                                                                            this.state.rangeJsonList !== undefined && this.state.rangeJsonList.length > 0 ?
                                                                                            this.state.rangeJsonList.map((rangeList, index) => {
                                                                                                rangeList.productId === order.id ? isDelHide++ : void 0

                                                                                                isPlus = this.state.rangeJsonList.filter(list=>list.productId === order.id).length
                                                                                        
                                                                                            return (
                                                                                                rangeList.productId === order.id  ?
                                                                                                    <div className="col-md-12 pb-3" key={`rangePageEditMode${index}`}>
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-9">
                                                                                                                <div className="row">
                                                                                                            <div className="col-md-6 nopadding pl-0">
                                                                                                                <input
                                                                                                                    id={`rangeFrom_${rangeList.productId}_${index}`}
                                                                                                                    name={`rangeFrom_${rangeList.jsonId}`}

                                                                                                                    value={rangeList.rangeFrom}
                                                                                                                    maxLength={19}
                                                                                                                    className={`form-control inputrange ${rangeList.isreadOnly ? 'readonly' : ''}`}
                                                                                                                    onChange={(e) => { this.changeJson(e, rangeList.productId, index,order) }}
                                                                                                                    readOnly={rangeList.isreadOnly}
                                                                                                                    onPaste = {(e) => { this.changeJson(e, rangeList.productId, index,order) }}
                                                                                                                    placeholder='Range from'
                                                                                                                    onBlur = {e=>this.checkJson(e,rangeList.productId, index,order)}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="col-md-6 nopadding pl-2">
                                                                                                                <input
                                                                                                                    id={`rangeTo_${rangeList.productId}_${index}`}
                                                                                                                    name={`rangeTo_${rangeList.jsonId}`}
                                                                                                                    value={rangeList.rangeTo}
                                                                                                                    maxLength={19}
                                                                                                                    className={`form-control inputrange ${rangeList.isreadOnly ? 'readonly' : ''}`}
                                                                                                                    onChange={(e) => { this.changeJson(e, rangeList.productId, index,order) }}
                                                                                                                    onPaste ={(e) => { this.changeJson(e, rangeList.productId, index,order) }}
                                                                                                                    placeholder="Range to"
                                                                                                                    readOnly={rangeList.isreadOnly}
                                                                                                                    onBlur = {e=>this.checkJson(e,rangeList.productId, index,order)}
                                                                                                                />
                                                                                                            </div>
                                                                                                            </div>
                                                                                                            </div>
                                                                                                            <div className="col-md-3">
                                                                                                            <div className="row">
                                                                                                            
                                                                                                            {
                                                                                                                rangeList.cardQty >= rangeList.usedCardQty && rangeList.usedCardQty > 0 ?

                                                                                                                    <div className="col-md-4 nopadding"><span className="danger form-control ">{rangeList.usedCardQty} </span></div>
                                                                                                                    :
                                                                                                                    <div className="col-md-4 nopadding"><span className="danger form-control  giftbor_all">0</span>
                                                                                                                    </div>

                                                                                                            }
                                                                                                                     {

                                                                                                        isDelHide !== 1 ?
                                                                                                            <div className="col-md-4 rangedelete">
                                                                                                                <div className="giftcardall icon-set ml-2">
                                                                                                                    <ul>
                                                                                                                        <li>
                                                                                                                            <button
                                                                                                                                className={`fa fa-trash-o range__add pt-2 ${rangeList.isreadOnly ? 'disableEvent' : ''}`}
                                                                                                                                aria-hidden="true"
                                                                                                                                onClick={(e) => { this.deleteJson(e, rangeList.productId, index) }}
                                                                                                                                id={`delete_range_${rangeList.productId}_index`
                                                                                                                                }>
                                                                                                                            </button>


                                                                                                                        </li>
                                                                                                                    </ul>

                                                                                                                </div>
                                                                                                            </div> : void 0

                                                                                                        
                                                                                                        }
                                                                                                        {
                                                                                                            isPlus === isDelHide ?
                                                                                                        
                                                                                                      
                                                                                                        <div className="col-md-4">
                                                                                                            <span className="danger form-control "
                                                                                                            
                                                                                                            id={`range_add_${order.id}`}
                                                                                                            onClick={(e) => { this.addRangeBox(e, order,index) }}
                                                                                                            ><i className="fa fa-plus"></i></span>
                                                                                                            
                                                                                                        </div> : void 0 
                                                                                                    }
                                                                                                            </div>

                                                                                                            </div>

                                                                                                   


                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : void 0
                                                                                            )
                                                                                        })
                                                                                        : void 0
                                                                                        :


                                                                                        this.state.rangeJsonList !== undefined && this.state.rangeJsonList.length > 0 ?
                                                                                            this.state.rangeJsonList.map((rangeList, index) => {
                                                                                                rangeList.productId === order.id ? isDelHide++ : void 0
                                                                                                isPlus = this.state.rangeJsonList.filter(list=>list.productId === order.id).length

                                                                                            
                                                                                                return (
                                                                                                    rangeList.productId === order.id && rangeList.jsonId !== `${rangeList.productId}_0` ?
                                                                                                        <div className="col-md-12 pb-3" key={`pb-3${index}`}>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-9">
                                                                                                                    <div className="row">
                                                                                                                <div className="col-md-6 nopadding pl-0">
                                                                                                                    <input
                                                                                                                        id={`rangeFrom_${rangeList.productId}_${index}`}
                                                                                                                        name={`rangeFrom_${rangeList.jsonId}`}

                                                                                                                        value={rangeList.rangeFrom}
                                                                                                                        maxLength={19}
                                                                                                                        className={`form-control inputrange ${rangeList.isreadOnly ? 'readonly' : ''}`}
                                                                                                                        onChange={(e) => { this.changeJson(e, rangeList.productId, index,order) }}
                                                                                                                        readOnly={rangeList.isreadOnly}
                                                                                                                        onPaste = {(e) => { this.changeJson(e, rangeList.productId, index,order) }}
                                                                                                                        placeholder='Range from'
                                                                                                                        onBlur = {e=>this.checkJson(e,rangeList.productId, index,order)}

                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className="col-md-6 nopadding pl-2">
                                                                                                                    <input
                                                                                                                        id={`rangeTo_${rangeList.productId}_${index}`}
                                                                                                                        name={`rangeTo_${rangeList.jsonId}`}
                                                                                                                        value={rangeList.rangeTo}
                                                                                                                        maxLength={19}
                                                                                                                        className={`form-control inputrange ${rangeList.isreadOnly ? 'readonly' : ''}`}
                                                                                                                        onChange={(e) => { this.changeJson(e, rangeList.productId, index,order) }}
                                                                                                                        onPaste ={(e) => { this.changeJson(e, rangeList.productId, index,order) }}
                                                                                                                        placeholder="Range To"
                                                                                                                        readOnly={rangeList.isreadOnly}
                                                                                                                        onBlur = {e=>this.checkJson(e,rangeList.productId, index,order)}

                                                                                                                    />
                                                                                                                </div>
                                                                                                                </div>
                                                                                                                </div>
                                                                                                                <div className="col-md-3">
                                                                                                                    <div className="row">
                                                                                                                {
                                                                                                                    rangeList.cardQty >= rangeList.usedCardQty && rangeList.usedCardQty > 0 ?

                                                                                                                        <div className="col-md-3 newaddpadding hideRange_9257 "><span className="danger form-control ">{rangeList.usedCardQty} </span></div>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                        <div className="col-md-3 newaddpadding hideRange_9257 "><span className="danger form-control  giftbor_all">0</span>
                                                                                                                        </div>
                                                                               
                                                                                                                        </>
                                                                                                                        

                                                                                                                }

                                                                                                                {

                                                                                                                    isDelHide !== 1 ?
                                                                                                                        <div className="col-md-3">
                                                                                                                            <div className="giftcardall icon-set newad_40">
                                                                                                                                <ul>
                                                                                                                                    <li>
                                                                                                                                        <button
                                                                                                                                            className="fa fa-trash-o range__add pt-2"
                                                                                                                                            aria-hidden="true"
                                                                                                                                            onClick={(e) => { this.deleteJson(e, rangeList.productId, index) }}
                                                                                                                                            id={`delete_range_${rangeList.productId}_index`
                                                                                                                                            }>
                                                                                                                                        </button>


                                                                                                                                    </li>
                                                                                                                                </ul>

                                                                                                                            </div>
                                                                                                                        </div> : void 0

                                                                                                                    }
                                                                                                                { 
                                                                                                                    isPlus === isDelHide ? 

                                                                                                                <div className="col-md-3 newaddpadding hideRange_9257 ">
                                                                                                                <span className="danger form-control add_50new"

                                                                                                                id={`range_add_${order.id}`}
                                                                                                                onClick={(e) => { this.addRangeBox(e, order,index) }}
                                                                                                                ><i className="fa fa-plus"></i></span>

                                                                                                                </div> : void 0 
                                                                                                            
                                                                                                            }

                                                                                                                
                                                                                                                </div>


                                                                                                                
                                                                                                                </div>
                                                                                                                
                                                                                                            

                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : void 0
                                                                                                )
                                                                                            })
                                                                                            : void 0
                                                                                    }
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    })
                                                                    : <div>
                                                                    </div>
                                                            }
                                                        </div>
                                                        <form className="billing-address billing-address_new">
                                                            <fieldset>
                                                                {flashErrorMessageSpan}
                                                                {flashErrorMessagetrackingNumberSpan}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        < p className="noteplus">*Note: Please enter card range for all gift cards.</p>
                                                                    </div>
                                                                    <div className="form-group rangesection col-8">
                                                                        <label>Enter tracking number</label>
                                                                        <InputMask
                                                                            value={this.state.trackingNumber}
                                                                            mask={this.state.trackingLengthValidation}
                                                                            maskChar={null} type="text"
                                                                            className={`form-control martop_10 ${this.state.isRangeEdit ? 'readonly' : ''}`} 
                                                                            id="trackingNumberInput" 
                                                                            name='orderTracking' 
                                                                            onChange={(e) => { this.setTrackindDetails(e) }} required="required"
                                                                            readOnly={this.state.isURLEditable}
                                                                            maxLength={12}
                                                                        />
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button
                                                                            type="button"
                                                                            id='continueButtonCheck'
                                                                            className="btn btn-light giftcard_button cardnone_button custome_btn ad30btn" 

                                                                            onClick={this.openShippingDetailPage.bind(this)}
                                                                        >continue</button>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </form>
                                                        {/* {this.state.rangePageEditMode?
                                                    <div className="bitton editrange mt-4 ">    
                                                            <button  
                                                                className={'btn btn-light giftcard_button mgr10'} 
                                                                onClick={(e)=>{this.enableEditing(e)}}>Edit Range</button>
                                                            <button  
                                                                className={'btn btn-light giftcard_button mgr10'} 
                                                                onClick={(e)=>{this.enableUrlEditing(e)}}>Edit Tracking Url</button>
                                                             <button 
                                                                className={'btn btn-light giftcard_button'} onClick={(e)=>{this.onCancle(e)}} >Cancel Order </button> 



                                                        </div>
                                                    :void 0 } */}



                                                        <div className="cancel model">
                                                            <Modal open={this.state.openCancelOrderModal} onClose={(e) => { this.onCloseCancelModal(e) }} center classNames={{ overlay: 'addAddressoverlay', modal: 'acknowledge-modalform' }}
                                                                closeOnOverlayClick={false}
                                                            >
                                                                <div className="modal-dialog ">
                                                                    <div className="modal-content">
                                                                        
                                                                        <div className="modal-header giftcart_title_header account">
                                                                            <h4 className="modal-title giftcart_all_header">Cancel Order</h4>
                                                                            <button type="button" className="close account" data-dismiss="modal">&times;</button>
                                                                        </div>
                                                                        <div className="modal-body ">
                                                                        { this.state.cancelReasonError !== ""? 
                                                                            <Alert className='alertFont' color='danger' >{this.state.cancelReasonError}</Alert> : "" }
                                                                            <p>Are you sure you want to cancel order number #{this.state.orderDetails.orderNumber}</p>
                                                                            <form action="/" className="acknowledge-modal">
                                                                                <label>
                                                                                    Cancellation Reason
                                                                            </label>
                                                                                <div className="cancel_order_modal pb-4">
                                                                                    <textarea className="col-md-12" rows="5" name="cancellationReason" onChange={(e) => this.setReason(e)} id="cancelReason"></textarea>
                                                                                </div>
                                                                                <button type="button" className="giftcard_button cardnone_button mgr10" onClick={(e) => { this.cancelOrder(e) }}>CANCEL ORDER</button>
                                                                                <button type="button" className="giftcard_button" onClick={this.onCloseCancelModal.bind(this)}>CLOSE</button>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Modal>
                                                        </div>



                                                    </div>
                                                    <div className="col-md-3 customer-section-right" id="customer-section-right" style={{ "height": "100vh" }}>
                                                        <div className="side-heading sidegiftcard">
                                                            <ul className="float-left">
                                                                <li>Sub Total</li>
                                                                <li>Discount</li>
                                                                <li>Shipping Charges</li>
                                                            </ul>
                                                            <ul className="text-right pull-right giftcard_range">
                                                                <li>$ {orderDetails.subTotal != '' ? HelperUtil._getAmountCommaSeparator(Number(orderDetails.subTotal).toFixed(2)).toString() : HelperUtil._getAmountCommaSeparator(Number(0).toFixed(2)).toString()}</li>
                                                                <li>-$ {orderDetails.discountAmount != '' ? HelperUtil._getAmountCommaSeparator(Number(orderDetails.discountAmount).toFixed(2)).toString() : HelperUtil._getAmountCommaSeparator(Number(0).toFixed(2)).toString()}</li>
                                                                <li>+$ {orderDetails.shippingAmount != null ? HelperUtil._getAmountCommaSeparator(Number(orderDetails.shippingAmount).toFixed(2)).toString() : (this.props.orderDetailsProps.shippingRate != null) ? HelperUtil._getAmountCommaSeparator(Number(this.props.orderDetailsProps.shippingRate).toFixed(2)).toString() : 0}</li>
                                                            </ul>
                                                            <div className="clearfix"></div>
                                                            <div className="right_sideall_amount">
                                                                <div className="totalAmount">
                                                                    <p>Total Amount</p> <span> $ {(orderDetails.totalAmount != undefined) ? HelperUtil._getAmountCommaSeparator(Number(orderDetails.totalAmount).toFixed(2)).toString() : HelperUtil._getAmountCommaSeparator(Number(this.props.orderDetailsProps.total).toFixed(2)).toString()}</span>
                                                                </div>
                                                                <div className="totalAmount topborder">
                                                                    <p>Total Cards</p> <span>{noOfCards}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orderDetails: state.orderDetails,
        acknowledgeOrder: state.acknowledgeOrder,
        orderList: state.orderList,
        sendShippingDetails: state.sendShippingDetails,
        cancelOrder: state.cancelOrder,
    };
}

export default connect(mapStateToProps, { orderListAction, sendShippingDetailsAction, cancelOrderAction })(GiftCardRangePage);


