import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
    isSubmitting: false,
    data: null,
    login: false
}

/**
 * Function for get customers list
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function customerList(state = data, action) {
    switch (action.type) {
        case constants.CUSTOMER_MANAGEMENT_LIST_DATA:
            return { ...state, data: action.payload };
        case constants.CUSTOMER_MANAGEMENT_LIST_DATA_ERROR:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}

/**
 * Function for unlock customer
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function unlockCustomer(state = data, action) {
    switch (action.type) {
        case constants.CUSTOMER_UNLOCK_ACCOUNT:
            return { ...state, data: action.payload };
        case constants.CUSTOMER_UNLOCK_ACCOUNT_ERROR:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}

/**
 * Function for reset customer password
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function resetCustomerPWD(state = data, action) {
    switch (action.type) {
        case constants.CUSTOMER_RESET_PASSWORD:
            return { ...state, data: action.payload };
        case constants.CUSTOMER_RESET_PASSWORD_ERROR:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}

/**
 * Function for update customer details
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function updateCustomerDetails(state = data, action) {
    switch (action.type) {
        case constants.UPDATE_CUSTOMER_DETAILS:
            return { ...state, data: action.payload };
        case constants.UPDATE_CUSTOMER_DETAILS_ERROR:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}
