import * as constants from '../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';
import CryptoJS from 'crypto-js'

/*
* Function for userManagementList and get users list
  @param NULL
  @return object object of api resoponse
*/
export function orderListAction(userId) {
  return (dispatch) => {
    axios.post(serverRoutes.ORDER_LIST, { userId })
      .then(function (response) {
        dispatch({
          type: constants.ORDER_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.ORDER_LIST_ERROR,
          payload: response
        })
      })
  }
}

/**
 * function to get order details by order id
 * @param {*} orderId 
 */
export function orderDetailsAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.ORDER_DETAILS, data)
      .then(function (response) {
        dispatch({
          type: constants.ORDER_DETAILS,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.ORDER_DETAILS_ERROR,
          payload: response
        })
      })
  }
}

/**
 * function to acknowledge order by order id
 * @param {*} orderId 
 */
export function acknowledgeOrderAction(data) {
  let declinedObj = ''
  let acceptanceObj = ''
  let acknowledgeRequest = '';
  let acknowledgeResponse = '';
  if (data.paymentType == "ACH") {
    return (dispatch) => {
      data.acknowledge = true
      axios.post(serverRoutes.ACKNOWLEDGE_ORDER, data).then((responsee) => {
        acceptanceObj = {
          status: true,
          pin: responsee.data.data.data.pin
        }
        dispatch({
          type: constants.ACKNOWLEDGE_ORDER,
          payload: acceptanceObj
        })

      }).catch(function (responsee) {
        declinedObj = {
          message: responsee.message,
          status: false
        }
        dispatch({
          type: constants.ACKNOWLEDGE_ORDER_ERROR,
          payload: declinedObj
        })
      });
    }
  } else {
    return (dispatch) => {
      // Capture API for card payment
      // Rajesh new code start payeezy-api
      let captureData = JSON.stringify(data.captureData);
      var CryptoData = CryptoJS.AES.encrypt(captureData,constants.ENCRYPT_DECRYPT_KEY).toString();
      axios.post(serverRoutes.CAPTURE_PAYEEZE_PAYMENT, { data:`${CryptoData}`})
        .then(function (response) {
          if (response.data.error == false) {
            acknowledgeRequest = {
              requestData: data.captureData,
              cardPaymentURL: constants.PAYEEZY_CARD_PAYMENT_URL,
              achPaymentURL: constants.PAYEEZY_ACH_PAYMENT_URL
            }
            acknowledgeResponse = {
              responseData: response.data.data,
              cardPaymentURL: constants.PAYEEZY_CARD_PAYMENT_URL,
              achPaymentURL: constants.PAYEEZY_ACH_PAYMENT_URL
            }
            data.acknowledgeRequest = acknowledgeRequest
            data.acknowledgeResponse = acknowledgeResponse
            data.acknowledge = true
            axios.post(serverRoutes.ACKNOWLEDGE_ORDER, data).then((responsee) => {
              acceptanceObj = {
                status: true,
                pin: responsee.data.data.data.pin
              }
              dispatch({
                type: constants.ACKNOWLEDGE_ORDER,
                payload: acceptanceObj
              })
            }).catch(function (responsee) {
              declinedObj = {
                message: responsee.message,
                status: false
              }
              dispatch({
                type: constants.ACKNOWLEDGE_ORDER_ERROR,
                payload: declinedObj
              })
            });
          }else{
            dispatch({
              type: constants.ACKNOWLEDGE_ORDER_ERROR,
              payload: response.data
            })
          }
        })
        .catch(function (response) {
          dispatch({
            type: constants.ACKNOWLEDGE_ORDER_ERROR,
            payload: response.data
          })
        })
      // Rajesh new code end payeezy-api and lod code commented below

      // axios.post(serverRoutes.CAPTURE_PAYMENT, data.captureData)
      //   .then(function (response) {
      //     if (response.data.data.includes('Accepted')) {
      //       acknowledgeRequest = {
      //         requestData: data.captureData,
      //         cardPaymentURL: constants.CARD_PAYMENT_URL,
      //         achPaymentURL: constants.ACH_PAYMENT_URL
      //       }
      //       acknowledgeResponse = {
      //         responseData: response.data.data,
      //         cardPaymentURL: constants.CARD_PAYMENT_URL,
      //         achPaymentURL: constants.ACH_PAYMENT_URL
      //       }
      //       data.acknowledgeRequest = acknowledgeRequest
      //       data.acknowledgeResponse = acknowledgeResponse
      //       data.acknowledge = true
      //       axios.post(serverRoutes.ACKNOWLEDGE_ORDER, data).then((responsee) => {
      //         acceptanceObj = {
      //           status: true,
      //           pin: responsee.data.data.data.pin
      //         }
      //         dispatch({
      //           type: constants.ACKNOWLEDGE_ORDER,
      //           payload: acceptanceObj
      //         })
      //       }).catch(function (responsee) {
      //         declinedObj = {
      //           message: responsee.message,
      //           status: false
      //         }
      //         dispatch({
      //           type: constants.ACKNOWLEDGE_ORDER_ERROR,
      //           payload: declinedObj
      //         })
      //       });
      //     }
      //   })
      //   .catch(function (response) {
      //     dispatch({
      //       type: constants.ACKNOWLEDGE_ORDER_ERROR,
      //       payload: response.data
      //     })
      //   })
    }
  }

}

/**
 * function to update card range and send shipping details
 * @param {*} orderId 
 */
export function sendShippingDetailsAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.SEND_SHIPPING_DETAILS, data)
      .then(function (response) {
        dispatch({
          type: constants.SEND_SHIPPING_DETAILS,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.SEND_SHIPPING_DETAILS_ERROR,
          payload: response
        })
      })
  }
}


/**
 * Method for get Customer oreder list 
 * @param {customer id }
 * @return {oreder list}
 */
export function getCustomerOrderListAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.CUSTOMER_ORDER_LIST, data)
      .then(function (response) {
        dispatch({
          type: constants.CUSTOMER_ORDER_LIST_DATA,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CUSTOMER_ORDER_LIST_DATA_ERROR,
          payload: response
        })
      })
  }
}
/**
 * Function to cancel the order
 * @param {*} data 
 */
export function cancelOrderAction(data) {
  let cancelRequest = '';
  let cancelResponse = '';
  
  return (dispatch) => {
    // Capture API for card payment
      // Rajesh new code start payeezy-api
      let captureData = JSON.stringify(data.cancelApiData);
      var CryptoData = CryptoJS.AES.encrypt(captureData,constants.ENCRYPT_DECRYPT_KEY).toString();
      axios.post(serverRoutes.VOID_PAYEEZE_PAYMENT, { data:`${CryptoData}`})
        .then(function (response) {
          //Cancel order API for card payment
          if (response.data.error == false) {
            cancelRequest = {
              requestData: data.cancelApiData,
              cardPaymentURL: constants.PAYEEZY_CARD_PAYMENT_URL,
              achPaymentURL: constants.PAYEEZY_ACH_PAYMENT_URL
            }
            cancelResponse = {
              responseData: response.data.data,
              cardPaymentURL: constants.PAYEEZY_CARD_PAYMENT_URL,
              achPaymentURL: constants.PAYEEZY_ACH_PAYMENT_URL
            }
            data.cancelRequest = cancelRequest
            data.cancelResponse = cancelResponse
            axios.post(serverRoutes.CANCEL_ORDER, data).then((responsee) => {
              dispatch({
                type: constants.CANCEL_ORDER,
                payload: responsee.data.data
              })
            }).catch(function (responsee) {
              dispatch({
                type: constants.CANCEL_ORDER_ERROR,
                payload: responsee
              })  
            });
          }else{
            dispatch({
              type: constants.CANCEL_ORDER_ERROR,
              payload: {
                message: 'CORS WARNING: Network Error',
                status: false
              }
            })
          }
        })
        .catch(function (response) {
          dispatch({
            type: constants.CANCEL_ORDER_ERROR,
            payload: response
          })
        })
        // Rajesh new code end payeezy-api and old code commented below

//     if(data.cancelApiData !== false) {
      
//       axios.post(serverRoutes.CANCEL_ORDER_PAYMENT_FROM_MERCHANT, data.cancelApiData)
//       .then(function (response) {
//         //Cancel order API for card payment
//         if (response.data.data.includes('Accepted')) {
//           cancelRequest = {
//             requestData: data.cancelApiData,
//             cardPaymentURL: constants.CARD_PAYMENT_URL,
//             achPaymentURL: constants.ACH_PAYMENT_URL
//           }
//           cancelResponse = {
//             responseData: response.data.data,
//             cardPaymentURL: constants.CARD_PAYMENT_URL,
//             achPaymentURL: constants.ACH_PAYMENT_URL
//           }
//           data.cancelRequest = cancelRequest
//           data.cancelResponse = cancelResponse
//           axios.post(serverRoutes.CANCEL_ORDER, data).then((responsee) => {
//             dispatch({
//               type: constants.CANCEL_ORDER,
//               payload: responsee.data.data
//             })
//           }).catch(function (responsee) {
//             dispatch({
//               type: constants.CANCEL_ORDER_ERROR,
//               payload: responsee
//             })  
//           });
//         } else {
//           dispatch({
//             type: constants.CANCEL_ORDER_ERROR,
//             payload: {
//               message: 'CORS WARNING: Network Error',
//               status: false
//             }
//           })
//         }
//       })
//       .catch(function (response) {
//         dispatch({
//           type: constants.CANCEL_ORDER_ERROR,
//           payload: response
//         })
//       })
//   }

// else {

//   axios.post(serverRoutes.CANCEL_ORDER, data).then((responsee) => {
//     dispatch({
//       type: constants.CANCEL_ORDER,
//       payload: responsee.data.data
//     })
//   }).catch(function (responsee) {
//     dispatch({
//       type: constants.CANCEL_ORDER_ERROR,
//       payload: responsee
//     })
//   });
// }
  }

}
