import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import Header from '../Layout/Header';
import pencil from '../../../public/img/pencil.png';
import deleteAcc from '../../../public/img/acc-delete.png';
import { customerProfileAction, changeCustomerPasswordAction,searchUserByIdAction, updateCustomerProfileAction, addCustomerAddressAction, updateCustomerAddressAction, deleteCustomerAddressAction, updateAddressStatusAction, updatePrimaryPhoneCIAMAction, updateUserAddressCIAMAction } from '../../../Actions/Customer/customerProfileAction';
import { getCustomerOrderListAction } from '../../../Actions/Admin/orderManagementAction'
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import css from '../../../../src/public/css/my-account-page.css'
import * as constant from '../../../Constants/index';
import noImage from '../../../public/img/noImageShipPage.png';
import successimg from '../../../public/img/success.png';
import errorimg from '../../../public/img/error.png';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import moment from "moment"
import InputMask from 'react-input-mask'
import _ from 'lodash'
import { GOOGLE_API, GOOGLE_API_KEY, trackingUrl } from '../../../../../client/src/Constants'
import ReactLoading from 'react-loading'
import ReactModal from 'react-modal'
import { sendOTPAction, otpVerifyAction } from '../../../Actions/Customer/CustomerLoginAction';
import * as language from '../../../Constants/translations';
import { searchUserCIAMbyEmail } from '../../../Actions/Customer/CustomerLoginAction';
import OtpModal from 'react-responsive-modal';
import Consent from './consent'
import profileData from './userProfileModal'
import ciamAddressModal from './ciamAddressModal';
import ciamAddressStateModal from './addressStateModal'
import ciamAddressDeleteStateModal from './deleteCiamJson'

/** Class of dashboard consits of Buy Gift Card button
 *  customer profile 
 *  Order history, customer info etc 
 * */
class Dashboard extends Component {

    constructor(props) {
        super();
        this.state = {
            customerProfileData: {
                firstName: "",
                lastName: "",
                email: "",
                companyName: "",
                primaryPhone: "",
                title: "",
                secondaryPhone: "",
                federalTax: "",
                homeAddress: "",
                workAddress: "",
                customerId: ''
            },
            CIAM_PrimaryPhone: '',
            edit: true,
            updatePasswordTab: '',
            OrderHistoryTab: '',
            profileTab: "active",
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            displayError: false,
            serverPasswordError: '',
            serverPasswordMessage: '',
            customerAddress1: {
                customerId: '',
                addressId: '',
                address1: "",
                address2: "address 2",
                city: "",
                state: "",
                zip: "",
                zip1: '',
                country: "",
                phone: "phone",
                status: 1,
                createdBy: "5",
                updatedBy: "5",
                addressType: "",
                language: "en",
                loggedInUser: ""
            },
            customerAddress2: {
                customerId: '',
                addressId: '',
                address1: "",
                address2: "address 2",
                city: "",
                state: "",
                zip: "",
                zip1: '',
                country: "",
                phone: "phone",
                status: 0,
                createdBy: "5",
                updatedBy: "5",
                addressType: "",
                language: "en",
                loggedInUser: ""
            },
            displaySuccess: false,
            addressesStatus: {
                Add1: false,
                Add2: false,
            },
            modalState: false,
            editAdress: '',
            apiAddress: {
                city: '',
                state: '',
                country: ''
            },
            zipEdit: false,
            readOnly: false,
            zipIvalid: false,
            defaultAddress: false,
            buttonState: {
                defaultButton1: false,
                defaultButton2: false

            },
            staticAddress1: {
                addresstype: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                country: ''

            },
            staticAddress2: {
                addresstype: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                country: ''

            },
            flashMesssage: {
                add: false,
                update: false
            },
            fromHeading: true,
            addressErrors: {},
            addressFlashMessageError: '',
            flashMessageError: '',
            flashMessageSuccess: '',
            customerOrderList: [],
            flashMessageErrorPrimaryPhone:'',
            Loading: false,
            lang: sessionStorage.language != undefined ? sessionStorage.language : 'en',
            checkPointUpdateState: false,

            fontSize: {
                header2: 24,
                addressmyprofile: 14,
                addressdetails: 16,
                profilelabel: 16,
                customerhistory: 15,
                customerhistorydetails: 13,
                customerpricetab: 14,
                allOrderdetails: 12,
            },
            increaseCounter: 0,
            decreaseCounter: 0,
            addressStatus: '',
            validAddressForm: {},
            isEmptyField: false,
            changePasswordFields: {},
            consent: 0,
            policyModalopen: false,
            primaryPhone: '',
            openOtpModal: false,
            otpStatus: false,
            otpErrorSpan: '',
            otpSuccessSpan: '',
            otp: {
                o1: '',
                o2: '',
                o3: '',
                o4: '',
            },
            otpCheck: false,
            ngId: '',
            updateData: false,
            editUserName: false,
            userName: {
                firstName: '',
                lastName: ''
            },
            isResend: false,
            updateNameError: false ,
            isHomeAllowed:false,
            isCIAM_Phone_UP:false,
            Loadingl:false ,
            checkNumber:'',
            customerAddinationData:{
                alternateph:'',
                cimatitle:'',
                addresses : []
            },
            userByid:false,

           
        }
        this.updatePasswordMessage = this.updatePasswordMessage.bind(this)
    }

    /**
     * Function for habdle edit on profile form
     */
    editData() {
        this.setState({
            edit: true
        })
    }

    /**
     * Password update message 
     * @param {passwordStatus} bool
     * @return {message} String
     */
    updatePasswordMessage(passwordStatus, message) {
        if (passwordStatus) {
            this.setState({ serverPasswordError: '', displaySuccess: true });
            setTimeout(function () {
                this.setState({ serverPasswordError: '', displaySuccess: '' });
                this.clearData()
            }.bind(this), 3000)
        } else {
            this.setState({ serverPasswordError: false, serverPasswordMessage: message });
            this.clearData()
            setTimeout(function () {
                this.setState({ serverPasswordError: '', serverPasswordMessage: '' });
            }.bind(this), 3000)
        }
    }

    /**
    * Function for handle cancel button on profile edit form
    */
    handleCancel() {
        this.setState({ edit: false })
    }

    /**
     * Function for clear date on Cancel button change password form 
     */
    clearData() {
        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            isEmptyField: false,
            changePasswordFields: {},
        })
    }

    /**
     * Function for handling messages 
     * @param {call} tabName 
     */
    eventMessages() {
        if (arguments[0] === "add" || arguments[0] === "update") {
            this.setState({
                flashMesssage: {
                    add: false,
                    update: false
                }
            })
        }
        if (arguments[0] === "profile") {
            this.setState({ flashMessageSuccess: "" })
        }

    }

    /**
     * Function for handle tab changes when tab is clicked 
     * @param e 
     */
    async handleTabChange(tabName) {
        arguments[1].preventDefault()
        this.setState({ updatePasswordTab: '', profileTab: '', OrderHistoryTab: '' })
        if (tabName === "updatePasswordTab") {
            this.setState({ updatePasswordTab: "active" })
            this.props.history.push(
                {
                    pathname: "/dashboard",
                    state: { fromPage: '' }
                }
            )
        } else if (tabName === "profileTab") {
            this.setState({ profileTab: "active" })
            this.props.history.push(
                {
                    pathname: "/dashboard",
                    state: { fromPage: "" }
                }
            )
        } else if (tabName === "OrderHistoryTab") {
            this.setState({
                OrderHistoryTab: "active",
                Loading: true,
            })
            let dataToSendServer = {
                "loggedInUser": `${this.state.customerProfileData.customerId}`,
                "language": "en",
                "customerId": `${this.state.customerProfileData.customerId}`
            }

            await this.props.getCustomerOrderListAction(dataToSendServer)

        }


    }

    /**
     * function for open add Address modal
     *  @param {event}
     * @param { address Type}
     * @returns {undefined}
     */
    onOpenModal(addName) {

        arguments[1].preventDefault()
        this.setState({ modalState: true, flashMessageError: '' })
        if (arguments[0] === "home") {
            this.setState({ editAdress: "customerAddress1" })
        } else {
            this.setState({ editAdress: "customerAddress2" })

        }
        if (arguments[0] === "add") {
            this.setState({
                fromHeading: true,
            })
        } else {
            this.setState({
                fromHeading: false,
            })
        }
    }

    /***
     * Method for clearing Address data 
     * 
     */
    clearAddressData = () => {

        let addressIdOne = this.state.customerAddress1.addressId;
        let addressIdSecond = this.state.customerAddress2.addressId;
        if (addressIdOne === "" || addressIdOne === undefined) {
            this.setState(
                {
                    customerAddress1: {
                        customerId: '',
                        addressId: '',
                        address1: "",
                        address2: "address 2",
                        city: "",
                        state: "",
                        zip: "",
                        country: "",
                        phone: "phone",
                        status: 1,
                        createdBy: "5",
                        updatedBy: "5",
                        addressType: "",
                        language: "en",
                        loggedInUser: this.state.customerProfileData.customerId,
                    }
                })
        } else if (!isNaN(addressIdOne)) {
            this.setState({
                customerAddress1: {
                    customerId: this.state.customerProfileData.customerId,
                    addressId: addressIdOne,
                    address1: this.state.staticAddress1.street,
                    address2: "address 2",
                    city: this.state.staticAddress1.city,
                    state: this.state.staticAddress1.state,
                    zip: this.state.staticAddress1.zip,
                    country: this.state.staticAddress1.country,
                    phone: "phone",
                    status: this.state.customerAddress1.status,
                    createdBy: "5",
                    updatedBy: "5",
                    addressType: this.state.customerAddress1.addressType,
                    language: "en",
                    loggedInUser: this.state.customerProfileData.customerId,
                }
            })

        }
        if (addressIdSecond === "" || addressIdSecond === undefined) {
            this.setState({
                customerAddress2: {
                    customerId: '',
                    addressId: '',
                    address1: "",
                    address2: "address 2",
                    city: "",
                    state: "",
                    zip: "",
                    country: "",
                    phone: "phone",
                    status: 0,
                    createdBy: "5",
                    updatedBy: "5",
                    addressType: "",
                    language: "en",
                    loggedInUser: this.state.customerProfileData.customerId,
                }
            })
        } else if (!isNaN(addressIdSecond)) {
            this.setState({
                customerAddress2: {
                    customerId: this.state.customerProfileData.customerId,
                    addressId: addressIdSecond,
                    address1: this.state.staticAddress2.street,
                    address2: "address 2",
                    city: this.state.staticAddress2.city,
                    state: this.state.staticAddress2.state,
                    zip: this.state.staticAddress2.zip,
                    country: this.state.staticAddress2.country,
                    phone: "phone",
                    status: this.state.customerAddress2.status,
                    createdBy: "5",
                    updatedBy: "5",
                    addressType: this.state.customerAddress2.addressType,
                    language: "en",
                    loggedInUser: this.state.customerProfileData.customerId,
                }
            })

        }

    }
    /**
     * function for handle close modal
     *  
     */
    onCloseModal = () => {
        this.setState({
            modalState: false,
            flashMessageError: '',
            addressFlashMessageError: '',
            flashMessageSuccess: '',
            flashMesssage: {
                add: false,
                update: false
            },
            isEmptyField: false,
            validAddressForm: {}
        });
        this.clearAddressData();
    };



    async componentWillMount() {
        if (sessionStorage.loggedIn == undefined || sessionStorage.customerName == undefined && sessionStorage.loggedIn == "" || sessionStorage.customerName == "") {
            this.props.history.push("/");
        }
        var loggedInUserId = sessionStorage.userID
        if (loggedInUserId) {
            this.setState({ Loading: true })
            await this.props.customerProfileAction(loggedInUserId);

        }
        if (sessionStorage.emailID) {
            await this.props.searchUserCIAMbyEmail(sessionStorage.emailID)
        }
    }

    /**
     * Method for display update password page 
     */
    openUpdatePasswordTab() {
        this.setState({
            updatePasswordTab: true,
            profileTab: false
        })
    }

    // Method to enter the current password for changing the password 
    setCurrentPassword(e) {
        try{
            let val = typeof e.target.value==='string' ? e.target.value.trimStart() : '';
            let key = e.target.name
            let { changePasswordFields } = this.state

            if (val === "") {
                changePasswordFields[key] = ""
            } else {
                changePasswordFields[key] = val
            }
            this.setState({
                currentPassword: val,
                changePasswordFields: changePasswordFields
            })

        }catch(err) { console.log("err",err);}
        
    }

    // Method to enter the new password to be set 
    setNewPassword(e) {
        try {
            let val = typeof e.target.value==='string' ? e.target.value.trimStart() :'';
            let key = e.target.name
            let { changePasswordFields } = this.state

            if (val === "") {
                changePasswordFields[key] = ""
            } else {
                changePasswordFields[key] = val
            }
            this.setState({
                newPassword: val,
                serverPasswordError: '',
                message: '',
                changePasswordFields: changePasswordFields
            })
        } catch(err){console.log("erro",err)}
        


    }

    // method to confirm the new password entered 
    setConfirmPassword(e) {
        try {
            
            let val = typeof e.target.value === 'string' ? e.target.value.trimStart() :'';
            let { changePasswordFields } = this.state
            let key = e.target.name
            if (val === "") {
                changePasswordFields[key] = ""
            } else {
                changePasswordFields[key] = val
            }
            this.setState({ confirmPassword: val });
            this.setState({
                displayError: false,
                changePasswordFields: changePasswordFields
            })
        } catch(err){console.log("err")}
      
    }


    /**
     * Method for setting Profile form titlle 
     * @param {event} 
     */
    setTitle(e) {
        let val = e.target.value;
        let key = e.target.name
        let { validAddressForm } = this.state
        if (val === "") {
            validAddressForm[key] = ""
        } else {
            validAddressForm[key] = val
        }
        this.setState({
            flashMessageError: ''
        })
        var customerData = this.state.customerProfileData
        this.setState({
            customerProfileData: {
                firstName: customerData.firstName,
                lastName: customerData.lastName,
                email: customerData.email,
                companyName: customerData.companyName,
                primaryPhone: customerData.primaryPhone,
                title: val.trimStart(),
                secondaryPhone: customerData.secondaryPhone,
                federalTax: customerData.federalTax,
                homeAddress: "",
                workAddress: "",
                customerId: customerData.customerId,
                validAddressForm: validAddressForm
            }
        })
    }

    /**
     * Method for set primery phone Number in customer profile form 
     * @param {*} e 
     */
    setPrimaryPhone(e) {
        this.setState({
            flashMessageError: ''
        })
        let val = e.target.value;
        let key = e.target.name
        let { validAddressForm } = this.state
        if (val === "") {
            validAddressForm[key] = ""
        } else {
            validAddressForm[key] = val
        }
        var customerData = this.state.customerProfileData
        this.setState({
            customerProfileData: {
                firstName: customerData.firstName,
                lastName: customerData.lastName,
                email: customerData.email,
                companyName: customerData.companyName,
                primaryPhone: val,
                title: customerData.title,
                secondaryPhone: customerData.secondaryPhone,
                federalTax: customerData.federalTax,
                homeAddress: "",
                workAddress: "",
                customerId: customerData.customerId,
                validAddressForm: validAddressForm
            }
        })
    }

    /**
     * Method for set secondary phone Number in customer profile form 
     * @param {*} e 
     */

    setSecondaryPhone(e) {
        this.setState({
            flashMessageError: ''
        })
        let val = e.target.value;
        let key = e.target.name
        let { validAddressForm } = this.state
        if (val === "") {
            validAddressForm[key] = ""
        } else {
            validAddressForm[key] = val
        }
        var customerData = this.state.customerProfileData
        this.setState({
            customerProfileData: {
                firstName: customerData.firstName,
                lastName: customerData.lastName,
                email: customerData.email,
                companyName: customerData.companyName,
                primaryPhone: customerData.primaryPhone,
                title: customerData.title,
                secondaryPhone: val,
                federalTax: customerData.federalTax,
                homeAddress: "",
                workAddress: "",
                customerId: customerData.customerId,
                validAddressForm: validAddressForm
            }
        })
    }

    /**
     * Method for set Company in customer profile form 
     * @param {*} e 
     */

    setCompanyName(e) {
        this.setState({
            flashMessageError: ''
        })
        let val = e.target.value;
        var customerData = this.state.customerProfileData
        this.setState({
            customerProfileData: {
                firstName: customerData.firstName,
                lastName: customerData.lastName,
                email: customerData.email,
                companyName: this.state.customerProfileData.companyName === "" ? val : this.state.customerProfileData.companyName,
                primaryPhone: customerData.primaryPhone,
                title: customerData.title,
                secondaryPhone: customerData.secondaryPhone,
                federalTax: customerData.federalTax,
                homeAddress: "",
                workAddress: "",
                customerId: customerData.customerId
            }
        })
    }

    /**
     * Method for set FedralTax in customer profile form 
     * @param {*} e 
     */

    setFederalTax(e) {
        this.setState({
            flashMessageError: ''
        })
        let val = e.target.value;
        let key = e.target.name
        let { validAddressForm } = this.state
        if (val === "") {
            validAddressForm[key] = ""
        } else {
            validAddressForm[key] = val
        }
        var customerData = this.state.customerProfileData
        this.setState({
            customerProfileData: {
                firstName: customerData.firstName,
                lastName: customerData.lastName,
                email: customerData.email,
                companyName: customerData.companyName,
                primaryPhone: customerData.primaryPhone,
                title: customerData.title,
                secondaryPhone: customerData.secondaryPhone,
                federalTax: val,
                homeAddress: "",
                workAddress: "",
                customerId: customerData.customerId,
                validAddressForm: validAddressForm
            }
        })
    }
    
    /**Change password helper  method  */
    chnageValidPasswordField() {
        let { changePasswordFields } = this.state
        if (this.state.currentPassword === "") {
            changePasswordFields.oldPassword = ''
            Object.assign(changePasswordFields, changePasswordFields)
        }
        if (this.state.newPassword === "") {
            changePasswordFields.newPassword = ''
            Object.assign(changePasswordFields, changePasswordFields)
        }
        if (this.state.confirmPassword === "") {
            changePasswordFields.confirmPassword = ''

            Object.assign(changePasswordFields, changePasswordFields)
        }
    

        this.setState({ changePasswordFields: changePasswordFields })
    }

    /***
     *Method for change password of customer 
     */
    changePassword() {
        this.setState({ isEmptyField: true })
        this.chnageValidPasswordField()
        var data = { "customerId": sessionStorage.userID, "password": this.state.newPassword, "oldpassword": this.state.currentPassword, "loggedInUser": sessionStorage.userID, "language": "en" }
        if (typeof this.state.currentPassword==='string' && this.state.currentPassword.trim() && this.state.currentPassword == '')  {
            this.errorMessage(language.language.current_password_please[0][this.state.lang])

        }
        else if ( typeof this.state.newPassword ==='string' && this.state.newPassword.trim() && this.state.newPassword == '') {
            this.errorMessage(language.language.new_password_please[0][this.state.lang])
        }
        else if (this.state.newPassword !== '' && this.state.currentPassword !== '' && this.state.newPassword == this.state.currentPassword) {

            this.errorMessage(language.language.Password_Same[0][this.state.lang])
        }
        else if (this.state.newPassword !== '' && this.setState.confirmPassword !== '' && this.state.newPassword !== this.state.confirmPassword) {

            this.errorMessage(language.language.match_password[0][this.state.lang])
        } else {
            let validate = /^([A1-z9]){7,15}$/;
            let email = this.state.newPassword;
            if (validate.test(email) && (email.length <= 15) && (email.length >= 8)) {
                this.setState({ isEmptyField: false, changePasswordFields: {} })
                this.props.changeCustomerPasswordAction(data)
            } else {
                this.errorMessage(language.language.Password_Validation[0][this.state.lang])
            }

        }


    }

    /***
     * Method for Update customer profile Information 
     * @param {event }
     */
    async  updateCustomerProfile() {
        try {
            let _this = this
            let { validAddressForm } = this.state
    
            var data =
            {
                "customerId": this.state.customerProfileData.customerId,
                "companyName": this.state.customerProfileData.companyName,
                "title": this.state.customerProfileData.title !== ''  && this.state.customerProfileData.title!==null? this.state.customerProfileData.title.trim() : '',
                "federalTax": this.state.customerProfileData.federalTax,
                "mobile": this.state.customerProfileData.primaryPhone,
                "phone": this.state.customerProfileData.secondaryPhone,
                "loggedInUser": this.state.customerProfileData.customerId,
                "consent": this.state.consent,
                "language": "en"
            }
 
            let isPhone_Numeric = /^[0-9]/.test(data.mobile)
            if (isPhone_Numeric) {
                let maskedMobile = `(${data.mobile.substr(0, 3)}) ${data.mobile.substr(3, 3)}-${data.mobile.substr(6, 9)}`
                data.mobile = maskedMobile
            }


            if (data.title === "" || data.title === null) {
                validAddressForm.title = ""
                this.setState({
                    flashMessageError: language.language.job_title_mandatory[0][this.state.lang],
                    isEmptyField: true
                })
                setTimeout(function () {
                    _this.setState({ flashMessageError: "" })
                }, 3000)
                return 0;
            }
            if (data.mobile == '') {
                validAddressForm.primaryPhone = ''
                this.setState({
                    flashMessageError: language.language.primary_number_mandatory[0][this.state.lang],
                    isEmptyField: true
                })
            } else if ((data.mobile).length < 14) {
                validAddressForm.primaryPhone = ''
                this.setState({
                    flashMessageError: language.language.Invalid_Primary_Phone[0][this.state.lang],
                    isEmptyField: true
                })
            }
            // Secondary Phone
            else if (data.phone != '' && (data.phone).length < 14) {
                validAddressForm.secondaryPhone = ''
                this.setState({
                    flashMessageError: language.language.Invalid_Secondary_Phone[0][this.state.lang],
                    isEmptyField: true
                })
            }
            // Secondary Phone
            else if (data.federalTax != '' && (data.federalTax).length < 13) {
                if (data.federalTax == '(') {
                    this.setState({
                        checkPointUpdateState: true,
                        Loading: true,
                        validAddressForm: {},
                        isEmptyField: false

                    })
                    await this.props.updateCustomerProfileAction(data)

                } else {
                    validAddressForm.federalTax = ''
                    this.setState({
                        isEmptyField: true,
                        flashMessageError: language.language.Invalid_Federal_Tax[0][this.state.lang],
                    })
                }
            } else {
            
                this.setState({
                    checkPointUpdateState: true,
                    validAddressForm: {},
                    isEmptyField: false,
                    Loading:true

                })
            
                if (this.state.CIAM_PrimaryPhone === null || this.state.CIAM_PrimaryPhone !== false)  {
                    if(this.state.customerProfileData.primaryPhone !== undefined &&this.state.checkNumber!==undefined&& this.state.checkNumber.replace(/[^a-zA-Z0-9]/g, '') !== this.state.customerProfileData.primaryPhone.replace(/[^a-zA-Z0-9]/g, '')) {
                        var otpJson = {
                            "northgateId": "",
                            "To": "+1" + this.state.customerProfileData.primaryPhone.replace(/[^a-zA-Z0-9]/g, ''),
                            "emailId": "",
                            "source": "gift_card"
                        }
                    
                        this.setState({
                            otpStatus: true,
                            updateData: true,
                            otpErrorSpan: '',
                            
                        })
                        setTimeout(function () {
                            _this.setState({ otpErrorSpan: '' })
                        }, 3000)
                        
                        await this.props.sendOTPAction(otpJson)  
                } else {
                    this.setState({
                        otpStatus: false,
                        
                        otpErrorSpan: '',
                    })
                    
                    
                       let title            =  this.state.customerProfileData.title
                       let secondaryPhone   =  this.state.customerProfileData.secondaryPhone
                       let dataCIAM =  profileData({title,secondaryPhone,...this.state.customerAddinationData,ngId:this.state.ngId,consent:this.state.consent,primaryPhoneNumber:this.state.customerProfileData.primaryPhone})                  
                       let mobileData = {
                        "source": "gift_card",
                        "prosperaId":this.state.ngId,
                        "isUpdateAllKeys": true,
                        "jobTitle" : title,
                        "alternatePhone": {
                            "customerPhone": [
                            {
                                "source": "gift_card",
                                "phoneType": "cell",
                                "dialCode": "+1",
                                "phoneNo": secondaryPhone.replace(/[^a-zA-Z0-9]/g, '')
                            }
                            ]
                        },
                        "isOptin": true,
                        "optin": [
                            {
                            "optinCategoryId": constant.OPTION_CATEGORY_ID,
                            "updatedsource": "gift_card",
                            "subscribe": this.state.consent===1 ? true : false
                            }
                        ]
                          
            
                    }
                    if(secondaryPhone!==""){
                        this.setState({
                            otpStatus: false,
                            Loadingl:true,
                            otpErrorSpan: '',
                           })
                        this.props.updatePrimaryPhoneCIAMAction(mobileData)
                    } else {
                        delete mobileData.alternatePhone
                        this.props.updatePrimaryPhoneCIAMAction(mobileData)

                    }
                    
                    await this.props.updateCustomerProfileAction(data) 
    
                

                }

                } else {
                    this.setState({
                        flashErrorMessageSpan: "Your Profile Information cannot be updated at this moment. Please try after sometime.",
                        Loading:false
                    })
                    setTimeout(function () {
                        _this.setState({ otpErrorSpan: '' })
                    }, 3000)
                }
            }


        } catch(er){console.log("err",er)}
        
    }

    /***
     * Method for allowed only charter in address fields 
     * @param {val}
     * @returns {Boolean}
     */
    isCharter = (val) => {
        let len = val.length;
        let num = 32
        let regX = /^([a-zA-Z]+\s)*[a-zA-Z]+$/
        try {
            num = +val[len - 1].toLocaleUpperCase().charCodeAt()
        } catch (e) { }
        return ((regX.test(val)) || num === 32) ? true : false
    }

    /**
     * Method for Autofill address in Address field
     * @param{enent}
     * 
     */
    autoFillAddress = async (event) => {
        try {
            let zip = event.target.value
            let self = this
            let nbhoodData = ''
            let { validAddressForm } = self.state
            if (zip.length === 5 || zip.length === 6 || zip.length === 4) {
                self.setState({ Loading: true })
                await axios.get(`${GOOGLE_API}?address=${zip}&key=${GOOGLE_API_KEY}&timestamp=${new Date().getTime()}`)
                    .then(function (response) {
                        if (response.status === 200 && response.data.results.length > 0) {
                            let address = _.filter(response.data.results[0].address_components, function (addressData) {
                                if (addressData.types[0] === "locality") {
                                    return addressData.long_name
                                }
                                if (addressData.types[0] === "neighborhood") {
                                    nbhoodData = addressData.long_name
                                }
                                if (addressData.types[0] === "administrative_area_level_1") {
                                    return addressData.long_name
                                }
                                if (addressData.types[0] === "country") {
                                    return addressData.long_name
                                }

                            })
                            if (address.length === 2) {
                                let addMissingAddressObject = {
                                    long_name: nbhoodData,
                                }
                                address.splice(0, 0, addMissingAddressObject)
                            }
                            if (address[0].long_name !== '') {
                                validAddressForm['city'] = address[0].long_name
                            }
                            if (address[1].long_name !== "") {
                                validAddressForm['state'] = address[1].long_name
                            }
                            if (address[2].long_name !== "") {
                                validAddressForm['country'] = address[2].long_name
                            }
                            self.setState({ defaultAddress: true, flashMessageError: '', addressFlashMessageError: '', validAddressForm: validAddressForm })
                            if (!self.state.addressesStatus.Add1 || self.state.editAdress === "customerAddress1") {
                                self.setState({
                                    customerAddress1: {
                                        customerId: self.state.customerProfileData.customerId,
                                        addressId: self.state.customerAddress1.addressId !== "" ? self.state.customerAddress1.addressId : '',
                                        address1: self.state.customerAddress1.address1,
                                        address2: "address 2",
                                        city: address.length > 0 && address[0].long_name !== '' ? address[0].long_name : self.state.customerAddress1.city,
                                        state: address[1] !== undefined && address[1].long_name !== '' ? address[1].long_name : self.state.customerAddress1.state,
                                        zip: self.state.customerAddress1.zip,
                                        country: address[2] !== undefined && address[2].long_name !== '' ? address[2].long_name : 'United States',
                                        phone: "phone",
                                        status: self.state.customerAddress1.status,
                                        createdBy: "5",
                                        updatedBy: "5",
                                        addressType: self.state.customerAddress1.addressType,
                                        language: "en",
                                        loggedInUser: self.state.customerProfileData.customerId
                                    },
                                    defaultAddress: true
                                })
                            } else {
                                self.setState({

                                    customerAddress2: {
                                        customerId: self.state.customerProfileData.customerId,
                                        addressId: self.state.customerAddress2.addressId !== "" ? self.state.customerAddress2.addressId : '',
                                        address1: self.state.customerAddress2.address1,
                                        address2: "address 2",
                                        city: address.length > 0 && address[0].long_name !== '' ? address[0].long_name : self.state.customerAddress2.city,
                                        state: address[1] !== undefined && address[1].long_name !== '' ? address[1].long_name : self.state.customerAddress2.state,

                                        zip: self.state.customerAddress2.zip,
                                        country: address[2] !== undefined && address[2].long_name !== '' ? address[2].long_name : 'United States',
                                        phone: "phone",
                                        status: self.state.customerAddress2.status,
                                        createdBy: "5",
                                        updatedBy: "5",
                                        addressType: self.state.customerAddress2.addressType,
                                        language: "en",
                                        loggedInUser: self.state.customerProfileData.customerId
                                    },
                                })
                            }
                        } else {
                            self.erase();
                            self.setState({ Loading: false })
                        }
                    })
                    .catch(function (errorResponse) {
                        self.setState({ Loading: false })
                    })
                self.setState({ Loading: false })
            }
        } catch (e) { console.log("eror ", e) }
    }

    /***
     * Method for clear address data if Google API return 0 address  
     * In case of edit of address
     **/
    erase = () => {
        let self = this
        if (!self.state.addressesStatus.Add1 || self.state.editAdress === "customerAddress1") {
            self.setState({
                customerAddress1: {
                    customerId: self.state.customerProfileData.customerId,
                    addressId: self.state.customerAddress1.addressId !== "" ? self.state.customerAddress1.addressId : '',
                    address1: self.state.customerAddress1.address1,
                    address2: "address 2",
                    city: "",
                    state: "",
                    zip: self.state.customerAddress1.zip,
                    country: "",
                    phone: "phone",
                    status: self.state.customerAddress1.status,
                    createdBy: "5",
                    updatedBy: "5",
                    addressType: self.state.customerAddress1.addressType,
                    language: "en",
                    loggedInUser: self.state.customerProfileData.customerId

                }
            })
        } else {
            self.setState({
                customerAddress2: {
                    customerId: self.state.customerProfileData.customerId,
                    addressId: self.state.customerAddress2.addressId !== "" ? self.state.customerAddress2.addressId : '',
                    address1: self.state.customerAddress2.address1,
                    address2: "address 2",
                    city: "",
                    state: "",
                    zip: self.state.customerAddress2.zip,
                    country: "",
                    phone: "phone",
                    status: self.state.customerAddress2.status,
                    createdBy: "5",
                    updatedBy: "5",
                    addressType: self.state.customerAddress2.addressType,
                    language: "en",
                    loggedInUser: self.state.customerProfileData.customerId

                }
            })
        }

    }






    /**
     * Method for setting address value on click in fields
     * @parem click event
     * @return nothing  
     *
     **/
    async  handleClickAdress(e, fieldName) {
        if(e.target.value !== "" && e.target.value[0].charCodeAt() === 32) {
            return 0;
        }
        let val = e.target.value;
        this.setValidInputFieldObject(e)
        if (fieldName === 'zip') {
            let numberRegx = /^[0-9]*$/
            if (!numberRegx.test(val)) return 0
        }
        if (fieldName !== 'zip' && !this.isCharter(val, fieldName) && fieldName !== 'street') {
            return 0
        }
        let self = this
        self.setState({ defaultAddress: true, flashMessageError: '', addressFlashMessageError: '' })
        if (!self.state.addressesStatus.Add1 || self.state.editAdress === "customerAddress1") {

            self.setState({
                customerAddress1: {
                    customerId: self.state.customerProfileData.customerId,
                    addressId: self.state.customerAddress1.addressId !== "" ? self.state.customerAddress1.addressId : '',
                    address1: fieldName === "street" ? val : self.state.customerAddress1.address1,
                    address2: "address 2",
                    city: fieldName === "city" ? val : self.state.customerAddress1.city,
                    state: fieldName === "state" ? val : self.state.customerAddress1.state,
                    zip: fieldName === "zip" ? val : self.state.customerAddress1.zip,
                    country: fieldName === "country" ? val : self.state.customerAddress1.country,
                    phone: "phone",
                    status: self.state.customerAddress1.status,
                    createdBy: "5",
                    updatedBy: "5",
                    addressType: fieldName === "addressType" ? val : self.state.customerAddress1.addressType,
                    language: "en",
                    loggedInUser: self.state.customerProfileData.customerId
                },
                defaultAddress: true
            })
        } else {
            this.setState({

                customerAddress2: {
                    customerId: self.state.customerProfileData.customerId,
                    addressId: self.state.customerAddress2.addressId !== "" ? self.state.customerAddress2.addressId : '',
                    address1: fieldName === "street" ? val : self.state.customerAddress2.address1,
                    address2: "address 2",
                    city: fieldName === "city" ? val : self.state.customerAddress2.city,
                    state: fieldName === "state" ? val : self.state.customerAddress2.state,
                    zip: fieldName === "zip" ? val : self.state.customerAddress2.zip,
                    country: fieldName === "country" ? val : self.state.customerAddress2.country,
                    phone: "phone",
                    status: self.state.customerAddress2.status,
                    createdBy: "5",
                    updatedBy: "5",
                    addressType: fieldName === "addressType" ? val : self.state.customerAddress2.addressType,
                    language: "en",
                    loggedInUser: self.state.customerProfileData.customerId
                },
            })
        }
    }

    /**
     * Method to set input form fields 
     */
    setValidInputFieldObject(data) {
        let fieldName = data.target.name
        let value = data.target.value
        let { validAddressForm } = this.state
        if (value === "" || value === "Choose address type") {
            validAddressForm[fieldName] = ''
        } else {
            validAddressForm[fieldName] = value
        }
        Object.assign(validAddressForm, validAddressForm)
    }

    /*Method to validate try code */
    setValidObject(data) {
        let { validAddressForm } = this.state
        if (data.addressType === 'Choose address type' || data.addressType === "") {
            validAddressForm['addressType'] = ""
        }
        if (data.address1 === "") {
            validAddressForm['street'] = ''
        }
        if (data.city === '') {
            validAddressForm['city'] = ''
        }
        if (data.zip === "") {
            validAddressForm['zip'] = ''
        }
        if (data.state === "") {
            validAddressForm['state'] = ''

        }
        if (data.country === "") {
            validAddressForm['country'] = ''
        }

        this.setState({ validAddressForm: validAddressForm })
    }


    /**
     * Method for add new address 
     * @param {state} object
     * @return  
     */
    async onSubmitAddress() {
        try {
            let lang = this.state.lang
        let data = ""
        
        if (!this.state.addressesStatus.Add1 || this.state.editAdress === "customerAddress1") {
            data = this.state.customerAddress1
        } else {
            data = this.state.customerAddress2
        }
        if (data.addressType == '' || data.addressType == 'Choose address type' || data.address1 == '' || data.city == '' || data.zip == '' || data.state == '' || data.country == '') {
            this.setState({
                addressFlashMessageError: language.language.all_fields_mandatory[0][lang],
                isEmptyField: true
            })
            this.setValidObject(data)
            return 0
        } else {
            let addObj = {
                "customerId": data.customerId === undefined ? "" : data.customerId,
                "addressId": data.addressId === undefined ? "" : data.addressId,
                "address1": `${data.address1.trim()}`,
                "address2": "address 2",
                "city": `${data.city.trim()}`,
                "state": `${data.state.trim()}`,
                "zip": `${data.zip}`,
                "country": `${data.country.trim()}`,
                "phone": "phone",
                "status": data.status === false ? 0 : 1,
                "createdBy": "5",
                "updatedBy": "5",
                "addressType": `${data.addressType}`,
                "language": "en",
                "loggedInUser": data.customerId,

            }
            let address_CIAM_JSON = {}

            if (addObj.addressId !== "" || this.state.editAdress === "customerAddress1") {
            
                address_CIAM_JSON = {
                    "source": "gift_card",
                    "prosperaId": this.state.ngId === undefined || this.state.ngId == "" ? "" : this.state.ngId,
                    "isUpdateAllKeys": true,
                    "address": {
                        "customerAddress": [{
                            "title": addObj.status === 1 ? "Home" : "Office",
                            "street": addObj.address1.trim(),
                            "zip": addObj.zip,
                            "city": addObj.city.trim(),
                            "state": addObj.state.trim(),
                            "country": addObj.country.trim(),
                            "isVerified": false,

                        }],

                    }
                }
                this.setState({ Loading: true })
                let staticAddress  = this.state.editAdress =="customerAddress1"  ? this.state.staticAddress1 : this.state.staticAddress2

               const ciamPhoneData =  {
                    "source": "gift_card",
                    "prosperaId": this.state.ngId,
                    "isUpdateAllKeys": true,
                    "address": {
                      "customerAddress": [
                        {
                           "source": "gift_card",
                           "title": addObj.addressType ,
                           "street": `${addObj.address1.trim()}`,
                           "zip": `${addObj.zip}`,
                           "city":  `${addObj.city.trim()}`,
                           "state": `${addObj.state.trim()}`,
                           "country": `${addObj.country.trim()}`,
                           "isPrimary":  this.state.editAdress === "customerAddress1"?this.state.buttonState.defaultButton1:this.state.buttonState.defaultButton2,
                           "isVerified": false
                        },
                        {
                            "source": "gift_card",
                            "title": staticAddress.addresstype ,
                            "street": `${staticAddress.street.trim()}`,
                            "zip": `${staticAddress.zip}`,
                            "city":  `${staticAddress.city.trim()}`,
                            "state": `${staticAddress.state.trim()}`,
                            "country": `${staticAddress.country.trim()}`,
                            "isPrimary":  false,
                            "isVerified": false
                         }
                      ]
                    }
                  }
                
                  await this.props.updateUserAddressCIAMAction(ciamPhoneData)
                
               
                await this.props.updateCustomerAddressAction(addObj)
                this.setState({ checkPointUpdateState: true })
           



            } else if (addObj.addressId !== "") {
                
                this.setState({ Loading: true })
                    const address =   {
                        "source": "gift_card",
                        "prosperaId": this.state.ngId,
                        "isUpdateAllKeys": true,
                        "address": {
                        "customerAddress": [
                            {
                            "source": "gift_card",
                            "title": addObj.status === 1 ? "Home" : "Office",
                            "street": `${addObj.address1.trim()}`,
                            "zip": `${addObj.zip}`,
                            "city":  `${addObj.city.trim()}`,
                            "state": `${addObj.state.trim()}`,
                            "country": `${addObj.country.trim()}`,
                            "isPrimary": addObj.status === 1 ? true : false,
                            "isVerified": false
                            }
                        ]
                        }
                    }
                await this.props.updateUserAddressCIAMAction(address)
                
                await this.props.updateCustomerAddressAction(addObj)
                //   if(addObj.prosperaId !== "") {
                //     delete(addObj.customerId)
                //     await this.props.updateUserAddressCIAMAction(address_CIAM_JSON)
                // }

                this.setState({ checkPointUpdateState: true })

            } else {
                let addObj = {
                    "customerId": data.customerId,
                    "address1": `${data.address1.trim()}`,
                    "address2": "address 2",
                    "city": `${data.city.trim()}`,
                    "state": `${data.state.trim()}`,
                    "zip": `${data.zip}`,
                    "country": `${data.country.trim()}`,
                    "phone": "phone",
                    "status": data.status,
                    "addressType": `${data.addressType}`,
                    "language": "en",
                    "loggedInUser": data.customerId,


                }

                if (!this.state.addressesStatus.Add1) {
                    this.setState({ Loading: true })
                    console.log("addresss 2====",addObj)

                    await this.props.addCustomerAddressAction(addObj)
                    const address = {
                        "source": "gift_card",
                        "prosperaId": this.state.ngId,
                        "isUpdateAllKeys": true,
                        "address": {
                          "customerAddress": [
                            {
                               "source": "gift_card",
                               "title": addObj.addressType,
                               "street": `${addObj.address1.trim()}`,
                               "zip": `${addObj.zip}`,
                               "city":  `${addObj.city.trim()}`,
                               "state": `${addObj.state.trim()}`,
                               "country": `${addObj.country.trim()}`,
                               "isPrimary":addObj.status === 1 ? true : false,
                               "isVerified": false
                            }
                          ]
                        }
                      }
                   await this.props.updateUserAddressCIAMAction(address)
                   
                    this.setState({ checkPointUpdateState: true })
                } else {
                    this.setState({ Loading: true })
                    const address = {
                        "source": "gift_card",
                        "prosperaId": this.state.ngId,
                        "isUpdateAllKeys": true,
                        "address": {
                          "customerAddress": [
                            {
                               "source": "gift_card",
                               "title": addObj.addressType,
                               "street": `${addObj.address1.trim()}`,
                               "zip": `${addObj.zip}`,
                               "city":  `${addObj.city.trim()}`,
                               "state": `${addObj.state.trim()}`,
                               "country": `${addObj.country.trim()}`,
                               "isPrimary": this.state.addressesStatus.Add2,   
                               "isVerified": false
                            }
                          ]
                        }
                      }
                      console.log("addresss 1====",addObj)
                      await this.props.addCustomerAddressAction(addObj);
                      await this.props.updateUserAddressCIAMAction(address)
                    
                    this.setState({ checkPointUpdateState: true })
                }
            }
            this.setState({ modalState: false, validAddressForm: {} })
        }
        } catch(e) {console.log("error in edit address");}
        
        
    }

    /**
     * Method for deleting customer address

     */
    deleteAddress() {
        arguments[1].preventDefault()
        this.setState({
            checkPointUpdateState: true,

        })
        let addressId = '';
        let addresstype = arguments[0];
        
        if (addresstype === "home") {
            addressId = this.state.customerAddress1.addressId
        } else {
            addressId = !isNaN(this.state.customerAddress2.addressId) ? this.state.customerAddress2.addressId : this.state.customerAddress1.addressId
        }
        let ciamAddressData  = ciamAddressDeleteStateModal({pimaddress:[this.state.customerAddress1,this.state.customerAddress2 ],ngId:this.state.ngId,consent:this.state.consent,addresses:this.state.customerAddinationData.addresses,addessId:addressId});
       
        let deleteAddresObj = {
            "customerId": this.state.customerProfileData.customerId,
            "addressId": addressId,
            "language": "en",
            "loggedInUser": this.state.customerProfileData.customerId
        }
     
        confirmAlert({

            customUI: ({ onClose }) => {
                var lang = this.state.lang
                return (
                    <div className="confirm-popup AddUserModelform">
                        <div className="modal-header giftcart_title_header"><h3 className="modal-title giftcart_all_header">{language.language.are_you_sure[0][lang]}</h3></div>
                        <div className="modal-body">
                            <p>{language.language.You_want_to_delete[0][lang]}</p>
                            <div className="formbtn">
                                <button type="button" className="giftcard_button mgr10"
                                    onClick={async () => {
                                        await this.props.deleteCustomerAddressAction(deleteAddresObj)
                                        this.props.updateUserAddressCIAMAction(ciamAddressData)
                                        onClose();
                                    }}
                                >
                                    {language.language.confirm[0][lang]}
                                </button>
                                <button type="button" onClick={onClose} className="giftcard_button cardnone_button">{language.language.No[0][lang]}</button></div>
                        </div>
                    </div>
                );
            }
        })
    }

    /**
     * Method for changing Address Status 
     * @param {click event}
     * @return response data 
     */
    async changeAddressState() {
        try {

        let objToSend = {}
        if (!arguments[1].target.checked) {
            this.setState({
                buttonState: {
                    defaultButton1: arguments[0] === "home" ? false : true,
                    defaultButton2: arguments[0] === "home" ? true : false
                }
            })
        } else {
            this.setState({
                buttonState: {
                    defaultButton1: arguments[0] === "home" ? true : false,
                    defaultButton2: arguments[0] === "home" ? false : true
                }
            })
        }
        if (this.state.buttonState.defaultButton1) {

            objToSend = {
                "customerId": this.state.customerProfileData.customerId,
                "status": 1,
                "addressId": this.state.customerAddress2.addressId,
                "language": "en",
                "loggedInUser": this.state.customerProfileData.customerId
            }
            let ciamAddressData  = ciamAddressStateModal({pimaddress:[this.state.customerAddress1,this.state.customerAddress2 ],ngId:this.state.ngId,consent:this.state.consent,addresses:this.state.customerAddinationData.addresses});

            if(ciamAddressData!==undefined) {
                  
                    ciamAddressData.address.customerAddress.map((add,i)=>{
                        if(i===0) {
                            add.isPrimary = false
                        } 
                        if(i===1) {
                            add.isPrimary = true
                        }
                    })
                    await this.props.updateUserAddressCIAMAction(ciamAddressData);
            
            }
            
            this.setState({ Loading: true, checkPointUpdateState: true })
            await this.props.updateAddressStatusAction(objToSend)

        } else {
            objToSend = {
                "customerId": this.state.customerProfileData.customerId,
                "status": 1,
                "addressId": this.state.customerAddress2 !== "" ? this.state.customerAddress1.addressId : '',
                "language": "en",
                "loggedInUser": this.state.customerProfileData.customerId
            }

            if (objToSend.addressId !== "") {
                let ciamData  = ciamAddressStateModal({pimaddress:[this.state.customerAddress1,this.state.customerAddress2 ],ngId:this.state.ngId,consent:this.state.consent,addresses:this.state.customerAddinationData.addresses});
               	if(ciamData !==undefined) {
                        ciamData.address.customerAddress.map((add,i)=>{
                    if(i===0) {
                        add.isPrimary = true
                    } 
                    if(i===1) {
                        add.isPrimary = false
                    }
                }) 
            		await this.props.updateUserAddressCIAMAction(ciamData);

                }  
                
            	this.setState({ Loading: true, checkPointUpdateState: true })
                await this.props.updateAddressStatusAction(objToSend)

            }
        }
        } catch(e) { console.log("page break",e)}
    }

/**
* React Life Cycle method 
* Invoke just after chenge in props 
*
* @param {*} nextProps 
*/
    async componentWillReceiveProps(nextProps) {
       
        try {
            if (nextProps.deleteCustomerAddressResponse && nextProps.deleteCustomerAddressResponse.data && nextProps.deleteCustomerAddressResponse.data.error === false) {

                if (this.state.checkPointUpdateState) {

                    this.props.customerProfileAction(this.state.customerProfileData.customerId);

                }
                nextProps.deleteCustomerAddressResponse.data = null

            }
            if (this.state.updateData == false && nextProps.customerProfileInfo && nextProps.customerProfileInfo.data && nextProps.customerProfileInfo.data.error === false) {
                let { data } = nextProps.customerProfileInfo.data.data
                this.setState({
                    customerProfileData: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        companyName: data.companyName,
                        primaryPhone: data.mobile,
                        title: data.title,
                        secondaryPhone: data.phone,
                        federalTax: data.federalTax,
                        customerId: data.id,
                        homeAddress: "",
                        workAddress: "",
                    },
                    primaryPhone: data.mobile,
                    checkNumber:data.mobile,
                    checkPointUpdateState: false,
                    consent: data.consent === true ? 1 : 0,
                    ngId: data.ngId,
                    userName: {
                        firstName: data.firstName,
                        lastName: data.lastName
                    },
                    Loading: false,
                })

                if (data.addresses && data.addresses.length > 0) {
                    this.setState({isHomeAllowed :true})
                    sessionStorage.setItem("isVerityPage",true)
                    if (data.addresses.length === 2) {
                        this.setState({
                            staticAddress1: {
                                addresstype: data.addresses[0].addressType,
                                street: data.addresses[0].address1,
                                city: data.addresses[0].city,
                                state: data.addresses[0].state,
                                country: data.addresses[0].country,
                                zip: data.addresses[0].zip
                            },
                            staticAddress2: {
                                addresstype: data.addresses[1].addressType,
                                street: data.addresses[1].address1,
                                city: data.addresses[1].city,
                                state: data.addresses[1].state,
                                country: data.addresses[1].country,
                                zip: data.addresses[1].zip
                            },
                        })
                    } else {
                        this.setState({
                            staticAddress1: {
                                addresstype: data.addresses[0].addressType,
                                street: data.addresses[0].address1,
                                city: data.addresses[0].city,
                                state: data.addresses[0].state,
                                country: data.addresses[0].country,
                                zip: data.addresses[0].zip
                            }
                        })
                    }
                }  else {
                    sessionStorage.setItem("isVerityPage",false)
                    this.setState({
                        customerAddress1: {
                            customerId: '',
                            addressId: '',
                            address1: "",
                            address2: "address 2",
                            city: "",
                            state: "",
                            zip: "",
                            country: "",
                            phone: "phone",
                            status: 1,
                            createdBy: "5",
                            updatedBy: "5",
                            addressType: "",
                            language: "en",
                            loggedInUser: ""
                        }
                    }
                    )
                }

                if (data.addresses.length > 0) {
                    let emptyAddressObj =
                    {
                        customerId: '',
                        addressId: '',
                        address1: "",
                        address2: "address 2",
                        city: "",
                        state: "",
                        zip: "",
                        country: "",
                        phone: "phone",
                        status: 1,
                        createdBy: "5",
                        updatedBy: "5",
                        addressType: "",
                        language: "en",
                        loggedInUser: ""
                    }

                    let addressObj1 = data.addresses[0] !== undefined ? data.addresses[0] : emptyAddressObj
                    let addressObj2 = data.addresses[1] !== undefined ? data.addresses[1] : emptyAddressObj
                    if (data.addresses.length === 2) {
                        this.setState({ addressesStatus: { Add1: true, Add2: true } })
                        if (data.addresses[0].status === true && data.addresses[1].status === true) {
                            this.setState({
                                buttonState: {
                                    defaultButton1: true,
                                    defaultButton2: false
                                }
                            })
                        } else {
                            this.setState({
                                buttonState: {
                                    defaultButton1: data.addresses[0].status === data.addresses[1].status ? true : data.addresses[0].status,
                                    defaultButton2: data.addresses[1].status
                                }
                            })
                        }

                    } else {
                        this.setState({
                            addressesStatus: { Add1: true, Add2: false },
                            buttonState: {
                                defaultButton1: true,
                                defaultButton2: false
                            }
                        })
                    }
                    this.setState({
                        customerAddress1: {
                            customerId: data.id,
                            addressId: addressObj1.id,
                            address1: addressObj1.address1,
                            address2: addressObj1.address2,
                            city: addressObj1.city,
                            state: addressObj1.state,
                            zip: addressObj1.zip,
                            country: addressObj1.country,
                            phone: addressObj1.phone,
                            status: addressObj1.status,
                            addressType: addressObj1.addressType,
                        },
                        customerAddress2: {
                            customerId: data.id,
                            addressId: addressObj2.id,
                            address1: addressObj2.address1,
                            address2: addressObj2.address2,
                            city: addressObj2.city,
                            state: addressObj2.state,
                            zip: addressObj2.zip,
                            country: addressObj2.country,
                            phone: addressObj2.phone,
                            status: addressObj2.status,
                            addressType: addressObj2.addressType,
                        },
                    })

                } else {
                    this.setState({
                        addressesStatus: {
                            Add1: false,
                            Add2: false
                        },
                        defaultAddress: true
                    })
                }
                
            } else { this.setState({ Loading: false })  }

            if (nextProps.changeCustomerPassword && nextProps.changeCustomerPassword.data && nextProps.changeCustomerPassword.data.error === false) {
                let passwordStatus = false;
                let message = '';
                if (nextProps.changeCustomerPassword.data && nextProps.changeCustomerPassword.data.data.status === true) {
                    passwordStatus = true;
                    message = nextProps.changeCustomerPassword.data.data.message
                } else {
                    message = nextProps.changeCustomerPassword.data.data.message
                }
                this.updatePasswordMessage(passwordStatus, message);
            }

            if (nextProps.updateCustomerProfileResponse && nextProps.updateCustomerProfileResponse.data && nextProps.updateCustomerProfileResponse.data.error === false) {
               
                if (nextProps.updateCustomerProfileResponse.data.data.hasOwnProperty('status') && nextProps.updateCustomerProfileResponse.data.data.status === true) {
            
                    this.setState({
                        flashMesssage: {
                            add: false,
                            update: false,
                            otpCheck: false
                        },
                        userName: false,
                        Loading:false,
                        // Loadingl:false,
                        checkNumber:nextProps.updateCustomerProfileResponse.data.data.data.mobile,
                        updateData:false,
                    })
                    this.setState({ flashMessageError: '', flashMessageSuccess: language.language.profile_info_updated[0][this.state.lang] })
                    if (this.state.checkPointUpdateState) {
                        this.props.searchUserCIAMbyEmail(sessionStorage.emailID);
                        this.props.customerProfileAction(this.state.customerProfileData.customerId);
                    }
                    nextProps.updateCustomerProfileResponse.data = null
                    setTimeout(this.eventMessages.bind(this, "profile"), 3000)
                   
                }
            }
            if (nextProps.addCustomerAddressResponse && nextProps.addCustomerAddressResponse.data && nextProps.addCustomerAddressResponse.data.error === false) {
                this.setState({
                    flashMesssage: {
                        add: true,
                    },
                    flashMessageSuccess: "",
                });
              
                if (this.state.checkPointUpdateState) {

                    this.props.customerProfileAction(this.state.customerProfileData.customerId)
                    this.setState({
                        checkPointUpdateState: false,
                        Loading: false
                    })
                }
                nextProps.addCustomerAddressResponse.data = null
                setTimeout(this.eventMessages.bind(this, "add"), 3000)
            } else {
                this.setState({
                    Loading: false,
                    checkPointUpdateState: false
                })
            }
            if (nextProps.updateCustomerAddressResponse && nextProps.updateCustomerAddressResponse.data && nextProps.updateCustomerAddressResponse.data.error === false) {
                this.setState({
                    flashMesssage: {
                        add: false,
                        update: true
                    }
                })
                if (this.state.checkPointUpdateState) {

                    this.props.customerProfileAction(this.state.customerProfileData.customerId)
                    this.setState({
                        checkPointUpdateState: false,
                        Loading: false
                    })
                }
                nextProps.updateCustomerAddressResponse.data = null;
                setTimeout(this.eventMessages.bind(this, "update"), 3000)

            } else {
                this.setState({
                   
                    checkPointUpdateState: false,
                })
            }
            if (nextProps.getCustomerOrderListResponse && nextProps.getCustomerOrderListResponse.data) {
                if (nextProps.getCustomerOrderListResponse.data.error === false) {
                    this.setState({
                        customerOrderList: nextProps.getCustomerOrderListResponse.data.data.data,
                        Loading: false,
                        flashMesssage: {
                            add: false,
                            update: false
                        }
                    })
                    nextProps.getCustomerOrderListResponse.data = null
                    if (this.props.location.state !== undefined) {
                        this.props.location.state.fromPage = ""
                    }
                    if (this.state.checkPointUpdateState) {
                        this.props.customerProfileAction(this.state.customerProfileData.customerId);

                    }
                    this.props.customerProfileAction(this.state.customerProfileData.customerId);
                }
            }
            if (nextProps.updateAddressStatusResponse && nextProps.updateAddressStatusResponse.data && nextProps.updateAddressStatusResponse.data.error === false) {
                if (this.state.checkPointUpdateState) {

                    let { data } = nextProps.updateAddressStatusResponse.data.data
                    if (data.id === this.state.customerAddress1.addressId) {
                        this.setState({
                            buttonState: {
                                defaultButton1: true,
                                defaultButton2: false
                            }
                        })
                    } else {
                        this.setState({
                            buttonState: {
                                defaultButton1: false,
                                defaultButton2: true
                            }
                        })
                    }
                    this.setState({ checkPointUpdateState: false })
                    nextProps.updateAddressStatusResponse.data = null
                    this.props.customerProfileAction(this.state.customerProfileData.customerId);
                }
            } else {
                this.setState({
                    checkPointUpdateState: false,
                    Loading: false
                })
            }
           

            if (nextProps.serachCIAMuser.data !== undefined && nextProps.serachCIAMuser.data !== null) {
                let _this = this
               
                if (nextProps.serachCIAMuser.data.data.ciam.length > 0) {
                    var data = nextProps.serachCIAMuser.data.data.ciam[0].primaryPhoneNumber
                    let ngId_ = nextProps.serachCIAMuser.data.data.ciam[0].prosperaId
                    if(!this.state.userByid){
                        this.props.searchUserByIdAction(ngId_)
                    } 
                    _this.setState({
                        CIAM_PrimaryPhone: data,
                        ngId : ngId_,
                        Loading:false,
                        userByid:true
                    })
                }
                // nextProps.serachCIAMuser.data = null 
            } else {
                this.setState({
                    CIAM_PrimaryPhone: false,
                   

                    // Loading:false
                })
            }


            if (this.props.location !== undefined && this.props.location.state !== undefined) {

                if (this.props.location.state.fromPage === "orderHistory") {
                    this.setState({
                        OrderHistoryTab: "active",
                        Loading: true,
                        profileTab: "",
                        updatePasswordTab: ""
                    })
                    let dataToSendServer = {
                        "loggedInUser": `${this.state.customerProfileData.customerId}`,
                        "language": "en",
                        "customerId": sessionStorage.userID
                    }
                    this.props.getCustomerOrderListAction(dataToSendServer)
                }
            }

            if (nextProps && nextProps.sendOtp) {
                let self = this
                try {
                    if(nextProps.sendOtp.data.error) {
                        this.setState({flashMessageError:"Your Profile Information cannot be updated at this moment. Please try after sometime."}) 
                        setTimeout(()=>{this.setState({flashMessageError:""})},3000);
                        return ;

                    }
                    if (nextProps.sendOtp.data && nextProps.sendOtp.data.data!==null && nextProps.sendOtp.data.data.success === true) {
                        if (self.state.otpStatus === true) {
                            self.setState({
                                openOtpModal: true,
                                otpStatus: false,
                                otpSuccessSpan: nextProps.sendOtp.data.data.msg,
                                otpErrorSpan: '',
                                Loading:false
                            })
                        }
                        
                    } else {
                        self.setState({
                            openOtpModal: true,
                            otpStatus: false,
                            otpErrorSpan: nextProps.sendOtp.data.data.msg,
                            otpSuccessSpan: '',
                            Loading:false
                        })
                    }
                    nextProps.sendOtp.data.data = null
                }
                catch (err) {
                }
            }

            if (nextProps.otpVerify && nextProps.otpVerify.data!==undefined && nextProps.otpVerify.data !== null && this.state.isResend === false) {
                
                if (nextProps.otpVerify.data.success === true && this.state.otpCheck == true) {
                    this.setState(state=>({
                        otpCheck: false,
                        openOtpModal: false,
                        Loadingl:true
                       
                    }))
                    var json =  {
                            "source": "gift_card",
                            "prosperaId": this.state.ngId,
                            "isUpdateAllKeys":true,
                            "dialCode": "+1",
                            "primaryPhoneNumber":this.state.customerProfileData.primaryPhone.replace(/[^a-zA-Z0-9]/g, ''),
                            "isPhoneVerified": true,
                            "jobTitle":this.state.customerProfileData.title,
                            "alternatePhone": {
                              "customerPhone": [
                                {
                                  "source": "gift_card",
                                  "phoneType": "cell",
                                  "dialCode": "+1",
                                  "phoneNo": this.state.customerProfileData.primaryPhone.replace(/[^a-zA-Z0-9]/g, '')
                                },
                                {
                                    "source": "gift_card",
                                    "phoneType": "cell",
                                    "dialCode": "+1",
                                    "phoneNo": this.state.customerProfileData.secondaryPhone.replace(/[^a-zA-Z0-9]/g, '')
                                }
                              ]
                            },
                            "isOptin": true,
                            "optin": [
                              {
                                "optinCategoryId": constant.OPTION_CATEGORY_ID,
                                "updatedsource": "gift_card",
                                "subscribe": this.state.consent == 1 ?  true :  false 
                              }
                            ]
                          }
                    if (this.state.ngId) {
                        this.setState({
                            checkPointUpdateState:true,
                            Loading:true,
                            otp: {
                                o1: '',
                                o2: '',
                                o3: '',
                                o4: '',
                            },
                        })
                        

                        // this.props.updateCustomerProfileAction(this.state.customerProfileData)
                        await this.props.updatePrimaryPhoneCIAMAction(json)
                        
                    }
                    

                    nextProps.otpVerify.data  = null 
                    // this.props.updateCustomerProfileAction(this.state.customerProfileData)
                } else {
                    this.setState({ otpErrorSpan: nextProps.otpVerify.data.msg })
                }
            } else {
                // this.setState({
                //     otpErrorSpan:"Your Profile Information cannot be updated at this moment. Please try after sometime."
                // })
                // setTimeout(()=>{this.setState({otpErrorSpan:''})},3000)
               // some thing wrong with CAIM
            }
            // if(nextProps.searchCIAMUserByIdData!==undefined) {
               
            //     if(nextProps.searchCIAMUserByIdData.data.error===false && nextProps.searchCIAMUserByIdData.data.data.success===true) {
            //         const { data } = nextProps.searchCIAMUserByIdData.data.data
                    
            //         this.setState({
            //             ...this.state,
            //             customerAddinationData:{
            //                 ...this.state.customerAddinationData,
            //                 alternateph:data.customer.alternatePhone===null ? data.customer.alternatePhone :data.customer.alternatePhone.customerPhone.filter(phone=>phone.isPrimary === false ),
            //                 addresses:data.customer.address,
                            
            //             }
            //         })
            //         this.setState(state=> ({ Loading:false ,Loadingl:false }) ) 
                    
            //     }
            //     nextProps.searchCIAMUserByIdData.data.data = null
            // }

            try {
                if (nextProps.updateAddressCIAMResponse.data) {

                } else {
                    console.log(nextProps.updateAddressCIAMResponse, "else")
                }
            } catch (e) { }
            finally {
                if (this.state.checkPointUpdateState) {

                    this.props.customerProfileAction(this.state.customerProfileData.customerId)
                    this.setState({
                        checkPointUpdateState: false,
                        // Loading: false
                    })
                }
            }

            /***Handle updatePrimaryPhoneCIAM response  */
            if(nextProps.hasOwnProperty('PrimaryPhoneCIAMResponse') && nextProps.PrimaryPhoneCIAMResponse.hasOwnProperty('data') &&  nextProps.PrimaryPhoneCIAMResponse.data !== null ) {
                const { data } = nextProps.PrimaryPhoneCIAMResponse.data
                if(data !== null && data !== undefined ) {
                    if(data.success) {
                        this.setState({
                            checkPointUpdateState:true,
                            Loading:true,
                            isCIAM_Phone_UP:true,
                            Loadingl:false,
                            otp: {
                                o1: '',
                                o2: '',
                                o3: '',
                                o4: '',
                            },
                            otpErrorSpan:'',
                            primaryPhone:this.state.CIAM_PrimaryPhone,
                            
                            
                        })
                        let data_ =
                        {
                            "customerId": this.state.customerProfileData.customerId,
                            "companyName": this.state.customerProfileData.companyName,
                            "title": this.state.customerProfileData.title != '' ? this.state.customerProfileData.title : '',
                            "federalTax": this.state.customerProfileData.federalTax,
                            "mobile": this.state.customerProfileData.primaryPhone,
                            "phone": this.state.customerProfileData.secondaryPhone,
                            "loggedInUser": this.state.customerProfileData.customerId,
                            "consent": this.state.consent,
                            "language": "en"
                        }
                     
                        // await this.props.updateCustomerProfileAction(data_)
                        await this.props.searchUserCIAMbyEmail(this.state.customerProfileData.email)
                        await this.props.updateCustomerProfileAction(data_)
                        await this.props.customerProfileAction(this.state.customerProfileData.customerId)
                    }
                    nextProps.PrimaryPhoneCIAMResponse.data =  null 
                }
            }            


        } catch (e) { 
            console.log("some error", e) 
            this.setState({Loading:false})
                 }
        finally {
            this.setState({ flashMessageError: ""})
        }
    }

    /** Method to logout from the session*/
    logOut() {
        sessionStorage.loggedIn = '';
        this.props.history.push("/");
    }

    /** Method to go to the Shipping page*/
    openShippingPage() {
        let self = this
        self.props.history.location.push({
            path: "/ordermanagement",
            state: 'shippmentPage'
        })
    }

    /** Method to set the language*/
    setLanguage(data) {
        this.setState({
            lang: data
        })
    }

    /** Method submit form on enter Keypress*/
    submitOnEnter(event) {
        let self = this
        if (event.which == 13 || event.keyCode == 13) {
            if (event.target.id == 'federal-tax') {
                self.updateCustomerProfile(this)
            }
            else if (event.target.id == 'confirmPassword') {
                self.changePassword()
            }
            else if (event.target.id == 'country') {
                self.onSubmitAddress()
            }
        }
    }

    /** Method for error message tih setTimer*/
    errorMessage(message) {
        this.setState({
            serverPasswordError: true,
            serverPasswordMessage: message
        });
        setTimeout(function () {
            this.setState({
                serverPasswordError: '',
                serverPasswordMessage: ''
            })
        }.bind(this), 3000);
    }

    /** Method for open ordermanagement page*/
    openOrderManagement() {
        sessionStorage.userID = this.state.customerProfileData.customerId
        sessionStorage.cardType = 'all'
        this.props.history.push('/ordermanagement')
    }

    /** Method to set the size of the text on the page*/
    changeSize(data) {
        if (data === 'increase') {
            var increaseCounter = this.state.increaseCounter
            increaseCounter = increaseCounter + 1
            if (increaseCounter <= 4) {
                this.setState({
                    fontSize: {
                        header2: Number(this.state.fontSize.header2) + 1,
                        addressmyprofile: Number(this.state.fontSize.addressmyprofile) + 1,
                        addressdetails: Number(this.state.fontSize.addressdetails) + 1,
                        profilelabel: Number(this.state.fontSize.profilelabel) + 1,
                        customerhistory: Number(this.state.fontSize.customerhistory) + 1,
                        customerhistorydetails: Number(this.state.fontSize.customerhistorydetails) + 1,
                        customerpricetab: Number(this.state.fontSize.customerpricetab) + 1,
                        allOrderdetails: Number(this.state.fontSize.allOrderdetails) + 1,
                    },
                    increaseCounter: increaseCounter
                })
            }
        }
        else if (data === 'decrease') {
            var decreaseCounter = this.state.decreaseCounter
            decreaseCounter = decreaseCounter + 1
            if (decreaseCounter <= 4) {
                this.setState({
                    fontSize: {
                        header2: Number(this.state.fontSize.header2) - 1,
                        addressmyprofile: Number(this.state.fontSize.addressmyprofile) - 1,
                        addressdetails: Number(this.state.fontSize.addressdetails) - 1,
                        profilelabel: Number(this.state.fontSize.profilelabel) - 1,
                        customerhistory: Number(this.state.fontSize.customerhistory) - 1,
                        customerhistorydetails: Number(this.state.fontSize.customerhistorydetails) - 1,
                        customerpricetab: Number(this.state.fontSize.customerpricetab) - 1,
                        allOrderdetails: Number(this.state.fontSize.allOrderdetails) - 1,
                    },
                    decreaseCounter: decreaseCounter,
                    increaseCounter: 0
                })
            }
        }
        else if (data === 'default') {
            this.setState({
                fontSize: {
                    header2: 24,
                    addressmyprofile: 14,
                    addressdetails: 16,
                    profilelabel: 16,
                    customerhistory: 15,
                    customerhistorydetails: 13,
                    customerpricetab: 14,
                    allOrderdetails: 12,
                },
                increaseCounter: 0,
                decreaseCounter: 0
            })
        }
    }

    componentDidUpdate() {
        if (this.props.changeCustomerPassword.data !== null) {
            this.props.changeCustomerPassword.data = null
        }
    }

    /** Method to select consent */
    setConsent = (e) => {
        if (e.target.checked) {
            this.setState({ consent: 1 })
        } else {
            this.setState({ consent: 0 })
        }
    }

    /** Method to open policy modal */
    openModalPolicy = (e) => {
        this.setState({ policyModalopen: true })
    }

    /** Method to close policy modal */
    closeModalPolicy = () => {
        this.setState({
            policyModalopen: false,
            otpErrorSpan: '',
        });
        if (document.getElementsByClassName("modalPolicy")[0]) {
            document.getElementsByClassName("modalPolicy")[0].classList.add("exit");
        }
    };

    /** Method to close opt modal */
    onCloseOtpModal = () => {
        this.setState({
            openOtpModal: false,
            otpErrorSpan: '',
            isResend: true,
            updateData:false,
            otp: {
                o1: '', o2: '', o3: '', o4: ''
            },
            
        })
        if(!this.state.isCIAM_Phone_UP) {
            let cum = Object.assign(this.state.customerProfileData,{primaryPhone:this.state.checkNumber})
            this.setState({customerProfileData:cum} ) 
        } else {
            let cum = Object.assign(this.state.customerProfileData,{primaryPhone:this.state.checkNumber})
            this.setState({customerProfileData:cum} ) 
        }
       
    }

    /** Method to varify the opt */
    verifyOTP() {
        try {
            const { otp } = this.state
            if (otp.o1 != '' && otp.o2 != '' && otp.o3 != '' && otp.o4 != '') {
                var otpString = otp.o1 + otp.o2 + otp.o3 + otp.o4
                var verifyOtpJson = {
                    "phoneNumber": "+1" +this.state.customerProfileData.primaryPhone.replace(/[^a-zA-Z0-9]/g, '') ,
                    "emailId": "",
                    "otp": otpString
                }

                this.setState({Loading:true })
                this.props.otpVerifyAction(verifyOtpJson)
                this.setState({
                    isResend: false,
                    otpSuccessSpan: '',
                    otpCheck: true,
                    otpErrorSpan: '',

                })
                
                document.getElementsByName('o1')[0].value = ''
            } else {
                this.setState({ otpErrorSpan: 'Invalid OTP' })
                setTimeout(function () {
                    this.setState({
                        otpErrorSpan: ''
                    })
                }.bind(this), 3000);
            }
        } catch (e) { console.log("error verifyOTP", e) }


    }

    /*Method to set otp */
    setOTP = (e) => {
        this.setState({ otpErrorSpan: '', flashMessageError: '', otpSuccessSpan: '' })
        let val = e.target.value;
        let key = e.target.name;
        let valid = {};
        if (val === '') {
            valid[key] = '';
        } else {
            valid[key] = val;
        }
        let otp = Object.assign(this.state.otp, valid);
        this.setState({
            otp: otp
        });
        if (val != '') {
            if (key == "o1")
                document.getElementsByName('o2')[0].focus()
            if (key == "o2")
                document.getElementsByName('o3')[0].focus()
            if (key == "o3")
                document.getElementsByName('o4')[0].focus()
        }
        if (val === '') {
            if (key == "o4")
                document.getElementsByName('o3')[0].focus()
            if (key == "o3")
                document.getElementsByName('o2')[0].focus()
            if (key == "o2")
                document.getElementsByName('o1')[0].focus()
        }
    }

    /**
     * function for handle close modal
     *  
     */
    onCloseModal = () => {
        this.setState({
            modalState: false,
            flashMessageError: '',
            addressFlashMessageError: '',
            flashMessageSuccess: '',
            flashMesssage: {
                add: false,
                update: false
            },
            isEmptyField: false,
            validAddressForm: {},
            editUserName: false,
            updateNameError:false ,
            

        });
        this.clearAddressData();




    };
    // Method for open and close modal for Edit User Name
    editUserName = () => {
        this.setState({ editUserName: true })
    }

    editName = (e) => {
        try {

            let key = e.target.name
            let val = e.target.value.trimStart()
            let userName = this.state

            if (key === 'firstName') {
                if (this.isCharter(val)) {
                    
                    let obj = Object.assign(userName, { firstName: val,lastName:this.state.userName.lastName })
                    this.setState({ userName: obj ,updateNameError:false})
                }

            } else if (key === 'lastName') {
                if (this.isCharter(val)) {

                    let obj = Object.assign(userName, { lastName: val,firstName:this.state.userName.firstName})
                    this.setState({ userName: obj,updateNameError:false })

                }
            }

        } catch (er) { console.log("erro is happening ", er) }

    }

    /** 
     * Method for update user name 
     * @param { event}
     * 
     */
    onUpdateUserName = (e) => {

        try {
            e.preventDefault()
            let { userName, customerProfileData } = this.state
            let regX = /^[A-Za-z ]$/
           
            let data =
            {
                "customerId": this.state.customerProfileData.customerId,
                "firstName": userName.firstName === undefined ? customerProfileData.firstName.trim() : userName.firstName.trim(),
                "lastName": userName.lastName === undefined ? customerProfileData.lastName.trim() : userName.lastName.trim(),
                "language": "en"
            }
            
            if (data.firstName === "" || regX.test(data.firstName)) {
                let  userName= {
                    firstName: '',
                }
                Object.assign(this.state.userName,userName)
                this.setState({updateNameError:true,isEmptyField:true,})
                return 0
            }
            if (data.lastName === "" || regX.test(data.lastName)) {
                let  userName= {
                    lastName: '',
                }
                Object.assign(this.state.userName,userName)
                this.setState({updateNameError:true,})
                return 0
            }
            if(data.lastName!=="" && data.firstName!=="" ) {
                this.setState({updateNameError:false})

            }
            this.setState({ Loading: true ,updateNameError:false})
            this.setState({ editUserName: false, checkPointUpdateState: true })

            this.props.updateCustomerProfileAction(data,this.state.ngId)


        } catch (error) { console.log("error", error) }

    }

    /***Method for cancle button for update user name  */
    handleCancle = (e) => {
        this.setState({ editUserName: false ,updateNameError:false})

    }
    
   //Method for Resend OTP 
    resendOTP = () => {
       
        var otpJson = {
            "northgateId": "",
            "To": "+1" + this.state.customerProfileData.primaryPhone.replace(/[^a-zA-Z0-9]/g, ''),
            "emailId": "",
            "source": "gift_card"
        }
        this.setState({
            isResend: true,
            otpErrorSpan: '',
            isEmptyField: true
        })
        this.setState({
            otpStatus: true,
            updateData: true,
        })
        try {
            document.getElementsByName('o1')[0].value = ""
        } catch (e) { console.log("erro", e) }
        finally {
            setTimeout(() => {
                document.getElementsByName('o1')[0].value = ""
                document.getElementsByName('o2')[0].value = ""
                document.getElementsByName('o3')[0].value = ""
                document.getElementsByName('o4')[0].value = ""
                this.setState({
                    otp: {
                        o1: "", o2: "", o3: "", o4: ""
                    }
                })

            }, 200)
        }

        
        this.props.sendOTPAction(otpJson)
    }
    
    render() {

        let { fontSize}  = this.state   
        if (sessionStorage.userID == '' && sessionStorage.loggedIn == '') {
            this.props.history.push('/');
        }
        let customerOrderList = this.state.customerOrderList;
        let flashMessageSuccessSpan = '';
        if (this.state.flashMessageSuccess) {
            flashMessageSuccessSpan = <p  style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{this.state.flashMessageSuccess}</p>;
        }
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <p style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{this.state.flashMessageError}</p>;
        }
        let addressFlashMessageErrorSpan = '';
        if (this.state.addressFlashMessageError) {
            addressFlashMessageErrorSpan = <p style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{this.state.addressFlashMessageError}</p>;
        }
        if (sessionStorage.loggedIn === '') {
            this.props.history.push("/");
        }
        let addressEdit = this.state.editAdress !== "" ? this.state.editAdress : "";
        let { customerAddress1 } = this.state;
        let { customerAddress2 } = this.state;
        let { addressesStatus } = this.state;
        let { addressStatus } = this.state
        addressStatus = addressesStatus.Add1 === false && addressesStatus.Add2 === false ? false : true
        const { customerProfileData } = this.state
        var lang = this.state.lang
     
        let { validAddressForm } = this.state
        let { changePasswordFields } = this.state
        let otpErrorSpan = ''
        if (this.state.otpErrorSpan) {
            otpErrorSpan = <p color='danger' style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{this.state.otpErrorSpan}</p>
        }
        let otpSuccessSpan = ''
        if (this.state.otpSuccessSpan) {
            otpSuccessSpan = <p style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{this.state.otpSuccessSpan}</p>
        }

      

        return (
            <div id="myaccount">
                <div className="wrapper page customerHome">

                    <Header language={this.setLanguage.bind(this)} changeSize={this.changeSize.bind(this)} locationProps={this.props} />

                    <section id="main">
                        <div className="container-fluid">
                            <div className="row">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item "><a href={addressStatus === true && this.state.isHomeAllowed ===true ? "/ordermanagement" : "#"} 
                                    className="active"  style={{ fontSize: fontSize.allOrderdetails + 'px' }}   > {language.language.home[0][lang]}</a></li>
                                    <li className="breadcrumb-item"><a href="#" style={{ fontSize: fontSize.allOrderdetails + 'px' }}  >{language.language.My_Account[0][lang]}</a></li>


                                </ul>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="account-section">
                                        <h2 style={{ fontSize: fontSize.header2 + 'px' }}>{language.language.My_Account[0][lang]}</h2>

                                    </div>
                                </div>
                                <div className="col-md-3 ">
                                    {addressesStatus.Add1 ?
                                        <a><button 
                                            type="button" 
                                            className="giftcard_button cardnone_button pull-right" 
                                            onClick={this.openOrderManagement.bind(this)}
                                            style={{ fontSize: fontSize.customerhistorydetails + 'px' }}   
                                            >{language.language.BUY_GIFT_CARD[0][lang]}</button></a>
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="user-profile boxshadow30">
                                        <a href="#/" className="pencil" onClick={(e) => { this.editUserName(e) }}>
                                            <img src={pencil} alt="edit" title="edit" />
                                        </a>
                                        <h2 style={{ fontSize: fontSize.header2 + 'px' }}>{customerProfileData.firstName + " " + customerProfileData.lastName}  
                                         <span className="tooltipSection" >{customerProfileData.firstName + " " + customerProfileData.lastName}</span></h2>




                                    </div>

                                    {/** Add  */}
                                    <div className="user-address boxshadow30">
                                        {
                                            this.state.flashMesssage.add === true ?
                                                <div className="alert alert-success alert_succesfull clearfix " role="alert">
                                                    <div className="successbtn"><img src={successimg} alt="alert_succesfull" title="alert_succesfull" /></div>
                                                    <p className="text-center clearfix">{language.language.Add_address[0][this.state.lang]} </p>
                                                </div>
                                                //  <Alert color="success">Address Added successfully </Alert> 
                                                : ''}

                                        {this.state.flashMesssage.update === true ?

                                            <div className="alert alert-success alert_succesfull clearfix " role="alert">
                                                <div className="successbtn"><img src={successimg} alt="alert_succesfull" title="alert_succesfull" /></div>
                                                <p className="text-center clearfix">{language.language.Update_address[0][this.state.lang]}</p>
                                            </div>



                                            : ''}

                                        <h3>{language.language.ADDRESS[0][lang]}</h3>
                                        {this.state.addressesStatus.Add1 ?
                                            <div className="account-home-section">
                                                <h4 style={{ fontSize: fontSize.addressmyprofile + 'px' }}>{this.state.staticAddress1.addresstype}</h4>
                                                {
                                                    isNaN(this.state.customerAddress2.addressId) || this.state.customerAddress2.addressId === "" ?
                                                        <label className="switch">
                                                            <input title="changeAddress" type="checkbox" checked={true} onClick={(e) => { this.changeAddressState("home", e) }} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                        :

                                                        <label className="switch">
                                                            <input title="changeAddress" type="checkbox" checked={this.state.buttonState.defaultButton1} onClick={(e) => { this.changeAddressState("home", e) }} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                }
                                                <address style={{ fontSize: fontSize.addressdetails + 'px' }}>

                                                    <div>
                                                        {this.state.staticAddress1.street}, {this.state.staticAddress1.city}
                                                        <br />
                                                        {this.state.staticAddress1.state}, <p className="add30">{this.state.staticAddress1.country}, <br /> {this.state.staticAddress1.zip} </p>

                                                    </div>
                                                </address>

                                                <div className="account-bottom-link">
                                                    <a href="#/" onClick={this.deleteAddress.bind(this, "home")} ><img src={deleteAcc} alt="deleteAcc" title="deleteAcc" /></a>
                                                    <a href="#/" onClick={(e) => { this.onOpenModal("home", e) }}><img src={pencil} alt="edit" title="edit" /></a>
                                                </div>
                                            </div> : ""}
                                        {this.state.addressesStatus.Add1 === true && this.state.addressesStatus.Add2 === true ?
                                            <div className="account-home-section">
                                                <h4  style={{ fontSize: fontSize.addressmyprofile + 'px' }} >{this.state.staticAddress2.addresstype}</h4>
                                                <label className="switch">
                                                    <input title="changeAddress" type="checkbox" checked={this.state.buttonState.defaultButton2} onClick={(e) => { this.changeAddressState("work", e) }} />
                                                    <span className="slider round"></span>
                                                </label>

                                                <address style={{ fontSize: fontSize.addressdetails + 'px' }}>


                                                    <div>
                                                        {this.state.staticAddress2.street}, {this.state.staticAddress2.city}
                                                        <br />
                                                        {this.state.staticAddress2.state}, <p className="add30">{this.state.staticAddress2.country},<br /> {this.state.staticAddress2.zip} </p>
                                                        <br />


                                                    </div>



                                                </address>

                                                <div className="account-bottom-link">
                                                    <a href="#/" onClick={this.deleteAddress.bind(this, "Office")} ><img src={deleteAcc} alt="deleteAcc" title="deleteAcc" /></a>
                                                    <a href="#/" onClick={(e) => { this.onOpenModal("office", e) }}><img src={pencil} alt="edit" title="edit" /></a>
                                                </div>
                                                <div>
                                                </div>
                                            </div>

                                            : ""}

                                        {this.state.addressesStatus.Add1 !== true || this.state.addressesStatus.Add2 !== true ?
                                            <div className="btn-section text-right">
                                                <button className="giftcard_button cardnone_button" data-toggle="modal" data-target="#registerModal" onClick={this.onOpenModal.bind(this, "add")}>{language.language.ADD_NEW_ADDRESS[0][lang]}</button>

                                            </div>
                                            : ''}


                                    </div>


                                    {/***    Add */}


                                </div>
                                <div className="col-md-9">
                                    {(this.state.Loading === true || this.state.Loadingl) && <div className="loader-react">
                                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                                    </div>
                                    }

                                    <div className="form-section boxshadow30">
                                        <ul className="nav nav-tabs profile-tab" role="tablist">
                                            <li role="presentation" className={this.state.profileTab + ' borderprofileTab borderProfileTabList custome_tab'}><a href="#/" className="profile-img" onClick={(e) => { this.handleTabChange("profileTab", e) }} style={{ fontSize: fontSize.customerhistory + 'px' }} >{language.language.profile[0][lang]}</a></li>
                                            <li role="presentation" className={this.state.updatePasswordTab + ' borderprofileTab borderProfileTabList'}><a href="#/" className="update-password" onClick={(e) => { this.handleTabChange("updatePasswordTab", e) }} style={{ fontSize: fontSize.customerhistory + 'px' }} >{language.language.update_password[0][lang]}</a></li>
                                            <li role="presentation" className={this.state.OrderHistoryTab + ' borderprofileTab borderProfileTabList'}><a href="#/" className="order-history" onClick={(e) => { this.handleTabChange("OrderHistoryTab", e) }} style={{ fontSize: fontSize.customerhistory + 'px' }}>{language.language.order_history[0][lang]}</a></li>
                                            <li className="profileBottomborder" role="presentation"></li>
                                        </ul>


                                        <div className="tab-content">
                                            <div role="tabpanel" className={`tab-pane ${this.state.profileTab}`} id="profile">
                                                <form className="address-form">
                                                    {flashMessageSuccessSpan !== "" ?
                                                        <div className="alert alert-success alert_succesfull clearfix col-md-6 " role="alert">
                                                            <div className="successbtn"><img src={successimg} alt="" /></div>
                                                            <p className="text-center clearfix"> {flashMessageSuccessSpan} </p>
                                                        </div>

                                                        : ''}
                                                    {flashErrorMessageSpan ?
                                                        <div className="alert alert-success alert_succesfull adpadding30 clearfix col-md-6 " role="alert">
                                                            <span><img src={errorimg} alt="alert_succesfull" title="alert_succesfull" /></span>
                                                            <p className="clearfix">{flashErrorMessageSpan}</p>
                                                        </div>
                                                        : ''}

                                                    

                                                    <div className="form-group">
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.company_name[0][lang]}</label>
                                                        <input title={language.language.company_name[0][lang]}
                                                            readOnly
                                                            type="text"
                                                            className="form-control non-editable"
                                                            value={this.state.customerProfileData.companyName}
                                                            onChange={this.setCompanyName.bind(this)} />

                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.title[0][lang]}</label>
                                                        <input title={language.language.title[0][lang]}
                                                            type="text"
                                                            readOnly={!this.state.edit}
                                                            className={`form-control ${validAddressForm.title === "" && this.state.isEmptyField ? 'red' : ""}`}
                                                            value={customerProfileData.title}
                                                            name="title"
                                                            ref={(e) => this.title = e}
                                                            onChange={this.setTitle.bind(this)} maxLength="50"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.email_id[0][lang]}</label>
                                                        <input title={language.language.email_id[0][lang]}
                                                            readOnly
                                                            type="email"
                                                            className="form-control non-editable" value={this.state.customerProfileData.email} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.primary_number[0][lang]}</label>
                                                        <InputMask title={language.language.primary_number[0][lang]}
                                                            readOnly={!this.state.edit}
                                                            className={`form-control ${validAddressForm.primaryPhone === "" && this.state.isEmptyField ? 'red' : ""}`}
                                                            onChange={this.setPrimaryPhone.bind(this)}
                                                            mask="(999) 999-9999" maskChar={null} value={customerProfileData.primaryPhone}
                                                            name="primaryPhone"
                                                        />
                                                        
                                                         
                                                              
                                                        
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.secondary_number[0][lang]}</label>
                                                        <InputMask title={language.language.secondary_number[0][lang]}
                                                            readOnly={!this.state.edit}
                                                            className={`form-control ${validAddressForm.secondaryPhone === "" && this.state.isEmptyField ? 'red' : ""}`}
                                                            onChange={this.setSecondaryPhone.bind(this)}
                                                            mask="(999) 999-9999"
                                                            maskChar={null}
                                                            value={customerProfileData.secondaryPhone != 0 ? customerProfileData.secondaryPhone : ''}
                                                            name="secondaryPhone"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.federal_tax[0][lang]}</label>
                                                        <InputMask title={language.language.federal_tax[0][lang]}
                                                            readOnly={!this.state.edit}
                                                            className={`form-control ${validAddressForm.federalTax === "" && this.state.isEmptyField ? 'red' : ""}`}
                                                            onChange={this.setFederalTax.bind(this)}
                                                            mask="(999-99-9999)"
                                                            maskChar={null}
                                                            value={customerProfileData.federalTax != 0 ? customerProfileData.federalTax : ''}
                                                            id="federal-tax"
                                                            onKeyPress={(e) => this.submitOnEnter(e)}
                                                            name="federalTax"
                                                        />
                                                    </div>
                                                    <div className="form-group" style={{ fontSize: fontSize.profilelabel + 'px' }}>
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}></label>
                                                        <input title={language.language.consent[0][lang]} type="checkbox" className="checkbox-btn" checked={this.state.consent === 1 ? true : false} onClick={this.setConsent.bind(this)} />
                                                        {language.language.consent[0][lang]}
                                                        <span className="purple-color" onClick={(e) => this.openModalPolicy(e)}>{language.language.consent[1][lang]}</span>
                                                    </div>
                                                    <div className="submitAccountDetail">
                                                        <button type="button" className="giftcard_button pull-right" 
                                                        style={{ fontSize: fontSize.customerhistory + 'px' }}
                                                        onClick={this.updateCustomerProfile.bind(this)}>{language.language.SAVE[0][lang]}</button>
                                                    </div>

                                                </form>
                                            </div>
                                            <div role="tabpanel" className={`tab-pane ${this.state.updatePasswordTab}`} id="update-password">
                                                <form className="update-password">
                                                    <div container className="form-group">
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.current_password[0][lang]}</label>
                                                        <input title={language.language.current_password[0][lang]}
                                                            className={`form-control ${this.state.isEmptyField && changePasswordFields.oldPassword === "" ? 'red' : ''}`}
                                                            type="password"
                                                            name='oldPassword'
                                                            value={this.state.currentPassword}
                                                            ref={(e) => this.currentPassword = e}
                                                            onChange={this.setCurrentPassword.bind(this)}
                                                        />
                                                    </div>

                                                    <div container className="form-group">
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.new_password[0][lang]}</label>
                                                        <input title={language.language.new_password[0][lang]}
                                                            className={`form-control ${this.state.isEmptyField && changePasswordFields.newPassword === "" ? 'red' : ''}`}
                                                            type="password" name='newPassword'
                                                            value={this.state.newPassword}
                                                            ref={(e) => this.newPassword = e} onChange={this.setNewPassword.bind(this)}
                                                        />
                                                    </div>

                                                    <div container className="form-group">
                                                        <label style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.confirm_password[0][lang]}</label>
                                                        <input title={language.language.confirm_password[0][lang]}
                                                            id='confirmPassword'
                                                            className={`form-control ${this.state.isEmptyField && changePasswordFields.confirmPassword === "" ? 'red' : ''}`}
                                                            type="password"
                                                            name='confirmPassword'
                                                            value={this.state.confirmPassword}
                                                            ref={(e) => this.confirmPassword = e}
                                                            onChange={this.setConfirmPassword.bind(this)}
                                                            onKeyPress={(e) => this.submitOnEnter(e)}
                                                        />
                                                    </div>

                                                    {this.state.serverPasswordError ?
                                                        <div className="alert alert-success alert_succesfull adpadding30 clearfix col-md-7" role="alert">
                                                            <span><img src={errorimg} alt="" /></span>
                                                            <p className="clearfix">{this.state.serverPasswordMessage}</p>
                                                        </div>
                                                        : ''}
                                                    {this.state.serverPasswordError === false ?
                                                        <div className="alert alert-success alert_succesfull adpadding30 clearfix col-md-6" role="alert">
                                                            <span><img src={errorimg} alt="alert_succesfull" title="alert_succesfull" /></span>
                                                            <p className="clearfix">{this.state.serverPasswordMessage}</p>
                                                        </div>
                                                        : ''}
                                                    {this.state.displaySuccess === true ?
                                                        <div className="alert alert-success alert_succesfull clearfix col-md-6" role="alert">
                                                            <div className="successbtn"><img src={successimg} alt="alert_succesfull" title="alert_succesfull" /></div>
                                                            <p className="text-center clearfix"> {language.language.password_updated_success[0][lang]} </p>
                                                        </div>
                                                        : ''}
                                                    <div className="formbtn" align-left>
                                                        <button type="button" className="giftcard_button mgr10" onClick={(e) => this.changePassword()}>{language.language.UPDATE[0][lang]}</button>
                                                        <button type="button" className="giftcard_button cardnone_button" onClick={(e) => { this.clearData() }}>{language.language.CANCEL[0][lang]}</button>
                                                    </div>
                                                </form>

                                            </div>
                                            <div role="tabpanel" className={`tab-pane ${this.state.OrderHistoryTab}`} id="order-history">

                                                <div className="container-fluid">

                                                    <div className="row">

                                                        <div className="ml-auto pull-right">


                                                        </div>

                                                        <div className="customer-order-history all-order all_scroll_section scroll-order-history">


                                                            {
                                                                customerOrderList.length > 0 ?
                                                                    customerOrderList.map(function (orderlist,i) {
                                                                        return (
                                                                            <div className="customer-order-history" key={i}>
                                                                                <div className="header">
                                                                                    <div className="row">
                                                                                        <div className="col-md-4">
                                                                                            <p className="date-field" style={{ fontSize: fontSize.customerhistory + 'px' }}>
                                                                                                {language.language.ORDER_NO[0][lang]}#{orderlist.orderNumber}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="col-md-4 text-center">
                                                                                            <p className="order-value" style={{ fontSize: fontSize.customerhistory + 'px' }}>
                                                                                                {moment(new Date(orderlist.orderDate)).format('"MM/DD/YYYY"').replace(/^"(.*)"$/, '$1')}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <p className="total-text-tab" style={{ fontSize: fontSize.customerhistory + 'px' }}>${Number(parseFloat(orderlist.total).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}</p>

                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                                {orderlist.orderedProduct.length > 0 ?

                                                                                    orderlist.orderedProduct.map(function (product, index) {

                                                                                        return (
                                                                                            <div className="row" key={index}>

                                                                                                <div className="col-md-4">
                                                                                                    <div className="media order-section">
                                                                                                        <div className="media-left-img">
                                                                                                            <img src={product.imagePath == "" ? noImage : constant.APIURL + product.imagePath + product.imageName} />
                                                                                                        </div>
                                                                                                        <div className="media-body">
                                                                                                            <h4 className="media-heading" style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{`${product.productType}
                                                                                                              ($${parseFloat(product.productPrice).toFixed(2)}) `}</h4>
                                                                                                            <span style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{language.language.Qty[0][lang]} : {product.noOfCards}</span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-12  text-center">
                                                                                                            <div className="price-section-tab">
                                                                                                                <p style={{ fontSize: fontSize.customerpricetab + 'px' }}>${Number(parseFloat(+product.productPrice * +product.noOfCards).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}</p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    {
                                                                                                        0 === index ?
                                                                                                            orderlist.cancelStatus === false || orderlist.cancelStatus === null ?
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className={orderlist.orderedProduct.length === 1 ? "delivery-section " : "delivery-section-all"}>
                                                                                                                        {orderlist.activationStatus === true ?
                                                                                                                            <p className="font-change" style={{ fontSize: fontSize.allOrderdetails + 'px' }}>{language.language.ORDER_STATUS[0][lang]}: Activated</p> :
                                                                                                                            orderlist.trackingUrl !== "" ?
                                                                                                                                // <p className="font-change" style={{ fontSize: fontSize.allOrderdetails + 'px' }}> Order Status : Delivered</p> :
                                                                                                                                <div>
                                                                                                                                    <p className="font-change" className="font-change reason-left" style={{ fontSize: fontSize.allOrderdetails + 'px' }}>{language.language.ORDER_STATUS[0][lang]}: shipped </p>
                                                                                                                                    {/* <p> */}
                                                                                                                                    <a className="font-change order-url reason-left" href={`${trackingUrl}${orderlist.orderTracking}&cntry_code=us&locale=en_US`} style={{ fontSize: fontSize.allOrderdetails + 'px' }}><span style={{ color: '#adadad' }}>{language.language.Tracking_Number[0][lang]}:</span> {orderlist.orderTracking}</a>
                                                                                                                                    {/* </p>  */}
                                                                                                                                </div> :
                                                                                                                                <div>
                                                                                                                                    <div className="font-change reason-left" style={{ fontSize: fontSize.allOrderdetails + 'px', color: '#adadad', textTransform: 'uppercase' }}> {language.language.Expected_Delivery_On[0][lang]}{moment( new Date(orderlist.eta)).format('"MM/DD/YYYY"').replace(/^"(.*)"$/, '$1')}</div>
                                                                                                                                </div>}

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className={orderlist.orderedProduct.length === 1 ? "delivery-section" : "delivery-section-all"}>
                                                                                                                        <p className="font-change" className="font-change " style={{ fontSize: fontSize.allOrderdetails + 'px' }}> {language.language.ORDER_STATUS[0][lang]}: Cancelled </p>

                                                                                                                        <div className="font-change" className="font-change capchanges reason-left" style={{ fontSize: fontSize.allOrderdetails + 'px' }}><span className="cap30">{language.language.Reason[0][lang]}: </span>
                                                                                                                            <span className="capchanges">{ orderlist.cancelReason}</span></div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            : ""
                                                                                                    }
                                                                                                </div> 
                                                                                            </div>
                                                                                        )

                                                                                    }) : ""
                                                                                }

                                                                            </div>
                                                                        )

                                                                    }) : <div className="ordernot_foud"><span> {language.language.NO_ORDER_ERROR[0][lang]} </span>  </div>

                                                            }


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer>
                            <p>© 2019 Northgate Gonzalez Market®</p>
                        </footer>
                    </section>
                    <script src="js/custom.js"></script>
                </div>
                <div className="modal" id="registerModal">
                    <Modal 
                        open={this.state.modalState}
                        onClose={this.onCloseModal} 
                        center 
                        classNames={{ overlay: 'addAddressoverlay', modal: 'addAddressModelform' }}
                        closeOnOverlayClick={false}
                    >

                        <div className="modal-dialog AddNewAddress">
                            <div className="modal-content">
                                {/*-- Modal Header */}
                                <div className="modal-header from-header account">
                                    {
                                        this.state.fromHeading ?
                                            <h4 className="modal-title " style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{language.language.ADD_NEW_ADDRESS[0][lang]}</h4> :
                                            <h4 className="modal-title " style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{language.language.EDIT_ADDRESS[0][lang]}</h4>

                                    }
                                    <button type="button" className="close account" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body ">
                                    <form action="/" className="address-form">
                                        {/* {addressFlashMessageErrorSpan ? addressFlashMessageErrorSpan : ""} */}

                                        {addressFlashMessageErrorSpan ?
                                            <div className="alert alert-success alert_succesfull adpadding30 clearfix  " role="alert">
                                                <span><img src={errorimg} alt="alert-success" title="alert-success" /></span>
                                                <p className="clearfix">{addressFlashMessageErrorSpan}</p>
                                            </div>
                                            : ''}
                                        <div className="form-group">
                                            {
                                                this.state.addressErrors["addresstype"] !== undefined ?
                                                    <Alert className='alertFont' bsStyle="danger">{this.state.addressErrors["addresstype"]}</Alert> : ''
                                            }
                                        {/* customerhistorydetails */}
                                            <label htmlFor="address" style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.Address_Type[0][lang]}</label>
                                            {
                                                isNaN(this.state.customerAddress1.addressId) || this.state.customerAddress1.addressId === "" ?
                                                    <select title={language.language.Choose_Address[0][lang]} className={`form-control ${validAddressForm.addressType === "" && this.state.isEmptyField ? 'red' : ''}`} onChange={(e) => { this.handleClickAdress(e, "addressType") }} name="addressType" >
                                                        <option style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{language.language.Choose_Address[0][lang]}</option>
                                                        <option style={{ fontSize: fontSize.customerhistorydetails + 'px' }} value="Home">{language.language.home[0][lang]}</option>
                                                        <option style={{ fontSize: fontSize.customerhistorydetails + 'px' }} value="Office">{language.language.Address_Office[0][lang]}</option>
                                                    </select> :
                                                    <select title={language.language.Choose_Address[0][lang]} className={`form-control ${validAddressForm.addressType === "" && this.state.isEmptyField ? 'red' : ''}`} onChange={(e) => { this.handleClickAdress(e, "addressType") }} value={addressEdit === "customerAddress1" ? customerAddress1.addressType : customerAddress2.addressType} name="addressType">
                                                        <option style={{ fontSize: fontSize.customerhistorydetails + 'px' }} >{language.language.Choose_Address[0][lang]}</option>
                                                        <option style={{ fontSize: fontSize.customerhistorydetails + 'px' }} value="Home">{language.language.home[0][lang]} </option>
                                                        <option  style={{ fontSize: fontSize.customerhistorydetails + 'px' }} value="Office">{language.language.Address_Office[0][lang]}</option>
                                                    </select>
                                            }

                                        </div>
                                        <div className="form-group">
                                            {
                                                this.state.addressErrors["street"] !== undefined ?
                                                    <Alert className='alertFont' bsStyle="danger">{this.state.addressErrors["street"]}</Alert> : ''
                                            }
                                            <label htmlFor="street" style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.Street[0][lang]}</label>
                                            {
                                                isNaN(this.state.customerAddress1.addressId) || this.state.customerAddress1.addressId === "" ?
                                                    <input title={language.language.Street[0][lang]} value = {this.state.customerAddress1.address1} type="text" className={`form-control ${validAddressForm.street === "" && this.state.isEmptyField ? 'red' : ''}`} onChange={(e) => { this.handleClickAdress(e, "street") }} maxLength="60" name="street" style={{ fontSize: fontSize.customerhistorydetails + 'px' }} /> :
                                                    <input title={language.language.Street[0][lang]} type="text" className={`form-control ${validAddressForm.street === "" && this.state.isEmptyField ? 'red' : ''}`} onChange={(e) => { this.handleClickAdress(e, "street") }} value={addressEdit === "customerAddress1" ? customerAddress1.address1 : customerAddress2.address1} maxLength="60" name="street" style={{ fontSize: fontSize.customerhistorydetails + 'px' }} />
                                            }



                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="zip" style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.Zip_Code[0][lang]}</label>


                                            {
                                                isNaN(this.state.customerAddress1.addressId) || this.state.customerAddress1.addressId === "" ?
                                                    <InputMask 
                                                        style={{ fontSize: fontSize.customerhistorydetails + 'px' }}
                                                        title={language.language.Zip_Code[0][lang]} mask="999999" maskChar={null} type="text"
                                                        onChange={(e) => { this.handleClickAdress(e, "zip") }}
                                                        onBlur={(e) => { this.autoFillAddress(e) }}
                                                        name="zip"
                                                        className={`form-control ${validAddressForm.zip === "" && this.state.isEmptyField ? 'red' : ''}`}
                                                    /> :

                                                    <InputMask  
                                                        title={language.language.Zip_Code[0][lang]} mask="999999" maskChar={null} type="text"
                                                        style={{ fontSize: fontSize.customerhistorydetails + 'px' }}
                                                        value={addressEdit === "customerAddress1" ? customerAddress1.zip : customerAddress2.zip}
                                                        onChange={(e) => { this.handleClickAdress(e, "zip") }}
                                                        onBlur={(e) => { this.autoFillAddress(e) }}
                                                        name="zip"
                                                        className={`form-control ${validAddressForm.zip === "" && this.state.isEmptyField ? 'red' : ''}`}
                                                    />
                                            }



                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="city" style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.City[0][lang]}</label>
                                            {
                                                isNaN(this.state.customerAddress1.addressId) || this.state.customerAddress1.addressId === "" ?
                                                    <input 
                                                        style={{ fontSize: fontSize.customerhistorydetails + 'px' }}
                                                        title={language.language.City[0][lang]}
                                                        type="text"
                                                        className={`form-control ${validAddressForm.city === "" && this.state.isEmptyField ? 'red' : ''}`}

                                                        onChange={(e) => { this.handleClickAdress(e, "city") }}
                                                        value={customerAddress1.city} maxLength="20"
                                                        name="city"
                                                    /> :
                                                    <input 
                                                        style={{ fontSize: fontSize.customerhistorydetails + 'px' }}
                                                        title={language.language.City[0][lang]}
                                                        type="text"
                                                        className={`form-control ${validAddressForm.city === "" && this.state.isEmptyField ? 'red' : ''}`}
                                                        readOnly={this.state.readOnly}
                                                        onChange={(e) => { this.handleClickAdress(e, "city") }}
                                                        value={addressEdit === "customerAddress1" ? customerAddress1.city : customerAddress2.city} maxLength="20" name="city" />
                                            }

                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="State" style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.State[0][lang]}</label>
                                            {
                                                isNaN(this.state.customerAddress1.addressId) || this.state.customerAddress1.addressId === "" ?
                                                    <input 
                                                        style={{ fontSize: fontSize.customerhistorydetails + 'px' }}
                                                        title={language.language.State[0][lang]}
                                                        type="text"
                                                        className={`form-control ${validAddressForm.state === "" && this.state.isEmptyField ? 'red' : ''}`}
                                                        onChange={(e) => { this.handleClickAdress(e, "state") }}
                                                        value={customerAddress1.state} maxLength="20" name="state"
                                                    /> :
                                                    <input 
                                                        style={{ fontSize: fontSize.customerhistorydetails + 'px' }}
                                                        title={language.language.State[0][lang]}
                                                        type="text"
                                                        className={`form-control ${validAddressForm.state === "" && this.state.isEmptyField ? 'red' : ''}`}
                                                        readOnly={this.state.readOnly} onChange={(e) => { this.handleClickAdress(e, "state") }}
                                                        value={addressEdit === "customerAddress1" ? customerAddress1.state : customerAddress2.state} maxLength="20"
                                                        name="state"
                                                    />
                                            }

                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="country" style={{ fontSize: fontSize.profilelabel + 'px' }}>{language.language.Country[0][lang]}</label>
                                            {
                                                isNaN(this.state.customerAddress1.addressId) || this.state.customerAddress1.addressId === "" ?
                                                    <input 
                                                        style={{ fontSize: fontSize.customerhistorydetails + 'px' }}
                                                        title={language.language.Country[0][lang]}
                                                        id='country'
                                                        type="text"
                                                        className={`form-control ${validAddressForm.country === "" && this.state.isEmptyField ? 'red' : ''}`}
                                                        onChange={(e) => { this.handleClickAdress(e, "country") }}
                                                        value={customerAddress1.country} onKeyPress={(e) => this.submitOnEnter(e)} maxLength="40"
                                                        name="country"
                                                    /> :
                                                    <input 
                                                        style={{ fontSize: fontSize.customerhistorydetails + 'px' }}
                                                        title={language.language.Country[0][lang]}
                                                        id='country'
                                                        type="text"
                                                        readOnly={this.state.readOnly}
                                                        className={`form-control ${validAddressForm.country === "" && this.state.isEmptyField ? 'red' : ''}`}
                                                        onChange={(e) => { this.handleClickAdress(e, "country") }}
                                                        value={addressEdit === "customerAddress1" ? customerAddress1.country : customerAddress2.country}
                                                        onKeyPress={(e) => this.submitOnEnter(e)}
                                                        maxLength="40"
                                                        name="country"
                                                    />
                                            }

                                        </div>
                                        <div className="buttons model-fot">

                                            <button  style={{ fontSize: fontSize.customerhistorydetails + 'px' }} type="button" className="giftcard_button pull-right" onClick={() => { this.onSubmitAddress() }}>{language.language.SAVE[0][lang]}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>



                {/**Policy modal open */}
                <div className="modal-box" id="">
                    <ReactModal 
                        closeTimeoutMS={500} 
                        isOpen={this.state.policyModalopen} 
                        onRequestClose={this.closeModalPolicy}
                        className="modal-dialog-box fullModal" 
                        center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel modalPolicy' }}
                        shouldCloseOnOverlayClick={false}
                        >
                        <div className="modal-content">
                            <div className="modal-header polcy-header">
                                <h4 className="modal-title"></h4>
                                <button className="styles_closeButton__20ID4 closemodalbtn pol-close" onClick={this.closeModalPolicy}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                            </div>
                            <div className="modal-body newsseventall container privacy-body">
                                <form>
                                    <div className="row">
                                        {/* <p> Consent to receiving promotional and marketing emails from Northgate Gonzalez, LLC and its affiliates and agree to their privacy policy</p> */}
                                        <Consent lang={lang} />
                                        <div className="formbtn pol-btn">
                                        <button type="button" className="giftcard_button mgr10 pull-right" onClick={this.closeModalPolicy}>{language.language.CLOSE[0][lang]}</button>
                                
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ReactModal>
                </div>
                {/**Policy modal end */}


                {/* otp modal */}
                <div className="modal-box" id="">
                <OtpModal 
                    open={this.state.openOtpModal} 
                    onClose={this.onCloseOtpModal} 
                    className="modal-dialog-box" center 
                    classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel otpModel' }}
                    closeOnOverlayClick={false}
                    showCloseIcon = {true}
                >

                        <div className="modal-content otpModel">
                            <div className="modal-header giftcart_title_header">
                                <h4 className="modal-title giftcart_all_header">Enter OTP</h4>
                            </div>

                            <div className="modal-body otpModel">
                                {/* {otpErrorSpan} */}
                                {otpErrorSpan !== '' ?
                                    <div className="alert alert-success alert_succesfull adpadding30 clearfix " role="alert">
                                        <span><img src={errorimg} alt="alert-success" title="alert-success" /></span>
                                        <p className="clearfix">{otpErrorSpan}</p>
                                    </div>
                                    : ''}

                                {otpSuccessSpan !== "" ?
                                    <div className="alert alert-success alert_succesfull clearfix" role="alert">
                                        <div className="successbtn"><img src={successimg} alt="alert-success" title="alert-success" /></div>
                                        <p className="text-center clearfix"> {otpSuccessSpan} </p>
                                    </div>

                                    : ''}
                                <form className="otp-form">

                                    <div className="row pdlft8">
                                        <p className="otp-text">Please enter your one time password (OTP) that has been sent on your mobile number. This OTP is valid for 10 mins.</p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row pdlft8">
                                            <div className="col-md-3 pl-0"><InputMask mask={'9'} maskChar={null} className="form-control  otp-inputbox" type="text" name="o1" onChange={(e) => this.setOTP(e)} value={this.state.otp.o1} /></div>
                                            <div className="col-md-3 pl-0"><InputMask mask={'9'} maskChar={null} className="form-control  otp-inputbox" type="text" name="o2" onChange={(e) => this.setOTP(e)} value={this.state.otp.o2} /></div>
                                            <div className="col-md-3 pl-0"><InputMask mask={'9'} maskChar={null} className="form-control  otp-inputbox" type="text" name="o3" onChange={(e) => this.setOTP(e)} value={this.state.otp.o3} /></div>
                                            <div className="col-md-3 pl-0"><InputMask mask={'9'} maskChar={null} className="form-control  otp-inputbox" type="text" name="o4" onChange={(e) => this.setOTP(e)} value={this.state.otp.o4} /></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 pl-0">
                                        <div className="row pdlft8">
                                            <div className="formbtn col-md-12 text-left resend-otp">
                                                <button type="button" className="giftcard_button mgr10 " onClick={this.verifyOTP.bind(this)}>VERIFY</button>
                                                <a href="javascript:void(0)" onClick={this.resendOTP.bind(this)} className="resend-otp pl-3" >Resend OTP !</a>

                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        
                    </OtpModal>
                </div>
                {/* otp modal end */}


                {/**User name update modal */}
                <div className="modal" id="registerModal">
                    <Modal open={this.state.editUserName} onClose={this.onCloseModal} center classNames={{ overlay: 'addAddressoverlay', modal: 'addAddressModelform' }}
                    
                    closeOnOverlayClick={false}
                    >

                        <div className="modal-dialog AddNewAddress">
                            <div className="modal-content">
                                {/*-- Modal Header */}
                                <div className="modal-header from-header account">
                                    <h4 className="modal-title " style={{ fontSize: fontSize.customerhistorydetails + 'px' }}>{language.language.EDIT_USER_NAME[0][lang]}</h4>
                                    <button type="button" className="close account" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body ">
                                    <div className="form-group">

                                    {
                                        this.state.updateNameError===true ?(
                                            <div className="row">
                                                <div className="col-md-12 form-group text-right">
                                                    <p className="noteplus30 pb-0 m-0" style={{color:"red",fontSize: fontSize.addressmyprofile + 'px'}}>{language.language.all_fields_mandatory[0][lang]}</p>
                                                </div>
                                            </div>) 
                                        :(
                                            <div className="row">
                                                <div className="col-md-12 form-group text-right">
                                                    <p className="noteplus30 pb-0 m-0" style={{fontSize: fontSize.addressmyprofile + 'px'}} >{language.language.all_fields_mandatory[0][lang]}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                        
                                        <label style={{fontSize: fontSize.addressmyprofile + 'px'}}>First Name</label>
                                        <input
                                            title="First Name"
                                           
                                            className={`form-control${this.state.isEmptyField && this.state.userName.firstName==="" ? ' red' : ''}`}

                                            value={this.state.userName.firstName}
                                            name="firstName"
                                            onChange={(e) => { this.editName(e) }}
                                            maxLength={15}
                                            style={{fontSize: fontSize.addressmyprofile + 'px'}}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label style={{fontSize: fontSize.addressmyprofile + 'px'}}>Last Name </label>
                                        <input
                                            title="Last Name"
                                            className={`form-control${this.state.isEmptyField && this.state.userName.lastName==="" ? ' red' : ''}`}
                                            value={this.state.userName.lastName}
                                            name="lastName"
                                            onChange={(e) => { this.editName(e) }}
                                            maxLength={15}
                                            style={{fontSize: fontSize.addressmyprofile + 'px'}}
                                        />
                                    </div>
                                   
                                    <div className="buttons model-fot">
                                        <button
                                            type="button"
                                            className="giftcard_button pull-right"
                                            onClick={(e) => { this.onUpdateUserName(e) }}
                                            style={{fontSize: fontSize.addressmyprofile + 'px'}}
                                            
                                            >{language.language.SAVE[0][lang]}
                                            
                                        </button>
                                        <button
                                            type="button"
                                            className="giftcard_button cardnone_button pull-right mgr10"
                                            data-dismiss="modal"
                                            onClick={(e) => { this.handleCancle(e) }}
                                            style={{fontSize: fontSize.addressmyprofile + 'px'}}

                                        >{language.language.CANCEL[0][lang]}
                                        </button>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal>

                </div>
                {/** modal end  */}

            </div>

        );
    }
}


/**
 * constant mapStateToProps for pass value in redux
 * @param object state
 * @return object userLoginInfo
*/
const mapStateToProps = (state) => {

    return {
        customerProfileInfo: state.customerProfile,
        changeCustomerPassword: state.changeCustomerPassword,
        updateCustomerProfileResponse: state.updateCustomerProfile,
        addCustomerAddressResponse: state.addCustomerAddress,
        updateCustomerAddressResponse: state.updateCustomerAddress,
        deleteCustomerAddressResponse: state.deleteCustomerAddress,
        updateAddressStatusResponse: state.updateAddressStatus,
        getCustomerOrderListResponse: state.getCustomerOrderList,
        serachCIAMuser: state.serachCIAMuser,
        sendOtp: state.sendOtp,
        otpVerify: state.otpVerify,
        userNameUpdateResponse: state.updateUserName,
        updateAddressCIAMResponse: state.updateAddressCIAM,
        searchCIAMUserByIdData:state.searchCIAMUserById,
        PrimaryPhoneCIAMResponse : state.updatePrimaryPhoneCIAM//this.state.primaryPhone
    };
}
export default connect(mapStateToProps, {searchUserByIdAction ,customerProfileAction, changeCustomerPasswordAction, updateCustomerProfileAction, addCustomerAddressAction, updateCustomerAddressAction, deleteCustomerAddressAction, updateAddressStatusAction, getCustomerOrderListAction, searchUserCIAMbyEmail, sendOTPAction, otpVerifyAction, updatePrimaryPhoneCIAMAction, updateUserAddressCIAMAction })(Dashboard)