import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
  isSubmitting: false,
  data: null,
  login: false
}

/**
 * Function for get users list
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function customerProfile(state = data, action) {
  switch (action.type) {
    case constants.CUSTOMER_PROFILE_INFO:
      return { ...state, data: action.payload };
    case constants.CUSTOMER_PROFILE_INFO_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to change customer password
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function changeCustomerPassword(state = data, action) {
  switch (action.type) {
    case constants.CUSTOMER_CHANGE_PASSWORD:
      return { ...state, login: true, data: action.payload };
    case constants.CUSTOMER_CHANGE_PASSWORD_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to update customer profile
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function updateCustomerProfile(state = data, action) {
  switch (action.type) {
    case constants.CUSTOMER_PROFILE_UPDATE:
      return { ...state, login: true, data: action.payload };
    case constants.CUSTOMER_PROFILE_UPDATE_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to reset customer password through link
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function resetCustomerPassword(state = data, action) {
  switch (action.type) {
    case constants.CUSTOMER_RESET_PASSWORD_DATA:
      return { ...state, login: true, data: action.payload };
    case constants.CUSTOMER_RESET_PASSWORD_DATA_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to activate customer
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function activateCustomer(state = data, action) {
  switch (action.type) {
    case constants.ACTIVATE_CUSTOMER_DATA:
      return { ...state, login: true, data: action.payload };
    case constants.ACTIVATE_CUSTOMER_DATA_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/*
 * Function to add customer address 
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function addCustomerAddress(state = data, action) {
  switch (action.type) {
    case constants.CUSTOMER_ADDRESS_DATA:
      return { ...state, login: true, data: action.payload };
    case constants.CUSTOMER_ADDRESS_DATA_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to add customer address 
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function updateCustomerAddress(state = data, action) {
  switch (action.type) {
    case constants.UPDATE_CUSTOMER_ADDRESS_DATA:
      return { ...state, login: true, data: action.payload };
    case constants.UPDATE_CUSTOMER_ADDRESS_DATA_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to add customer address 
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function deleteCustomerAddress(state = data, action) {
  switch (action.type) {
    case constants.DELETE_CUSTOMER_ADDRESS_DATA:
      return { ...state, login: true, data: action.payload };
    case constants.DELETE_CUSTOMER_ADDRESS_DATA_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to add customer address 
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function updateAddressStatus(state = data, action) {
  switch (action.type) {
    case constants.UPDATE_ADDRESS_STATUS_DATA:
      return { ...state, login: true, data: action.payload };
    case constants.UPDATE_ADDRESS_STATUS_DATA_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to search customer in CIAM by email address
 * @param {*} state 
 * @param {*} action 
 */
export function serachCIAMuser(state = data, action) {
  switch (action.type) {
    case constants.CIAM_SEARCH:
      return { ...state, login: true, data: action.payload };
    case constants.CIAM_SEARCH_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }  
}

/**
 * Function to update customer primary phone number in CIAM
 * @param {*} state 
 * @param {*} action 
 */
export function updatePrimaryPhoneCIAM(state = data, action){
  switch (action.type) {
    case constants.UPDATE_PRIMARY_PHONE_CIAM:
      return { ...state, login: true, data: action.payload };
    case constants.UPDATE_PRIMARY_PHONE_CIAM_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to update customer address in CIAM
 * @param {*} state 
 * @param {*} action 
 */
export function updateAddressCIAM(state = data, action){
  switch (action.type) {
    case constants.UPDATE_ADDRESS_CIAM:
      return { ...state, login: true, data: action.payload };
    case constants.UPDATE_ADDRESS_CIAM_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}
/**
 * Function to update customer address in CIAM
 * @param {*} state 
 * @param {*} action 
 */
export function searchCIAMUserById(state = data, action){
  switch (action.type) {
    case constants.SEARCH_USER_BY_ID:
      return { ...state, login: true, data: action.payload };
    case constants.SEARCH_USER_BY_ID_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}