import React, { Component } from 'react'
import * as HelperUtil from './../../../utils/common-helper'
import moment from "moment";
import ReactModal from 'react-modal';
import { discountListAction } from './../../../Actions/Admin/discountManagementAction'
import _ from 'lodash';
import * as language from './../../../Constants/translations'
import { connect } from 'react-redux'


/** Main class for displaying discount list */
class Discount extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isDicountopen: false,
            discountList: [],
            activeDiscount: [],
            currentDate: moment(new Date()).format('MM/DD/YYYY'),
            lang: 'en',
        }
    }

    /**
    * React Built in method 
    * invoked just before component about to mount 
    * 
    */
    componentWillMount() {
        this.props.discountListAction(6)

    }



    /**
  * React Life Cycle method 
  * Invoke just after chenge in props / setState
  *
  * @param {*} nextProps 
  */
    componentWillReceiveProps(nextProps) {
        try {
            if (nextProps.hasOwnProperty('lang')) {
                if (nextProps.lang !== null && nextProps.lang !== undefined) {
                    this.setState({ lang: nextProps.lang })
                }
            }
            if (nextProps.hasOwnProperty('discountList') && nextProps.discountList.hasOwnProperty('data')) {
                if (nextProps.discountList.data.error !== true) {
                    let discountList = nextProps.discountList.data.data.data
                    let list = []
                    let activeDiscount = []
                    discountList.length ? discountList.map((discount) => {
                        let a, b,c;
                        console.log(discount)
                        if (discount != null && discount.validFrom != null && discount.validFrom != null) {
                            a = moment(new Date(discount.validFrom)).format('MM/DD/YYYY')
                            b = moment(new Date(discount.validTill)).format('MM/DD/YYYY')
                            c = moment(new Date()).format('MM/DD/YYYY')
                          
                            if((moment(c).isBetween(a,b) || c === a || c === b) && discount.status === true ) {
                                list.push(discount)
                            
                            } 
                            
                        }
                    }) : void 0
                    discountList = list
                    if (discountList !== null && discountList !== '') {
                        if (discountList.length) {
                            discountList.map((list) => {
                                if (list.status === true) {
                                    activeDiscount.push(list)
                                }
                                return 0
                            })
                        }
                    }
                    this.setState({

                        activeDiscount: discountList
                    })
                }
            }

        } catch (e) {console.log("error ",e)}

        
    }

    /**
      * Method for fetching discount 
      * @param { event }
      */
    showDiscount = (e) => {
        
       try { this.setState({ isDicountopen: true })
        setTimeout(()=>{
            document.getElementsByClassName("ReactModal__Overlay")[0].className ="newmodal"
          },200)
        e.preventDefault()} 
        catch(e) {console.log("erro",e)}
    }

    /**Method for close discount modal  */
    closeModal = () => {

        this.setState({ isDicountopen: false });
    };

    render() {
        let activeDiscount = this.state.activeDiscount
        let cardDisount = []
        let achDiscount = []
        let {lang } = this.state
        activeDiscount.length && activeDiscount !== null ? activeDiscount.map((discount) => {
            discount.paymentType === "Card" ? cardDisount.push(discount) : achDiscount.push(discount)
            return 0
        }) : void 0
        let cardSort
        if (cardDisount.length) {
            cardSort = _.sortBy(cardDisount, (discount) => {
                return discount.amountFrom
            })
        }
        let achSort
        if (achDiscount.length) {
            achSort = _.sortBy(achDiscount, (discount) => {
                return discount.amountFrom
            })
        }
        let fontSize =  this.props.fontSize !== undefined ?  this.props.fontSize.loginSignup :''
    
        let discountString = sessionStorage.language === "spa" ? "o mas" : "and above"
        return (
            <div>

                <div className="card_icon cardnone_button">
                    <a 
                        title={language.language.Discount[0][lang]} 
                        href="javascript:void(0)" 
                        onClick={(e) => { this.showDiscount(e) }}
                        style={{ fontSize:fontSize  + 'px'}}
                    >{language.language.Discount[0][lang]} </a>
                </div>


                <ReactModal
                    isOpen={this.state.isDicountopen}
                    closeTimeoutMS={500}
                    onRequestClose={this.closeModal}
                    className="modal-dialog-box"
                    center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }} shouldCloseOnOverlayClick={false}>
                    <div className="modal-content landingcustomemodal discount_home">
                        <div className="modal-header giftcart_title_header">
                            <h4 className="modal-title giftcart_all_header new_modal">{language != undefined ? 
                            activeDiscount.length > 0 ?
                            language.language.Gift_CARD_ToolTip_Discount[0][lang] :language.language.Gift_CARD_ToolTip_Discount_Not_Found[0][lang] : ''}</h4>
                            <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModal}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                        </div>
                       
                       { activeDiscount.length > 0 ?
                        <div className="modal-body">
                            
                                {activeDiscount.length && activeDiscount != null && activeDiscount != '' ?

                                    <div className="discount-pay discount_new discount_home">

                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{language.language.Payment_Type[0][lang]}</th>
                                                    <th>{language.language.Purchase_Amount[0][lang]}</th>
                                                    <th>{language.language.Gift_card_discount[0][lang]} <i className="fa fa-info-circle question_mark" title = {language.language.Gift_CARD_ToolTip_Discount[0][lang]}></i> </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {cardSort != undefined && cardSort.length > 0 ? cardSort.map((discount, index) => {
                                                    return (<tr>
                                                        {index === 0 ? <td> <span className="fa fa-credit-card"></span> {language.language.CARD_Discount[0][lang]}</td> : <td></td>}
                                                        <td>${HelperUtil._getAmountCommaSeparator(discount.amountFrom).toString()} {discount.amountTo === 0 ? discountString : "- $" + HelperUtil._getAmountCommaSeparator(discount.amountTo).toString()}</td>
                                                        <td>{discount.percent}%</td>
                                                    </tr>)
                                                })
                                                    : <tr></tr>}
                                                {cardDisount != undefined && cardDisount.length && achDiscount != undefined && achDiscount.length ?
                                                    <tr >
                                                        <td style={{ borderBottom: '2px solid #a1a1a1', padding: '0' }}></td>
                                                        <td style={{ borderBottom: '2px solid #a1a1a1', padding: '0' }}></td>
                                                        <td style={{ borderBottom: '2px solid #a1a1a1', padding: '0' }}></td>
                                                    </tr> :
                                                    <tr></tr>}
                                                {achSort != undefined && achSort.length > 0 ? achSort.map((discount, index) => {
                                                    return (<tr className="">
                                                        {index === 0 ? <td> <span className="fa fa-university"></span> {language.language.ACH_Discount[0][lang]}</td> : <td></td>}
                                                        <td>${HelperUtil._getAmountCommaSeparator(discount.amountFrom).toString()} {discount.amountTo === 0 ? discountString : "- $" + HelperUtil._getAmountCommaSeparator(discount.amountTo).toString()}</td>
                                                        <td>{discount.percent}%</td>
                                                    </tr>)
                                                })
                                                    : <tr></tr>}

                                            </tbody>


                                        </table>

                                    </div>
                                    : ''}

                                </div> : void 0}
                            </div> 
                </ReactModal>
            </div>
        )
    }
}

/**
 * constant mapStateToProps for pass value in redux
 * @param object state
 * @return object userLoginInfo
*/
const mapStateToProps = (state) => {
    return {

        discountList: state.discountList

    };
}
export default connect(mapStateToProps, { discountListAction })(Discount)