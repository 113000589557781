import React, { Component } from 'react';
import { connect } from 'react-redux'
import { denominationListAction, getCardTypeAction, denominationAddAction, denominationUpdateAction, denominationImageDeleteAction ,resetAddEditDenomintion} from '../../../Actions/Admin/denominationManagementAction';
import Modal from 'react-responsive-modal';
import * as constant from '../../../Constants/';
import ImageUpload from "./uploadImage"
import { Alert } from 'reactstrap';
import nouploadimage from '../../../public/img/no-image-uploaded.jpg';
import ReactLoading from 'react-loading'
import _ from 'lodash';

/** Class for Adding and Updating Gift card design */
class AddEditDenomination extends Component {

  constructor(props) {
    super(props);
    this.state = {
      flashMessageError: '',
      flashMessageSuccess: '',
      open: false,
      cardTypeList: [],
      imageType: '',
      imagebase64: '',
      giftCardTypeId: '',
      denominationValue: '',
      userId: '',
      file: null,
      denominationId: '',
      denominationImageName: '',
      denominationImagePath: '',
      giftcardDropDownInputName: 'cardType',
      giftcardRadioInputName: '',
      clearImage: false,
      Loading: false,
      checkPointUpdateState: false,
      denominationOption: false,
      fontSize: {
        header1: 72,
        header2: 24,
        giftCardButton: 21,
        loginSignup: 13,
        language: 10,
        a: 15,
        breadcrumb: 12,
        giftCardText: 22,
        tabs: 14,
        cardAmount: 20.8,
        sideBarText: 18,
      },
      holidayOption: true,
      hideRedPoint: false,
      fileSizeErrorMessage: "",
    }
    this.showErrormessage = this.showErrormessage.bind(this)
  }

  /**
   * function for authenticate user is valid or not on submit
   * @param object event
   * @return object userParams
    */
  denominationSubmitHandler = async (event) => {
    event.preventDefault();
    let self = this
    let firstSelectedIndex = undefined
    try {
      let current = document.getElementsByClassName("circle")[0];
      let elementsArray = current.children[0][current.children[0].selectedIndex]
      let denominationList = self.state.cardTypeList
      let filteredDenominationList = _.filter(denominationList, (list) => {
        return list.subDenom.length > 0
      })
      filteredDenominationList[0].subDenom.map((element) => {
        if (element.id === +elementsArray.value) {
          firstSelectedIndex = elementsArray.value
        }
        return 0
      })
    } catch (e) { }
    if (this.state.denominationId == 0) {
      let denominationParams = {
        typeId: firstSelectedIndex === undefined ? this.state.giftCardTypeId : firstSelectedIndex,
        // price: this.state.denominationValue,
        price: 5,
        loggedInUser: sessionStorage.userID,
        language: 'en',
        imageType: this.state.imageType,
        userId: sessionStorage.userID,
        image: this.state.imagebase64
      }
      if (denominationParams.typeId != '') {
        this.setState({ checkPointUpdateState: true, Loading: true })
        await this.props.denominationAddAction(denominationParams)   //API Call
      }
      else {
        this.setState({ flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE })
      }
    }
    else if (this.state.denominationId != 0) {
      let denominationParams = {
        typeId: this.state.giftCardTypeId,
        // price: this.state.denominationValue,
        price: 5,
        loggedInUser: sessionStorage.userID,
        language: 'en',
        imageType: this.state.imageType,
        userId: sessionStorage.userID,
        image: this.state.imagebase64,
        id: this.state.denominationId
      }
      this.setState({ checkPointUpdateState: true, Loading: true })
      await this.props.denominationUpdateAction(denominationParams); //API Call
      await this.props.resetAddEditDenomintion()
    }
    else if (this.state.denominationValue < 1) {
      this.setState({ flashMessageError: 'Invalid denomination' })
    }
  }

  /**
  * Method to delete the image
  * @param object event
  * @return object userParams
  * 
  **/
  removeImageHandler = (event) => {
    if (event === undefined) {
      this.setState({ clearImage: false })
    } else {
      event.preventDefault();
      this.setState({
        clearImage: true,
        denominationImageName: '',
        mimetype: '',
        idCardBase64: '',
        imagebase64:"",
        imageType: 'None'

      })
    }
  }

  /**
   * Method for handle filesize exceed message error 
   */
  showErrormessage() {
    let self = this
    self.setState({
      fileSizeErrorMessage: "File Size exceeds 2 MB"
    })
    setTimeout(function () {
      self.setState({
        fileSizeErrorMessage: ""
      })
    }, 5000)

  }

  /***
   * Method for converting image in Base64 
   * @param{ file to process }
   * @param{callback function }
   *  
   * */
  getBase64(file, cb) {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result)
      };
      reader.onerror = function (error) {
      };
    } catch (e) { }
  }

  /***
   * Method for upload Image
   * @param{file }
   * 
   */
  handleUploadImage(files) {

    let self = this
    var idCardBase64
    var file = files[0];
    var FileSize = file !== undefined ? file.size / 1024 / 1024 : 0;
    var mimetype

    if (FileSize <= 2) {
      self.getBase64(file, (result) => {
        var base64 = result.split(",");
        idCardBase64 = base64[1]
        mimetype = file.type.split("/")
        self.setState({ imageType: mimetype[1], imagebase64: idCardBase64, denominationImageName: '', denominationImagePath: '' });
      });
    }
    else {
      alert("File Size exceeds 2 MB")
    }
  }

  /**
   * React built-in function 
   * invoked just before compnent about to mount 
   */
  componentWillMount() {
    this.props.getCardTypeAction(); // API call 
  }


  /**
  * React built in method 
  * invoked after any upadtion in dom
  * 
  **/
  componentDidUpdate() {
    this.childGiftcard();
    try {
      let domEle = document.getElementsByTagName("select")
      if (domEle[1].selectedIndex >= 1) {
        document.getElementsByClassName(" happy-group")[0].className.replace(" happy-group", "");
        document.getElementsByClassName(" happy-group")[0].className = " circle"

      } else {
        document.getElementsByClassName(" circle")[0].className.replace(" circle", "");
        document.getElementsByClassName(" circle")[0].className = " happy-group "
      }
    } catch (e) { }
  }

  // Method to select the gidt card name 
  manageGiftCardButton(event) {

    let self = this
    let listOption = this.state.cardTypeList
    let idDefault = ''
    _.filter(listOption, list => {
      if (list.subDenom.length > 0) {
        idDefault = list.subDenom[1].id
        self.setState({ holidayOption: false })
      }
    })
    if (typeof event === 'object') {
      let val = event.target.value
      this.setState({ flashMessageError: '', giftCardTypeId: val !== undefined ? val : idDefault, giftcardDropDownInputName: 'cardType', giftcardRadioInputName: '' });
    } else {
      this.setState({ flashMessageError: '', giftCardTypeId: event, giftcardDropDownInputName: '', giftcardRadioInputName: 'cardType' })
    }
  }

  /**
   * Method for handle Denomination type 
   * @param {event}
   * @return {undefined}
   */
  handelDenomination(event) {
    try {
      if (event.target.className === " happy-group" || event.target.className === " circle") {
        let domele = document.getElementsByTagName("select")[1]
        if (domele.options.selectedIndex === 1 || domele.options.selectedIndex === 0) {
          this.manageGiftCardButton(event)
          this.setState({ hideRedPoint: true })
          let ele = document.getElementsByName("cardType");
          for (let i = 0; i < ele.length; i++)
            ele[i].name = "";
          this.setState({
            denominationOption: true,
          })
        }
      } else {
        event.preventDefault();
        if (event.target.options.selectedIndex !== 0) {
          this.setState({ hideRedPoint: true })
          this.manageGiftCardButton(event)
          var ele = document.getElementsByName("cardType");
          for (let i = 0; i < ele.length; i++)
            ele[i].name = "";
          this.setState({
            denominationOption: true,
          })
        }
      }
    } catch (e) { }
  }

  /**
   * Function for set subdomain state
   * @param {*} event 
   * @param {*} flag 
  */
  setSubDenominationList() {
    let _self = this;
    let cardTypeArray = this.state.cardTypeList;
    let storage = false;
    cardTypeArray.map((values) => {
      if (values.subDenom.length > 0 && storage === false) {
        values.subDenom.map(function (data) {
          (_self.state.giftCardTypeId === data.id) ? storage = true : void 0;
          return 0
        })
      }
      return 0
    })
    if (storage) {
      this.setState({ denominationOption: true })
    } else {
      this.setState({ denominationOption: false })
    }
  }

  /**
   * Method for handling dropdown  denominaion type 
   * @param {event } 
   * @param {Boolean}
   * @return {undefined}
   */
  handleDropdownList(event, flag) {
    if (event) {
      this.setState({
        holidayOption: flag
      })
    } else {
      this.setState({
        holidayOption: false
      })
    }
  }

  /**
  * Function for create gift card type radio button and dropdown
  * @param null
  * @return {undefined}
  */
  childGiftcard() {
    let hideOption = {
      description: "Holidays",
      id: undefined,
      subDenom: [],
      name: "Holidays"
    }
    let giftCardStorage = [];
    let cardTypeArray = this.state.cardTypeList;
    const { fontSize } = this.state
    let denomination = this.state.denominationId
    cardTypeArray.map((result, i) => {
      giftCardStorage.push(
        (result.subDenom.length > 0) ? <div ><div className={` ${this.state.denominationOption === true && this.state.denominationId !== 0 ? "circle" : "happy-group"}`}
          onClick={(e) => { this.handelDenomination(e) }}>
          <select
            name={this.state.giftcardDropDownInputName}
            className="select-dropdown custome_drop hover-add"
            onChange={(e) => this.manageGiftCardButton(e)}
            onMouseEnter={this.handleDropdownList.bind(this)}
            // onMouseOut={(this.state.denominationOption !== true) ? this.handleDropdownList.bind(this, true) : ''}
          >
            {
              result.subDenom[0].id !== undefined ? result.subDenom.unshift(hideOption) : void 0
            }
            {
              this.state.holidayOption && denomination === 0 ? <option
                style={{ fontSize: fontSize.tabs + 'px' }}>{result.name}</option> :
                result.subDenom.map((data, i) => {
                  return <option
                    style={{ fontSize: fontSize.tabs + 'px', display: data.id === undefined ? "none" : "block" }}
                    value={data.id}
                    selected={
                      (this.state.giftCardTypeId === data.id) ? data.name : ''}
                  >{data.name}</option>
                })
            }
          </select> </div> </div> :
          <div key={result.name} className="col-md-3 denominationRadio">
            <label className={`card-radio payment_radio_btn checkbox-inline ${this.state.hideRedPoint === true ? 'hidePoint' : ""}`}>{result.name}
              <input type="radio" name={this.state.giftcardRadioInputName} value={this.state.denominationId === 0 ? result.id : this.state.giftCardTypeId} onClick={(e) => { this.setCardType(result.id); this.manageGiftCardButton(result.id) }} checked={this.state.giftCardTypeId == result.id} /><em className="float-left btn-space" ></em></label>
          </div>
      )

    });
    return giftCardStorage;
  }

  /**
   * React life cycle Method 
   * keep waiting for any props change
   * @param {*} nextProps 
   */

  componentWillReceiveProps(nextProps) {
    try {
      let self = this
      if (nextProps.cardTypeListData && nextProps.cardTypeListData.data) {
        this.setState({
          cardTypeList: nextProps.cardTypeListData.data.data.data
        })
        this.setSubDenominationList();
      }
      if (nextProps && nextProps.updateDenominationData.data && nextProps.updateDenominationData.data.error === false) {
        if (self.state.checkPointUpdateState) {
          if (nextProps.updateDenominationData.data.data.hasOwnProperty('status') && nextProps.updateDenominationData.data.data.status === true) {
            this.setState({ flashMessageSuccess: nextProps.updateDenominationData.data.data.message })
            setTimeout(async function () {
              self.setState({
                checkPointUpdateState: false,
                flashMessageSuccess: "",
                Loading: false,
                imagebase64: ''
              })
              self.props.modalClose();
              await nextProps.denominationListAction()
              nextProps.locationProps.history.push(
                "/ng-admin/denomination")
            }, 5000);
          } else {
            this.setState({ flashMessageError: nextProps.updateDenominationData.data.data.message })
          }
        }
      } else {
        this.setState({ checkPointUpdateState: false })
      }
      if (nextProps && nextProps.deleteDenominationImageData.data) {
        if (nextProps.deleteDenominationImageData.data.data.hasOwnProperty('status') && nextProps.deleteDenominationImageData.data.data.status === true) {
          this.setState({ flashMessageSuccess: nextProps.deleteDenominationImageData.data.data.message })
          setTimeout(function () {
            window.location.reload();
            this.props.modalClose();
          }, 5000);
        } else {
          this.setState({ flashMessageError: nextProps.deleteDenominationImageData.data.data.message })
        }
      }
      if (nextProps.addDenominationData && nextProps.addDenominationData.data
        && this.state.checkPointUpdateState && nextProps.addDenominationData.data.error === false) {
        if (nextProps.addDenominationData.data.data.hasOwnProperty('status') && (nextProps.addDenominationData.data.data.status === true)) {
          this.setState({ flashMessageSuccess: nextProps.addDenominationData.data.data.message })
          setTimeout(async function () {
            self.setState({
              checkPointUpdateState: false,
              flashMessageSuccess: "",
              Loading: false,
              imagebase64: '',
            })
            self.props.modalClose();
            await nextProps.denominationListAction() // API call for denomination list
            nextProps.locationProps.history.push("/ng-admin/denomination")
          }, 5000);
        } else {
          this.setState({ flashMessageError: nextProps.addDenominationData.data.data.message, Loading: false, })
        }
      } else {
        this.setState({ checkPointUpdateState: false })
      }
      if (nextProps.denominationDetails) {
        this.setState({
          giftCardTypeId: nextProps.denominationDetails.typeId,
          denominationValue: nextProps.denominationDetails.price,
          denominationId: nextProps.denominationDetails.variantId,
          denominationImageName: nextProps.denominationDetails.imageName,
          denominationImagePath: nextProps.denominationDetails.imagePath
        });
      } else {
        this.setState({
          giftCardTypeId: '',
          denominationValue: '',
          denominationId: 0,
          denominationImageName: '',
          denominationImagePath: ''
        })
      }
    } catch (e) { }
  }

  /***
   * Method for setting card types
   * @param{event}
   */
  setCardType(value) {
    try {
      let element = document.getElementsByClassName("select-dropdown custome_drop hover-add")[0]
      element.selectedIndex = 0
      this.setState({
        giftCardTypeId: value,
        denominationOption: false,
        holidayOption: true,
        hideRedPoint: false
      })
    } catch (e) { }
    try {
      let element = document.getElementsByClassName("circle")[0];
      element.className = element.className.replace(" circle", " happy-group")
    } catch (error) { }
  }

  /** Method for close model */
  onCloseModal = () => {
    this.setState({
      flashMessageSuccess: "",
      flashMessageError: '',
      checkPointUpdateState: false,
      holidayOption: true,
      hideRedPoint: false,
      denominationOption: false
    })
    try {
      let current = document.getElementsByClassName(" circle");
      current[0].className = current[0].className.replace(" circle", " happy-group");

    } catch (err) { }
    this.props.modalClose();
  }


  render() {
    try {
      if (this.props.checkModal && this.state
        .hideRedPoint === true) {
        let current = document.getElementsByClassName(' happy-group');
        current[0].className = current[0].className.replace(" happy-group", " circle")

      }
    } catch (err) { }
    if (this.props.checkModal) {
      this.childGiftcard()
    }
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
    }
    var cardTypeArray = []
    cardTypeArray = this.state.cardTypeList
    return (

      <Modal open={this.props.checkModal} onClose={this.onCloseModal} center classNames={{ overlay: 'addUseroverlay', modal: 'AddUserModelform denominationForm' }}
      closeOnOverlayClick={false}
      >
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header giftcart_title_header">
              <h3 className="modal-title giftcart_all_header"> {(this.state.denominationId === 0) ? 'Add Gift card design' : 'Edit Gift card design'}</h3>
            </div>
            <div className="modal-body">
              <form>
                <div container className="form-group">
                  {flashSuceessMessageSpan}
                  {flashErrorMessageSpan}
                  {this.state.fileSizeErrorMessage !== "" ?
                    <Alert className='alertFont' color="danger" >{this.state.fileSizeErrorMessage}</Alert> : void 0
                  }
                  <div className="row">
                    {this.childGiftcard()}
                  </div>
                </div>
                <div container className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group position-relative">
                        <label className="pull-left ">Upload Picture</label>
                        <div className="dragImg-after">
                          {
                            <img src={this.state.denominationImageName ? constant.APIURL + this.state.denominationImagePath + this.state.denominationImageName : nouploadimage} height="30" width="30" />
                          }
                        </div>
                        <div className="dragImg">
                          <i className="drag-img-area">
                            <ImageUpload
                              uploadImages={this.handleUploadImage.bind(this)}
                              name='image'
                              clearImageHandler={this.removeImageHandler}
                              clearImage={this.state.clearImage}
                              clear
                              erorMessage={this.showErrormessage}
                            />
                          </i>
                          <div className="btn upload-btn-wrapper btn-sm">
                            <i className="click-to-upload"><button className="giftcard_button cardnone_button Denomination-btn">Upload</button>
                              <ImageUpload
                                type="file"
                                uploadImages={this.handleUploadImage.bind(this)}
                                name='image'
                                clearImageHandler={this.removeImageHandler}
                                clearImage={this.state.clearImage}
                                clear
                                erorMessage={this.showErrormessage}
                              />
                            </i>
                          </div>
                        </div>
                        <div className="dragImg">
                          <button type="button" className="btn btn-default btn-non-fill remove-btn" onClick={this.removeImageHandler.bind(this)}>X</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="border-btn">
                        <div className="formbtn float-right" align-right>
                          <button type="button" className='giftcard_button mgr10' onClick={this.denominationSubmitHandler.bind(this)}>Save</button>
                          <button type="button" className="giftcard_button cardnone_button" onClick={this.onCloseModal.bind(this)}>Back</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    cardTypeListData: state.cardTypeList,
    addDenominationData: state.addDenomination,
    updateDenominationData: state.updateDenomination,
    deleteDenominationImageData: state.deleteDenominationImage
  }
}

export default connect(mapStateToProps, { getCardTypeAction, denominationAddAction, denominationUpdateAction, denominationImageDeleteAction,resetAddEditDenomintion })(AddEditDenomination);
