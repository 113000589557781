import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import * as constant from '../../../Constants';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pagination from "react-js-pagination";
import { Alert } from 'reactstrap';
import moment from "moment";
import { giftCardListAction } from '../../../Actions/Admin/giftCardMangementAction';
import ReactLoading from 'react-loading'
import * as HelperUtil from "../../../utils/common-helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datetime from 'node-datetime';
import Helmet from 'react-helmet';


/**Class for displaying gift card details  */
class GiftCardManagements extends Component {

  constructor() {
    super();
    this.state = {
      pageactive: 1,
      filteredDataList: [],
      giftCardList: [],
      listToFilter: [],
      filterBy: '',
      startDate: new Date(),
      endDate: new Date(),
      sDateToCompare: '',
      eDateToCompare: '',
      flashMessageSuccess: '',
      flashMessageError: '',
      Loading: false,
      activeClassName: '',
      filterData: '',
      sortOrder: '',
      fliterStartDate: 'From',
      fliterEndDate: 'To',
      sortField: '',
    }
  };

  /** Method for change active page in pagination*/
  changeactive(page) {
    this.setState({
      pageactive: page
    })
  }

  /** Method for change number in pagination*/
  handlePageChange = (pageNumber) => {
    this.setState({ pageactive: pageNumber })
  }

  /** Method for filter list by given user key*/
  setFilterBy(e) {
    var val = e.target.value
    this.setState({
      filterBy: val,
      filterData: '',
      filteredDataList: this.state.listToFilter,
      startDate: moment(new Date()).format('MM/DD/YYYY'),
      endDate: moment(new Date()).format('MM/DD/YYYY'),
      fliterStartDate: 'From',
      fliterEndDate: 'To',
    })
  }

  /** Method for filter result*/
  filterSearch(event) {
    let self = this
    var newDataList = []
    newDataList = this.state.listToFilter
    var val = event.target.value
    this.setState({
      filterData: val
    })
    var newArr = newDataList.filter(function (e) {
      if (self.state.filterBy == 'Order Number') {
        if (
          ((typeof e.orderNumber != "undefined" && e.orderNumber != null && e.orderNumber !== "") && e.orderNumber.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      } else if (self.state.filterBy == 'Gift Card Number Range') {

        console.log("Data #####",'Gift Card Number Range')
        let data = ''
        if (val != '') {
          if (
            ((typeof e.orderedProduct[0].productRange[0] !== "undefined" && e.orderedProduct[0].productRange[0] !== null && e.orderedProduct[0].productRange.length))
          ) {
            //order map
            e.orderedProduct.length ? e.orderedProduct.map((orderedProduct) => {
              orderedProduct.productRange.length ? orderedProduct.productRange.map((productRange) => {
                let rangeFrom = parseInt(productRange.rangeFrom)
                let rangeTo = parseInt(productRange.rangeTo)
                val = parseInt(val)
                if (productRange.rangeFrom.includes(val) || productRange.rangeTo.includes(val)) {
                  data = productRange
                  return data
                }
              }) : console.log("")
            }) : console.log("")
            if (data != '') {
              return data
            }
          }
        } else {
          return newDataList
        }
      }
      else if (self.state.filterBy == 'Pin') {
        if (
          ((typeof e.pin != "undefined" && e.pin != null && e.pin !== "") && e.pin.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Expected Delivery Date') {
        if (
          ((typeof e.eta != "undefined" && e.eta != null && e.eta !== "") && new Date(e.eta).toString().includes(val))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Tracking Number') {
        if (
          ((typeof e.orderTracking != "undefined" && e.orderTracking != null && e.orderTracking !== "") && e.orderTracking.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == "Activation") {
        if (
          ((typeof e.activationStatus != "undefined" && e.activationStatus != null && e.activationStatus !== "") && e.activationStatus.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Customer Name') {
        if (
          ((typeof e.customerName != "undefined" && e.customerName != null && e.customerName !== "") && e.customerName.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else {
        return e
      }
    })
    this.setState({
      filteredDataList: newArr,
      pageactive: 1
    })
  }

  /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
  componentWillMount() {
    try {
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.componentID.history.push("/ng-admin");
      }
    } catch (err) { }
    this.setState({ activeClassName: this.props.pathParam })
    if (this.props.totalGiftCard) {
      this.setState({
        giftCardList: this.props.totalGiftCard,
        listToFilter: this.props.totalGiftCard,
        filteredDataList: this.props.totalGiftCard,
      })
    } else {
      this.setState({ Loading: true })
      var data = { "loggedInUser": sessionStorage.userID, "language": "en", "trackingUrl": true }
      this.props.giftCardListAction(data);
    }
  }

  /**
* React Built method 
* keep waiting for change in props 
* @param {*} nextProps 
*/
  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.giftCardList && nextProps.giftCardList.data && nextProps.giftCardList.data.error === false) {
        let {data} = nextProps.giftCardList.data.data
        data = data.filter((val)=> val.cancelStatus !== true)
        this.setState({
          giftCardList: data,
          listToFilter: data,
          filteredDataList: data,
          Loading: false,
        })
        document.getElementById('user-dashboard').style.height = window.innerHeight - 125 + 'px';
        let reso_height = document.getElementById('user-dashboard').style.height
        document.getElementsByClassName('res_height')[0].style.height = reso_height.slice(0, -2) - 240 + 'px';
      } else {
        this.setState({ Loading: false })
      }
    } catch (e) { }
  }

  /** Method for manipulate date*/
  handleChangeStart(date) {
    this.setState({
      startDate: date,
      filterBy: '',
      filterData: '',
    })
  }

  /** Method for manipulate date*/
  handleChangeEnd(date) {
    this.setState({
      endDate: date,
      filterBy: '',
      filterData: '',
    })
  }

  /** Method for date manipulation*/
  handleChangeStartDate(date) {
    try {
      let date_ = date.target !== undefined ? date.target.value :false
      
      let data = /^[0-9-/]*$/
      if( !data.test(date_) && date_!==false) { 
        
        date.target.value = ''
            
      } else {
        var pastDateTime = datetime.create(date);
        var modifedate = moment(pastDateTime.now()).format('YYYY-MM-DD HH:mm');
        let endDate = this.state.endDate != '' ? moment(this.state.endDate).format('YYYY-MM-DD HH:mm') : ''
        let {filteredDataList, filterBy, filterData, giftCardList} = this.state
          if(filterBy !== '' && filterData !== '' && giftCardList.length > 0){
            switch (filterBy) {
              case "Order Number":
                filteredDataList = giftCardList.filter((data)=>  data.orderNumber.includes(filterData))
                break;
              case "Customer Name":
                filteredDataList = giftCardList.filter((data)=> data.customerName.toLowerCase().includes(filterData.toLowerCase()))
                break;
              case "Pin":
                filteredDataList = giftCardList.filter((data)=>  data.pin.includes(filterData))
                break;
              case "Tracking Number ":
                filteredDataList = giftCardList.filter((data)=>  data.orderTracking.includes(filterData))
                break;
              case "Gift Card Number Range":
                filteredDataList = giftCardList.filter((data)=>{
                        let  val = ''
                        data.orderedProduct.length ? data.orderedProduct.map((orderedProduct) => {
                          orderedProduct.productRange.length ? orderedProduct.productRange.map((productRange) => {
                            filterData = parseInt(filterData)
                            if (productRange.rangeFrom.includes(filterData) || productRange.rangeTo.includes(filterData)) {
                              val = productRange
                            }
                          }) : console.log("")
                        }) : console.log("")
                    return val
                  })
                break;
              case "Expected Delivery Date":
                filteredDataList = giftCardList.filter((data)=>  new Date(data.eta).toString().includes(filterData))
                break;
              default:
                  filteredDataList = giftCardList
            }
          }
        const result = HelperUtil._getFilteredOrderListByDate(moment(date).format('YYYY-MM-DD HH:mm'), filteredDataList, endDate)
        this.setState({
          startDate: date,
          sDateToCompare: modifedate,
          filteredDataList: result,
          fliterStartDate: moment(date).format('MM/DD/YYYY'),
          // filterBy: '',
          // filterData: '',
          // filteredDataList: this.state.listToFilter,
        });
      }
    } catch(e) {console.log("error in handleChangeStartDate",e)}
  }

  /** Method for date manipulation*/
  handleChangeEndDate(date) {
    try {
      let date_ = date.target !== undefined ? date.target.value :false
      
      let data = /^[0-9-/]*$/
      if( !data.test(date_) && date_!==false) { 
        
        date.target.value = ''
            
      } else {
        var pastDateTime = datetime.create(date);
        var modifedate = moment(pastDateTime.now());
        let {filteredDataList, filterBy, filterData, giftCardList} = this.state
          if(filterBy !== '' && filterData !== '' && giftCardList.length > 0){
            switch (filterBy) {
              case "Order Number":
                filteredDataList = giftCardList.filter((data)=>  data.orderNumber.includes(filterData))
                break;
              case "Customer Name":
                filteredDataList = giftCardList.filter((data)=> data.customerName.toLowerCase().includes(filterData.toLowerCase()))
                break;
              case "Pin":
                filteredDataList = giftCardList.filter((data)=>  data.pin.includes(filterData))
                break;
              case "Tracking Number ":
                filteredDataList = giftCardList.filter((data)=>  data.orderTracking.includes(filterData))
                break;
              case "Gift Card Number Range":
                filteredDataList = giftCardList.filter((data)=>{
                        let  val = ''
                        data.orderedProduct.length ? data.orderedProduct.map((orderedProduct) => {
                          orderedProduct.productRange.length ? orderedProduct.productRange.map((productRange) => {
                            filterData = parseInt(filterData)
                            if (productRange.rangeFrom.includes(filterData) || productRange.rangeTo.includes(filterData)) {
                              val = productRange
                            }
                          }) : console.log("")
                        }) : console.log("")
                    return val
                  })
                break;
              case "Expected Delivery Date":
                filteredDataList = giftCardList.filter((data)=>   new Date(data.eta).toString().includes(filterData))
                break;
              default:
                  filteredDataList = giftCardList
            }
          }
        const result = HelperUtil._getFilteredOrderListByDate(moment(this.state.startDate).format('YYYY-MM-DD HH:mm'), filteredDataList, moment(date).format('YYYY-MM-DD HH:mm'))
        this.setState({
          endDate: date,
          eDateToCompare: modifedate,
          filteredDataList: result,
          fliterEndDate: moment(date).format('MM/DD/YYYY'),
          // filterBy: '',
          // filterData: '',
        });
      }
    } catch(e) {console.log("error in ",e)}
  }

  /** Method to reset the date*/
  resetList(e) {
    var val = e.target.value
    this.setState({
      filterBy: '',
      filterData: '',
      startDate: moment(new Date()).format('MM/DD/YYYY'),
      endDate: moment(new Date()).format('MM/DD/YYYY'),
      fliterStartDate: 'From',
      fliterEndDate: 'To',
      filteredDataList: this.state.listToFilter,
    })
  }

  /** Method for getting order detail*/
  getOrderInfo(data) {
    let self = this
    self.props.componentID.history.push({
      pathname: "/ng-admin/giftcardmanagement",
      state: { displayOrderInfo: data }
    })
  }

  /** Method for sorting data onclick*/
  sortBy(dataList, Name) {
    this.setState({
      sortField: Name
    })

    let response = constant.SORT_BY(dataList, Name, this.state.sortOrder)
    this.setState({
      filteredDataList: response.data,
      sortOrder: response.sortOrder
    })
  }

  render() {
    let { filteredDataList } = this.state
    let { pageactive } = this.state
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
    }
    var list = filteredDataList != undefined ? filteredDataList.slice((pageactive - 1) * 10, (pageactive) * 10) : ''

    return (
      <div className="admin_top">
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }
        <Helmet>
            <title>Gift Card Management</title>
        </Helmet>
        <div className="content-wrapper" className="d-flex flex-column">
          <Header />
          <div className="main">
            <Navigation activeClassName={this.state.activeClassName} />
            <div className="dashboardContent">
              <div className="container-fluid">
                <div className="admin-header">
                  <div className="user-dashboard user-management clearfix" id="user-dashboard">
                    <div className="row mar0">
                      <div className="col-md-12">
                        {flashSuceessMessageSpan}
                        {flashErrorMessageSpan}
                        <h2>Gift Card Management</h2>
                        <div className="row">
                          <div className="pull-left col-md-10 shipping-filter">
                            <div className="row">
                              <div className="form-group filter-input">
                                <div className="styled-select slate">
                                  <select className="select-dropdown" name="test" onChange={(e) => this.setFilterBy(e)} value={this.state.filterBy}>
                                    <option>Select</option>
                                    <option value="Order Number">Order Number</option>
                                    <option value="Customer Name">Customer Name</option>
                                    <option value="Gift Card Number Range">Gift Card Number Range</option>
                                    <option value="Pin">Pin</option>
                                    <option value="Expected Delivery Date">Expected Shipping Date </option>
                                    <option value="Tracking Number">Tracking Number</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group filter-input">
                                <div className="inner-addon search-input">
                                  <input type="text" className="form-control input-style search-icon" placeholder="Search" value={this.state.filterData} onChange={this.filterSearch.bind(this)} />
                                </div>
                              </div>
                              <div className="datePicker">
                                <div className="form-group filter-calendar datePickerReact">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <DatePicker 
                                        className='form-control calendar-icon' 
                                        placeholderText={this.state.fliterStartDate} 
                                        maxDate={new Date()} disabled={this.state.filter} 
                                        inputProps={{ readOnly: true }} 
                                        onChange={this.handleChangeStartDate.bind(this)} 
                                        onChangeRaw = {this.handleChangeStartDate.bind(this)} 
                                      />
                                    </div>
                                    <div className="col-sm-6 secondDatePikker">
                                      <DatePicker 
                                        className='form-control calendar-icon' 
                                        placeholderText={this.state.fliterEndDate} 
                                        maxDate={new Date()} 
                                        disabled={this.state.filter} 
                                        inputProps={{ readOnly: true }} 
                                        onChange={this.handleChangeEndDate.bind(this)} 
                                        onChangeRaw={this.handleChangeEndDate.bind(this)} 
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="refresh-btn">
                                <i onClick={this.resetList.bind(this)} title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive ">
                          <table id="example" className="table table-striped table-bordered" cellSpacing="0" width="100%">
                            <thead>
                              <tr>
                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'orderNumber')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'orderNumber' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Order Number</th>
                                <th className="all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'orderDate')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'orderDate' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Order Date</th>
                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'customerName')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'customerName' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Customer Name</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'rangeArray[0].from')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'rangeArray[0].from' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Gift Card Number Range</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'pin')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'pin' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Pin</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'eta')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'eta' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Expected Shipping Date</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'orderTracking')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'orderTracking' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Tracking Number</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'currentStatus')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'currentStatus' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Activation</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                list.length > 0 ? list.map((key, index) => {
                                  var orderedProductRange = []
                                  orderedProductRange = key.orderedProduct
                                  var rangeArray = []
                                  var json = { "from": '', "to": '' }
                                  orderedProductRange.map((products) => {
                                    products.productRange.map((p) => {
                                      json = { "from": p.rangeFrom, "to": p.rangeTo }
                                      rangeArray.push(json)
                                    })
                                  })
                                  return <tr key={index}>
                                    <td><span onClick={() => this.getOrderInfo(key)}> {key.orderNumber}</span></td>
                                    <td>{moment( new Date(key.orderDate)).format('MM/DD/YYYY')}</td>
                                    <td>{key.customerName}</td>
                                    <td>
                                      {rangeArray.length > 0 ? rangeArray.map(function (result, index) {
                                        return (
                                          <span>{result.from + '-' + result.to}<br /></span>
                                        )
                                      }) : ''
                                      }
                                    </td>
                                    <td>{key.pin}</td>
                                    <td>{moment( new Date(key.eta)).format('MM/DD/YYYY')}</td>
                                    <td>{key.orderTracking}</td>
                                    <td>{key.activationStatus == false ? 'Pending' : 'Activated'}</td>
                                    <td>{key.activationStatus == false ? <span id="blue" onClick={() => this.getOrderInfo(key)}> Activate</span> : '--'}</td>
                                  </tr>
                                }) : <tr><td className="recordNotFound" colSpan="9">No record found</td></tr>
                              }
                            </tbody>
                          </table>

                        </div>
                        <ul className="pagination pull-right my-1">
                          <Pagination
                            hideDisabled
                            activePage={pageactive}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.filteredDataList.length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                            firstPageText='First'
                            lastPageText='Last'
                            prevPageText="Prev"
                            nextPageText="Next"
                          />
                        </ul>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    orderList: state.orderList,
    giftCardList: state.giftCardList
  };
}

export default connect(mapStateToProps, { giftCardListAction })(GiftCardManagements);