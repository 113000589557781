import React, { Component } from 'react';
import { Route, BrowserRouter } from "react-router-dom";
import HomeCustomer from './../Components/Customer/Home';
import Login from './../Components/Admin/Login';
import Home from '../Components/Admin/Home';
import Addmanagement from './../Components/Admin/UserManagement/addManagement'
import UserManagements from './../Components/Admin/UserManagement';
import Navigation from '../Components/Admin/Layout/Navigation';
import { Provider } from 'react-redux';
import { store } from './../Store';
import '../public/fonts/font-awesome/css/font-awesome.min.css';
import ShipManagements from './../Components/Admin/ShipManagement';
import CustomerManagements from './../Components/Admin/Customermanagement';
import Dashboard from '../Components/Customer/Home/dashboard';
import DiscountManagements from '../Components/Admin/DiscountManagement';
import DenominationManagements from '../Components/Admin/DenominationManagement';
import OrderManagement from '../Components/Customer/OrderManagement';
import OrderDetailsManagement from '../Components/Customer/OrderManagement/orderDetailsManagements';
import AdminOrderManagement from './../Components/Admin/OrderManagement';
import GiftCardManagement from './../Components/Admin/GiftCardManagement';
import RoleError from './../Components/Admin/RoleError';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../public/css/style.css';
import * as constant from '../Constants';
import * as language from '../Constants/translations';
import HomeSettings from './../Components/Admin/HomeSettings';
import FAQ from './../Components/Admin/FAQ';
import FAQCustomer from '../Components/Customer/FAQ/index';
import PrivacyPolicy from '../Components/Customer/PrivacyPolicy/index'
import PolicyManagement from '../Components/Admin/PolicyManagement/index'
import LandingPageManagement from '../Components/Admin/LandingPageManagement/index'

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: sessionStorage.language != undefined ? sessionStorage.language === 'spa' ?  'spa':   'en'  :'en'
    };
    this.check();
    this.initListener();
    this.initInterval();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    
  }


  /**
   * Function for logout session user
   */
  sessionLogout() {
    localStorage.removeItem("loggedIn");
    sessionStorage.removeItem("loggedIn");
    sessionStorage.language = 'en'
    sessionStorage.removeItem("length");
    if(window.location.href.includes('/ng-admin')) {
       window.location.href='/ng-admin'
    } else {
      window.location.href='/'
    }
  }


  /**
  * Function for modal confirm box
  */
  statusConfirm() {
    sessionStorage.userName = "";
    sessionStorage.loggedIn = "";
    sessionStorage.isSuperAdmin = "";
    sessionStorage.roleName = "";
    sessionStorage.roleID = "";
    sessionStorage.cardType = "";
    sessionStorage.customerName = "";
    sessionStorage.userID = "";
    sessionStorage.userType = "";
    sessionStorage.removeItem("length");
    sessionStorage.removeItem("emailID");
    sessionStorage.removeItem('adminLoggedIn')
    localStorage.removeItem("productCardDetailsData")
    localStorage.removeItem("productCardDetailsLength")
    localStorage.removeItem('denominationPriceData');
    localStorage.removeItem("isVerityPage")
    localStorage.removeItem('UserOtherAddress')
    localStorage.removeItem('guestUserDetails')
    localStorage.removeItem('isVerifyAddress')

    sessionStorage.clear()
    document.addEventListener('click', this.handleOutsideClick, false);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-popup AddUserModelform" ref={node => { this.node = node; }}>
            <div className="modal-header from-header"><h3>{language.language.Session_message[0][this.state.lang]}</h3></div>
            <div className="modal-body">
              <p>{language.language.SESSION_EXPIRE[0][this.state.lang]}</p>
              <div className="formbtn">
                <button type="button" className="giftcard_button"
                  onClick={() => { this.sessionLogout(this); onClose(); }} >Ok
               </button>
              </div>
            </div>
          </div>
        );
      }
    })
  }

  /**
  * Function for handling click outside confirm box
  */
  handleOutsideClick(e) {
    try{
      this.sessionLogout(this);
    }catch(e){
      console.log('error in session----',e)
    }

  }

  getLastAction() {
    return parseInt(localStorage.getItem(constant.STORE_KEY));
  }

  setLastAction(lastAction) {
    localStorage.setItem(constant.STORE_KEY, lastAction.toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, constant.CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + constant.MINUTES_UNITL_AUTO_LOGOUT * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout) {
      if (sessionStorage.loggedIn && window.location.pathname !== "/" &&  window.location.pathname !== "/ng-admin") {
        this.statusConfirm();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentWillMount() {
    let uri = window.location.href.split('/');
    if(localStorage.getItem('isVerifyAddress')!==null ) {
      if(localStorage.getItem('isVerifyAddress')=== "false") {
        if(uri.includes('ordermanagement')) {
          window.location.href = '/dashboard'
        }
      }
    }
  }



  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <Route exact='/' path='/dashboard/' component={Dashboard} />
            <Route exact path='/' component={HomeCustomer} />
            <Route exact='/' path='/ordermanagement' component={OrderManagement} />
            <Route exact='/' path='/orderdetailsmanagements' component={OrderDetailsManagement} />
            <Route exact='/ng-admin/' path='/ng-admin/Navigation' component={Navigation} />
            <Route exact='/ng-admin/' path='/ng-admin/' component={Login} />
            <Route exact='/ng-admin/' path='/ng-admin/home' component={Home} />
            <Route exact='/ng-admin/' path='/ng-admin/addmanagement' component={Addmanagement} />
            <Route exact='/ng-admin/' path='/ng-admin/usermanagement' component={UserManagements} />
            <Route exact='/ng-admin/' path='/ng-admin/shipmanagement' component={ShipManagements} />
            <Route exact='/ng-admin/' path='/ng-admin/customermanagement' component={CustomerManagements} />
            <Route exact='/ng-admin/' path='/ng-admin/discountmanagement' component={DiscountManagements} />
            <Route exact='/ng-admin/' path='/ng-admin/denomination' component={DenominationManagements} />
            <Route exact='/ng-admin/' path='/ng-admin/ordermanagement' component={AdminOrderManagement} />
            <Route exact='/ng-admin/' path='/ng-admin/giftcardmanagement' component={GiftCardManagement} />
            <Route exact='/ng-admin/' path='/ng-admin/homesettings' component={HomeSettings} />
            <Route exact='/ng-admin/' path='/ng-admin/error' component={RoleError} />
            <Route exact='/ng-admin/' path='/ng-admin/faq' component={FAQ} />
            <Route exact='/' path='/FAQ/' component={FAQCustomer} />
            <Route exact='/' path='/privacy-policy' component={PrivacyPolicy} />
            <Route exact='/ng-admin/' path='/ng-admin/privacy-policy' component={PolicyManagement} />
            <Route exact='/ng-admin/' path='/ng-admin/LandingPageManagement' component={LandingPageManagement} />
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}
export default Routes;
