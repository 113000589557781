import React, { Component } from 'react';
import FAQList from './faqLIstPage';

/** Main class for displaying FAQ list */
class FAQ extends Component {

  componentDidMount() {
    try {
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.history.push("/ng-admin");
      }
    } catch (err) {

    }
  }

  render() {
    let pathParam = this.props.location.pathname.split("/").pop()

    try {
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.history.push("/ng-admin");
      } else if (sessionStorage.hasOwnProperty("adminLoggedIn") && sessionStorage.isSuperAdmin != 'true') {
        if (sessionStorage.roleID != 3) {
          this.props.history.push("/ng-admin/error");
        }
      }
    } catch (err) {

    }

    var FAQPage;
    if (this.props.location.state == undefined) {
      FAQPage = <FAQList componentID={this.props} pathParam={pathParam} />
    }
    return (FAQPage);
  }
}
export default FAQ;
