import React, { Component } from 'react';
import CustomerLogo from '../../../public/img/CustomerLogoNew.png';
import { customerListAction, customerResetPWDAction } from '../../../Actions/Admin/customerManagementAction';
import { denominationListAction, getCardTypeAction } from '../../../Actions/Admin/denominationManagementAction';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { customerRegisterAction, customerLoginAction, sendOTPAction, veriftOTPAction, searchUserAction, updateNumberAction, searchUserCIAMbyEmail, createUpdateUserCIAM } from '../../../Actions/Customer/CustomerLoginAction';

import { discountListAction } from '../../../Actions/Admin/discountManagementAction'
import { connect } from 'react-redux';
import '../../../public/css/customer-style.css';
import _ from 'lodash'
import ResetPasswordModal from 'react-responsive-modal';
import { activateCustomerAction } from '../../../Actions/Customer/customerProfileAction';
import { customerPasswordResetAction } from '../../../Actions/Customer/CustomerPasswordAction'
import base64 from 'base-64'
import InputMask from 'react-input-mask'
import * as language from '../../../Constants/translations';
import OtpModal from 'react-responsive-modal';
import * as constant from '../../../Constants/index';
import { viewHomeSettingAction } from '../../../Actions/Admin/userManagementAction';
import successimg from '../../../public/img/success.png';
import errorimg from '../../../public/img/error.png';
import ReactLoading from 'react-loading';
import LoginModal from './loginModal';
import profile from '../../../public/img/profile.png';
import UpdateNumberModal from 'react-responsive-modal';
import moment from "moment"
import ReactModal from 'react-modal';
import * as HelperUtil from "./../../../utils/common-helper";
import { NORTHGATE_URL } from '../../../Constants';//../../../../Constants

/** Main class for landing page having login , signup , guest user option, etc .*/
const modelStyles = {
  content: {
    background: '#ffffff00',
    transform: 'translate(-50%, -50%)'
  }
};

/**
 * Class for Customer Landing page
 */
class HomeCustomer extends Component {

  constructor(props) {
    super();

    this.state = {
      loginModalopen: false,
      regiModalOpen: false,
      validObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      invalidObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      check: 1,
      customerList: [],
      denominationList: [],

      forgetModalopen: false,
      resetPasswordModal: false,

      setPassword: "",
      confirmSetPassword: "",
      resetEncodedData: "",
      flashMessageError: '',
      flashMessageSuccess: '',
      emailErrorSpan: "",
      passwordErrorSpan: "",
      open: false,
      changeColorState: false,
      regchangeColorState: false,
      nameInputMask: 'aaaaaaaaaaaaaaa',
      lang: sessionStorage.language !== undefined ? sessionStorage.language === 'spa' ? 'spa' : 'en' : 'en',
      openOtpModal: false,
      otp: {
        o1: '',
        o2: '',
        o3: '',
        o4: '',
      },
      userData: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        pimId: ''
      },
      cardTypeList: [],
      policyModalopen: false,
      homeSetting: {
        englishHeader1: ''
        ,
        englishHeader1Color: '',
        englishHeader1FontSize: '',
        englishHeader2: '',
        englishHeader2Color: '',
        englishHeader2FontSize: '',
        spanishHeader1: '',
        spanishHeader1Color: '',
        spanishHeader1FontSize: '',
        spanishHeader2: '',
        spanishHeader2Color: '',
        spanishHeader2FontSize: '',
        imageName: '',
        imagePath: '',
        phoneNumber: ''
      },
      otpErrorSpan: '',
      otpSuccessSpan: '',
      fontSize: {
        header1: 46,
        header2: 36,
        giftCardButton: 21,
        loginSignup: 12,
        language: 10,
        a: 16,
        loginModalLabel: 14,
        loginModelButton: 13,
        headerSp1: 46,
        headerSp2: 36

      },
      increaseCounter: 0,
      decreaseCounter: 0,
      hideMessage: true,
      Loading: false,
      OTPCount: 0,
      isEmptyField: false,

      openUpdateNumberModal: false,
      isInputFieldEmpty: false,
      updateNumberStatus: false,
      customerUpdatedNumber: '',
      customerID: '',
      otpStatus: false,
      discountList: [],
      activeDiscount: [],
      currentDate: moment(new Date()).format('MM/DD/YYYY'),
      isDicountopen: false,
      CIAMData: '',
      isResendOtp: false,
      increasemove: 4,
      decreasemove: 4,
      isOTPVerified: false,
      userActivated: false,
      isActivUser: true,
      defaultFonts: {
        header1: 46,
        header2: 36,
        headerSp1: 46,
        headerSp2: 36
      }

    }
    if (window.performance) {

      if (performance.navigation.type == 1) {
        this.setState({ loginModalopen: false })
      }
    }
  }

  /**
  * Function for forgetPassword New Password input value
  * @param object event
  * @return object State
 */
  setNewPasswordValue = (event) => {
    let value = event.target.value
    if (value === "") {
    } else {
      this.setState({
        flashMessageError: '',
        setPassword: value
      })
    }
  }

  /**
  * Function for forgetPassword Confirm Password input value
  * @param object event
  * @return object State
 */
  setConfirmPasswordValue = (event) => {
    let value = event.target.value
    if (value === "") {
    } else {
      this.setState({
        flashMessageError: '',
        confirmSetPassword: value
      })
    }
  }

  /** Function for call lyfe cycle and handel props response*/
  componentWillReceiveProps(nextProps) {
    try {
      var lang = this.state.lang
      let self = this
      if (nextProps.customerList && nextProps.customerList.data) {
        try {
          if (nextProps.customerList.data.data && nextProps.customerList.data.data.data != null) {

            let user = []
            self.setState({
              customerList: nextProps.customerList.data.data.data,
            })
            user = nextProps.customerList.data.data.data.filter(customer => customer.email === this.state.userData.email)

            if (user[0].dateActivated === null && this.state.isActivUser) {
              var otpJson = {
                "northgateId": "",
                "To": "+1" + this.state.userData.phoneNumber,
                "emailId": "",
                "source": "gift_card"
              }
              this.setState({
                otpStatus: true,
                isActivUser: false
              })
              this.props.sendOTPAction(otpJson)
            }
          }
        } catch (err) {
        }
      }
      if (nextProps.customerLoginInfo && nextProps.customerLoginInfo.data) {
        try {
          if (nextProps.customerLoginInfo.data.status) {
            sessionStorage.loggedIn = true;
            sessionStorage.customerName = nextProps.customerLoginInfo.data.data.firstName + " " + nextProps.customerLoginInfo.data.data.lastName;
            sessionStorage.userID = nextProps.customerLoginInfo.data.data.id;
            sessionStorage.emailID = nextProps.customerLoginInfo.data.data.email
            if (nextProps.customerLoginInfo.data.data.addresses.length > 0) {
              sessionStorage.cardType = 'all'
              sessionStorage.language = this.state.lang
              self.props.history.push("/ordermanagement");
            }
            else
              self.props.history.push("/dashboard");
          } else {
            self.setState({
              passwordErrorSpan: nextProps.customerLoginInfo.data.message,
              Loading: false
            })
          }
        }
        catch (err) {
        }
      }
      if (nextProps.resetCustomerPasswordResponse && nextProps.resetCustomerPasswordResponse.data) {
        try {
          if (nextProps.resetCustomerPasswordResponse.data.hasOwnProperty('status') && (nextProps.resetCustomerPasswordResponse.data.status === true)) {
            self.setState({ flashMessageError: '', flashMessageSuccess: nextProps.resetCustomerPasswordResponse.data.message })
            setInterval(function () {
              let redirect = window.location.href.substring(window.location.href.lastIndexOf('?'), 0)
              window.location = redirect;
            }, 5000);
          } else {
            self.setState({ flashMessageError: nextProps.resetCustomerPasswordResponse.data.message })
          }
        }
        catch (err) {
        }
      }

      if (nextProps && nextProps.sendOtp) {
        try {
          if (nextProps.customerList !== undefined && nextProps.customerList.data !== undefined && nextProps.customerList.data !== null) {
            if (nextProps.customerList.data.data !== undefined && Array.isArray(nextProps.customerList.data.data.data)) {

              let email = this.state.userData.email
              let userFound = []
              this.setState({ Loading: false })
              userFound = nextProps.customerList.data.data.data.filter(list => {
                return list.email === email
              })

              if (userFound.length === 1) {
                if (userFound[0].dateActivated === null) {
                  if (nextProps.sendOtp.data && nextProps.sendOtp.data.data.success === true) {
                    if (self.state.otpStatus === true) {
                      self.setState({
                        openOtpModal: true,
                        otpStatus: false,
                        otpSuccessSpan: nextProps.sendOtp.data.data.msg,
                        otpErrorSpan: '',

                      })
                    }
                  } else {
                    self.setState({
                      openOtpModal: true,
                      otpStatus: false,
                      otpErrorSpan: nextProps.sendOtp.data.data.msg,
                      otpSuccessSpan: ''
                    })
                  }
                } else {

                  if (!this.state.userActivated) {
                    self.setState({
                      openOtpModal: true,
                      otpStatus: false,
                      userActivated: true,
                      otpSuccessSpan: "Account already activated",
                      otpErrorSpan: ''
                    })
                  }

                  setTimeout(() => { this.setState({ loginModalopen: true, openOtpModal: false }) }, 3000)
                }
              }
            }
          }
        } catch (err) { console.log("erriorr", err) }
      }

      if (nextProps && nextProps.verifyOtp) {

        try {
          if (nextProps.verifyOtp && nextProps.verifyOtp.data && nextProps.verifyOtp.data.data.success != false) {
            if (nextProps.verifyOtp.data.data.message == language.language.Account_exists[0][lang]) {
              self.setState({
                loginModalopen: false,
                emailErrorSpan: '',
                openOtpModal: true,
                flashMessageSuccess: "",
                validObj: {
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  companyName: "",
                  phone: "",
                  confirmPassword: "",
                },
                otpErrorSpan: nextProps.verifyOtp.data.data.message,
                flashMessageError: '',
                Loading: false
              });
              setTimeout(function () {
                nextProps.verifyOtp.data = null
              }
                , 3000)
            }
            else if (nextProps.verifyOtp.data.data.message && this.state.hideMessage) {
              self.setState({ flashMessageSuccess: language.language.customer_activation_success[0][lang] })
              setTimeout(() => {
                self.setState({
                  loginModalopen: true,
                  openOtpModal: false,
                  emailErrorSpan: '',
                  flashMessageSuccess: '',
                  validObj: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    companyName: "",
                    phone: "",
                    confirmPassword: "",
                  },
                  flashMessageError: '',
                  otpErrorSpan: '',
                  Loading: false,
                  isOTPVerified: true,
                });
              }, 5000)
            } else {
              self.setState({
                loginModalopen: true,
                openOtpModal: false,
                emailErrorSpan: '',
                flashMessageSuccess: "",
                validObj: {
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  companyName: "",
                  phone: "",
                  confirmPassword: "",
                },
                flashMessageError: '',
                otpErrorSpan: '',
                Loading: false
              })
            }

          } else {
            if (this.state.isResendOtp === false) {
              self.setState(({
                flashMessageError: nextProps.verifyOtp.data.data.msg,
                Loading: false
              }))
            }

          }
        }
        catch (err) {
        }
      }
      if (nextProps.cardTypeListData && nextProps.cardTypeListData.data) {
        try {
          this.setState({
            cardTypeList: nextProps.cardTypeListData.data.data.data
          })
        } catch (err) {

        }
        finally { /** Last block always called in try.. catch..   */
          self.setState({ Loading: false })
          setTimeout(() => { self.setState({ flashMessageError: '' }) }, 3000)

        }
      }

      if (nextProps.viewHomeSettings && nextProps.viewHomeSettings.data) {
        try {
          this.setState({
            homeSetting: {
              englishHeader1: nextProps.viewHomeSettings.data.data.data.header1,
              englishHeader1Color: nextProps.viewHomeSettings.data.data.data.header1Color,
              englishHeader1FontSize: nextProps.viewHomeSettings.data.data.data.header1FontSize,
              englishHeader2: nextProps.viewHomeSettings.data.data.data.header2,
              englishHeader2Color: nextProps.viewHomeSettings.data.data.data.header2Color,
              englishHeader2FontSize: nextProps.viewHomeSettings.data.data.data.header2FontSize,
              spanishHeader1: nextProps.viewHomeSettings.data.data.data.spanishHeader1,
              spanishHeader1Color: nextProps.viewHomeSettings.data.data.data.spanishHeader1Color,
              spanishHeader1FontSize: nextProps.viewHomeSettings.data.data.data.spanishHeader1FontSize,
              spanishHeader2: nextProps.viewHomeSettings.data.data.data.spanishHeader2,
              spanishHeader2Color: nextProps.viewHomeSettings.data.data.data.spanishHeader2Color,
              spanishHeader2FontSize: nextProps.viewHomeSettings.data.data.data.spanishHeader2FontSize,
              imageName: nextProps.viewHomeSettings.data.data.data.imageName,
              imagePath: nextProps.viewHomeSettings.data.data.data.imagePath,
              phoneNumber: nextProps.viewHomeSettings.data.data.data.phoneNumber,
            },
            fontSize: {
              header1: +nextProps.viewHomeSettings.data.data.data.header1FontSize,
              header2: +nextProps.viewHomeSettings.data.data.data.header2FontSize,
              headerSp1: +nextProps.viewHomeSettings.data.data.data.spanishHeader1FontSize,
              headerSp2: +nextProps.viewHomeSettings.data.data.data.spanishHeader2FontSize
            },
            defaultFonts: {
              header1: +nextProps.viewHomeSettings.data.data.data.header1FontSize,
              header2: +nextProps.viewHomeSettings.data.data.data.header2FontSize,
              headerSp1: +nextProps.viewHomeSettings.data.data.data.spanishHeader1FontSize,
              headerSp2: +nextProps.viewHomeSettings.data.data.data.spanishHeader2FontSize
            }


          }, () => { nextProps.viewHomeSettings.data = null })

          //   const fontSize_  = {
          //     ...this.state.fontSize,
          //     header1: +nextProps.viewHomeSettings.data.data.data.header1FontSize,
          //     header2: +nextProps.viewHomeSettings.data.data.data.header2FontSize,
          //     headerSp1:+nextProps.viewHomeSettings.data.data.data.spanishHeader1FontSize,
          //     headerSp2:+nextProps.viewHomeSettings.data.data.data.spanishHeader2FontSize
          //   }
          //   this.setState({
          //     fontSize :fontSize_
          //   })
        } catch (err) {
        }
      }
      if (nextProps.resetCustomerPWDResponse && nextProps.resetCustomerPWDResponse.data) {
        try {
          if (nextProps.resetCustomerPWDResponse.data.hasOwnProperty('status') && (nextProps.resetCustomerPWDResponse.data.status === true)) {
            self.setState({ flashMessageError: '', flashMessageSuccess: language.language.reset_password_link[0][this.state.lang] })
            setInterval(function () {
              let redirect = window.location.href.substring(window.location.href.lastIndexOf('?'), 0)
              window.location = redirect;
            }, 5000);

          } else {
            self.setState({ flashMessageError: nextProps.resetCustomerPWDResponse.data.message })
          }
        }
        catch (err) {

        }
      }
      if (nextProps.updateNumberForOTP && nextProps.updateNumberForOTP.data) {
        try {
          if (nextProps.updateNumberForOTP.data.hasOwnProperty("status") && (nextProps.updateNumberForOTP.data.status === true)) {
            if (this.state.updateNumberStatus === true) {
              self.setState({ flashMessageError: '', flashMessageSuccess: "Your mobile number has been updated successfully" })
              setTimeout(() => self.setState({ openUpdateNumberModal: false }), 100)
              setTimeout(async function () {
                self.setState({
                  customerUpdatedNumber: nextProps.updateNumberForOTP.data.data.mobile,
                  openUpdateNumberModal: false,
                  Loading: false,
                  flashMessageSuccess: '',
                  updateNumberStatus: false,
                  validObj: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    companyName: "",
                    phone: "",
                    confirmPassword: ""
                  },
                })
                self.sendOTP()
              }, 3000);
            }
          } else {
            self.setState({ flashMessageError: nextProps.updateNumberForOTP.data.message, flashMessageSuccess: '', Loading: false, updateNumberStatus: false })
          }
        } catch (e) { }
      }
      if (nextProps.activateCustomerResponse && nextProps.activateCustomerResponse.data) {
        try {
          if (nextProps.activateCustomerResponse.data.data.hasOwnProperty('status') && (nextProps.activateCustomerResponse.data.data.status === true)) {
            self.setState({
              Loading: false,
              loginModalopen: true,
              openOtpModal: false,
              emailErrorSpan: '',
              flashMessageSuccess: language.language.customer_activation_success[0][lang],
              validObj: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                companyName: "",
                phone: "",
                confirmPassword: "",
              },
              flashMessageError: '',
              otpErrorSpan: ''
            });

          } else {
            self.setState({ flashMessageError: nextProps.activateCustomerResponse.data.data.message })
            setTimeout(function () {
              nextProps.activateCustomerResponse.data = null
              window.location.href = constant.DYNAMIC_DOMAIN
            }
              , 3000)
          }
        }
        catch (err) {

        }
      }

      /** Function Fetching Discount list  */
      if (nextProps.hasOwnProperty('discountList') && nextProps.discountList.hasOwnProperty('data')) {
        if (nextProps.discountList.data.error !== true) {
          let discountList = nextProps.discountList.data.data.data
          let list = []
          let activeDiscount = []
          discountList.length ? discountList.map((discount) => {
            let a, b, c;
            if (discount != null && discount.validFrom != null && discount.validFrom != null) {
              a = moment(discount.validFrom).format('MM/DD/YYYY')
              b = moment(discount.validTill).format('MM/DD/YYYY')
              c = moment(new Date()).format('MM/DD/YYYY')
              if ((moment(c).isBetween(a, b) || c === a || c === b) && discount.status === true) {
                list.push(discount)

              }

            }
          }) : void 0
          discountList = list
          if (discountList != null && discountList != '') {
            if (discountList.length) {
              discountList.map((list) => {
                if (list.status == true) {
                  activeDiscount.push(list)
                }
              })
            }
          }
          this.setState({
            discountList,
            activeDiscount: discountList
          })
        }

        if (nextProps.serachCIAMuser) {
          if (nextProps.serachCIAMuser.data.data.ciam.length > 0) {
            var data = nextProps.serachCIAMuser.data.data.ciam[0]
            this.setState({
              CIAMData: data
            })
          }
        }
      }
    } catch (e) { }
  }

  /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
  async componentWillMount() {
    try {
      if (sessionStorage.loggedIn !== '' && sessionStorage.emailID !== undefined) {
        if (localStorage.isVerifyAddress !== undefined && localStorage.isVerifyAddress === "false") {
          this.props.history.push("/dashboard");
        } else {
          this.props.history.push("/ordermanagement");
        }

      }
    } catch (err) {

    }
    var json = { "language": "en" }
    await this.props.viewHomeSettingAction(json)
    await this.props.customerListAction(6)
    await this.props.denominationListAction(6)
    await this.props.getCardTypeAction();
    await this.props.discountListAction(6)
    var currentUrl = window.location.href
    var customerEncodedData = currentUrl.substring(currentUrl.indexOf('=') + 1)
    this.setState({
      resetEncodedData: customerEncodedData
    })
    var otpVerified
    var firstName
    var lastName
    var phoneNumber
    var email
    var pimId

    if (currentUrl.includes("forgetId")) {
      this.setState({ resetPasswordModal: true, updateNumberStatus: false });
    }
    let customerID = {};
    let self = this
    if (currentUrl.includes("activate")) {
      if (customerEncodedData.includes("#")) {
        customerEncodedData = customerEncodedData.split("#")[0]
      }
      var decodedData = base64.decode(customerEncodedData)

      var decodedDataArray = decodedData.split('&??&')
      if (decodedDataArray.length) {
        otpVerified = decodedDataArray[5]
        firstName = decodedDataArray[1]
        phoneNumber = decodedDataArray[3]
        lastName = decodedDataArray[2]
        email = decodedDataArray[4]
        pimId = decodedDataArray[0]
        phoneNumber = phoneNumber.replace(/[^\w\s/]/gi, '')
        phoneNumber = phoneNumber.replace(/\s/g, "")

        self.setState({
          userData: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            pimId: pimId
          }
        })
        self.props.searchUserCIAMbyEmail(email);
        if (otpVerified == 1) {
          if (customerEncodedData.includes("#")) {
            customerEncodedData = customerEncodedData.replace('#', '');
            customerID = { "customerId": customerEncodedData, "language": "en" }
            this.props.activateCustomerAction(customerID)
          } else {
            customerID = { "customerId": customerEncodedData, "language": "en" }
            this.props.activateCustomerAction(customerID)
          }
        } else {

          // var otpJson = {
          //   "northgateId": "",
          //   "To": "+91" + phoneNumber,
          //   "emailId": "",
          //   "source": "gift_card"
          // }
          // this.setState({
          //   otpStatus: true
          // })
          // this.props.sendOTPAction(otpJson)
        }
      }
    }
  }

  /**
   * Function to resend OTP
   */
  resendOTP(e) {
    e.preventDefault()
    var OTPCount = this.state.OTPCount
    OTPCount = OTPCount + 1
    this.setState({
      Loading: true,
      flashMessageError: '',
      OTPCount: OTPCount
    })
    var phoneNumber = this.state.userData.phoneNumber
    var otpJson = {
      "prosperaId": "",
      "To": "+1" + phoneNumber,
      "emailId": "",
      "source": "gift_card"
    }
    this.setState({ otpStatus: true, otpErrorSpan: '', otpSuccessSpan: '', isResendOtp: true })
    this.props.sendOTPAction(otpJson)
  }

  /**
   * Function to skip OTP verification
   */
  skipOTPVerification(e) {
    e.preventDefault()
    var currentUrl = window.location.href
    var customerEncodedData = currentUrl.substring(currentUrl.indexOf('=') + 1)
    let decodedData = base64.decode(customerEncodedData)
    let decodedDataArray = decodedData.split("&??&")
    let customerSourceId = ''

    let userData = {
      "customerID": "",
      "firstName": decodedDataArray[1],
      "lastName": decodedDataArray[2],
      "emailId": decodedDataArray[4],
      "phoneNumber": decodedDataArray[3]
    }


    if (this.state.customerList.length > 0) {
      customerSourceId = this.state.customerList.filter(customer => customer.email === decodedDataArray[4])[0].id

    }


    if (customerEncodedData.includes("#")) {
      customerEncodedData = customerEncodedData.replace('#', '');
      userData.customerID = { "customerId": customerEncodedData, "language": "en" }
    } else {
      userData.customerID = { "customerId": customerEncodedData, "language": "en" }
    }
    var ciamCreateUserJson = {}
    if (this.state.CIAMData) {
      ciamCreateUserJson = {
        "source": "gift_card",
        "firstName": userData.firstName,
        "lastName": userData.lastName,
        "email": userData.emailId,
        "alternateEmail": {
          "customerEmail": [
            {
              "source": "gift_card",
              "emailType": "Home",
              "email": userData.emailId
            }
          ]
        },
        "sourceMapping": {
          "customerSources": [
            {
              "firstName": userData.firstName,
              "lastName": userData.lastName,
              "sourceId": `${customerSourceId}`,
              "source": "gift_card",
              "isSource": true
            }
          ]
        },
        "isActive": true,
        "prosperaId": this.state.CIAMData.prosperaId,
        "isUpdateAllKeys": true,
        "alternatePhone": {
          "customerPhone":
            [
              {
                "source": "gift_card",
                "phoneType": "Home",
                "dialCode": "+1",
                "phoneNo": userData.phoneNumber,

              }
            ]
        },
        "isOptin": true,
        "optin": [
          {
            "optinCategoryId": constant.OPTION_CATEGORY_ID,
            "updatedsource": "gift_card",
            "subscribe": false
          }
        ]
      }
    } else {

      ciamCreateUserJson = {
        "source": "gift_card",
        "sourceMapping": {
          "customerSources": [
            {
              "firstName": userData.firstName,
              "lastName": userData.lastName,
              "sourceId": `${customerSourceId}`,
              "source": "gift_card",
              "isSource": true
            }
          ]
        },
        "firstName": userData.firstName,
        "lastName": userData.lastName,
        "email": decodedDataArray[4],
        "isActive": true,
        "alternateEmail": {
          "customerEmail": [
            {
              "source": "gift_card",
              "emailType": "Home",
              "email": decodedDataArray[4]

            }
          ]
        },
        "alternatePhone": {
          "customerPhone": [
            {
              "source": "gift_card",
              "phoneType": "Home",
              "dialCode": "+1",
              "phoneNo": userData.phoneNumber.replace(/[^a-zA-Z0-9]/g, ''),

            }
          ]
        },
        "isOptin": true,
        "optin": [
          {
            "optinCategoryId": constant.OPTION_CATEGORY_ID,
            "updatedsource": "gift_card",
            "subscribe": false
          }
        ]
      }
    }
    var createAndActivateJson = {
      "customerID": customerEncodedData,
      "create": ciamCreateUserJson,
      "skipFlag": true,
      "pimId": +decodedDataArray[0]
    }
    this.setState({ Loading: true, openOtpModal: false })
    this.props.createUpdateUserCIAM(createAndActivateJson);
  }

  /**
  * Function for open loginModal
 */
  onOpenModalLogin = () => {
    this.setState({
      onCloseModalReg: true
    })
    if (this.props.customerLoginInfo.data != null) {
      this.props.customerLoginInfo.data = null
    }
    this.setState({ loginModalopen: true, emailErrorSpan: '', flashMessageError: '', onCloseModalReg: true, onCloseModal: true, regiModalOpen: false, passwordErrorSpan: '', validObj: { firstName: "", lastName: "", email: "", password: "", companyName: "", phone: "", confirmPassword: "" }, changeColorState: true });
    var currentUrl = window.location.href
    var customerId = window.location.href.substring(window.location.href.lastIndexOf('=') + 1)
    let customerID = {};
    if (currentUrl.includes("activate")) {
      if (customerId.includes("#")) {
        customerId = customerId.replace('#', '');
        customerID = { "customerId": customerId, "language": "en" }
        this.props.activateCustomerAction(customerID)
      } else {
        customerID = { "customerId": customerId, "language": "en" }
        this.props.activateCustomerAction(customerID)
      }
    }
  };

  /**
  * Function for close loginModal
 */
  onCloseModalLogin = () => {
    this.setState({ openOtpModal: false, loginModalopen: false, emailErrorSpan: '', passwordErrorSpan: '', changeColorState: false, isEmptyField: false });
  };

  /**
  * Function for close ResetConfModal
 */
  onCloseResetConfModal = () => {
    this.setState({ resetPasswordModal: false });
  };

  /**
  * Function for open RegistraionModal
 */
  onOpenModalReg = () => {
    this.setState({
      loginModalopen: false,
      regiModalOpen: true,
      regchangeColorState: true,
      openOtpModal: false,
      onCloseModal: false,

      validObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      flashMessageError: '',
      flashMessageSuccess: '',
    });
    this.onCloseModalLogin()
  };

  /**
  * Function for validate Password
  * * @return boolean
 */
  validatePassword(password) {
    let pattern = /^(a1-z9){7,15}$/;
    if (password.match(pattern)) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
  * Function for Reseting customer password
 */
  setResetPassword() {
    let password = this.state.setPassword;
    if (password.length < 8 || password.length > 15) {
      this.setState({ flashMessageError: language.language.Password_Validation[0][this.state.lang] })
    }
    else if (this.state.setPassword == "" && this.state.confirmSetPassword == "") {
      this.setState({ flashMessageError: "Password Can't be null" })
    }
    else if (this.state.setPassword == this.state.confirmSetPassword) {
      let newCreatedPassword = this.state.setPassword;
      let encodedData = this.state.resetEncodedData
      let decodedData = base64.decode(encodedData)
      let email = decodedData.slice(0, decodedData.indexOf('&'))
      let
        customerPasswrodResetData = {
          email: email,
          password: newCreatedPassword,
          language: "en"
        }
      this.props.customerPasswordResetAction(customerPasswrodResetData)
    } else {
      this.setState({ flashMessageError: "Password do not match" })
    }
  }

  componentDidUpdate() {
    if (this.props.searchAndCreateUser.data != null) {
      this.props.searchAndCreateUser.data = null
    }
  }

  componentDidMount() {
    if (this.props.searchAndCreateUser.data != null) {
      this.props.searchAndCreateUser.data = null
    }
    this.setState({
      loginModalopen: false
    })
    setTimeout(function () {
      let crouselButton = document.querySelectorAll(".gifts-slider ._1JwaBMUCMsc8QmGrT7DX45 ._3h8t2ML6KJ6OrCXmy0O_sN");
      let crouselButtonCount = crouselButton.length;
      for (var i = 0; i < crouselButton.length; i++) {
        crouselButton[i].style.width = (96 / crouselButtonCount) + '%';
      }

    }, 1000);
  }

  /**
    * Function to redirect to buygiftcard "All cards"
    */
  openOrderManagement() {
    try {
      sessionStorage.userID = 0
      sessionStorage.cardType = 'all'
      this.props.history.push('/ordermanagement')
      sessionStorage.language = this.state.lang
    } catch (e) { console.log("erorr openOrderManagement", e) }

  }

  /**
  * Function to set language to Spanish
  */
  activateSpanishLanguage() {
    sessionStorage.language = 'spa'
    this.setState({
      lang: 'spa'
    })

  }

  /**
   * Function to set language to English
   */
  activateEnglishLanguage() {
    sessionStorage.language = 'en'
    this.setState({
      lang: 'en'
    })

  }

  /**
   * Function to close OTP modal
   */
  onCloseOtpModal = () => {
    this.setState({
      openOtpModal: false
    })
  }

  /*Function to verify otp */
  verifyOTP() {
    const { otp } = this.state
    if (otp.o1 != '' && otp.o2 != '' && otp.o3 != '' && otp.o4 != '') {
      var otpString = otp.o1 + otp.o2 + otp.o3 + otp.o4
      var currentUrl = window.location.href
      let customerID = {};
      var customerEncodedData = currentUrl.substring(currentUrl.indexOf('=') + 1)
      if (customerEncodedData.includes("#")) {
        customerEncodedData = customerEncodedData.replace('#', '');
        customerID = { "customerId": customerEncodedData, "language": "en" }

      } else {
        customerID = { "customerId": customerEncodedData, "language": "en" }

      }

      var verifyOtpJson = {
        "phoneNumber": this.state.userData.phoneNumber,
        "emailId": "",
        "otp": otpString,
        "customerData": customerID,
        "firstName": this.state.userData.firstName,
        "lastName": this.state.userData.lastName,
        "email": this.state.userData.email,
        "pimId": this.state.userData.pimId
      }
      this.setState({ Loading: true, isResendOtp: false })
      this.props.veriftOTPAction(verifyOtpJson)
      this.setState({
        otpSuccessSpan: ''
      })
    } else {
      this.setState({ otpErrorSpan: 'Invalid OTP' })
    }
  }

  /*Function to set otp */
  setOTP = (e) => {
    this.setState({ otpErrorSpan: '', flashMessageError: '' })
    let val = e.target.value;
    let key = e.target.name;
    let valid = {};
    if (val === '') {
      valid[key] = '';
    } else {
      valid[key] = val;
    }
    let otp = Object.assign(this.state.otp, valid);
    this.setState({
      otp: otp
    });
    if (val != '') {
      if (key == "o1")
        document.getElementsByName('o2')[0].focus()
      if (key == "o2")
        document.getElementsByName('o3')[0].focus()
      if (key == "o3")
        document.getElementsByName('o4')[0].focus()
    }
    if (val === '') {
      if (key == "o4")
        document.getElementsByName('o3')[0].focus()
      if (key == "o3")
        document.getElementsByName('o2')[0].focus()
      if (key == "o2")
        document.getElementsByName('o1')[0].focus()
    }
  }

  /**
     * Function to redirect to buygiftcard "All cards"
     */
  openBuyGiftCradPage(e) {
    try {
      var cardType = e.target.id
      sessionStorage.userID = 0
      sessionStorage.cardType = cardType
      sessionStorage.language = this.state.lang
      this.props.history.push('/ordermanagement')
    } catch (e) { console.log("erro", e) }

  }

  /**
   * Function to change font size
   */
  changeSize(data) {
    if (data === 'increase') {
      let increaseCounter = this.state.increasemove
      let decreaseCounter = this.state.decreasemove

      if (increaseCounter > 0) {
        decreaseCounter = decreaseCounter + 1
        increaseCounter = increaseCounter - 1
        this.setState({
          fontSize: {
            header1: Number(this.state.fontSize.header1) + 1,
            header2: Number(this.state.fontSize.header2) + 1,
            headerSp1: Number(this.state.fontSize.headerSp1) + 1,
            headerSp2: Number(this.state.fontSize.headerSp2) + 1,
            giftCardButton: Number(this.state.fontSize.giftCardButton) + 1,
            loginSignup: Number(this.state.fontSize.loginSignup) + 1,
            language: Number(this.state.fontSize.language) + 1,
            a: Number(this.state.fontSize.a) + 1,
            loginModalLabel: Number(this.state.fontSize.loginModalLabel) + 1,
            loginModelButton: Number(this.state.fontSize.loginModelButton) + 1,

            //loginModelButton
          },
          increasemove: increaseCounter,
          decreasemove: decreaseCounter
        })
      }
    }
    else if (data === 'decrease') {
      let increaseCounter = this.state.increasemove
      let decreaseCounter = this.state.decreasemove
      if (decreaseCounter > 0) {
        decreaseCounter = decreaseCounter - 1
        increaseCounter = increaseCounter + 1
        this.setState({
          fontSize: {
            header1: Number(this.state.fontSize.header1) - 1,
            header2: Number(this.state.fontSize.header2) - 1,
            headerSp1: Number(this.state.fontSize.headerSp1) - 1,
            headerSp2: Number(this.state.fontSize.headerSp2) - 1,
            giftCardButton: Number(this.state.fontSize.giftCardButton) - 1,
            loginSignup: Number(this.state.fontSize.loginSignup) - 1,
            language: Number(this.state.fontSize.language) - 1,
            a: Number(this.state.fontSize.a) - 1,
            loginModalLabel: Number(this.state.fontSize.loginModalLabel) - 1,
            loginModelButton: Number(this.state.fontSize.loginModelButton) - 1,

          },
          increasemove: increaseCounter,
          decreasemove: decreaseCounter
        })
      }
    }
    else if (data === 'default') {

      this.setState({
        fontSize: {
          header1: +this.state.defaultFonts.header1,
          header2: +this.state.defaultFonts.header2,
          headerSp1: +this.state.defaultFonts.headerSp1,
          headerSp2: +this.state.defaultFonts.headerSp2,
          giftCardButton: 21,
          loginSignup: 12,
          language: 10,
          a: 16,
          loginModalLabel: 14,
          loginModelButton: 13,
        },
        increasemove: 4,
        decreasemove: 4
      }, () => console.log(this.state.fontSize))
    }
  }

  /**
   * Function for FAQ page
   */
  redirectToFaq = (event) => {
    try {
      if (this.props !== undefined && this.props.history !== undefined) {
        sessionStorage.language = this.state.lang
        this.props.history.push('/faq')
      }
    } catch (e) { }
  }

  /**
  * Function for close RegistraionModal
 */
  onCloseModalReg = () => {
    if (this.props.searchAndCreateUser.data != null) {
      this.props.searchAndCreateUser.data = null
    }
    this.setState({ regiModalOpen: false, Loading: false, flashMessageError: '', regchangeColorState: false });

    if (document.getElementsByClassName("RegFormModel")[0]) {
      document.getElementsByClassName("RegFormModel")[0].classList.add("exit");
    }
  };

  changeLinkColorLogin = () => {
    this.setState({
      changeColorState: true,
      regchangeColorState: false
    })
  }
  changeLinkColorSignUp = () => {
    this.setState({
      changeColorState: false,
      regchangeColorState: true
    })
  }

  /**
   * Function to open OTP modal
   */
  onOpenUpdateNumberModal = () => {
    this.setState({
      openUpdateNumberModal: true,
      otpErrorSpan: '',
      otpSuccessSpan: ''
    })
  }

  /**
   * Function to close OTP modal
   */
  onCloseUpdateNumberModal = () => {
    this.setState({
      openUpdateNumberModal: false
    })
  }

  setValue = (event) => {
    this.setState({
      flashMessageSuccess: '',
      flashMessageError: "",
      isInputFieldEmpty: false
    })
    let key = event.target.name
    let value = event.target.value
    let valid = {}
    let invalid = {}
    if (value === "") {
      invalid[key] = language.language.required_field[0]
      valid[key] = ""
    } else {
      invalid[key] = ""
      valid[key] = value
    }
    let inValidaData = Object.assign(this.state.invalidObj, invalid)
    let validaData = Object.assign(this.state.validObj, valid)
    this.setState({ invalidObj: inValidaData, validObj: validaData })
  }

  onUpdateNumberSubmit = () => {
    let { lang } = this.state
    let phone = this.state.validObj.phone;
    if (phone == "") {
      this.setState({
        flashMessageError: "Please enter your Mobile Number", isInputFieldEmpty: true
      })
    } else if (phone.length < 14) {
      this.setState({
        flashMessageError: language.language.invalid_phone[0][lang], isInputFieldEmpty: true
      })
      Object.assign(this.state.validObj, { phoneError: true })
    } else {
      let data = { "mobile": phone, "language": "en", "customerId": this.state.resetEncodedData }
      this.props.updateNumberAction(data)
      this.setState({
        Loading: true, isInputFieldEmpty: false, updateNumberStatus: true, otpErrorSpan: '', otpSuccessSpan: ''
      })
    }
  }

  /***Method for send otp  */
  sendOTP = () => {
    let { customerUpdatedNumber } = this.state
    var currentUrl = window.location.href
    var customerEncodedData = currentUrl.substring(currentUrl.indexOf('=') + 1)
    this.setState({
      resetEncodedData: customerEncodedData
    })
    var otpVerified
    var firstName
    var lastName
    var phoneNumber
    var email

    if (currentUrl.includes("forgetId")) {

      this.setState({ resetPasswordModal: true, updateNumberStatus: false });
    }

    let customerID = {};
    let self = this
    if (currentUrl.includes("activate")) {
      if (customerEncodedData.includes("#")) {
        customerEncodedData = customerEncodedData.split("#")[0]
      }
      var decodedData = base64.decode(customerEncodedData)
      var decodedDataArray = decodedData.split('&??&')
      if (decodedDataArray.length) {
        otpVerified = decodedDataArray[5]
        firstName = decodedDataArray[1]
        lastName = decodedDataArray[2]
        email = decodedDataArray[4]
        if (customerUpdatedNumber === '') {
          phoneNumber = decodedDataArray[3]
        } else {
          phoneNumber = customerUpdatedNumber
        }
        phoneNumber = phoneNumber.replace(/[^\w\s/]/gi, '')
        phoneNumber = phoneNumber.replace(/\s/g, "")
        self.setState({
          userData: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber
          }
        })


        if (otpVerified == 1) {
          if (customerEncodedData.includes("#")) {
            customerEncodedData = customerEncodedData.replace('#', '');
            customerID = { "customerId": customerEncodedData, "language": "en" }
            this.props.activateCustomerAction(customerID)
          } else {
            customerID = { "customerId": customerEncodedData, "language": "en" }
            this.props.activateCustomerAction(customerID)
          }
        }
        else {
          var otpJson = {
            "northgateId": "",
            "To": "+1" + phoneNumber,
            "emailId": "",
            "source": "gift_card"
          }

          this.setState({
            otpStatus: true
          })
          this.props.sendOTPAction(otpJson)
        }
      }
    }
  }


  /**
  * Method for fetching discount 
  * @param { event }
  */
  showDiscount = (e) => {
    this.setState({ isDicountopen: true })
    setTimeout(() => {

      document.getElementsByClassName("ReactModal__Overlay")[0].className = "newmodal"


    }, 200)
    e.preventDefault()
  }

  /** Method for close modal   */
  closeModal = () => {

    this.setState({ isDicountopen: false });
  };

  render() {

    var regchangeColorClass = this.state.regchangeColorState === true ? 'yellowColor' : '';
    var changeColorClass = this.state.changeColorState === true ? 'yellowColor' : '';

    //flash Messages for errors and successes
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <p >{this.state.flashMessageSuccess}</p>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <p color='danger'>{this.state.flashMessageError}</p>;
    }

    //error message for otp
    let otpErrorSpan = ''
    if (this.state.otpErrorSpan) {
      otpErrorSpan = <p color='danger'>{this.state.otpErrorSpan}</p>
    }
    let otpSuccessSpan = ''
    if (this.state.otpSuccessSpan) {
      otpSuccessSpan = <p>{this.state.otpSuccessSpan}</p>
    }
    var lang = this.state.lang
    const { cardTypeList } = this.state
    const { homeSetting } = this.state
    const { fontSize, validObj } = this.state
    let activeDiscount = this.state.activeDiscount
    let cardDisount = []
    let achDiscount = []
    activeDiscount.length && activeDiscount !== null ? activeDiscount.map((discount) => {
      discount.paymentType === "Card" ? cardDisount.push(discount) : achDiscount.push(discount)
    }) : void 0
    let cardSort
    if (cardDisount.length) {
      cardSort = _.sortBy(cardDisount, (discount) => {
        return discount.amountFrom
      })
    }
    let achSort
    if (achDiscount.length) {
      achSort = _.sortBy(achDiscount, (discount) => {
        return discount.amountFrom
      })
    }

    let font = {
      header1: lang !== 'spa' ? homeSetting.englishHeader1FontSize : homeSetting.spanishHeader1FontSize,
      header2: lang !== 'spa' ? homeSetting.englishHeader2FontSize : homeSetting.spanishHeader2FontSize,
      header1Color: lang !== 'spa' ? homeSetting.englishHeader1Color : homeSetting.spanishHeader1Color,
      header2Color: lang !== 'spa' ? homeSetting.englishHeader2Color : homeSetting.spanishHeader2Color
    }

    let discountString = sessionStorage.language === "spa" ? "o mas" : "and above"
    return (

      <div className="wrapper customerHome">
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }

        <div className="custom-gc-bg" style={{ backgroundImage: 'url(' + constant.APIURL + homeSetting.imagePath + homeSetting.imageName + ')' }}>
          <main>
            <section className="jumbotron text-center">
              <div className="container">
                <h1 tabindex="-1" style={{ fontSize: lang !== 'spa' ? fontSize.header1 : fontSize.headerSp1 + 'px', color: font.header1Color }} className="jumbotron-heading">{this.state.lang !== 'spa' ? homeSetting.englishHeader1 : homeSetting.spanishHeader1}</h1>
                <h2 tabindex="-1" style={{ fontSize: lang !== 'spa' ? fontSize.header2 : fontSize.headerSp2 + 'px', color: font.header2Color }} className="caption-head">{this.state.lang !== 'spa' ? homeSetting.englishHeader2 : homeSetting.spanishHeader2}</h2>
                <h3>

                  <a href="javascript:void(0)" tabindex="-1" style={{ fontSize: fontSize.giftCardButton + 'px' }} className="my-2 buygiftbtn" onClick={this.openOrderManagement.bind(this)}>{language.language.BUY_GIFT_CARD[0][lang]}</a>
                </h3>
              </div>
            </section>
            <div className="py-3 tab-container">
              <div className="container justify-content-md-center">

                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-4 ">
                    <div className="g-card mb-4">
                      <div className="animation-two">
                        <div className="sliderthumb">
                          <img tabindex="-1" className="card-img-top" src={Array.isArray(cardTypeList) && cardTypeList.length ? constant.APIURL + cardTypeList[0].imagePath + cardTypeList[0].imageName : ''} id={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[0].id : 0} name="all" onClick={this.openBuyGiftCradPage.bind(this)} alt={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[0].name : ''} title={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[0].name : ''} /></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-4">
                    <div className="g-card mb-4">
                      <div className="animation-two">
                        <div className="sliderthumb">
                          <img tabindex="-1" className="card-img-top" src={Array.isArray(cardTypeList) &&  cardTypeList.length ? constant.APIURL + cardTypeList[1].imagePath + cardTypeList[1].imageName : ''} id={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[1].id : 0} name="birthday" onClick={this.openBuyGiftCradPage.bind(this)} alt={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[1].name : ''} title={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[1].name : ''} /></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-4">
                    <div className="g-card mb-4">
                      <div className="animation-two">
                        <div className="sliderthumb">
                          <img tabindex="-1" className="card-img-top" src={Array.isArray(cardTypeList) &&  cardTypeList.length ? constant.APIURL + cardTypeList[2].imagePath + cardTypeList[2].imageName : ''} id={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[2].id : 0} name="thankYou" onClick={this.openBuyGiftCradPage.bind(this)} alt={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[2].name : ''} title={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[2].name : ''} /></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 offset-lg-0 offset-md-4">
                    <div className="g-card mb-4">
                      <div className="animation-two">
                        <div className="sliderthumb">
                          <img tabindex="-1" className="card-img-top" src={Array.isArray(cardTypeList) && cardTypeList.length ? constant.APIURL + cardTypeList[3].imagePath + cardTypeList[3].imageName : ''} id={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[3].id : 0} name="holidays" onClick={this.openBuyGiftCradPage.bind(this)} alt={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[3].name : ''} title={Array.isArray(cardTypeList) && cardTypeList.length ? cardTypeList[3].name : ''} /></div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </main>

          <div className="modal-box" id="">
            <LoginModal
              open={this.state.loginModalopen}
              changeLinkColorSignUp={this.changeLinkColorSignUp}
              changeLinkColorLogin={this.changeLinkColorLogin}
              openSignup={this.state.regiModalOpen}
              modalClose={this.onCloseModalLogin}
              regModalClose={this.onCloseModalReg}
              stateData={this.state}
              locationProps={this.props} />
          </div>


          {/* ResetPassword Confirmation modal */}
          <div className="modal-box" id="">
            <ResetPasswordModal open={this.state.resetPasswordModal} onClose={this.onCloseResetConfModal} className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Reset Your Password</h4>
                </div>
                <div className="modal-body">
                  <form>
                    {flashSuceessMessageSpan !== "" ?
                      <div className="alert alert-success alert_succesfull clearfix" role="alert">
                        <div className="successbtn"><img src={successimg} alt="success icon" /></div>
                        <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                      </div>

                      : ''}
                    {flashErrorMessageSpan ?
                      <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                        <span><img src={errorimg} alt="error icon" /></span>
                        <p className="clearfix">{flashErrorMessageSpan}</p>
                      </div>
                      : ''}
                    <div className="form-group">
                      <label htmlFor="passowrdReset">New Password</label>
                      <input title="New Password" type="password" className="form-control" onChange={(e) => this.setNewPasswordValue(e)} name="newpassword" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="passowrdReset">Confirm Password</label>
                      <input title="Confirm Password" type="password" className="form-control" onChange={(e) => this.setConfirmPasswordValue(e)} name="confirmpassword" />
                    </div>
                    <div className="buttons">
                      <button type="button" onClick={(e) => this.setResetPassword()} className="btn btn-default login-btn" >Reset Password </button>
                      <button type="button" className="giftcard_button cardnone_button" onClick={this.onCloseResetConfModal}>Cancel</button>
                    </div>
                  </form>
                </div>
              </div>
            </ResetPasswordModal>
          </div>

          {/* otp modal */}
          {
            this.state.userActivated ?
              <OtpModal
                open={this.state.openOtpModal}
                onClose={this.onCloseOtpModal}
                className="modal-dialog-box" center
                classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel otpModel' }}
                closeOnOverlayClick={false}
                showCloseIcon={false}
              >
                <div className="modal-content otpModel">
                  <div className="modal-header giftcart_title_header">
                    <h4 className="modal-title giftcart_all_header">NOTIFICATION</h4>
                  </div>

                  <div className="modal-body otpModel">

                    <div className="alert alert-success alert_succesfull clearfix" role="alert">
                      <div className="successbtn"><img src={successimg} alt="success icon" /></div>
                      <p className="text-center clearfix"> Account is already activated  </p>
                    </div>
                  </div>
                </div>
              </OtpModal>

              :
              <div className="modal-box" id="">
                <OtpModal
                  open={this.state.openOtpModal}
                  onClose={this.onCloseOtpModal}
                  className="modal-dialog-box" center
                  classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel otpModel' }}
                  closeOnOverlayClick={false}
                  showCloseIcon={false}
                >
                  {!this.state.openUpdateNumberModal ?

                    <div className="modal-content otpModel">
                      <div className="modal-header giftcart_title_header">
                        <h4 className="modal-title giftcart_all_header">Enter OTP</h4>
                      </div>

                      <div className="modal-body otpModel">
                        {/* {otpErrorSpan} */}
                        {flashSuceessMessageSpan !== "" ?
                          <div className="alert alert-success alert_succesfull clearfix" role="alert">
                            <div className="successbtn"><img src={successimg} alt="success icon" /></div>
                            <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                          </div>

                          : ''}
                        {flashErrorMessageSpan ?
                          <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                            <span><img src={errorimg} alt="error icon" /></span>
                            <p className="clearfix">{flashErrorMessageSpan}</p>
                          </div>
                          : ''}
                        {otpErrorSpan ?
                          <div className="alert alert-success alert_succesfull adpadding30 clearfix " role="alert">
                            <span><img src={errorimg} alt="error icon" /></span>
                            <p className="clearfix">{otpErrorSpan}</p>
                          </div>
                          : ''}

                        {otpSuccessSpan !== "" ?
                          <div className="alert alert-success alert_succesfull clearfix" role="alert">
                            <div className="successbtn"><img src={successimg} alt="success icon" /></div>
                            <p className="text-center clearfix"> {otpSuccessSpan} </p>
                          </div>

                          : ''}
                        <form className="otp-form">

                          <div className="row pdlft8">
                            <p className="otp-text">Please enter your one time password (OTP) that has been sent on your mobile number. This OTP is valid for 10 mins.</p>
                          </div>
                          <div className="col-md-12">
                            <div className="row pdlft8">
                              <div className="col-md-3 pl-0"><InputMask mask={'9'} maskChar={null} className="form-control  otp-inputbox" type="text" name="o1" onChange={(e) => this.setOTP(e)} /></div>
                              <div className="col-md-3 pl-0"><InputMask mask={'9'} maskChar={null} className="form-control  otp-inputbox" type="text" name="o2" onChange={(e) => this.setOTP(e)} /></div>
                              <div className="col-md-3 pl-0"><InputMask mask={'9'} maskChar={null} className="form-control  otp-inputbox" type="text" name="o3" onChange={(e) => this.setOTP(e)} /></div>
                              <div className="col-md-3 pl-0"><InputMask mask={'9'} maskChar={null} className="form-control  otp-inputbox" type="text" name="o4" onChange={(e) => this.setOTP(e)} /></div>
                            </div>
                          </div>
                          <div className="col-md-12 pl-0">
                            <div className="row pdlft8">
                              <div className="formbtn col-md-12 text-left resend-otp">
                                <button type="button" className="giftcard_button mgr10 " onClick={this.verifyOTP.bind(this)}>VERIFY</button>
                                {this.state.OTPCount != 2 ?
                                  <a href="javascript:void(0)" className="resend-otp pl-3" onClick={this.resendOTP.bind(this)}>Resend OTP !</a>
                                  :
                                  <a href="javascript:void(0)" className="resend-otp" onClick={this.skipOTPVerification.bind(this)}>Skip OTP Verification</a>
                                }
                                <a href="javascript:void(0)" className="resend-otp pl-4" onClick={this.onOpenUpdateNumberModal.bind(this)}>Change Mobile Number !</a>


                              </div>

                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    :
                    <div className="modal-content otpModel">
                      <div className="modal-header giftcart_title_header">
                        <h4 className="modal-title giftcart_all_header">{language.language.Update_Mobile[0][lang]}</h4>
                      </div>
                      <div className="modal-body">
                        {flashSuceessMessageSpan !== "" ?
                          <div className="alert alert-success alert_succesfull clearfix" role="alert">
                            <div className="successbtn"><img src={successimg} alt="" /></div>
                            <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                          </div>

                          : ''}
                        {flashErrorMessageSpan ?
                          <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                            <span><img src={errorimg} alt="" /></span>
                            <p className="clearfix">{flashErrorMessageSpan}</p>
                          </div>
                          : ''}

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="phone">{language.language.mobile_number[0][lang]}</label>
                              <InputMask
                                className={`form-control ${validObj.phone === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                                id="phone"
                                onChange={(e) => this.setValue(e)}
                                mask="(999) 999-9999"
                                maskChar={null}
                                name="phone"
                                value={validObj.phone}
                              />
                            </div>
                          </div>
                          <div className="buttons col-md-12 text-center">
                            <button className="giftcard_button mgr10" onClick={(e) => this.onUpdateNumberSubmit(e)}>{language.language.update[0][lang]}</button>
                          </div>
                        </div>


                      </div>
                    </div>
                  }
                </OtpModal>

              </div>
          }
          {/* otp modal end */}

          {/* Update phone */}
          {/* <div className="modal-box" id="">
            <UpdateNumberModal open={this.state.openUpdateNumberModal} onClose={this.onCloseUpdateNumberModal} className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}>
              <div className="modal-content">
                <div className="modal-header giftcart_title_header">
                  <h4 className="modal-title giftcart_all_header">Update phone</h4>
                </div>
                <div className="modal-body">
                  {flashSuceessMessageSpan !== "" ?
                    <div className="alert alert-success alert_succesfull clearfix" role="alert">
                      <div className="successbtn"><img src={successimg} alt="" /></div>
                      <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                    </div>

                    : ''}
                  {flashErrorMessageSpan ?
                    <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                      <span><img src={errorimg} alt="" /></span>
                      <p className="clearfix">{flashErrorMessageSpan}</p>
                    </div>
                    : ''}
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="phone">{language.language.phone_number[0][lang]}</label>
                        <InputMask
                          className={`form-control ${validObj.phone === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                          id="phone"
                          onChange={(e) => this.setValue(e)}
                          mask="(999) 999-9999"
                          maskChar={null}
                          name="phone"
                          value={validObj.phone}
                        />
                        </div>
                      </div>
                      <div className="buttons col-md-12 text-center">
                        <button type="button" className="giftcard_button mgr10" onClick={(e) => this.onUpdateNumberSubmit(e)}>Update Phone</button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </UpdateNumberModal>
          </div> */}


          <header id="header">
            <div className="container-fluid">
              <div className="logo-div pull-left">
                <a tabindex="-1" href={NORTHGATE_URL} className="logo"><img src={CustomerLogo} alt="Northgate Market Logo" title="Northgate Market Logo" /></a>
              </div>
              <div className="header-search pull-left">

                <div className="search-container">
                  <form className="search-header">
                    <fieldset>
                    </fieldset>
                  </form>
                  <ul className="text-size-menu">
                    <li title="Decrease Font" tabindex="-1" onClick={this.changeSize.bind(this, 'decrease')} ><a href="javascript:void(0)" style={{ fontSize: fontSize.a + 'px' }}>A-</a></li>
                    <li title="A" tabindex="-1" onClick={this.changeSize.bind(this, 'default')} ><a href="javascript:void(0)" style={{ fontSize: fontSize.a + 'px' }}>A</a></li>
                    <li title="Increase font" tabindex="-1" onClick={this.changeSize.bind(this, 'increase')} ><a href="javascript:void(0)" style={{ fontSize: fontSize.a + 'px' }} >A+</a></li>
                  </ul>
                </div>
              </div>

              <div className="header-login right_section landing-right_section">
                <div className="faq-section" >
                  <ul className="user-icon-header newcustome_head topheaderhm">
                    <li className="custom-faq">
                      <div className="card_icon cardnone_button mgr10">
                        <a href="javascript:void(0)" tabindex="-1" style={{ fontSize: fontSize.loginSignup + 'px' }} onClick={(e) => { this.redirectToFaq(e) }} title={language.language.FAQ[0][lang]}>{language.language.FAQ[0][lang]}</a>
                      </div>
                    </li>


                    <li className="custom-bulk">
                      <div className="card_icon cardnone_button mgr10">

                        <a title={language.language.Discount[0][lang]} href="javascript:void(0)" className={regchangeColorClass} style={{ fontSize: fontSize.loginSignup + 'px' }} onClick={(e) => { this.showDiscount(e) }}>{language.language.Discount[0][lang]}</a>

                      </div>
                    </li>

                    {/* for cart */}

                  </ul>
                  <ul className="login-menu-header">
                    <li><a tabindex="-1" title={language.language.Login[0][lang]} href="javascript:void(0)" className={changeColorClass} style={{ fontSize: fontSize.loginSignup + 'px' }} onClick={this.onOpenModalLogin.bind(this)}>{language.language.Login[0][lang]}</a></li>
                    <li ><a tabindex="-1" title={language.language.Signup[0][lang]} href="javascript:void(0)" className={regchangeColorClass} style={{ fontSize: fontSize.loginSignup + 'px' }} onClick={this.onOpenModalReg.bind(this)}>{language.language.Signup[0][lang]}</a></li>



                  </ul>
                </div>
                <div className={sessionStorage.userID == 0 ? 'language-menu-div pull-right' : 'language-menu-space pull-right'}>
                  <form className="form">
                    <fieldset>
                      <legend>
                        <div className="switch-field">
                          <input type="radio" id="switch_left" name="switch_2" value="yes" checked={this.state.lang == 'en' ? true : false} />
                          <label tabindex="-1" htmlFor="switch_left" title={language.language.english[0][lang]} onClick={this.activateEnglishLanguage.bind(this)} style={{ fontSize: fontSize.language + 'px' }}>{language.language.english[0][lang]}</label>
                          <input type="radio" id="switch_right" name="switch_2" value="no" checked={this.state.lang == 'spa' ? true : false} />
                          <label tabindex="-1" htmlFor="switch_right" title={language.language.spanish[0][lang]} onClick={this.activateSpanishLanguage.bind(this)} style={{ fontSize: fontSize.language + 'px' }}>{language.language.spanish[0][lang]}</label>
                        </div>
                      </legend>
                    </fieldset>
                  </form>
                </div>

              </div>
            </div>
            {/**Discount Modal  */}
            <ReactModal isOpen={this.state.isDicountopen} closeTimeoutMS={500} onRequestClose={this.closeModal}
              className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }} shouldCloseOnOverlayClick={false}>
              <div className="modal-content landingcustomemodal discount_home">
                <div className="modal-header giftcart_title_header">
                  <h4 className="modal-title giftcart_all_header">{language != undefined ?
                    activeDiscount.length > 0 ?
                      language.language.Gift_CARD_ToolTip_Discount[0][lang] : language.language.Gift_CARD_ToolTip_Discount_Not_Found[0][lang] : ''}</h4>
                  <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModal}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                </div>
                {
                  activeDiscount.length > 0 ?

                    <div className="modal-body">
                      {activeDiscount.length && activeDiscount != null && activeDiscount != '' ?

                        <div className="discount-pay discount_new">

                          <table className="table">
                            <thead>
                              <tr>
                                <th>{language.language.Payment_Type[0][lang]}</th>
                                <th>{language.language.Purchase_Amount[0][lang]}</th>
                                <th>{language.language.Gift_card_discount[0][lang]} <i className="fa fa-info-circle question_mark" title={language.language.Gift_CARD_ToolTip_Discount[0][lang]}></i></th>
                              </tr>
                            </thead>

                            <tbody>
                              {cardSort != undefined && cardSort.length > 0 ? cardSort.map((discount, index) => {
                                return (<tr key={index}>
                                  {index === 0 ? <td> <span className="fa fa-credit-card"></span> {language.language.CARD_Discount[0][lang]}</td> : <td></td>}
                                  <td>${HelperUtil._getAmountCommaSeparator(discount.amountFrom).toString()} {discount.amountTo === 0 ? discountString : "- $" + HelperUtil._getAmountCommaSeparator(discount.amountTo).toString()}</td>
                                  <td>{discount.percent}%</td>
                                </tr>)
                              })
                                : <tr></tr>}
                              {cardDisount != undefined && cardDisount.length && achDiscount != undefined && achDiscount.length ?
                                <tr >
                                  <td style={{ borderBottom: '2px solid #a1a1a1', padding: '0' }}></td>
                                  <td style={{ borderBottom: '2px solid #a1a1a1', padding: '0' }}></td>
                                  <td style={{ borderBottom: '2px solid #a1a1a1', padding: '0' }}></td>
                                </tr> :
                                <tr></tr>}
                              {achSort != undefined && achSort.length > 0 ? achSort.map((discount, index) => {
                                return (<tr className="" key={index}>
                                  {index === 0 ? <td> <span className="fa fa-university"></span>  {language.language.ACH_Discount[0][lang]}</td> : <td></td>}
                                  <td>${HelperUtil._getAmountCommaSeparator(discount.amountFrom).toString()} {discount.amountTo === 0 ? discountString : "- $" + HelperUtil._getAmountCommaSeparator(discount.amountTo).toString()}</td>
                                  <td>{discount.percent}%</td>
                                </tr>)
                              })
                                : <tr></tr>}

                            </tbody>


                          </table>

                        </div>
                        : ''}


                    </div>
                    : void 0}

              </div>
            </ReactModal>
          </header>

        </div>

        <div>
        </div>
      </div>
    )
  }
}


/**
 * constant mapStateToProps for pass value in redux
 * @param object state
 * @return object userLoginInfo
*/
const mapStateToProps = (state) => {
  return {
    customerLoginInfo: state.customerLogin,
    customerRegisterInfo: state.customerRegister,
    customerList: state.customerList,
    resetCustomerPWDResponse: state.resetCustomerPWD,
    resetCustomerPasswordResponse: state.resetCustomerPassword,
    activateCustomerResponse: state.activateCustomer,
    sendOtp: state.sendOtp,
    verifyOtp: state.verifyOtp,
    cardTypeListData: state.cardTypeList,
    viewHomeSettings: state.viewHomeSettings,
    searchAndCreateUser: state.searchAndCreateUser,
    updateNumberForOTP: state.updateNumberForOTP,
    discountList: state.discountList,
    serachCIAMuser: state.serachCIAMuser,

  };
}
export default connect(mapStateToProps, { customerListAction, customerResetPWDAction, customerRegisterAction, customerLoginAction, customerPasswordResetAction, activateCustomerAction, denominationListAction, sendOTPAction, veriftOTPAction, getCardTypeAction, viewHomeSettingAction, searchUserAction, updateNumberAction, discountListAction, searchUserCIAMbyEmail, createUpdateUserCIAM })(HomeCustomer)