import React, { Component,createRef } from "react";
import Header from "../Layout/Header";
import Navigation from "../Layout/Navigation";
import { connect } from "react-redux";
import {
  orderDetailsAction,
  acknowledgeOrderAction,
  cancelOrderAction,
} from "../../../Actions/Admin/orderManagementAction";
import * as constant from "../../../Constants/";
import Modal from "react-responsive-modal";
import moment from "moment";
import * as HelperUtil from "../../../utils/common-helper";
import ReactLoading from "react-loading";
import { Alert, Fade } from "reactstrap";
import Helmet from "react-helmet";
import { Button } from "react-bootstrap/lib/InputGroup";
import Invoice from "./invoice";
import ReactToPrint from "react-to-print";
import printIcon from '../../../public/img/print-icon.png';


/** Class for viewing OrderDetails*/
class OrderDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: "",
      orderDetails: {
        customerName: "",
        shippingType: "",
        expectedShippingDate: "",
        orderNumber: "",
        orderDate: "",
        orderedProduct: [],
        subTotal: "",
        discountAmount: "",
        shippingAmount: "",
        totalAmount: "",
        shippingAddress1: "",
        shippingAddress2: "",
        shippingAddressId: "",
        shippingCity: "",
        shippingCountry: "",
        shippingState: "",
        shippingZip: "",
        historyId: "",
        paymentType: "",
        status: "",
        cancelReason: "",
      },
      nextOrderId: "",
      openAcknowledgeModal: false,
      pin: "",
      openCancelOrderModal: false,
      cancellationReason: "",
      Loading: false,
      activeClassName: "",
      flashMessageError: "",
      openInvoiceModal:false
    };
  
    this.invoiceRef = createRef();
  }
  componentWillMount() {
    try {
      if (localStorage.loggedIn == "" || sessionStorage.length == 0) {
        this.props.componentID.history.push("/ng-admin");
      }
    } catch (err) {}
    this.setState({ activeClassName: this.props.pathParam });
    var data = {
      orderId: this.props.orderInfo,
      loggedInUser: sessionStorage.userID,
      language: "en",
    };
    if (this.props.orderInfo != "") {
      this.setState({
        Loading: true,
        openAcknowledgeModal: false,
      });
      this.props.orderDetailsAction(data);
    }
  }

  componentDidMount() {
    if (this.props.acknowledgeOrder.data != null) {
      this.props.acknowledgeOrder.data = null;
    }
    if (this.props.cancelOrder.data != null) {
      this.props.cancelOrder.data = null;
    }
  }
  onPrintClick=(val)=>{
    this.setState({openInvoiceModal:val})
  }
  componentWillReceiveProps(nextProps) {
    console.log("next props:: ", nextProps);
    try {
      if (nextProps.orderInfo != "") {
        this.setState({
          orderId: nextProps.orderInfo,
        });
      }
      if (
        nextProps.orderDetails &&
        nextProps.orderDetails.data &&
        nextProps.orderDetails.data.error === false
      ) {
        var orderDetails = nextProps.orderDetails.data.data.data;
        console.log(orderDetails.currentStatus, "currentStatus");
        var status = "";
        var cancelReason = "";
        if (
          orderDetails.acknowledge == true &&
          orderDetails.orderedProduct[0].productRange.length > 0 &&
          orderDetails.activationStatus == ""
        ) {
          // status = 'Delivered'
          status = "Shipped";
        } else if (
          orderDetails.acknowledge == true &&
          orderDetails.activationStatus
        ) {
          status = "Activated";
        } else if (orderDetails.acknowledge == true) {
          status = "Acknowledged";
        } else if (orderDetails.cancelStatus == true) {
          status = "Cancelled";
          cancelReason = orderDetails.cancelReason;
        } else if (orderDetails.paymentStatus == false) {
          status = "Payment Failed";
        } else status = "Pending";
        this.setState({
          orderDetails: {
            customerName: orderDetails.customerName,
            shippingType: orderDetails.shippingType,
            expectedShippingDate: orderDetails.eta,
            orderNumber: orderDetails.orderNumber,
            orderDate: orderDetails.orderDate,
            orderedProduct: orderDetails.orderedProduct,
            subTotal: orderDetails.subTotal,
            discountAmount: orderDetails.discountAmount,
            shippingAmount: orderDetails.shippingRate,
            totalAmount: orderDetails.total,
            shippingAddress1: orderDetails.shippingAddress1,
            shippingAddress2: orderDetails.shippingAddress2,
            shippingAddressId: orderDetails.shippingAddressId,
            shippingCity: orderDetails.shippingCity,
            shippingCountry: orderDetails.shippingCountry,
            shippingState: orderDetails.shippingState,
            shippingZip: orderDetails.shippingZip,
            historyId: orderDetails.historyId,
            paymentType: orderDetails.paymentType,
            status: status,
            cancelReason: cancelReason,
          },
          Loading: false,
        });
      } else {
        this.setState({ Loading: false });
      }
      if (nextProps.acknowledgeOrder && nextProps.acknowledgeOrder.data) {
        try {
          if (nextProps.acknowledgeOrder.data.status == true) {
            this.setState({
              openAcknowledgeModal: true,
              pin: nextProps.acknowledgeOrder.data.pin,
            });
          } else {
            this.setState({ Loading: false });
          }
        } catch (err) {}
      } else {
        this.setState({ Loading: false });
      }
      if (nextProps.cancelOrder && nextProps.cancelOrder.data) {
        try {
          if (nextProps.cancelOrder.data.status == true) {
            this.setState({
              openCancelOrderModal: false,
              Loading: false,
            });
            this.props.orderDetailsProps.history.push({
              pathname: "/ng-admin/ordermanagement",
            });
          } else {
            if (nextProps.cancelOrder.data.toLowerCase().includes("declined")) {
              this.setState({ flashMessageError: "declined" });
            }
            this.setState({ Loading: false });
          }
          nextProps.cancelOrder.data = null;
        } catch (err) {}
      } else {
        this.setState({ Loading: false });
      }
    } catch (e) {}
  }

  /* Function to render next order details */
  nextOrder() {
    this.setState({
      orderId: "",
      orderDetails: {
        customerName: "",
        shippingType: "",
        expectedShippingDate: "",
        orderNumber: "",
        orderDate: "",
        orderedProduct: [],
        subTotal: "",
        discountAmount: "",
        shippingAmount: "",
        totalAmount: "",
        shippingAddress1: "",
        shippingAddress2: "",
        shippingAddressId: "",
        shippingCity: "",
        shippingCountry: "",
        shippingState: "",
        shippingZip: "",
        historyId: "",
        paymentType: "",
      },
      nextOrderId: "",
    });
  }

  /* Function to acknowledge the order */
  acknowledgeOrder() {
    this.setState({ Loading: true });
    // Rajesh new request prepared for payeeze-api start
    var historyId = this.state.orderDetails.historyId;
    var array = historyId.split("_");
    let newcaptureData = {
      id: array[0],
      tag: array[1],
      amount: array[2],
    };
    // Rajesh new request prepared for payeeze-api end
    let captureData =
      constant.ACKNOWLEDGE_ORDER_PAYMENT +
      this.state.orderDetails.totalAmount +
      "&postonly=" +
      this.state.orderDetails.historyId;
    var data = {
      orderId: this.state.orderId,
      loggedInUser: sessionStorage.userID,
      language: "en",
      captureData: newcaptureData,
      paymentType: this.state.orderDetails.paymentType,
      paymentDate: new Date(),
    };
    this.props.acknowledgeOrderAction(data);
  }

  /**
   * Function to close the modal
   */
  onCloseModal() {
    this.setState({
      openAcknowledgeModal: false,
    });
    this.props.orderDetailsProps.history.push({
      pathname: "/ng-admin/ordermanagement",
    });
  }

  /*Function to open gift card range page */
  openGiftCardRangePage() {
    let self = this;
    this.props.orderDetailsProps.history.push({
      pathname: "/ng-admin/ordermanagement",
      state: {
        pageType: "rangePage",
        orderDetails: this.state.orderDetails,
        pin: this.state.pin,
        orderId: this.state.orderId,
      },
    });
  }

  /** Function for cancel order*/
  cancelOrder() {
    let self = this;
    let { cancellationReason } = this.state;
    if (cancellationReason !== "") {
      this.setState({ Loading: true });
      // Rajesh new request prepared for payeeze-api start
      var historyId = this.state.orderDetails.historyId;
      //console.log("orderDetails:: ", this.state.orderDetails);
      var array = historyId.split("_");
      let newcancelApiData = {
        id: array[0],
        tag: array[1],
        amount: array[2],
      };
      // Rajesh new request prepared for payeeze-api end
      let cancelApiData =
        constant.CANCELE_PENDING_PAYMENT +
        this.state.orderDetails.totalAmount +
        "&orderkeyid=" +
        this.state.orderDetails.orderNumber +
        "&historykeyid=" +
        this.state.orderDetails.historyId;
      var data = {
        orderId: this.state.orderId,
        loggedInUser: sessionStorage.userID,
        language: "en",
        cancelReason: this.state.cancellationReason,
        cancelApiData: newcancelApiData,
	amount:HelperUtil._getAmountCommaSeparator(
        Number(this.state.orderDetails.totalAmount).toFixed(2)
).toString()
      };

      this.props.cancelOrderAction(data);
    } else {
      this.setState({
        flashMessageError: "Please provide the reason for cancellation",
      });
      setTimeout(function() {
        self.setState({
          flashMessageError: "",
        });
      }, 3000);
    }
  }

  /** Function for open model when order is about to cancle*/
  openCancelOrderModal() {
    this.setState({
      openCancelOrderModal: true,
    });
  }

  /** Function to close the cancel modal */
  onCloseCancelModal() {
    this.setState({
      openCancelOrderModal: false,
    });
  }

  /** Function for handle click event and set cancelation reason by user*/
  setReason(e) {
    var val = e.target.value;
    this.setState({
      flashMessageError: "",
      cancellationReason: val,
    });
  }

  render() {
    if (sessionStorage.loggedIn === "" && sessionStorage.length == 0) {
      this.props.history.push("/ng-admin");
    }
    const { orderDetails } = this.state;
    let {
      acknowledge,
      cancelStatus,
      paymentStatus,
    } = this.props.orderDetailsProps.location.state.displayOrderInfo;
    console.log(
      "orderDetails location:: ",
      this.props.orderDetailsProps.location.state.displayOrderInfo
    );
    let flashMessageErrorSpan;
    if (this.state.flashMessageError) {
      flashMessageErrorSpan = (
        <Alert className="alertFont" color="danger">
          {this.state.flashMessageError}
        </Alert>
      );
    }
    return (
      <div className="admin_top">
        {this.state.Loading === true && (
          <div className="loader-react">
            <ReactLoading
              type={"spinningBubbles"}
              color={"green"}
              className="reactLoader"
            />
          </div>
        )}
        <Helmet>
          <title>
            {orderDetails.status == "Pending"
              ? "Acknowledge Order"
              : "Order Details"}
          </title>
        </Helmet>
        <div className="content-wrapper d-flex flex-column">
          <Header />
         
          <div className="main">
            <Navigation activeClassName={this.state.activeClassName} />
            <div className="dashboardContent">
              <div className="header-padding">
                <div className="container-fluid">
                  <div className="acknowledgeOrder-wrapper pagelist">
                    <div className="row mar0">
                      <div className="col-md-8 customer-section-left">
                        <h3 className="top_heading">
                          {orderDetails.status == "Pending"
                            ? "Acknowledge Order"
                            : "Order Details"}
                        </h3>
                        <div className="printMe">
                         
                         <button className="giftcard_button add_btnfont pull-right button-padding"onClick={()=>this.onPrintClick(true)} >
                           Take a Print
                         </button>
                       </div>
                        <div className="acknowledgeOrder">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="product">
                                <h3>{orderDetails.orderNumber}</h3>
                                <p>
                                  {moment(
                                    new Date(orderDetails.orderDate)
                                  ).format("MM/DD/YYYY")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                  
                        <div className="acknowledgeOrderBox" style={{maxHeight:'400px',paddingTop:'30px',overflow:'hidden',overflowY:'visible',paddingRight:'20px'}}>
                          <div className="row" >
                            {orderDetails.orderedProduct.length > 0
                              ? orderDetails.orderedProduct.map(
                                  (orderDetails, i) => {
                                    return (
                                      <div
                                        className="col-md-3"
                                        key={`orderDetails_admin_${i}`}

                                      >
                                        <div className="orderBoxSquare Custome_Order newcustome">
                                          <img
                                            src={
                                              constant.APIURL +
                                              orderDetails.imagePath +
                                              orderDetails.imageName
                                            }
                                            className="media-object"
                                            alt=""
                                          />
                                          <span className="giftOrderCircle">
                                            {orderDetails.noOfCards}
                                          </span>
                                        </div>
                                        
                                        <div className="orderBoxText">
                                          <h5 className="orderGift">
                                            {orderDetails.productType}
                                          </h5>
                                          <p>
                                            ${" "}
                                            {HelperUtil._getAmountCommaSeparator(
                                              Number(
                                                orderDetails.productPrice
                                              ).toFixed(2)
                                            ).toString()}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : ""}
                          </div>
                        </div>
                        <div className="order_Acknowledge_section">
                          <div className="row">
                            <div className="col-md-7">
                              <div className="row">
                                <div className="col-sm-6">
                                  <ul>
                                    <li>Sub Total</li>
                                    <li>Discount</li>
                                    <li>Shipping Charges</li>
                                  </ul>
                                </div>
                                <div className="col-sm-6">
                                  <ul className="text-right">
                                    <li>
                                      ${" "}
                                      {orderDetails.subTotal != ""
                                        ? HelperUtil._getAmountCommaSeparator(
                                            Number(
                                              orderDetails.subTotal
                                            ).toFixed(2)
                                          ).toString()
                                        : "0.00"}
                                    </li>
                                    <li>
                                      -${" "}
                                      {orderDetails.discountAmount != ""
                                        ? HelperUtil._getAmountCommaSeparator(
                                            orderDetails.discountAmount.toFixed(
                                              2
                                            )
                                          ).toString()
                                        : "0.00"}
                                    </li>
                                    <li>
                                      +${" "}
                                      {orderDetails.shippingAmount != "" &&
                                      orderDetails.shippingAmount !== null
                                        ? HelperUtil._getAmountCommaSeparator(
                                            orderDetails.shippingAmount.toFixed(
                                              2
                                            )
                                          ).toString()
                                        : "0.00"}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="totalAmountBox">
                                <span>Total Amount</span>
                                <h3>
                                  ${" "}
                                  {HelperUtil._getAmountCommaSeparator(
                                    Number(orderDetails.totalAmount).toFixed(2)
                                  ).toString()}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-4 customer-section-right adpad30"
                        id="customer-section-right"
                        style={{ height: "85vh" }}
                      >
                        <div className="side-heading">
                          <div className="rightpart">
                            <h3>{orderDetails.customerName}</h3>
                            <address>
                              {orderDetails.shippingAddress1 != "" ||
                              orderDetails != undefined
                                ? orderDetails.shippingAddress1 +
                                  ", " +
                                  orderDetails.shippingCity +
                                  ", " +
                                  orderDetails.shippingState +
                                  ", " +
                                  orderDetails.shippingCountry +
                                  ", " +
                                  orderDetails.shippingZip
                                : ""}
                            </address>
                          </div>
                          <div className="rightpart">
                            <h3>Shipment Type </h3>
                            <p>
                              {orderDetails.shippingType != ""
                                ? orderDetails.shippingType
                                : "Normal"}
                            </p>
                          </div>
                          <div className="rightpart">
                            <h3>Expected Delivery Date</h3>
                            <p>
                              {moment(
                                new Date(orderDetails.expectedShippingDate)
                              ).format("MM/DD/YYYY")}
                            </p>
                          </div>
                          <div className="rightpart">
                            <h3>Status</h3>
                            <p>{orderDetails.status}</p>
                          </div>
                          {orderDetails.status == "Cancelled" ? (
                            <div className="rightpart">
                              <h3>Reason for cancellation</h3>
                              <p>{orderDetails.cancelReason}</p>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {(acknowledge == null && cancelStatus === null) ||
                          (acknowledge == false &&
                            cancelStatus === false &&
                            paymentStatus == true) ? (
                            orderDetails.paymentType == "CARD" ? (
                              <div className="buttons">
                                <button
                                  type="button"
                                  className="btn btn-light giftcard_button cardnone_button"
                                  onClick={this.acknowledgeOrder.bind(this)}
                                >
                                  acknowledge
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-light cancelbtn giftcard_button"
                                  onClick={this.openCancelOrderModal.bind(this)}
                                >
                                  cancel order
                                </button>
                              </div>
                            ) : (
                              <div className="buttons">
                                <button
                                  type="button"
                                  className="btn btn-light giftcard_button cardnone_button"
                                  onClick={this.acknowledgeOrder.bind(this)}
                                >
                                  acknowledge
                                </button>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       ({this.state.openInvoiceModal} ? <Invoice openInvoiceModal={this.state.openInvoiceModal} onPrintClick={this.onPrintClick} orderDetails={this.state.orderDetails}/> : "") 
        <Modal
          open={this.state.openAcknowledgeModal}
          onClose={this.onCloseModal.bind(this)}
          center
          classNames={{
            overlay: "addAddressoverlay",
            modal: "acknowledge-modalform modaladd_new",
          }}
          closeOnOverlayClick={false}
        >
          <div className="modal-center modal-dialog ">
            <div className="modal-content">
              {/*-- Modal Header */}
              <div className="modal-header giftcart_title_header acknowledgement">
                <h4 className="modal-title giftcart_all_header p-0">
                  Acknowledgement
                </h4>
                <button
                  type="button"
                  className="close account"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body ">
                <p>
                  You have acknowledged order number #
                  {this.state.orderDetails.orderNumber}
                </p>
                <form action="/" className="acknowledge-modal">
                  <button
                    type="button"
                    className="giftcard_button mgr10"
                    onClick={this.openGiftCardRangePage.bind(this)}
                  >
                    CONTINUE
                  </button>
                  <button
                    type="button"
                    className="giftcard_button cardnone_button"
                    onClick={this.onCloseModal.bind(this)}
                  >
                    CANCEL
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.openCancelOrderModal}
          onClose={this.onCloseCancelModal.bind(this)}
          center
          classNames={{
            overlay: "addAddressoverlay",
            modal: "acknowledge-modalform",
          }}
          closeOnOverlayClick={false}
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              {/*-- Modal Header */}
              <div className="modal-header giftcart_title_header account">
                <h4 className="modal-title giftcart_all_header">
                  Cancel Order
                </h4>
                <button
                  type="button"
                  className="close account"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body ">
                {flashMessageErrorSpan}
                <p>
                  Are you sure you want to cancel order number #
                  {this.state.orderDetails.orderNumber}
                </p>
                <form action="/" className="acknowledge-modal">
                  <label>Cancellation Reason</label>
                  <div className="cancel_order_modal pb-4">
                    <textarea
                      title="cancellationReason"
                      className="col-md-12"
                      rows="5"
                      name="cancellationReason"
                      onChange={(e) => this.setReason(e)}
                      id="cancelReason"
                    ></textarea>
                  </div>
                  <button
                    type="button"
                    className="giftcard_button cardnone_button mgr10"
                    onClick={this.cancelOrder.bind(this)}
                  >
                    CANCEL ORDER
                  </button>
                  <button
                    type="button"
                    className="giftcard_button"
                    onClick={this.onCloseCancelModal.bind(this)}
                  >
                    CLOSE
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderDetails: state.orderDetails,
    acknowledgeOrder: state.acknowledgeOrder,
    cancelOrder: state.cancelOrder,
  };
};

export default connect(mapStateToProps, {
  orderDetailsAction,
  acknowledgeOrderAction,
  cancelOrderAction,
})(OrderDetailsPage);
