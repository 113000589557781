import React from 'react';
import { Link } from 'react-router-dom';
import changepass from '../../../../public/img/changepass.png';
import signout from '../../../../public/img/signout.png';
import giftcardlogo from '../../../../public/img/logo.png';
import Modal from 'react-responsive-modal';
import { Button } from 'react-bootstrap';
import { changePasswordAction, navigationSideBarAction } from '../../../../Actions/Admin/userManagementAction';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import Navigation from '../../Layout/Navigation'
import ReactLoading from 'react-loading'



class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openChangePassword: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      displayError: false,
      flashMessageError: '',
      flashMessageSuccess: '',
      navigationClass: '',
      displayPasswordError: false,
      displayValidationError: false,
      Loading: false,
    };
  }

  logOut() {
    try {
      sessionStorage.userName = "";
      sessionStorage.loggedIn = "";
      sessionStorage.isSuperAdmin = "";
      sessionStorage.roleName = "";
      sessionStorage.roleID = "";
      sessionStorage.userID = ''
      sessionStorage.language = 'en'
      sessionStorage.removeItem('adminLoggedIn');
      sessionStorage.userType = '';
      localStorage.removeItem("loggedIn");
      window.location.href = "/"
    } catch (e) { }
  }

  openChangePasswordModal() {
    this.setState({
      openChangePassword: true,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
  }

  onCloseModal = () => {
    try {
      this.setState({
        openChangePassword: false,
        displayPasswordError: false,
        displayValidationError: false,
        displayError: false,

      })
    } catch(e) { console.log("error in cancel",e)}
  
  }

  setOldPassword(e) {
    let val = e.target.value;
    this.setState({
      oldPassword: val
    })
  }

  setNewPassword(e) {
    let val = e.target.value;
    this.setState({
      newPassword: val,
      displayError: false,
      displayPasswordError: false,
      displayValidationError: false
    })
  }

  setConfirmPassword(e) {
    let val = e.target.value;
    this.setState({
      confirmPassword: val,
      displayError: false,
      displayPasswordError: false,
      displayValidationError: false
    })
  }

  //function to validate Password
  validatePassword(password) {
    let pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/
    return password.match(pattern) ? true : false
    // if (password.match(pattern)) {
    //   return true;
    // }
    // else {
    //   return false;
    // }
  }

  changePassword() {
    var data = { "id": sessionStorage.userID, "password": this.state.newPassword, "oldpassword": this.state.oldPassword, "loggedInUser": sessionStorage.userID, "language": "en" }
    if (this.state.newPassword !== '' && this.state.oldPassword !== '' && this.state.newPassword == this.state.oldPassword) {
      this.setState({
        displayPasswordError: true
      })
      return
    }
    else if (this.state.newPassword != '' && this.setState.confirmPassword != '' && this.state.newPassword != this.state.confirmPassword) {
      this.setState({
        displayError: true
      })
      return
    }
    else {
      if (!(this.validatePassword(data.password))) {
        this.setState({
          displayValidationError: true
        })
        return
      }
      this.setState({ Loading: true })
      this.props.changePasswordAction(data)
    }
  }

  componentWillMount() {
    if (sessionStorage.newUser == 'true') {
      this.setState({
        openChangePassword: true
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changePasswordData && nextProps.changePasswordData.data) {
      if (nextProps.changePasswordData.data.data.hasOwnProperty('status') && nextProps.changePasswordData.data.data.status === true) {
        sessionStorage.newUser = 'false'
        this.setState({ flashMessageSuccess: nextProps.changePasswordData.data.data.message })
        setInterval(function () {
          window.location.reload();
        }, 5000);
      } else {
        this.setState({ flashMessageError: nextProps.changePasswordData.data.data.message, Loading: false })
      }
    }
    if (nextProps.navigationClass && nextProps.navigationClass.data) {
      this.setState({ navigationClass: nextProps.navigationClass.data });
    }
  }
  componentDidMount() {
    /* When the user clicks on the button, 
toggle between hiding and showing the dropdown content */
    function myFunction() {
      document.getElementById("myDropdown").classList.toggle("show");
    }
    // Close the dropdown if the user clicks outside of it
    window.onclick = function (event) {
      if (!event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    }
  }

  toggleSidrBar() {
    if (this.state.navigationClass == 'abc') {
      this.props.navigationSideBarAction('fliph');
    } else if (this.state.navigationClass == '') {
      this.props.navigationSideBarAction('fliph');
    }
    else {
      this.props.navigationSideBarAction('abc');
    }
  }

  render() {
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
    }
    return (
      <div>
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }
        <header className="header nav_top fixed-top">
          <nav className="navbar northgate_nav navbar-toggleable-md navbar-light pt-0 pb-0 ">
            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={(e) => this.toggleSidrBar()}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand p-0" href="/ng-admin/home"
            >
              <img src={giftcardlogo} alt="" /></a>
            <div className="float-left nav_icon"> <a href="#" className="button-left" onClick={(e) => this.toggleSidrBar()}
            ><span className="fa fa-fw fa-bars "></span></a> </div>
            <div className="list-inline header-top pull-right user-icon-header">
              <div className="dropdown adminDropdown">
                <a className="dropbtn "> {sessionStorage.userName} <i className="fa fa-angle-down"></i></a>
                <div className="dropdown-content" id="myDropdown">
                  {/* {sessionStorage.isSuperAdmin !== 'true' ? */}
                    <div className="dropdown-header"><a href="#" onClick={this.openChangePasswordModal.bind(this)}><img src={changepass} /> Change Password</a></div>
                    {/* // : ''} */}
                  <div className="dropdown-header"><a href="/ng-admin" onClick={this.logOut.bind(this)}><img src={signout} alt="" /> Logout</a></div>
                </div>
              </div>
            </div>
            <div className=" navbar-collapse flex-row-reverse" id="navbarNavDropdown">
              <div className="navbar-nav">
                <div className="header-rightside">
                  <div className="login-menu-div pull-left">
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <Modal open={this.state.openChangePassword} onClose={this.onCloseModal} center classNames={{ overlay: 'addUseroverlay', modal: 'AddUserModelform' }}
           closeOnOverlayClick={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header giftcart_title_header">
                  <h3 className="modal-title giftcart_all_header"> Change Password</h3>
                </div>
                <div className="modal-body">
                  {flashSuceessMessageSpan}
                  {flashErrorMessageSpan}
                  <form>
                    <div container className="form-group">
                      <label>Current Password</label>
                      <input className='form-control' type="password" name='oldPassword' value={this.state.oldPassword} ref={(e) => this.oldPassword = e} onChange={this.setOldPassword.bind(this)} />
                    </div>
                    <div container className="form-group">
                      <label>New Password</label>
                      <input className='form-control' type="password" name='newPassword' value={this.state.newPassword} ref={(e) => this.newPassword = e} onChange={this.setNewPassword.bind(this)} />
                    </div>
                    <div container className="form-group">
                      <label>Confirm Password</label>
                      <input className='form-control' type="password" name='confirmPassword' value={this.state.confirmPassword} ref={(e) => this.confirmPassword = e} onChange={this.setConfirmPassword.bind(this)} />
                    </div>
                    {this.state.displayError ? <span style={{ color: 'red' }}>New & Confirm password doesn't match</span> : ''}
                    {this.state.displayPasswordError ? <span style={{ color: 'red' }}>New Password and Current Password cannot be same</span> : ''}
                    {this.state.displayValidationError ? <span style={{ color: 'red' }}>Password must be 8-15 charcaters long and must include at least 1 numeric, 1 lower case and 1 upper case letter</span> : ''}
                    <div className="formbtn" align-left>
                      <button type="button" className='giftcard_button mgr10' onClick={(e) => this.changePassword()}>RESET</button>
                      <button onClick={this.onCloseModal} type="button" className="giftcard_button cardnone_button">CANCEL</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>
        </header>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    changePasswordData: state.changePassword,
    navigationClass: state.navigationToggleClass
  };
}

export default connect(mapStateToProps, { changePasswordAction, navigationSideBarAction })(Header);
