import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
  isSubmitting: false,
  data: null,
  login: false
}

/**
 * Function for get denomination list
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function denominationList(state = data, action) {
  switch (action.type) {
    case constants.DENOMINATION_LIST:
      return { ...state, data: action.payload };
    case constants.DENOMINATION_LIST_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for get card-type list
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function cardTypeList(state = data, action) {
  switch (action.type) {
    case constants.CARD_TYPE_LIST:
      return { ...state, data: action.payload };
    case constants.CARD_TYPE_LIST_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to add denomination
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function addDenomination(state = data, action) {
  switch (action.type) {
    case constants.ADD_DENOMINATION:
      return { ...state, data: action.payload };
    case constants.ADD_DENOMINATION_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to update denomination
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function updateDenomination(state = data, action) {
  switch (action.type) {
    case constants.UPDATE_DENOMINATION:
      return { ...state, data: action.payload };
    case constants.UPDATE_DENOMINATION_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to delete denomination
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function deleteDenomination(state = data, action) {
  switch (action.type) {
    case constants.DELETE_DENOMINATION:
      return { ...state, data: action.payload };
    case constants.DELETE_DENOMINATION_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to delete denomination
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function deleteDenominationImage(state = data, action) {
  switch (action.type) {
    case constants.DELETE_DENOMINATION_IMAGE:
      return { ...state, data: action.payload };
    case constants.DELETE_DENOMINATION_IMAGE_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}