import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Alert } from 'reactstrap';
import { userLog } from '../../../Actions/Admin/userAction';
import LoginImage from '../../../public/img/north-gate-logo.png';
import LostPasswordModal from 'react-responsive-modal';
import * as constants from '../../../Constants/index';
import { resetPwdLinkAction, updateUserPwdAction } from '../../../Actions/Admin/userManagementAction';
import ResetPasswordModal from 'react-responsive-modal';
import * as language from '../../../Constants/translations';
import ReactLoading from 'react-loading'

/** Class for User Login*/
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginError: '',
      validObj: {
        username: "",
        password: ""
      },
      invalidObj: {
        username: "",
        password: ""
      },
      openLostPasswordModal: false,
      forgetinvalidObj: {
        email: ""
      },
      forgetvalidObj: {
        email: ""
      },
      flashMessageError: '',
      resetEncodedData: '',
      resetPasswordModal: false,
      setPassword: "",
      confirmSetPassword: "",
      lang: 'en',
      Loading: false,
      isErrorField: false,

    }
  }

  /**
   * Component life cycle
   * Function for store user name in session storage variable
   * @param nextProps
   * @return session info
  */
  componentWillReceiveProps(nextProps) {
    try {
      let self = this
      if (nextProps.userLoginInfo && nextProps.userLoginInfo.data) {
        if (nextProps && nextProps.userLoginInfo.data) {
          if (nextProps.userLoginInfo.data.data.role!=='' && nextProps.userLoginInfo.data.hasOwnProperty('status')  && nextProps.userLoginInfo.data.status === true) {
        
            sessionStorage.loggedIn = true;
            sessionStorage.userName = nextProps.userLoginInfo.data.data.name;
            sessionStorage.userID = nextProps.userLoginInfo.data.data.id;
            sessionStorage.roleName = nextProps.userLoginInfo.data.data.role;
            sessionStorage.roleID = nextProps.userLoginInfo.data.data.roleId;
            sessionStorage.isSuperAdmin = nextProps.userLoginInfo.data.data.admin;
            sessionStorage.adminLoggedIn = true
            localStorage.setItem('loggedIn', true)
            if (nextProps.userLoginInfo.data.data.activateDate == null) {
              sessionStorage.newUser = true
            }
          } else if(nextProps.userLoginInfo.data.data.role ==''){
            let self = this
            this.setState({ loginError: 'Invalid Login' })
            setTimeout(function () {
              self.setState({ loginError: "", Loading: false })
            }, 5000)
          }
          
          else {
            let self = this
            this.setState({ loginError: nextProps.userLoginInfo.data.message })
            setTimeout(function () {
              self.setState({ loginError: "", Loading: false })
            }, 5000)
          }
        }
      } else if (nextProps.userLoginInfo && nextProps.userLoginInfo.data && nextProps.userLoginInfo.login == false) {
        sessionStorage.loggedIn = false;
        sessionStorage.userName = '';
        sessionStorage.roleID = '';
        sessionStorage.type = '';
      }
      if (nextProps.sendResetPwdLink && nextProps.sendResetPwdLink.data) {
        try {
          if (nextProps.sendResetPwdLink.data.data.hasOwnProperty('status') && nextProps.sendResetPwdLink.data.data.status === true) {
            self.setState({
              flashMessageSuccess: nextProps.sendResetPwdLink.data.data.message,
            })
            setTimeout(function () {
              self.setState({
                openLostPasswordModal: false,
                flashMessageSuccess: '',
                loginError: "",
                Loading: false
              })
            }, 5000);
          } else {
            if (nextProps.sendResetPwdLink.data.data.message == 'Invalid emailid') {
              self.setState({ flashMessageError: 'Email Address does not exist' })
              setTimeout(function () {
                self.setState({ flashMessageError: '' })
              }, 5000)
            } else {
              self.setState({ flashMessageError: nextProps.sendResetPwdLink.data.data.message, Loading: false })
            }
          }
        }
        catch (err) { }
      }
      if (nextProps.updateUserPwd && nextProps.updateUserPwd.data) {
        try {
          if (nextProps.updateUserPwd.data.data.hasOwnProperty('status') && (nextProps.updateUserPwd.data.data.status === true)) {
            self.setState({ flashMessageError: '', flashMessageSuccess: nextProps.updateUserPwd.data.data.message })
            setTimeout(function () {
              let redirect = window.location.href.substring(window.location.href.lastIndexOf('?'), 0)
              window.location = redirect;
            }, 5000);
          } else {
            self.setState({ flashMessageError: nextProps.updateUserPwd.data.data.message })
          }
        }
        catch (err) { }
      }
    } catch (e) { }
  }

  /**
   * Function for authenticate user is valid or not on submit
   * @param object event
   * @return object userParams
  */
  authenticate = (event) => {
    if (this.state.validObj.username != "" && this.state.validObj.password != "") {
      let userParams = {
        username: this.state.validObj.username,
        password: this.state.validObj.password
      }
      this.setState({ Loading: true })
      this.props.userLog(userParams)
    } else {
      let invalid = {}
      if (this.state.validObj.username == "") {
        invalid['username'] = "Please enter username"
      }
      if (this.state.validObj.password == "") {
        invalid['password'] = "Please enter password"
      }
      let inValidaData = Object.assign(this.state.invalidObj, invalid)
      this.setState({ invalidObj: inValidaData })
    }
  }

  /**
   * Function for validate user details
   * @param object event
   * @return object state
  */
  setValue = (event) => {
    let key = event.target.name
    let value = event.target.value
    let valid = {}
    let invalid = {}
    if (value === "") {
      invalid[key] = "This field is required"
      valid[key] = ""
    } else {
      invalid[key] = ""
      valid[key] = value
    }
    let inValidaData = Object.assign(this.state.invalidObj, invalid)
    let validaData = Object.assign(this.state.validObj, valid)
    this.setState({ invalidObj: inValidaData, validObj: validaData })
  }

  /**
   * Function to open lost password modal
   */
  lostPassword() {
    this.setState({
      openLostPasswordModal: true,
      flashMessageError: '',
    })
  }

  /* Function to set email address for lost password */
  forgetEmail = (event) => {
    event.preventDefault();
    let key = event.target.name
    let value = event.target.value
    let valid = {}
    let invalid = {}
    if (value === "") {
      invalid[key] = "This Field is required"
      valid[key] = ""
    } else {
      invalid[key] = ""
      valid[key] = value
    }
    let inValidaData = Object.assign(this.state.forgetinvalidObj, invalid)
    let validaData = Object.assign(this.state.forgetvalidObj, valid)
    this.setState({ forgetinvalidObj: inValidaData, forgetvalidObj: validaData, flashMessageError: "" })
  }

  /***
   * Function to close the Lost Password Modal 
   */
  closeLostPasswordModal() {
    this.setState({
      openLostPasswordModal: false,
      emailID: ''
    })
  }

  /* Function to set reset password link */
  resetPWDLink() {
    if (this.state.forgetvalidObj.email != "") {
      if (this.validateMail(this.state.forgetvalidObj.email)) {
        let userParams = {
          email: this.state.forgetvalidObj.email,
        }
        let url = window.location.host;
        let userDetails = { url: 'http://' + url + '/ng-admin/?forgotPassword=', email: userParams.email, "language": "en" };
        this.setState({ Loading: true })
        this.props.resetPwdLinkAction(userDetails);
      } else {
        this.setState({ flashMessageSuccess: "", flashMessageError: constants.INVALID_EMAIL })
      }
    }
    else {
      let invalid = {}
      this.setState({ flashMessageError: "Email Address can't be blank" })
      invalid['password'] = "This field is required"
      let inValidaData = Object.assign(this.state.forgetinvalidObj, invalid)
      this.setState({ forgetinvalidObj: inValidaData })
    }
  }

  /** Function to validate Email*/
  validateMail = (email) => {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
  componentWillMount() {
    var currentUrl = window.location.href
    var customerEncodedData = currentUrl.substring(currentUrl.indexOf('=') + 1)
    if (currentUrl.includes("forgotPassword=")) {
      this.setState({
        resetEncodedData: customerEncodedData
      })
      this.setState({ resetPasswordModal: true });
    }
  }

  /**
   * Function for handle click event and set password 
   */
  setNewPasswordValue = (event) => {
    let value = event.target.value
    if (value === "") {
    } else {
      this.setState({
        flashMessageError: '',
        setPassword: value
      })
    }
  }

  /**
   * Function for handle click event and match passord with two input fields
   */
  setConfirmPasswordValue = (event) => {
    let value = event.target.value
    if (value === "") {
    } else {
      this.setState({
        flashMessageError: '',
        confirmSetPassword: value
      })
    }
  }

  /** Function for close popup modal for reset Password*/ 
  closeResetPwdModal() {
    this.setState({
      resetPasswordModal: false
    })
  }

  /**
   * Method for reset password of user 
   */
  setResetPassword() {
    let password = this.state.setPassword;
    if (!(this.validatePassword(password))) {
      this.setState({ flashMessageError: language.language.Password_Validation[0][this.state.lang]})
    }
    else if (this.state.setPassword == "" && this.state.confirmSetPassword == "") {
      this.setState({ flashMessageError: "Password can't be null" })
    }
    else if (this.state.setPassword == this.state.confirmSetPassword) {
      let newCreatedPassword = this.state.setPassword;
      let encodedData = this.state.resetEncodedData
      let userPasswrodResetData = {
        userId: encodedData,
        password: newCreatedPassword,
        language: "en"
      }
      this.props.updateUserPwdAction(userPasswrodResetData)
    } else {
      this.setState({ flashMessageError: "Passwords do not match" })
    }
  }

  /** Function to validate Password*/
  validatePassword(password) {
    let pattern =  /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!@#$%^&*]+){8,15}$/;
    if (password.match(pattern)) {
      return true;
    }
    else {
      return false;
    }
  }

  /** Function for submit form on enter Keypress*/
  submitOnEnter(event) {
    if (event.which == 13 || event.keyCode == 13) {
      //Admin LogIn Form.
      if (event.target.id == 'pwd') {
        this.authenticate()
      }
    }
  }

  render() {
    let loginErrorSpan = '';
    if (this.state.loginError != false) {
      loginErrorSpan = <Alert className='alertFont' color='danger'>{this.state.loginError}</Alert>;
    }
    let { validObj, invalidObj } = this.state
    if (localStorage.loggedIn == 'true' && sessionStorage.adminLoggedIn) {
      if (sessionStorage.length > 0) {
        this.props.history.push("/ng-admin/home");
      }
      else {
        this.props.history.push("/ng-admin");
        localStorage.setItem("loggedIn", "")
      }
    }
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <Alert className='alertFont' >{this.state.flashMessageSuccess}</Alert>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
    }
    var lang = this.state.lang
    return (
      <div className="main-wrapper">
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }
        <div className="login-wrapper">
          <div className="container">
            <div className="login-box">
              <div className="north-logo">
                <img src={LoginImage} height="auto" />
              </div>
              {loginErrorSpan}
              <form>
                <div className="form-group">
                  <fieldset className="the-fieldset login-input-field">
                    <legend className="the-legend">Username</legend>
                    <Input onChange={(e) => this.setValue(e)} name="username" id="email" value={validObj.username} ref={(e) => this.username = e} className="form-control" />
                  </fieldset>
                  <span>{invalidObj.username}</span>
                </div>
                <div className="form-group">
                  <fieldset className="the-fieldset login-input-field">
                    <legend className="the-legend">Password</legend>
                    <Input type="password" onChange={(e) => this.setValue(e)} name="password" id="pwd" value={validObj.password} ref={(e) => this.password = e} className="form-control" onKeyPress={(e) => this.submitOnEnter(e)} />

                  </fieldset>
                  <span>{invalidObj.password}</span>
                </div>
                <div className="form-group lost-pwd">
                  <label className="form-check-label align-right" onClick={this.lostPassword.bind(this)}>
                    Forgot Password
                          </label>
                </div>
                <div className="login-Btn">
                  <button type="button" className="btn btn-success  btn-block" onClick={(e) => this.authenticate(e)}>LOGIN</button>
                </div>

              </form>

            </div>
          </div>
        </div>

        {/* lost password modal */}
        <div className="modal-box" id="">
          <LostPasswordModal open={this.state.openLostPasswordModal} onClose={this.closeLostPasswordModal.bind(this)} className="modal-dialog-box" center classNames={{ overlay: 'forgotPasswordoverlay', modal: 'forgotPassword' }} closeOnOverlayClick={false}>
            <div className="modal-content">
              <div className="modal-header giftcart_title_header">
                <h4 className="modal-title giftcart_all_header">Reset Password</h4>
              </div>
              <div className="modal-body">
                <form>
                  {flashErrorMessageSpan ? flashErrorMessageSpan : ""}
                  {flashSuceessMessageSpan ? flashSuceessMessageSpan : ""}
                  <div className="form-group">
                    <label htmlFor="email">{language.language.email_id[0][lang]}</label>
                    <input type="email" className="form-control" id="email" onChange={(e) => this.forgetEmail(e)} name="email" required placeholder={language.language.email_id[0][lang]} ref={(e) => this.email = e} onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = language.language.email_id[0][lang]} />
                  </div>
                  <div className="row pdlft8">
                    <div className="formbtn margin-left_20">
                      <button type="button" className="giftcard_button mgr10 " onClick={(e) => this.resetPWDLink()}>{language.language.Resend_Password[0][lang]}</button>
                      <button type="button" className="giftcard_button mgr10 " onClick={this.closeLostPasswordModal.bind(this)}>{language.language.CANCEL[0][lang]}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </LostPasswordModal>
        </div>
        <div className="modal-box" id="">
          <ResetPasswordModal open={this.state.resetPasswordModal} onClose={this.closeResetPwdModal.bind(this)} className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }} closeOnOverlayClick={false}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Reset Your Password</h4>
              </div>
              <div className="modal-body">
                <form>
                  {flashErrorMessageSpan ? flashErrorMessageSpan : ""}
                  {flashSuceessMessageSpan ? flashSuceessMessageSpan : ""}
                  <div className="form-group">
                    <label htmlFor="passowrdReset">New Password</label>
                    <input type="password" className="form-control" onChange={(e) => this.setNewPasswordValue(e)} name="newpassword" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="passowrdReset">Confirm Password</label>
                    <input type="password" className="form-control" onChange={(e) => this.setConfirmPasswordValue(e)} name="confirmpassword" />
                  </div>
                  <div className="buttons">
                    <button type="button" onClick={(e) => this.setResetPassword()} className="btn btn-default login-btn" >Reset Password</button>
                    <button type="button" className="giftcard_button cardnone_button" onClick={this.closeResetPwdModal.bind(this)}>Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </ResetPasswordModal>
        </div>
      </div>
    );
  }
}

/**
 * constant mapStateToProps for pass value in redux
 * @param object state
 * @return object userLoginInfo
*/
const mapStateToProps = (state) => {
  return {
    userLoginInfo: state.userLogin,
    sendResetPwdLink: state.sendResetPwdLink,
    updateUserPwd: state.updateUserPwd
  };
}

/* connction between redux and react with connect HOC */
export default connect(mapStateToProps, { userLog, resetPwdLinkAction, updateUserPwdAction })(Login);
