import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';

class RoleError extends React.Component {
    render() {
        
        if(localStorage.getItem('loggedIn')===null){
            this.props.history.push("/ng-admin");
        }
        return (
            <div className="admin_top">
            <div className="content-wrapper" className="d-flex flex-column">
                <Header/>
                <div className="main">
                    <Navigation/>
                    <div className="dashboardContent">
                        <div className="container-fluid">
                        <Alert className='alertFont' color='danger'>You do not have to access to this page</Alert>
                        </div >
                    </div >
                </div >
            </div >
        </div >
        )
    }
}
export default RoleError;