import React from "react"

import moment from "moment"

/**Class for setting pdf  format  */
export default class PdfFormat extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      OrderNumber: '',
      OrderDate: '',
      CustomerType: '',
      CustomerName: '',
      Address: '',
      ExpectedDeliveryDate: '',
      ShipmentType: '',
      Amount: '',
      PaymentType: "",
      Status: '',
      tableHeader:[],
      
    };

  }
  componentWillMount() {
      this.setState({tableHeader:this.props.tableHeader})
  }



  render() {

    let tblRowData = []
    var a = this.props.data;
    while(a.length) {
      tblRowData.push((a.splice(0,30)));
    }
   let  tblHeader = this.state.tableHeader.length  > 0 &&  this.state.tableHeader!==undefined ? this.state.tableHeader:[]

    return (
      <div className="section ">
        {
          tblRowData.length>0 ? tblRowData.map((table,i)=>{
            return (
              <div  id={`pdfId_${i}`} style={{ width: "640px",height:"auto", display: "block", position: "absolute", top: "0", left: "-9999px" }}>
              <table style={{ width: "100%", wordSpacing: "2px", margin: "0 auto", verticalAlign: "top", color: "#000", fontFamily: "Helvetica Neue", verticalAlign: "top", border: "0", cellpadding: "0", cellSpacing: "0" }}>
              <thead>
                  {tblHeader.map(tblHeader=>(<td style={{ padding: "10px", verticalAlign: "top",border:'solid .5px #ccc'  }}>
                    <p style={{ fontSize: "10px", margin: "0", lineHeight: "11px" ,color:"black" }}>{tblHeader}</p>
                  </td>))}
                  </thead>
                  {table.map(tblData=>(
                      
                <tbody>
                    <td style={{ padding: "10px", verticalAlign: "top",border:'solid .5px #ccc' ,color:"black"}}> <p style={{ fontSize: "10px", margin: "0", lineHeight: "11px", }}>{tblData["Customer Name"]}</p></td>
                    <td style={{ padding: "10px", verticalAlign: "top",border:'solid .5px #ccc'}}>  <p style={{ fontSize: "10px", margin: "0", lineHeight: "11px",  }}>{tblData.Address}</p></td>
                    <td style={{ padding: "10px", verticalAlign: "top",border:'solid .5px #ccc'}}>  <p style={{ fontSize: "10px", margin: "0", lineHeight: "11px", }}>{tblData["Creation Date"]}</p></td>
                 
              </tbody>
                  ))}
              </table>
            </div>
            )


          }) :void 0
         
        }
        </div>
    );
  }


}
