import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import AddEditFaq from './addEditFaq'
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";
import ReactLoading from 'react-loading'
import "react-datepicker/dist/react-datepicker.css";
import { getFAQListAction, deleteFAQAction, getFAQTotalRecordsAction } from '../../../Actions/Admin/faqManagementAction'
import * as language from '../../../Constants/translations';
import _ from 'lodash'
import Helmet from 'react-helmet';

// Class for displaying FAQ list
class FAQListPage extends Component {
   constructor() {
      super();
      this.state = {
         open: false,
         faqList: [],
         faqDetails: '',
         pageactive: 1,
         filteredDataList: [],
         filterBy: '',
         Loading: false,
         activeClassName: '',
         filterData: '',
         checkPointUpdateState: false,
         sortOrder: '',
         listToFilter: '',
         faqDataList: [],
         checkPointForUpdateState: false,
         lang: sessionStorage.language != undefined ? sessionStorage.language : "en",
         toggleLang: "English",
         englishListData: [],
         spanishListData: [],
         sequence: 0,
         totalFAQRecords: [],
         editState: false

      };
   }

   // Method to open the Add FAQ modal 
   onOpenModal = () => {
      this.setState({ open: true, faqDetails: '' });
   }

   /**
 * Function for update state for close modal
 * 
 */
   status = () => {
      this.props.getFAQTotalRecordsAction()
      this.setState({
         open: false,
         filterBy: 'Select',
         filterData: '',
         filteredDataList: this.state.faqDataList,
         editState: false,
         faqDetails: '',
      });
   }

   /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
   async componentWillMount() {
      var json = { "language": "en" }
      this.setState({ Loading: true })
      await this.props.getFAQListAction(json)
      await this.props.getFAQTotalRecordsAction()
      this.setState({
         activeClassName: this.props.pathParam,
      })
   }

   /**
* React Built method 
* keep waiting for change in props 
* @param {*} nextProps 
*/
   async componentWillReceiveProps(nextProps) {
      let self = this
      try {
         if (nextProps.faqTotalRecordsResponse !== undefined && nextProps.faqTotalRecordsResponse.data !== null &&  nextProps.faqTotalRecordsResponse.data.error !== true) {
            if (!this.state.editState) {
               if (this.state.toggleLang === "English" && nextProps.faqTotalRecordsResponse.data.data.data.English !== undefined) {
                  this.setState({ sequence: Number(nextProps.faqTotalRecordsResponse.data.data.data.English) })
               } else if (this.state.toggleLang === "Spanish" && nextProps.faqTotalRecordsResponse.data.data.data.Spanish !== undefined) {
                  this.setState({ sequence: Number(nextProps.faqTotalRecordsResponse.data.data.data.Spanish) })
               } else {
                  this.setState({ sequence: 0 })
               }
            }
            self.setState({ totalFAQRecords: nextProps.faqTotalRecordsResponse.data.data.data })
         }
         if (nextProps.faqListResponse !== undefined && nextProps.faqListResponse.data !== null &&  nextProps.faqListResponse.data.error !== true) {
            self.setState({
               faqDataList: nextProps.faqListResponse.data.data.data,
               filteredDataList: nextProps.faqListResponse.data.data.data,
               listToFilter: nextProps.faqListResponse.data.data.data,
               checkPointForUpdateState: false,
               Loading: false,
               englishListData: _.filter(nextProps.faqListResponse.data.data.data, function (o) { return o.lang == "English" }),
               spanishListData: _.filter(nextProps.faqListResponse.data.data.data, function (o) { return o.lang == "Spanish" })
            })
         } else {
            self.setState({ Loading: false })
         }
         try {
            document.getElementById('user-dashboard').style.height = window.innerHeight - 115 + 'px';
            let reso_height = document.getElementById('user-dashboard').style.height
            document.getElementsByClassName('res_height')[0].style.height = reso_height.slice(0, -2) - 240 + 'px';
         }catch(e){ console.log("DOM element not find error")}
            
         if (nextProps.faqDeleteResponse !== undefined && nextProps.faqDeleteResponse.data !== null && nextProps.faqDeleteResponse.data.error !== true) {
            if (self.state.checkPointForUpdateState == true) {
               self.props.getFAQTotalRecordsAction()
               self.props.getFAQListAction({ "language": "en" })
            }
         }

      } catch (e) {
         console.log("eror ",e )
      }
   }

   /**
    * Function to delete the FAQ
    */
   async deleteFaq(e, faqId) {
      let json = { "faqId": `${faqId}`, "language": "en" }
      let self = this
      confirmAlert({
         customUI: ({ onClose }) => {
            var lang = this.state.lang
            return (
               <div className="confirm-popup AddUserModelform">
                  <div className="modal-header giftcart_title_header"><h3 className="modal-title giftcart_all_header">{language.language.are_you_sure[0][lang]}</h3></div>
                  <div className="modal-body">
                     <p>You want to delete this FAQ</p>
                     <div className="formbtn">
                        <button type="button" className="giftcard_button mgr10"
                           onClick={async() => {
                              self.setState({
                                 checkPointForUpdateState: true,
                                 Loading: true
                              })
                             await this.props.deleteFAQAction(json)
                              onClose();
                           }}
                        >
                           {language.language.confirm[0][lang]}
                        </button>
                        <button type="button" onClick={onClose} className="giftcard_button cardnone_button">{language.language.No[0][lang]}</button></div>
                  </div>
               </div>
            );
         }
      })//                              
   }

   /**
    * Function to delete the FAQ
    */
   editFaq(e, faqData) {
      this.setState({ open: true, faqDetails: faqData, editState: true });
   }

   /**
     * function to change the active page
     * @param {*} page 
     */
   handlePageChange = (pageNumber) => {
      var count = pageNumber == 1 ? 0 : (pageNumber - 1) * 10;
      this.setState({
         pageactive: pageNumber
      })
   }

   /**
      * Function for auto search 
      * @param {*} event 
      */
   filterSearch(event) {
      let self = this
      var newDataList = []
      newDataList = this.state.listToFilter
      var val = event.target.value
      this.setState({
         filterData: val
      })
      var newArr = newDataList.filter(function (e) {
         if (self.state.filterBy == 'Question') {
            if (
               ((typeof e.question != "undefined" && e.question != null && e.question !== "") && e.question.toLowerCase().includes(val.toLowerCase()))
            ) {
               return e
            }
         }
         else if (self.state.filterBy == 'Answer') {
            if (
               ((typeof e.answer != "undefined" && e.answer != null && e.answer !== "") && e.answer.toLowerCase().includes(val.toLowerCase()))
            ) {
               return e
            }
         }
         else if (self.state.filterBy == 'Sequence') {
            if (
               ((typeof e.sequence != "undefined" && e.sequence != null && e.sequence !== "") && e.sequence.toString().includes(val))
            ) {
               return e
            }
         }
         else {
            return e
         }
      })
      this.setState({
         filteredDataList: newArr,
         pageactive: 1,
      })
   }

   /** Function to reset the date */
   resetList(e) {
      var val = e.target.value
      this.setState({
         filterBy: '',
         filterData: '',
         filteredDataList: this.state.listToFilter,
      })
   }

   /**
      * Function to set the filter value
      */
   setFilterBy(e) {
      var val = e.target.value
      this.setState({
         filterBy: val,
         filterData: '',
         filteredDataList: this.state.listToFilter
      })
   }

   /**
      * Function to set language
   * */
   changeLanguage = (e) => {
      let toggleLang
      let filteredDataList
      let { totalFAQRecords } = this.state
      let sequence
      if (e.target.name === "Spanish") {
         toggleLang = "Spanish"
         filteredDataList = this.state.spanishListData
         sequence = totalFAQRecords !== undefined && totalFAQRecords.Spanish !== undefined ? Number(totalFAQRecords.Spanish) : 0
      } else {
         toggleLang = "English"
         filteredDataList = this.state.englishListData
         sequence = totalFAQRecords !== undefined && totalFAQRecords.English !== undefined ? Number(totalFAQRecords.English) : 0
      }
      this.setState({
         toggleLang,
         filteredDataList,
         pageactive: 1,
         filterBy: 'Select',
         filterData: '',
         sequence
      })
   }

   render() {
      let { filteredDataList } = this.state
      let { toggleLang } = this.state
      filteredDataList = toggleLang === "English" ? _.filter(filteredDataList, function (o) { return o.lang == "English" }) : _.filter(filteredDataList, function (o) { return o.lang == "Spanish" })
      var list = filteredDataList != undefined ? filteredDataList.slice((this.state.pageactive - 1) * 10, (this.state.pageactive) * 10) : ''
      return (
         <div className="admin_top">
            {
               this.state.Loading === true && <div className="loader-react">
                  <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
               </div>
            }
                     
            <Helmet>
               <title>FAQ</title>
            </Helmet>
            <div id="content-wrapper" className="d-flex flex-column">
               <Header />
               <div className="main">
                  <Navigation activeClassName={this.state.activeClassName} />
                  <div className="dashboardContent">
                     <div className="container-fluid">
                        <div className="user-dashboard user-management" id="user-dashboard">
                           <div className="container-fluid">
                              <div className="admin-header">
                                 <div className="row mar0">
                                    <div className="col-md-12">
                                       <h2>Frequently asked questions</h2>
                                       <div className="row">
                                          <div className="pull-left col-md-9 shipping-filter">
                                             <div className="row">
                                                <div className="form-group filter-input">
                                                   <div className="styled-select slate">
                                                      <select className="select-dropdown" value={this.state.filterBy} name="test" onChange={(e) => this.setFilterBy(e)}>
                                                         <option value="Select">Select</option>
                                                         <option value="Sequence">Sequence</option>
                                                         <option value="Question">Question</option>
                                                         <option value="Answer">Answer</option>
                                                      </select>
                                                   </div>
                                                </div>
                                                <div className="form-group filter-input">
                                                   <div className="inner-addon search-input">
                                                      <input type="text" className="form-control input-style search-icon" placeholder="Search" value={this.state.filterData} onChange={this.filterSearch.bind(this)} />
                                                   </div>
                                                </div>
                                                <div className="ref-radio">
                                                   <div className="btn-palce">
                                                      <form className="form">
                                                         <div className="radio-field">
                                                            <input type="radio" id="switch_left" name="English" value="yes" checked={toggleLang === "English" ? true : false} className="switch-lang" onClick={(e) => { this.changeLanguage(e) }} />
                                                            <label htmlFor="switch_left">English</label>
                                                            <input type="radio" id="switch_right" name="Spanish" value="no" checked={toggleLang === "Spanish" ? true : false} className="switch-lang" onClick={(e) => { this.changeLanguage(e) }} />
                                                            <label htmlFor="switch_right">Spanish</label>
                                                         </div>
                                                      </form>

                                                   </div>
                                                </div>
                                                <div className="refresh-btn cust-cref-btn">
                                                   <i onClick={this.resetList.bind(this)} title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>
                                                </div>

                                             </div>
                                          </div>
                                          <div className="pull-right col-md-3  mbl-scn"><button className="giftcard_button add_btnfont pull-right button-padding" onClick={(e) => this.onOpenModal()}>ADD FAQ</button></div>
                                       </div>
                                       <div className="table-responsive">
                                          <table id="example" className="table table-striped table-bordered faq-table" cellSpacing="0" width="100%">
                                             <thead>
                                                <tr>
                                                   <th className=" all_cursorpointer" width="10%">Sequence </th>
                                                   <th className="customer-name-td all_cursorpointer" width="35%">Question</th>
                                                   <th className=" all_cursorpointer" width="35%">Answer </th>
                                                   <th className=" all_cursorpointer" width="10%">Language </th>
                                                   <th className="all_cursorpointer text-center" width="10%">Action</th>
                                                </tr>
                                             </thead>



                                             <tbody>
                                                {list.length > 0 ? list.map(list => (
                                                   <tr>
                                                      <td onClick={(e) => this.editFaq(e, list)}>{list.sequence}</td>
                                                      <td>{list.question}</td>
                                                      <td>{list.answer}</td>
                                                      <td>{list.lang}</td>
                                                      <td className="action-td"><a onClick={(e) => this.editFaq(e, list)} href="#"><i className="fa fa-pencil" style={{ "margin-left": "5px;" }}></i></a>
                                                         <a onClick={(e) => this.deleteFaq(e, list.id)} href="#"><i className="fa fa-trash" style={{ "margin-left": "5px;" }}></i></a>
                                                      </td>
                                                   </tr>
                                                ))
                                                   : <tr><td className="recordNotFound" colSpan="9">No record found</td></tr>
                                                }
                                             </tbody>
                                          </table>

                                          <ul className="pagination pull-right my-1">
                                             <Pagination
                                                hideDisabled
                                                activePage={this.state.pageactive}
                                                itemsCountPerPage={10}
                                                totalItemsCount={filteredDataList.length}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                                firstPageText='First'
                                                lastPageText='Last'
                                                prevPageText="Prev"
                                                nextPageText="Next"
                                             />
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <AddEditFaq checkModal={this.state.open} modalClose={this.status} sequence={this.state.sequence} redirectPage={this.userRedirect} faqObj={this.state.faqDetails}
               locationProps={this.props.componentID} lang={toggleLang}
            />
         </div>
      )
   }
}

const mapStateToProps = (state) => {
   return {
      faqListResponse: state.getFAQList,
      faqDeleteResponse: state.deleteFAQ,
      faqTotalRecordsResponse: state.getFAQTotalRecords
   }
}

export default connect(mapStateToProps, { getFAQListAction, deleteFAQAction, getFAQTotalRecordsAction })(FAQListPage);