const ciamAddressDeleteStateModal = (state)=> {
	try {
		let addresses;
        let pimAddress=[]
            addresses = state.addresses.customerAddress;
            let addressdelete = state.pimaddress.filter(address=>{
               return  address.addressId !== undefined 
            })

            if(addressdelete.length == 1) {
                pimAddress.push({
                    "title":addressdelete[0].addressType,
                    "street":addressdelete[0].address1,
                    "city": addressdelete[0].city,
                    "state": addressdelete[0].state,
                    "zip": addressdelete[0].zip,
                    "country": addressdelete[0].country,
                    "isPrimary":false,
                    "isVerified": false,
                    "source": "gift_card",
                });
            } else {
                addressdelete.map((address,i)=>{
                    if(address.addressId == state.addessId) {
                        pimAddress.push({
                            "title":address.addressType,
                            "street":state.pimaddress[i].address1,
                            "city": address.city,
                            "state": address.state,
                            "zip": address.zip,
                            "country": address.country,
                            "isPrimary":false,
                            "isVerified": false,
                            "source": "gift_card",
                            });
                    } else {
                        let tempAddress = {
                            "title":address.addressType,
                            "street":state.pimaddress[i].address1,
                            "city": address.city,
                            "state": address.state,
                            "zip": address.zip,
                            "country": address.country,
                            "isPrimary": true,
                            "isVerified": false,
                            "source": "gift_card",
                            }
                        pimAddress.push(tempAddress)
                    }
                    
                })
            }
            console.log("addressdelete",addressdelete)
		return ({
		"source": "gift_card",
		"prosperaId": state.ngId,
		"address": {
			"customerAddress": [...pimAddress]
		}
	})
	} catch(err) { console.log("error",err);}
	
}

export default ciamAddressDeleteStateModal ;
