import React from "react"

/** Class for displaying pdf file */
export default class PdfFormat extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      OrderNumber: '',
      OrderDate: '',
      CustomerType: '',
      CustomerName: '',
      Address: '',
      ExpectedDeliveryDate: '',
      ShipmentType: '',
      Amount: '',
      PaymentType: "",
      Status: '',
      tableHeader: [],

    };
  }

  /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
  componentWillMount() {
    this.setState({ tableHeader: this.props.tableHeader })
  }

  render() {

    let tblRowData = []

    var a = this.props.data;

    while (a.length) {
      tblRowData.push((a.splice(0, 15)));
    }
    let tblHeader = this.state.tableHeader.length > 0 && this.state.tableHeader !== undefined ? this.state.tableHeader : []

    return (
      <div className="section ">
        {
          tblRowData.length > 0 ? tblRowData.map((table, i) => {
            return (
              <div key={`section_pdf${i}`} id={`pdfId_${i}`} style={{ width: "700px", height: "auto", display: "block", position: "absolute", top: "0", left: "-9999px" }}>
                <table style={{ width: "100%", wordSpacing: "2px", marginLeft: "10px", marginTop: "10px", verticalAlign: "top", color: "#000", fontFamily: "Helvetica Neue", verticalAlign: "top", border: "0", cellpadding: "0", cellSpacing: "0" }}>
                  <thead>
                    {tblHeader.map((tblHeader,i) => (<td key={`tblHeader__${i}`} style={{ padding: "10px", verticalAlign: "top", border: 'solid .5px #ccc' }}>
                      <p  style={{ fontSize: "10px", margin: "0", lineHeight: "11px", color: "black" }}>{tblHeader}</p>
                    </td>))}
                  </thead>
                  {table.map((tblData,index )=> (
                    <tbody key={`tblData${index}`}>
                      <td style={{ padding: "15px", verticalAlign: "top", border: 'solid .5px #ccc ' }}> <p style={{ fontSize: "10px", margin: "0", lineHeight: "1.5px", color: "black" }}>{tblData["Order Number"]}</p></td>
                      <td style={{ padding: "10px", verticalAlign: "top", border: 'solid .5px #ccc' }}> <p style={{ fontSize: "10px", margin: "0", lineHeight: "11px" }}>{tblData["Customer Name"]}</p></td>
                      <td style={{ padding: "10px", verticalAlign: "top", border: 'solid .5px #ccc' }}> <p style={{ fontSize: "10px", margin: "0", lineHeight: "11px" }}>{tblData["Address"]}</p></td>
                      <td style={{ padding: "10px", verticalAlign: "top", border: 'solid .5px #ccc' }}> <p style={{ fontSize: "10px", margin: "0", lineHeight: "11px" }}>{tblData["Order Date"]}</p></td>
                      <td style={{ padding: "10px", verticalAlign: "top", border: 'solid .5px #ccc' }}><p style={{ fontSize: "10px", margin: "0", lineHeight: "11px" }}>{tblData["Expected Delivery Date"]}</p></td>
                      <td style={{ padding: "10px", verticalAlign: "top", border: 'solid .5px #ccc' }}><p style={{ fontSize: "10px", margin: "0", lineHeight: "11px" }}>{tblData["Shipment Type"]}</p></td>
                      <td style={{ padding: "10px", verticalAlign: "top", border: 'solid .5px #ccc' }}> <p style={{ fontSize: "10px", margin: "0", lineHeight: "11px" }}>{tblData["Amount"]}</p></td>
                      <td style={{ padding: "10px", verticalAlign: "top", border: 'solid .5px #ccc' }}> <p style={{ fontSize: "10px", margin: "0", lineHeight: "11px" }}>{tblData["Payment Type"]}</p></td>
                      <td style={{ padding: "10px", verticalAlign: "top", border: 'solid .5px #ccc' }}><p style={{ fontSize: "10px", margin: "0", lineHeight: "11px" }}>{tblData["Activation Status"]}</p></td>
                    </tbody>
                  ))}
                </table>
              </div>
            )


          }) : void 0

        }
      </div>
    );
  }


}
