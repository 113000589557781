import * as constants from '../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';


/*
* Function for userManagementList and get users list
  @param NULL
  @return object object of api resoponse
*/
export function giftCardListAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.GIFT_CARD_LIST, data)
      .then(function (response) {
        dispatch({
          type: constants.GIFT_CARD_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.GIFT_CARD_LIST_ERROR,
          payload: response
        })
      })
  }
}


/*
* Function for userManagementList and get users list
  @param NULL
  @return object object of api resoponse
*/
export function activateGiftCardAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.ACTIVATE_GIFT_CARDS, data)
      .then(function (response) {
        dispatch({
          type: constants.ACTIVATE_GIFT_CARDS,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.ACTIVATE_GIFT_CARDS_ERROR,
          payload: response
        })
      })
  }
}