import React, { Component } from 'react';
import GiftCardManagements from './giftCardManagement';
import ActivateGiftCardReferencePage from './activateGiftCardReferencePage';

/** Main class for gift card management */
class GiftCardManagement extends Component {
    
    componentDidMount(){
        try{
            if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
              this.props.history.push("/ng-admin");
          } else if(sessionStorage.hasOwnProperty("adminLoggedIn") && sessionStorage.isSuperAdmin != 'true' && sessionStorage.roleName != "Admin" && sessionStorage.roleName != "Fulfillment") {
            this.props.history.push("/ng-admin/error");
          }
          }catch (err){
      
          }
      }


    render() {
        let pathParam = this.props.location.pathname.split("/").pop()
        let GiftCardManagement;
        if (this.props.location.state == undefined) {
            GiftCardManagement = <GiftCardManagements componentID={this.props}
                pathParam={pathParam}
            />
        }
        else if (this.props.location.state.totalGiftCard != undefined || this.props.location.state.totalGiftCard != null) {
            GiftCardManagement = <GiftCardManagements componentID={this.props} totalGiftCard={this.props.location.state.totalGiftCard}
                pathParam={pathParam}
            />
        }
        else if (this.props.location.state.displayOrderInfo != undefined || this.props.location.state.displayOrderInfo != null) {
            var orderInfo = this.props.location.state.displayOrderInfo.orderId
            GiftCardManagement = <ActivateGiftCardReferencePage orderInfo={orderInfo} componentID={this.props} pageProps={this.props}
                pathParam={pathParam}
            />
        }
        return GiftCardManagement;
    }
}
export default GiftCardManagement;
