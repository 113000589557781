import React from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading'
import { viewHomeSettingAction, updateHomeSettingAction } from './../../../Actions/Admin/userManagementAction';
import nouploadimage from '../../../public/img/no-image-uploaded.jpg';
import * as constant from '../../../Constants/';
import ImageUpload from '../DenominationManagement/uploadImage'
import { Alert } from 'reactstrap';
import { CompactPicker } from 'react-color'
import { PhotoshopPicker } from 'react-color'
import { BlockPicker } from 'react-color'
import { SketchPicker } from 'react-color'
import InputMask from 'react-input-mask'
import reactCSS from 'reactcss'
import Helmet from 'react-helmet';


/** Class for displaying home settings*/
class HomeSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeSetting: {
        englishHeader1: '',
        englishHeader1FontSize: '',
        englishHeader1FontColor: '',
        englishHeader2: '',
        englishHeader2FontSize: '',
        englishHeader2FontColor: '',
        spanishHeader1: '',
        spanishHeader1FontSize: '',
        spanishHeader1FontColor: '',
        spanishHeader2: '',
        spanishHeader2FontSize: '',
        spanishHeader2FontColor: '',
        imageName: '',
        imagePath: '',
        settingId: '',
        phone: ''
      },
      preview: false,
      imagebase64: '',
      imageFileName: '',
      editPage: 'homeSetting',
      Loading: false,
      flasSuccessMessage: '',
      flasErrorMessage: '',
      editFlashErrorMessaage: "",
      activeClassName: '',
      fileSizeErrorMessage: "",
      displayEnHead1ColorPicker: false,
      displayEnHead2ColorPicker: false,
      displaySpHead1ColorPicker: false,
      displaySpHead2ColorPicker: false
    }
    this.clearMessages = this.clearMessages.bind(this)
    this.validateObjectToSave = this.validateObjectToSave.bind(this)
    this.showErrormessage = this.showErrormessage.bind(this)
  }

  componentDidMount() {
    if (this.props.updateHomeSetting.data != null) {
      this.props.updateHomeSetting.data = null
    }
  }

  /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
  async componentWillMount() {
    var json = { "language": "en" }
    this.setState({
      Loading: true,
      activeClassName: this.props.pathParam
    })
    await this.props.viewHomeSettingAction(json)
  }

  /** Method for clear all messages*/
  clearMessages() {
    this.setState({
      flasSuccessMessage: '',
      flasErrorMessage: ''
    })
  }

  /**
* React Built method 
* keep waiting for change in props 
* @param {*} nextProps 
*/
  componentWillReceiveProps(nextProps) {
    try {
      let self = this
      if (nextProps.viewHomeSettings && nextProps.viewHomeSettings.data && nextProps.viewHomeSettings.data.error === false) {
        let { data } = nextProps.viewHomeSettings.data.data
        this.setState({
          homeSetting: {
            englishHeader1: data.header1,
            englishHeader2: data.header2,
            spanishHeader1: data.spanishHeader1,
            spanishHeader2: data.spanishHeader2,
            imageName: data.imageName,
            imagePath: data.imagePath,
            settingId: data.settingId,
            spanishHeader1FontSize: data.spanishHeader1FontSize,
            spanishHeader1FontColor: data.spanishHeader1Color ? data.spanishHeader1Color : '',
            spanishHeader2FontSize: data.spanishHeader2FontSize,
            spanishHeader2FontColor: data.spanishHeader2Color ? data.spanishHeader2Color : '',
            englishHeader1FontSize: data.header1FontSize,
            englishHeader2FontSize: data.header2FontSize,
            englishHeader1FontColor: data.header1Color ? data.header1Color : '',
            englishHeader2FontColor: data.header2Color ? data.header2Color : '',
            phone: data.phoneNumber
          },
          checkPointReload: false,
          Loading: false,
        })
        localStorage.setItem('homeSettingData', JSON.stringify({
          englishHeader1: data.header1,
          englishHeader2: data.header2,
          spanishHeader1: data.spanishHeader1,
          spanishHeader2: data.spanishHeader2,
          imageName: data.imageName,
          imagePath: data.imagePath,
          settingId: data.settingId,
          spanishHeader1FontSize: data.spanishHeader1FontSize,
          spanishHeader1FontColor: data.spanishHeader1Color ? data.spanishHeader1Color : '',
          spanishHeader2FontSize: data.spanishHeader2FontSize,
          spanishHeader2FontColor: data.spanishHeader2Color ? data.spanishHeader2Color : '',
          englishHeader1FontSize: data.header1FontSize,
          englishHeader2FontSize: data.header2FontSize,
          englishHeader1FontColor: data.header1Color ? data.header1Color : '',
          englishHeader2FontColor: data.header2Color ? data.header2Color : '',
          phone: data.phoneNumber
        }));
      } else {
        this.setState({ Loading: false, })
      }
      if (nextProps.updateHomeSetting && nextProps.updateHomeSetting.data && nextProps.updateHomeSetting.data.error === false) {
        !Array.isArray(nextProps.updateHomeSetting.data.data.data) ?
          this.setState({
            flasSuccessMessage: nextProps.updateHomeSetting.data.data.message,
            Loading: false
          },
            () => { setTimeout(function () { self.clearMessages() }, 5000) }
          )
          :
          this.setState({
            flasErrorMessage: nextProps.updateHomeSetting.data.data.message,
            Loading: false
          },
            () => { setTimeout(function () { self.clearMessages() }, 5000) }
          )
        if (this.state.checkPointReload) {
          let json = { "language": "en" }
          this.props.viewHomeSettingAction(json)
        }
      } else {
        this.setState({ Loading: false })
      }
    } catch (e) { }
  }

  /**
   *Method for editing home setting 
   * @param {event} event 
   */
  enSetHeader1(event) {
    let value = event.target.value
    let field = event.target.name
    if (field === "header-1") {
      this.setState({
        homeSetting: {
          englishHeader1: value,
          englishHeader2: this.state.homeSetting.englishHeader2,
          spanishHeader1: this.state.homeSetting.spanishHeader1,
          spanishHeader2: this.state.homeSetting.spanishHeader2,
          imageName: this.state.homeSetting.imageName,
          imagePath: this.state.homeSetting.imagePath,
          settingId: this.state.homeSetting.settingId
        }
      })
    }
  }

  /**
  *Method for editing home setting 
  * @param {event} event 
  */
  enSetHeader2(event) {
    let value = event.target.value
    let field = event.target.name
    if (field === "enheader-2") {
      this.setState({
        homeSetting: {
          englishHeader1: this.state.homeSetting.englishHeader1,
          englishHeader2: value,
          spanishHeader1: this.state.homeSetting.spanishHeader1,
          spanishHeader2: this.state.homeSetting.spanishHeader2,
          imageName: this.state.homeSetting.imageName,
          imagePath: this.state.homeSetting.imagePath,
          settingId: this.state.homeSetting.settingId
        }
      })
    }
  }

  /**
  *Method for editing home setting 
  * @param {event} event 
  */
  spSetHeader1(event) {
    let value = event.target.value
    let field = event.target.name
    if (field === "spheader1") {
      this.setState({
        homeSetting: {
          englishHeader1: this.state.homeSetting.englishHeader1,
          englishHeader2: this.state.homeSetting.englishHeader2,
          spanishHeader1: value,
          spanishHeader2: this.state.homeSetting.spanishHeader2,
          imageName: this.state.homeSetting.imageName,
          imagePath: this.state.homeSetting.imagePath,
          settingId: this.state.homeSetting.settingId
        }
      })
    }
  }

  /**
  *Method for editing home setting 
  * @param {event} event 
  */
  spSetHeader2(event) {
    let value = event.target.value
    let field = event.target.name
    if (field === "spheader2") {
      this.setState({
        homeSetting: {
          englishHeader1: this.state.homeSetting.englishHeader1,
          englishHeader2: this.state.homeSetting.englishHeader2,
          spanishHeader1: this.state.homeSetting.spanishHeader1,
          spanishHeader2: value,
          imageName: this.state.homeSetting.imageName,
          imagePath: this.state.homeSetting.imagePath,
          settingId: this.state.homeSetting.settingId
        }
      })
    }
  }

  /***
   * Method for validate object to save 
   * @param {Obeject}
   * @returns {false}
   */
  validateObjectToSave = (object) => {
    let self = this
    if (object.header1 === "" || object.header2 === "" || object.spanishHeader1 === "" || object.spanishHeader2 === "") {
      self.setState({ editFlashErrorMessaage: constant.ALL_FIELDS_ERROR_MESSAGE })
      return false
    }
  }

  /**
   * Method for update new home setting
   * @param {click event} event 
   * 
   */
  saveHomeSetting(eve) {
    let { homeSetting } = this.state
    let self = this
    eve.preventDefault()
    let updateHomeSettingJson = {
      "settingId": `${homeSetting.settingId}`,
      "header1": `${homeSetting.englishHeader1}`.trim(),
      "header2": `${homeSetting.englishHeader2}`.trim(),
      "spanishHeader1": `${homeSetting.spanishHeader1}`.trim(),
      "spanishHeader2": `${homeSetting.spanishHeader2}`.trim(),
      "loggedInUser": "6",
      "language": "en",
      "imageType": "png",
      "userId": constant.PARENT_ID,
      "headerImage": self.state.imagebase64,
      "header1Color": homeSetting.englishHeader1FontColor,
      "header1FontSize": homeSetting.englishHeader1FontSize,
      "header2Color": homeSetting.englishHeader2FontColor,
      "header2FontSize": homeSetting.englishHeader2FontSize,
      "spanishHeader1FontSize": homeSetting.spanishHeader1FontSize,
      "spanishHeader1Color": homeSetting.spanishHeader1FontColor,
      "spanishHeader2FontSize": homeSetting.spanishHeader2FontSize,
      "spanishHeader2Color": homeSetting.spanishHeader2FontColor,
      "phoneNumber": homeSetting.phone !== null ? homeSetting.phone : '',
    }

    if (self.validateObjectToSave(updateHomeSettingJson) === false) {
      setTimeout(function () {
        self.setState({
          editFlashErrorMessaage: ""
        })
      }, 5000)
    } else if (updateHomeSettingJson.phoneNumber === '' || updateHomeSettingJson.phoneNumber.length < 12) {
      self.setState({
        editFlashErrorMessaage: "Please enter Phone Number"
      })
    } else if (updateHomeSettingJson.header1 !== '' && updateHomeSettingJson.header1Color !== '' && updateHomeSettingJson.header1FontSize !== ''
      && updateHomeSettingJson.header2 !== '' &&
      updateHomeSettingJson.header2Color !== '' && updateHomeSettingJson.header2FontSize !== '' && updateHomeSettingJson.spanishHeader1 !== '' && updateHomeSettingJson.spanishHeader1FontSize !== '' &&
      updateHomeSettingJson.spanishHeader1Color !== '' && updateHomeSettingJson.spanishHeader2 !== '' && updateHomeSettingJson.spanishHeader2FontSize !== '' && updateHomeSettingJson.spanishHeader2Color !== '' && updateHomeSettingJson.phoneNumber !== '') {
      self.setState({
        Loading: true,
        checkPointReload: true,
      })
      this.props.updateHomeSettingAction(updateHomeSettingJson)
    } else {
      self.setState({
        editFlashErrorMessaage: "All Fields are mendatory"
      })
    }
  }
  /**
   * Method for Reset All home setting 
   * @param {event}
   */
  resetHomeSetting(event) {
    event.preventDefault();
    try {
      if (localStorage.getItem("homeSettingData") !== null) {
        let defaultData = JSON.parse(localStorage.getItem("homeSettingData"))
        this.setState({
          homeSetting: {
            englishHeader1: defaultData.englishHeader1,
            englishHeader2: defaultData.englishHeader2,
            spanishHeader1: defaultData.spanishHeader1,
            spanishHeader2: defaultData.spanishHeader2,
            imageName: defaultData.imageName,
            imagePath: defaultData.imagePath,
            settingId: defaultData.settingId,
            spanishHeader1FontSize: defaultData.spanishHeader1FontSize,
            spanishHeader1FontColor: defaultData.spanishHeader1FontColor ? defaultData.spanishHeader1FontColor : '',
            spanishHeader2FontSize: defaultData.spanishHeader2FontSize,
            spanishHeader2FontColor: defaultData.spanishHeader2FontColor ? defaultData.spanishHeader2FontColor : '',
            englishHeader1FontSize: defaultData.englishHeader1FontSize,
            englishHeader2FontSize: defaultData.englishHeader2FontSize,
            englishHeader1FontColor: defaultData.englishHeader1FontColor ? defaultData.englishHeader1FontColor : '',
            englishHeader2FontColor: defaultData.englishHeader2FontColor ? defaultData.englishHeader2FontColor : '',
            phone: defaultData.phone
          },
          preview: false,
          imagebase64: ''
        })
      }
    } catch (e) { }
  }

  /**
   * Method for convert image in x64
   * @param {file} file 
   * @param {converBase} cb 
   */
  getBase64(file, cb) {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result)
      };
      reader.onerror = function (error) {
      };
    } catch (e) { }
  }

  /**
   * Method for handle upload image 
   * @param {image Data}
   * 
   */
  handleUploadImage(files) {
    let self = this
    var idCardBase64
    var file = files[0];
    var FileSize = file !== undefined ? file.size / 1024 / 1024 : 0;
    var mimetype
    if (file !== undefined) {
      this.setState({ imageFileName: file.name })
      if (FileSize <= 5) {
        self.getBase64(file, (result) => {
          var base64 = result.split(",");
          idCardBase64 = base64[1]
          mimetype = file.type.split("/")
          self.setState({
            imageType: mimetype[1],
            imagebase64: idCardBase64,
            imageName: '',
            imagePath: '',
            preview: true
          });
        });
      } else {
        alert("File Size exceeds 5 MB")
      }
    }
  }

  /**
   * function for authenticate user is valid or not on submit
   * @param object event
   * @return object userParams
  */
  removeImageHandler = (event) => {
    if (event === undefined) {
      this.setState({ clearImage: false })
    } else {
      event.preventDefault();
      this.setState({
        clearImage: true,
        imageName: ''
      })
    }
  }

  /**
  * Method for handle filesize exceed message error 
  */
  showErrormessage() {
    let self = this
    self.setState({
      fileSizeErrorMessage: "File Size exceeds 5 MB"
    })
    setTimeout(function () {
      self.setState({
        fileSizeErrorMessage: ""
      })
    }, 5000)
  }

  /**
  *Method for Setting values for home setting 
  * @param {event} event 
  */
  setValue = (e) => {
    let key = e.target.name
    let val = e.target.value
    this.setState({
      editFlashErrorMessaage: '',
      flashMessageError: '',
      flashMessageSuccess: ''
    })
    let valid = {};
    if (val === '') {
      valid[key] = '';
    } else {
      valid[key] = val;
    }
    let homeSetting = Object.assign(this.state.homeSetting, valid);
    this.setState({ homeSetting });
  }
  /**
    *Method for Setting englishHeader1FontColor 
    * @param {event} event 
    */
  setHeader1FontColor = (color) => {
    this.setState({
      editFlashErrorMessaage: '',
      flashMessageError: '',
      flashMessageSuccess: ''
    })
    let valid = {};
    valid["englishHeader1FontColor"] = color.hex;
    let homeSetting = Object.assign(this.state.homeSetting, valid);
    this.setState({ homeSetting });
  }
  /**
  *Method for Setting englishHeader2FontColor 
  * @param {event} event 
  */
  setHeader2FontColor = (color) => {
    this.setState({
      editFlashErrorMessaage: '',
      flashMessageError: '',
      flashMessageSuccess: ''
    })
    let valid = {};
    valid["englishHeader2FontColor"] = color.hex;
    let homeSetting = Object.assign(this.state.homeSetting, valid);
    this.setState({ homeSetting });
  }
  /**
  *Method for Setting spanishHeader1FontColor 
  * @param {event} event 
  */
  setSpanishHeader1FontColor = (color) => {
    this.setState({
      editFlashErrorMessaage: '',
      flashMessageError: '',
      flashMessageSuccess: ''
    })
    let valid = {};
    valid["spanishHeader1FontColor"] = color.hex;
    let homeSetting = Object.assign(this.state.homeSetting, valid);
    this.setState({ homeSetting });
  }
  /**
  *Method for Setting spanishHeader2FontColor 
  * @param {event} event 
  */
  setSpanishHeader2FontColor = (color) => {
    this.setState({
      editFlashErrorMessaage: '',
      flashMessageError: '',
      flashMessageSuccess: ''
    })
    let valid = {};
    valid["spanishHeader2FontColor"] = color.hex;
    let homeSetting = Object.assign(this.state.homeSetting, valid);
    this.setState({ homeSetting });
  }

  /**Method to enable english header 1 colour picker */
  handleClickEnHead1Color = () => {
    this.setState({
      displayEnHead1ColorPicker: !this.state.displayEnHead1ColorPicker,
      displayEnHead2ColorPicker: false,
      displaySpHead1ColorPicker: false,
      displaySpHead2ColorPicker: false
    })
  };

  /** Method to close the colour picker  */
  handleCloseEnHead1Color = () => {
    this.setState({ displayEnHead1ColorPicker: false })
  };

  /** Method to open english header 2 colour picker  */
  handleClickEnHead2Color = () => {
    this.setState({
      displayEnHead2ColorPicker: !this.state.displayEnHead2ColorPicker,
      displayEnHead1ColorPicker: false,
      displaySpHead1ColorPicker: false,
      displaySpHead2ColorPicker: false
    })
  };

  /** Method to close the colour picker */
  handleCloseEnHead2Color = () => {
    this.setState({ displayEnHead2ColorPicker: false })
  };

  /** Method to open spanish header 1 colour picker */
  handleClickSpHead1Color = () => {
    this.setState({
      displayEnHead1ColorPicker: false,
      displayEnHead2ColorPicker: false,
      displaySpHead1ColorPicker: !this.state.displaySpHead1ColorPicker,
      displaySpHead2ColorPicker: false
    })
  };

  /** Method to close the colour picker  */
  handleCloseSpHead1Color = () => {
    this.setState({ displaySpHead1ColorPicker: false })
  };

  /** Method to open spanish header 2 colour  picker */
  handleClickSpHead2Color = () => {
    this.setState({
      displayEnHead2ColorPicker: false,
      displayEnHead1ColorPicker: false,
      displaySpHead1ColorPicker: false,
      displaySpHead2ColorPicker: !this.state.displaySpHead2ColorPicker
    })
  };

  /**Method to close the colour picker */
  handleCloseSpHead2Color = () => {
    this.setState({ displaySpHead2ColorPicker: false })
  };

  styleButton = (color) => {
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: color,
          float: 'right',

        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return styles
  }

  render() {
    let { homeSetting } = this.state
    return (
      <div className="admin_top">
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }
        <div id="content-wrapper" className="d-flex flex-column">
          <Header />
          <div className="main">
            <Navigation activeClassName={this.state.activeClassName} />
            <div className="dashboardContent">
              <div className="container-fluid">
                <div className="admin-header">
                  {this.state.flasSuccessMessage !== "" ?
                    <Alert className='alertFont'> {this.state.flasSuccessMessage} </Alert>
                    : ''
                  }
                  {
                    this.state.flasErrorMessage !== "" ?
                      <Alert className='alertFont' color="danger">{this.state.flasErrorMessage}</Alert>
                      : ''
                  }
                  {
                    this.state.editFlashErrorMessaage !== "" ?
                      <Alert color="danger" >{this.state.editFlashErrorMessaage}</Alert> :
                      ""
                  }
                  {this.state.fileSizeErrorMessage !== "" ?
                    <Alert className='alertFont' color="danger" >{this.state.fileSizeErrorMessage}</Alert> : void 0
                  }
                  <Helmet>
                      <title>Home settings</title>
                  </Helmet>
                      <div className="user-dashboard user-management" id="user-dashboard">
                    <div className="row mar0">
                      <div className="col-md-12">
                        <h2>home settings</h2>
                        <div className="row">
                          <div className="pull-left col-md-12 shipping-filter">
                            <div className="row">
                              <div className="col-md-12 ">
                                <div container className="form-group">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group position-relative">
                                        <label className="pull-left home-setting-label">Background image</label>
                                        <div className="new-drag-n-drop">
                                          <div className="dragImg">
                                            <div className="dragImg-after">
                                              {this.state.preview ?
                                                <img src={`data:image/jpeg;base64,${this.state.imagebase64}`}
                                                  height="30" width="30" alt="" /> :
                                                <img src={homeSetting.imageName ? constant.APIURL + homeSetting.imagePath + homeSetting.imageName : nouploadimage}
                                                  height="30" width="30" alt="" />
                                              }
                                            </div>
                                            <p className="dragPhoto">Drag Photo</p>
                                            <i className="drag-img-area">
                                              <b className="drag-img-inner">
                                                <ImageUpload uploadImages={this.handleUploadImage.bind(this)} name='image'
                                                  clearImageHandler={this.removeImageHandler}
                                                  clearImage={this.state.clearImage}
                                                  clear
                                                  homeSetting={homeSetting}
                                                  erorMessage={this.showErrormessage}
                                                /></b></i>
                                            <div className="btn upload-btn-wrapper btn-sm">
                                              <i className="click-to-upload"><button className="giftcard_button cardnone_button Denomination-btn">Upload</button>
                                                <ImageUpload type="file"
                                                  uploadImages={this.handleUploadImage.bind(this)} name='image'
                                                  clearImageHandler={this.removeImageHandler}
                                                  clearImage={this.state.clearImage}
                                                  clear
                                                  homeSetting={homeSetting}
                                                  erorMessage={this.showErrormessage}
                                                /></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="lang-en-section language home-setting">
                              <fieldset>
                                <div className="lang-en">English</div>
                                <form className="form-horizontal" action="">
                                  <div className="form-group row">
                                    <label className="control-label col-md-2 text-center" htmlFor="">Title 1:</label>
                                    <div className="col-md-10">
                                      <input type="text" className="form-control" id="" placeholder="Enter " name="englishHeader1" value={homeSetting.englishHeader1} onChange={(e) => { this.setValue(e) }} />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="control-label col-md-2 text-center" htmlFor="">Font Size:</label>
                                    <div className="col-md-4">
                                      <select className="form-control" value={homeSetting.englishHeader1FontSize} name="englishHeader1FontSize" onChange={this.setValue.bind(this)}>
                                        <option value="">Select Font Size</option>
                                        <option value="12">12</option>
                                        <option value="14">14</option>
                                        <option value="16">16</option>
                                        <option value="18">18</option>
                                        <option value="20">20</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="34">34</option>
                                        <option value="36">36</option>
                                        <option value="38">38</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="44">44</option>
                                        <option value="46">46</option>
                                        <option value="48">48</option>
                                        <option value="50">50</option>
                                        <option value="52">52</option>
                                        <option value="54">54</option>
                                        <option value="56">56</option>
                                        <option value="58">60</option>
                                        <option value="60">60</option>
                                        <option value="62">62</option>
                                        <option value="64">64</option>
                                        <option value="66">66</option>
                                        <option value="68">68</option>
                                        <option value="70">70</option>
                                        <option value="72">72</option>
                                      </select>
                                    </div>
                                    <label className="control-label col-md-2 text-center" htmlFor="">Font Color:</label>
                                    <div className="col-md-4" title="Choose font color">

                                      <div style={this.styleButton(homeSetting.englishHeader1FontColor).swatch} onClick={this.handleClickEnHead1Color}>
                                        <span className="color-span">{homeSetting.englishHeader1FontColor}</span>
                                        <div style={this.styleButton(homeSetting.englishHeader1FontColor).color} />
                                      </div>
                                      {this.state.displayEnHead1ColorPicker ? <div style={this.styleButton(homeSetting.englishHeader1FontColor).popover}>
                                        <div style={this.styleButton(homeSetting.englishHeader1FontColor).cover} onClick={this.handleCloseEnHead1Color} />
                                        <SketchPicker color={homeSetting.englishHeader1FontColor} onChange={this.setHeader1FontColor} />
                                      </div> : null}

                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="control-label col-md-2 text-center" htmlFor="">title 2:</label>
                                    <div className="col-md-10">
                                      <input type="text" className="form-control" id="" placeholder="Enter " name="englishHeader2" value={this.state.homeSetting.englishHeader2}
                                        onChange={(e) => { this.setValue(e) }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="control-label col-md-2 text-center" htmlFor="">Font Size:</label>
                                    <div className="col-md-4">
                                      <select className="form-control" value={this.state.homeSetting.englishHeader2FontSize} name="englishHeader2FontSize" onChange={this.setValue.bind(this)}>
                                        <option value="">Select Font Size</option>
                                        <option value="12">12</option>
                                        <option value="14">14</option>
                                        <option value="16">16</option>
                                        <option value="18">18</option>
                                        <option value="20">20</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="34">34</option>
                                        <option value="36">36</option>
                                        <option value="38">38</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="44">44</option>
                                        <option value="46">46</option>
                                        <option value="48">48</option>
                                        <option value="50">50</option>
                                        <option value="52">52</option>
                                        <option value="54">54</option>
                                        <option value="56">56</option>
                                        <option value="58">60</option>
                                        <option value="60">60</option>
                                        <option value="62">62</option>
                                        <option value="64">64</option>
                                        <option value="66">66</option>
                                        <option value="68">68</option>
                                        <option value="70">70</option>
                                        <option value="72">72</option>
                                      </select>
                                    </div>
                                    <label className="control-label col-md-2 text-center" htmlFor="">Font Color:</label>
                                    <div className="col-md-4" title="Choose font color">
                                      <div style={this.styleButton(homeSetting.englishHeader2FontColor).swatch} onClick={this.handleClickEnHead2Color}>
                                        <span className="color-span">{homeSetting.englishHeader2FontColor}</span>
                                        <div style={this.styleButton(homeSetting.englishHeader2FontColor).color} />
                                      </div>
                                      {this.state.displayEnHead2ColorPicker ? <div style={this.styleButton(homeSetting.englishHeader2FontColor).popover}>
                                        <div style={this.styleButton(homeSetting.englishHeader2FontColor).cover} onClick={this.handleCloseEnHead2Color} />
                                        <SketchPicker color={homeSetting.englishHeader1FontColor} onChange={this.setHeader2FontColor} />
                                      </div> : null}

                                    </div>
                                  </div>

                                </form>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="lang-sp-section language home-setting">
                              <fieldset>
                                <div className="lang-en">Spanish </div>
                                <form className="form-horizontal" action="">
                                  <div className="form-group row">
                                    <label className="control-label col-md-2 text-center" htmlFor="">title 1:</label>
                                    <div className="col-md-10">
                                      <input type="text" className="form-control" id="" placeholder="Enter " name="spanishHeader1" value={this.state.homeSetting.spanishHeader1}
                                        onChange={(e) => { this.setValue(e) }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="control-label col-md-2 text-center" htmlFor="">Font Size:</label>
                                    <div className="col-md-4">
                                      <select className="form-control" value={this.state.homeSetting.spanishHeader1FontSize} name="spanishHeader1FontSize" onChange={this.setValue.bind(this)}>
                                        <option value="">Select Font Size</option>
                                        <option value="12">12</option>
                                        <option value="14">14</option>
                                        <option value="16">16</option>
                                        <option value="18">18</option>
                                        <option value="20">20</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="34">34</option>
                                        <option value="36">36</option>
                                        <option value="38">38</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="44">44</option>
                                        <option value="46">46</option>
                                        <option value="48">48</option>
                                        <option value="50">50</option>
                                        <option value="52">52</option>
                                        <option value="54">54</option>
                                        <option value="56">56</option>
                                        <option value="58">60</option>
                                        <option value="60">60</option>
                                        <option value="62">62</option>
                                        <option value="64">64</option>
                                        <option value="66">66</option>
                                        <option value="68">68</option>
                                        <option value="70">70</option>
                                        <option value="72">72</option>
                                      </select>
                                    </div>
                                    <label className="control-label col-md-2 text-center" htmlFor="">Font Color:</label>
                                    <div className="col-md-4" title="Choose font color">

                                      <div style={this.styleButton(homeSetting.spanishHeader1FontColor).swatch} onClick={this.handleClickSpHead1Color}>
                                        <span className="color-span">{homeSetting.spanishHeader1FontColor}</span>
                                        <div style={this.styleButton(homeSetting.spanishHeader1FontColor).color} />
                                      </div>
                                      {this.state.displaySpHead1ColorPicker ? <div style={this.styleButton(homeSetting.spanishHeader1FontColor).popover}>
                                        <div style={this.styleButton(homeSetting.spanishHeader1FontColor).cover} onClick={this.handleCloseSpHead1Color} />
                                        <SketchPicker color={homeSetting.spanishHeader1FontColor} onChange={this.setSpanishHeader1FontColor} />
                                      </div> : null}

                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="control-label col-md-2 text-center" htmlFor="">title 2:</label>
                                    <div className="col-md-10">
                                      <input type="text" className="form-control" id="" placeholder="Enter " name="spanishHeader2" value={this.state.homeSetting.spanishHeader2}
                                        onChange={(e) => { this.setValue(e) }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="control-label col-md-2 text-center" htmlFor="">Font Size:</label>
                                    <div className="col-md-4">
                                      <select className="form-control" value={this.state.homeSetting.spanishHeader2FontSize} name="spanishHeader2FontSize" onChange={this.setValue.bind(this)}>
                                        <option value="">Select Font Size</option>
                                        <option value="12">12</option>
                                        <option value="14">14</option>
                                        <option value="16">16</option>
                                        <option value="18">18</option>
                                        <option value="20">20</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="34">34</option>
                                        <option value="36">36</option>
                                        <option value="38">38</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="44">44</option>
                                        <option value="46">46</option>
                                        <option value="48">48</option>
                                        <option value="50">50</option>
                                        <option value="52">52</option>
                                        <option value="54">54</option>
                                        <option value="56">56</option>
                                        <option value="58">60</option>
                                        <option value="60">60</option>
                                        <option value="62">62</option>
                                        <option value="64">64</option>
                                        <option value="66">66</option>
                                        <option value="68">68</option>
                                        <option value="70">70</option>
                                        <option value="72">72</option>
                                      </select>
                                    </div>
                                    <label className="control-label col-md-2 text-center" htmlFor="">Font Color:</label>
                                    <div className="col-md-4" title="Choose font color">

                                      <div style={this.styleButton(homeSetting.spanishHeader2FontColor).swatch} onClick={this.handleClickSpHead2Color}>
                                        <span className="color-span">{homeSetting.spanishHeader2FontColor}</span>
                                        <div style={this.styleButton(homeSetting.spanishHeader2FontColor).color} />
                                      </div>
                                      {this.state.displaySpHead2ColorPicker ? <div style={this.styleButton(homeSetting.spanishHeader2FontColor).popover}>
                                        <div style={this.styleButton(homeSetting.spanishHeader2FontColor).cover} onClick={this.handleCloseSpHead2Color} />
                                        <SketchPicker color={homeSetting.spanishHeader2FontColor} onChange={this.setSpanishHeader2FontColor} />
                                      </div> : null}

                                    </div>
                                  </div>
                                </form>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="lang-sp-section language home-setting">
                              <fieldset>
                                <div className="lang-en">Admin Phone No </div>
                                <form className="form-horizontal" action="">
                                  <div className="form-group row">
                                    <label className="control-label col-md-2 text-center" htmlFor="">Phone No:</label>
                                    <div className="col-md-10">
                                      <InputMask mask={"999-999-9999"} maskChar={null} value={this.state.homeSetting.phone} className="form-control" id="" placeholder="Enter your Phone No " name="phone" onChange={this.setValue.bind(this)} />
                                    </div>
                                  </div>

                                </form>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                        <div className="buttons mtg20 pull-right">
                          <button type="button" className="giftcard_button mgr10" onClick={(eve) => { this.saveHomeSetting(eve) }}>Save</button>
                          <button type="button" className="giftcard_button cardnone_button " onClick={(e) => { this.resetHomeSetting(e) }}  >reset</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    viewHomeSettings: state.viewHomeSettings,
    updateHomeSetting: state.updateHomeSetting
  };
}

export default connect(mapStateToProps, { viewHomeSettingAction, updateHomeSettingAction })(HomeSettingsPage);