import { combineReducers } from 'redux';
import { userLogin } from './Admin/userLoginManagement';
import { userList, addUser, getRoles, updateUserStatus, updateUser, changePassword, navigationToggleClass,sendResetPwdLink,updateUserPwd,updateHomeSetting } from './Admin/userManagement';
import { customerList, unlockCustomer, resetCustomerPWD,updateCustomerDetails } from './Admin/customerManagement'
import { customerRegister, customerLogin,searchAndCreateUser,sendOtp,verifyOtp,viewHomeSettings, updateNumberForOTP,otpVerify } from './Customer/customerHomeManagement';
import { customerProfile, changeCustomerPassword, updateCustomerProfile, resetCustomerPassword, activateCustomer, addCustomerAddress, updateCustomerAddress, deleteCustomerAddress, updateAddressStatus,serachCIAMuser,updatePrimaryPhoneCIAM,updateAddressCIAM,searchCIAMUserById } from './Customer/customerProfileManagement'
import { discountList, addDiscount, editDiscount, deleteDiscount, paymentTypes } from './Admin/discountManagement'
import { shippmentList, addShippment, editShippment, deleteShippment, shippmentTypes } from './Admin/shippmentManagement'
import { denominationList, cardTypeList, addDenomination, updateDenomination, deleteDenomination, deleteDenominationImage } from './Admin/denominationManagement'
import {addToCart,createOrder} from './Customer/giftCardManagement';
import {orderList,orderDetails,acknowledgeOrder,sendShippingDetails,cancelOrder,getCustomerOrderList} from './Admin/orderManagementReducer';
import {giftCardList,activateGiftCards} from './Admin/giftCardManagement';
import {dashboardOrderList, pendingOrderList, cancelledOrderList, activatedGiftCardList, pendingGiftCardList} from './Admin/dashboardManagement';
import {getFAQList,createFAQ,updateFAQ,getFAQDetails,deleteFAQ, getFAQTotalRecords} from './Admin/faqManagement';
const rootReducer = combineReducers({
    userLogin, userList, addUser, getRoles,
    updateUserStatus, customerRegister, customerLogin, customerList, unlockCustomer,
    resetCustomerPWD, updateUser, changePassword, customerProfile, changeCustomerPassword, updateCustomerProfile, resetCustomerPassword, discountList,
    activateCustomer, addCustomerAddress,
    updateCustomerAddress, deleteCustomerAddress,updateAddressStatus,  
    shippmentList, addShippment, editShippment, deleteShippment, shippmentTypes,
    denominationList, cardTypeList, addDenomination, updateDenomination, deleteDenomination,deleteDenominationImage,
    addDiscount, editDiscount, deleteDiscount, paymentTypes,updateCustomerDetails, addToCart,createOrder,
    orderList,orderDetails,acknowledgeOrder,sendShippingDetails,cancelOrder,giftCardList,getCustomerOrderList,activateGiftCards, 
    dashboardOrderList, pendingOrderList, cancelledOrderList, activatedGiftCardList, pendingGiftCardList, navigationToggleClass,
    searchAndCreateUser,sendOtp,verifyOtp,sendResetPwdLink,updateUserPwd,viewHomeSettings,updateHomeSetting,
    getFAQList,createFAQ,updateFAQ,getFAQDetails,deleteFAQ, getFAQTotalRecords, updateNumberForOTP,serachCIAMuser,otpVerify,updatePrimaryPhoneCIAM,
    updateAddressCIAM,searchCIAMUserById

});

export default rootReducer;
