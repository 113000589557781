import * as constants from './../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';

/*
* Function for get customer profile data
  @param NULL
  @return object object of api resoponse
*/
export function customerProfileAction(userId) {
  return (dispatch) => {
    axios.post(serverRoutes.CUSTOMER_PROFILE, {
      userId
    })
      .then(function (response) {
        dispatch({
          type: constants.CUSTOMER_PROFILE_INFO,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CUSTOMER_PROFILE_INFO_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function to change password for customer
  @param object object of user
  @return object object of api resoponse
*/
export function changeCustomerPasswordAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.CUSTOMER_CHANGE_PASSWORD, userData)
      .then(function (response) {
        dispatch({
          type: constants.CUSTOMER_CHANGE_PASSWORD,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CUSTOMER_CHANGE_PASSWORD_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function to update customer profile
  @param object object of user
  @return object object of api resoponse
*/
export function updateCustomerProfileAction(userData,ngId="") {
  return (dispatch) => {
   
    axios.post(serverRoutes.CUSTOMER_UPDATE_PROFILE, userData)
      .then(function (response) {
        if(ngId!==""){
          const CIAM_NAME_DATA = {
           
              "source":"gift_card",
              "prosperaId":ngId,
              "isUpdateAllKeys":true,
              "firstName": userData.firstName, //lastName
              "lastName": userData.lastName
            
          }
          axios.post(serverRoutes.REGISTER_AND_UPDATE,CIAM_NAME_DATA)
          .then(response=>console.log("respomse",response))
          .catch(error=>console.log("error",error))
        }
        dispatch({
          type: constants.CUSTOMER_PROFILE_UPDATE,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CUSTOMER_PROFILE_UPDATE_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function to activate customer
  @param object object of user
  @return object object of api resoponse
*/
export function activateCustomerAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.ACTIVATE_CUSTOMER, userData)
      .then(function (response) {
        dispatch({
          type: constants.ACTIVATE_CUSTOMER_DATA,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.ACTIVATE_CUSTOMER_DATA_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Function for add new customer address 
 * @param customer address data object
 * @return response data object
 */
export function addCustomerAddressAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.ADD_CUSTOMER_ADDRESS, userData)
      .then(function (response) {
        dispatch({
          type: constants.CUSTOMER_ADDRESS_DATA,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CUSTOMER_ADDRESS_DATA_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Function for add new customer address 
 * @param customer address data object
 * @return response data object
 */
export function updateCustomerAddressAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.UPDATE_CUSTOMER_ADDRESS, userData)
      .then(function (response) {
        dispatch({
          type: constants.UPDATE_CUSTOMER_ADDRESS_DATA,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.UPDATE_CUSTOMER_ADDRESS_DATA_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Method for add new customer address 
 * @param customer address data object
 * @return response data object
 */
export function deleteCustomerAddressAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.DELETE_CUSTOMER_ADDRESS, userData)
      .then(function (response) {
        dispatch({
          type: constants.DELETE_CUSTOMER_ADDRESS_DATA,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.DELETE_CUSTOMER_ADDRESS_DATA_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Method for add new customer address 
 * @param customer address data object
 * @return response data object
 */
export function updateAddressStatusAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.UPDATE_ADDRESS_STATUS, userData)
      .then(function (response) {
        dispatch({
          type: constants.UPDATE_ADDRESS_STATUS_DATA,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.UPDATE_ADDRESS_STATUS_DATA_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Method to update customet phone number in CIAM
 * @param {*} phone phone number to update
 */
export function updatePrimaryPhoneCIAMAction(phone){
  return (dispatch) =>{
    
    axios.post(serverRoutes.REGISTER_AND_UPDATE,phone)
    .then(function (resoponse){
      dispatch({
        type:constants.UPDATE_PRIMARY_PHONE_CIAM,
        payload:resoponse.data
      })
    })
    .catch(function(resoponse){
      dispatch({
        type:constants.UPDATE_PRIMARY_PHONE_CIAM_ERROR,
        payload:resoponse.data
      })
    })
  }
}



/**
 * Method to update customer Address in CIAM
 * @param {addressData} 
 */
export function updateUserAddressCIAMAction(data){
  return (dispatch) =>{
    
    axios.post(serverRoutes.REGISTER_AND_UPDATE,data)
    .then(function (resoponse){
      dispatch({
        type:constants.UPDATE_ADDRESS_CIAM,
        payload:resoponse.data
      })
    })
    .catch(function(resoponse){
      
      dispatch({
        type:constants.UPDATE_ADDRESS_CIAM_ERROR,
        payload:resoponse.data
      })
    })
  }
}



/**
 * Method to update customer Address in CIAM
 * @param {addressData} 
 */
export function searchUserByIdAction(data){
  return (dispatch) =>{
    
    axios.get(serverRoutes.SEARCH_USER_BY_ID,{
        params:{
          prosperId: data
        }})
    .then(function (resoponse){
      dispatch({
        type:constants.SEARCH_USER_BY_ID,
        payload:resoponse.data
      })
    })
    .catch(function(resoponse){
      
      dispatch({
        type:constants.SEARCH_USER_BY_ID_ERROR,
        payload:resoponse.data
      })
    })
  }
}


