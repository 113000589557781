import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
  isSubmitting: false,
  data: null,
  login: false
}

/**
 * Function for get shippment list
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function shippmentList(state = data, action) {
  switch (action.type) {
    case constants.SHIPPMENT_MANAGEMENT_DATA:
      return { ...state, data: action.payload };
    case constants.SHIPPMENT_MANAGEMENT_DATA_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for add shippment
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function addShippment(state = data, action) {
  switch (action.type) {
    case constants.ADD_SHIPPMENT:
      return { ...state, data: action.payload };
    case constants.ADD_SHIPPMENT_ERROR:
      return { ...state, data: action.payload };
    case constants.REMOVE_SHIPPMENT:
      console.log('m aa gya')
      return null;
    default:
      return state;
  }
}

/**
 * Function for edit shippment
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function editShippment(state = data, action) {
  switch (action.type) {
    case constants.EDIT_SHIPPMENT:
      return { ...state, data: action.payload };
    case constants.EDIT_SHIPPMENT_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for delete shippment
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function deleteShippment(state = data, action) {
  switch (action.type) {
    case constants.DELETE_SHIPPMENT:
      return { ...state, data: action.payload };
    case constants.DELETE_SHIPPMENT_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for get payment types for shippment
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function shippmentTypes(state = data, action) {
  switch (action.type) {
    case constants.SHIPPMENT_TYPE:
      return { ...state, data: action.payload };
    case constants.SHIPPMENT_TYPE_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

