import * as constants from '../../Constants';
let data = {
    isSubmitting: false,
    data: null,
    login: false
}

/**
 * function for manage state and return new state in to store
 * @param object state, action
 * @return object state
*/
export function userLogin(state = data, action) {
    switch (action.type) {
        case constants.LOGIN_DATA:
            return { ...state, isSubmitting: true, login: true, data: action.payload };
        case constants.LOGIN_DATA_ERROR:
            return { ...state, isSubmitting: true, login: false, data: action.payload };
        default:
            return state;
    }
}
