import React, { Component } from 'react';
import CustomerManagements from './customerManagement';
import CustomerManagementViewPage from './customerDetailsViewPage'
import * as constant from '../../../Constants'

/**Main claas for Customer Manegement */
class CustomerManagement extends Component {

  componentDidMount(){
     try{
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.componentID.history.push("/ng-admin");
    } else if(sessionStorage.roleID != constant.ADMIN_USER && sessionStorage.isSuperAdmin != true) {
      this.props.history.push("/ng-admin/error");
    }
    }catch (err){

    }
  }

  render() {
    let pathParam = this.props.location.pathname.split("/").pop()

    var CustomerManagement;
    if (this.props.location.state == undefined) {
      CustomerManagement = <CustomerManagements componentID={this.props} 
      pathParam = {pathParam}
      />
    } else if (this.props.location.state.displayCustomerInfo != undefined || this.props.location.state.displayCustomerInfo != null) {
      var customerInfo = this.props.location.state.displayCustomerInfo
      CustomerManagement = <CustomerManagementViewPage customerInfo={customerInfo} componentID={this.props}
      pathParam = {pathParam}      
      />
    }  else if (this.props.location.state == 'edit') {
      CustomerManagement = <div>this is Edit page</div>
    } else {
      CustomerManagement = <div>this is index page</div>
    }
    return (CustomerManagement);
  }

}
export default CustomerManagement;
