import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
  isSubmitting: false,
  data: null,
  login: false
}

/**
 * Function for get users list
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function giftCardList(state = data, action) {
  switch (action.type) {
    case constants.GIFT_CARD_LIST:
      return { ...state, data: action.payload };
    case constants.GIFT_CARD_LIST_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to activate gift cards
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function activateGiftCards(state = data, action) {
  switch (action.type) {
    case constants.ACTIVATE_GIFT_CARDS:
      return { ...state, data: action.payload };
    case constants.ACTIVATE_GIFT_CARDS_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}