import React, { Component } from "react";
import Header from "../Layout/Header";
import addressBorder from "../../../public/img/address-borderImg.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  shippmentListAction,
  shippmentTypeAction,
} from "../../../Actions/Admin/shippingManagementAction";
import { discountListAction } from "../../../Actions/Admin/discountManagementAction";
import { connect } from "react-redux";
import pencil from "../../../public/img/pencil.png";
import _ from "lodash";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import moment from "moment";
import { customerProfileAction } from "../../../Actions/Customer/customerProfileAction";
import * as constant from "../../../Constants/";
import * as HelperUtil from "../../../utils/common-helper";
import noImage from "../../../public/img/noImageShipPage.png";
import InputMask from "react-input-mask";
import { customerListAction } from "../../../Actions/Admin/customerManagementAction";
import axios from "axios";
import ReactLoading from "react-loading";
import { GOOGLE_API, GOOGLE_API_KEY } from "../../../Constants";
import * as language from "../../../Constants/translations";
import errorimg from "../../../public/img/error.png";
import ReactModal from "react-modal";
import Consent from "../Home/consent";
// Class for displaying shippment details page for placing order
class ShippmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipnigDate: new Date().setDate(new Date().getDate() + 9),
      shippmentList: [],
      minDate: new Date(),
      ETA: new Date().setDate(new Date().getDate() + 4),
      discountList: [],
      openModal: false,
      otherAddress: {
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      address1: {
        id: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        status: "",
        addresstype: "",
      },
      address2: {
        id: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        status: "",
        addresstype: "",
      },
      address3: {
        id: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        status: false,
        addresstype: "Other",
      },
      shippingAddress: {
        id: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        addresstype: "",
      },
      homeClassActive: false,
      officeClassActive: false,
      otherClassActive: false,
      cardListDetails: "",
      addressFlashMessageError: "",
      guestUserDetails: {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
      },
      flashMessageError: "",
      nameInputMask: "aaaaaaaaaaaaaaa",
      customerList: [],
      Loading: false,
      lang:
        sessionStorage.language != undefined ? sessionStorage.language : "en",
      zipInvalid: false,
      fontSize: {
        mainCost: 24,
        itemPriceCart: 16,
        mediaheading: 12,
        breadcrumb: 12,
        giftCardText: 24,
        costSectionPrice: 20,
        subTotal: 14,
        shippinglabel: 14,
        fulfill: 12,
      },
      increaseCounter: 0,
      decreaseCounter: 0,
      addressStatus: false,
      editOtherAddress: false,
      shippmentTypes: [],
      defaultShippmentType: true,
      defaultShippment: "fedex ground",
      flashShippingErrorMessage: "",
      userExistErrorMessage: "",
      shippmentOption: "",
      checkpoint: true,
      validAddressFields: {
        street: "",
        zip: "",
        city: "",
        state: "",
        country: "",
      },
      isFieldsEmpty: false,
      recipientDetailsValidObj: {},
      shippmentDefaultOption: true,
      isDisabled: false,
      etaDefault: true,
      willFirst: true,
      shippignAddressType: "",
      increasemove: 4,
      decreasemove: 4,
      loading1: false,
      consent: 1,
      policyModalopen: false,
    };
    this.setShippingAddress = this.setShippingAddress.bind(this);
    this.breadCrumbCLick = this.breadCrumbCLick.bind(this);
  }
  breadcrumb;

  /**
   * function to set the valid till date
   *
   */
  setShippingDate(date) {
    arguments[1].preventDefault();
    this.setState({
      shipnigDate:
        date >= new Date(new Date().setDate(new Date().getDate() + 9))
          ? date
          : new Date(new Date().setDate(new Date().getDate() + 9)),
    });
  }

  async componentWillMount() {
    try {
      this.setState({
        cardListDetails: this.props.cardListDetails,
        recipientDetailsValidObj:
          localStorage.getItem("guestUserDetails") !== null &&
          localStorage.getItem("guestUserDetails") !== "undefined"
            ? JSON.parse(localStorage.guestUserDetails)
            : { ...this.state.guestUserDetails, ...this.state.shippingAddress },
        isFieldsEmpty: false,
        consent:
          localStorage.getItem("guestUserDetails") !== null &&
          localStorage.getItem("guestUserDetails") !== "undefined"
            ? JSON.parse(localStorage.getItem("guestUserDetails")).consent
            : 1,
        address3:
          localStorage.UserOtherAddress !== undefined
            ? JSON.parse(localStorage.UserOtherAddress)
            : this.state.address3,
        otherAddress:
          localStorage.UserOtherAddress !== undefined
            ? JSON.parse(localStorage.UserOtherAddress)
            : this.state.otherAddress,
      });
      var loggedInUserId = sessionStorage.userID;
      if (sessionStorage.userID != "") {
        this.props.customerProfileAction(sessionStorage.userID);
      }
      this.setState({ Loading: true, loading1: true });
      await this.props.customerListAction(loggedInUserId);
      await this.props.shippmentListAction(loggedInUserId);
      await this.props.discountListAction(loggedInUserId);

      await this.props.shippmentTypeAction(loggedInUserId);
    } catch (e) {
      console.log("error", e);
    }
  }
  componentWillUnmount(props) {
    try {
      this.props.customerList.data = null;
    } catch (e) {
      console.log("erorr", e);
    }
  }

  componentDidMount() {
    this.setShippmentAddress();
  }

  /**
   * Method for Setting UI for Shipping Address
   */
  setShippmentAddress() {
    try {
      if (
        sessionStorage.userID != 0 &&
        sessionStorage.userID != undefined &&
        sessionStorage.loggedIn !== ""
      ) {
        let self = this;
        var btnContainer = document.getElementById("myDIV");
        var btns = btnContainer.getElementsByClassName("col-md-4 col-md");
        for (var i = 0; i < btns.length; i++) {
          btns[i].addEventListener("click", function(e) {
            e.preventDefault();
            let UserOtherAddress =
              localStorage.UserOtherAddress !== undefined
                ? JSON.parse(localStorage.UserOtherAddress)
                : "";
            if (UserOtherAddress !== "") {
              UserOtherAddress.status =
                this.title === "address3" ? true : false;
              localStorage.UserOtherAddress = JSON.stringify(UserOtherAddress);
              self.setState({
                address3: JSON.parse(localStorage.UserOtherAddress),
              });
            }
            var current = document.getElementsByClassName("active");
            if (current[2] != undefined) {
              current[2].className = current[2].className.replace(
                " active",
                ""
              );
              self.setState({ addressStatus: true });
            }
            if (current[4] != undefined) {
              current[4].className = current[4].className.replace(
                " active",
                ""
              );
            }
            if (current[3] != undefined) {
              current[3].className = current[3].className.replace(
                " active",
                ""
              );
              self.setState({ addressStatus: true });
            }
            this.childNodes[0].className += " active";

            if (
              current[2].parentElement.title == "address1" ||
              this.title == "address1"
            ) {
              self.setState({
                shippingAddress: {
                  id: self.state.address1.id,
                  street: self.state.address1.street,
                  city: self.state.address1.city,
                  state: self.state.address1.state,
                  zip: self.state.address1.zip,
                  country: self.state.address1.country,
                  status: self.state.address1.status,
                  addresstype: self.state.address1.addresstype,
                },
              });
              Object.assign(self.state.cardListDetails, {
                shippingAddress: {
                  id: self.state.address1.id,
                  street: self.state.address1.street,
                  city: self.state.address1.city,
                  state: self.state.address1.state,
                  zip: self.state.address1.zip,
                  country: self.state.address1.country,
                  status: self.state.address1.status,
                  addresstype: self.state.address1.addresstype,
                },
              });
            }
            if (
              current[2].parentElement.title == "address2" ||
              this.title == "address2"
            ) {
              self.setState({
                shippingAddress: {
                  id: self.state.address2.id,
                  street: self.state.address2.street,
                  city: self.state.address2.city,
                  state: self.state.address2.state,
                  zip: self.state.address2.zip,
                  country: self.state.address2.country,
                  status: self.state.address2.status,
                  addresstype: self.state.address2.addresstype,
                },
              });
              Object.assign(self.state.cardListDetails, {
                shippingAddress: {
                  id: self.state.address2.id,
                  street: self.state.address2.street,
                  city: self.state.address2.city,
                  state: self.state.address2.state,
                  zip: self.state.address2.zip,
                  country: self.state.address2.country,
                  addresstype: self.state.address2.addresstype,
                },
              });
            } else if (
              current[2].title == "address3" ||
              this.title == "address3"
            ) {
              self.setState({
                shippingAddress: {
                  id:
                    self.state.address3.id !== undefined
                      ? self.state.address3.id
                      : 0,
                  street: self.state.address3.street,
                  city: self.state.address3.city,
                  state: self.state.address3.state,
                  zip: self.state.address3.zip,
                  country: self.state.address3.country,

                  status: true,
                },
              });
              Object.assign(self.state.cardListDetails, {
                shippingAddress: {
                  id:
                    self.state.address3.id !== undefined
                      ? self.state.address3.id
                      : 0,
                  street: self.state.address3.street,
                  city: self.state.address3.city,
                  state: self.state.address3.state,
                  zip: self.state.address3.zip,
                  country: self.state.address3.country,
                  status: true,
                },
              });
            }
          });
        }
      }
    } catch (e) {
      console.log("if error ", e);
    }
  }

  /**
   * Method for Auto fill address using Google API
   * @param {event}
   *
   */
  autoFillAddress = async (event) => {
    try {
      let val = event.target.value;
      let self = this;
      let nbhoodData = "";
      let { validAddressFields } = this.state;
      if (val.length === 4 || val.length === 5 || val.length === 6) {
        this.setState({ Loading: true });
        await axios
          .get(
            `${GOOGLE_API}?address=${val}&key=${GOOGLE_API_KEY}&timestamp=${new Date().getTime()}`
          )
          .then(function(response) {
            if (response.status === 200 && response.data.results.length > 0) {
              let address = _.filter(
                response.data.results[0].address_components,
                function(addressData) {
                  if (addressData.types[0] === "locality") {
                    return addressData.long_name;
                  }
                  if (addressData.types[0] === "neighborhood") {
                    nbhoodData = addressData.long_name;
                  }
                  if (addressData.types[0] === "administrative_area_level_1") {
                    return addressData.long_name;
                  }
                  if (addressData.types[0] === "country") {
                    return addressData.long_name;
                  }
                }
              );
              if (address.length === 2) {
                let addMissingAddressObject = {
                  long_name: nbhoodData,
                };
                address.splice(0, 0, addMissingAddressObject);
              }
              if (address[0].long_name) {
                validAddressFields["city"] = address[0].long_name;
              }
              if (address[1].long_name) {
                validAddressFields["state"] = address[1].long_name;
              }
              if (address[0].long_name) {
                validAddressFields["country"] = address[2].long_name;
              }
              if (!sessionStorage.userID) {
                sessionStorage.userID = 0;
              }
              // localStorage.UserOtherAddress = sessionStorage.userID !== '0' ? JSON.stringify(validAddressFields) : undefined
              localStorage.guestUserDetails =
                sessionStorage.userID === "0"
                  ? JSON.stringify({
                      ...self.state.recipientDetailsValidObj,
                      ...self.state.validAddressFields,
                    })
                  : undefined;
              self.setState({
                otherAddress: {
                  street: self.state.otherAddress.street,
                  zip: val,
                  city:
                    address[0] !== undefined && address[0] !== ""
                      ? address[0].long_name
                      : self.otherAddress.city,
                  state:
                    address[1] !== undefined && address[1] !== ""
                      ? address[1].long_name
                      : self.otherAddress.state,
                  country:
                    address[2] !== undefined && address[2] !== ""
                      ? address[2].long_name
                      : self.otherAddress.country,
                },
                recipientDetailsValidObj: {
                  ...self.state.recipientDetailsValidObj,
                  ...self.state.validAddressFields,
                },
              });
            } else {
              self.setState({
                otherAddress: {
                  street: self.state.otherAddress.street,
                  zip: self.state.otherAddress.zip,
                  city: "",
                  state: "",
                  country: "",
                },
                Loading: false,
              });
            }
          })
          .catch(function(errorResponse) {
            self.setState({ Loading: false });
          });
        this.setState({ Loading: false });
      } else {
        self.setState({
          otherAddress: {
            street: self.state.otherAddress.street,
            zip: this.state.otherAddress.zip,
            city: "",
            state: "",
            country: "",
          },
          Loading: false,
        });
      }
    } catch (e) {}
  };

  /** Method to open policy modal */
  openModalPolicy = (e) => {
    this.setState({ policyModalopen: true });
  };

  /** Method to select consent */
  setConsent = (e) => {
    let tempGuestUserDetail =
      JSON.parse(localStorage.getItem("guestUserDetails")) === null
        ? {}
        : JSON.parse(localStorage.getItem("guestUserDetails"));

    if (e.target.checked) {
      tempGuestUserDetail.consent = 1;
      localStorage.removeItem("guestUserDetails");
      localStorage.setItem(
        "guestUserDetails",
        JSON.stringify(tempGuestUserDetail)
      );
      tempGuestUserDetail = null;
      this.setState({ consent: 1 });
    } else {
      tempGuestUserDetail.consent = 0;
      localStorage.removeItem("guestUserDetails");
      localStorage.setItem(
        "guestUserDetails",
        JSON.stringify(tempGuestUserDetail)
      );
      tempGuestUserDetail = null;
      this.setState({ consent: 0 });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ Loading: false });
    var shippingCharges = 0;
    var shippingDetails = [];
    var defaultShippment = "fedex ground";
    var cardListDetails = this.state.cardListDetails;
    var json = {};
    let shippmentIndex = 0;

    try {
      if (nextProps.shippmentType && nextProps.shippmentType.data) {
        this.setState({
          shippmentTypes: nextProps.shippmentType.data.data.data,
        });
      }
      if (
        nextProps.shippmentList &&
        nextProps.shippmentList.data &&
        nextProps.shippmentList.data.error === false
      ) {
        nextProps.shippmentList.data.data.data.map((list, index) => {
          if (
            list.startRange <= cardListDetails.totalQty &&
            cardListDetails.totalQty <= list.endRange
          ) {
            shippmentIndex++;
            if (
              list.shippingType.toLocaleLowerCase() ===
                this.state.defaultShippment.toLocaleLowerCase() &&
              this.state.defaultShippmentType
            ) {
              defaultShippment = list.shippingType.toLocaleLowerCase();
              json = {
                shippingCharges: list.shippingRate,
                shippingDetails: list,
              };
            }
          }
        });
        Object.assign(this.state.cardListDetails, json);
        this.setState({
          shippmentList: nextProps.shippmentList.data.data.data,
          defaultShippment: defaultShippment,
        });
      } else {
        this.setState({ Loading: false });
      }
      if (nextProps.discountList && nextProps.discountList.data) {
        this.setState({
          discountList: nextProps.discountList.data.data.data,
        });
      }
      if (sessionStorage.userID != 0) {
        if (
          nextProps.hasOwnProperty("shipmentPageProps") &&
          nextProps.shipmentPageProps.hasOwnProperty("location")
        ) {
        }
        if (
          nextProps.customerProfileInfo.data !== null &&
          nextProps.customerProfileInfo.data.error != true &&
          nextProps.customerProfileInfo &&
          nextProps.customerProfileInfo.data
        ) {
          let { data } = nextProps.customerProfileInfo.data.data;
          if (data.addresses && data.addresses.length > 0) {
            localStorage.setItem("isVerifyAddress", true);
            if (data.addresses.length === 2) {
              this.setState({
                address1: {
                  addresstype: data.addresses[0].addressType,
                  street: data.addresses[0].address1,
                  city: data.addresses[0].city,
                  state: data.addresses[0].state,
                  country: data.addresses[0].country,
                  zip: data.addresses[0].zip,
                  id: data.addresses[0].id,
                  status: data.addresses[0].status,
                },
                address2: {
                  addresstype: data.addresses[1].addressType,
                  street: data.addresses[1].address1,
                  city: data.addresses[1].city,
                  state: data.addresses[1].state,
                  country: data.addresses[0].country,
                  zip: data.addresses[1].zip,
                  id: data.addresses[1].id,
                  status: data.addresses[1].status,
                },
              });
            } else {
              this.setState({
                address1: {
                  addresstype: data.addresses[0].addressType,
                  street: data.addresses[0].address1,
                  city: data.addresses[0].city,
                  state: data.addresses[0].state,
                  country: data.addresses[0].country,
                  zip: data.addresses[0].zip,
                  id: data.addresses[0].id,
                  status: true,
                },
              });
            }
          }
          if (data.addresses && data.addresses.length > 0) {
            if (
              data.addresses[1] !== undefined &&
              !data.addresses[0].status &&
              ((data.addresses[1].status && !data.addresses[0].status) ||
                data.addresses[1].status)
            ) {
              this.setState({
                shippingAddress: {
                  id: this.state.address2.id,
                  street: this.state.address2.street,
                  city: this.state.address2.city,
                  state: this.state.address2.state,
                  zip: this.state.address2.zip,
                  country: this.state.address2.country,
                  addresstype: this.state.address2.addresstype,
                },
              });
            } else {
              this.setState({
                shippingAddress: {
                  id: this.state.address1.id,
                  street: this.state.address1.street,
                  city: this.state.address1.city,
                  state: this.state.address1.state,
                  zip: this.state.address1.zip,
                  country: this.state.address1.country,
                  addresstype: this.state.address1.addresstype,
                },
              });
            }
            if (localStorage.getItem("UserOtherAddress") !== null) {
              let addressobj3 = JSON.parse(
                localStorage.getItem("UserOtherAddress")
              );

              if (addressobj3.status === true) {
                this.setState({
                  shippingAddress: addressobj3,
                });
              }
            }
            setTimeout(() => {
              this.setShippingAddress(nextProps);
            }, 50);

            /***    Test for address selection problem     */
            // let address3_ = Object.assign(this.state.address3,{status:false})
            // this.setState({address3:address3_})
          } else {
            localStorage.setItem("isVerifyAddress", false);
          }
          nextProps.customerProfileInfo.data = null;
        }
      }
      if (nextProps.customerList && nextProps.customerList.data) {
        if (nextProps.customerList.data.data.data !== null) {
          this.setState((state) => ({
            customerList: nextProps.customerList.data.data.data,
            Loading: false,
          }));
          setTimeout(() => {
            this.setState((state) => ({ loading1: false }));
          }, 2024);
        }
        nextProps.customerList.data.data.data = null;
      }

      if (nextProps.customerInfo !== undefined) {
        var data = nextProps.customerInfo;
        setTimeout(() => {
          this.setShippmentAddress();
        }, 50);
        if (data.addresses && data.addresses.length > 0) {
          if (data.addresses.length === 2) {
            this.setState({
              address1: {
                addresstype: data.addresses[0].addressType,
                street: data.addresses[0].address1,
                city: data.addresses[0].city,
                state: data.addresses[0].state,
                country: data.addresses[0].country,
                zip: data.addresses[0].zip,
                id: data.addresses[0].id,
                status: data.addresses[0].status,
              },
              address2: {
                addresstype: data.addresses[1].addressType,
                street: data.addresses[1].address1,
                city: data.addresses[1].city,
                state: data.addresses[1].state,
                country: data.addresses[0].country,
                zip: data.addresses[1].zip,
                id: data.addresses[1].id,
                status: data.addresses[1].status,
              },
              defaultShippment: "FedEx Ground".toLocaleLowerCase(),
              // defaultShippmentType:true
            });
          } else {
            this.setState({
              address1: {
                addresstype: data.addresses[0].addressType,
                street: data.addresses[0].address1,
                city: data.addresses[0].city,
                state: data.addresses[0].state,
                country: data.addresses[0].country,
                zip: data.addresses[0].zip,
                id: data.addresses[0].id,
                status: true,
              },
              defaultShippment: "FedEx Ground".toLocaleLowerCase(),
              // defaultShippmentType:true
            });
          }
        }
        if (data.addresses && data.addresses.length > 0) {
          if (data.addresses[1] !== undefined && data.addresses[1].status) {
            this.setState({
              shippingAddress: {
                id: data.addresses[1].id,
                street: data.addresses[1].address1,
                city: data.addresses[1].city,
                state: data.addresses[1].state,
                zip: data.addresses[1].zip,
                country: data.addresses[1].country,
              },
            });
          } else {
            this.setState({
              shippingAddress: {
                id: data.addresses[0].id,
                street: data.addresses[0].address1,
                city: data.addresses[0].city,
                state: data.addresses[0].state,
                zip: data.addresses[0].zip,
                country: data.addresses[0].country,
              },
            });
          }
        }
      }
    } catch (e) {
      console.log("#### error is ", e);
    }
  }

  /***
   * Method for estimate shipping time
   *
   */

  calculateETA() {
    try {
      var total = {};
      var totalQuantity = 0;
      var totalPrice = 0;
      var ETA = new Date();
      var shippingCharges = 0;
      var shippingDetails = {};
      let filtredList = [];
      let shippmentLst = [];

      total =
        Array.isArray(this.state.cardListDetails) &&
        this.state.cardListDetails.length > 0
          ? this.state.cardListDetails.map((list, index) => {
              totalQuantity = totalQuantity + Number(list.productQuantity);
              totalPrice =
                totalPrice + Number(list.productQuantity) * list.price;
            })
          : {};
      if (totalQuantity && this.state.shippmentList) {
        var data = _.filter(this.state.shippmentList, (shippment) => {
          return (
            totalQuantity >= shippment.startRange &&
            (shippment.endRange == 0
              ? true
              : totalQuantity <= shippment.endRange) &&
            shippment.shippingType.toLocaleLowerCase() ===
              this.state.defaultShippment.toLocaleLowerCase()
          );
        });

        data = data.length > 0 ? data[0] : [];
        shippingDetails = data;
        var shippingEndTime =
          shippingDetails.endTime !== undefined ? shippingDetails.endTime : "";
        shippingCharges =
          shippingDetails.shippingRate !== undefined
            ? shippingDetails.shippingRate
            : 0;
        var days = new Date().getDate() + (shippingEndTime + 1);
        if (shippingEndTime != "") ETA = new Date().setDate(days);
        else ETA = this.state.ETA;
        if (this.state.shipnigDate > ETA) {
          ETA = new Date(this.state.shipnigDate);
        } else if (shippingEndTime == "") {
          ETA = this.state.ETA;
        } else {
          ETA = new Date(ETA);
        }
        filtredList = this.state.shippmentList.filter(
          (list) =>
            list.startRange <= this.state.cardListDetails.totalQty &&
            (list.endRange == 0
              ? true
              : this.state.cardListDetails.totalQty <= list.endRange)
        );
        filtredList.map((list, index) => {
          if (
            list.shippingType.toLocaleLowerCase() ===
            this.state.defaultShippment.toLocaleLowerCase()
          ) {
            shippmentLst = list;
          }
        });
        var json = {
          orderDate: new Date(),
          eta:
            this.state.etaDefault === true
              ? new Date(
                  moment(new Date()).add(3 + shippmentLst.endTime, "days")
                )
              : ETA,
          shippingAddress: this.state.shippingAddress,
          shippingCharges: shippingCharges,
          totalQty: totalQuantity,
          subTotal: totalPrice,
          shippingDetails: shippingDetails,
        };
        Object.assign(this.state.cardListDetails, json);
      }
    } catch (er) {
      console.log("calculateETA", er);
    }
  }

  /**
   * Function to open address modal
   */
  openAddressModal() {
    var self = this;
    self.setState({
      addressFlashMessageError: "",
      openModal: true,
    });
  }

  /***
   * Method for allowed only charter in address fields
   * @param {val}
   * @returns {Boolean}
   */
  isCharter = (val) => {
    try {
      let len = val.length;
      let num = 32;
      let regX = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
      try {
        num = +val[len - 1].toLocaleUpperCase().charCodeAt();
      } catch (e) {}
      return regX.test(val) || num === 32 ? true : false;
    } catch (e) {}
  };

  /**
   * function for add other address
   * @param object e
   * @return object state
   */
  async setValue(e) {
    try {
      this.setState({
        flashMessageError: "",
        addressFlashMessageError: "",
        flashShippingErrorMessage: "",
      });

      let val = e.target.value.trimStart();
      let key = e.target.name;
      let valid = {};
      let tempValidFields = this.state.validAddressFields;
      let tempGuestDetail = this.state.recipientDetailsValidObj;
      if (val === "") {
        valid[key] = "";
        tempValidFields[key] = "";
        tempGuestDetail[key] = "";
      } else {
        valid[key] = val;
        valid[key] = val.trimStart();
        tempValidFields[key] = val.trimStart();
        tempGuestDetail[key] = val.trimStart();
      }
      if (key !== "zip" && !this.isCharter(val) && key !== "street") {
        return 0;
      }
      let otherAddress = Object.assign(this.state.otherAddress, valid);
      if (
        sessionStorage.userID === "0" &&
        localStorage.getItem("guestUserDetails") !== null
      ) {
        let loaclAddress = JSON.parse(localStorage.guestUserDetails);
        loaclAddress = Object.assign(loaclAddress, tempValidFields);
        localStorage.guestUserDetails = JSON.stringify(loaclAddress);
      }
      this.setState({
        otherAddress: otherAddress,
        addressFlashMessageError: "",
        validAddressFields: tempValidFields,
      });
    } catch (e) {}
  }

  /**
   * Function to close the modal
   */
  onCloseModal() {
    let shippingActiveClass = document.getElementsByClassName(
      "address-section"
    );
    this.setState({
      openModal: false,
      isFieldsEmpty: false,
      validAddressFields: {},
      recipientDetailsValidObj: {},
      otherAddress: {
        street: this.state.otherAddress.street,
        city: this.state.otherAddress.city,
        state: this.state.otherAddress.state,
        zip: this.state.otherAddress.zip,
        country: this.state.otherAddress.country,
        addressFlashMessageErrorSpan: "",
        isFieldsEmpty: false,
        validAddressFields: {},
      },
    });
    if (this.state.editOtherAddress === false) {
      this.setState({
        openModal: false,
        otherAddress: {
          street: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          addressFlashMessageErrorSpan: "",
        },
      });
    }
    let otherAddressObjectValue = Object.values(this.state.otherAddress);
    let valueCount = 0;
    otherAddressObjectValue.map((v) => {
      if (v === "") {
        valueCount++;
      }
      return 0;
    });
    if (valueCount >= 5 || !this.state.editOtherAddress) {
      try {
        // if( shippingActiveClass[0].className==='address-section  active') {
        // }
        // if (this.state.address1.status) {
        //     shippingActiveClass[0].className += ' active';
        // } else {
        //     shippingActiveClass[1].className += ' active';
        // }
      } catch (e) {}
    }
  }

  //Method to add new address
  addAddress() {
    try {
      var lang = this.state.lang;
      let otherAddress = this.state.otherAddress;
      if (
        otherAddress.street == "" ||
        otherAddress.city == "" ||
        otherAddress.state == "" ||
        otherAddress.country == "" ||
        otherAddress.zip == ""
      ) {
        this.setState({
          addressFlashMessageError:
            language.language.all_fields_mandatory[0][lang],
          isFieldsEmpty: true,
        });
      } else {
        let UserOtherAddress = this.state.otherAddress;
        UserOtherAddress.status = true;
        localStorage.setItem(
          "UserOtherAddress",
          JSON.stringify(UserOtherAddress)
        );
        this.setState({
          address3: {
            street: this.state.otherAddress.street.trim(),
            city: this.state.otherAddress.city.trim(),
            state: this.state.otherAddress.state.trim(),
            zip: this.state.otherAddress.zip,
            country: this.state.otherAddress.country.trim(),
            status: true,
          },
          openModal: false,
        });
        this.setState({
          shippingAddress: {
            ...this.state.otherAddress,
            id: 0,
          },
        });
        Object.assign(this.state.cardListDetails, {
          shippingAddress: {
            ...this.state.otherAddress,
            id: 0,
          },
        });
        let domElementAddressOne = document.querySelector('[title="address1"]');
        domElementAddressOne.firstChild.className = "address-section";
        let domElementAddressTwo = document.querySelector('[title="address2"]');
        domElementAddressTwo.firstChild.className = "address-section";
      }
    } catch (e) {
      console.log("error ", e);
    }
  }

  /***
   * Method for setting by default shipping adderress
   * @param {state}
   * @return  {shipping addrress}
   */
  setShippingAddress(nextProps) {
    // this.state.cardListDetails['defaultShippingAddress'] = this.state.shippingAddress
    const defaultShippingAddress = this.state.shippingAddress;
    const tempCardDetails = Object.assign(
      this.state.cardListDetails,
      defaultShippingAddress
    );
    let { address1 } = this.state;
    let { address2 } = this.state;
    let { address3 } = this.state;
    this.setState({ cardListDetails: tempCardDetails });
    if (
      nextProps.hasOwnProperty("shipmentPageProps") &&
      nextProps.shipmentPageProps.hasOwnProperty("location")
    ) {
      let { state } = nextProps.shipmentPageProps.location;

      if (state !== undefined && state.shippignAddressType !== undefined) {
        if (state.shippignAddressType === address1.addresstype) {
          this.setState({
            shippingAddress: { ...address1 },
            shippignAddressType: state.shippignAddressType,
          });
        } else if (state.shippignAddressType === address2.addresstype) {
          this.setState({
            shippingAddress: { ...address2 },
            shippignAddressType: state.shippignAddressType,
          });
        } else {
          this.setState({
            shippingAddress: { ...address3 },
            shippignAddressType: "other",
            address3: { ...address3, status: true },
          });
        }
        this.setState({ willFirst: false });
      }
      nextProps.shipmentPageProps.location.state = undefined;
    }
  }

  //Method for erasing data used in highlight errors in form fields
  clearErrorFieldsData() {
    this.setState({
      validAddressFields: {},
      recipientDetailsValidObj: {},
      isFieldsEmpty: false,
    });
  }

  /* Function to redirect user on payment page */
  redirectToPaymentPage() {
    try {
      this.setState({ isFieldsEmpty: true });
      let { lang } = this.state;
      if (!sessionStorage.userID) {
        sessionStorage.userID = 0;
      }
      if (this.state.cardListDetails.shippingDetails.length < 1) {
        this.setState({
          flashShippingErrorMessage:
            language.language.no_shipping_found[0][lang],
        });
      } else if (this.state.shippingAddress.id !== "") {
        let cardDetails = Object.assign(this.state.cardListDetails, {
          shippingAddress: this.state.shippingAddress,
        });

        this.props.shipmentPageProps.history.push({
          pathname: "/ordermanagement",
          state: {
            data: cardDetails,
            pageType: "paymentPage",
          },
        });
      } else if (sessionStorage.userID == 0) {
        var guestUserDetails =
          localStorage.guestUserDetails === undefined
            ? this.state.guestUserDetails
            : JSON.parse(localStorage.guestUserDetails);
        var email = guestUserDetails.email;
        let { mobile } = guestUserDetails;

        if (
          guestUserDetails.firstName != "" &&
          guestUserDetails.lastName != "" &&
          email != "" &&
          guestUserDetails.mobile !== ""
        ) {
          if (this.validateMail(guestUserDetails.email)) {
            var list = this.state.customerList;
            let resList = _.filter(list, (v) =>
              _.includes(guestUserDetails.email.toLowerCase(), v.email)
            );
            if (resList != "") {
              this.setState({
                userExistErrorMessage: constant.EMAIL_FOUND,
              });
            } else {
              //var otherAddress = this.state.otherAddress
              if (
                guestUserDetails.street != "" &&
                guestUserDetails.city != "" &&
                guestUserDetails.state != "" &&
                guestUserDetails.zip != "" &&
                guestUserDetails.country != "" &&
                guestUserDetails.mobile != ""
              ) {
                let mobile = guestUserDetails.mobile.replace(
                  /[^a-zA-Z0-9]/g,
                  ""
                );
                if (mobile.length < 10) {
                  this.setState({
                    flashMessageError:
                      language.language.shipping_form_mandatory_mobile[0][lang],
                    isFieldsEmpty: true,
                  });
                  return 0;
                }

                var shippingAddress = {
                  id: "",
                  street: guestUserDetails.street,
                  city: guestUserDetails.city,
                  state: guestUserDetails.state,
                  zip: guestUserDetails.zip,
                  country: guestUserDetails.country,
                };
                Object.assign(
                  this.state.cardListDetails.shippingAddress,
                  shippingAddress
                );
                Object.assign(this.state.cardListDetails, {
                  guestUserDetails: {
                    firstName: guestUserDetails.firstName,
                    lastName: guestUserDetails.lastName,
                    mobile: guestUserDetails.mobile,
                    email: guestUserDetails.email,
                    consent: this.state.consent,
                  },
                });
                this.clearErrorFieldsData();
                this.props.shipmentPageProps.history.push({
                  pathname: "/ordermanagement",
                  state: {
                    data: this.state.cardListDetails,
                    pageType: "paymentPage",
                  },
                });
              } else {
                this.setState({
                  flashMessageError:
                    language.language.shipping_form_mandatory[0][lang],
                });
              }
            }
          } else {
            this.setState({
              isFieldsEmpty: true,
              flashMessageError: language.language.Invalid_Email[0][lang],
            });
            return 0;
          }
        } else {
          this.setState({
            flashMessageError:
              language.language.shipping_form_mandatory[0][lang],
          });
          return 0;
        }
        if (mobile.length === 0 || mobile.length < 10) {
          this.setState({
            flashMessageError:
              language.language.shipping_form_mandatory_mobile[0][lang],
            isFieldsEmpty: true,
          });
          return 0;
        }
      }
    } catch (e) {
      this.setState({
        flashMessageError: "Something went wrong!!",
        isFieldsEmpty: true,
      });
      console.log("error in redirect page ", e);
    }
  }

  /**
   * function for add guest details
   * @param object e
   * @return object state
   */
  setGuestDetails(e) {
    try {
      let val = e.target.value;
      let key = e.target.name;
      let valid = {};
      let tempValidGuesDetail = this.state.recipientDetailsValidObj;
      if (val === "") {
        valid[key] = "";
        tempValidGuesDetail[key] = "";
      } else {
        valid[key] = val;
        tempValidGuesDetail[key] = val;
      }
      let guestUserDetails = Object.assign(this.state.guestUserDetails, valid);
      tempValidGuesDetail.consent = this.state.consent;
      this.setState({
        guestUserDetails: guestUserDetails,
        recipientDetailsValidObj: tempValidGuesDetail,
      });
      localStorage.setItem(
        "guestUserDetails",
        JSON.stringify(tempValidGuesDetail)
      );
      tempValidGuesDetail = null;
    } catch (e) {}
    this.setState({
      flashMessageError: "",
      addressFlashMessageError: "",
    });
  }

  //function to validate Email
  validateMail = (email) => {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  setLanguage(data) {
    this.setState({
      lang: data,
    });
  }

  //submit form on enter Keypress
  submitOnEnter(event) {
    if (event.which == 13 || event.keyCode == 13) {
      //other address form
      if (event.target.id == "country") {
        this.addAddress();
      }
    }
  }

  // Method to set the size of the items of the current page
  changeSize(data) {
    if (data === "increase") {
      let increaseCounter = this.state.increasemove;
      let decreaseCounter = this.state.decreasemove;

      if (increaseCounter > 0) {
        decreaseCounter = decreaseCounter + 1;
        increaseCounter = increaseCounter - 1;

        this.setState({
          fontSize: {
            itemPriceCart: Number(this.state.fontSize.itemPriceCart) + 1,
            mediaheading: Number(this.state.fontSize.mediaheading) + 1,
            breadcrumb: Number(this.state.fontSize.breadcrumb) + 1,
            giftCardText: Number(this.state.fontSize.giftCardText) + 1,
            costSectionPrice: Number(this.state.fontSize.costSectionPrice) + 1,
            subTotal: Number(this.state.fontSize.subTotal) + 1,
            mainCost: Number(this.state.fontSize.mainCost) + 1,
            shippinglabel: Number(this.state.fontSize.shippinglabel) + 1,
            fulfill: Number(this.state.fontSize.fulfill) + 1,
          },
          increasemove: increaseCounter,
          decreasemove: decreaseCounter,
        });
      }
    } else if (data === "decrease") {
      let increaseCounter = this.state.increasemove;
      let decreaseCounter = this.state.decreasemove;
      if (decreaseCounter > 0) {
        decreaseCounter = decreaseCounter - 1;
        increaseCounter = increaseCounter + 1;

        this.setState({
          fontSize: {
            itemPriceCart: Number(this.state.fontSize.itemPriceCart) - 1,
            mediaheading: Number(this.state.fontSize.mediaheading) - 1,
            breadcrumb: Number(this.state.fontSize.breadcrumb) - 1,
            giftCardText: Number(this.state.fontSize.giftCardText) - 1,
            costSectionPrice: Number(this.state.fontSize.costSectionPrice) - 1,
            subTotal: Number(this.state.fontSize.subTotal) - 1,
            mainCost: Number(this.state.fontSize.mainCost) - 1,
            shippinglabel: Number(this.state.fontSize.shippinglabel) - 1,
            fulfill: Number(this.state.fontSize.fulfill) - 1,
          },
          increasemove: increaseCounter,
          decreasemove: decreaseCounter,
        });
      }
    } else if (data === "default") {
      this.setState({
        fontSize: {
          mainCost: 24,
          subTotal: 14,
          costSectionPrice: 20,
          itemPriceCart: 16,
          mediaheading: 12,
          breadcrumb: 12,
          giftCardText: 24,
          shippinglabel: 14,
          fulfill: 12,
        },
        increaseCounter: 0,
        decreaseCounter: 0,
        increasemove: 4,
        decreasemove: 4,
      });
    }
  }

  /***
   * Method for breadCrumbCLick
   * @param {event}
   * @return {undefined}
   */
  breadCrumbCLick(event) {
    try {
      let targetName = event.target.className;
      if (targetName === "active gift-card") {
        if (
          sessionStorage.hasOwnProperty("emailID") &&
          sessionStorage.emailID !== ""
        ) {
          if (this.props.shipmentPageProps !== undefined) {
            this.props.shipmentPageProps.history.push("/ordermanagement");
          }
        } else {
          if (this.props.shipmentPageProps !== undefined) {
            this.props.shipmentPageProps.history.push("/");
          }
        }
      }
      if (event.target.className === "active view-cart") {
        if (this.props.shipmentPageProps !== undefined) {
          this.props.shipmentPageProps.history.push("/orderDetailsManagements");
        }
      }
    } catch (e) {
      console.log("eror in breadCrumbCLick", e);
    }
  }

  editOtherAddress = (event) => {
    this.setState({
      openModal: true,
      editOtherAddress: true,
    });
  };

  /***
   * Method for redirectGiftCard giftCard
   * @param {event}
   * @return {undefined}
   */
  redirectGiftCard() {
    if (this.props.shipmentPageProps !== undefined) {
      this.props.shipmentPageProps.history.push("/ordermanagement");
    }
  }

  /**
   * Method for calculate shipping charges
   * @parma {event}
   * @return {undefined}
   */
  calcutateShippingChrages = async (event) => {
    let indexForShippmentType = event.target.value;
    await this.setState((state) => ({
      defaultShippmentType: false,
      defaultShippment: indexForShippmentType.toLocaleLowerCase(),
    }));
    this.calculateETA();
  };

  /***
   * Method for change Shippment Option
   * @param {event}
   * @return {undefined}
   */
  changeShippingType = (e) => {
    try {
      let shippment = e.target.value;
      let filtredList = [];
      this.setState({ shippmentDefaultOption: false });

      filtredList = this.state.shippmentList.filter(
        (list) =>
          list.startRange <= this.state.cardListDetails.totalQty &&
          this.state.cardListDetails.totalQty <= list.endRange
      );
      if (shippment === "immediate") {
        this.setState({
          defaultShippmentType: true,
          defaultShippment: "FedEx Ground".toLocaleLowerCase(),
          isDisabled: false,
          etaDefault: true,
        });
      } else {
        this.setState({
          defaultShippmentType: false,
          defaultShippment: "FedEx Ground".toLocaleLowerCase(),
          isDisabled: true,
          etaDefault: false,
        });
      }
      this.calculateETA();
    } catch (e) {
      console.log("hi this si error ", e);
    }
  };

  /**
   * Method for get filtred list
   */
  getShippmentList = () => {
    if (this.state.shippmentOption !== "custom") {
      return this.state.shippmentList;
    } else {
      return this.state.shippmentList.filter(
        (list) =>
          list.shippingType.toLocaleUpperCase() ===
          "FedEx Ground".toLocaleUpperCase()
      );
    }
  };

  closeModel() {
    this.onCloseModal();
  }
  /**
   * Method for render UI Custom Immediate  Delivery Options
   *
   */
  uiShipping(shippmentOption) {
    let shippingDetails = this.state.cardListDetails.shippingDetails;
    shippingDetails = shippingDetails != undefined ? shippingDetails : "";
    const { fontSize } = this.state;
    let immediateShippmentComponent = "";
    let filtredList = [];
    filtredList = this.state.shippmentList.filter(
      (list) =>
        list.startRange <= this.state.cardListDetails.totalQty &&
        (list.endRange === 0
          ? true
          : this.state.cardListDetails.totalQty <= list.endRange)
    );

    console.log("--------filtredList----------", filtredList);

    if (filtredList.length > 0) {
      return filtredList.map((list, index) => {
        if (
          list.startRange <= this.state.cardListDetails.totalQty &&
          (list.endRange === 0
            ? true
            : this.state.cardListDetails.totalQty <= list.endRange)
        ) {
          if (
            this.state.defaultShippmentType === true &&
            list.shippingType.toLocaleLowerCase() === "fedex ground"
          ) {
            immediateShippmentComponent = (
              <div
                className={` ${
                  this.state.shippmentOption === "custom "
                    ? "radio_btn mar_20 custom "
                    : "radio_btn mar_20"
                }`}
                key={`shippmentOption${index}`}
              >
                <div className="desc-field">
                  {this.state.isDisabled ? (
                    <input
                      title={list.shippingType}
                      type="radio"
                      name="a"
                      value={list.shippingType}
                      checked
                      onClick={(e) => {
                        this.calcutateShippingChrages(e);
                      }}
                      disabled
                    />
                  ) : (
                    <input
                      title={list.shippingType}
                      type="radio"
                      name="a"
                      value={list.shippingType}
                      checked
                      onClick={(e) => {
                        this.calcutateShippingChrages(e);
                      }}
                    />
                  )}
                </div>

                <span
                  className="shipping_option"
                  style={{ fontSize: fontSize.costSectionPrice + "px" }}
                >{`${list.shippingType} `}</span>
                <span
                  className="desc-text"
                  style={{ fontSize: fontSize.shippinglabel + "px" }}
                >
                  {` - ${
                    list.shippingType === "FedEx Ground"
                      ? language.language.Expected_Delivery_Around[0][
                          this.state.lang
                        ]
                      : language.language.Expected_Delivery[0][this.state.lang]
                  }
                                            ${
                                              this.state.shippmentOption !==
                                              "custom radio_btn"
                                                ? moment(new Date())
                                                    .add(
                                                      3 + list.endTime,
                                                      "days"
                                                    )
                                                    .format("MM/DD/YYYY")
                                                : ""
                                            }
                                            `}
                </span>
                <div
                  className="shipping-des"
                  style={{ fontSize: fontSize.shippinglabel + "px" }}
                >
                  ({language.language.Applied_Shipping[0][this.state.lang]}
                  {list.shippingRate === null ? 0 : list.shippingRate})
                </div>
              </div>
            );
          } else {
            immediateShippmentComponent = (
              <div
                className={` ${
                  this.state.shippmentOption === "custom "
                    ? "radio_btn mar_20 custom "
                    : "radio_btn mar_20"
                }`}
                key={`shippmentImm${index}`}
              >
                <div className="desc-field">
                  {this.state.isDisabled ? (
                    <input
                      title={list.shippingType}
                      type="radio"
                      name="a"
                      value={list.shippingType}
                      onClick={(e) => {
                        this.calcutateShippingChrages(e);
                      }}
                      disabled
                    />
                  ) : (
                    <input
                      title={list.shippingType}
                      type="radio"
                      name="a"
                      value={list.shippingType}
                      onClick={(e) => {
                        this.calcutateShippingChrages(e);
                      }}
                    />
                  )}
                </div>

                <span
                  className="shipping_option"
                  style={{ fontSize: fontSize.costSectionPrice + "px" }}
                >{`${list.shippingType} `}</span>
                <span
                  className="desc-text"
                  style={{ fontSize: fontSize.shippinglabel + "px" }}
                >
                  {` - ${
                    list.shippingType === "FedEx Ground"
                      ? language.language.Expected_Delivery_Around[0][
                          this.state.lang
                        ]
                      : language.language.Expected_Delivery[0][this.state.lang]
                  }
                                            ${
                                              this.state.shippmentOption !==
                                              "custom radio_btn"
                                                ? moment(new Date())
                                                    .add(
                                                      3 + list.endTime,
                                                      "days"
                                                    )
                                                    .format("MM/DD/YYYY")
                                                : ""
                                            }
                                            `}
                </span>
                <div
                  className="shipping-des"
                  style={{ fontSize: fontSize.shippinglabel + "px" }}
                >
                  {`(${language.language.Applied_Shipping[0][this.state.lang]}${
                    list.shippingRate === null ? 0 : list.shippingRate
                  })`}
                </div>
              </div>
            );
          }
        }
        return immediateShippmentComponent;
      });
    }

    let decriptionComponent = (
      <div className="shipping-description">
        <label>
          {shippingDetails.length > 1
            ? `(Shippment for   
                            ${this.state.cardListDetails.shippingDetails.startRange}-
                            ${this.state.cardListDetails.shippingDetails.endRange} quantity is 
                            ${this.state.cardListDetails.shippingDetails.startTime}-
                            ${this.state.cardListDetails.shippingDetails.endTime}   
                            days)`
            : ""}
        </label>
      </div>
    );

    return immediateShippmentComponent + decriptionComponent;
  }

  /**
   * Method for render UI Custom Custom Delivery Options
   *
   */
  uiShippingCustom() {
    let shippmentFiltredList = this.getShippmentList();
    let filtredList = [];
    let shippmentComponent = "";
    let shippmentIndex = 0;
    const { fontSize } = this.state;
    filtredList = shippmentFiltredList.filter(
      (list) =>
        list.startRange <= this.state.cardListDetails.totalQty &&
        this.state.cardListDetails.totalQty <= list.endRange &&
        list.shippingType.toLocaleLowerCase() ===
          "FedEx Ground".toLocaleLowerCase()
    );

    filtredList.map((list, index) => {
      list.startRange <= this.state.cardListDetails.totalQty &&
      this.state.cardListDetails.totalQty <= list.endRange
        ? shippmentIndex++
        : void 0;

      shippmentComponent = (
        <div
          className={`radio_btn mar_20 pl-12 ${
            this.state.shippmentOption === "custom" ? "custom" : ""
          }`}
          key={`shippmentComponent${index}`}
        >
          <div className="desc-field">
            {this.state.isDisabled ? (
              <input
                title={list.shippingType}
                placeholder=""
                checked
                type="radio"
                name="a"
                value={list.shippingType}
                onClick={(e) => {
                  this.calcutateShippingChrages(e);
                }}
              />
            ) : (
              <input
                title={list.shippingType}
                placeholder=""
                type="radio"
                name="a"
                value={list.shippingType}
                onClick={(e) => {
                  this.calcutateShippingChrages(e);
                }}
                disabled
              />
            )}
          </div>
          <span
            className="shipping_option"
            style={{ fontSize: fontSize.costSectionPrice + "px" }}
          >{`${list.shippingType}`}</span>
          {/* <span className="desc-text"> {`     
                    - Expect delivery by 
                    ${moment(new Date()).add((3 + list.endTime), 'days').format('MM/DD/YYYY')} 
                    `

                }</span> */}
          <div
            className="shipping-des"
            style={{ fontSize: fontSize.shippinglabel + "px" }}
          >
            {`
                        (${
                          language.language.Applied_Shipping[0][this.state.lang]
                        }${list.shippingRate})`}
          </div>
        </div>
      );
      return 0;
    });
    return shippmentComponent;
  }

  /** Method to close policy modal */
  closeModalPolicy = () => {
    this.setState({
      policyModalopen: false,
      otpErrorSpan: "",
    });
    if (document.getElementsByClassName("modalPolicy")[0]) {
      document.getElementsByClassName("modalPolicy")[0].classList.add("exit");
    }
  };

  render() {
    var totalAmount = 0;
    if (sessionStorage.userID != 0 && sessionStorage.loggedIn === "") {
      this.props.shipmentPageProps.history.push({
        pathname: "/",
      });
    }
    let activeAdress1 = "";
    let activeAdress2 = "";
    let { address1 } = this.state;
    let { address2 } = this.state;

    if (this.state.willFirst) {
      activeAdress1 =
        this.state.address1.status && this.state.address2.status
          ? true
          : this.state.address1.status === true
          ? true
          : false;
      activeAdress2 = activeAdress1 === true ? false : true;
    } else {
      if (address1.addresstype === this.state.shippignAddressType) {
        activeAdress1 = true;
      } else if (address2.addresstype === this.state.shippignAddressType) {
        activeAdress2 = true;
      }
    }

    if (this.state.shippmentList) {
      this.calculateETA();

      var shippingCharges =
        this.state.cardListDetails.shippingCharges === undefined
          ? 0
          : this.state.cardListDetails.shippingCharges;

      totalAmount = shippingCharges + this.state.cardListDetails.subTotal;
    }
    let addressFlashMessageErrorSpan = "";
    if (this.state.addressFlashMessageError) {
      addressFlashMessageErrorSpan = (
        <p style={{ fontSize: this.state.fontSize.breadcrumb + "px" }}>
          {this.state.addressFlashMessageError}
        </p>
      );
    }
    let flashShippingErrorMessageSpan = "";
    if (this.state.flashShippingErrorMessage) {
      flashShippingErrorMessageSpan = (
        <p style={{ fontSize: this.state.fontSize.breadcrumb + "px" }}>
          {this.state.flashShippingErrorMessage}
        </p>
      );
    }
    let flashMessageErrorSpan = "";
    if (this.state.flashMessageError) {
      flashMessageErrorSpan = (
        <p style={{ fontSize: this.state.fontSize.breadcrumb + "px" }}>
          {this.state.flashMessageError}
        </p>
      );
    }
    let userExistErrorMessageSpan = "";
    if (this.state.userExistErrorMessage) {
      userExistErrorMessageSpan = (
        <p style={{ fontSize: this.state.fontSize.breadcrumb + "px" }}>
          {this.state.userExistErrorMessage} Please login to continue.
        </p>
      );
    }

    const { fontSize } = this.state;
    var { lang } = this.state;

    let { validAddressFields } = this.state;
    let { recipientDetailsValidObj } = this.state;

    return (
      <div id="myaccounts">
        {(this.state.Loading === true || this.state.loading1 === true) && (
          <div className="loader-react">
            <ReactLoading
              type={"spinningBubbles"}
              color={"green"}
              className="reactLoader"
            />
          </div>
        )}
        <div className="wrapper page customerHome">
          <Header
            language={this.setLanguage.bind(this)}
            changeSize={this.changeSize.bind(this)}
            locationProps={this.props}
          />
          <section id="main" className="shipping-page">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <ul className="breadcrumb all_breadcrumb_top">
                    <li className="breadcrumb-item ">
                      <a
                        style={{ fontSize: fontSize.breadcrumb + "px" }}
                        href="javascript:void(0);"
                        className="active gift-card"
                        onClick={this.breadCrumbCLick}
                      >
                        {language.language.home[0][lang]}
                      </a>
                    </li>
                    {sessionStorage.emailID === "" ||
                    sessionStorage.emailID == undefined ? (
                      <li className="breadcrumb-item">
                        <a
                          style={{ fontSize: fontSize.breadcrumb + "px" }}
                          href="javascript:void(0);"
                          onClick={(e) => this.redirectGiftCard()}
                          className="active"
                        >
                          {language.language.gift_cards[0][lang]}
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="breadcrumb-item">
                      <a
                        className="active view-cart"
                        href="javascript:void(0);"
                        onClick={this.breadCrumbCLick}
                        style={{ fontSize: fontSize.breadcrumb + "px" }}
                      >
                        {language.language.view_cart[0][lang]}
                      </a>
                    </li>
                    <li className="breadcrumb-item">
                      <a style={{ fontSize: fontSize.breadcrumb + "px" }}>
                        {" "}
                        {language.language.shipping_details[0][lang]}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="shipnig-heading addcard-section adnewpad">
                    <h2 style={{ fontSize: fontSize.giftCardText + "px" }}>
                      {language.language.shipping_details[0][lang]}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-9">
                  <div className="shipnig-details shippingSection boxshadow30">
                    {flashShippingErrorMessageSpan ? (
                      <div
                        className="alert alert-success alert_succesfull adpadding30 clearfix col-md-6"
                        role="alert"
                      >
                        <span>
                          <img src={errorimg} alt="errorimg" title="errorimg" />
                        </span>
                        <p className="clearfix">
                          {flashShippingErrorMessageSpan}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {sessionStorage.userID != 0 &&
                    sessionStorage.userID != undefined &&
                    sessionStorage.loggedIn !== "" ? (
                      <div className="shipping-address-section">
                        <h3 style={{ fontSize: fontSize.shippinglabel + "px" }}>
                          {language.language.Address[0][lang]}
                        </h3>
                        <div className="row row-flex" id="myDIV">
                          <div
                            className="col-md-4 col-md active"
                            title="address1"
                          >
                            <div
                              className={`address-section ${
                                this.state.address3.status === false &&
                                activeAdress1 === true &&
                                this.state.addressStatus === false
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <img
                                src={addressBorder}
                                alt="addressBorder"
                                title="addressBorder"
                              />
                              <h4
                                style={{
                                  fontSize: fontSize.shippinglabel + "px",
                                }}
                              >
                                {this.state.address1.addresstype != ""
                                  ? this.state.address1.addresstype
                                  : ""}
                              </h4>
                              <address
                                style={{
                                  fontSize: fontSize.shippinglabel + "px",
                                }}
                              >
                                {this.state.address1.addresstype != ""
                                  ? this.state.address1.street +
                                    "," +
                                    this.state.address1.city +
                                    ","
                                  : ""}
                                <br />
                                {this.state.address1.addresstype != ""
                                  ? this.state.address1.state +
                                    "," +
                                    this.state.address1.country +
                                    "," +
                                    this.state.address1.zip
                                  : ""}
                              </address>
                            </div>
                          </div>
                          {this.state.address2.id != "" ? (
                            <div className="col-md-4 col-md" title="address2">
                              <div
                                className={`address-section ${
                                  this.state.address3.status === false &&
                                  activeAdress2 === true &&
                                  this.state.addressStatus === false
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <img
                                  src={addressBorder}
                                  alt="addressBorder"
                                  title="addressBorder"
                                />
                                <h4
                                  style={{
                                    fontSize: fontSize.shippinglabel + "px",
                                  }}
                                >
                                  {this.state.address2.addresstype != ""
                                    ? this.state.address2.addresstype
                                    : ""}
                                </h4>
                                <address
                                  style={{
                                    fontSize: fontSize.shippinglabel + "px",
                                  }}
                                >
                                  {this.state.address2.addresstype != ""
                                    ? this.state.address2.street +
                                      "," +
                                      this.state.address2.city +
                                      ","
                                    : ""}
                                  <br />
                                  {this.state.address2.addresstype != ""
                                    ? this.state.address2.state +
                                      "," +
                                      this.state.address2.country +
                                      "," +
                                      this.state.address2.zip
                                    : ""}
                                </address>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="col-md-4 col-md"
                              style={{ display: "none" }}
                            ></div>
                          )}
                          {this.state.address3.country != "" ? (
                            <div className="col-md-4 col-md" title="address3">
                              <div
                                className={`address-section ${
                                  this.state.address3.status === true
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <img
                                  src={addressBorder}
                                  alt="addressBorder"
                                  title="addressBorder"
                                  alt="addressBorder"
                                />
                                <h4
                                  style={{
                                    fontSize: fontSize.shippinglabel + "px",
                                  }}
                                >
                                  {language.language.OTHER[0][lang]}
                                </h4>
                                {this.state.address3.country == "" ? (
                                  ""
                                ) : (
                                  <address
                                    style={{
                                      fontSize: fontSize.shippinglabel + "px",
                                    }}
                                  >
                                    {this.state.address3.street +
                                      "," +
                                      this.state.address3.city}
                                    <br />
                                    {this.state.address3.state +
                                      "," +
                                      this.state.address3.country +
                                      "," +
                                      this.state.address3.zip}
                                  </address>
                                )}
                                <a
                                  className="editbtn"
                                  href="javascript:void(0)"
                                  onClick={(e) => {
                                    this.editOtherAddress(e);
                                  }}
                                >
                                  <img
                                    src={pencil}
                                    alt="pencil"
                                    title="pencil"
                                  />
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="col-md-4 col-md"
                              style={{ display: "none" }}
                            ></div>
                          )}
                          {this.state.address3.country == "" ? (
                            <div className="col-md-4">
                              <div className="address-button-section text-center">
                                <div
                                  className="border-text"
                                  style={{
                                    fontSize: fontSize.breadcrumb + "px",
                                  }}
                                >
                                  {language.language.or[0][lang]}
                                </div>
                                <button
                                  style={{
                                    fontSize: fontSize.shippinglabel + "px",
                                  }}
                                  className="giftcard_button cardnone_button othercustome"
                                  onClick={this.openAddressModal.bind(this)}
                                >
                                  {language.language.OTHER[0][lang]}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="col-md-4 col-md"
                              style={{ display: "none" }}
                            ></div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="Recipient-section">
                          <h3>
                            {language.language.recipient_detials[0][lang]}
                          </h3>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <form className="billing-address">
                              {flashMessageErrorSpan ? (
                                <div
                                  className="alert alert-success alert_succesfull adpadding30 clearfix col-md-6"
                                  role="alert"
                                >
                                  <span>
                                    <img
                                      src={errorimg}
                                      alt="errorimg"
                                      title="errorimg"
                                    />
                                  </span>
                                  <p className="clearfix">
                                    {flashMessageErrorSpan}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}

                              {userExistErrorMessageSpan ? (
                                <div
                                  className="alert alert-success alert_succesfull adpadding30 clearfix"
                                  role="alert"
                                >
                                  <span>
                                    <img
                                      src={errorimg}
                                      alt="errorimg"
                                      title="errorimg"
                                    />
                                  </span>
                                  <p className="clearfix">
                                    {userExistErrorMessageSpan}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                              <fieldset>
                                <legend></legend>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        for="fname"
                                        style={{
                                          fontSize:
                                            fontSize.shippinglabel + "px",
                                        }}
                                      >
                                        {language.language.First_Name[0][lang]}
                                      </label>
                                      <InputMask
                                        id="fname"
                                        type="text"
                                        className={`form-control ${
                                          recipientDetailsValidObj.firstName ===
                                            "" && this.state.isFieldsEmpty
                                            ? "red"
                                            : ""
                                        }`}
                                        value={
                                          recipientDetailsValidObj.firstName !==
                                            undefined &&
                                          recipientDetailsValidObj.firstName !==
                                            ""
                                            ? recipientDetailsValidObj.firstName
                                            : ""
                                        }
                                        id=""
                                        mask={this.state.nameInputMask}
                                        onChange={this.setGuestDetails.bind(
                                          this
                                        )}
                                        maskChar={null}
                                        name="firstName"
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        for="lname"
                                        style={{
                                          fontSize:
                                            fontSize.shippinglabel + "px",
                                        }}
                                      >
                                        {language.language.Last_Name[0][lang]}
                                      </label>
                                      <InputMask
                                        type="text"
                                        id="lname"
                                        className={`form-control ${
                                          recipientDetailsValidObj.lastName ===
                                            "" && this.state.isFieldsEmpty
                                            ? "red"
                                            : ""
                                        }`}
                                        value={
                                          recipientDetailsValidObj.lastName !==
                                            undefined &&
                                          recipientDetailsValidObj.lastName !==
                                            ""
                                            ? recipientDetailsValidObj.lastName
                                            : ""
                                        }
                                        id=""
                                        mask={this.state.nameInputMask}
                                        onChange={this.setGuestDetails.bind(
                                          this
                                        )}
                                        maskChar={null}
                                        name="lastName"
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        style={{
                                          fontSize:
                                            fontSize.shippinglabel + "px",
                                        }}
                                      >
                                        {language.language.Email_ID[0][lang]}
                                      </label>
                                      <input
                                        title={
                                          language.language.Email_ID[0][lang]
                                        }
                                        type="email"
                                        className={`form-control ${
                                          recipientDetailsValidObj.email ===
                                            "" && this.state.isFieldsEmpty
                                            ? "red"
                                            : ""
                                        }`}
                                        value={
                                          recipientDetailsValidObj.email !==
                                            undefined &&
                                          recipientDetailsValidObj.email !== ""
                                            ? recipientDetailsValidObj.email
                                            : ""
                                        }
                                        id=""
                                        name="email"
                                        onChange={this.setGuestDetails.bind(
                                          this
                                        )}
                                        maxLength={50}
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        for="mobilenum"
                                        style={{
                                          fontSize:
                                            fontSize.shippinglabel + "px",
                                        }}
                                      >
                                        {language.language.mobile[0][lang]}
                                      </label>
                                      <InputMask
                                        id="mobilenum"
                                        className={`form-control ${
                                          recipientDetailsValidObj.mobile ===
                                            "" && this.state.isFieldsEmpty
                                            ? "red"
                                            : ""
                                        }`}
                                        value={
                                          recipientDetailsValidObj.mobile !==
                                            undefined &&
                                          recipientDetailsValidObj.mobile !== ""
                                            ? recipientDetailsValidObj.mobile
                                            : ""
                                        }
                                        id=""
                                        onChange={this.setGuestDetails.bind(
                                          this
                                        )}
                                        mask="(999) 999-9999"
                                        maskChar={null}
                                        name="mobile"
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        style={{
                                          fontSize:
                                            fontSize.shippinglabel + "px",
                                        }}
                                      >
                                        {language.language.Street[0][lang]}
                                      </label>
                                      <input
                                        title={
                                          language.language.Street[0][lang]
                                        }
                                        type="text"
                                        className={`form-control ${
                                          recipientDetailsValidObj.street ===
                                            "" && this.state.isFieldsEmpty
                                            ? "red"
                                            : ""
                                        }`}
                                        value={
                                          recipientDetailsValidObj.street !==
                                            undefined &&
                                          recipientDetailsValidObj.street !== ""
                                            ? recipientDetailsValidObj.street
                                            : ""
                                        }
                                        id=""
                                        name="street"
                                        onChange={this.setValue.bind(this)}
                                        maxLength={30}
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        for="zipcode"
                                        style={{
                                          fontSize:
                                            fontSize.shippinglabel + "px",
                                        }}
                                      >
                                        {language.language.Zip_Code[0][lang]}
                                      </label>
                                      <InputMask
                                        id="zipcode"
                                        mask="999999"
                                        maskChar={null}
                                        className={`form-control ${
                                          recipientDetailsValidObj.zip === "" &&
                                          this.state.isFieldsEmpty
                                            ? "red"
                                            : ""
                                        }`}
                                        value={
                                          recipientDetailsValidObj.zip !==
                                            undefined &&
                                          recipientDetailsValidObj.zip !== ""
                                            ? recipientDetailsValidObj.zip
                                            : ""
                                        }
                                        id=""
                                        name="zip"
                                        onChange={this.setValue.bind(this)}
                                        onBlur={(e) => {
                                          this.autoFillAddress(e);
                                        }}
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        style={{
                                          fontSize:
                                            fontSize.shippinglabel + "px",
                                        }}
                                      >
                                        {language.language.City[0][lang]}
                                      </label>
                                      <input
                                        title={language.language.City[0][lang]}
                                        type="text"
                                        className={`form-control ${
                                          recipientDetailsValidObj.city ===
                                            "" && this.state.isFieldsEmpty
                                            ? "red"
                                            : ""
                                        }`}
                                        value={
                                          recipientDetailsValidObj.city !==
                                            undefined &&
                                          recipientDetailsValidObj.city !== ""
                                            ? recipientDetailsValidObj.city
                                            : ""
                                        }
                                        id=""
                                        name="city"
                                        // value={this.state.otherAddress.city}
                                        onChange={this.setValue.bind(this)}
                                        maxLength={30}
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        style={{
                                          fontSize:
                                            fontSize.shippinglabel + "px",
                                        }}
                                      >
                                        {language.language.State[0][lang]}
                                      </label>
                                      <input
                                        title={language.language.State[0][lang]}
                                        type="text"
                                        className={`form-control ${
                                          recipientDetailsValidObj.state ===
                                            "" && this.state.isFieldsEmpty
                                            ? "red"
                                            : ""
                                        }`}
                                        value={
                                          recipientDetailsValidObj.state !==
                                            undefined &&
                                          recipientDetailsValidObj.state !== ""
                                            ? recipientDetailsValidObj.state
                                            : ""
                                        }
                                        id=""
                                        name="state"
                                        // value={this.state.otherAddress.state}
                                        onChange={this.setValue.bind(this)}
                                        maxLength={30}
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        style={{
                                          fontSize:
                                            fontSize.shippinglabel + "px",
                                        }}
                                      >
                                        {language.language.Country[0][lang]}
                                      </label>
                                      <input
                                        title={
                                          language.language.Country[0][lang]
                                        }
                                        type="text"
                                        className={`form-control ${
                                          recipientDetailsValidObj.country ===
                                            "" && this.state.isFieldsEmpty
                                            ? "red"
                                            : ""
                                        }`}
                                        value={
                                          recipientDetailsValidObj.country !==
                                            undefined &&
                                          recipientDetailsValidObj.country !==
                                            ""
                                            ? recipientDetailsValidObj.country
                                            : ""
                                        }
                                        id=""
                                        name="country"
                                        // value={this.state.otherAddress.country}
                                        onChange={this.setValue.bind(this)}
                                        maxLength={30}
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {this.state.flashMessageError ? (
                                  <div
                                    className="form-group"
                                    style={{
                                      fontSize: fontSize.breadcrumb + "px",
                                    }}
                                  >
                                    <label
                                      className="noteplus30 p-0"
                                      style={{
                                        color: "red",
                                        fontSize: fontSize.shippinglabel + "px",
                                      }}
                                    >
                                      {
                                        language.language
                                          .all_fields_mandatory[0][lang]
                                      }
                                    </label>
                                  </div>
                                ) : (
                                  <div
                                    className="form-group"
                                    style={{
                                      fontSize: fontSize.shippinglabel + "px",
                                    }}
                                  >
                                    <label
                                      className="noteplus30 p-0"
                                      style={{
                                        fontSize: fontSize.shippinglabel + "px",
                                      }}
                                    >
                                      {
                                        language.language
                                          .all_fields_mandatory[0][lang]
                                      }
                                    </label>
                                  </div>
                                )}
                                <div
                                  className="form-group"
                                  style={{
                                    fontSize: fontSize.profilelabel + "px",
                                  }}
                                >
                                  <label
                                    style={{
                                      fontSize: fontSize.profilelabel + "px",
                                    }}
                                  ></label>
                                  <input
                                    title={language.language.consent[0][lang]}
                                    type="checkbox"
                                    className="checkbox-btn"
                                    checked={
                                      this.state.consent === 1 ? true : false
                                    }
                                    onClick={this.setConsent.bind(this)}
                                  />
                                  {language.language.consent[0][lang]}
                                  <span
                                    className="purple-color"
                                    onClick={(e) => this.openModalPolicy(e)}
                                  >
                                    {language.language.consent[1][lang]}
                                  </span>
                                </div>
                                <div className="form-group pull-left"></div>
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={`checkbox-section addnewleft ${
                        this.state.shippmentOption !== "custom" ? "active" : ""
                      }`}
                    >
                      <h4>{language.language.Shipping_Type[0][lang]} </h4>
                      <div className="col-md-12">
                        <div className="select shippment ">
                          {/* <div className="form-control mb-4" onChange={(e) => { this.changeShippingType(e) }}> */}

                          <div className="optionlist">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="tabslist cust_tm">
                                  {this.state.defaultShippmentType ? (
                                    <label className="card-radio checkbox-inline mb-0">
                                      <input
                                        title="immediate"
                                        type="radio"
                                        name="shippmentOption"
                                        value="immediate"
                                        onChange={(e) => {
                                          this.changeShippingType(e);
                                        }}
                                        checked
                                      />
                                      <em></em>{" "}
                                      <span
                                        style={{
                                          fontSize:
                                            fontSize.costSectionPrice + "px",
                                        }}
                                      >
                                        {
                                          language.language
                                            .Imidiate_Shipping[0][lang]
                                        }
                                      </span>
                                    </label>
                                  ) : (
                                    <label className="card-radio checkbox-inline mb-0">
                                      <input
                                        title="immediate"
                                        type="radio"
                                        name="shippmentOption"
                                        value="immediate"
                                        onChange={(e) => {
                                          this.changeShippingType(e);
                                        }}
                                      />
                                      <em></em>{" "}
                                      <span
                                        style={{
                                          fontSize:
                                            fontSize.costSectionPrice + "px",
                                        }}
                                      >
                                        {
                                          language.language
                                            .Imidiate_Shipping[0][lang]
                                        }
                                      </span>
                                    </label>
                                  )}

                                  <span
                                    className="Northgate_heading delivery"
                                    style={{
                                      fontSize:
                                        fontSize.shippinglabel +
                                        "px !important ",
                                    }}
                                  >
                                    {
                                      language.language._3_days_delivery[0][
                                        lang
                                      ]
                                    }
                                  </span>
                                </div>
                                <div className="col-md-12 p-2">
                                  {typeof this.uiShipping("immediate") ===
                                  "string" ? (
                                    <p className="shippment_not pl-2">
                                      {
                                        language.language.no_shippment_found[0][
                                          lang
                                        ]
                                      }
                                    </p>
                                  ) : (
                                    this.uiShipping("immediate")
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 custom-new">
                                <div className="tabslist cust_tm">
                                  <label className="card-radio checkbox-inline">
                                    <input
                                      title="changeShippingType"
                                      type="radio"
                                      name="shippmentOption"
                                      value="custom"
                                      onChange={(e) => {
                                        this.changeShippingType(e);
                                      }}
                                    />
                                    <em></em>{" "}
                                    <span
                                      style={{
                                        fontSize:
                                          fontSize.costSectionPrice + "px",
                                      }}
                                    >
                                      {
                                        language.language.Custom_Delivery[0][
                                          lang
                                        ]
                                      }
                                    </span>
                                  </label>
                                </div>

                                <div className="calender">
                                  <form className="shipnig-form">
                                    <div className="row">
                                      <div className="col-md-5 pr-0">
                                        <label
                                          style={{
                                            fontSize:
                                              fontSize.shippinglabel + "px",
                                          }}
                                        >
                                          {
                                            language.language
                                              .Select_Delivery_Date[0][lang]
                                          }
                                        </label>
                                      </div>
                                      <div className="col-md-7">
                                        <DatePicker
                                          className="form-control calendar-img"
                                          selected={this.state.shipnigDate}
                                          onChange={this.setShippingDate.bind(
                                            this
                                          )}
                                          minDate={new Date().setDate(
                                            new Date().getDate() + 9
                                          )}
                                          dateFormat="MM/dd/yyyy"
                                          style={{
                                            fontSize:
                                              fontSize.shippinglabel + "px",
                                          }}
                                        />
                                      </div>

                                      <div
                                        className="disclaimer"
                                        style={{
                                          fontSize:
                                            fontSize.mediaheading + "px",
                                        }}
                                      >
                                        {/* <span>{`Disclaimer: 
                                                                                    (Expected delivery between dates
                                                                                    ${moment(this.state.cardListDetails.eta).subtract(2, 'days').format('MM/DD/YYYY')} 
                                                                                    - ${moment(this.state.cardListDetails.eta).add(2, 'days').format('MM/DD/YYYY')}
                                                                                    )`} </span> */}
                                        <span className="northgate">
                                          {
                                            language.language
                                              .The_delivery_date_may_vary[0][
                                              lang
                                            ]
                                          }
                                        </span>
                                      </div>

                                      {/* <div className="col-md-5"> 
                                                                <span>{language.language.Expected_Delivery[0][lang]} {moment(this.state.cardListDetails.eta).format('MM/DD/YYYY')}</span>
                                                            {/* </div> */}
                                    </div>
                                  </form>
                                </div>
                                {/* <div>{this.uiShipping('custom')}</div> */}
                                <div>
                                  {typeof this.uiShippingCustom() ===
                                  "string" ? (
                                    <p className="shippment_not pl-3">
                                      {
                                        language.language.no_shippment_found[0][
                                          lang
                                        ]
                                      }
                                    </p>
                                  ) : (
                                    this.uiShippingCustom()
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="radio-btn"> */}
                      <button
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                        className="giftcard_button pull-right mt-4"
                        onClick={this.redirectToPaymentPage.bind(this)}
                      >
                        {language.language.CONTINUE[0][lang]}{" "}
                      </button>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shipping-order-summary boxshadow30">
                    <div className="order_summary_section">
                      <h3 style={{ fontSize: fontSize.itemPriceCart + "px" }}>
                        {language.language.ORDER_SUMMARY[0][lang]}
                      </h3>
                      <div className="order_br"></div>
                    </div>
                    <div className="shippingorderScrolling payment_right all_scroll_section">
                      {Array.isArray(this.state.cardListDetails)
                        ? this.state.cardListDetails.map(
                            (cardDetails, index) => {
                              return (
                                <div
                                  className="shipping-order-aside"
                                  key={`cardListDetails___${index}`}
                                >
                                  <div className="media">
                                    <div className="media-left aside-img">
                                      <img
                                        src={
                                          cardDetails.imagePath == ""
                                            ? noImage
                                            : constant.APIURL +
                                              cardDetails.imagePath +
                                              cardDetails.imageName
                                        }
                                        title={
                                          cardDetails.typeName +
                                          "  $" +
                                          cardDetails.price
                                        }
                                        alt={cardDetails.typeName}
                                        title={cardDetails.typeName}
                                      />
                                    </div>
                                    <div className="media-body payment_list">
                                      <h4
                                        className="aside-heading"
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      >
                                        ${" "}
                                        {HelperUtil._getAmountCommaSeparator(
                                          cardDetails.price.toFixed(2)
                                        )}
                                      </h4>
                                      <p
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      >
                                        {cardDetails.typeName}{" "}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: fontSize.breadcrumb + "px",
                                        }}
                                      >
                                        {language.language.Qty[0][lang]} :
                                        {cardDetails.productQuantity}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : ""}
                    </div>
                    <div className="shipping-order-total-summary">
                      <div className="shipping-order-subtotal">
                        <p
                          className="shipping-subtotal"
                          style={{ fontSize: fontSize.shippinglabel + "px" }}
                        >
                          {" "}
                          {language.language.subTotal[0][lang]}{" "}
                        </p>
                        <p
                          className="shipping-total"
                          style={{ fontSize: fontSize.shippinglabel + "px" }}
                        >
                          ${" "}
                          {this.state.cardListDetails.subTotal != undefined
                            ? HelperUtil._getAmountCommaSeparator(
                                this.state.cardListDetails.subTotal.toFixed(2)
                              ).toString()
                            : 0}{" "}
                        </p>
                      </div>
                      <div className="shipping-order-subtotal subtotal_shipping">
                        <p
                          className="shipping-subtotal"
                          style={{ fontSize: fontSize.shippinglabel + "px" }}
                        >
                          {language.language.Shipping[0][lang]}
                        </p>
                        <p
                          className="shipping-total"
                          style={{ fontSize: fontSize.shippinglabel + "px" }}
                        >
                          +{" "}
                          {shippingCharges != undefined
                            ? "$" +
                              HelperUtil._getAmountCommaSeparator(
                                shippingCharges.toFixed(2)
                              ).toString()
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="shipping-order-total">
                      <p
                        className="shipping-total-text"
                        style={{ fontSize: fontSize.itemPriceCart + "px" }}
                      >
                        {language.language.Total_Amount[0][lang]}
                      </p>
                      <p
                        className="shipping-total-amount"
                        style={{ fontSize: fontSize.itemPriceCart + "px" }}
                      >
                        ${" "}
                        {totalAmount != undefined
                          ? HelperUtil._getAmountCommaSeparator(
                              totalAmount.toFixed(2)
                            ).toString()
                          : 0}
                      </p>
                    </div>
                    <div className="shipping-order-button text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/**Policy modal open */}
        <div className="modal-box" id="">
          <ReactModal
            closeTimeoutMS={500}
            isOpen={this.state.policyModalopen}
            onRequestClose={this.closeModalPolicy}
            className="modal-dialog-box fullModal"
            center
            classNames={{
              overlay: "LoginFormoverlay",
              modal: "LoginFormModel modalPolicy",
            }}
            shouldCloseOnOverlayClick={false}
          >
            <div className="modal-content">
              <div className="modal-header polcy-header">
                <h4 className="modal-title"></h4>
                <button
                  className="styles_closeButton__20ID4 closemodalbtn pol-close"
                  onClick={this.closeModalPolicy}
                >
                  <svg
                    className="styles_closeIcon__1QwbI"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 36 36"
                  >
                    <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                  </svg>
                </button>
              </div>
              <div className="modal-body newsseventall container privacy-body">
                <form>
                  <div className="row">
                    {/* <p> Consent to receiving promotional and marketing emails from Northgate Gonzalez, LLC and its affiliates and agree to their privacy policy</p> */}
                    <Consent lang={lang} />
                    <div className="formbtn pol-btn">
                      <button
                        type="button"
                        className="giftcard_button mgr10 pull-right"
                        onClick={this.closeModalPolicy}
                      >
                        {language.language.CLOSE[0][lang]}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ReactModal>
        </div>
        {/**Policy modal end */}
        <Modal
          open={this.state.openModal}
          onClose={this.onCloseModal.bind(this)}
          center
          classNames={{
            overlay: "addAddressoverlay",
            modal: "addAddressModelform",
          }}
          closeOnOverlayClick={false}
        >
          <div className="modal-dialog AddNewAddress">
            <div className="modal-content">
              {/*-- Modal Header */}
              <div className="modal-header giftcart_title_header account">
                {this.state.editOtherAddress ? (
                  <h4
                    className="modal-title giftcart_all_header"
                    style={{ fontSize: fontSize.breadcrumb + "px" }}
                  >
                    {language.language.EDIT_ADDRESS[0][lang]}
                  </h4>
                ) : (
                  <h4
                    className="modal-title giftcart_all_header"
                    style={{ fontSize: fontSize.breadcrumb + "px" }}
                  >
                    {language.language.ADD_NEW_ADDRESS[0][lang]}
                  </h4>
                )}
                <button
                  type="button"
                  className="close account"
                  data-dismiss="modal"
                  onClick={(e) => {
                    this.closeModel(e);
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body ">
                <form action="/" className="address-form">
                  {addressFlashMessageErrorSpan ? (
                    <div
                      className="alert alert-success alert_succesfull adpadding30 clearfix"
                      role="alert"
                    >
                      <span>
                        <img src={errorimg} alt="errorimg" title="errorimg" />
                      </span>
                      <p className="clearfix">{addressFlashMessageErrorSpan}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label
                      htmlFor="street"
                      style={{ fontSize: fontSize.itemPriceCart + "px" }}
                    >
                      {language.language.Street[0][lang]}
                    </label>
                    {this.state.editOtherAddress ? (
                      <input
                        type="text"
                        title={this.state.otherAddress.street}
                        className={`form-control ${
                          validAddressFields.street === "" &&
                          this.state.isFieldsEmpty
                            ? "red"
                            : ""
                        }`}
                        value={this.state.otherAddress.street}
                        name="street"
                        onChange={this.setValue.bind(this)}
                        maxLength="60"
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                      />
                    ) : (
                      <input
                        title={this.state.otherAddress.street}
                        type="text"
                        className={`form-control ${
                          validAddressFields.street === "" &&
                          this.state.isFieldsEmpty
                            ? "red"
                            : ""
                        }`}
                        name="street"
                        onChange={this.setValue.bind(this)}
                        maxLength="60"
                        value={this.state.otherAddress.street}
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="street "
                      style={{ fontSize: fontSize.itemPriceCart + "px" }}
                    >
                      {language.language.Zip_Code[0][lang]}
                    </label>
                    {this.state.editOtherAddress ? (
                      <InputMask
                        mask="999999"
                        maskChar={null}
                        type="text"
                        className={`form-control ${
                          validAddressFields.zip === "" &&
                          this.state.isFieldsEmpty
                            ? "red"
                            : ""
                        }`}
                        name="zip"
                        onChange={this.setValue.bind(this)}
                        onBlur={(e) => {
                          this.autoFillAddress(e);
                        }}
                        value={this.state.otherAddress.zip}
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                      />
                    ) : (
                      <InputMask
                        mask="999999"
                        maskChar={null}
                        type="text"
                        className={`form-control ${
                          validAddressFields.zip === "" &&
                          this.state.isFieldsEmpty
                            ? "red"
                            : ""
                        }`}
                        name="zip"
                        onChange={this.setValue.bind(this)}
                        onBlur={(e) => {
                          this.autoFillAddress(e);
                        }}
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="street"
                      style={{ fontSize: fontSize.itemPriceCart + "px" }}
                    >
                      {language.language.City[0][lang]}
                    </label>
                    {this.state.editOtherAddress ? (
                      <input
                        title={this.state.otherAddress.city}
                        type="text"
                        className={`form-control ${
                          validAddressFields.city === "" &&
                          this.state.isFieldsEmpty
                            ? "red"
                            : ""
                        }`}
                        value={this.state.otherAddress.city}
                        maxLength="20"
                        name="city"
                        onChange={this.setValue.bind(this)}
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                      />
                    ) : (
                      <input
                        type="text"
                        title={this.state.otherAddress.city}
                        className={`form-control ${
                          validAddressFields.city === "" &&
                          this.state.isFieldsEmpty
                            ? "red"
                            : ""
                        }`}
                        value={this.state.otherAddress.city}
                        maxLength="20"
                        name="city"
                        onChange={this.setValue.bind(this)}
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="street"
                      style={{ fontSize: fontSize.itemPriceCart + "px" }}
                    >
                      {language.language.State[0][lang]}
                    </label>
                    {this.state.editOtherAddress ? (
                      <input
                        type="text"
                        title={this.state.otherAddress.state}
                        className={`form-control ${
                          validAddressFields.state === "" &&
                          this.state.isFieldsEmpty
                            ? "red"
                            : ""
                        }`}
                        value={this.state.otherAddress.state}
                        maxLength="20"
                        name="state"
                        onChange={this.setValue.bind(this)}
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                      />
                    ) : (
                      <input
                        title={this.state.otherAddress.state}
                        type="text"
                        className={`form-control ${
                          validAddressFields.state === "" &&
                          this.state.isFieldsEmpty
                            ? "red"
                            : ""
                        }`}
                        value={this.state.otherAddress.state}
                        maxLength="20"
                        name="state"
                        onChange={this.setValue.bind(this)}
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="street"
                      style={{ fontSize: fontSize.itemPriceCart + "px" }}
                    >
                      {language.language.Country[0][lang]}
                    </label>
                    <input
                      title={this.state.otherAddress.country}
                      type="text"
                      className={`form-control ${
                        validAddressFields.country === "" &&
                        this.state.isFieldsEmpty
                          ? "red"
                          : ""
                      }`}
                      id="country"
                      value={this.state.otherAddress.country}
                      maxLength="40"
                      onKeyPress={(e) => this.submitOnEnter(e)}
                      name="country"
                      onChange={this.setValue.bind(this)}
                      style={{ fontSize: fontSize.shippinglabel + "px" }}
                    />
                  </div>
                  <div className="form-group">
                    <div>
                      <button
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                        type="button"
                        className="shipSave btn btn-default btn-fill cardSavebtn"
                        onClick={this.addAddress.bind(this)}
                      >
                        {language.language.SAVE[0][lang]}{" "}
                      </button>
                      <button
                        style={{ fontSize: fontSize.shippinglabel + "px" }}
                        type="button"
                        className="shipCancel btn btn-default btn-non-fill cardcancelbtn"
                        onClick={this.onCloseModal.bind(this)}
                      >
                        {language.language.CANCEL[0][lang]}
                      </button>{" "}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>

        <footer>
          <p>© 2019 Northgate Gonzalez Market®</p>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shippmentList: state.shippmentList,
    discountList: state.discountList,
    customerProfileInfo: state.customerProfile,
    customerList: state.customerList,
    shippmentType: state.shippmentTypes,
  };
};

export default connect(mapStateToProps, {
  shippmentListAction,
  discountListAction,
  customerProfileAction,
  customerListAction,
  shippmentTypeAction,
})(ShippmentPage);
