module.exports = {

    language: {
        // CARD_PAYMENT_ERROR_1102440001: [{
        //     en: 'Address has been added successfully', spa: 'Se agregó nueva dirección de modo satisfactorio'
        // }],
        Add_address: [{
            en: 'Address has been added successfully', spa: 'Se agregó nueva dirección de modo satisfactorio'
        }],
        Update_address: [{
            en: 'Address updated successfully', spa: 'Actualización satisfactoria de la dirección'
        }],
        My_Account: [{
            en: 'My Account', spa: 'Mi cuenta'
        }],
        Account_exists: [{
            en: 'Account is already activated!', spa: 'La cuenta ya está activada!'
        }],
        profile: [{
            en: 'profile', spa: 'perfil'
        }],
        update_password: [{
            en: 'update password', spa: 'ACTUALIZAR CONTRASEÑA'
        }],
        Password_Same: [{
            en: "New Password and Current Password can't be same", spa: 'La contraseña nueva y la contraseña actual no deben ser las mismas'
        }],
        Password_Validation: [{
            en: "Password should be of 8-15 characters ", spa: 'La contraseña debe ser de 8 a 15 caracteres '
        }],
        new_password_please: [{
            en: 'Please enter New Password', spa: 'Te pedimos que ingreses la contraseña nueva'
        }],
        match_password: [{
            en: 'New Password and Confirm Password does not match', spa: 'No coinciden la contraseña nueva ni la contraseña confirmada'
        }],
        update: [{
            en: 'Update', spa: 'ACTUALIZAR'
        }],
        Qty: [{
            en: 'Qty', spa: 'Cantidad'
        }],
        Reason: [{
            en: 'REASON', spa: 'MOTIVO'
        }],
        order_history: [{
            en: 'order history', spa: 'HISTORIAL DE PEDIDOS'
        }],
        home: [{
            en: 'Home', spa: 'Página principal'
        }],
        gift_cards: [{
            en: 'Gift Card Varieties', spa: 'Tarjetas de Regalo'
        }],
        Search: [{
            en: 'Search', spa: 'Buscar'
        }],
        Login: [{
            en: 'Login', spa: 'Ingresar'
        }],
        Signup: [{
            en: 'Signup', spa: 'Inscribirse'
        }],
        Discount: [{ 
            en: 'Bulk Discount', spa: 'Descuento al por mayor' 
        }],

        Only_Discount: [{
            en: 'Discount', spa: 'Descuento'
        }],
        Payment_Type: [{
            en: 'Payment Type', spa: 'Tipo de Pago'
        }],
        Gift_card_discount: [{
            en: 'Gift Card Discount', spa: 'Descuento de Tarjeta de Regalo'
        }],
        Purchase_Amount: [{
            en: 'Purchase Amount', spa: 'Monto de la Compra'
        }],
        English: [{
            en: 'English', spa: 'Inglés '
        }],
        Spanish: [{
            en: 'Spanish', spa: 'Español '
        }],
        Northgate_Gift_Card: [{
            en: 'Northgate Gift Card', spa: 'Tarjeta de Regalo Northgate'
        }],
        GIFT_OF_CHOICE_FOR_YOUR_LOVED_ONE: [{
            en: 'GIFT OF CHOICE FOR YOUR LOVED ONE!', spa: '¡EL REGALO INDICADO PARA TU SER AMADO!'
        }],
        BUY_GIFT_CARD: [{
            en: 'BUY GIFT CARDS', spa: 'COMPRA UNA TARJETA DE REGALO'
        }],
        Email_ID: [{
            en: 'Email Address', spa: 'Correo Electrónico'
        }],
        Session_message: [{
            en: 'Session Expired', spa: 'Sesión caducada'
        }],
        SESSION_EXPIRE: [{
            en: 'Your session has been expired. Please login again.', spa: 'Su sesión ha expirado. Por favor, inicie sesión de nuevo.'
        }],
        Password: [{
            en: 'Password', spa: 'Contraseña '
        }],
        REGISTER: [{
            en: 'REGISTER', spa: 'REGISTRARSE'
        }],
        Forgot_Password: [{
            en: 'Forgot Password', spa: 'Olvidé contraseña '
        }],
        Continue_as_a_guest: [{
            en: 'Continue as a guest', spa: 'Continúa como invitado'
        }],
        All: [{
            en: 'All', spa: 'Todo'
        }],
        All_Occasions: [{
            en: 'All Occasions', spa: 'Todas las ocasiones '
        }],
        Birthday: [{
            en: 'Birthday', spa: 'Cumpleaños '
        }],
        Thank_You: [{
            en: 'Thank You', spa: 'Gracias'
        }],
        Holidays: [{
            en: 'Holidays', spa: 'Días Festivos'
        }],
        Christmas: [{
            en: 'Christmas', spa: 'Navidad'
        }],
        company_name: [{
            en: 'Company Name', spa: 'Nombre de la Compañía'
        }],
        title: [{
            en: 'Job Title', spa: 'Puesto'
        }],
        job_title_mandatory: [{
            en: 'Job Title is mandatory', spa: 'Anotar el puesto es obligatorio'
        }],
        email_id: [{
            en: 'Email Address', spa: 'Correo Electrónico'
        }],
        email_address_req: [{
            en: 'Email Address is required', spa: 'Es necesaria la dirección de correo electrónico'
        }],
        reset_password_link: [{
            en: 'Reset password link has been sent, please check your email', spa: 'Se envió el enlace de restablecimiento de la contraseña. Te pedimos que revises tu correo electrónico.'
        }],
        primary_number: [{
            en: 'Primary Phone Number', spa: 'Número de teléfono principal'
        }],
        primary_number_mandatory: [{
            en: 'Primary Phone Number is mandatory', spa: 'Anotar el número de teléfono principal es obligatorio'
        }],
        secondary_number: [{
            en: 'Secondary Phone Number (Optional)', spa: 'Número de teléfono secundario (Opcional)'
        }],
        federal_tax: [{
            en: 'Federal Tax ID (Optional)', spa: 'Identificación de impuestos federales (opcional)'
        }],
        current_password: [{
            en: 'Current Password', spa: 'Contraseña actual'
        }],
        current_password_please: [{
            en: 'Please enter Current Password', spa: 'Te pedimos que ingreses la contraseña actual'
        }],
        new_password: [{
            en: 'New Password', spa: 'Contraseña nueva'
        }],
        confirm_password: [{
            en: 'Confirm Password', spa: 'Confirmar Contraseña '
        }],
        Resend_Password: [{
            en: 'Send Password Reset Link', spa: 'Enviar restablecer enlace de contraseña'
        }],
        UPDATE: [{
            en: 'UPDATE', spa: 'ACTUALIZAR'
        }],
        CANCEL: [{
            en: 'CANCEL', spa: 'CANCELAR'
        }],
        CLOSE: [{
            en: 'Close', spa: 'Cerca'
        }],
        ADDRESS: [{
            en: 'ADDRESS', spa: 'DIRECCIÓN'
        }],
        ADD_NEW_ADDRESS: [{
            en: 'ADD NEW ADDRESS', spa: 'AGREGAR NUEVA DIRECCIÓN'
        }],
        EDIT_ADDRESS: [{
            en: 'EDIT ADDRESS', spa: 'CAMBIAR DIRECCIÓN'
        }],
        SAVE: [{
            en: 'SAVE', spa: 'GUARDAR'
        }],
        Address_Type: [{
            en: 'Address Type', spa: 'Tipo de dirección '
        }],
        Address_Office: [{
            en: 'Office', spa: 'Oficina'
        }],
        Street: [{
            en: 'Street Address', spa: 'Dirección'
        }],
        Zip_Code: [{
            en: 'Zip Code', spa: 'Zona Postal'
        }],
        City: [{
            en: 'City', spa: 'Ciudad'
        }],
        State: [{
            en: 'State', spa: 'Estado'
        }],
        Country: [{
            en: 'Country', spa: 'País'
        }],
        are_you_sure: [{
            en: 'ARE YOU SURE?', spa: '¿ESTÁS SEGURO?'
        }],
        confirm: [{
            en: 'Confirm', spa: 'Confirmar'
        }],
        You_want_to_delete: [{
            en: 'You want to delete the address', spa: 'Quieres borrar la dirección'
        }],
        Invalid_Email: [{
            en: 'Invalid Email Address', spa: 'Dirección de correo electrónico no válida'
        }],
        Invalid_Primary_Phone: [{
            en: 'Invalid Primary Phone Number', spa: 'Número de teléfono principal no válido'
        }],
        Invalid_Secondary_Phone: [{
            en: 'Invalid Secondary Phone Number', spa: 'Número de teléfono secundario no válido'
        }],
        Invalid_Federal_Tax: [{
            en: 'Invalid Federal Tax ID', spa: 'ID de impuesto federal no válido'
        }],
        FORGOT_PASSWORD: [{
            en: 'FORGET PASSWORD', spa: 'Olvidé la contraseña'
        }],
        Payment_Details: [{
            en: 'Please enter All Payment Details', spa: 'Te pedimos que ingreses todos los detalles del pago'
        }],
        No: [{
            en: 'No', spa: 'No'
        }],
        Business: [{
            en: 'Business', spa: 'Empresarial'
        }],
        view_cart: [{
            en: 'View Cart', spa: 'Ver carrito de compras'
        }],
        no_items_found: [{
            en: '"No Items Found"', spa: '"No hay productos"'
        }],
        no_shippment_found: [{
            en: 'Shipment Option not found', spa: 'Opción de envío no encontrada'
        }],
        no_shipping_found: [{
            en: 'No shipping found', spa: 'No se ha encontrado envío'
        }],
        sub_total: [{
            en: 'SUB-TOTAL', spa: 'SUBTOTAL'
        }],
        add_more: [{
            en: 'ADD-MORE', spa: 'AGREGAR MÁS'
        }],
        clear_cart: [{
            en: 'CLEAR CART', spa: 'VACIAR CARRITO DE COMPRAS'
        }],
        checkout: [{
            en: 'CHECKOUT', spa: 'PAGAR'
        }],
        clear_cart_confirm: [{
            en: 'CONFIRM', spa: 'CONFIRMAR'
        }],
        confirm_message: [{
            en: 'Are you sure you want to clear your cart?', spa: '¿Realmente quieres vaciar tu carrito de compras?'
        }],
        shipping_date: [{
            en: 'Select Shipping Date', spa: 'Seleccionar fecha de envío'
        }],
        business_day: [{
            en: 'Allow 3 business days to us to fulfill your order', spa: 'Permite 3 días hábiles para que preparemos tu pedido.'
        }],
        Shipping_Type: [{
            en: 'Shipping Options', spa: 'Tipo de envío'
        }],
        Shipping_Charges: [{
            en: 'Shipping Charges', spa: 'Cargos por envío'
        }],
        recipient_detials: [{
            en: 'Recipient Details', spa: 'Detalles del destinatario'
        }],
        Normal_Shipment: [{
            en: 'Normal Shipment', spa: 'Envío normal'
        }],
        Imidiate_Shipping: [{
            en: "Immediate Shipping", spa: "Envío Inmediato"
        }],
        Custom_Delivery: [{
            en: "Custom Delivery", spa: "Entrega personalizada"
        }],
        _3_days_delivery: [{
            en: "Northgate will take 3 days to prepare the shipment", spa: "Northgate tardara 3 días en preparar el envío"
        }],
        Address: [{
            en: 'Address', spa: 'Dirección'
        }],
        Choose_Address: [{
            en: 'Choose address type', spa: 'Selecciona tipo de dirección'
        }],
        Empty_Email_Address: [{
            en: "Email Address can't be blank", spa: 'El campo de la dirección de correo electrónico no puede dejarse en blanco'
        }],
        Empty_Password: [{
            en: "Password can't be blank", spa: 'El campo de la contraseña no puede dejarse en blanco'
        }],
        Invalid_Mobile: [{
            en: "Invalid Mobile Number", spa: 'Número de teléfono celular no válido'
        }],
        Update_Mobile: [{
            en: "UPDATE MOBILE NUMBER", spa: 'ACTUALIZAR NÚMERO DE MÓVIL'
        }],
        OTHER: [{
            en: 'OTHER', spa: 'OTRO'
        }],
        or: [{
            en: 'OR', spa: 'O'
        }],
        Tracking_Number: [{
            en: 'Tracking Number', spa: 'Número de seguimiento del envío'
        }],
        ORDER_SUMMARY: [{
            en: 'ORDER SUMMARY', spa: 'RESUMEN DEL PEDIDO'
        }],
        ORDER_STATUS: [{
            en: 'ORDER STATUS', spa: 'ESTADO DEL PEDIDO'
        }],
        ORDER_NO: [{
            en: 'ORDER NO', spa: 'NÚMERO DEL PEDIDO'
        }],
        NO_ORDER_ERROR: [{
            en: 'No orders found', spa: 'No se han encontrado pedidos'
        }],
        Select_Delivery_Date: [{
            en: 'Select Delivery Date', spa: 'Seleccione Fecha de Entrega'
        }],
        The_delivery_date_may_vary: [{
            en: 'The delivery date may vary by +/- 2-3 days.', spa: 'La fecha de entrega puede variar en +/- 2-3 días.'
        }],
        ORDER_Placed: [{
            en: 'Order Placed Successfully', spa: 'Pedido hecho de modo satisfactorio'
        }],
        subTotal: [{
            en: 'Sub Total', spa: 'Subtotal'
        }],
        Shipping: [{
            en: 'Shipping', spa: 'Envío'
        }],
        Applied_Shipping: [{
            en: 'Shipping charges of $', spa: 'Se aplicarán cargos de envío de $'
        }],
        // Applied_Shipping_2: [{
        //     en: ' will be applicable', spa: ' por el envío del pedido'
        // }],
        Total_Amount: [{
            en: 'Total Amount', spa: 'Cantidad total'
        }],
        CONTINUE: [{
            en: 'CONTINUE', spa: 'CONTINUAR'
        }],
        Expected_Delivery: [{
            en: 'Expected delivery by ', spa: 'Espere entrega antes del '
        }],
        Expected_Delivery_On: [{
            en: 'Expected Delivery On ', spa: 'ENTREGA ESPERADA EN '
        }],
        Expected_Delivery_Around:[{
            en: 'Expect delivery around ', spa: 'Espere entrega alrededor del '
        }],
        billing_address: [{
            en: 'BILLING ADDRESS', spa: 'Dirección '
        }],
        card_details: [{
            en: 'CARD DETAILS', spa: 'DETALLES DE LA TARJETA'
        }],
        CARD: [{
            en: 'CARD', spa: 'TARJETA'
        }],
        CARD_Discount: [{
            en: 'Credit Card/Debit Card', spa: 'Tarjeta de Crédito/Tarjeta de Debito'
        }],
        Gift_CARD_ToolTip_Discount: [{
            en: 'Gift Card Discount - We offer the following volume discount levels when you purchase Gift Cards from Northgate.',
            spa: 'Descuento de Tarjeta de Regalo - Ofrecemos los siguientes niveles de descuento por volumen cuando compra Tarjetas de Regalo de Northgate.'
        }],
        Gift_CARD_ToolTip_Discount_Not_Found: [{
            en: 'Gift Card Discount - No discount present.',
            spa: 'Descuento de Tarjeta de Regalo - No hay descuento disponible.'
        }],
        CARD_NUMBER: [{
            en: 'CARD NUMBER', spa: 'NÚMERO DE TARJETA'
        }],
        CARDHOLDER: [{
            en: 'CARDHOLDER’S NAME', spa: 'NOMBRE EN LA TARJETA'
        }],
        MONTH: [{
            en: 'MONTH', spa: 'MES'
        }],
        YEAR: [{
            en: 'YEAR', spa: 'AñO'
        }],
        Select_MONTH: [{
            en: 'Select Month', spa: 'Seleccionar mes'
        }],
        Select_Year: [{
            en: 'Select Year', spa: 'Seleccionar año'
        }],
        CVV: [{
            en: 'CVV', spa: 'CVV'
        }],
        billing_same: [{
            en: 'My billing address is same as my shipping address', spa: 'Mi dirección es la misma que la dirección de envío. '
        }],
        Make_Payment: [{
            en: 'Make Payment', spa: 'Hacer Pago'
        }],
        Billing_Address_Error: [{
            en: "Please enter the billing address", spa: "Te pedimos que ingreses la dirección de facturación "
        }],
        cvv: [{
            en: 'cvv', spa: 'cvv'
        }],
        payment_details: [{
            en: 'Payment Details', spa: 'Detalles del pago'
        }],
        shipping_details: [{
            en: 'Shipping Details', spa: 'Detalles del envío '
        }],
        First_Name: [{
            en: 'First Name', spa: 'Nombre'
        }],
        Last_Name: [{
            en: 'Last Name', spa: 'Apellido'
        }],
        mobile: [{
            en: 'Mobile', spa: 'Móvil'
        }],
        guest_user_details: [{
            en: 'Guest user details', spa: 'Detalles del usuario visitante'
        }],
        my_profile: [{
            en: 'My Profile', spa: 'Mi perfil'
        }],
        profile_info_updated: [{
            en: 'Profile information updated successfully', spa: 'Actualización satisfactoria de la información de perfil'
        }],
        my_cart: [{
            en: 'My Cart', spa: 'Mi carrito de compras'
        }],
        Logout: [{
            en: 'Logout', spa: 'Salir'
        }],
        ADD: [{
            en: 'ADD', spa: 'AGREGAR'
        }],
        gift_Card_details: [{
            en: 'Gift Card Details', spa: 'Detalles de la tarjeta de regalo'
        }],
        card_selection: [{
            en: 'Cards Selected', spa: 'Tarjetas seleccionadas'
        }],
        all_fields_mandatory: [{
            en: '*Note: All fields are mandatory', spa: 'Importante: Es obligatorio llenar todos los campos'
        }],
        Note: [{
            en: '*Note ', spa: '*Aviso '
        }],
        giift_card_price_limit: [{
            en: 'Maximum allowed cart value is $3,000', spa: 'El valor máximo permitido por carrito es de $3,000'
        }],
        gift_card_discount_info: [{
            en: 'Your total discount will be applied at checkout', spa: 'Su descuento total se aplicará al finalizar la compra.'
        }],
        shipping_form_mandatory: [{
            en: ' Fill the form below or Login to continue', spa: 'Rellene el siguiente formulario o inicie sesión para continuar'
        }],
        shipping_form_mandatory_mobile: [{
            en: 'Invalid mobile number ', spa: ''
        }],
        already_account: [{
            en: 'Already have an account?', spa: '¿Ya tiene una cuenta?',
        }],
        login_here: [{
            en: 'Login Here', spa: 'Entre Aquí'
        }],
        consent: [{
            en: 'I consent to receiving promotional and marketing emails from Northgate Gonzalez, LLC and its affiliates and agree to their ', 
            spa: 'Doy mi consentimiento para recibir correos electrónicos promocionales y de mercadeo de Northgate Gonzalez, LLC y sus afiliados y acepto su',

        },
        {
            en: "Privacy Policy", spa: "Póliza de Privacidad"
        }
        ],
        enter_first_name: [{
            en: 'Please enter the First Name', spa: 'Te pedimos que ingreses el nombre'
        }],
        enter_last_name: [{
            en: 'Please enter the Last Name', spa: 'Te pedimos que ingreses el apellido'
        }],
        enter_company_name: [{
            en: 'Please enter the Company Name', spa: 'Te pedimos que ingreses el nombre de la empresa'
        }],
        enter_email: [{
            en: 'Please enter the Email Address', spa: 'Por favor, introduzca la dirección de correo electrónico'
        }],
        invalid_phone: [{
            en: 'Invalid Phone Number', spa: 'Número de teléfono celular no válido'
        }],
        set_password_msg: [{
            en: 'Please set your Password', spa: 'Te pedimos que establezcas tu contraseña'
        }],
        confirm_password_msg: [{
            en: 'Please confirm your Password', spa: 'Te pedimos que confirmes tu contraseña'
        }],
        password_updated_success: [{
            en: 'Password updated successfully!', spa: '¡Actualización satisfactoria de la contraseña!'
        }],
        password_match_error: [{
            en: 'Passwords do not match', spa: 'Las contraseñas no coinciden'
        }],
        activation_link: [{
            en: 'Your activation link has been sent to your email id. Click and activate your account to shop exciting gift cards.', spa: 'Se envió el enlace de activación, revisa tu correo electrónico '
        }],
        phone_number: [{
            en: 'Phone Number', spa: 'Número de teléfono'
        }],
        enter_phone_number: [{
            en: 'Please enter your Phone Number', spa: 'Por favor, introduzca su número de teléfono'
        }],
        mobile_number: [{
            en: 'Mobile Number', spa: 'Número de celular'
        }],
        enter_mobile_number: [{
            en: 'Please enter your Mobile Number', spa: 'Please enter your Mobile Number'
        }],
        customer_password_length: [{
            en: 'Password should be minimum 8 characters long', spa: 'La contraseña debe tener un mínimo de 8 caracteres'
        }],
        required_field: [{
            en: 'This Field is required', spa: 'Este campo es requerido'
        }],
        customer_activation_success: [{
            en: 'Your account is active now, please login.', spa: 'Tu cuenta está activa ahora, te pedimos que ingreses.'
        }],
        consent_title: [{
            en: 'Consent', spa: 'Consentimiento'
        }],
        english: [{
            en: 'English', spa: 'Inglés'
        }],
        spanish: [{
            en: 'Spanish', spa: 'Español'
        }],
        EDIT_USER_NAME: [{
            en: "EDIT USERNAME ", spa: "EDITAR NOMBRE DE USUARIO"
        }],
        FAQ_HEADING: [{
            en: "Frequently asked questions", spa: "Preguntas frecuentes"
        }],
        ACH: [{
            en: 'ACH', spa: 'ACH(transferencia bancaria electrónica)'
        }],
        ACH_Discount: [{
            en: 'ACH/Electronic Bank Account Payment', spa: 'Pago por cuenta bancaria electrónica/ACH (transferencia bancaria electrónica)'
        }],
        ACH_Name: [{
            en: "NAME", spa: "NOMBRE"
        }],
        ACH_Routing_Number: [{
            en: "ROUTING NUMBER", spa: "NÚMERO DE RUTA BANCARIA"
        }],
        ACH_Account_Number: [{
            en: "ACCOUNT NUMBER", spa: "NÚMERO DE CUENTA"
        }],
        ACH_Account_Type: [{
            en: "ACCOUNT TYPE", spa: "TIPO DE CUENTA"
        }],
        ACH_Check_Number: [{
            en: "CHECK NUMBER", spa: "NÚMERO DE CHEQUE"
        }],
        ACH_Check_Type: [{
            en: "CHECK TYPE", spa: "TIPO DE CHEQUE"
        }],
        ACH_Account_Discription: [{
            en: "ACCOUNT TYPE DESCRIPTION", spa: "DESCRIPCIÓN DEL TIPO DE CUENTA"
        }],
        ACH_Select_Discription: [{
            en: "Select Type Description", spa: "Selecciona tipo de descripción"
        }],
        ACH_Select_Account_Type: [{
            en: "Select Account Type", spa: "Selecciona tipo de cuenta"
        }],
        ACH_Select_Check_Type: [{
            en: "Select Check Type", spa: "Selecciona tipo de cheque"
        }],
        ACH_DropDown_Checking: [{
            en: "Checking Account", spa: "Cuenta de cheques",
        }],
        ACH_DropDown_Saving: [{
            en: "Savings Account", spa: "Cuenta de ahorros"
        }],
        ARC: [{
            en: "ARC - Accounts Receivable Check Conversion", spa: "ARC - Conversión de cheque a cuentas por cobrar"
        }],
        BOC: [{
            en: "BOC - Back-Office Conversion", spa: "BOC - Conversión administrativa interna "
        }],
        C21: [{
            en: "C21 - Check 21", spa: "C21 - Ley Cheque 21 "
        }],
        CCD: [{
            en: "CCD - Cash Concentration or Disbursement", spa: "CCD - Concentración de efectivo o desembolso"
        }],
        POP: [{
            en: "POP - Point-of-Purchase Check Conversion", spa: "POP - Conversión de cheque a punto de compra"
        }],
        PPD: [{
            en: "PPD - Prearranged Payment and Deposit", spa: "PPD - Pago y depósito predefinidos"
        }],
        TEL: [{
            en: "TEL - Telephone Authorized Entry", spa: "TEL - Ingreso autorizado por teléfono"
        }],
        WEB: [{
            en: "WEB - Internet Authorized Entry", spa: "WEB - Ingreso autorizado por internet"
        }],
        FAQ: [{
            en: "FAQ", spa: "PREGUNTAS FRECUENTES"
        }],

    }

}