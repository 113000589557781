import React from 'react';

//stateless component
function Consent(prosp) {
    return (
            prosp.lang == 'en' ?

            <div>
            <h5 style={{textAlign: 'center'}}><br />
            PRIVACY POLICY<br />
            </h5>
            <h5>Effective Date: January 01, 2020<br/></h5>
            <p>This Privacy Policy describes how Northgate Gonzalez, LLC, Northgate Gonzalez Financial, LLC, and their affiliates (collectively, <strong>“Northgate”</strong>) collect, use, disclose, and protect your personal information.  It also contains legal disclosures, information regarding your rights concerning your personal information, and contact information for Northgate for matters related to your personal information.&nbsp; &nbsp;&nbsp;</p>
            <h5 className="m-0">CONSENT TO PRIVACY POLICY:</h5>
            <p>When you access a Northgate website, use a Northgate mobile application, or provide personal information to Northgate by any means, you agree to the terms of this Privacy Policy.  &nbsp; &nbsp;&nbsp;</p>
            <p>Special offers, promotions, contests, or specific services might use or require different personal information practices than are described in this Privacy Policy.  If so, those practices will be identified in the specific materials related to those offers, promotions, contests, or services. &nbsp;</p>
            <h5 className="m-0">
            CATEGORIES OF PERSONAL INFORMATION COLLECTED:</h5>
            <p>Northgate has collected or may collect the following categories of personal information:</p>
            <ul>
                <li>Identifiers. </li>
                <li>Characteristics of protected classifications under California or federal law.</li>
                <li>Commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies</li>
                <li>Biometric information</li>
                <li>Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement</li>
                <li>Geolocation data.</li>
                <li>Audio, electronic, visual, thermal, olfactory, or similar information.</li>
                <li>Professional or employment-related information.</li>
                <li>Education information, defined as information that is not publicly available personally identifiable information as defined in the Family Educational Rights and Privacy Act (20 U.S.C. section 1232g, 34 C.F.R. Part 99).</li>
                <li>To the extent not covered by another category listed above: name, signature, social security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, financial information, medical information, or health insurance information.</li>
                <li>Inferences drawn from any of the information identified above to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</li>
            </ul>
            <br />
            <p>The personal information Northgate collects may (i) include personal information or data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, (ii) involve the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, and (iii) include data concerning health or data concerning a natural person’s sex life or sexual orientation.</p><br />
            <h5 className="m-0">
            SOURCES OF PERSONAL INFORMATION:</h5>
            <p>Northgate has collected or may collect personal information from the following sources:</p>
            <ul>
                <li>Directly from consumers, including when you register as a user of one or more of Northgate’s websites or applications, shop online or in Northgate’s stores, sign up for or use Northgate’s products or services, enroll in a marketing programs, cash a check, or contact Northgate.</li>
                <li>Automatically through Northgate’s websites, and applications, which may include cookies, web beacons, and similar technologies.</li>
                <li>Security cameras when you are at Northgate’s facilities.</li>
                <li>Third parties whose goods and services Northgate directly or indirectly offers or promotes. </li>
                <li>Third parties who provide Northgate with goods or services to help Northgate operate its businesses.</li>
                
            </ul>
            <p>Northgate may use cookies, web beacons, and similar technologies in connection with its websites, mobile applications, and email messages.&nbsp;</p>
           
            <h5 className="m-0">
            SHARING OF PERSONAL INFORMATION:</h5>
            <p>Northgate has shared or may share personal information with the following categories of third parties:</p>
           
            <ul>
                <li>Third parties whose goods and services Northgate directly or indirectly offers or promotes.</li>
                <li>Third parties who provide Northgate with goods or services to help Northgate operate its businesses.</li> 
                <li>Professional advisors and consultants.</li>  
                <li>Governmental authorities.</li>
                <li>Participants in legal proceedings.</li>
            </ul>
            <p>Your personal information may be disclosed as part of an actual or proposed corporate business transaction, such as a merger, acquisition, joint venture, or financing or sale of company assets, and could be transferred to a third party as one of the business assets in such a transaction. It may also be disclosed in the event of insolvency, bankruptcy, or receivership.</p>
            
            <h5 className="m-0">
            PURPOSES FOR COLLECTING PERSONAL INFORMATION:</h5>
            <p>Northgate has collected or may collect personal information for the following purposes:</p>
                <ul>
                <li> Communicating with consumers.</li>
                <li> Providing consumers with goods and services.</li> 
                <li> Marketing or advertising goods and services.</li> 
                <li> Verifying consumers’ identities.</li>
                <li> Keeping records of activities.</li>
                <li> Conducting statistical, demographic, and other analysis.</li> 
                <li> Monitoring and analyzing trends, usage, and activities.</li>
                <li> Conducting market research, surveys, and similar inquiries.</li>
                <li> Preventing, investigating, or providing notice of fraud, unlawful activity, or unauthorized access to or use of information, websites, or data systems.</li>
                <li> Complying with applicable law or meeting legal obligations.</li>
                <li> Engaging in legal proceedings.</li>
                <li> Collecting debts.</li>
                <li> Satisfying contractual obligations.</li>
                <li> Assisting law enforcement or other government agencies.</li> 
                <li> Personalizing consumers’ experiences.</li>
                <li> Entering consumers into sweepstakes and promotions.&nbsp;&nbsp;</li>

            </ul>
            <br />
            <p>Northgate may link personal information (such as your name or e-mail address) with non-personal information (such as the unique identifier associated with your computer or mobile device). Northgate may also link personal information with other generally or publicly available information to help it identify your preferences or interests. The information Northgate collects may also be merged with information available from other sources such as (1) companies that match e-mail addresses with postal addresses and other information; (2) credit card verification and credit services; and (3) Northgate’s affiliates.</p>
            <h5 className="m-0">
            SELLING OF PERSONAL INFORMATION:</h5>
            <p>For purposes of this Section, “sell” has the meaning provided for under California Civil Code Section 1798.140(t).  Northgate does not sell any personal information.  Accordingly, Northgate does not sell the personal information or minors under 16 years of age without affirmative authorization.  </p>
            <h5 className="m-0">
            PERSONALLY IDENTIFIABLE INFORMATION:</h5>
            <p>For purposes of this Section, “personally identifiable information” has the meaning provided for under California Business & Professions Code Section 22577(a).  Northgate collects the categories of personally identifiable information set forth in the Section above entitled “Categories of Personal Information Collected” and may share personally identifiable information with the categories of third parties set forth in the Section above entitled “Sharing of Personal Information.</p>
            <br />
            <p>Northgate’s websites do not respond to “do not track” signals or similar mechanisms. </p>
           <br />
           <p>Northgate is not a part of a third party tracking network and does not allow third parties to collect personally identifiable information about consumers’ online activities through Northgate’s website. Northgate may also allow third parties to serve ads on its websites and applications, and if you visit those third parties’ websites, they may collect information about your visit or use. This Privacy Policy does not apply to the practices of third parties that collect your personal information. Northgate encourages you to check the third parties’ privacy policies to learn about their practices concerning their collection, use, disclosure, and protection of your personal information.</p>
            <br />
            <p>Except as provided in the Section below entitled “Your Rights Regarding Personal Information After Collection,” Northgate does not maintain a process for an individual consumer who uses or visits its website, application, or online service to review and request changes to any of his or her personally identifiable information.</p>
            <h5 className="m-0">YOUR RIGHTS REGARDING PERSONAL INFORMATION AFTER COLLECTION</h5>
            <p>
            You have the right to request that Northgate disclose to you: the specific pieces of your personal information that it collected, the categories of your personal information that it collected, the categories of third parties (recipients) to whom Northgate provided your personal information, the business or commercial purpose for processing, collecting, or selling your personal information, the sources from which Northgate collected your personal information, the existence of your rights of access, rectification, deletion, and portability regarding your personal information, your rights to restrict or object to processing, and to complain to a supervisory authority concerning, your personal information, Northgate’s retention period for your personal information, and any automated decision-making, including profiling, made using your personal information</p>
            <br />
            <p>You have the right to request that Northgate delete the personal information it has collected regarding you and that it require its service providers to do so as well.  However, Northgate have no obligation to comply with your request if there is a legal basis for the processing of your personal information, including:</p>
            <ul>
                <li>You have consented to the processing.</li>
                <li>The processing is for purposes of initiating or fulfilling a contract with you, including completing transactions and providing goods and services.</li>
                <li>The processing is necessary to comply with Northgate’s legal obligations, including under the California Electronic Communications Privacy Act.</li>
                <li>The processing is necessary to protect Northgate’s interests or the interests of third parties, including detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity (or prosecuting those responsible), debugging to identify and repair functionality errors, exercising or ensuring the right of another to exercise free speech or another legal right; or to enable solely internal uses aligned with a consumer’s expectations given their relationship with the business.</li>

            </ul>
            <p>You have the right to require that Northgate correct erroneous personal information it has collected regarding you.</p>
            <br />
            <p>As stated above, Northgate does not currently sell personal information.  If Northgate changes its policies or practices regarding the sale of personal information, you will have the right to opt-out of Northgate’s sale of your personal information.</p>
            <br />
            <p>You may have the right to receive a copy of your personal information in a structured, commonly used, machine-readable format and to have your personal information transmitted by Northgate to another controller without hindrance from Northgate.</p>   
            <br />
            <p>You have the right to withdraw, in whole or in part, any consent you have given to Northgate regarding the processing, collection, use, storage, disclosure, and sale of your personal information. </p>
            <br />
            <p>You have the right to object to the processing of your personal information.  If you object, Northgate will only process your personal information if it can prove it has compelling legitimate reasons for doing so that outweigh your interests, rights, and freedoms, or for the establishment, exercise, or defense of legal claims.  </p>
            <br />
            <p>If you believe that the processing of your personal information violates legal requirements, you have the right to lodge a complaint with a competent data protection supervisory authority.</p>
            <br />
            <p>You can control the marketing communications you receive from Northgate by updating your profile through a Northgate application, clicking the “unsubscribe” link in Northgate emails, or contacting Northgate using the information below.</p>
            <br />
            <p>Note that there may be a delay between the time you make an opt-out, removal, or revocation requests and the time it is processed by Northgate.  </p>
            <br />
            <p>You may make requests under this section by calling (888) 672-9832 or sending an email on <a href="mailto:privacy@northgatemarkets.com">privacy@northgatemarkets.com.</a> Requests will be acknowledged within 10 days. Northgate will review each request and then contact the person making the request for verification purposes. The verification process will vary depending on who is making the request (e.g., the consumer versus an agent of the consumer), the nature of the request (e.g., categories of personal information versus specific pieces of personal information), and the consumer’s interactions with Northgate. The person making the request may be required to provide personal information about the particular consumer so that Northgate may match that information against its records. If a consumer uses an agent to make a request, Northgate may require the consumer to provide the agent with written permission to make the request and verify their own identity directly with Northgate. The verification process may be accomplished by telephone, via text message, using email, and/or through other electronic means such as a password-protected account through Northgate. Northgate reserves the right to deny requests in whole or in part to the extent permitted under applicable law, including if it cannot verify a consumer’s identity with the required degree or certainty or in good faith suspects fraudulent or malicious activity concerning the request. Northgate will respond to requests within 45 days, subject to extensions of up to an additional 45 days for which the consumer or its agent will receive notice and an explanation for the reasons the extension is required.   
            </p>
            <br />
            <h5 className="m-0">FINANCIAL INCENTIVES</h5>
            <p>Northgate offers coupons and similar discounts and benefits through its marketing program. These items may be considered a “financial incentive” or result in a “price or service difference” under the regulations to the California Consumer Privacy Act.  The categories of personal information that are implicated by Northgate’s marketing program are:</p>

         <ul>
            <li>Identifiers;</li>
            <li>Commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies;</li>
            <li>Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement;</li>
            <li>Geolocation data; and</li>
            <li>Inferences drawn from any of the information identified above to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes </li>
        </ul>
        <p>You can opt-in to the financial incentive or price or service difference by contacting Northgate using the methods identified in the “How to Contact Us” section below.  You can withdraw from the financial incentive at any time by using the methods identified in the “Marketing Choices and Opt-Out” section below.  The financial incentive or price or service difference that may result from participating in Northgate’s marketing programs would be permitted under the California Consumer Privacy Act because, based on industry knowledge and sales trends related to Northgate’s historical marketing practices,  Northgate expects to receive more in annual revenue from a consumer who participates in Northgate’s marketing program compared to a similar consumer who does not participate.  Northgate in good faith estimates that this difference in annual revenue will be at least $5.00, but it does not use loyalty cards or similar methods that would allow it to accurately track revenue relative to participation in its marketing program at an individual consumer level.</p> <br />
        <h5 className="m-0">INFORMATION PERTAINING TO CHILDREN</h5>
        <p>Northgate is the only operator of its website, and its contact information is listed in the “How to Contact Us” section below. Northgate does not knowingly or intentionally collect personal information from children under the age of 16, and has taken steps to avoid doing so through its website. Accordingly, Northgate does not enable children to make their personal information publicly available, knowingly or intentionally use their information in any manner, or knowingly or intentionally disclose their information to anyone. If a child under the age of 16 has or may have provided personal information to Northgate, a parent or guardian may inform us of that fact by contacting us using one of the methods listed in the “How to Contact Us” section below, and Northgate will then review its records and delete any such information from its database. If you are under the age of 16, do not provide Northgate with any personal information either directly or indirectly (e.g., by posting personal information on Northgate’s website). Any personal information that Northgate receives from a person whom it believes is under the age of 16 will be deleted.</p>
        <h5 className="m-0">SECURITY OF PERSONAL INFORMATION</h5>
        <p>Northgate uses various physical, electronic, and procedural measures to protect your personal information from unauthorized disclosure or misuse. Northgate employ Secure Sockets Layer (SSL) technology and other security measures to encrypt sensitive personal information you input, such as credit card numbers, during transmission. However, because no data security systems are completely secure, Northgate does not warrant that any personal information that you provide to it will be secure or safe from unauthorized access. If you provide any personal information to Northgate, you do so at your own risk. Northgate may retain your personal information for as long as permitted by applicable law.  Northgate may transfer your personal information to and use and store your personal information in any location.  Northgate is based in the United States.  When you interact with Northgate, your personal information may be transferred to a country other than your own, including the United States.  Data protection rules of other countries may be different from those of your country. Certain locations may not provide safeguards for your personal information, creating risks that it will be disclosed to or used by third parties in a manner that may be harmful or objectionable to you.  Courts, law enforcement agencies, regulatory agencies, or security authorities in other countries may be entitled to access your personal information.  Any collection of personal information will be made in accordance with all applicable laws.  For example, Northgate will not obtain a credit report without first obtaining your specific and express authorization for us to do so</p><br />
        <h5 className="m-0">LINKS TO OTHER WEBSITES</h5>
        <p>Northgate’s website may contain links to websites operated by third parties. Northgate may, from time to time, at its sole discretion, add or remove links to other websites. These links are provided solely as a convenience to you, and access to any such websites is at your own risk. Your business dealings, communications with or participations in promotions with advertisers (other than Northgate) and any terms, conditions, warranties or representations associated with such dealings are solely between you and those third parties. When you visit their websites, they may collect information about your visit and use it to provide advertisements to you.</p><br />
        <h5 className="m-0">SOCIAL NETWORK WEBSITES</h5>
        <p> By accessing the links to certain social network websites posted on Northgate’s website, application, or service, you may have interactions that you initiate with Northgate on those third party websites, applications, or services. Examples of such interactions include enabling you to transmit content from your account on a third party website, application, or service; to “like” or “share” Northgate’s content to other websites, applications, or services; and to otherwise connect Northgate’s website, application, or service to a third party website, application, or service.</p> <br />
        <p>Information you post or to which you provide access on such third-party websites and services may be publicly displayed on Northgate’s or a third party’s website, application, or service. Similarly, if you post information on a third party website, application, or service that references Northgate’s website (e.g., by using a hashtag associated with Northgate in a tweet or status update), your post may be published on Northgate’s website or social network websites in accordance with the terms of the third party website or service. Also, both Northgate and the third party may have access to certain information about you and your use of Northgate’s website and the third party site or service.</p><br />
        <p>The information Northgate collects in connection with its social network websites (e.g., Northgate’s Facebook page) is subject to this Privacy Policy. The information collected and stored by the third parties remains subject to those third parties’ privacy practices.</p><br />
        <h5 className="m-0">MARKETING CHOICES AND OPT-OUT</h5>
        <p>Northgate may send you email messages, direct mail offers, or other communications regarding products or services unless you specifically ask it not to do so when you enroll in its marketing program, access its website or application, or change your preferences by updating any accounts you may have with Northgate. At any time, you may elect to discontinue receiving commercial messages from Northgate by submitting an opt-out request to the Northgate Customer Service Center (see the “How to Contact Us” Section below) or by following the instructions in the form of the communication you received, as described below:</p>
        <ul>
            <li>Emails: To opt out of receiving marketing materials via email, use the method provided in the emails or contact the Northgate Customer Service Center.</li>
            <li>Printed Materials: To opt out of receiving printed marketing materials at your postal address, such as catalogs, flyers, or postcards, contact the Northgate Customer Service Center.</li>
            <li>Voice Messages: To opt out of receiving voice messages by telephone, you may follow the instructions stated in the pre-recorded voice message or contact the Northgate Customer Service Center.</li>
            <li>Text Messages: To opt out of receiving text messages or for more information about Northgate’s mobile marketing, refer to Northgate’s Mobile Marketing Terms and Conditions.</li>

        </ul>
        <h5 className="m-0">
        CHANGES TO THIS POLICY
        </h5>
        <p>Northgate will notify you of any changes to this Privacy Policy by posting a modified version of this Privacy Policy on its websites and indicating the date it was last modified (i.e., the effective date). Northgate encourages you to visit its websites periodically to view the latest version of this Privacy Policy.</p><br />
        <h5 className="m-0">HOW TO CONTACT US</h5>
            <p>Please contact the Northgate Customer Service Center by mail, email, or telephone, if you have any questions, comments, or concerns about this Privacy Policy or privacy-related comments or questions:</p>
            <br /><p>Email:<a href="mailto:Privacy@NorthgateMarkets.com">Privacy@NorthgateMarkets.com</a></p><br />
            <address>
            NORTHGATE GONZALEZ, LLC<br />
            Attn: Northgate Customer Service Center <br />
            1201 N. Magnolia Avenue <br />
            Anaheim, CA 92801 <br />
            Phone: (888) 672-9832 <br />

            </address>
            <br />
            {/* <strong>You may also exercise certain rights identified above using this form [link] </strong> */}

            </div>

                :
                <div>
            <h5 style={{textAlign: 'center'}}><br />
            POLÍTICA DE PRIVACIDAD<br />
            </h5>
            <h5>Fecha efectiva: 1 de enero de 2020<br/></h5>
            <p>Esta Política de Privacidad describe cómo Northgate Gonzalez, LLC, Northgate Gonzalez Financial, LLC, y sus afiliados (colectivamente, “Northgate”) recopilan, utilizan, divulgan, y protegen su información personal.  También incluye divulgaciones legales, e información relacionada a sus derechos correspondientes sobre información personal, e información de contacto para Northgate por asuntos relacionados con su información personal.&nbsp; &nbsp;&nbsp;</p>
            <h5 className="m-0">CONSENTIMIENTO A LA POLÍTICA DE PRIVACIDAD</h5>
            <p>Cuando accede a una página web de Northgate, utiliza una aplicación móvil de Northgate, o proporciona información personal a Northgate por cualquier medio, acepta los términos de esta Política de Privacidad.   &nbsp; &nbsp;&nbsp;</p>
            <p>Las ofertas especiales, promociones, concursos o servicios específicos pudieran utilizar o requerir prácticas de información personal diferentes a las descritas en esta Política de Privacidad.  De ser así, esas prácticas se identificarán en los materiales específicos relacionados con esas ofertas, promociones, concursos o servicios. &nbsp;</p>
            <h5 className="m-0">
            CATEGORÍAS DE INFORMACIÓN PERSONAL RECOPILADA</h5>
            <p>Northgate ha recopilado o puede recopilar las siguientes categorías de información personal:</p>
            
            <ul>
                <li>Identificadores</li>
                <li>Características de las clasificaciones protegidas bajo la ley de California o federal</li>
                <li>Información comercial, incluidos registros de bienes personales, productos o servicios adquiridos, obtenidos o considerados, u otras historias o tendencias de compra o consumo</li>
                <li>Información biométrica</li>
                <li>Información de Internet u otra actividad de red electrónica, incluyendo, pero no limitado a, historial de navegación, historial de búsqueda, e información sobre la interacción de un consumidor con un sitio web de Internet, aplicación o publicidad</li>
                <li>Datos de geolocalización</li>
                <li>Información de audio, electrónica, visual, térmica, olfativa o similar</li>
                <li>Información profesional o laboral</li>
                <li>Información académica, definida como información que no es de acceso público, información personalmente identificable tal como se define en la Ley de Derechos Educativos y Privacidad de la Familia (20 U.S.C. sección 1232g, 34 C.F.R. Apartado 99)</li>
                <li>En la medida en que no esté incluida en otra categoría enumerada anteriormente: nombre, firma, número de seguridad social, características físicas o descripción, domicilio, número de teléfono, número de pasaporte, licencia de conducir o número de identificación estatal, número de póliza de seguro, educación, empleo, historial de empleo, número de cuenta bancaria, número de tarjeta de crédito, número de tarjeta de débito, información financiera, información médica o información del seguro médico</li>
                <li>Inferencias extraídas de cualquier información identificada anteriormente para crear un perfil sobre un consumidor que refleja las preferencias del consumidor, características, tendencias psicológicas, predisposiciones, comportamiento, actitudes, inteligencia, habilidades y aptitudes</li> 
            </ul><br />
            <p>La información personal que Northgate recopila puede (i) incluir información o datos personales que revelen origen racial o étnico, opiniones políticas, creencias religiosas o filosóficas, o afiliación sindical, (ii) involucrar el procesamiento de datos genéticos, datos biométricos con el fin de identificar de forma única a una persona física, e (iii) incluir datos relativos a la salud o datos relativos a la vida sexual o la orientación sexual de una persona física.</p>
            <br />
            
            <h5 className="m-0">
            FUENTES DE INFORMACIÓN PERSONAL</h5>
            <p>Northgate ha recopilado o puede recopilar información personal de las siguientes fuentes:</p>
            <ul>
                <li>Directamente de los consumidores, incluso cuando se registra como usuario de uno o más sitios web o aplicaciones de Northgate, compra en línea o en las tiendas de Northgate, se registra para utilizar productos o servicios de Northgate, se inscribe en un programa de marketing, cobra un cheque, o contacta a Northgate.</li>
                <li>Automáticamente a través de los sitios web y aplicaciones de Northgate, que pueden incluir cookies, contadores de visitas y tecnologías similares. </li>
                <li>Cámaras de seguridad cuando usted está en las instalaciones de Northgate.</li>
                <li>Terceros cuyos bienes y servicios Northgate ofrece o promueve directa o indirectamente. </li>
                <li>Terceros que proporcionan a Northgate bienes o servicios para ayudar a Northgate a operar sus negocios.</li>
                
            </ul>
            <p>Northgate puede utilizar cookies, contadores de visitas y tecnologías similares en relación con sus sitios web, aplicaciones móviles y mensajes de correo electrónico.&nbsp;</p>
           
            <h5 className="m-0">
            INTERCAMBIO DE INFORMACIÓN PERSONAL</h5>
            <p>Northgate ha compartido o puede compartir información personal con las siguientes categorías de terceros:</p>
           
            <ul>
                <li>Terceros cuyos bienes y servicios Northgate ofrece o promueve directa o indirectamente.</li>
                <li>Terceros que proporcionan a Northgate bienes o servicios para ayudar a Northgate a operar sus negocios.</li> 
                <li>Asesores y consultores profesionales.  </li>  
                <li>Autoridades gubernamentales.</li>
                <li>Participantes en procedimientos judiciales.</li>
            </ul>
            <br />
            <p>Su información personal puede ser divulgada como parte de una transacción comercial corporativa real o propuesta, como una fusión, adquisición, contrato de asociación, o financiación o venta de activos de la compañía, y podría transferirse a un tercero como un activo comercial de dicha operación. También pudiera divulgarse en caso de insolvencia, quiebra o administración judicial.</p>
            
            <h5 className="m-0">
            PROPÓSITOS PARA RECOPILAR INFORMACIÓN PERSONAL</h5>
            <p>Northgate ha recopilado o puede recopilar información personal para los siguientes propósitos:</p>
                <ul>
                    <li>Comunicarse con los consumidores.</li>
                    <li>Proporcionar bienes y servicios a los consumidores.</li> 
                    <li>Comercializar o publicitar bienes y servicios. </li>
                    <li>Verificar las identidades de los consumidores.</li>
                    <li>Llevar registros de las actividades.</li>
                    <li>Realizar análisis estadísticos, demográficos y de otro tipo.</li> 
                    <li>Monitorear y analizar tendencias, uso y actividades.</li>
                    <li>Realizar estudios de mercado, encuestas y otras investigaciones similares.</li>
                    <li>Prevenir, investigar o dar aviso de fraude, actividad ilegal o acceso no autorizado a información, sitios web o sistemas de datos.</li>
                    <li>Cumplir con la ley aplicable o cumplir con las obligaciones legales.</li>
                    <li>Participar en procedimientos judiciales.</li>
                    <li>Cobrar deudas.</li>
                    <li>Cumplir con obligaciones contractuales.</li>
                    <li>Apoyar a las fuerzas del orden público u otras dependencias de gobierno. </li>
                    <li>Personalizar las experiencias de los consumidores.</li>
                    <li>Introducir a los consumidores en sorteos y promociones.&nbsp;&nbsp;</li>
               

            </ul>
            <br />
            <p>Northgate puede vincular información personal (como su nombre o dirección de correo electrónico) con información no personal (como el identificador único asociado con su computadora o dispositivo móvil).  Northgate también puede vincular información personal con otra información general o públicamente disponible para ayudarle a identificar sus preferencias o intereses La información que Northgate recopila también puede combinarse con la información disponible de otras fuentes, como (1) empresas que combinan direcciones de correo electrónico con direcciones postales y otra información; (2) verificación de tarjetas de crédito y servicios de créditos; y (3) filiales de Northgate.</p>
            <h5 className="m-0">
            VENTA DE INFORMACIÓN PERSONAL</h5>
            <p>Para efectos de esta Sección, el término “vender” se apega a lo establecido en la Sección 1798.140(t) del Código Civil de California. Northgate no vende ninguna información personal.  En congruencia, Northgate no vende la información personal ni de menores de 16 años sin autorización afirmativa. </p>
            <h5 className="m-0">
            INFORMACIÓN PERSONALMENTE IDENTIFICABLE</h5>
            <p>Para efectos de esta Sección, el término “información personalmente identificable” se apega a lo establecido en la Sección 22577(t) del Código de Negocios y Profesiones de California.  Northgate recopila las categorías de información personalmente identificable expuestas en la sección anterior titulada "Categorías de Información Personal Recopilada" y puede compartir información personalmente identificable con las categorías de terceros establecidas en la sección anterior titulada “Intercambio de Información Personal.”</p>
            <br />
            <p>Los sitios web de Northgate no responden a señales de "no seguimiento" o mecanismos similares. </p>
           <br />
           <p>Northgate no forma parte de alguna red de seguimiento de terceros y no permite a terceros recopilar información personalmente identificable sobre las actividades en línea de los consumidores a través del sitio web de Northgate. Northgate también puede permitir que terceros publiquen anuncios en sus sitios web y aplicaciones, y si usted visita esos sitios web de terceros, pueden recopilar información sobre su visita o uso. Esta Política de Privacidad no aplica a las prácticas de terceros que recopilan su información personal. Northgate le invita a revisar las políticas de privacidad de terceros para conocer sus prácticas relativas a la recopilación, uso, divulgación y protección de su información personal.</p>
            <br />
            <p>Salvo lo dispuesto en la Sección siguiente titulada “Sus Derechos en Relación con la Información Personal Después de ser Recopilada”, Northgate no mantiene un proceso para un consumidor individual que utiliza o visita su sitio web, solicitud, o servicio en línea para revisar y solicitar cambiar su información personalmente identificable.</p>
            <h5 className="m-0">SUS DERECHOS EN RELACIÓN CON LA INFORMACIÓN PERSONAL DESPUÉS DE SER RECOPILADA</h5>
            <p>
            Usted tiene el derecho de solicitar que Northgate le revele: las secciones específicas de información personal que ha recopilado, las categorías de información personal que ha recopilado, las características de los terceros (receptores) a quienes Northgate ha proporcionado su información personal, el propósito comercial o de negocio para procesar, recopilar o vender su información personal, las fuentes de las que Northgate ha obtenido su información personal, la existencia de sus derechos al acceso, rectificación, eliminación y portabilidad de su información personal, sus derechos a restringir u objetar el procesamiento, y a quejarse ante una autoridad supervisora en relación con su información personal, el período de reserva de Northgate para su información personal, y cualquier decisión automatizada, incluyendo la creación de un perfil, realizada con su información personal.    </p>
            <br />
            <p>Usted tiene derecho a solicitar que Northgate elimine la información personal que haya recopilado sobre usted y que exija a sus proveedores de servicios que también lo hagan.  Sin embargo, Northgate no tiene ninguna obligación de cumplir con su solicitud si existe una base legal para el procesamiento de su información personal, incluyendo que:</p>
            <ul>
                <li>Usted haya consentido al procesamiento.</li>
                <li>El procesamiento sea para fines de iniciar o cumplir un contrato con usted, incluyendo finiquitar transacciones y proporcionar bienes y servicios.</li>
                <li>El procesamiento sea necesario para cumplir con las obligaciones legales de Northgate, incluyendo la Ley de Privacidad de Comunicaciones Electrónicas de California.</li>
                <li>El procesamiento sea necesario para proteger los intereses de Northgate o de terceros, incluyendo la detección de incidentes de seguridad, protección contra actividades maliciosas, engañosas, fraudulentas o ilegales (o procesar a los responsables), depuración para identificar y reparar errores de funcionalidad, ejercicio o garantía del derecho de otra persona a ejercer la libertad de expresión u otro derecho legal; o para permitir usos exclusivamente internos alineados con las expectativas del consumidor dadas sus relaciones con la empresa.</li>

            </ul>
            <p>Usted tiene el derecho de exigir que Northgate corrija la información personal errónea que ha recopilado sobre usted.</p>
            <br />
            <p>Como se ha indicado anteriormente, Northgate no vende actualmente información personal.  Si Northgate cambiara las políticas o prácticas de venta de información personal, usted podrá optar por no participar en la venta de su información personal ofrecida por Northgate.</p>
            <br />
            <p>Usted podría tener derecho a recibir copia de su información personal en un formato estructurado, de uso común y legible en dispositivos electrónicos y a que su información personal sea transmitida por Northgate a otro controlador sin perjuicio por parte de Northgate.  </p>   
            <br />
            <p>Usted tiene el derecho de retirar, total o parcialmente, cualquier consentimiento que haya dado a Northgate con respecto al procesamiento, recopilación, uso, almacenamiento, divulgación y venta de su información personal.  </p>
            <br />
            <p>Usted tiene derecho a oponerse al procesamiento de su información personal.  Si usted se opusiera, Northgate sólo procesará su información personal si pudiera comprobar que tiene razones legítimas convincentes para hacerlo, las cuales prevalecen sobre sus intereses, derechos y libertades, o para establecer, ejercer o defender sus demandas legales.</p>
            <br />
            <p>Si cree que el procesamiento de su información personal viola los requisitos legales, tiene derecho a presentar una queja ante una autoridad de protección de datos competente.</p>
            <br />
            <p>Usted puede gestionar las comunicaciones de marketing que recibe de Northgate actualizando su perfil a través de una aplicación de Northgate, haciendo clic en el enlace "cancelar la suscripción" en los correos electrónicos de Northgate, o contactando a Northgate utilizando la siguiente información.</p>
            <br />
            <p>Tenga en cuenta que puede haber un desfase entre el momento en que realiza una solicitud de exclusión, eliminación o revocación y el momento en que es procesada por Northgate. </p>
            <br />
            <p><p>Usted pude hacer solicitudes bajo esta sección llamando al (888) 672-9832 o enviando un correo electrónico 
	            a <a href="mailto:privacy@northgatemarkets.com">privacy@northgatemarkets.com</a>. Las solicitudes serán atendidas en un plazo de 10 días. Northgate revisará cada solicitud y contactará a quien la hizo para fines de verificación. El proceso de verificación variará dependiendo de quién haga la solicitud (por ejemplo, el consumidor frente a un agente del consumidor), la naturaleza de la solicitud (por ejemplo, categorías de información personal frente a categorías específicas de información personal), y las interacciones del consumidor con Northgate. Se puede solicitar a quien hace la solicitud que proporcione información personal sobre el consumidor en particular para que Northgate pueda cotejarla en sus registros. Si un consumidor recurre a un agente para hacer una solicitud, Northgate podría exigir al consumidor proporcionar al agente un permiso por escrito para hacer la solicitud y verificar su propia identidad directamente con Northgate. El proceso de verificación puede realizarse por teléfono, por mensaje de texto, por correo electrónico y/o por otros medios electrónicos, como por ejemplo, una cuenta protegida por contraseña a través de Northgate. Northgate se reserva el derecho a rechazar las solicitudes en su totalidad o en parte en la medida permitida por la ley aplicable, incluyendo si no se pudiera verificar la identidad del consumidor con el grado o la certeza requerida o si de buena fe se sospechara de una actividad fraudulenta o maliciosa con respecto a la solicitud. Northgate responderá a las solicitudes en un plazo de 45 días, sujeto a ampliaciones de hasta 45 días adicionales para los cuales el consumidor o su agente recibirá aviso y una explicación de las razones por las que se requiere la prórroga.</p>   
            </p>
            <br />
            <h5 className="m-0">INCENTIVOS FINANCIEROS</h5>
            <p>Northgate ofrece cupones y descuentos y beneficios similares a través de su programa de marketing. Estos artículos pudieran ser considerados como un "incentivo financiero" o resultar en una "diferencia de precio o servicio" bajo las regulaciones de la Ley de Privacidad del Consumidor de California.  Las categorías de información personal que están implicadas por el programa de marketing de Northgate son:</p>

         <ul>
           
            <li>Identificadores;</li>
            <li>Información comercial, incluyendo registros de bienes personales, productos o servicios adquiridos, obtenidos o considerados, u otras historias o tendencias de compra o consumo;</li>
            <li>Información de Internet u otra actividad de red electrónica, incluyendo, pero no limitado a, historial de navegación, historial de búsqueda, e información sobre la interacción de un consumidor con un sitio web de Internet, aplicación o publicidad;</li>
            <li>Datos de geolocalización; e</li>
            <li>Inferencias extraídas de cualquier información identificada anteriormente para crear un perfil sobre un consumidor que refleja las preferencias del consumidor, características, tendencias psicológicas, predisposiciones, comportamiento, actitudes, inteligencia, habilidades y aptitudes 
            </li>
        </ul>
        <p>Puede optar por el incentivo financiero o la diferencia de precio o servicio contactando a Northgate utilizando los medios identificados en la sección siguiente "Cómo Contactarnos".  Puede renunciar al incentivo financiero en cualquier momento utilizando los medios identificados en la sección "Opciones de Marketing y Exclusión Voluntaria" indicada más abajo.  El incentivo financiero o la diferencia de precio o servicio que puede resultar de participar en los programas de marketing de Northgate sería autorizado por la Ley de Privacidad del Consumidor de California, porque, con base en el conocimiento de la industria y las tendencias de ventas relacionadas con las prácticas históricas de marketing de Northgate, Northgate espera recibir más en ingresos anuales de un consumidor que participa en el programa de marketing de Northgate en comparación con un consumidor similar que no participa.  Northgate, de buena fe, calcula que esta diferencia en ingresos anuales será de al menos $5.00, pero no hace uso de tarjetas de lealtad o métodos similares que le permitan rastrear con precisión los ingresos relativos a la participación en su programa de marketing a nivel de consumidor individual.   </p> <br />
        <h5 className="m-0">INFORMACIÓN RELATIVA A LOS NIÑOS</h5>
        <p>Northgate es el único operador de su sitio web, y su información de contacto aparece en la sección "Cómo Contactarnos" indicada más abajo. Northgate no recopila a sabiendas o intencionalmente información personal de niños menores de 16 años, y ha tomado medidas para evitar hacerlo a través de su sitio web. En consecuencia, Northgate no permite a los niños hacer pública su información personal, utilizar su información de cualquier manera, o revelar su información a alguien de forma consciente o intencionada. Si un niño menor de 16 años ha facilitado o pudiera haber facilitado información personal a Northgate, uno de sus padres o tutores puede informarnos de ello contactándonos a través de alguno de los medios que se indican en la sección "Cómo Contactarnos" que aparece más abajo, y Northgate revisará sus registros y eliminará toda esa información de su base de datos. Si tienes menos de 16 años, no proporciones a Northgate información personal directa o indirectamente (por ejemplo, publicando información personal en el sitio web de Northgate). Toda información personal que Northgate reciba de una persona que parezca menor a 16 años será eliminada.</p>
        <h5 className="m-0">SEGURIDAD DE LA INFORMACIÓN PERSONAL</h5>
        <p>Northgate utiliza diversas medidas físicas, electrónicas y de procedimiento para proteger su información personal de la divulgación no autorizada o el mal uso. Northgate utiliza la tecnología Secure Sockets Layer (SSL) y otras técnicas de seguridad para cifrar la información personal sensible que usted introduce, como los números de tarjeta de crédito, durante la transmisión. Sin embargo, ya que ningún sistema de seguridad de datos es completamente seguro, Northgate no garantiza que alguna información personal que usted proporcione estará segura o a salvo del acceso no autorizado. Si usted proporciona información personal a Northgate, lo hace bajo su propio riesgo. Northgate puede conservar su información personal durante el tiempo que lo permita la legislación aplicable.  Northgate puede transferir su información personal y utilizar y almacenar su información personal en cualquier lugar.  Northgate tiene su sede en los Estados Unidos.  Cuando usted interactúa con Northgate, su información personal puede ser transferida a un país distinto del suyo, incluyendo los Estados Unidos.  Las leyes para la protección de datos de otros países pueden ser diferentes a las de su país. Es posible que algunas localidades no ofrezcan garantías para proteger su información personal, lo que implica riesgos de que sea divulgada o utilizada por terceros de manera que pueda resultar perjudicial u ofensiva para usted.  Los tribunales, los organismos encargados de hacer cumplir la ley, los órganos reguladores o las autoridades de seguridad de otros países pueden tener derecho a acceder a su información personal.  Cualquier recopilación de información personal se hará de acuerdo con todas las legislaciones pertinentes.  Por ejemplo, Northgate no obtendrá un historial crediticio sin obtener primero su autorización expresa y específica para que lo podamos hacer.</p><br />
        <h5 className="m-0">ENLACES A OTROS SITIOS WEB</h5>
        <p>El sitio web de Northgate puede contener enlaces a sitios web operados por terceros. Northgate puede, de vez en cuando y a su entera discreción, añadir o eliminar enlaces a otros sitios web. Estos enlaces se le proporcionan únicamente para su conveniencia, y el acceso a cualquiera de estos sitios web es bajo su propio riesgo. Sus relaciones comerciales, comunicaciones o participaciones en promociones con anunciantes (que no sean Northgate) y los términos, condiciones, garantías o representaciones asociadas a dichas transacciones son exclusivamente entre usted y dichas terceras partes. Cuando usted visita sus sitios web, ellos pueden recopilar información sobre su visita y utilizarla para enviarle publicidad.</p><br />
        <h5 className="m-0">SITIOS WEB DE REDES SOCIALES</h5>
        <p>Al acceder a los enlaces a ciertos sitios web de redes sociales publicados en el sitio web, aplicación o el servicio de Northgate, es posible que se produzcan interacciones que usted inicie con Northgate en esos sitios web, aplicaciones o servicios de terceros. Ejemplos de estas interacciones incluyen la posibilidad de transmitir el contenido de su cuenta en un sitio web, aplicación o servicio de terceros; para dar "Me gusta" o "compartir" al contenido de Northgate con otros sitios web, aplicaciones o servicios; y para conectar de otra forma el sitio web, la aplicación o el servicio de Northgate con un sitio web, aplicación o servicio de terceros. </p> <br />
        <p>La información que usted publique o a la que proporcione acceso en dichos sitios web y servicios de terceros puede ser mostrada públicamente en el sitio web, aplicación o servicio de Northgate o de un tercero. Del mismo modo, si usted publica información en un sitio web, aplicación o servicio de terceros que haga referencia al sitio web de Northgate (por ejemplo, utilizando un hashtag asociado con Northgate en un tweet o en una actualización de estado), su publicación puede aparecer en el sitio web de Northgate o en los sitios web de redes sociales de acuerdo con los términos del sitio web o servicio de terceros. Además, tanto Northgate como el tercero pueden tener acceso a cierta información sobre usted y el uso que haga del sitio web de Northgate y del sitio o servicio del tercero. </p><br />
        <p>	
La información que Northgate recopila en relación con sus sitios web de redes sociales (por ejemplo, la página de Facebook de Northgate) está sujeta a esta Política de Privacidad. La información recopilada y almacenada por terceras partes sigue estando sujeta a las prácticas de privacidad de dichas terceras partes.</p><br />
        <h5 className="m-0">OPCIONES DE MARKETING Y EXCLUSIÓN VOLUNTARIA</h5>
        <p>Northgate puede enviarle mensajes de correo electrónico, ofertas de correo directo u otras comunicaciones relacionadas con productos o servicios, a menos que usted específicamente solicite que no lo haga al inscribirse en su programa de marketing, al acceder a su sitio web o a su aplicación, o al cambiar sus preferencias mediante la actualización de cualquier cuenta que pueda tener con Northgate. En cualquier momento, usted puede optar por dejar de recibir mensajes comerciales de Northgate mediante la presentación de una solicitud de exclusión voluntaria en el Centro de Atención al Cliente de Northgate (véase la sección "Cómo Contactarnos" más abajo) o siguiendo las instrucciones en el formulario de la comunicación que recibió, como se describe a continuación:</p>
        <ul>
           
            <li>Correos electrónicos: Para dejar de recibir información de marketing por correo electrónico, use el método proporcionado en los correos electrónicos o póngase en contacto con el Centro de Servicio al Cliente de Northgate.</li>
            <li>Materiales Impresos: Para dejar de recibir materiales impresos de marketing en su domicilio postal, como catálogos, volantes o postales, contacte al Centro de Servicio al Cliente de Northgate.</li>
            <li>Mensajes de Voz: Para dejar de recibir mensajes de voz por teléfono, puede seguir las instrucciones indicadas en el mensaje de voz pre-grabado o ponerse en contacto con el Centro de Servicio al Cliente de Northgate.</li>
            <li>Mensajes de Texto: Para dejar de recibir mensajes de texto o para mayor información sobre el marketing móvil de Northgate, consulte los Términos y Condiciones de Marketing Móvil de Northgate.</li>
        </ul>
        <br />
        <h5 className="m-0">
        CAMBIOS EN ESTA POLÍTICA
        </h5>
        <p>Northgate le notificará cualquier cambio en esta Política de Privacidad publicando una versión modificada de la misma en sus sitios web e indicando la fecha en que se modificó por última vez (es decir, la fecha de entrada en vigor). Northgate le invita a visitar sus sitios web de manera regular para ver la última versión de esta Política de Privacidad.</p><br />
        <h5 className="m-0">CÓMO CONTACTARNOS</h5>
            <p>Favor de contactar al Centro de Servicio al Cliente de Northgate por correo postal, correo electrónico o teléfono, si tiene preguntas, comentarios o inquietudes acerca de esta Política de Privacidad o comentarios o preguntas relacionadas con la privacidad:</p>
            <br /><p>Correo electrónico:<a href="mailto:Privacy@NorthgateMarkets.com">  Privacy@NorthgateMarkets.com</a></p><br />
           

            <address>
            NORTHGATE GONZALEZ, LLC<br />
            Atn: Centro de Servicio al Cliente de Northgate <br />
            1201 N. Magnolia Avenue <br />
            Anaheim, CA 92801<br />
            Teléfono (888) 672-9832 <br />

            </address>
            <br />
            {/* <strong>Usted también puede ejercer ciertos derechos indicados anteriormente usando este formulario[link] </strong> */}

            </div>
            )
}
export default Consent;