import React, { Component } from 'react';
import DiscountManagements from './discountManagement';
import * as constant from '../../../Constants'

/** Main class for discount management */  
class DiscountManagement extends Component {
  
  /**
   * React Built in method 
   * invoked just after component mounted
   */
  componentDidMount() {
    try {
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.history.push("/ng-admin");
      } else if (sessionStorage.roleID != constant.ADMIN_USER && sessionStorage.isSuperAdmin != 'true') {
        this.props.history.push("/ng-admin/error");
      }
    } catch (err) { }
  }

  render() {
    let pathParam = this.props.location.pathname.split("/").pop()
    var DiscountManagement;
    if (this.props.location.state == undefined) {
      DiscountManagement = <DiscountManagements componentID={this.props} pathParam={pathParam} />
    }
    return (DiscountManagement);
  }
}
export default DiscountManagement;
