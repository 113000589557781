import * as constants from '../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';

/*
* Function for get roles list
  @param NULL
  @return object object of api resoponse
*/
export function getRolesAction() {
  return (dispatch) => {
    axios.post(serverRoutes.GET_API_ROLES)
      .then(function (response) {
        dispatch({
          type: constants.USER_ROLES,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.USER_ROLES_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for userManagementList and get users list
  @param NULL
  @return object object of api resoponse
*/
export function userListAction(userId) {
  return (dispatch) => {
    axios.post(serverRoutes.GET_USERS, { userId })
      .then(function (response) {
        dispatch({
          type: constants.USER_MANAGEMENT_LIST_DATA,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.USER_MANAGEMENT_LIST_DATA_ERROR,
          payload: response
        })
      })
  }
}

/*
* Function for add new user
  @param object object of user
  @return object object of api resoponse
*/
export function userAddAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.CREATE_USER, userData)
      .then(function (response) {
        dispatch({
          type: constants.USER_MANAGEMENT_ADD_RESPONSE,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.USER_MANAGEMENT_ADD_RESPONSE_ERROR,
          payload: response
        })
      })
  }
}

/*
* Function for update user status
  @param object object of user
  @return object object of api resoponse
*/
export function updateUserStatusAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.UPDATE_STATUS, userData)
      .then(function (response) {
        dispatch({
          type: constants.USER_MANAGEMENT_USER_STATUS_DATA_RESPONSE,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.USER_MANAGEMENT_USER_STATUS_DATA_RESPONSE_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Function for edit user
 * @param object object of user
 * @return object object of api response
 */
export function userUpdateAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.UPDATE_USER, userData)
      .then(function (response) {
        dispatch({
          type: constants.USER_MANAGEMENT_UPDATE_RESPONSE,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.USER_MANAGEMENT_UPDATE_RESPONSE_ERROR,
          payload: response.data
        })
      })
  }
}


/*
* Function to change password for admin/fullfilment user
  @param object object of user
  @return object object of api resoponse
*/
export function changePasswordAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.CHANGE_PASSWORD, userData)
      .then(function (response) {
        dispatch({
          type: constants.ADMIN_CHANGE_PASSWORD,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.ADMIN_CHANGE_PASSWORD_ERROR,
          payload: response.data
        })
      })
  }
}

export function navigationSideBarAction(toggleClass) {
  return (dispatch) => {
    dispatch({
      type: 'navigation',
      payload: toggleClass
    })
  }
}
/*Function to send reset password link */
export function resetPwdLinkAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.SEND_RESET_PWD_LINK, userData)
      .then(function (response) {
        dispatch({
          type: constants.SEND_RESET_PWD_LINK,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.SEND_RESET_PWD_LINK_ERROR,
          payload: response.data
        })
      })
  }
}

/*Function to update user password */
export function updateUserPwdAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.UPDATE_USER_PWD, userData)
      .then(function (response) {
        dispatch({
          type: constants.UPDATE_USER_PWD,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.UPDATE_USER_PWD_ERROR,
          payload: response.data
        })
      })
  }
}

/*Function to view home setting */
export function viewHomeSettingAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.VIEW_HOME_SETTING, userData)
      .then(function (response) {
        dispatch({
          type: constants.VIEW_HOME_SETTING,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.VIEW_HOME_SETTING_ERROR,
          payload: response.data
        })
      })
  }
}

/*Function to update home setting data */

export function updateHomeSettingAction(userData) {
  return (dispatch) => {
    axios.post(serverRoutes.UPDATE_HOME_SETTING, userData)
      .then(function (response) {
        dispatch({
          type: constants.UPDTATE_HOME_SETTING,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.UPDTATE_HOME_SETTING_ERROR,
          payload: response.data
        })
      })
  }
}