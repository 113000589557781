import * as constants from '../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';

/**
 * Function to get FAQ list
 * @param {*} data 
 */
export function getFAQListAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.GET_FAQ_LIST, data)
      .then(function (response) {
        dispatch({
          type: constants.GET_FAQ_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.GET_FAQ_LIST_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Function to create FAQ
 * @param {*} data 
 */
export function createFAQAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.CREATE_FAQ, data)
      .then(function (response) {
        dispatch({
          type: constants.CREATE_FAQ,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CREATE_FAQ_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Function to update FAQ
 * @param {*} data 
 */
export function updateFAQAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.UPDATE_FAQ, data)
      .then(function (response) {
        dispatch({
          type: constants.UPDATE_FAQ,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.UPDATE_FAQ_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Function to get FAQ details
 * @param {*} data 
 */
export function getFAQDetailsAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.GET_FAQ_DETAILS, data)
      .then(function (response) {
        dispatch({
          type: constants.GET_FAQ_DETAILS,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.GET_FAQ_DETAILS_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Function to delete FAQ
 * @param {*} data 
 */
export function deleteFAQAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.DELETE_FAQ, data)
      .then(function (response) {
        dispatch({
          type: constants.DELETE_FAQ,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.DELETE_FAQ_ERROR,
          payload: response.data
        })
      })
  }
}

/**
 * Function to get FAQ total recordes
 * @param {*} data 
 */
export function getFAQTotalRecordsAction() {
  return (dispatch) => {
    axios.post(serverRoutes.GET_TOTAL_FAQ_RECORDS)
      .then(function (response) {
        dispatch({
          type: constants.GET_FAQ_TOTAL_RECORDS,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.GET_FAQ_TOTAL_RECORDS_ERROR,
          payload: response.data
        })
      })
  }
}