import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
}

/**
 * Function for add to card product
 * @param {object} state set initial state in default case
 * @param {object} action
 */
export function addToCart(state = data, action) {

  switch (action.type) {
    case constants.ADD_TO_CART_PRODUCT:
      let newState = Object.assign({}, state, { data: action.payload });
      return newState;
    default:
      return state;
  }
}

/**
 * Function to add customer address 
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function createOrder(state = data, action) {
  switch (action.type) {
    case constants.BUY_GIFT_CARD:
      return { ...state, login: true, data: action.payload };
    case constants.BUY_GIFT_CARD_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}
