import * as constants from '../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';

/*
* Function for dashboardOrderListAction and get order list
  @param NULL
  @return object object of api resoponse
*/
export function dashboardOrderListAction() {
  return (dispatch) => {
    axios.post(serverRoutes.DASHBOARD_ORDER_LIST)
      .then(function (response) {
        dispatch({
          type: constants.DASHBOARD_ORDER_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.DASHBOARD_ORDER_LIST_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for pendingOrderListAction and pending order list
  @param NULL
  @return object object of api resoponse
*/
export function pendingOrderListAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.PENDING_ORDER_LIST, data)
      .then(function (response) {
        dispatch({
          type: constants.PENDING_ORDER_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.PENDING_ORDER_LIST_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for cancelledOrderListAction and cancelled order list
  @param NULL
  @return object object of api resoponse
*/
export function cancelledOrderListAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.CANCELLED_ORDER_LIST, data)
      .then(function (response) {
        dispatch({
          type: constants.CANCELLED_ORDER_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.CANCELLED_ORDER_LIST_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for activatedGiftCardAction and activated giftcard list
  @param NULL
  @return object object of api resoponse
*/
export function activatedGiftCardListAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.ACTIVATED_GIFT_CARD_LIST, data)
      .then(function (response) {
        dispatch({
          type: constants.ACTIVATED_GIFTCARD_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.ACTIVATED_GIFTCARD_LIST_ERROR,
          payload: response.data
        })
      })
  }
}

/*
* Function for pendingGiftCardAction and pending giftcard list
  @param NULL
  @return object object of api resoponse
*/
export function pendingGiftCardListAction(data) {
  return (dispatch) => {
    axios.post(serverRoutes.PENDING_GIFT_CARD_LIST, data)
      .then(function (response) {
        dispatch({
          type: constants.PENDING_GIFTCARD_LIST,
          payload: response.data
        })
      })
      .catch(function (response) {
        dispatch({
          type: constants.PENDING_GIFTCARD_LIST_ERROR,
          payload: response.data
        })
      })
  }
}