import React, {Component} from 'react';
import UserManagements from './userManagements';

/** Main class for UserManagement*/
class UserManagement extends Component{

    componentDidMount(){
        try{
            if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
              this.props.history.push("/ng-admin");
          } else if(sessionStorage.hasOwnProperty("adminLoggedIn") && sessionStorage.isSuperAdmin != 'true') {
            this.props.history.push("/ng-admin/error");
          }
          }catch (err){
      
          }
      }

    render() {
            let pathParam = this.props.location.pathname.split("/").pop()
            let UserManagement;
            if (this.props.location.state == undefined) {
                UserManagement = <UserManagements componentID = {this.props}
                pathParam={pathParam} pageProps={this.props}
                />
            } else if(this.props.location.state == 'add') {
                UserManagement = <div>this is add page</div>
            } else if(this.props.location.state == 'edit') {
                UserManagement = <div>this is Edit page</div>
            } else {
                UserManagement = <div>this is index page</div>
            }
            return UserManagement;
        }
}

export default UserManagement;
