import React, { Component } from 'react';
import Header from '../Layout/Header';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import _ from "lodash"
import 'react-accessible-accordion/dist/fancy-example.css';

// Class for PrivacyPolicy 
class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: sessionStorage.language != undefined ? sessionStorage.language : "english",
      PrivacyPolicyDataList: [],
      Loading: false,
      PrivacyPolicyLangDataList: [],
      fontSize: {
        breadcrumb: 12,
        titleFont: 24,
        accordionFont:18
      },
      increaseCounter: 0,
      decreaseCounter: 0,
    }
  }

  /**
   * Method set language on this component
   * @param {*} data 
   */
  setLanguage(data) {
    this.setState({
      lang: data,
    })
  }

  /**
   * Method for change size of font  
   * @param {data} 
   */
  changeSize(data) {
    if (data === 'increase') {
      var increaseCounter = this.state.increaseCounter
      increaseCounter = increaseCounter + 1
      if (increaseCounter <= 4) {
        this.setState({
          fontSize: {
            breadcrumb: Number(this.state.fontSize.breadcrumb) + 1,
            titleFont: Number(this.state.fontSize.titleFont) + 1,
            accordionFont:Number(this.state.fontSize.accordionFont) + 1,
          },
          increaseCounter: increaseCounter
        })
      }
    }
    else if (data === 'decrease') {
      var decreaseCounter = this.state.decreaseCounter
      decreaseCounter = decreaseCounter + 1
      if (decreaseCounter <= 4) {
        this.setState({
          fontSize: {
            breadcrumb: Number(this.state.fontSize.breadcrumb) - 1,
            titleFont: Number(this.state.fontSize.titleFont) - 1,
            accordionFont:Number(this.state.fontSize.accordionFont) - 1,
          },
          decreaseCounter: decreaseCounter,
          increaseCounter: 0
        })
      }
    }
    else if (data === 'default') {
      this.setState({
        fontSize: {
          breadcrumb: 12,
          titleFont: 24,
          accordionFont:18
        },
        increaseCounter: 0,
        decreaseCounter: 0
      })
    }
  }



  /***
   * Method for getting sorted list as per language specific
   * 
   */
  getPrivacyPolicyList = () => {
    // console.log("PrivacyPolicyLangDataList=======",this.state)
    // try{
    //   let PrivacyPolicyList = this.state.PrivacyPolicyLangDataList;
    //   let lang = this.state.lang === "en" ? "English" : this.state.lang === "spa" ? "Spanish" : "English"
    //   let langSpecificPrivacyPolicyList = _.filter(PrivacyPolicyList, function (list) {
    //     if(list.lang !== null && list.lang !== undefined) {
    //       return list.lang.toUpperCase() === lang.toUpperCase()
    //     }
    //   })
    //   return langSpecificPrivacyPolicyList
    // }catch(e){}
    
  }

  // Method to redirect to buy gift card page
  redirectToPrivacyPolicy = (event) => {
    try {
      if (this.props.history !== undefined) {
        if (sessionStorage.emailID === '' || sessionStorage.emailID == undefined) {
          this.props.history.push('/')
        } else {
          this.props.history.push("/ordermanagement")
        }

      }
    } catch (e) { }
  }

  render() {
    let filtredPrivacyPolicyList = []
    filtredPrivacyPolicyList = this.getPrivacyPolicyList()
    const { fontSize } = this.state
    return (
      <div id="myaccount">
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }
        <div className="wrapper page customerHome" >
        
          <Header language={this.setLanguage.bind(this)} changeSize={this.changeSize.bind(this)} locationProps={this.props} />
          <section id="main" className="shipping-page">
            <div className="container-fluid">
              <div className="row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item "><a style={{ fontSize: fontSize.breadcrumb + 'px' }} href='javascript:void(0);' onClick={(e) => { this.redirectToPrivacyPolicy(e) }} className="active">Home</a></li>
                  <li className="breadcrumb-item"><a style={{ fontSize: fontSize.breadcrumb + 'px' }} href="#">PrivacyPolicy</a></li>
                </ul>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="account-section">
                    <h2 style={{ fontSize: fontSize.titleFont + 'px' }}>Privacy Policy</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </section>
        </div>
        <footer>
          <p>© 2019 Northgate Gonzalez Market®</p>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // getPrivacyPolicyList: state.getPrivacyPolicyList,
  };
}

export default connect(mapStateToProps)(PrivacyPolicy);
