import React from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import { orderDetailsAction } from '../../../Actions/Admin/orderManagementAction';
import { activateGiftCardAction } from '../../../Actions/Admin/giftCardMangementAction';
import moment from "moment"
import * as HelperUtil from "../../../utils/common-helper"
import * as constant from '../../../Constants/';
import { Alert } from 'reactstrap';
import ReactLoading from 'react-loading'
import _ from 'lodash'
import InputMask from 'react-input-mask'
import Helmet from 'react-helmet';


/** Class for entering the gift card refrence number */
class GiftCardReferenceRangePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: '',
            orderDetails: {
                customerName: '',
                shippingType: '',
                expectedShippingDate: '',
                orderNumber: '',
                orderDate: '',
                orderedProduct: [],
                subTotal: '',
                discountAmount: '',
                shippingAmount: '',
                totalAmount: '',
                shippingAddress1: '',
                shippingAddress2: '',
                shippingAddressId: '',
                shippingCity: '',
                shippingCountry: '',
                shippingState: '',
                shippingZip: '',
                trackingNumber: '',
                totalCards: ''
            },
            nextOrderId: '',
            openAcknowledgeModal: false,
            pin: '',
            openCancelOrderModal: false,
            cancellationReason: '',
            referenceRange: '',
            referenceArray: [],
            flashMessageError: '',
            flashMessageSuccess: '',
            Loading: false,
            activeClassName: '',
            checkPointUpdateState: false,
            referenceValue: ''
        }
    }
    /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
    async componentWillMount() {
        this.setState({ activeClassName: this.props.pathParam })
        var data = { "orderId": this.props.orderInfo, "loggedInUser": sessionStorage.userID, "language": "en" }
        if (this.props.orderInfo != '') {
            await this.props.orderDetailsAction(data)
            this.setState({ Loading: true })
        }
    }

    /**
* React Built method 
* keep waiting for change in props 
* @param {*} nextProps 
*/
    componentWillReceiveProps(nextProps) {
        try {
            let self = this
            if (nextProps.orderInfo != '') {
                this.setState({
                    orderId: nextProps.orderInfo
                })
            }
            if (nextProps.orderDetails && nextProps.orderDetails.data && nextProps.orderDetails.data.error === false) {
                var orderDetails = nextProps.orderDetails.data.data.data
                this.setState({
                    orderDetails: {
                        customerName: orderDetails.customerName,
                        shippingType: orderDetails.shippingType,
                        expectedShippingDate: orderDetails.eta,
                        orderNumber: orderDetails.orderNumber,
                        orderDate: orderDetails.orderDate,
                        orderedProduct: orderDetails.orderedProduct,
                        subTotal: orderDetails.subTotal,
                        discountAmount: orderDetails.discountAmount != null ? orderDetails.discountAmount : 0,
                        shippingAmount: orderDetails.shippingRate != null ? orderDetails.shippingRate : 0,
                        totalAmount: orderDetails.total,
                        shippingAddress1: orderDetails.shippingAddress1,
                        shippingAddress2: orderDetails.shippingAddress2,
                        shippingAddressId: orderDetails.shippingAddressId,
                        shippingCity: orderDetails.shippingCity,
                        shippingCountry: orderDetails.shippingCountry,
                        shippingState: orderDetails.shippingState,
                        shippingZip: orderDetails.shippingZip,
                        trackingNumber: orderDetails.orderTracking,
                        totalCards: orderDetails.totalCards,
                        pin: orderDetails.pin
                    },
                    Loading: false
                })
            } else {
                this.setState({ Loading: false })
            }
            if (nextProps.activateGiftCards && nextProps.activateGiftCards.data && self.state.checkPointUpdateState) {
                if (nextProps.activateGiftCards.data.data.hasOwnProperty('status') && nextProps.activateGiftCards.data.data.status === true) {
                    this.setState({
                        flashMessageSuccess: "Order activated successfully!!!",
                    })
                    setTimeout(function () {
                        self.setState({
                            Loading: false,
                            flashMessageSuccess: "",
                            checkPointUpdateState: false
                        })
                        nextProps.activateGiftCards.data.data = null
                        nextProps.pageProps.history.push({
                            pathname: "/ng-admin/giftcardmanagement",

                        })
                    }, 5000)
                }
                else {
                    this.setState({
                        flashMessageError: nextProps.activateGiftCards.data.data.message ? nextProps.activateGiftCards.data.data.message : 'Something went wrong',
                    })
                    setInterval(function () {
                        self.setState({
                            Loading: false,
                            flashMessageSuccess: "",
                            checkPointUpdateState: false
                        })
                        nextProps.activateGiftCards.data.data = null
                        nextProps.pageProps.history.push({
                            pathname: "/ng-admin/giftcardmanagement",
                        })
                    }, 5000);
                }
            } else {
                self.setState({
                    Loading: false,
                    checkPointUpdateState: false,
                    flashMessageSuccess: "",
                    flashMessageError: ''
                })
            }

        } catch (e) { }
    }

    /**
     * Method for handle navigation 
     */
    redirectToListPage() {
        if (this.props.pageProps.history != undefined)
            this.props.pageProps.history.push({
                pathname: '/ng-admin/giftcardmanagement'
            })
    }

    /** Method for open popup model */
    openShippingDetailPage() {
        let self = this
        self.props.pageProp.history.push({
            pathname: "/ng-admin/ordermanagement",
            state: {
                pageType: 'shippingPage',
            }
        })
    }

    /***
     * Method for set  Range value entred by user
     * @param{ Clickevent}
     *  
     * */
    setRange(e) {
        this.setState({
            flashMessageError: '',
            flashMessageSuccess: ''
        })
        let val = e.target.value;
        this.setState({
            referenceValue: val,

        })
    }

    /***
     * Method for set  Range value entred by user
     * @param{ onBlur evenrt}
     *  
     * */
    setRangeOnBlur(e, order) {
        e.target.placeholder = "Reference Number"
        let val = e.target.value;
        var arr = this.state.referenceArray
        let obj = { "id": order.id, "referenceNumber": val, "updatedBy": sessionStorage.userID }
        arr.push(obj)
        this.setState({
            referenceArray: arr,
        })
    }

    /** Method for activate gift card */
    activateGiftCards() {
        try {
            if (this.state.referenceValue != '') {
                let validArray = []
                let check = ''
                let activateRange = []
                let orderedProduct = this.state.orderDetails.orderedProduct
                let { referenceArray } = this.state
                orderedProduct.length ? orderedProduct.map((product) => {
                    check = _.findLastIndex(referenceArray, function (o) { return o.id == product.id; });
                    if (check >= 0)
                        validArray.push(check)
                }) : console.log('')
                if (validArray.length == orderedProduct.length) {
                    validArray.map((valid, index) => {
                        activateRange.push(referenceArray[valid])
                    })
                    var jsonToActivateGiftCards = {
                        "loggedInUser": sessionStorage.userID,
                        "language": "en",
                        "orderId": this.props.orderInfo,
                        "activateRange": activateRange,
                        "giftCardActivationDate": new Date()
                    }
                    if (jsonToActivateGiftCards.activateRange.length) {
                        let submit = jsonToActivateGiftCards.activateRange.every(function (activateRange) {
                            return activateRange.referenceNumber != ''
                        })
                        if (submit) {
                            this.setState({ Loading: true, checkPointUpdateState: true })
                            this.props.activateGiftCardAction(jsonToActivateGiftCards)
                        } else {
                            this.setState({
                                flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
                            })
                        }
                    }
                } else {
                    this.setState({
                        flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
                    })
                }
            } else {
                this.setState({
                    flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
                })
            }
        } catch (e) { }
    }

    componentDidMount() {
        if (this.props.activateGiftCards.data != null) {
            this.props.activateGiftCards.data = null
        }
    }

    render() {
        let activateDivHide = false
        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
        }
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
        }
        const { orderDetails } = this.state
        var noOfCards = 0
        var totalCards = orderDetails.orderedProduct.length > 0 ? orderDetails.orderedProduct.map((data) => {
            return noOfCards += parseInt(data.noOfCards)
        }) : 0
        return (<div className="admin_top">
            {
                this.state.Loading === true && <div className="loader-react">
                    <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                </div>
            }
            <Helmet>
                <title>Activate Gift Card</title>
            </Helmet>
            <div className="content-wrapper" className="d-flex flex-column">
                <Header />
                <div className="main">
                    <aside>
                        <div className="sidebar left">
                            <Navigation activeClassName={this.state.activeClassName} />
                        </div>
                    </aside>
                    <div className="dashboardContent">
                        <div className="container-fluid">
                            <div className="acknowledgeOrder-wrapper pagelist">
                                <div className="row mar0">
                                    <div className="col-md-8 customer-section-left">
                                        <h3 className="top_heading">Activate Gift Card</h3>
                                        <div className="acknowledgeOrder">
                                            {flashSuceessMessageSpan}
                                            {flashErrorMessageSpan}
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="product">
                                                        <h3>#{orderDetails.orderNumber}</h3>
                                                        <p>{moment(new Date(orderDetails.orderDate)).format('MM/DD/YYYY')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="nextOrder">
                                                        <label className="btn btn-light">Pin Number</label>
                                                        <label className="form-control">{orderDetails.pin}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                this.state.orderDetails.orderedProduct.length > 0 ?
                                                    this.state.orderDetails.orderedProduct.map((order) => {
                                                        return <div className="col-md-12">
                                                            <div className="acknowledgeOrderBox acknowledge_btm acknowledge_top ">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-2 col-sm-4">
                                                                                <div className="orderBoxSquare giftcardBox Custome_Order">
                                                                                    <img src={constant.APIURL + order.imagePath + order.imageName} className="media-object" alt="" />
                                                                                    <span className="giftOrderCircle">{order.noOfCards}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-10">
                                                                                <div className="row">
                                                                                    <div className="col-md-3">
                                                                                        <div className="orderBoxText_giftcard">
                                                                                            <h5 className="orderGift">{order.productType}</h5>
                                                                                            <span className="pb-3 pt-0">$ {HelperUtil._getAmountCommaSeparator(Number(order.productPrice).toFixed(2)).toString()}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        {order.productRange.map((product) => {
                                                                                            if (product.referenceNumber) activateDivHide = true;
                                                                                            return <div className="row">
                                                                                                <div className="col-md-5 nopadding p-0">
                                                                                                    <label className="rangelist">{product.rangeFrom}-<br />{product.rangeTo}</label>
                                                                                                </div>
                                                                                                <div className="col-md-5 nopadding">
                                                                                                    <InputMask type="text" className="form-control"
                                                                                                        mask={"********************"}
                                                                                                        maskChar={null}
                                                                                                        value={product.referenceNumber}
                                                                                                        readOnly={product.referenceNumber ? true : false}
                                                                                                        name="referenceRange" onInput={(e) => this.setRange(e)} onBlur={(e) => this.setRangeOnBlur(e, order)} onFocus={(e) => e.target.placeholder = ""} placeholder="Reference Number"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                    : <div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4 customer-section-right" id="customer-section-right" style={{ "height": "100vh" }}>
                                        <div className="side-heading sidegiftcard">
                                            <ul className="float-left">
                                                <li>Sub Total</li>
                                                <li>Discount</li>
                                                <li>Shipping Charges</li>
                                            </ul>
                                            <ul className="text-right right_sidetext">
                                                <li>$ {orderDetails.subTotal != '' ? HelperUtil._getAmountCommaSeparator(Number(orderDetails.subTotal).toFixed(2)).toString() : 0}</li>
                                                <li>-$ {orderDetails.discountAmount != '' ? HelperUtil._getAmountCommaSeparator(orderDetails.discountAmount.toFixed(2)).toString() : 0}</li>
                                                <li>+$ {orderDetails.shippingAmount != '' ? HelperUtil._getAmountCommaSeparator(orderDetails.shippingAmount.toFixed(2)).toString() : 0}</li>
                                            </ul>
                                            <div className="clearfix"></div>
                                            <div className="right_sideall_amount">
                                                <div className="totalAmount">
                                                    <p>Total Amount</p> <span> $ {HelperUtil._getAmountCommaSeparator(Number(orderDetails.totalAmount).toFixed(2)).toString()}</span>
                                                </div>
                                                <div className="totalAmount topborder">
                                                    <p>Total Cards</p> <span>{noOfCards}</span>
                                                </div>
                                            </div>
                                            <div className="button-activate ml-5" style={{ display: (activateDivHide) ? 'none' : 'block' }}>
                                                <button type="button" className="btn btn-light giftcard_button " onClick={this.activateGiftCards.bind(this)}>activate</button>
                                                <button type="button" className="btn btn-light cancelbtn giftcard_button cardnone_button" onClick={this.redirectToListPage.bind(this)}>back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orderDetails: state.orderDetails,
        activateGiftCards: state.activateGiftCards
    };
}

export default connect(mapStateToProps, { orderDetailsAction, activateGiftCardAction })(GiftCardReferenceRangePage);