import React from 'react';
import { Link } from 'react-router-dom';
import signout from '../../../../public/img/signout.png';
import userIcon from '../../../../public/img/user-icon.png';
// import CustomerLogo from '../../../../public/img/CustomerLogo.png';
import CustomerLogoNew from '../../../../public/img/CustomerLogoNew.png';
import profile from '../../../../public/img/profile.png';
import faqicon from '../../../../public/img/faq.png';
import { customerProfileAction } from '../../../../Actions/Customer/customerProfileAction'
import { customerResetPWDAction, customerListAction } from '../../../../Actions/Admin/customerManagementAction'
import { customerRegisterAction, customerLoginAction, sendOTPAction, searchUserAction } from '../../../../Actions/Customer/CustomerLoginAction';
import { connect } from 'react-redux';
import carticon from '../../../../public/img/cart-icon.png';
import cart from '../../../../public/img/acc-order-history-active.png';
import Cookies from 'universal-cookie';
import * as language from '../../../../Constants/translations';
import ReactModal from 'react-modal';
import InputMask from 'react-input-mask';
import _ from 'lodash'
import base64 from 'base-64'
import * as constants from '../../../../Constants';
import successimg from '../../../../public/img/success.png';
import errorimg from '../../../../public/img/error.png';
import ReactLoading from 'react-loading';
import * as HelperUtil from "../../../../utils/common-helper"
import Discount from './../../Discount'
import Consent from '../../Home/consent'




// Class for the header files like search bar , user icon , etc.
class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      lang: sessionStorage.language != undefined ? sessionStorage.language=== 'spa' ? 'spa':'en' : 'en',
      fontSize: {
        header1: 72,
        header2: 24,
        //  giftCardButton: 21,
        loginSignup: 12,
        language: 10,
        a: 16,
        breadcrumb: 13,
        //  giftCardText: 22,
          tabs: 14,
        // cardAmount:20.8,
        //   sideBarText:18,
      },
      increaseCounter: 0,
      decreaseCounter: 0,
      addressesStatus: '',
      loginModalopen: false,
      regiModalOpen: false,
      Loading: false,
      isFieldsEmpty: false,

      modalIsOpen: false,
      signupModalIsOpen: false,
      regsFirstNameErrorSpan: "",
      regsLastNameErrorSpan: "",
      regsCompanyNameErrorSpan: "",
      regsEmailErrorSpan: "",
      regsPhoneErrorSpan: "",
      regsPasswordErrorSpan: "",
      regsConfirmPasswordErrorSpan: "",
      check: 1,
      modalForget: false,
      policyModalopen: false,
      forgetvalidObj: {
        email: ""
      },
      forgetinvalidObj: {
        email: ""
      },
      nameInputMask: 'aaaaaaaaaaaaaaa',
      validObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      customerList: [],
      checkUpdateState: false,
      isInputFieldEmpty: false,
      newsLetterModalopen: false,
      newsLetterStatus: true,
      increasemove: 4,
      decreasemove: 4
    }
  }


  // Function for allowing the user to logout 
  logOut() {
    // alert("l")
    
    try {
      sessionStorage.removeItem('FromPaymentPage')
      sessionStorage.userID = ''
      sessionStorage.loggedIn = ''
      sessionStorage.language = 'en'
      sessionStorage.removeItem('emailID')
      sessionStorage.removeItem('cardType')
      sessionStorage.removeItem('customerName')
      sessionStorage.userType = ''
      localStorage.removeItem('UserOtherAddress')
      localStorage.removeItem("productCardDetailsLength")
      localStorage.removeItem('productCardDetailsData');
      localStorage.removeItem('denominationPriceData')
      localStorage.removeItem("isVerifyAddress")
    } catch(e) {console.log("error ",e)}
    
    try {

      window.location.href = '/'

    } catch (error) {

    }

    //   sessionStorage.userType=''
  }

  componentWillMount() {
    var loggedInUserId = sessionStorage.userID
    this.props.customerListAction(loggedInUserId)
    if (loggedInUserId)
      this.props.customerProfileAction(loggedInUserId);
  }


  // Function for displaying my cart option in user icon
  mycart() {
    this.props.history.push({
      pathname: "/ordermanagement"
    })
  }

  // Function to go to view cart by selecting my cart from user icon  
  openCart() {
    // const cookies = new Cookies();

    // if (this.props.locationProps !== undefined && this.props.locationProps.shipmentPageProps !== undefined) {
    //   this.props.locationProps.shipmentPageProps.history.push(
    //     {
    //       pathname: "/orderDetailsManagements", state: {
    //         data: cookies.get('cart'),
    //         pageType: 'cartDetails'
    //       }
    //     }
    //   )
    // } else if (this.props.locationProps !== undefined && this.props.locationProps.paymentPageProps !== undefined) {
    //   this.props.locationProps.paymentPageProps.history.push({
    //     pathname: "/orderDetailsManagements", state: {
    //       data: cookies.get('cart'),
    //       pageType: 'cartDetails'
    //     }
    //   })
    // } else if (this.props.locationProps !== undefined && this.props.locationProps.history !== undefined) {
     
    //   this.props.locationProps.history.push({
    //     pathname: "/orderDetailsManagements", state: {
    //       data: cookies.get('cart'),
    //       pageType: 'cartDetails'
    //     }
    //   })
    // }
  }

  // Method to select the spanish language 
  activateSpanishLanguage() {
  
    sessionStorage.language = 'spa'
    this.setState({
      lang: 'spa'
    })
    this.props.language(sessionStorage.language)
  }

  // Method to select the english language 
  activateEnglishLanguage() {
   
    sessionStorage.language = 'en'
    this.setState({
      lang: 'en'
    })
    this.props.language(sessionStorage.language)
  }

  // Funtion for managing the size of the page  
  changeSize(data) {
    try {
      this.props.changeSize(data)
      
      if (data === 'increase') {
        let increaseCounter = this.state.increasemove
        let decreaseCounter = this.state.decreasemove

        if (increaseCounter >0 ) {
          decreaseCounter = decreaseCounter + 1
          increaseCounter = increaseCounter-1
          this.setState({
            fontSize: {
                  
              loginSignup: Number(this.state.fontSize.loginSignup) + 1,
              language: Number(this.state.fontSize.language) + 1,
              tabs: Number(this.state.fontSize.tabs) + 1,
              a: Number(this.state.fontSize.a) + 1,
              breadcrumb: Number(this.state.fontSize.breadcrumb) + 1,


               
            },
            increasemove:increaseCounter,
            decreasemove:decreaseCounter
          })
        } 
      }
      else if (data === 'decrease') {
        let increaseCounter = this.state.increasemove
        let decreaseCounter = this.state.decreasemove
        if (decreaseCounter >0) {
          decreaseCounter = decreaseCounter - 1
          increaseCounter = increaseCounter+1
          this.setState({
            fontSize: {
              //   header1: Number(this.state.fontSize.header1) - 1,
              //    header2: Number(this.state.fontSize.header2) - 1,
              //    giftCardButton: Number(this.state.fontSize.giftCardButton) - 1,
              loginSignup: Number(this.state.fontSize.loginSignup) - 1,
              language: Number(this.state.fontSize.language) - 1,
              a: Number(this.state.fontSize.a) - 1,
              tabs: Number(this.state.fontSize.tabs) - 1,
              breadcrumb: Number(this.state.fontSize.breadcrumb) -1,


              //     breadcrumb: Number(this.state.fontSize.breadcrumb) - 1,
              //     giftCardText: Number(this.state.fontSize.giftCardText) - 1,
              //    giftCard: Number(this.state.fontSize.giftCard) - 1,
              //    tabs: Number(this.state.fontSize.tabs) - 1,
              //    addButtonText: Number(this.state.fontSize.addButtonText) - 1,
              //    cardAmount: Number(this.state.fontSize.cardAmount) + 1,
              //  sideBarText:Number(this.state.fontSize.sideBarText) + 1
            },
            increasemove:increaseCounter,
            decreasemove:decreaseCounter
          })
        }

      }
      else if (data === 'default') {
        this.setState({
          fontSize: {
            //  header1: 72,
            //  header2: 24,
            // giftCardButton: 21,
            loginSignup: 12,
            language: 10,
            a: 16,
            tabs:14,
            breadcrumb: 13,
            //    giftCardText: 22,
            //  giftCard: 0,
            // tabs: 14,
            // addButtonText: 0,
            // cardAmount:20.8,
            //   sideBarText:18
          },
          increaseCounter: 0,
          decreaseCounter: 0,
          increasemove: 4,
          decreasemove: 4
        })
      }
    } catch(e) {console.log("error",e)}
    
  }

  componentWillReceiveProps(nextProps) {
    let self = this
    let lang = this.state.lang
    try {
      if (nextProps.customerProfileInfo && nextProps.customerProfileInfo.data && nextProps.customerProfileInfo.data.error === false) {

        let { data } = nextProps.customerProfileInfo.data.data

        data.addresses.length ? data.addresses.map((address) => {
          localStorage.setItem("isVerifyAddress", true)
          if (address.status == true) {
            self.setState({
              addressesStatus: true
            })
          }
        }) : localStorage.setItem("isVerifyAddress", false)

      }
      if (nextProps.customerLoginInfo && nextProps.customerLoginInfo.data) {
        if (nextProps.customerLoginInfo.data.status) {
          localStorage.removeItem('guestUserDetails')
          sessionStorage.loggedIn = true;
          sessionStorage.customerName = nextProps.customerLoginInfo.data.data.firstName + " " + nextProps.customerLoginInfo.data.data.lastName;
          sessionStorage.userID = nextProps.customerLoginInfo.data.data.id;
          sessionStorage.emailID = nextProps.customerLoginInfo.data.data.email
          if (nextProps.customerLoginInfo.data.data.addresses.length > 0) {
            sessionStorage.cardType = 'all'
            var Data = []
            Data = JSON.parse(localStorage.getItem('productCardDetailsData'))

            if (self.state.loginStateUpdate == true) {
              setTimeout(async function () {
                self.setState({
                  loginStateUpdate: false
                })
                self.closeModal()
                self.setState({ Loading: false })
                if (nextProps.locationProps.shipmentPageProps != undefined) {
                  nextProps.locationProps.shipmentPageProps.history.push({
                    pathname: '/ordermanagement', state: {
                      data: Data,
                      customerLoginInfo: nextProps.customerLoginInfo.data.data,
                      pageType: 'shippmentPage'
                    }
                  });
                }
                else if(nextProps.locationProps.cardListProps != undefined){
                  nextProps.locationProps.cardListProps.history.push({
                    pathname: "/orderDetailsManagements", state: {
                        data: Data,
                        customerLoginInfo: nextProps.customerLoginInfo.data.data,
                        pageType: 'cartDetails'
                    }
                })
                }
              }, 5000)
            }

          }
          else {
            if (self.state.loginStateUpdate == true) {
              self.setState({ loginStateUpdate: false })
              if (nextProps.locationProps.hasOwnProperty("cardListProps") && nextProps.locationProps.cardListProps !== undefined) {
                nextProps.locationProps.cardListProps.history.push("/dashboard");
              } else if (nextProps.locationProps.hasOwnProperty("shipmentPageProps") && nextProps.locationProps.shipmentPageProps !== undefined) {
                nextProps.locationProps.shipmentPageProps.history.push("/dashboard");
              } else if (nextProps.locationProps.hasOwnProperty("paymentPageProps") && nextProps.locationProps.paymentPageProps !== undefined) {
                nextProps.locationProps.paymentPageProps.history.push("/dashboard");
              } else if (nextProps.locationProps.hasOwnProperty("getFAQList") && nextProps.locationProps.history !== undefined) {
                nextProps.locationProps.history.push("/dashboard");
              }
            }

          }

        } else {
          self.setState({
            passwordErrorSpan: nextProps.customerLoginInfo.data.message,
            Loading: false
          })
        }
      }
      if (nextProps.resetCustomerPWDResponse && nextProps.resetCustomerPWDResponse.data) {
        if (nextProps.resetCustomerPWDResponse.data.hasOwnProperty('status') && (nextProps.resetCustomerPWDResponse.data.status === true)) {
          self.setState({ flashMessageError: '', flashMessageSuccess: language.language.reset_password_link[0][this.state.lang] })
          setInterval(function () {
            let redirect = window.location.href.substring(window.location.href.lastIndexOf('?'), 0)
            window.location = redirect;
          }, 5000);
        } else {
          self.setState({ flashMessageError: nextProps.resetCustomerPWDResponse.data.message })
        }
      }
      if (nextProps.customerList && nextProps.customerList.data) {
        if (nextProps.customerList.data.data && nextProps.customerList.data.data.data != null) {
          self.setState({
            customerList: nextProps.customerList.data.data.data,
          })

        }
      }
      if (nextProps.searchAndCreateUser && nextProps.searchAndCreateUser.data) {
        try {
          if (nextProps.searchAndCreateUser.data.hasOwnProperty('status') && nextProps.searchAndCreateUser.data.status === true) {
            self.setState({
              flashMessageError: '',
              flashMessageSuccess: language.language.activation_link[0][lang],
            })
            if (this.state.checkUpdateState === true) {
              setTimeout(async function () {
                self.setState({
                  Loading: false,
                  flashMessageSuccess: '',
                  flashMessageError: '',
                  checkUpdateState: false
                })
                await self.closeSignUpModal()
                // window.location.reload();
                // self.props.modalClose();
                // redirect to /home

                // try {
                //     if (nextProps.locationProps.history != undefined) {
                //         self.props.locationProps.history.push("/")
                //     }
                //     else if (nextProps.locationProps.orderManagementProps != undefined) {
                //         self.props.locationProps.orderManagementProps.history.push("/")
                //     } else if (nextProps.locationProps.locationProps != undefined) {
                //         if (nextProps.locationProps.locationProps.cardListProps != undefined) {
                //             self.props.locationProps.locationProps.cardListProps.history.push("/")
                //         }
                //         else if (nextProps.locationProps.locationProps.shipmentPageProps != undefined) {
                //             self.props.locationProps.locationProps.shipmentPageProps.history.push("/")
                //         } else if (nextProps.locationProps.locationProps.paymentPageProps != undefined) {
                //             self.props.locationProps.locationProps.paymentPageProps.history.push("/")
                //         }
                //     }
                // } catch (e) { }
                // self.props.locationProps("/");

              }, 5000);
            } else {
              self.setState({
                Loading: false,
                flashMessageSuccess: '',
                flashMessageError: '',
                checkUpdateState: false
              })
              self.closeSignUpModal()
            }
          } else {
            self.setState({ flashMessageError: JSON.stringify(nextProps.searchAndCreateUser.data.message), Loading: false })
          }
        } catch (err) {

        }

      }
    } catch (e) { }
  }

  // Method to go to the FAQ page by clicking on the FAQ(?) icon
  redirectToFaq = (event) => {
    try {


      if (this.props.locationProps.history !== undefined) {
        this.props.locationProps.history.push('/faq')
      } else if (this.props.locationProps.shipmentPageProps !== undefined && this.props.locationProps.shipmentPageProps.history !== undefined) {
        this.props.locationProps.shipmentPageProps.history.push('/faq')
      } else if (this.props.locationProps.paymentPageProps !== undefined && this.props.locationProps.paymentPageProps.history !== undefined) {
        this.props.locationProps.paymentPageProps.history.push('/faq')
      }
      if (this.props.locationProps.cardListProps !== undefined && this.props.locationProps.cardListProps.history !== undefined) {
        this.props.locationProps.cardListProps.history.push('/faq')
      }
    } catch (e) { }

  }



  /**
  * function for close loginModal
  */

  /***
     * Method for breadCrumbCLick giftCard
     * @param {event} 
     * @return {undefined}
     */
  redirectGiftCard() {
    window.location.href=constants.NORTHGATE_URL
    // console.log("propssssss", this.props)
    // if (sessionStorage.emailID !== '' && sessionStorage.emailID !== undefined) {
    //   if (this.props.cardListProps !== undefined) {
    //     this.props.cardListProps.history.push("/ordermanagement")
    //   } else if (this.props.locationProps.hasOwnProperty("history") && this.props.locationProps.history !== undefined) {
    //     this.props.locationProps.history.push("/ordermanagement")
    //   } else if (this.props.locationProps.hasOwnProperty("shipmentPageProps") && this.props.locationProps.shipmentPageProps.history !== undefined) {
    //     this.props.locationProps.shipmentPageProps.history.push("/ordermanagement")
    //   } else if (this.props.locationProps.hasOwnProperty("paymentPageProps") && this.props.locationProps.paymentPageProps.history !== undefined) {
    //     this.props.locationProps.paymentPageProps.history.push("/ordermanagement")
    //   } else {
    //     this.props.history.push("/ordermanagement")
    //   }
    // } else {
    //   if (this.props.cardListProps !== undefined) {
    //     this.props.cardListProps.history.push("/")
    //   } else if (this.props.locationProps !== undefined && this.props.locationProps.hasOwnProperty("history") && this.props.locationProps.history !== undefined) {
    //     this.props.locationProps.history.push("/")
    //   } else if (this.props.locationProps !== undefined && this.props.locationProps.hasOwnProperty("shipmentPageProps") && this.props.locationProps.shipmentPageProps.history !== undefined) {
    //     this.props.locationProps.shipmentPageProps.history.push("/")
    //   } else if (this.props.locationProps !== undefined && this.props.locationProps.hasOwnProperty("paymentPageProps") && this.props.locationProps.paymentPageProps.history !== undefined) {
    //     this.props.locationProps.paymentPageProps.history.push("/")
    //   } else {
    //     this.props.history.push("/")
    //   }
    // }
  }


  /**
  * function for open loginModal
  */
 openModal = () => {
  this.resetStates()
  this.setState({ modalIsOpen: true, signupModalIsOpen: false, changeColorState: true, regchangeColorState: false });
};

  /**
* function for close loginModal
*/
closeModal = () => {
  this.resetStates()
  this.setState({ modalIsOpen: false, changeColorState: false });
};

  /**
   * function for open RegistraionModal
  */
 openSignUpModal = () => {
  this.resetStates()
  this.setState({ signupModalIsOpen: true, modalIsOpen: false, regchangeColorState: true, changeColorState: false });
};

  closeSignUpModal = () => {
    this.resetStates()
    // this.props.modalClose();
    this.setState({ signupModalIsOpen: false, modalIsOpen: false, regchangeColorState: false });
  };

  /**
     * function for enable registration button
     */
  enableRegistrationButton = (e) => {
    if (e.target.checked) {
      this.setState({ check: 1 })
    } else {
      this.setState({ check: 0 })

    }
  }

  closeModalForget = () => {
    this.resetStates()
    // this.props.modalClose();
    this.setState({ modalForget: false });
  };

  openModalForget = () => {
    this.resetStates()
    // this.props.modalClose();
    this.setState({ modalForget: true, modalIsOpen: false, changeColorState: false })
  }
  /**
      * function to open policyModal
      */
  openModalPolicy = () => {
    this.setState({
      policyModalopen: true
    });
  };
  /**
* function to close policyModal
*/
  closeModalPolicy = () => {
    this.setState({
      policyModalopen: false
    });
  };

  /**
    * function for authenticate user is valid or not on submit of registration
    * @param object event
    * @return object userParams
   */
  authenticate = (status) => {
    let { newsLetterStatus } = this.state
    if (status !== undefined) {
      newsLetterStatus = false
    }
    
    let firstName = this.state.validObj.firstName
    var lang = this.state.lang
    let phoneRegx = /^(a1-z9){7,15}$/;

    if (firstName == '') {
    
      this.setState({
        regsFirstNameErrorSpan: language.language.enter_first_name[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.lastName == '') {
      this.setState({
        regsLastNameErrorSpan: language.language.enter_last_name[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.companyName == '') {
      this.setState({
        regsCompanyNameErrorSpan: language.language.enter_company_name[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.email == '') {
      this.setState({
        regsEmailErrorSpan: language.language.enter_email[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.phone == '') {
      this.setState({
        regsPhoneErrorSpan: "Please enter your Phone Number", isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.password == '') {
      this.setState({
        regsPasswordErrorSpan: language.language.set_password_msg[0][lang], isInputFieldEmpty: true
      })
    }
    if(!this.state.validObj.password.match(phoneRegx)  && this.state.validObj.confirmPassword === '') {
      this.setState({regsPasswordErrorSpan :language.language.Password_Validation[0][this.state.lang] })
      return 0;
  }
    if (this.state.validObj.confirmPassword == '') {
      this.setState({
        regsConfirmPasswordErrorSpan: language.language.confirm_password_msg[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.password != this.state.validObj.confirmPassword) {
      Object.assign(this.state.validObj, { passwordMatchError: true })
      this.setState({
        regsConfirmPasswordErrorSpan: language.language.password_match_error[0][lang], isInputFieldEmpty: true
      })
    }
    if (this.state.validObj.email != '' && !(this.validateMail(this.state.validObj.email))) {
      Object.assign(this.state.validObj, { emailError: true })
      this.setState({
        regsEmailErrorSpan: language.language.Invalid_Email[0][lang], isInputFieldEmpty: true
      })
    } else { Object.assign(this.state.validObj, { emailError: false }) }
    if (this.state.validObj.password != '' && ((this.state.validObj.password).length < 8)) {
      Object.assign(this.state.validObj, { passwordLengthError: true })
      this.setState({
        regsPasswordErrorSpan: language.language.customer_password_length[0][lang]
      })
    } else { Object.assign(this.state.validObj, { passwordLengthError: false }) }
    if ((this.state.validObj.phone).length < 14) {
      this.setState({
        regsPhoneErrorSpan: language.language.invalid_phone[0][lang], isInputFieldEmpty: true
      })
      Object.assign(this.state.validObj, { phoneError: true })
    } else { Object.assign(this.state.validObj, { phoneError: false }) }
    if (this.state.validObj.firstName != "" && this.state.validObj.lastName != "" && this.state.validObj.companyName != "" && this.state.validObj.email != "" && this.state.validObj.phone !== "" && (this.state.validObj.password).length >= 8 && (this.state.validObj.password == this.state.validObj.confirmPassword) && this.validateMail(this.state.validObj.email) && (this.state.validObj.phone).length == 14) {
      let url = window.location.protocol + "//" + window.location.host + "?activate=";
      // if (url.includes("#")) {
      //     let rem = url.indexOf("#");
      //     url = url.slice(0, rem) + "?activate="
      // } else {
      //     url = url.slice(0, -1) + "?activate="
      // }
      let userParams = {
        firstName: this.state.validObj.firstName,
        lastName: this.state.validObj.lastName,
        email: this.state.validObj.email,
        password: this.state.validObj.password,
        mobile: this.state.validObj.phone,
        companyName: this.state.validObj.companyName,
        activateUrl: url,
        consent: this.state.check
      }
      if (this.state.check === 1 || newsLetterStatus === false) {
        this.setState({ newsLetterModalopen: false, Loading: true, checkUpdateState: true })
        this.props.searchUserAction(userParams)
      } else if (this.state.check === 0 && newsLetterStatus === true) {
        this.setState({
          newsLetterModalopen: true
        })
      }

    } else {
      let invalid = {}
      if (this.state.validObj.username == "") {
        invalid['username'] = language.language.required_field[0]
      }
      if (this.state.validObj.password == "") {
        invalid['password'] = language.language.required_field[0]
      }
      let inValidaData = Object.assign(this.state.invalidObj, invalid)
      this.setState({ invalidObj: inValidaData })
    }
  }

  /**
    * function for sending customer password reset link
   */
  resetPWDLink() {
    if (this.state.forgetvalidObj.email != "") {
      if (this.validateMail(this.state.forgetvalidObj.email)) {
        let userParams = {
          email: this.state.forgetvalidObj.email,
        }

        var list = this.state.customerList
        let resList = _.filter(list, (v) => _.includes(userParams.email, v.email))
        if (resList != "") {
          let timeSamp = HelperUtil._currentDateTime();
          let encodedData = base64.encode(userParams.email + "&&&" + timeSamp)
          let url = constants.CUSTOMER_PASSWORD_RESET + "?forgetId=" + encodedData
          let userDetails = { url: url, email: userParams.email };
          this.setState({ Loading: true })
          this.props.customerResetPWDAction(userDetails);

        } else {

          this.setState({ flashMessageSuccess: "", flashMessageError: constants.EMAIL_NOT_FOUND })
        }

      } else {
        this.setState({ flashMessageSuccess: "", flashMessageError: constants.INVALID_EMAIL })
      }

    }
    else {
      let invalid = {}

      this.setState({ flashMessageError: language.language.email_address_req[0][this.state.lang] })
      invalid['password'] = "This Field is required"
      let inValidaData = Object.assign(this.state.forgetinvalidObj, invalid)
      this.setState({ forgetinvalidObj: inValidaData })

    }
  }

  /**
    * function for forgetPassword Email input value
    * @param object event
    * @return object State
   */
  forgetEmail = (event) => {
    event.preventDefault();
    let key = event.target.name
    let value = event.target.value
    let valid = {}
    let invalid = {}
    if (value === "") {
      invalid[key] = "This Field is required"
      valid[key] = ""
    } else {
      invalid[key] = ""
      valid[key] = value
    }
    let inValidaData = Object.assign(this.state.forgetinvalidObj, invalid)
    let validaData = Object.assign(this.state.forgetvalidObj, valid)
    this.setState({ forgetinvalidObj: inValidaData, forgetvalidObj: validaData, flashMessageError: "" })
  }

  resetStates = () => {
  
    this.setState({
      flashMessageError: '',
      flashMessageSuccess: '',
      isInputFieldEmpty: false,
      isFieldsEmpty: false,
      validObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      invalidObj: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        confirmPassword: ""
      },
      emailErrorSpan: "",
      passwordErrorSpan: "",
      regsFirstNameErrorSpan: "",
      regsLastNameErrorSpan: "",
      regsCompanyNameErrorSpan: "",
      regsEmailErrorSpan: "",
      regsPhoneErrorSpan: "",
      regsPasswordErrorSpan: "",
      regsConfirmPasswordErrorSpan: "",
      check: 1,
      newsLetterStatus: true,
    })
  }

  /**
  * function for authenticate user is valid or not on submit
  * @param object event
  * @return object userParams
 */
  authenticateLogin = async (event) => {
    let email = this.state.validObj.email;
    let password = this.state.validObj.password;
    if (email == "") {
      this.setState({
        emailErrorSpan: language.language.Empty_Email_Address[0][this.state.lang], isFieldsEmpty: true
      })
    }
    else if (password == "") {
      this.setState({
        passwordErrorSpan: language.language.Empty_Password[0][this.state.lang], isFieldsEmpty: true
      })
    }
    else if (email != "" && password != "") {
      if (this.validateMail(email)) {
        let userParams = {
          email: email,
          password: password
        }
        this.setState({ Loading: true, loginStateUpdate: true })
        await this.props.customerLoginAction(userParams)
      } else {
        Object.assign(this.state.validObj, { emailError: true })
        this.setState({
          emailErrorSpan: constants.INVALID_EMAIL,
          isFieldsEmpty: true

        })
      }
    } else {

      let invalid = {}
      if (this.state.validObj.username == "") {
        invalid['username'] = "This Field is required"
      }
      if (this.state.validObj.password == "") {
        invalid['password'] = "This Field is required"
      }
      let inValidaData = Object.assign(this.state.invalidObj, invalid)
      this.setState({
        invalidObj: inValidaData,
        flashMessageError: "Fields can't be null"
      })
    }
  }

  /**
         * function to submit form on enter Keypress
         */
  submitOnEnter(event) {
    if (event.which == 13 || event.keyCode == 13) {
      //customer LogIn Form
      if (event.target.id == 'pwd') {
        this.authenticateLogin();
      }
      //customer SignUp Form
      else if (event.target.id == 'cnf-pwd-signup') {
        this.authenticate()
      }
      //customer forget Password
      else if (event.target.id == 'email-forget-password') {
        this.resetPWDLink()
      }
    }
  }
  /**
* function for validate Email
* * @return boolean
*/
  validateMail = (email) => {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());

  }
  /**
   * function for validate user details
   * @param object event
   * @return object state
  */

  setValue = (event) => {
    this.setState({
      emailErrorSpan: "",
      flashMessageSuccess: '',
      flashMessageError: "",
      passwordErrorSpan: ""
    })
    let key = event.target.name
    let value = event.target.value
    let valid = {}
    let invalid = {}
    if (value === "") {
      invalid[key] = language.language.required_field[0]
      valid[key] = ""
    } else {
      invalid[key] = ""
      valid[key] = value
    }
    //Test for first space in input field
    if(event.target.value !== "" && event.target.value[0].charCodeAt() === 32) {
             
      return 0;
    }


    let inValidaData = Object.assign(this.state.invalidObj, invalid)
    let validaData = Object.assign(this.state.validObj, valid)

    this.setState({
      invalidObj: inValidaData, validObj: validaData,
      emailErrorSpan: "",
      passwordErrorSpan: "",
      regsFirstNameErrorSpan: "",
      regsLastNameErrorSpan: "",
      regsCompanyNameErrorSpan: "",
      regsEmailErrorSpan: "",
      regsPhoneErrorSpan: "",
      regsPasswordErrorSpan: "",
      regsConfirmPasswordErrorSpan: ""
    })
  }

  closeModalNewsLetter = () => {
    this.setState({
      newsLetterModalopen: false
    })
  }

  async componentDidMount() {
    try {
      if (this.props.searchAndCreateUser.data !== null && this.state.checkUpdateState === true) {
        this.props.searchAndCreateUser.data = null
      }
      // if (this.props.customerLoginInfo.data !== null) {
      //     this.props.customerLoginInfo.data = null
      // }
    } catch (err) { }
  }

  render() {
    let open = this.props.open || this.state.modalIsOpen

    var regchangeColorClass = this.state.regchangeColorState ? 'yellowColor' : '';
    var changeColorClass = this.state.changeColorState ? 'yellowColor' : '';
    var lang = this.state.lang
    const { fontSize } = this.state

    let regsFirstNameErrorSpan = <span className='validMessage'>{this.state.regsFirstNameErrorSpan}</span>
    let regsLastNameErrorSpan = <span className='validMessage'>{this.state.regsLastNameErrorSpan}</span>
    let regsCompanyNameErrorSpan = <span className='validMessage'>{this.state.regsCompanyNameErrorSpan}</span>
    let regsEmailErrorSpan = <span className='validMessage'>{this.state.regsEmailErrorSpan}</span>
    let regsPhoneErrorSpan = <span className='validMessage'>{this.state.regsPhoneErrorSpan}</span>
    let regsPasswordErrorSpan = <span className='validMessage'>{this.state.regsPasswordErrorSpan}</span>
    let regsConfirmPasswordErrorSpan = <span className='validMessage'>{this.state.regsConfirmPasswordErrorSpan}</span>
    let { validObj } = this.state
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <p >{this.state.flashMessageSuccess}</p>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <p color='danger'>{this.state.flashMessageError}</p>;

    }
    //error messages for email validations
    let emailErrorSpan = "";
    if (this.state.emailErrorSpan) {
      emailErrorSpan = <span className='validMessage'>{this.state.emailErrorSpan}</span>
    }
    let passwordErrorSpan = "";
    if (this.state.passwordErrorSpan) {
      passwordErrorSpan = <span className='validMessage'>{this.state.passwordErrorSpan}</span>
    }
    return (
      <div id="myaccount">
        <div className="wrapper page customerHome" >
          {
            this.state.Loading === true && <div className="loader-react">
              <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
            </div>
          }
          <header id="header">
            <div className="container-fluid">
              <div className="logo-div pull-left">
                <a href="javascript:void(0);" title="Northgate Market" onClick={(e) => this.redirectGiftCard(this)} className="logo"><img src={CustomerLogoNew} alt="Northgate Giftcard logo" /></a>
              </div>
              <div className="header-search pull-left">

                <div className="search-container">
                  <form className="search-header">
                    <fieldset>
                     
                    </fieldset>
                  </form>
                  <ul className="text-size-menu">
                    <li onClick={this.changeSize.bind(this, 'decrease')} ><a href="javascript:void(0)" style={{ fontSize: fontSize.a + 'px' }}>A-</a></li>
                    <li onClick={this.changeSize.bind(this, 'default')} ><a href="javascript:void(0)" style={{ fontSize: fontSize.a + 'px' }}>A</a></li>
                    <li onClick={this.changeSize.bind(this, 'increase')} ><a href="javascript:void(0)" style={{ fontSize: fontSize.a + 'px' }} >A+</a></li>
                  </ul>
                </div>
              </div>

              <div className="header-login right_section new30_add pull-right ">
                <div className="faq-section">
                 
                  <ul className="user-icon-header">
                     <li  className="faq-comm">
                      <div className="card_icon cardnone_button mgr10">
                        <Link 
                          to="/faq"
                          onClick={(e) => { this.redirectToFaq(e) }}
                          title="Frequently Asked Questions"
                          style={{ fontSize:fontSize.loginSignup}}
                          >{language.language.FAQ[0][lang]} </Link>
                        {/* <a href="/faq"
                          onClick={(e) => { this.redirectToFaq(e) }}
                          title="Frequently Asked Questions">FAQ
                        </a> */}
                      </div>
                    </li>
                    {/* for cart */}

                    <li className="icon-custm_new other_30">

                    <div className="card_icon cardnone_button  mgr10">
                      <Discount fontSize={fontSize} lang={this.state.lang}/>
                      </div>
                    </li>
                  </ul>
                  {/* <Discount lang={this.state.lang}/> */}

                  {(sessionStorage.userID != 0 && sessionStorage.userID != undefined && sessionStorage.loggedIn !== '') ?
                    <ul className="user-icon-header">
                      <li className="dropdown cust-drop">
                        <a className="dropbtn card_icon mgr10"><img src={userIcon} alt="userIcon" title="userIcon" /></a>
                        <div className="dropdown-content" id="myDropdown">
                          <div className="dropdown-header">
                            <a href="javascript:void(0)">{sessionStorage.customerName} </a>
                          </div>
                          <a href="/dashboard" className="">
                            <img src={profile} alt="profile" title="profile" /> {language.language.my_profile[0][lang]}
                          </a>
                          <a href="orderDetailsManagements" onClick={this.openCart.bind(this)} className={
                            JSON.parse(localStorage.getItem("productCardDetailsData")) === null || JSON.parse(localStorage.getItem("productCardDetailsData")).length==0?"disableEvent":""

                          } >
                            <img src={cart} alt="cart" title={language.language.my_cart[0][lang]} /> {language.language.my_cart[0][lang]}
                          </a>
                          <div className="dropdown-footer"><a href="JavaScript:Void(0);"
                            onClick={this.logOut.bind(this)}><img src={signout} alt="signout" title="signout" /> {language.language.Logout[0][lang]}</a></div>
                        </div>
                      </li>
                    </ul> :
                    <ul className="login-menu-header  custom-login"><li>
                      <a title={language.language.Login[0][lang]} href="JavaScript:Void(0);" className={changeColorClass} style={{ fontSize: fontSize.loginSignup + 'px' }} onClick={this.openModal}>{language.language.Login[0][lang]}</a></li>
                      <li ><a title={language.language.Signup[0][lang]} href="JavaScript:Void(0);" className={regchangeColorClass} style={{ fontSize: fontSize.loginSignup + 'px' }} onClick={this.openSignUpModal}>{language.language.Signup[0][lang]}</a></li>
                    </ul>
                  }

                </div>
                <div className={sessionStorage.userID == 0 ? 'language-menu-div' : 'language-menu-space language-cust'}>
                  {/* <div className="language-menu-div pull-right"> */}
                  <form className="form">
                    <legend>
                    <div className="switch-field">
                      <input type="radio" id="switch_left" name="switch_2" value="yes" checked={this.state.lang == 'en' ? true : false} />
                      <label htmlFor="switch_left" title={language.language.english[0][lang]} onClick={this.activateEnglishLanguage.bind(this)} style={{ fontSize: fontSize.language + 'px' }}>{language.language.english[0][lang]} </label>
                      <input type="radio" id="switch_right" name="switch_2" value="no" checked={this.state.lang == 'spa' ? true : false} />
                      <label htmlFor="switch_right" title={language.language.spanish[0][lang]} onClick={this.activateSpanishLanguage.bind(this)} style={{ fontSize: fontSize.language + 'px' }}>{language.language.spanish[0][lang]}</label>
                    </div>
                    </legend>
                  </form>
                </div>

              </div>
            </div>
          </header>
        </div>

        {/* loginModal */}
        <div className="modal-box" id="">
          <ReactModal isOpen={open} closeTimeoutMS={500} onRequestClose={this.closeModal}
            className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}
            shouldCloseOnOverlayClick={false}
            >
            <div className="modal-content landingcustomemodal">
              <div className="modal-header giftcart_title_header">
                <h4 className="modal-title giftcart_all_header" style={{ fontSize: fontSize.breadcrumb + 'px' }} >{language != undefined ? language.language.Login[0][lang] : ''}</h4>
                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModal}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    {flashSuceessMessageSpan !== "" ?
                      <div className="alert alert-success alert_succesfull clearfix" role="alert">
                        <div className="successbtn"><img src={successimg} alt="successimg" title="successimg" /></div>
                        <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                      </div>

                      : ''}
                    {flashErrorMessageSpan ?
                      <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                        <span><img src={errorimg} alt="errorimg" title="errorimg" /></span>
                        <p className="clearfix">{flashErrorMessageSpan}</p>
                      </div>
                      : ''}
                    <label htmlFor="email" style={{ fontSize: fontSize.tabs + 'px' }}>{language != undefined ? language.language.email_id[0][lang] : ''}</label>
                    <input
                      type="email"
                      className={`form-control ${(validObj.email === "" || validObj.emailError) && this.state.isFieldsEmpty ? "red" : ''}`}
                      id="email"
                      onFocus={(e) => e.target.placeholder = ""}
                      onBlur={(e) => e.target.placeholder = language != undefined ? language.language.email_id[0][lang] : ''}
                      onChange={(e) => this.setValue(e)}
                      required name="email"
                      value={validObj.email ? validObj.email : ''}
                      ref={(e) => this.email = e}
                      placeholder={language != undefined ? language.language.email_id[0][lang] : ''}
                      style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                    />
                    {emailErrorSpan ? emailErrorSpan : ""}
                  </div>
                  <div className="form-group">
                    <label style={{ fontSize: fontSize.tabs + 'px' }} htmlFor="pwd" >{language != undefined ? language.language.Password[0][lang] : ''}</label>
                    <input
                      type="password"
                      className={`form-control ${validObj.password === "" && this.state.isFieldsEmpty ? "red" : ''}`}
                      id="pwd"
                      onFocus={(e) => e.target.placeholder = ""}
                      onBlur={(e) => e.target.placeholder = language != undefined ? language.language.Password[0][lang] : ''}
                      onChange={(e) => this.setValue(e)}
                      required name="password"
                      value={validObj.password ? validObj.password : ''}
                      ref={(e) => this.password = e} placeholder={language != undefined ? language.language.Password[0][lang] : ''}
                      onKeyPress={(e) => this.submitOnEnter(e)} 
                      style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                    />
                    {passwordErrorSpan ? passwordErrorSpan : ""}
                  </div>
                  <div className="buttons">
                    <button type="button" className="giftcard_button mgr10" style={{ fontSize: fontSize.breadcrumb + 'px' }}  onClick={(e) => this.authenticateLogin(e)}>{language != undefined ? language.language.Login[0][lang] : ''}</button>
                    <button type="button" className="giftcard_button cardnone_button " onClick={this.openSignUpModal}  style={{ fontSize: fontSize.breadcrumb + 'px' }}  data-toggle="modal" data-target="#registerModal">{language != undefined ? language.language.REGISTER[0][lang] : ''}</button>
                  </div>
                  <ul className="forgot-guest-links">
                    <li><a href="javascript:void(0)" style={{ fontSize: fontSize.breadcrumb + 'px' }}  onClick={this.openModalForget}>{language != undefined ? language.language.Forgot_Password[0][lang] : ''}</a></li>
                    <li><a href="javascript:void(0)" style={{ fontSize: fontSize.breadcrumb + 'px' }}  onClick={this.closeModal.bind(this)}>{language != undefined ? language.language.Continue_as_a_guest[0][lang] : ''}</a></li>
                  </ul>
                </form>
              </div>
            </div>
          </ReactModal>
        </div>

        {/* SignUp Modal */}
        <div id="">
          <ReactModal
            closeTimeoutMS={500}
            isOpen={this.state.signupModalIsOpen}
            onRequestClose={this.closeSignUpModal}
            className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}
            shouldCloseOnOverlayClick={false}
          >

            <div className="modal-dialog">

              <div className="modal-content">

                <div className="modal-header giftcart_title_header">
                  <h4 className="modal-title giftcart_all_header"  style={{ fontSize: fontSize.breadcrumb + 'px' }} >{language.language.REGISTER[0][lang]}</h4>
                  <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeSignUpModal}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                </div>
                <div className="modal-body signUp_model">
                  {flashSuceessMessageSpan !== "" ?
                    <div className="alert alert-success alert_succesfull clearfix" role="alert">
                      <div className="successbtn"><img src={successimg} alt="successimg" title="successimg" /></div>
                      <p className="text-center clearfix red"> {flashSuceessMessageSpan} </p>
                    </div>

                    : ''}
                  {flashErrorMessageSpan ?
                    <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                      <span><img src={errorimg} alt="errorimg" title="errorimg" /></span>
                      <p className="clearfix">{flashErrorMessageSpan}</p>
                    </div>
                    : ''}
                  <div className="row">
                    <div className="col-md-12 form-group text-right">
                      <p className="noteplus30 pb-0 m-0 red"  style={{ fontSize: fontSize.breadcrumb + 'px' }} >{language.language.all_fields_mandatory[0][lang]}</p>
                    </div>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="firstName" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.First_Name[0][lang]}</label>
                          <InputMask
                            id="firstName"
                            type="text"
                            className={`form-control ${validObj.firstName === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                            mask={this.state.nameInputMask}
                            onChange={(e) => this.setValue(e)}
                            maskChar={null}
                            name="firstName"
                            value={validObj.firstName}
                            style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                          />
                          {regsFirstNameErrorSpan ? regsFirstNameErrorSpan : ''}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="lastName" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.Last_Name[0][lang]}</label>
                          <InputMask
                            id="lastName"
                            type="text"
                            className={`form-control ${validObj.lastName === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                            mask={this.state.nameInputMask}
                            onChange={(e) => this.setValue(e)}
                            maskChar={null}
                            name="lastName"
                            value={validObj.lastName}
                            style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                          />
                          {regsLastNameErrorSpan ? regsLastNameErrorSpan : ''}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="company-name" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.company_name[0][lang]}</label>
                      <input title={language.language.company_name[0][lang]}
                        type="text"
                        className={`form-control ${validObj.companyName === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                        id="company-name"
                        onChange={(e) => this.setValue(e)}
                        name="companyName"
                        value={validObj.companyName}
                        ref={(e) => this.companyName = e}
                        maxLength={30}
                        style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                      />
                      {regsCompanyNameErrorSpan ? regsCompanyNameErrorSpan : ''}
                    </div>
                    <div className="form-group">
                      <label htmlFor="email-signup" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.Email_ID[0][lang]}</label>
                      <input title={language.language.Email_ID[0][lang]}
                        type="email"
                        className={`form-control ${(validObj.email === "" || validObj.emailError) && this.state.isInputFieldEmpty ? 'red' : ''}`}
                        id="email-signup"
                        onChange={(e) => this.setValue(e)}
                        name="email" value={validObj.email}
                        ref={(e) => this.email = e}
                        maxLength={40}
                        style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                      />
                      {regsEmailErrorSpan ? regsEmailErrorSpan : ''}
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.mobile_number[0][lang]}</label>
                      <InputMask title={language.language.mobile_number[0][lang]}
                        className={`form-control ${validObj.phone === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                        id="phone"
                        onChange={(e) => this.setValue(e)}
                        mask="(999) 999-9999"
                        maskChar={null}
                        name="phone"
                        value={validObj.phone}
                        style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                      />
                      {regsPhoneErrorSpan ? regsPhoneErrorSpan : ''}
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="pwd-signup" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.Password[0][lang]}</label>
                          <input title={language.language.Password[0][lang]}
                            type="password"
                            className={`form-control ${(validObj.password === "" || validObj.passwordMatchError || validObj.passwordLengthError) && this.state.isInputFieldEmpty ? 'red' : ''}`}
                            id="pwd-signup"
                            onChange={(e) => this.setValue(e)}
                            name="password" value={validObj.password}
                            ref={(e) => this.password = e}
                            maxLength={15}
                            style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                          />
                          {regsPasswordErrorSpan ? regsPasswordErrorSpan : ''}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="cnf-pwd-signup" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.confirm_password[0][lang]}</label>
                          <input title={language.language.confirm_password[0][lang]}
                            type="password"
                            className={`form-control ${(validObj.confirmPassword === "" || validObj.passwordMatchError || validObj.passwordLengthError) && this.state.isInputFieldEmpty ? 'red' : ''}`}
                            id="cnf-pwd-signup"
                            onChange={(e) => this.setValue(e)}
                            name="confirmPassword"
                            value={validObj.confirmPassword}
                            ref={(e) => this.confirmPassword = e}
                            onKeyPress={(e) => this.submitOnEnter(e)}
                            style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                          />
                          {regsConfirmPasswordErrorSpan ? regsConfirmPasswordErrorSpan : ''}
                        </div>
                      </div>
                    </div>

                    <div className="form-group checkBoxWrapper">
                      <input title="{language.language.consent[0][lang]}" type="checkbox" className="checkbox-btn" checked={this.state.check === 1 ? true : false} onClick={this.enableRegistrationButton.bind(this)} />
                      <label className="checkbox-inline-policy label-text"  style={{ fontSize: fontSize.breadcrumb + 'px' }} >
                      {language.language.consent[0][lang]} <span className="purple-color" onClick={(e) => this.openModalPolicy(e) }  style={{ fontSize: fontSize.breadcrumb + 'px' }} > {language.language.consent[1][lang]}</span>
                      </label>
                    </div>
                    {/* <div className="form-group">
                      <label className="noteplus30">{language.language.all_fields_mandatory[0][lang]}</label>
                    </div> */}
                    <div className="form-group buttonArea">
                      <div className="buttons">
                        <button type="button"  style={{ fontSize: fontSize.breadcrumb + 'px' }}  className="giftcard_button cardnone_button" onClick={() => this.authenticate()}>{language.language.REGISTER[0][lang]} </button>


                      </div>
                    </div>

                    <ul className="guest-links">
                      <li  style={{ fontSize: fontSize.breadcrumb + 'px' }} >{language.language.already_account[0][lang]}<a href="javascript:void(0)" data-toggle="modal" onClick={(e) => this.openModal(e)} data-target="#myModal"  style={{ fontSize: fontSize.breadcrumb + 'px' }} > {language.language.login_here[0][lang]}</a></li>
                    </ul>
                  </form>
                </div>

              </div>
            </div>

          </ReactModal>
        </div>

        {/* ForgetPassword Modal */}
        <div className="modal-box" id="">
          <ReactModal isOpen={this.state.modalForget} onRequestClose={this.closeModalForget} closeTimeoutMS={500}
            className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}
            shouldCloseOnOverlayClick={false}
            >
            <div className="modal-content landingcustomemodal">
              <div className="modal-header giftcart_title_header">
                <h4 className="modal-title giftcart_all_header"  style={{ fontSize: fontSize.breadcrumb + 'px' }} >{language.language.Forgot_Password[0][lang]}</h4>
                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModalForget}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
              </div>
              <div className="modal-body">
                <form>
                  {flashSuceessMessageSpan !== "" ?
                    <div className="alert alert-success alert_succesfull clearfix " role="alert">
                      <div className="successbtn"><img src={successimg} alt="successimg" title="successimg" /></div>
                      <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                    </div>

                    : ''}
                  {flashErrorMessageSpan ?
                    <div className="alert alert-success alert_succesfull adpadding30 clearfix " role="alert">
                      <span><img src={errorimg} alt="errorimg" title="errorimg" /></span>
                      <p className="clearfix">{flashErrorMessageSpan}</p>
                    </div>
                    : ''}
                  <div className="form-group">
                    <label htmlFor="email"  style={{ fontSize: fontSize.tabs + 'px' }} >{language.language.email_id[0][lang]} </label>
                    <input 
                     style={{ fontSize: fontSize.breadcrumb + 'px' }} 
                    title={language.language.email_id[0][lang]} type="email" className="form-control" id="email-forget-password" onChange={(e) => this.forgetEmail(e)} name="email" required onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = language.language.email_id[0][lang]} ref={(e) => this.email = e} placeholder={language.language.email_id[0][lang]} onKeyPress={(e) => this.submitOnEnter(e)} />
                  </div>
                  <div className="buttons">
                    <button type="button"  style={{ fontSize: fontSize.breadcrumb + 'px' }}   onClick={(e) => this.resetPWDLink()} className="btn btn-default login-btn" >{language.language.Resend_Password[0][lang]}</button>
                    <button type="button"  style={{ fontSize: fontSize.breadcrumb + 'px' }}  className="btn btn-default register-btn" onClick={this.closeModalForget}>{language.language.CANCEL[0][lang]}</button>
                  </div>

                </form>
              </div>
            </div>
          </ReactModal>
        </div>

                {/**Policy modal open */}
                <div className="modal-box" id="">
          <ReactModal 
            closeTimeoutMS={500} 
            isOpen={this.state.policyModalopen} 
            onRequestClose={this.closeModalPolicy} 
            className="modal-dialog-box fullModal" center 
            classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel modalPolicy' }}
            shouldCloseOnOverlayClick={false}
            >
            <div className="modal-content">
            <div className="modal-header polcy-header">
                <h4 className="modal-title"></h4>
                <button className="styles_closeButton__20ID4 closemodalbtn pol-close" onClick={this.closeModalPolicy}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
              </div>
              <div className="modal-body newsseventall container privacy-body">
                <form>
                  <div className="row">
                    {/* <p> Consent to receiving promotional and marketing emails from Northgate Gonzalez, LLC and its affiliates and agree to their privacy policy</p> */}
                    <Consent lang={lang} />
                    
                    <div className="formbtn pol-btn">
                    <button type="button" className="giftcard_button mgr10 pull-right" onClick={this.closeModalPolicy}>{language.language.CLOSE[0][lang]}</button>
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ReactModal>
        </div>
        {/**Policy modal end */}


        {/**News Letter modal open */}
        <div className="modal-box" id="">
          <ReactModal 
            closeTimeoutMS={500} 
            isOpen={this.state.newsLetterModalopen} 
            onRequestClose={this.closeModalNewsLetter} 
            className="modal-dialog-box" center 
            classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel modalPolicy' }}
            shouldCloseOnOverlayClick={false}
            >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Confirm</h4>
                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModalNewsLetter}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
              </div>
              <div className="modal-body News-letter">
                <form>

                  <div className="row">
                    <div className="newsletter-text">
                      <p>Are you sure, you do not want to hear from us?</p>
                    </div>
                  </div>

                  <hr id="full-hr" />
                  <div className="newsletter-button">
                    <button type="button" className="giftcard_button mgr10" onClick={this.authenticate.bind(true)}>I Agree</button>
                    <button type="button" className="giftcard_button mgr10" onClick={this.closeModalNewsLetter.bind(this)}>Cancel</button>

                  </div>
                </form>
              </div>
            </div>
          </ReactModal>
        </div>
        {/**News Letter modal end */}
      </div>
    )
  }
}

/**
 * constant mapStateToProps for pass value in redux
 * @param object state
 * @return object userLoginInfo
*/
const mapStateToProps = (state) => {

  return {
    customerProfileInfo: state.customerProfile,
    pageProps: state.props,

    customerLoginInfo: state.customerLogin,
    customerRegisterInfo: state.customerRegister,
    sendOtp: state.sendOtp,
    resetCustomerPWDResponse: state.resetCustomerPWD,
    customerList: state.customerList,
    searchAndCreateUser: state.searchAndCreateUser
  };
}
export default connect(mapStateToProps, { customerProfileAction, customerRegisterAction, customerLoginAction, sendOTPAction, customerResetPWDAction, customerListAction, searchUserAction })(Header)
