import React, { Component } from 'react';
import Header from '../Layout/Header';
import "react-datepicker/dist/react-datepicker.css";
import { discountListAction } from '../../../Actions/Admin/discountManagementAction';
import { connect } from 'react-redux'
import _ from 'lodash';
import { buyGiftCardAction } from '../../../Actions/Customer/GiftCardAction'
import moment from "moment"
import * as constant from '../../../Constants/';
import * as HelperUtil from "../../../utils/common-helper"
import noImage from '../../../public/img/noImageShipPage.png';
import paymenticon from '../../../public/img/cards-img.png';
import successimg from '../../../public/img/success.png';
import errorimg from '../../../public/img/error.png';
import axios from 'axios';
import ReactLoading from 'react-loading'
import { GOOGLE_API, GOOGLE_API_KEY } from '../../../Constants'
import * as language from '../../../Constants/translations';
import InputMask from 'react-input-mask'

/*
Class to For payment(Card/Ach) page
 */
class PaymentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discountList: [],
            discountDetails: {
                id: '',
                percent: '',
                amount: ''
            },
            cardListDetails: '',
            setBilling: false,
            billingAddress: {
                id: '',
                street: '',
                city: '',
                state: '',
                country: '',
                zip: ''
            },
            flashMessageError: '',
            flashMessageSuccess: '',
            lang: sessionStorage.language != undefined ? sessionStorage.language : 'en',
            Loading: true,
            validCardObj: {
                ccnum: '',
                ccname: '',
                expmon: '',
                expyear: '',
                cvv2: '',
                cardType: ''
            },
            fontSize: {
                paymentlabel: 13,
                breadcrumb: 12,
                giftCardText: 24,
                cardDetailtext: 16,
                orderSummary: 16,
                cardradio: 14,
                billinglabel: 14,
                subTotalAmmount: 14,
                asideText: 13,
                asideTextPara: 12,
            },
            increaseCounter: 0,
            decreaseCounter: 0,
            discountDetail: {
                discountId: 0,
                discountPercent: 0,
                discountAmount: 0,
                totalAmount: 0

            },
            ispaymentTypeChoose: false,
            paymentType: 'CARD',
            validAchObj: {
                ckname: '',
                ckaba: '',
                ckacct: '',
                ckno: '',
                ckaccttypedesc: '',
                cktype: '',
                ckaccttype: ''
            },
            validBillingAddressObj: {},
            isFieldEmpty: false,
            valicAchfields: {},
            validcCardfields: {}


        }
        this.breadCrumbCLick = this.breadCrumbCLick.bind(this);
    };

    componentWillMount() {
        this.props.discountListAction(sessionStorage.userID)
        this.setState({
            cardListDetails: this.props.cardListDetails,
            valicAchfields: { ...this.state.validAchObj },
            validcCardfields: { ...this.state.validCardObj }
        })
    }

    componentDidMount() {
        if (this.props.createOrder.data != null) {
            this.props.createOrder.data = null
        }
    }

    componentWillReceiveProps(nextProps) {
        try {
            
            let self = this
            if(this.props.customerLoginResponse.data===null && nextProps.customerLoginResponse!==undefined && nextProps.customerLoginResponse.data !==null && nextProps.customerLoginResponse.data.data!==undefined) {
               
                sessionStorage.FromPaymentPage = true
               
                self.props.paymentPageProps.history.push({
                    pathname: '/ordermanagement',
                    state: {
                        pageType: 'shippmentPage',
                        data :this.props.cardListDetails,
                        state:'fromPaymentPage'
                    } 
                }) 
            } 
            if (nextProps.discountList && nextProps.discountList.data) {
                this.setState({
                    discountList: nextProps.discountList.data.data.data
                })
            }
            if (nextProps.createOrder && nextProps.createOrder.data) {
                try {
                    if (nextProps.createOrder.data.status == true) {
                        let domElementPayment = document.getElementById("createOrder");
                        domElementPayment.setAttribute("disabled", "disabled")
                       
                        this.setState({
                            flashMessageSuccess: nextProps.createOrder.data.message
                        })
                        localStorage.removeItem('productCardDetailsLength');
                        localStorage.removeItem('productCardDetailsData');
                        localStorage.removeItem('denominationPriceData');
                        /**If order placed by guest USER */
                        localStorage.removeItem('guestUserDetails');
                        /**for logged in user paymnet */
                        localStorage.removeItem('UserOtherAddress');
                        sessionStorage.removeItem('FromPaymentPage')
                        if (sessionStorage.userID != 0) {
                            setTimeout(function () {
                                self.props.paymentPageProps.history.push({
                                    pathname: '/dashboard',
                                    state: { fromPage: "orderHistory" }
                                })
                            }, 5000);
                        }
                        else {
                            setTimeout(function () {
                                self.props.paymentPageProps.history.push({
                                    pathname: '/',
                                })
                            }, 5000);
                        }
                    } else {
                        
                        this.setState({

                            Loading: false,
                            flashMessageError: nextProps.createOrder.data.message
                        })
                    }
                } catch (err) {
                    this.setState({
                        Loading: false,
                        flashMessageError: 'Network Error'
                    })
                }
            }else {
                this.setState({
                    Loading: false,

                })
            }
        } catch (e) { console.log("bug india",e) }
        
    }

    /****
     * 
     * function to cacluate Discount
     * @param {cardType}
     */
    calculateDiscount(paymentType) {
        var subtotal = this.props.cardListDetails.subTotal
        let discountList = []
        if (this.state.discountList.length) {
            this.state.discountList.map((discount) => {
                if (discount.status == true) {
                    discountList.push(discount)
                }
            })
        }
        var data = _.filter(discountList, (discount) =>{
            let cond ;
            if(moment(new Date()).format('MM/DD/YYYY') >= moment(discount.validFrom).format('MM/DD/YYYY') && moment(new Date()).format('MM/DD/YYYY') <= moment(discount.validTill).format('MM/DD/YYYY') ) {
                cond =  true
            } else if(moment(new Date()).format('MM/DD/YYYY') === moment(discount.validTill).format('MM/DD/YYYY')) {
                cond = true
            } else {
                cond =  false
            }
            // let cond = moment(new Date())> moment(discount.validTill.date) ? false :true
         
            return ( discount.status === true && cond && (subtotal >= discount.amountFrom) && (subtotal <= discount.amountTo && discount.paymentType.toUpperCase() === paymentType.toUpperCase()) || (subtotal >= discount.amountFrom && discount.amountTo == 0 && discount.paymentType.toUpperCase() === paymentType.toUpperCase()))
            
            }
        );
        
        let filteredList = _.filter(discountList, (o) => { return o.amountTo === 0 })

        let maxDiscountPercent = filteredList.length ? filteredList[0].percent : 0

        var discountPercent = data.length ? data[0].percent : 0
        // if(discountPercent == 0) {
        //     discountPercent = maxDiscountPercent
        // }
        var discountId = data.length ? data[0].id : 0
        var discountAmount = discountPercent / 100
        discountAmount = subtotal * discountAmount
        var total = subtotal - (discountAmount)
        let shippingCharges = this.props.cardListDetails.shippingCharges == undefined ? 0 : this.props.cardListDetails.shippingCharges
        var totalAmount = (total + shippingCharges)
        var json = {
            discountId: discountId,
            discountPercent: discountPercent,
            discountAmount: discountAmount,
            totalAmount: totalAmount
        }
        Object.assign(this.state.cardListDetails, json);
    }


    /**
     * Method for setting up valid object to billing address Object 
     * Use for Making boarder red if field is empty
     */
    onSubmitSetValidBillingObj(billingAddress) {
        let tempValidObj = this.state.validBillingAddressObj
        if (billingAddress.street === "") {
            tempValidObj['street'] = ''
        }
        if (billingAddress.zip === '') {
            tempValidObj['zip'] = ''
        }
        if (billingAddress.city === '') {
            tempValidObj['city'] = ''
        }
        if (billingAddress.state === '') {
            tempValidObj['state'] = ''
        }
        if (billingAddress.country === '') {
            tempValidObj['country'] = ''
        }
        this.setState({ validBillingAddressObj: tempValidObj })
    }
    /*
    Function to create the order/buy gift card.
    */
    createOrder() {
       
        let creditCardDetails = this.state.validCardObj
        let achDetails = this.state.validAchObj
        let { billingAddress } = this.state
        if (this.state.paymentType == 'CARD') {
            this.onSubmitSetValidBillingObj(billingAddress)


            if (this.state.setBilling === false && billingAddress.street === '' || billingAddress.zip === '' || billingAddress.city === '' || billingAddress.country === '' || billingAddress.state === '') {
                this.setState({
                    flashMessageError: language.language.Billing_Address_Error[0][this.state.lang],
                    isFieldEmpty: true
                })
                setInterval(function () {
                }, 5000);
            }
            // check payment type
            else if (this.state.paymentType == "CARD") {
                if (creditCardDetails.expmon !== '' && creditCardDetails.expmon == "00") {
                    this.setState({
                        flashMessageError: 'Please select the Month',
                    })
                }
                else if (creditCardDetails.expyear !== '' && creditCardDetails.expyear == "00") {
                    this.setState({
                        flashMessageError: 'Please select the Year',
                    })
                }
                 else if (creditCardDetails.cardType !== '' && creditCardDetails.cardType == "00") {
                    this.setState({
                        flashMessageError: 'Please select card type',
                    })
                }
                else if (creditCardDetails.ccnum !== '' && creditCardDetails.ccname != '' && creditCardDetails.cvv2 !== '' && creditCardDetails.expmon !== '' && creditCardDetails.expyear !== '' && creditCardDetails.cardType !== '') {
                    let cardTypeByNumber = HelperUtil._getCardType(creditCardDetails.ccnum)
                    if(creditCardDetails.cardType !== cardTypeByNumber) return this.setState({flashMessageError : 'Card type does not match credit card number.'})
                    this.makepayment();
                }
                else {
                    this.setState({
                        flashMessageError: language.language.Payment_Details[0][this.state.lang],
                        isFieldEmpty: true
                    })
                }
            }

        }
        else if (this.state.paymentType == "ACH") {
           
            let  achDetails = this.state.valicAchfields
            if(achDetails.ckname === ''  && achDetails.ckaba === '' && achDetails.ckacct === '' && achDetails.ckno === '' && achDetails.ckaccttypedesc === '' && achDetails.cktype === '' && achDetails.ckaccttype === '') {
                this.setState({
                    flashMessageError: language.language.Payment_Details[0][this.state.lang],
                    isFieldEmpty:true
                })
            } else if (achDetails.ckname === '') {
                this.setState({
                    flashMessageError: 'Please fill name field',
                })
            } else if (achDetails.ckaccttypedesc !== '' && achDetails.ckaccttypedesc == "00") {
                this.setState({
                    flashMessageError: 'Please Select Type Description',
                })
            }
            else if (achDetails.ckaccttype !== '' && achDetails.ckaccttype == "00") {
                this.setState({
                    flashMessageError: 'Please select Account Type',
                })
            }
            else if (achDetails.cktype !== '' && achDetails.cktype == "00") {
                this.setState({
                    flashMessageError: 'Please select Check Type',
                })
            }
            else if (achDetails.ckname !== '' && achDetails.ckaba !== '' && achDetails.ckacct !== '' && achDetails.ckno !== '' && achDetails.ckaccttypedesc !== '' && achDetails.cktype !== '' && achDetails.ckaccttype !== '') {
                this.makepayment();
            } else {
                this.setState({
                    flashMessageError: language.language.Payment_Details[0][this.state.lang],
                    isFieldEmpty: true
                })
            }
        }
    }

    /*
    function to change toggle for billing Address.
    */
    setBillingAddress(e) {
        if (e.target.checked == false) {
            this.setState({
                setBilling: e.target.checked,
                billingAddress: {
                    id: '',
                    street: '',
                    city: '',
                    state: '',
                    country: '',
                    zip: ''
                },
                flashMessageError: ''
            })
        }
        else if (e.target.checked == true) {
            let { defaultShippingAddress } = this.props.cardListDetails;
            this.setState({
                setBilling: e.target.checked,
                validBillingAddressObj: {},
                billingAddress: {
                    id: this.props.cardListDetails.shippingAddress.id !== "" ? this.props.cardListDetails.shippingAddress.id : defaultShippingAddress !== undefined ? defaultShippingAddress.id : '',
                    street: this.props.cardListDetails.shippingAddress.street !== "" ? this.props.cardListDetails.shippingAddress.street :
                        defaultShippingAddress !== undefined ? defaultShippingAddress.street : '',
                    city: this.props.cardListDetails.shippingAddress.city !== "" ? this.props.cardListDetails.shippingAddress.city :
                        defaultShippingAddress !== undefined ? defaultShippingAddress.city : '',
                    state: this.props.cardListDetails.shippingAddress.state !== "" ? this.props.cardListDetails.shippingAddress.state :
                        defaultShippingAddress !== undefined ? defaultShippingAddress.state : '',
                    country: this.props.cardListDetails.shippingAddress.country !== "" ? this.props.cardListDetails.shippingAddress.country :
                        defaultShippingAddress !== undefined ? defaultShippingAddress.country : '',
                    zip: this.props.cardListDetails.shippingAddress.zip !== "" ? this.props.cardListDetails.shippingAddress.zip :
                        defaultShippingAddress !== undefined ? defaultShippingAddress.zip : ''
                },
                flashMessageError: ''
            })
        }
    }

    /**
     * Method for autofill address using google API 
     * 
     */
    autoFillAddress = async (event, fieldName) => {
        let fieldValue = ''
        let self = this
        let nbhoodData = ''
        let tempValidObj = this.state.validBillingAddressObj
        try {
            fieldValue = event.target.value
            let key = fieldName
            if (key === 'zip' && (fieldValue.length === 4 || fieldValue.length === 5 || fieldValue.length === 6)) {
                this.setState({ Loading: true })
                await axios.get(`${GOOGLE_API}?address=${fieldValue}&key=${GOOGLE_API_KEY}&timestamp=${new Date().getTime()}`)
                    .then(function (response) {
                        if (response.status === 200) {
                            if (response.data.results.length > 0) {
                                let address = _.filter(response.data.results[0].address_components, function (addressData) {
                                    if (addressData.types[0] === "locality") {
                                        return addressData.long_name
                                    }
                                    if (addressData.types[0] === "neighborhood") {
                                        nbhoodData = addressData.long_name
                                    }
                                    if (addressData.types[0] === "administrative_area_level_1") {
                                        return addressData.long_name
                                    }
                                    if (addressData.types[0] === "country") {
                                        return addressData.long_name
                                    }
                                });
                                if (address.length === 2) {
                                    let addMissingAddressObject = {
                                        long_name: nbhoodData,
                                    }
                                    address.splice(0, 0, addMissingAddressObject)
                                }

                                //code for setting input field in billing address 
                                if (address[0].long_name !== '') {
                                    tempValidObj['city'] = address[0].long_name
                                }
                                if (address[1].long_name !== '') {
                                    tempValidObj['state'] = address[1].long_name
                                }
                                if (address[2].long_name !== '') {
                                    tempValidObj['country'] = address[2].long_name
                                }

                                self.setState({
                                    billingAddress: {
                                        street: self.state.billingAddress.street,
                                        zip: fieldValue,
                                        city: address[0] !== undefined && address[0] !== "" ? address[0].long_name : self.billingAddress.city,
                                        state: address[1] !== undefined && address[1] !== "" ? address[1].long_name : self.billingAddress.state,
                                        country: address[2] !== undefined && address[2] !== "" ? address[2].long_name : self.billingAddress.country
                                    }
                                })
                            } else {
                                self.setState({
                                    billingAddress: {
                                        street: self.state.billingAddress.street,
                                        zip: fieldValue,
                                        city: "",
                                        state: "",
                                        country: "",
                                    }
                                })
                            }
                        }
                    })
                    .catch(function (errorResponse) { })
                this.setState({ Loading: false })
            } else {
                this.setState({
                    Loading: false,
                    billingAddress: {
                        street: self.state.billingAddress.street,
                        zip: fieldValue,
                        city: "",
                        state: "",
                        country: "",
                    }
                })
            }
        } catch (e) { }
    }

    /***
    * Method for allowed only charter in address fields 
    * @param {val}
    * @returns {Boolean}
    */
    isCharter = (val) => {
        let len = val.length;
        let num = 32
        let regX = /^([a-zA-Z]+\s*)*[a-zA-Z]+$/
        try {

            num = +val[len - 1].toLocaleUpperCase().charCodeAt()
        } catch (e) { }
        return ((regX.test(val)) || num === 32) ? true : false
    }

    /**
   * function for add other address
   * @param object e
   * @return object state
   */
    async setValue(e) {

        this.setState({ flashMessageError: '' })
        let val = e.target.value.trimStart();
        let key = e.target.name;
        let { validBillingAddressObj } = this.state
        let valid = {};
        if (val === '') {
            validBillingAddressObj[key] = ''
            valid[key] = '';
        } else {
            valid[key] = val.trimStart();
            validBillingAddressObj[key] = val.trimStart()

        }

        this.setState({ validBillingAddressObj: validBillingAddressObj })
        let billingAddress = Object.assign(this.state.billingAddress, valid);
        if (key !== 'zip' && !this.isCharter(val) && key !== 'street') return 0;

        this.setState({ billingAddress: billingAddress, addressFlashMessageError: '' });
    }

    /*
    function to change Languages
    */
    setLanguage(data) {
        this.setState({
            lang: data
        })
    }

    /*
    function to submit form on enter Keypress
    */
    submitOnEnter(event) {
        if (event.which == 13 || event.keyCode == 13) {
            if (event.target.id == 'country') {
                try {
                    if (document.getElementById(event.target.id).readOnly == false) {
                        this.createOrder.bind()
                    }
                } catch (err) { }
            }
        }
    }

    /*
    function to set creditCard value
    */
    setCardValue = (event) => {
        this.setState({ flashMessageError: "", flashMessageSuccess: "" })
        let key = event.target.name
        let value = event.target.value
        let valid = {}
        let invalid = {}
        let tempCardValidObj = this.state.validcCardfields
        if (value === "") {
            invalid[key] = "This Field is required"
            valid[key] = ""
            tempCardValidObj[key] = ''
        } else {
            invalid[key] = ""
            valid[key] = value
            tempCardValidObj[key] = value

        }
        if (key === "ccname") {
            if (this.isCharter(value)) {
                let validaData = Object.assign(this.state.validCardObj, valid)
                this.setState({ validCardObj: validaData })
            }
        } else {
            let validaData = Object.assign(this.state.validCardObj, valid)
            this.setState({ validCardObj: validaData })
        }
        this.setState({ validcCardfields: tempCardValidObj })
    }

    /***
    * Method for breadCrumbCLick
    * @param {event} 
    * @return {undefined}
    */
    breadCrumbCLick(event) {
        let cartProdeuctList = JSON.parse(localStorage.getItem("productCardDetailsData"))
        let targetName = event.target.className
        if (targetName === "active gift-card") {
            if (sessionStorage.hasOwnProperty("emailID") && sessionStorage.emailID !== '') {
                if (this.props.paymentPageProps !== undefined) {
                    this.props.paymentPageProps.history.push("/ordermanagement")
                }
            } else {
                if (this.props.paymentPageProps !== undefined) {
                    this.props.paymentPageProps.history.push("/")
                }
            }
        }
        if (targetName === "active view-cart") {
            if (this.props.paymentPageProps !== undefined) {
                this.props.paymentPageProps.history.push("/orderDetailsManagements")
            }
        }
        
        if (targetName === "active shoping") {
            let {location}  = this.props.paymentPageProps
            this.props.paymentPageProps.history.push({
                pathname: "/ordermanagement",
                state: {
                    data: cartProdeuctList,
                    pageType: "shippmentPage",
                    shippignAddressType:location.state.data['shippingAddress'].addresstype === undefined ?'Other' :location.state.data['shippingAddress'].addresstype
                }
            })
        }
    }

    /*
    function to change font Size
    */
    changeSize(data) {
        if (data === 'increase') {
            var increaseCounter = this.state.increaseCounter
            increaseCounter = increaseCounter + 1
            if (increaseCounter <= 4) {
                this.setState({
                    fontSize: {
                        asideText: Number(this.state.fontSize.asideText) + 1,
                        asideTextPara: Number(this.state.fontSize.asideTextPara) + 1,
                        orderSummary: Number(this.state.fontSize.orderSummary) + 1,
                        paymentlabel: Number(this.state.fontSize.paymentlabel) + 1,
                        breadcrumb: Number(this.state.fontSize.breadcrumb) + 1,
                        giftCardText: Number(this.state.fontSize.giftCardText) + 1,
                        cardDetailtext: Number(this.state.fontSize.cardDetailtext) + 1,
                        cardradio: Number(this.state.fontSize.cardradio) + 1,
                        billinglabel: Number(this.state.fontSize.billinglabel) + 1,
                        subTotalAmmount: Number(this.state.fontSize.subTotalAmmount) + 1,
                    },
                    increaseCounter: increaseCounter
                })
            }
        }
        else if (data === 'decrease') {
            var decreaseCounter = this.state.decreaseCounter
            decreaseCounter = decreaseCounter + 1
            if (decreaseCounter <= 4) {
                this.setState({
                    fontSize: {
                        asideText: Number(this.state.fontSize.asideText) - 1,
                        asideTextPara: Number(this.state.fontSize.asideTextPara) - 1,
                        paymentlabel: Number(this.state.fontSize.paymentlabel) - 1,
                        breadcrumb: Number(this.state.fontSize.breadcrumb) - 1,
                        giftCardText: Number(this.state.fontSize.giftCardText) - 1,
                        cardDetailtext: Number(this.state.fontSize.cardDetailtext) - 1,
                        cardradio: Number(this.state.fontSize.cardradio) - 1,
                        billinglabel: Number(this.state.fontSize.billinglabel) - 1,
                        orderSummary: Number(this.state.fontSize.orderSummary) - 1,
                        subTotalAmmount: Number(this.state.fontSize.subTotalAmmount) - 1,
                    },
                    decreaseCounter: decreaseCounter,
                    increaseCounter: 0
                })
            }
        }
        else if (data === 'default') {
            this.setState({
                fontSize: {
                    subTotalAmmount: 14,
                    asideText: 13,
                    asideTextPara: 12,
                    orderSummary: 16,
                    paymentlabel: 13,
                    billinglabel: 14,
                    cardradio: 14,
                    breadcrumb: 12,
                    cardDetailtext: 16,
                    giftCardText: 24,
                },
                increaseCounter: 0,
                decreaseCounter: 0
            })
        }
    }

    /**
     * Method for get Discount on payment Type clicked 
     * @param{event e} supply card type as first argument to the function 
     */
    getDicount(e, event) {
        
        let ach = document.getElementById('ach')
        let card = document.getElementById('card')
        if (e == "ACH") {
            ach.style.display = 'block'
            this.setState({
                paymentType: 'ACH',
                flashMessageError: '',
                validCardObj: {
                    ccname: '',
                    ccnum: '',
                    cvv2: '',
                    expmon: '',
                    expyear: '',
                },
                
                ispaymentTypeChoose: true,
                isFieldEmpty: false,
                validcCardfields: { ...this.state.validcCardfields },
                setBilling:this.state.setBilling,
            })
           
            card.style.display = 'none'
        } else {
            ach.style.display = 'none'
            this.setState({
                paymentType: 'CARD',
                flashMessageError: '',
                ispaymentTypeChoose: false,
                isFieldEmpty: false,
                valicAchfields: {     
                ckname: '',
                ckaba: '',
                ckacct: '',
                ckno: '',
                ckaccttypedesc: '',
                cktype: '',
                ckaccttype: '',
                }
            })
            card.style.display = 'block'
        }
        this.calculateDiscount(e)// getting discount on payment type  
        let { cardListDetails } = this.state
        this.setState({
            discountDetail: {
                discountId: cardListDetails.discountId,
                discountPercent: cardListDetails.discountPercent,
                discountAmount: cardListDetails.discountAmount,
                totalAmount: cardListDetails.totalAmount
            }
        })
    }

    /*
    function to set ACH value
    */
    setACHValue = (event) => {

        this.setState({ flashMessageError: "", flashMessageSuccess: "" })
        let key = event.target.name
        let value = event.target.value
        let valid = {}
        let invalid = {}
        let tempAchValidFields = this.state.valicAchfields
        if (value === "") {
            invalid[key] = "This Field is required"
            valid[key] = ""
            tempAchValidFields[key] = ''
        } else {
            invalid[key] = ""
            valid[key] = value;
            tempAchValidFields[key] = value
        }
        if (key === "ckname") {
            if (this.isCharter(value)) {
                let validaData = Object.assign(this.state.validCardObj, valid)
                this.setState({ validAchObj: validaData })
            }
        } else {
            let validaData = Object.assign(this.state.validCardObj, valid)
            this.setState({ validAchObj: validaData })
        }
        this.setState({ valicAchfields: tempAchValidFields })
    }

    /**
     * function to Call Make Payemnt API
     * 
     */
        
     makepayment() {
        let creditCardDetails = this.state.validCardObj
        let { validAchObj } = this.state
        var orderDate = this.props.cardListDetails.orderDate
        var eta = moment(this.props.cardListDetails.eta).format('MM/DD/YYYY')
        var denomination = ''
        var denominationList = []
        denomination = this.props.cardListDetails.length ? this.props.cardListDetails.map((cards) => {
            denomination = {
                "productId": cards.variantId,
                "noOfCards": cards.productQuantity,
                "price": cards.price
            }
            denominationList.push(denomination)
        }) : ''
        if (sessionStorage.userID != 0) {
            let { defaultShippingAddress } = this.props.cardListDetails
            let createOrderJson = {
                "orderDate": orderDate,
                "eta": eta,
                "subTotal": this.props.cardListDetails.subTotal.toFixed(2),
                "bankCharges": 0,
                "total": this.state.discountDetail.discountId === 0 ? this.state.cardListDetails.totalAmount.toFixed(2) : this.state.discountDetail.totalAmount.toFixed(2),
                "totalCards": this.props.cardListDetails.totalQty,
                "status": 0,  //in starting should be 0 for pending order, after payment it will be 1
                "customerId": sessionStorage.userID,
                "discountId": this.state.cardListDetails.discountId,
                "discountAmount": this.state.cardListDetails.discountAmount,
                "paymentType": this.state.paymentType,
                "shippingId": this.props.cardListDetails.shippingDetails.id === undefined || this.props.cardListDetails.shippingDetails.id === "" ? 0 : this.props.cardListDetails.shippingDetails.id,
                "shippingAddress": {
                    "id": this.props.cardListDetails.shippingAddress.id !== "" ? this.props.cardListDetails.shippingAddress.id : defaultShippingAddress.id,
                    "address1": this.props.cardListDetails.shippingAddress.street !== "" ? this.props.cardListDetails.shippingAddress.street : defaultShippingAddress.street,
                    "address2": "",
                    "zip": this.props.cardListDetails.shippingAddress.zip !== "" ? this.props.cardListDetails.shippingAddress.zip : defaultShippingAddress.zip,
                    "city": this.props.cardListDetails.shippingAddress.city !== "" ? this.props.cardListDetails.shippingAddress.city : defaultShippingAddress.city,
                    "state": this.props.cardListDetails.shippingAddress.state !== "" ? this.props.cardListDetails.shippingAddress.state : defaultShippingAddress.state,
                    "country": this.props.cardListDetails.shippingAddress.country !== "" ? this.props.cardListDetails.shippingAddress.country : defaultShippingAddress.country
                },
                "billingAddress": {
                    "id": this.state.billingAddress.id,
                    "address1": this.state.billingAddress.street,
                    "Address2": "",
                    "zip": this.state.billingAddress.zip,
                    "city": this.state.billingAddress.city,
                    "state": this.state.billingAddress.state,
                    "country": "US"
                },
                "denomination": denominationList,
                "orderNumber": new Date().getTime(),
                "lang" : this.state.lang,
                "cardType": creditCardDetails.cardType
            }
            this.setState({ Loading: true })
            let amount = createOrderJson.total
            let submiAmount = createOrderJson.total * 100
            let cardPymentData = constant.MAKING_CREDIT_CARD_PAYMENT + createOrderJson.orderNumber + "&amount=" + amount + "&ccnum=" + creditCardDetails.ccnum + "&ccname=" + creditCardDetails.ccname + "&expmon=" + creditCardDetails.expmon + "&expyear=" + creditCardDetails.expyear + "&cvv2=" + creditCardDetails.cvv2 + "&ci_email=" + sessionStorage.emailID + "&ci_billaddr1=" + createOrderJson.billingAddress.address1 + "&ci_billcity=" + createOrderJson.billingAddress.city + "&ci_billstate=" + createOrderJson.billingAddress.state + "&ci_billzip=" + createOrderJson.billingAddress.zip + "&currencycode=USD&ci_billcountry=US"
            // Rajesh new request prepared for payeeze-api start
            let newCardPaymentData = {
                "merchant_ref": createOrderJson.orderNumber,
                "transaction_type": "authorize",
                "method": "credit_card",
                "amount": Math.trunc(submiAmount),
                "currency_code": "USD",
                "credit_card": {
                  "type": creditCardDetails.cardType,
                  "cardholder_name": creditCardDetails.ccname,
                  "card_number": creditCardDetails.ccnum,
                  "exp_date": creditCardDetails.expmon + creditCardDetails.expyear.slice(2),
                  "cvv": creditCardDetails.cvv2
                }
              };
              // console.log("total",createOrderJson.total);
              // console.log("ruppees",newCardPaymentData);
            // Rajesh new request prepared for payeeze-api end
            let achPaymentData = constant.MAKING_ACH_PAYMENT + validAchObj.ckname + "</ckname><ckaba>" + validAchObj.ckaba + "</ckaba><ckacct>" + validAchObj.ckacct + "</ckacct><ckno>" + validAchObj.ckno + "</ckno><ckaccttype>" + validAchObj.ckaccttype + "</ckaccttype><ckaccttypedesc>" + validAchObj.ckaccttypedesc + "</ckaccttypedesc><cktype>" + validAchObj.cktype + "</cktype><ipaddress>127.0.0.1</ipaddress><currencycode>USD</currencycode><amount>" + amount + "</amount><merchantordernumber>" + createOrderJson.orderNumber + "</merchantordernumber></inputs></transaction></trans_catalog></interface_driver>"
            //let paymentData = this.state.paymentType == 'CARD' ? cardPymentData : achPaymentData
            let paymentData = this.state.paymentType == 'CARD' ? newCardPaymentData : achPaymentData
            this.props.buyGiftCardAction(createOrderJson, paymentData)
        } else {
           
            let createOrderJson = {
                "userType": "guest",
                "firstName": this.props.cardListDetails.guestUserDetails.firstName,
                "lastName": this.props.cardListDetails.guestUserDetails.lastName,
                "email": this.props.cardListDetails.guestUserDetails.email,
                "phone": "",
                "mobile": this.props.cardListDetails.guestUserDetails.mobile,
                "companyName": "",
                "designation": "",
                "federalTax": "",
                "consent": this.props.cardListDetails.guestUserDetails.consent,
                "orderDate": orderDate,
                "eta": eta,
                "subTotal": this.props.cardListDetails.subTotal.toFixed(2),
                "bankCharges": 0,
                "total": this.state.discountDetail.discountId === 0 ? this.state.cardListDetails.totalAmount.toFixed(2) : this.state.discountDetail.totalAmount.toFixed(2),
                "totalCards": this.props.cardListDetails.totalQty,
                "status": 0,  //in starting should be 0 for pending order, after payment it will be 1
                "customerId": 0,
                "discountId": this.state.cardListDetails.discountId,
                "discountAmount": this.state.cardListDetails.discountAmount,
                "paymentType": this.state.paymentType,
                "shippingId": this.props.cardListDetails.shippingDetails.id !== undefined ? this.props.cardListDetails.shippingDetails.id : 0,
                "shippingAddress": {
                    "id": 0,
                    "address1": this.props.cardListDetails.shippingAddress.street,
                    "address2": "",
                    "zip": this.props.cardListDetails.shippingAddress.zip,
                    "city": this.props.cardListDetails.shippingAddress.city,
                    "state": this.props.cardListDetails.shippingAddress.state,
                    "country": this.props.cardListDetails.shippingAddress.country
                },
                "billingAddress": {
                    "id": 0,
                    "address1": this.state.billingAddress.street,
                    "address2": "",
                    "zip": this.state.billingAddress.zip,
                    "city": this.state.billingAddress.city,
                    "state": this.state.billingAddress.state,
                    "country": "US"
                },
                "denomination": denominationList,
                "orderNumber": new Date().getTime(),
                "lang" : this.state.lang,
                "cardType": creditCardDetails.cardType
            }
            this.setState({ Loading: true })
            let amount = createOrderJson.total
            let submiAmount = createOrderJson.total * 100
            let cardPymentData = constant.MAKING_CREDIT_CARD_PAYMENT + createOrderJson.orderNumber + "&amount=" + amount + "&ccnum=" + creditCardDetails.ccnum + "&ccname=" + creditCardDetails.ccname + "&expmon=" + creditCardDetails.expmon + "&expyear=" + creditCardDetails.expyear + "&cvv2=" + creditCardDetails.cvv2 + "&ci_email=" + this.props.cardListDetails.guestUserDetails.email + "&ci_billaddr1=" + createOrderJson.billingAddress.address1 + "&ci_billcity=" + createOrderJson.billingAddress.city + "&ci_billstate=" + createOrderJson.billingAddress.state + "&ci_billzip=" + createOrderJson.billingAddress.zip + "&currencycode=USD&ci_billcountry=US"
            // Rajesh new request prepared for payeeze-api start
            let newCardPaymentData = {
                "merchant_ref": createOrderJson.orderNumber,
                "transaction_type": "authorize",
                "method": "credit_card",
                "amount": Math.trunc(submiAmount),
                "currency_code": "USD",
                "credit_card": {
                  "type": creditCardDetails.cardType,
                  "cardholder_name": creditCardDetails.ccname,
                  "card_number": creditCardDetails.ccnum,
                  "exp_date": creditCardDetails.expmon + creditCardDetails.expyear.slice(2),
                  "cvv": creditCardDetails.cvv2
                }
              };
            // Rajesh new request prepared for payeeze-api end
            let achPaymentData = constant.MAKING_ACH_PAYMENT + validAchObj.ckname + "</ckname><ckaba>" + validAchObj.ckaba + "</ckaba><ckacct>" + validAchObj.ckacct + "</ckacct><ckno>" + validAchObj.ckno + "</ckno><ckaccttype>" + validAchObj.ckaccttype + "</ckaccttype><ckaccttypedesc>" + validAchObj.ckaccttypedesc + "</ckaccttypedesc><cktype>" + validAchObj.cktype + "</cktype><ipaddress>127.0.0.1</ipaddress><currencycode>USD</currencycode><amount>" + amount + "</amount><merchantordernumber>" + createOrderJson.orderNumber + "</merchantordernumber></inputs></transaction></trans_catalog></interface_driver>"
            // let paymentData = this.state.paymentType == 'CARD' ? cardPymentData : achPaymentData
            let paymentData = this.state.paymentType == 'CARD' ? newCardPaymentData : achPaymentData
            this.props.buyGiftCardAction(createOrderJson, paymentData)
        }
    }

    /***
     * Method for redirectGiftCard giftCard
     * @param {event} 
     * @return {undefined}
     */
    redirectGiftCard() {
        if (this.props.paymentPageProps !== undefined) {
            this.props.paymentPageProps.history.push("/ordermanagement")
        }
    }

    render() {
        
        if (sessionStorage.userID != 0 && sessionStorage.loggedIn === '') {
            this.props.paymentPageProps.history.push({
                pathname: '/'
            });
        }
        var lang = this.state.lang
        const { fontSize } = this.state
        this.calculateDiscount(this.state.paymentType)
        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <p style={{ fontSize: fontSize.paymentlabel + 'px' }} >{this.state.flashMessageSuccess}</p>;
        }
        if (this.state.flashMessageError) {
            
            flashErrorMessageSpan = <p style={{ fontSize: fontSize.paymentlabel + 'px' }}>{this.state.flashMessageError}</p>;
        }
        let shippingCharges = this.props.cardListDetails.shippingCharges == undefined ? 0 : this.props.cardListDetails.shippingCharges
       
        var currentYear = new Date().getFullYear();
        let { validCardObj } = this.state
        let { validAchObj } = this.state
        let { validBillingAddressObj } = this.state
        let { valicAchfields } = this.state
        let { validcCardfields } = this.state
        
        return (
            <div id="myaccount">
                {
                    this.state.Loading === true && <div className="loader-react">
                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                    </div>
                }
                <div className="wrapper page customerHome" >
                    <Header language={this.setLanguage.bind(this)} changeSize={this.changeSize.bind(this)} locationProps={this.props} />
                    <section id="main">
                        <div className="container-fluid">
                            <div className="row">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item ">
                                        <a style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                            href="javascript:void(0);" className="active gift-card"
                                            onClick={this.breadCrumbCLick}>{language.language.home[0][lang]}
                                        </a>
                                    </li>
                                    {sessionStorage.emailID === '' || sessionStorage.emailID == undefined ?
                                        <li className="breadcrumb-item">
                                            <a style={{ fontSize: fontSize.breadcrumb + 'px' }} href="javascript:void(0);"
                                                onClick={(e) => this.redirectGiftCard()}
                                                className="active">{language.language.gift_cards[0][lang]}</a></li>
                                        : ''}
                                    <li className="breadcrumb-item">
                                        <a className="active view-cart" href="javascript:void(0);"
                                            onClick={this.breadCrumbCLick}
                                            style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                        >{language.language.view_cart[0][lang]}
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a className="active shoping"
                                            style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                            href="javascript:void(0);" onClick={this.breadCrumbCLick}> {language.language.shipping_details[0][lang]}</a></li>
                                    <li className="breadcrumb-item"><a style={{ fontSize: fontSize.breadcrumb + 'px' }}>{language.language.payment_details[0][lang]}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="shipnig-heading">
                                        <h2 style={{ fontSize: fontSize.giftCardText + 'px' }}>{language.language.payment_details[0][lang]}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="card-details boxshadow30">
                                        <h3 style={{ fontSize: fontSize.cardDetailtext + 'px' }}>{language.language.card_details[0][lang]}</h3>
                                        <div className="row">
                                            <div className="col-md-10">
                                                <div className="payment-form-section">
                                                    <div className="row">
                                                        <div className="col-md-6 p-0">
                                                            <div className="card-checkbox-section">
                                                                <div className="card-radio-btn">
                                                                    <div className="row">
                                                                        <div className="col-md-6 pull-left">
                                                                            <label className="card-radio payment_radio_btn checkbox-inline">
                                                                                {
                                                                                    this.state.ispaymentTypeChoose === false ?
                                                                                        <input title="payment-type"  type="radio" name="payment-type" value="card"
                                                                                            checked
                                                                                            // onClick={this.getDicount.bind(this, "CARD")}
                                                                                        />
                                                                                        :
                                                                                        <input title="payment-type"  type="radio" name="payment-type" value="card"
                                                                                            // onClick={this.getDicount.bind(this, "CARD")}
                                                                                        />
                                                                                }
                                                                              <em>&nbsp;</em>
                                                                                <span style={{ fontSize: fontSize.cardradio + 'px' }} >{language.language.CARD[0][lang]}</span>
                                                                            </label>
                                                                        </div>
                                                                        {/* <div className="col-md-6 pull-left">
                                                                            <label className="card-radio payment_radio_btn checkbox-inline">
                                                                                <input title="ACH"  type="radio" name="payment-type" value="ach"
                                                                                    onClick={this.getDicount.bind(this, "ACH")}
                                                                                />
                                                                                <em>&nbsp;</em>
                                                                                <span style={{ fontSize: fontSize.cardradio + 'px' }}>{language.language.ACH[0][lang]}</span>
                                                                            </label>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.ispaymentTypeChoose === false ?
                                                            <div className="col-md-6 custom_width">
                                                                <div className="card-details-section clearfix">
                                                                    <img className="auto" src={paymenticon} alt="paymenticon" title="paymenticon" />

                                                                </div>
                                                            </div> : ''
                                                        }
                                                    </div>
                                                    {/* {card Payment form} */}
                                                    <form className="payment-form" id='card'>
                                                        <fieldset>

                                                             {/* Card Holder Name */}

                                                             <div className="form-group">
                                                                <label style={{ fontSize: fontSize.paymentlabel + 'px' }}>{language.language.CARDHOLDER[0][lang]}</label>
                                                                <input title= {language.language.CARDHOLDER[0][lang]}
                                                                    type="text"
                                                                    className={`form-control ${validcCardfields.ccname === '' && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                    onChange={(e) => this.setCardValue(e)}
                                                                    value={validCardObj.ccname}
                                                                    id=""
                                                                    name="ccname"
                                                                    style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                />
                                                            </div>

                                                              {/* cardTypeDropDown */}
                                                              <div className="form-group">
                                                                        <label style={{ fontSize: fontSize.paymentlabel + 'px' }}>Card Type</label>
                                                                            <div className="select-style">
                                                                                <select title={language.language.MONTH[0][lang]} onChange={(e) => this.setCardValue(e)} value={validCardObj.cardType} maskChar={null} name="cardType"
                                                                                    className={`form-control ${(validcCardfields.cardType === '' || validcCardfields.cardType === '00') && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                                    style={{ fontSize: fontSize.paymentlabel + 'px' }} >
                                                                                    <option value="00" className="select-one">Select card type</option>
                                                                                    <option value="VISA">VISA</option>
                                                                                    <option value="MASTERCARD">MASTERCARD</option>
                                                                                    <option value="AMERICAN EXPRESS">AMERICAN EXPRESS</option>
                                                                                    <option value="DISCOVER">DISCOVER</option>
                                                                                    {/* <option value="MAESTRO">MAESTRO</option> */}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                            {/* Card Number */}
                                                            <div className="form-group">
                                                                <label style={{ fontSize: fontSize.paymentlabel + 'px' }}>{language.language.CARD_NUMBER[0][lang]}</label>
                                                                <InputMask title={language.language.CARD_NUMBER[0][lang]}
                                                                    type='text'
                                                                    className={`form-control ${validcCardfields.ccnum === '' && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                    mask={"9999999999999999"}
                                                                    value={validCardObj.ccnum}
                                                                    onChange={(e) => this.setCardValue(e)}
                                                                    maskChar={""}
                                                                    name="ccnum" 
                                                                    title="CARD_NUMBER"
                                                                    style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                    
                                                                    />
                                                            </div>
                                                          
                                                                
                                                           
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label style={{ fontSize: fontSize.paymentlabel + 'px' }}>{language.language.MONTH[0][lang]}</label>
                                                                            <div className="select-style">
                                                                                <select title={language.language.MONTH[0][lang]} onChange={(e) => this.setCardValue(e)} value={validCardObj.expmon} maskChar={null} name="expmon"
                                                                                    className={`form-control ${(validcCardfields.expmon === '' || validcCardfields.expmon === '00') && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                                    style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                                >
                                                                                    <option value="00" className="select-one">{language.language.Select_MONTH[0][lang]}</option>
                                                                                    <option value="01">01</option>
                                                                                    <option value="02">02</option>
                                                                                    <option value="03">03</option>
                                                                                    <option value="04">04</option>
                                                                                    <option value="05">05</option>
                                                                                    <option value="06">06</option>
                                                                                    <option value="07">07</option>
                                                                                    <option value="08">08</option>
                                                                                    <option value="09">09</option>
                                                                                    <option value="10">10</option>
                                                                                    <option value="11">11</option>
                                                                                    <option value="12">12</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label style={{ fontSize: fontSize.paymentlabel + 'px' }}>{language.language.YEAR[0][lang]}</label>
                                                                            <div className="select-style">
                                                                                <select title={language.language.YEAR[0][lang]} onChange={(e) => this.setCardValue(e)} value={validCardObj.expyear} name="expyear"
                                                                                    className={`form-control ${(validcCardfields.expyear === '' || validcCardfields.expyear === '00') && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                                    style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                                >
                                                                                    <option value="00" className="select-one">{language.language.Select_Year[0][lang]}</option>
                                                                                    <option value={currentYear}>{currentYear}</option>
                                                                                    <option value={currentYear + 1}>{currentYear + 1}</option>
                                                                                    <option value={currentYear + 2}>{currentYear + 2}</option>
                                                                                    <option value={currentYear + 3}>{currentYear + 3}</option>
                                                                                    <option value={currentYear + 4}>{currentYear + 4}</option>
                                                                                    <option value={currentYear + 5}>{currentYear + 5}</option>
                                                                                    <option value={currentYear + 6}>{currentYear + 6}</option>
                                                                                    <option value={currentYear + 7}>{currentYear + 7}</option>
                                                                                    <option value={currentYear + 8}>{currentYear + 8}</option>
                                                                                    <option value={currentYear + 9}>{currentYear + 9}</option>
                                                                                    <option value={currentYear + 10}>{currentYear + 10}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group"> 
                                                                            <label style={{ fontSize: fontSize.paymentlabel + 'px' }}>{language.language.cvv[0][lang]}</label>
                                                                            <InputMask title={language.language.cvv[0][lang]}
                                                                                mask={"9999"}
                                                                                onChange={(e) => this.setCardValue(e)}
                                                                                maskChar={null}
                                                                                value={validCardObj.cvv2}
                                                                                name="cvv2"
                                                                                className={`form-control ${validcCardfields.cvv2 === '' && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                                style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                                type='password'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </form>
                                                    {/* {ACH Payment form} */}
                                                    {/* <form className="payment-form" id='ach' style={{ display: 'none' }}>
                                                        <fieldset>
                                                            <div className="form-group">
                                                                <label>{language.language.ACH_Name[0][lang]}</label>
                                                                <input title="Name"
                                                                    value={validAchObj.ckname}
                                                                    type="text"
                                                                    className={`form-control ${valicAchfields.ckname === "" && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                    name="ckname"
                                                                    onChange={(e) => this.setACHValue(e)}
                                                                    maxLength="50"
                                                                />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{language.language.ACH_Routing_Number[0][lang]}</label>
                                                                        <InputMask title="Routing Number"
                                                                            mask={"999999999999999"}
                                                                            maskChar={null}
                                                                            value={validAchObj.ckaba}
                                                                            type="text"
                                                                            className={`form-control ${valicAchfields.ckaba === "" && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                            name="ckaba"
                                                                            onChange={(e) => this.setACHValue(e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{language.language.ACH_Account_Number[0][lang]}</label>
                                                                        <InputMask title="Account Number"
                                                                            mask={"99999999999999999999"}
                                                                            maskChar={null} value={validAchObj.ckacct}
                                                                            type="text"
                                                                            className={`form-control ${valicAchfields.ckacct === "" && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                            name="ckacct"
                                                                            onChange={(e) => this.setACHValue(e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label >{language.language.ACH_Check_Number[0][lang]}</label>
                                                                        <InputMask title="Check Number"
                                                                            mask={"999999999999999"}
                                                                            maskChar={null}
                                                                            value={validAchObj.ckno}
                                                                            type="text"
                                                                            className={`form-control ${valicAchfields.ckno === "" && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                            name="ckno" onChange={(e) => this.setACHValue(e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label >{language.language.ACH_Account_Discription[0][lang]}</label>
                                                                        <div className="select-style">
                                                                            <select title="Account Type Description" value={validAchObj.ckaccttypedesc} name="ckaccttypedesc" onChange={(e) => this.setACHValue(e)}

                                                                                className={`form-control ${(valicAchfields.ckaccttypedesc === "" || valicAchfields.ckaccttypedesc === "00") && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                            >
                                                                                <option value="00" className="select-one">{language.language.ACH_Select_Discription[0][lang]}</option>
                                                                                <option value="personal">Personal</option>
                                                                                <option value="business">{language.language.Business[0][lang]}</option>
                                                                            </select>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label >{language.language.ACH_Account_Type[0][lang]}</label>
                                                                        <div className="select-style" >
                                                                            <select title="Account Type" value={validAchObj.ckaccttype} name="ckaccttype" onChange={(e) => this.setACHValue(e)}
                                                                                className={`form-control ${(valicAchfields.ckaccttype === "" || valicAchfields.ckaccttype === "00") && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                            >
                                                                                <option value="00" className="select-one">{language.language.ACH_Select_Account_Type[0][lang]}</option>
                                                                                <option value="1">{language.language.ACH_DropDown_Checking[0][lang]}</option>
                                                                                <option value="2">{language.language.ACH_DropDown_Saving[0][lang]}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label >{language.language.ACH_Check_Type[0][lang]}</label>
                                                                        <div className="select-style">
                                                                            <select title="Check Type" value={validAchObj.cktype} name="cktype" onChange={(e) => this.setACHValue(e)}
                                                                                className={`form-control ${(valicAchfields.cktype === "" || valicAchfields.cktype === "00") && this.state.isFieldEmpty ? 'red' : ''}`}
                                                                            >
                                                                                <option value="00" className="select-one">{language.language.ACH_Select_Check_Type[0][lang]}</option>
                                                                                <option value="ARC">{language.language.ARC[0][lang]}</option>
                                                                                <option value="BOC">{language.language.BOC[0][lang]}</option>
                                                                                <option value="C21">{language.language.C21[0][lang]}</option>
                                                                                <option value="CCD">{language.language.CCD[0][lang]}</option>
                                                                                <option value="POP">{language.language.POP[0][lang]}</option>
                                                                                <option value="PPD">{language.language.PPD[0][lang]}</option>
                                                                                <option value="TEL">{language.language.TEL[0][lang]}</option>
                                                                                <option value="WEB">{language.language.WEB[0][lang]}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </form> */}
                                                </div>
                                                {this.state.paymentType == 'CARD' ?
                                                    <div className="col-md-12">
                                                        <div className="billing-heading">
                                                            <h3 style={{ fontSize: fontSize.cardDetailtext + 'px' }}>{language.language.billing_address[0][lang]}</h3>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <form className="billing-address">
                                                                    <fieldset>
                                                                        <legend></legend>
                                                                        <div className="form-group billing-switch">
                                                                            <label className="switch ">
                                                                                <input title="BillingAddress" type="checkbox" onClick={(e) => this.setBillingAddress(e)} checked={this.state.setBilling} />
                                                                                <span className="slider round"></span>
                                                                            </label>
                                                                            <span style={{ fontSize: fontSize.paymentlabel + 'px' }} className="billing-switch-text">{language.language.billing_same[0][lang]}</span>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label style={{ fontSize: fontSize.billinglabel + 'px' }}>{language.language.Street[0][lang]}</label>
                                                                            <input title={language.language.Street[0][lang]}
                                                                                type="text"
                                                                                readOnly={this.state.setBilling}
                                                                                value={this.state.billingAddress.street}
                                                                                className={`form-control ${this.state.setBilling === true ? "non-editable" : ""}
                                                                                ${validBillingAddressObj.street === '' && this.state.isFieldEmpty ? 'red' : ''}
                                                                                `}
                                                                                id=""
                                                                                name='street'
                                                                                onChange={this.setValue.bind(this)}
                                                                                maxLength="60"
                                                                                style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label style={{ fontSize: fontSize.billinglabel + 'px' }}>{language.language.Zip_Code[0][lang]}</label>
                                                                            <InputMask title={language.language.Zip_Code[0][lang]}
                                                                                mask="999999"
                                                                                maskChar={null}
                                                                                type="text"
                                                                                readOnly={this.state.setBilling}
                                                                                value={this.state.billingAddress.zip}
                                                                                className={`form-control ${this.state.setBilling === true ? "non-editable" : ""}
                                                                    
                                                                    ${validBillingAddressObj.zip === '' && this.state.isFieldEmpty ? 'red' : ''}
                                                                    `} id="" name='zip' onChange={this.setValue.bind(this)}

                                                                                onBlur={(e) => { this.autoFillAddress(e, "zip") }}
                                                                                style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label style={{ fontSize: fontSize.billinglabel + 'px' }}>{language.language.City[0][lang]}</label>
                                                                            <input title={language.language.City[0][lang]}
                                                                                type="text" 
                                                                                readOnly={this.state.setBilling}
                                                                                value={this.state.billingAddress.city}
                                                                                className={`form-control ${this.state.setBilling === true ? "non-editable" : ""}
                                                                    ${validBillingAddressObj.city === '' && this.state.isFieldEmpty ? 'red' : ''}
                                                                    `}
                                                                                id="" name='city'
                                                                                onChange={this.setValue.bind(this)}
                                                                                maxLength={20}
                                                                                style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                                />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label style={{ fontSize: fontSize.billinglabel + 'px' }}>{language.language.State[0][lang]}</label>
                                                                              <input title={language.language.State[0][lang]}
                                                                                type="text"
                                                                                readOnly={this.state.setBilling}
                                                                                value={this.state.billingAddress.state}
                                                                                className={`form-control ${this.state.setBilling === true ? "non-editable" : ""}
                                                                    ${validBillingAddressObj.state === '' && this.state.isFieldEmpty ? 'red' : ''}
                                                                    `}
                                                                                id=""
                                                                                name='state'
                                                                                onChange={this.setValue.bind(this)}
                                                                                maxLength={20}
                                                                                style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label style={{ fontSize: fontSize.billinglabel + 'px' }}>{language.language.Country[0][lang]}</label>
                                                                            <input title={language.language.Country[0][lang]}
                                                                                type="text"
                                                                                readOnly={this.state.setBilling}
                                                                                value={this.state.billingAddress.country}
                                                                                className={`form-control ${this.state.setBilling === true ? "non-editable" : ""}
                                                                    ${validBillingAddressObj.country === '' && this.state.isFieldEmpty ? 'red' : ''}
                                                                    `}
                                                                                id="country" name='country'
                                                                                onChange={this.setValue.bind(this)}
                                                                                onKeyPress={(e) => this.submitOnEnter(e)}
                                                                                maxLength={40}
                                                                                style={{ fontSize: fontSize.paymentlabel + 'px' }}
                                                                            />
                                                                        </div>

                                                                    </fieldset>
                                                                </form>
                                                                {
                                                    this.state.flashMessageError !== "" ?
                                                        <div className="alert alert-success alert_succesfull adpadding30 clearfix pull-left col-md-7" role="alert">
                                                            <span><img src={errorimg} alt="errorimg" title="errorimg" /></span>
                                                            <p className="clearfix">{flashErrorMessageSpan}</p>
                                                        </div> : ''
                                                }
                                                    {this.state.flashMessageSuccess !== '' ?
                                                    <div className="alert alert-success alert_succesfull clearfix pull-left col-md-8" role="alert">
                                                        <div className="successbtn"><img src={successimg} alt="successimg" title="successimg" /></div>
                                                        <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                                                    </div>
                                                    : ''
                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <div></div>}
                                            
                                               
                                            <div className="form-group col-md-5">
                                                    <button type="button"
                                                        className="giftcard_button"
                                                        id="createOrder"
                                                        onClick={this.createOrder.bind(this)}
                                                        style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                                        >{language.language.Make_Payment[0][lang]}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="shipping-order-summary paymentSideBar boxshadow30">
                                        <div className="order_summary_section">
                                            <h3 style={{ fontSize: fontSize.orderSummary + 'px' }} >{language.language.ORDER_SUMMARY[0][lang]}</h3>
                                            <div className="order_br"></div>
                                        </div>
                                        <div className="paymentSideBarScrolling">
                                            <div className="shipping-order-aside shippingorderScrolling payment_right all_scroll_section">
                                                {
                                                    this.state.cardListDetails.length ? this.state.cardListDetails.map((cardDetails,i) => {
                                                        return <div className="shipping-order-aside" key={`shippingorderScrolling${i}`}>
                                                            <div className="media">
                                                                <div className="media-left aside-img">
                                                                    <img  title={cardDetails.typeName + "  $" + cardDetails.price} alt={cardDetails.typeName + "  $" + cardDetails.price}  src={cardDetails.imagePath == "" ? noImage : constant.APIURL + cardDetails.imagePath + cardDetails.imageName} />
                                                                </div>
                                                                <div className="media-body payment_list">
                                                                    <h4 style={{ fontSize: fontSize.asideText + 'px' }} className="aside-heading">$ {HelperUtil._getAmountCommaSeparator(cardDetails.price.toFixed(2))}</h4>
                                                                    <p style={{ fontSize: fontSize.asideTextPara + 'px' }}>{cardDetails.typeName} </p>
                                                                    <p style={{ fontSize: fontSize.asideTextPara + 'px' }}>Qty :{cardDetails.productQuantity}  </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }) : ''
                                                }
                                            </div>
                                            <div className="shipping-order-total-summary payment_right">
                                                <div className="shipping-order-subtotal ">
                                                    <p style={{ fontSize: fontSize.subTotalAmmount + 'px' }} className="shipping-subtotal">{language.language.subTotal[0][lang]}</p>
                                                    <p style={{ fontSize: fontSize.subTotalAmmount + 'px' }} className="shipping-total">$ {HelperUtil._getAmountCommaSeparator(this.props.cardListDetails.subTotal.toFixed(2)).toString()}</p>
                                                </div>
                                                <div className="shipping-order-subtotal subtotal_shipping">
                                                    <p style={{ fontSize: fontSize.subTotalAmmount + 'px' }} className="shipping-subtotal">{language.language.Only_Discount[0][lang]} ({
                                                        this.state.discountDetail.discountId === 0 ?
                                                            this.state.cardListDetails.discountPercent :
                                                            this.state.discountDetail.discountPercent
                                                    }%) </p>
                                                    <p style={{ fontSize: fontSize.subTotalAmmount + 'px' }} className="shipping-total">- $ {HelperUtil._getAmountCommaSeparator(
                                                        this.state.discountDetail.discountId === 0 ?
                                                            this.state.cardListDetails.discountAmount.toFixed(2)
                                                            : this.state.discountDetail.discountAmount.toFixed(2)
                                                    ).toString()}</p>
                                                </div>
                                                <div className="shipping-order-subtotal subtotal_shipping">
                                                    <p style={{ fontSize: fontSize.subTotalAmmount + 'px' }} className="shipping-subtotal ">{language.language.Shipping_Charges[0][lang]} </p>
                                                    <p style={{ fontSize: fontSize.subTotalAmmount + 'px' }} className="shipping-total">+ $ {HelperUtil._getAmountCommaSeparator(shippingCharges.toFixed(2)).toString()}</p>
                                                </div>
                                            </div>
                                            <div className="shipping-order-total payment_right total_pay">
                                                <p style={{ fontSize: fontSize.orderSummary + 'px' }} className="shipping-total-text">{language.language.Total_Amount[0][lang]}</p>
                                                <p style={{ fontSize: fontSize.orderSummary + 'px' }} className="shipping-total-amount">$ {
                                                    HelperUtil._getAmountCommaSeparator(
                                                        this.state.discountDetail.discountId === 0 ?
                                                            this.state.cardListDetails.totalAmount.toFixed(2)
                                                            : this.state.discountDetail.totalAmount.toFixed(2)
                                                    ).toString()}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer>
                            <p>© 2019 Northgate Gonzalez Market®</p>
                        </footer>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        discountList: state.discountList,
        createOrder: state.createOrder,
        customerLoginResponse:state.customerLogin
    }
}

export default connect(mapStateToProps, { discountListAction, buyGiftCardAction })(PaymentPage);