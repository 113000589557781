import React, { Component } from 'react';
import { connect } from 'react-redux';
import { denominationListAction } from '../../../Actions/Admin/denominationManagementAction';
import * as constant from '../../../Constants';
import { addToCartAction } from '../../../Actions/Customer/GiftCardAction';
import Header from '../Layout/Header';
import * as HelperUtil from "../../../utils/common-helper";
import noImage from '../../../public/img/cardListNoImage.png';
import * as language from '../../../Constants/translations';
import InputMask from 'react-input-mask';
import { discountListAction } from '../../../Actions/Admin/discountManagementAction';
import moment from "moment"
import Slider from 'react-slick'
import { number } from 'prop-types';
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert';

// Class for view cart page displaying the slected card list 
class orderDetailsManagements extends Component {

    constructor() {
        super();
        this.state = {
            denominationList: '',
            cartProductObjectList: '',
            lang: sessionStorage.language != undefined ? sessionStorage.language : 'en',
            cartProductListIndex: [],
            discountList: [],
            fontSize: {
                mainCost: 24,
                itemPriceCart: 15,
                mediaheading: 12,
                breadcrumb: 12,
                giftCardText: 22,
                costSectionPrice: 20,
                tabs: 14,
                subTotal: 14,
                discountText: 13,

            },
            increaseCounter: 0,
            decreaseCounter: 0,
            increasemove: 4,
            decreasemove: 4,
            currentDate: moment(new Date()).format('MM/DD/YYYY'),
            // currentDate : moment(new Date()).add(2, 'day').format('MM/DD/YYYY') //for date testing
            activeDiscount: [],
            isClearCart :true,
            lastData:'',
            lastProdcuctQty:1
        }
        this.breadCrumbCLick = this.breadCrumbCLick.bind(this)
    };

    componentWillMount() {
        this.props.discountListAction(sessionStorage.userID)
        var denominationList = this.state.denominationList;
        this.setState({
            denominationList: [],
        })
        if (denominationList) {
            this.setState({
                denominationList: denominationList
            })
        }
        if (this.state.denominationList) {
            this.setState({ denominationList: this.state.denominationList })
        }
        else {
            this.setState({ denominationList: this.props.cardListDetails })
            sessionStorage.productObjectSession = this.props.cardListDetails
        }
        if (localStorage.getItem("productCardDetailsData") !== null) {
            this.setState({ denominationList: JSON.parse(localStorage.getItem("productCardDetailsData")) })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ Loding: false })
        try {
            if (nextProps.discountList != null && nextProps.discountList.data != null) {
                let discountList = nextProps.discountList.data.data.data
                let list = []
                let activeDiscount = []
                discountList.length ? discountList.map((discount) => {
                    let a, b
                    if (discount != null && discount.validFrom != null && discount.validFrom != null) {
                        a = moment(discount.validFrom).format('MM/DD/YYYY')
                        b = moment(discount.validTill).format('MM/DD/YYYY')
                        new Date(a) <= new Date(this.state.currentDate) ? new Date(this.state.currentDate) <= new Date(b) ? list.push(discount) : void 0 : void 0
                    }
                }) : void 0
               
                discountList = list
                if (discountList != null && discountList != '') {
                    if (discountList.length) {
                        discountList.map((list) => {
                            if (list.status == true) {
                                activeDiscount.push(list)
                            }
                        })
                    }
                }
                this.setState({
                    discountList,
                    activeDiscount
                })
            }
        } catch (err) {
        }

    }

    // Method for logout modal
    logOut() {
        sessionStorage.loggedIn = '';
        sessionStorage.userID = ''
        sessionStorage.language = 'en'
        localStorage.removeItem('productCardDetailsLength');
        localStorage.removeItem('productCardDetailsData');
        sessionStorage.removeItem('isVerifyAddress')
        this.props.history.push("/");
    }

    //Method to set the gift card product quantity 
    setProductQuantity(event, productData, index) {
        let cartProductData = this.state.denominationList;
       
        if (cartProductData.length > 0) {
            if (event.type == 'blur' && (!event.target.value || event.target.value == '0')) {
               
                if (this.state.denominationList.length === 1 && (event.target.value==0 || event.target.value == '' && event.type))
                    if(this.clearCheckOut()) {
                        event.target.value = '0'
                        let updateProductQuantity = event.target.value ? +event.target.value : 0;
                        let updateProductQuantityPrice = productData.price * updateProductQuantity;
                        var insertProductQuantityPrice = { productQuantity: updateProductQuantity, productQuantityPrice: updateProductQuantityPrice };
                        Object.assign(productData, insertProductQuantityPrice);
                        Object.assign(cartProductData, productData);
                        this.setState({ cartProductObjectList: cartProductData, sideBarPanelCartDetails: true })
                        this.deleteCartProductIndex(productData)
                        this.setState({isClearCart : false });
                        return
                }  
                cartProductData = cartProductData.splice(index, 1);
                this.deleteCartProductIndex(productData)
                Object.assign(cartProductData, productData);
                this.setState({ cartProductObjectList: cartProductData, sideBarPanelCartDetails: true })
                // if (this.state.denominationList.length === 0) {
                //     this.clearCheckOut()
                // }
            }
            else {
                
                let xDenominationObj = this.state.denominationList.productQuantity
                if(this.state.denominationList.length===1 &&  this.state.denominationList.productQuantity>=1) {
                
                   this.setState({
                    lastProdcuctQty:xDenominationObj
                   })
                }
                if(this.state.denominationList.length === 1 && this.state.cartProductObjectList[0]!==undefined  && this.state.cartProductObjectList[0].productQuantity!== ""     )    {
                    this.setState({
                        lastData: this.state.cartProductObjectList
                    })
                }
               
                let updateProductQuantity = event.target.value ? parseInt(event.target.value) : '';
                let updateProductQuantityPrice = productData.price * updateProductQuantity;
                var insertProductQuantityPrice = { productQuantity: updateProductQuantity, productQuantityPrice: updateProductQuantityPrice };
                Object.assign(productData, insertProductQuantityPrice);
                Object.assign(cartProductData, productData);
                this.setState({ cartProductObjectList: cartProductData, sideBarPanelCartDetails: true })
                this.deleteCartProductIndex(productData)
            }
        }
        localStorage.setItem("productCardDetailsData", JSON.stringify(this.state.denominationList))
    }

    /**
     * Function for increment gift card product in cart
     * @param {object} productData object of product
     * @return object
     */
    incrementGiftCard(productData) {
        let cartProductData = this.state.denominationList;
        if (cartProductData.length > 0) {
            let updateProductQuantity = Number(productData.productQuantity) + 1;
            let updateProductQuantityPrice = productData.price * updateProductQuantity;
            var insertProductQuantityPrice = { productQuantity: updateProductQuantity, productQuantityPrice: updateProductQuantityPrice };
            Object.assign(productData, insertProductQuantityPrice);
            Object.assign(cartProductData, productData);
            this.setState({ denominationList: cartProductData, sideBarPanelCartDetails: true })
        }
        localStorage.setItem("productCardDetailsData", JSON.stringify(this.state.denominationList))
    }

    /**
     * Function for decrement and remove gift card product in cart
     * @param {object} productData, object of product,  arrayIndex here array index use for   *  identity index of     
     *  array for remove in to product array.
     * @return object
     */
    decrementGiftCard(productData, arrayIndex) {
        let cartQuantity = this.state.denominationList;
        
        if (cartQuantity.length > 0) {
            if (productData.productQuantity >= 2) {
                let updateQuantity = productData.productQuantity - 1;
                let updateQuantityPrice = productData.price * updateQuantity;
                var insertProductQuantityPrice = { productQuantity: updateQuantity, productQuantityPrice: updateQuantityPrice };
                Object.assign(productData, insertProductQuantityPrice);
                Object.assign(cartQuantity, productData);
                this.setState({ denominationList: cartQuantity, sideBarPanelCartDetails: true })
            } else if (this.state.denominationList.length === 1 && productData.productQuantity === 1) {
                this.setState({lastProdcuctQty:1})
                this.clearCheckOut()

            }
            else {
                cartQuantity.splice(arrayIndex, 1);
                Object.assign(cartQuantity, productData);
                this.setState({ denominationList: cartQuantity, sideBarPanelCartDetails: true })
            }
            // if (this.state.denominationList.length > 1) {
            //     cartQuantity.splice(arrayIndex, 1);
            //     this.setState({ denominationList: cartQuantity, sideBarPanelCartDetails: true })
            //     Object.assign(cartQuantity, productData);
            //     localStorage.setItem('productCardDetailsLength', this.state.denominationList.length);
            //     localStorage.setItem("productCardDetailsData", JSON.stringify(this.state.denominationList))
            // }

            // }
        }
        localStorage.setItem("productCardDetailsData", JSON.stringify(this.state.denominationList))

    }

    //Method to delete the gift card from the list  
    deleteCartProductIndex(product) {
        var variantId = product.variantId
        var denominationList = this.state.denominationList;
        var index = denominationList.indexOf(variantId);
        if (index > -1) {
            denominationList.splice(index, 1);
        }
    }

    /**
     * Function for remove product cart in close button
     * @param {object} object of product data
     * @return {object}
     */
    removeProductListFromArray(productData, arrayIndex) {
        let cartQuantity = this.state.denominationList;
        if (cartQuantity.length > 1) {
            cartQuantity.splice(arrayIndex, 1);
            Object.assign(cartQuantity, productData);
            this.setState({ denominationList: cartQuantity })
            localStorage.setItem('productCardDetailsLength', this.state.denominationList.length);
            localStorage.setItem("productCardDetailsData", JSON.stringify(this.state.denominationList))
        }

        else if (this.state.denominationList.length === 1) {
           
            this.setState({lastProdcuctQty:this.state.denominationList[0].productQuantity})
            this.clearCheckOut()
        }
    }

    
    //Method to clear the view cart list on clicking clear cart
    clearCheckOut() {
       let self = this
       let result =  true 
        const options  = {
          
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm-popup AddUserModelform">
                        <div className="modal-header modal-header giftcart_title_header"><h3 className="modal-title giftcart_all_header"> {language.language.clear_cart_confirm[0][this.state.lang]}</h3></div>
                        <div className="modal-body">
                            <p>{language.language.confirm_message[0][this.state.lang]}</p>
                            <div className="formbtn">
                                <button type="button" className="giftcard_button mgr10"
                                    onClick={() => {
                                        localStorage.removeItem("productCardDetailsLength")
                                        localStorage.removeItem("productCardDetailsData")
                                        localStorage.removeItem('denominationPriceData')
                                        

                                        if (this.state.denominationList.length > 0) {
                                            this.setState({ denominationList: [] })
                                            if (this.state.denominationList.length == 0 || this.state.denominationList.length == "") {
                                                this.redirectToBack()
                                            }
                                        }
                                        try {
                                            if (this.props.cardListProps !== undefined) {
                                                this.props.cardListProps.history.push({
                                                    pathname: '/ordermanagement',
                                                })
                                            } else {
                                                this.props.history.push({
                                                    pathname: '/ordermanagement',
                                                })
                                            }
                                        }
                                        catch (er) { }
                                        onClose();
                                    }}
                                >
                                    {language.language.clear_cart_confirm[0][this.state.lang]}
                                </button>

                                <button className="giftcard_button cardnone_button" onClick={(e) => {
                                    result =true; 
                                    let objX = this.state.denominationList[0]
                                    let obj = {
                                        productQuantity:this.state.lastProdcuctQty,
                                        productQuantityPrice:this.state.lastProdcuctQty*objX.price
                                    }
                                    let lastProduct =    [Object.assign({...objX,...obj})]
                                   
                                    this.setState({isClearCart:false,denominationList:lastProduct}); 
                                    localStorage.setItem("productCardDetailsData", JSON.stringify(lastProduct))

                                     onClose() } }>No</button>

                                {/* <button type="button" onClick={onClose} className="giftcard_button cardnone_button">No</button> */}
                                
                                </div>
                        </div>
                    </div>
                );
            }
        }
        confirmAlert(options)
        return result
    }

    //Method to go to the buy gift card page on clicking on the add more button 
    redirectToBack(event) {
        // event.preventDefault()
        sessionStorage.cardType = 'all'
        if (this.props.cardListProps !== undefined) {
            this.props.cardListProps.history.push('/ordermanagement')
        } else {
            this.props.history.push({
                pathname: '/ordermanagement',
            })
        }
    }

    //Method to go on the next page on clicking on continue 
    redirectToShippmentPage() {
        try {
            let productrows = this.state.cartProductObjectList
            if (this.state.denominationList.length === '') {
                return;
            }
            if (this.state.denominationList.length === 0) {
                return;
            }
            if (this.props.history === undefined) {
                this.props.cardListProps.history.push({
                    pathname: '/ordermanagement',
                    state: {
                        data: this.state.denominationList,
                        pageType: 'shippmentPage',
                    }
                })
            }
            if (this.props !== undefined && this.props.history !== undefined) {
                this.props.history.push({
                    pathname: '/ordermanagement',
                    state: {
                        data: this.state.denominationList,
                        pageType: 'shippmentPage',
                    }
                })
            }
        } catch{ }
    }

    //Method to set the languagae 
    setLanguage(data) {
        this.setState({
            lang: data
        })
    }

    // sortAmount(){

    // }


    // Method to set the size of the page items
    changeSize(data) {
        if (data === 'increase') {
            let increaseCounter = this.state.increasemove
            let decreaseCounter = this.state.decreasemove
            if (increaseCounter > 0) {
                decreaseCounter = decreaseCounter + 1
                increaseCounter = increaseCounter - 1
                this.setState({
                    fontSize: {
                        itemPriceCart: Number(this.state.fontSize.itemPriceCart) + 1,
                        mediaheading: Number(this.state.fontSize.mediaheading) + 1,
                        breadcrumb: Number(this.state.fontSize.breadcrumb) + 1,
                        giftCardText: Number(this.state.fontSize.giftCardText) + 1,
                        costSectionPrice: Number(this.state.fontSize.costSectionPrice) + 1,
                        subTotal: Number(this.state.fontSize.subTotal) + 1,
                        mainCost: Number(this.state.fontSize.mainCost) + 1,
                        discountText: Number(this.state.fontSize.discountText) + 1,
                        tabs: Number(this.state.fontSize.tabs) + 1,
                    },
                    increasemove: increaseCounter,
                    decreasemove: decreaseCounter
                })
            }
        }
        else if (data === 'decrease') {
            let increaseCounter = this.state.increasemove
            let decreaseCounter = this.state.decreasemove
            if (decreaseCounter > 0) {
                decreaseCounter = decreaseCounter - 1
                increaseCounter = increaseCounter + 1
                this.setState({
                    fontSize: {
                        itemPriceCart: Number(this.state.fontSize.itemPriceCart) - 1,
                        mediaheading: Number(this.state.fontSize.mediaheading) - 1,
                        breadcrumb: Number(this.state.fontSize.breadcrumb) - 1,
                        giftCardText: Number(this.state.fontSize.giftCardText) - 1,
                        costSectionPrice: Number(this.state.fontSize.costSectionPrice) - 1,
                        subTotal: Number(this.state.fontSize.subTotal) - 1,
                        mainCost: Number(this.state.fontSize.mainCost) - 1,
                        discountText: Number(this.state.fontSize.discountText) - 1,
                        tabs: Number(this.state.fontSize.tabs) - 1,
                    },
                    increasemove: increaseCounter,
                    decreasemove: decreaseCounter
                })
            }
        }
        else if (data === 'default') {
            this.setState({
                fontSize: {
                    mainCost: 24,
                    subTotal: 14,
                    costSectionPrice: 20,
                    itemPriceCart: 15,
                    mediaheading: 12,
                    breadcrumb: 12,
                    giftCardText: 22,
                    tabs: 14,
                    discountText: 15
                },
                increasemove: 4,
                decreasemove: 4
            })
        }
    }

    /***
     * Method for breadCrumbCLick
     * @param {event} 
     * @return {undefined}
     */
    breadCrumbCLick(event) {
        if (sessionStorage.hasOwnProperty("emailID") && sessionStorage.emailID !== '') {
            if (this.props.cardListProps !== undefined) {
                this.props.cardListProps.history.push("/ordermanagement")
            } else if (this.props.history !== undefined) {
                this.props.history.push("/ordermanagement")
            }
        } else {
            if (this.props.cardListProps !== undefined) {
                this.props.cardListProps.history.push("/")
            } else if (this.props.history !== undefined) {
                this.props.history.push("/")
            }
        }
    }

    /***
     * Method for breadCrumbCLick giftCard
     * @param {event} 
     * @return {undefined}
     */
    redirectGiftCard() {
        if (this.props.cardListProps !== undefined) {
            this.props.cardListProps.history.push("/ordermanagement")
        } else if (this.props.history !== undefined) {
            this.props.history.push("/ordermanagement")
        }
    }

    render() {
        
        if (sessionStorage.userID != 0 && sessionStorage.loggedIn === '') {
            this.props.cardListProps.history.push({
                pathname: '/'
            });
        }
        let denominationsData = [];
        if (this.state.denominationList != undefined && this.state.denominationList.length > 0) {
            denominationsData = this.state.denominationList;
        }
        let productTotalPrice;
        if (productTotalPrice) {
            productTotalPrice = productTotalPrice;
        } else {
            productTotalPrice = 0;
        }
        var lang = this.state.lang
        let { discountList } = this.state
        let { activeDiscount } = this.state
        let cardDisount = []
        let achDiscount = []
        let minQty = 0
        if(this.state.denominationList!==undefined && Array.isArray(this.state.denominationList)) {
                this.state.denominationList.map(list=>{ minQty += list.productQuantity})
                activeDiscount.length && activeDiscount !== null ? activeDiscount.map((discount) => {
                    discount.paymentType === "Card" ? cardDisount.push(discount) : achDiscount.push(discount)
                }) : void 0
        
        }
       
        let cardSort
        if (cardDisount.length) {
            cardSort = _.sortBy(cardDisount, (discount) => {
                return discount.amountFrom
            })
            // cardSort = cardDisount.sort((a,b)=> a.amountFrom < b.amountFrom  ? a : b)
        }
    

        let achSort
        if (achDiscount.length) {
            achSort = _.sortBy(achDiscount, (discount) => {
                return discount.amountFrom
            })
        }

        const { fontSize } = this.state
        const settings = {
            infinite: true,
            slidesToShow: 4,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 4000,
            cssEase: "linear",
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1
                    }
                }
            ]
        };
        let sliderEnable = activeDiscount.length && activeDiscount != null && activeDiscount != '' ? activeDiscount.length > 4 ? true : false : false
        let discountString = sessionStorage.language === "spa" ? "o mas" : "and above"
        return (
            <div id="mya ccount">
                <div className="wrapper page customerHome" >
                    <Header language={this.setLanguage.bind(this)} cardListProps={this.props.cardListProps} changeSize={this.changeSize.bind(this)} locationProps={this.props} />
                    <section id="main" className="order-details">
                        <div className="container-fluid">
                            <div className="row">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item ">
                                        <a style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                            href="javascript:void(0);"
                                            onClick={this.breadCrumbCLick.bind()}
                                            className="active">{language.language.home[0][lang]}</a></li>
                                    {sessionStorage.emailID === '' || sessionStorage.emailID == undefined ?
                                        <li className="breadcrumb-item">
                                            <a style={{ fontSize: fontSize.breadcrumb + 'px' }} href="javascript:void(0);"
                                                onClick={(e) => this.redirectGiftCard(this)}
                                                className="active">{language.language.gift_cards[0][lang]}</a></li>
                                        : ''}
                                    <li className="breadcrumb-item">
                                        <a style={{ fontSize: fontSize.breadcrumb + 'px' }} >{language.language.view_cart[0][lang]}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="account-section">
                                        <h3 style={{ fontSize: fontSize.giftCardText + 'px' }}>{language.language.view_cart[0][lang]}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className=" order-main-section boxshadow30">
                                <div className="orderDetailscrollbar">
                                    {
                                        Array.isArray(denominationsData) && denominationsData.length > 0 ? denominationsData.map((key, index) => {
                                            productTotalPrice = productTotalPrice + key.productQuantityPrice;
                                            return <div className="row" key={`row_ordMa${index}`}>
                                                <div className="col-md-6">
                                                    <div className="media order-section order_viewcart_addcart">
                                                        <div className="media-left">
                                                            <img alt={key.typeName} title={key.typeName} src={key.imagePath == "" ? noImage : constant.APIURL + key.imagePath + key.imageName} />
                                                        </div>
                                                        <div className="media-body view-cart-section">
                                                            <h4 style={{ fontSize: fontSize.mediaheading + 'px' }} className="media-heading">{key.typeName}</h4>
                                                            <span style={{ fontSize: fontSize.itemPriceCart + 'px' }} className="item-price-cart">$ {HelperUtil._getAmountCommaSeparator(key.price.toFixed(2))} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-section">
                                                                <div className="incrementPrice incrementOrder">
                                                                    <div className="value-button" id="" value="Decrease Value">
                                                                        <button className={`minus-icon   ${(key.productQuantity > 0) ? 'minusRed' : ''}`} disabled={(key.productQuantity > 0) ? '' : 'disabled'} onClick={(e) => this.decrementGiftCard(key, index)}></button>
                                                                    </div>
                                                                    <InputMask 
                                                                        id="number" mask="999" 
                                                                        maskChar={null} value={key.productQuantity} 
                                                                        onChange={(e) => this.setProductQuantity(e, key, index)} 
                                                                        onBlur={(e) => this.setProductQuantity(e, key, index)} 
                                                                        style={{ fontSize: fontSize.tabs + 'px' }}
                                                                            />
                                                                    <div className="value-button" id="" value="Increase Value">
                                                                        <button  className={`pluse-icon pull-right ${(key.productQuantity < 999) ? '' : 'plusGrey'}`} onClick={(e) => this.incrementGiftCard(key)} value="Increase Value" disabled={(key.productQuantity < 999) ? '' : 'disabled'} ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="cost-section">
                                                                <p style={{ fontSize: fontSize.costSectionPrice + 'px' }} >$ {HelperUtil._getAmountCommaSeparator(key.productQuantityPrice.toFixed(2)).toString()}</p>
                                                                <button type="button" className="close cart" onBlur={(e) => this.deleteCartProductIndex(e, key, index)} onClick={(e) => this.removeProductListFromArray(key, index)}>&times;</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }) :
                                            <div className="norecordfound">{language.language.no_items_found[0][lang]}</div>
                                    }
                                </div>

                                <div className="row">
                                    <hr />
                                    <div className="col-md-6">
                                        <div className="media order-section">
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="Sub-total">
                                                    <p style={{ fontSize: fontSize.subTotal + 'px' }} className="sub-total">{language.language.sub_total[0][lang]}</p>
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="main-cost">
                                                    <p style={{ fontSize: fontSize.mainCost + 'px' }}>$ {HelperUtil._getAmountCommaSeparator(productTotalPrice.toFixed(2)).toString()}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row onlyfortab">
                                    <hr />

                                    {/* Discount Tabel */}
                                    <div className="col-md-6 pt-0">
                                        {activeDiscount.length && activeDiscount != null && activeDiscount != '' ?

                                            <div className="discount-pay">
                                                <h3 style={{ fontSize: fontSize.giftCardText + 'px' }}>{language.language.Gift_CARD_ToolTip_Discount[0][lang]}</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: fontSize.discountText + 'px' }}>{language.language.Payment_Type[0][lang]}</th>
                                                            <th style={{ fontSize: fontSize.discountText + 'px' }}>{language.language.Purchase_Amount[0][lang]}</th>
                                                            <th style={{ fontSize: fontSize.discountText + 'px' }}>{language.language.Gift_card_discount[0][lang]} <i className="fa fa-info-circle question_mark" title={language.language.Gift_CARD_ToolTip_Discount[0][lang]}></i></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {cardSort != undefined && cardSort.length > 0 ? cardSort.map((discount, index) => {
                                                            return (<tr key={`discount_cardSort${index}`}>
                                                                {index === 0 ? <td style={{ fontSize: fontSize.tabs + 'px' }}> <span className="fa fa-credit-card"></span> {language.language.CARD_Discount[0][lang]}</td> : <td></td>}
                                                                <td style={{ fontSize: fontSize.tabs + 'px' }}>${HelperUtil._getAmountCommaSeparator(discount.amountFrom).toString()} {discount.amountTo === 0 ? discountString : "- $" + HelperUtil._getAmountCommaSeparator(discount.amountTo).toString()}</td>
                                                                <td style={{ fontSize: fontSize.tabs + 'px' }}>{discount.percent}%</td>
                                                            </tr>)
                                                        })
                                                            : <tr></tr>}
                                                        {cardDisount != undefined && cardDisount.length && achDiscount != undefined && achDiscount.length ?
                                                            <tr >
                                                                <td style={{ borderBottom: '2px solid #a1a1a1', padding: '0' }}></td>
                                                                <td style={{ borderBottom: '2px solid #a1a1a1', padding: '0' }}></td>
                                                                <td style={{ borderBottom: '2px solid #a1a1a1', padding: '0' }}></td>
                                                            </tr> :
                                                            <tr></tr>}
                                                        {achSort != undefined && achSort.length > 0 ? achSort.map((discount, index) => {
                                                            return (<tr className="" key={`achSort${index}`}>
                                                                {index === 0 ? <td style={{ fontSize: fontSize.tabs + 'px' }}> <span className="fa fa-university"></span> {language.language.ACH_Discount[0][lang]}</td> : <td></td>}
                                                                <td style={{ fontSize: fontSize.tabs + 'px' }}>${HelperUtil._getAmountCommaSeparator(discount.amountFrom).toString()} {discount.amountTo === 0 ? discountString : "- $" + HelperUtil._getAmountCommaSeparator(discount.amountTo).toString()}</td>
                                                                <td style={{ fontSize: fontSize.tabs + 'px' }}>{discount.percent}%</td>
                                                            </tr>)
                                                        })
                                                            : <tr></tr>}

                                                    </tbody>


                                                </table>

                                            </div>
                                            : ''}

                                    </div>
                                    {/* Discout Table End */}





                                    <div className="col-md-6 order-btn-section adnewbtn text-right clearfix">

                                        {/* <ul className="get-discount-viewcart">
                                    
                                {activeDiscount.length && activeDiscount != null && activeDiscount != '' ? activeDiscount.map((list)=>{
                                    
                                    return <li style={{ fontSize: fontSize.subTotal + 'px' }}>Get {list.percent}% discount on amount ${list.amountFrom}{list.amountTo == 0 ? " and above" : `- $${list.amountTo}`} on {list.paymentType} Payment</li>
                                    
                                }) : ''
                            }
                            </ul> */}

                                        <button
                                            style={{ fontSize: fontSize.discountText + 'px' }}
                                            className="giftcard_button cardnone_button mgr10 mrbt10" onClick={this.redirectToBack.bind(this)}>{language.language.add_more[0][lang]}</button>
                                        <button 

                                        style={{ fontSize: fontSize.discountText + 'px' }}
                                         className={this.state.denominationList != undefined && this.state.denominationList.length ? "giftcard_button cardnone_button mgr10 mrbt10" : "giftcard_button cardnone_button mgr10 mrbt10 disableEvent"} onClick={this.clearCheckOut.bind(this)}>{language.language.clear_cart[0][lang]}</button>
                                    
                                       
                                       
                                        <button

                                            style={{ fontSize: fontSize.discountText + 'px' }}
                                            className={((this.state.denominationList != undefined && this.state.denominationList.length) && ((!sessionStorage.emailID && productTotalPrice <= 3000) || (sessionStorage.emailID)))  ? `giftcard_button cardnone_button mgr10 mrbt10 ${minQty===0?'disableEvent' :''}` : "giftcard_button cardnone_button mgr10 mrbt10 disableEvent"} onClick={this.redirectToShippmentPage.bind(this)}>{language.language.checkout[0][lang]}</button>

                                        {!sessionStorage.emailID ? (this.state.denominationList != undefined && (this.state.denominationList.length || !this.state.denominationList.length) && productTotalPrice <= 3000)
                                            ?
                                            (
                                                <div className="form-group note-section">
                                                    <label className="noteLabel" style={{ fontSize: fontSize.breadcrumb + 'px' }}   >{language.language.Note[0][lang]}- </label>
                                                    <ul className="card-notes-menu">
                                                        <li  style={{ fontSize: fontSize.breadcrumb + 'px' }} >{language.language.giift_card_price_limit[0][lang]}</li>
                                                        <li  style={{ fontSize: fontSize.breadcrumb + 'px' }} >{language.language.gift_card_discount_info[0][lang]}</li>
                                                    </ul>
                                                </div>
                                            )
                                            :
                                            <div className="form-group note-section">
                                                <label className="noteLabel" style={{ fontSize: fontSize.breadcrumb + 'px' }}>{language.language.Note[0][lang]} </label>
                                                <ul className="card-notes-menu">
                                                    <li style={{ color: 'red',fontSize: fontSize.breadcrumb + 'px' }}>{language.language.giift_card_price_limit[0][lang]}</li>
                                                    <li style={{ fontSize: fontSize.breadcrumb + 'px' }}>{language.language.gift_card_discount_info[0][lang]}</li>
                                                </ul>
                                            </div>
                                            : <label className="noteplus30 p-0 giftcard_note" style={{ color: 'red',fontSize: fontSize.breadcrumb + 'px' }} ><b>{language.language.Note[0][lang]}</b> {language.language.gift_card_discount_info[0][lang]}</label>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* //(this.state.denominationList != undefined && this.state.denominationList.length) */}
                        {/* slider for discount */}
                        {/* <div>
                                {!sliderEnable ?
                                    <div className="d-flex justify-content-center bd-highlight row">
                                        {activeDiscount.length && activeDiscount != null && activeDiscount != '' ? activeDiscount.map((list, index) => {
                                            return index < 4 ? <div className="col-md-3" style={{ display: "inlineBlock" }}>
                                                <div className="discount_card">
                                                    <div className="discount_number float-left">
                                                        <h4>{list.percent}%</h4>
                                                        <span>Discount</span>
                                                    </div>
                                                    <div className="discount_details">
                                                        <p>on amount</p>
                                                        <span>$ {HelperUtil._getAmountCommaSeparator(list.amountFrom.toFixed(2)).toString()} - {list.amountTo == 0 ? "+" : "$" +  HelperUtil._getAmountCommaSeparator(list.amountTo.toFixed(2)).toString() }</span>
                                                        <p>on {list.paymentType} Payment</p>
                                                    </div>
                                                </div>
                                            </div>
                                                : ''
                                        }) : ''
                                        }
                                    </div>
                                    :
                                    <Slider {...settings}>
                                        {activeDiscount.length && activeDiscount != null && activeDiscount != '' ? activeDiscount.map((list) => {
                                            return <div className="row">
                                                <div className="col-md-12">
                                                    <div className="discount_card">
                                                        <div className="discount_number float-left">
                                                            <h4>{list.percent}%</h4>
                                                            <span>Discount</span>
                                                        </div>
                                                        <div className="discount_details">
                                                            <p>on amount</p>
                                                            <span>$ {HelperUtil._getAmountCommaSeparator(list.amountFrom.toFixed(2)).toString()} - $ {HelperUtil._getAmountCommaSeparator(list.amountTo.toFixed(2)).toString()}</span>
                                                            <p>on {list.paymentType} Payment</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }) : ''
                                        }
                                    </Slider>
                                }
                            </div> */}


                        <footer>
                            <p>© 2019 Northgate Gonzalez Market®</p>
                        </footer>
                    </section>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        denominations: state.denominationList,
        productListData: state.addToCart,
        discountList: state.discountList
    };
}

export default connect(mapStateToProps, { denominationListAction, addToCartAction, discountListAction })(orderDetailsManagements);