var HelperUtil = require('../../src/utils/common-helper')
/*
* Constants of application
*/
export const PARENT_ID = 5;
export const ACTIVE = 1;
export const LOGGED_IN_USER = 26;
export const ADMIN_USER = 3;
export const FULLFILMENT_USER = 4;
export const OPTION_CATEGORY_ID = 133785;
export const NORTHGATE_URL="https://www.northgatemarket.com/"
export const ENCRYPT_DECRYPT_KEY = 'payemtpimcorekey'
//export const BASE_URL = 'http://giftcardadminuat.pimcoreclients.com';
//export const BASE_URL = 'http://giftcard.pimcoreclients.com:3000';

//API URL for local
//   export const API_AUTH = '37285388972917cc2126fae0b3c512d3214240539488397114c13294ef1f078a';
//  export const APIURL = 'http://giftcard.local';

//API for stage local

// export const API_AUTH = 'ee4a0795071666a07bad1bcd47b985927c474c38042b31ab4879bcf30bd53680'
// export const APIURL = "http://stage.gift-card.local"

//API URL for Productioin
export const API_AUTH = '37adf80d7f583d8c55fa11f3171e45b9ded35a011a32e0b00447a8d074ebcda4';
export const APIURL = 'https://pimadmin.northgategiftcard.com';  

/** Admin panel login url for user */
export const URL = window.location.href.substring(window.location.href.lastIndexOf('?'), 0)

/* Admin login */
export const LOGIN_DATA = "LOGIN_DATA"
export const LOGIN_DATA_ERROR = "LOGIN_DATA_ERROR"

/* User management module */
export const USER_MANAGEMENT_LIST_DATA = "USER_MANAGEMENT_LIST_DATA"
export const USER_MANAGEMENT_LIST_DATA_ERROR = "USER_MANAGEMENT_LIST_DATA_ERROR"
export const USER_MANAGEMENT_ADD_RESPONSE = "USER_MANAGEMENT_ADD_RESPONSE"
export const USER_MANAGEMENT_ADD_RESPONSE_ERROR = "USER_MANAGEMENT_ADD_RESPONSE_ERROR"

export const USER_MANAGEMENT_USER_STATUS_DATA_RESPONSE = "USER_MANAGEMENT_USER_STATUS_DATA_RESPONSE"
export const USER_MANAGEMENT_USER_STATUS_DATA_RESPONSE_ERROR = "USER_MANAGEMENT_USER_STATUS_DATA_RESPONSE_ERROR"

export const USER_MANAGEMENT_UPDATE_RESPONSE = "USER_MANAGEMENT_UPDATE_RESPONSE"
export const USER_MANAGEMENT_UPDATE_RESPONSE_ERROR = "USER_MANAGEMENT_UPDATE_RESPONSE_ERROR"


/* user roles */
export const USER_ROLES = "USER_ROLES"
export const USER_ROLES_ERROR = "USER_ROLES_ERROR"

/* Customer register module */

export const CUSTOMER_MANAGEMENT_LIST_DATA = "CUSTOMER_MANAGEMENT_LIST_DATA"
export const CUSTOMER_MANAGEMENT_LIST_DATA_ERROR = "CUSTOMER_MANAGEMENT_LIST_DATA_ERROR"

export const CUSTOMER_REGISTER_DATA = "CUSTOMER_REGISTER_DATA"
export const CUSTOMER_REGISTER_DATA_ERROR = "CUSTOMER_REGISTER_DATA_ERROR"

export const CUSTOMER_LOGIN_DATA = "CUSTOMER_LOGIN_DATA"
export const CUSTOMER_LOGIN_DATA_ERROR = "CUSTOMER_LOGIN_DATA_ERROR"

export const CUSTOMER_UNLOCK_ACCOUNT = "CUSTOMER_UNLOCK_ACCOUNT"
export const CUSTOMER_UNLOCK_ACCOUNT_ERROR = "CUSTOMER_UNLOCK_ACCOUNT_ERROR"

export const CUSTOMER_RESET_PASSWORD = "CUSTOMER_RESET_PASSWORD"
export const CUSTOMER_RESET_PASSWORD_ERROR = "CUSTOMER_RESET_PASSWORD_ERROR"

export const ADMIN_CHANGE_PASSWORD = "ADMIN_CHANGE_PASSWORD"
export const ADMIN_CHANGE_PASSWORD_ERROR = "ADMIN_CHANGE_PASSWORD_ERROR"

/* Customer profile*/
export const CUSTOMER_PROFILE_INFO = "CUSTOMER_PROFILE_INFO"
export const CUSTOMER_PROFILE_INFO_ERROR = "CUSTOMER_PROFILE_INFO_ERROR"

export const CUSTOMER_CHANGE_PASSWORD = "CUSTOMER_CHANGE_PASSWORD"
export const CUSTOMER_CHANGE_PASSWORD_ERROR = "CUSTOMER_CHANGE_PASSWORD_ERROR"

export const CUSTOMER_PROFILE_UPDATE = "CUSTOMER_PROFILE_UPDATE"
export const CUSTOMER_PROFILE_UPDATE_ERROR = "CUSTOMER_PROFILE_UPDATE_ERROR"
//for forget Password
export const CUSTOMER_PASSWORD_RESET = window.location.protocol + "//" + window.location.host
export const CUSTOMER_RESET_PASSWORD_DATA = "CUSTOMER_RESET_PASSWORD_DATA"
export const CUSTOMER_RESET_PASSWORD_DATA_ERROR = "CUSTOMER_RESET_PASSWORD_DATA_ERROR"

//discount management
export const DISCOUNT_MANAGEMENT_DATA = "DISCOUNT_MANAGEMENT_DATA"
export const DISCOUNT_MANAGEMENT_DATA_ERROR = "DISCOUNT_MANAGEMENT_DATA_ERROR"
export const ADD_DISCOUNT = "ADD_DISCOUNT"
export const ADD_DISCOUNT_ERROR = "ADD_DISCOUNT_ERROR"
export const EDIT_DISCOUNT = "EDIT_DISCOUNT"
export const EDIT_DISCOUNT_ERROR = "EDIT_DISCOUNT_ERROR"
export const DELETE_DISCOUNT = "DELETE_DISCOUNT"
export const DELETE_DISCOUNT_ERROR = "DELETE_DISCOUNT_ERROR"
export const PAYMENT_TYEPE_DISCOUNT = "PAYMENT_TYEPE_DISCOUNT"
export const PAYMENT_TYEPE_DISCOUNT_ERROR = "PAYMENT_TYEPE_DISCOUNT_ERROR"

//denomination management
export const CARD_TYPE_LIST = "CARD_TYPE_LIST"
export const CARD_TYPE_LIST_ERROR = "CARD_TYPE_LIST_ERROR"


//for Customer Activation
export const ACTIVATE_CUSTOMER_DATA = "ACTIVATE_CUSTOMER_DATA"
export const ACTIVATE_CUSTOMER_DATA_ERROR = "ACTIVATE_CUSTOMER_DATA_ERROR"
// for add customer Address 
export const CUSTOMER_ADDRESS_DATA = "CUSTOMER_ADDRESS_DATA"
export const CUSTOMER_ADDRESS_DATA_ERROR = "CUSTOMER_ADDRESS_DATA_ERROR"

//constant for customer update address
export const UPDATE_CUSTOMER_ADDRESS_DATA = "UPDATE_CUSTOMER_ADDRESS_DATA"
export const UPDATE_CUSTOMER_ADDRESS_DATA_ERROR = "UPDATE_CUSTOMER_ADDRESS_DATA_ERROR"

//constant for delelte customer Address
export const DELETE_CUSTOMER_ADDRESS_DATA = "DELETE_CUSTOMER_ADDRESS_DATA"
export const DELETE_CUSTOMER_ADDRESS_DATA_ERROR = "DELETE_CUSTOMER_ADDRESS_DATA_ERROR"

/** Update Address Status */
export const UPDATE_ADDRESS_STATUS_DATA = "UPDATE_ADDRESS_STATUS_DATA"
export const UPDATE_ADDRESS_STATUS_DATA_ERROR = "UPDATE_ADDRESS_STATUS_DATA_ERROR"



export const DENOMINATION_LIST = "DENOMINATION_LIST"
export const DENOMINATION_LIST_ERROR = "DENOMINATION_LIST_ERROR"

export const ADD_DENOMINATION = "ADD_DENOMINATION"
export const ADD_DENOMINATION_ERROR = "ADD_DENOMINATION_ERROR"

export const UPDATE_DENOMINATION = "UPDATE_DENOMINATION"
export const UPDATE_DENOMINATION_ERROR = "UPDATE_DENOMINATION_ERROR"

export const DELETE_DENOMINATION = "DELETE_DENOMINATION"
export const DELETE_DENOMINATION_ERROR = "DELETE_DENOMINATION_ERROR"

export const DELETE_DENOMINATION_IMAGE = "DELETE_DENOMINATION_IMAGE"
export const DELETE_DENOMINATION_IMAGE_ERROR = "DELETE_DENOMINATION_IMAGE_ERROR"

//shippment managements
export const SHIPPMENT_MANAGEMENT_DATA = "SHIPPMENT_MANAGEMENT_DATA"
export const SHIPPMENT_MANAGEMENT_DATA_ERROR = "SHIPPMENT_MANAGEMENT_DATA_ERROR"
export const ADD_SHIPPMENT = "ADD_SHIPPMENT"
export const REMOVE_SHIPPMENT = "REMOVE_SHIPPMENT"
export const ADD_SHIPPMENT_ERROR = "ADD_SHIPPMENT_ERROR"
export const EDIT_SHIPPMENT = "EDIT_SHIPPMENT"
export const EDIT_SHIPPMENT_ERROR = "EDIT_SHIPPMENT_ERROR"
export const DELETE_SHIPPMENT = "DELETE_SHIPPMENT"
export const DELETE_SHIPPMENT_ERROR = "DELETE_SHIPPMENT_ERROR"
export const SHIPPMENT_TYPE = "SHIPPMENT_TYPE"
export const SHIPPMENT_TYPE_ERROR = "SHIPPMENT_TYPE_ERROR"


/* Customer Management */
export const UPDATE_CUSTOMER_DETAILS = "UPDATE_CUSTOMER_DETAILS"
export const UPDATE_CUSTOMER_DETAILS_ERROR = "UPDATE_CUSTOMER_DETAILS_ERROR"

/*Validation error messages*/
export const EMAIL_NOT_FOUND = 'This Email Address is not registered with us.'
export const EMAIL_FOUND = 'This Email Address is already registered with us.'
export const INVALID_EMAIL = 'Invalid Email Address'
/* Gift card management */
/* Add to cart product */
export const ADD_TO_CART_PRODUCT = 'ADD_TO_CART_PRODUCT'
export const REMOVE_CART_PRODUCT = 'REMOVE_CART_PRODUCT'


/* Buy Gift card */
export const BUY_GIFT_CARD = 'BUY_GIFT_CARD'
export const BUY_GIFT_CARD_ERROR = 'BUY_GIFT_CARD_ERROR'

/* Order Management */
export const ORDER_LIST = 'ORDER_LIST'
export const ORDER_LIST_ERROR = 'ORDER_LIST_ERROR'
export const CUSTOMER_ORDER_LIST_DATA = 'CUSTOMER_ORDER_LIST_DATA'
export const CUSTOMER_ORDER_LIST_DATA_ERROR = 'CUSTOMER_ORDER_LIST_DATA_ERROR'


export const ORDER_DETAILS = 'ORDER_DETAILS'
export const ORDER_DETAILS_ERROR = 'ORDER_DETAILS_ERROR'
export const ACKNOWLEDGE_ORDER = 'ACKNOWLEDGE_ORDER'
export const ACKNOWLEDGE_ORDER_ERROR = 'ACKNOWLEDGE_ORDER_ERROR'
export const SEND_SHIPPING_DETAILS = 'SEND_SHIPPING_DETAILS'
export const SEND_SHIPPING_DETAILS_ERROR = 'SEND_SHIPPING_DETAILS_ERROR'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR'

/** Constant for idle time out */
//export const Idle = ReactIdle.default

/** Constant for session time out in application */

export const MINUTES_UNITL_AUTO_LOGOUT = 600 //10 Minutes
export const CHECK_INTERVAL = 1
export const STORE_KEY = 'lastAction'
export const SESSION_EXPIRE_MESSAGE = "Your session has been expired. Please login again."
//export const Idle = ReactIdle.default
//   wordlpay test apikey
export const WORLDPAY_API_URL = "https://api.worldpay.com/v1/orders"
export const WORLDPAY_MERCHANT_ID = "c1e03cd0-e376-43da-95fd-cce7b9012d65"
export const WORLDPAY_SERVICE_KEY = "T_S_95b399fd-e82d-48eb-9e25-d39f697b0b9d"
export const WORLDPAY_CLIENT_KEY = "T_C_658da769-abf9-4ced-ba5f-5d7e228de304"
export const AUTH = "Basic ODAwNTAyMzpwdVJsVGTMZmlVdkc="
export const HOST = "https://gwapi.demo.securenet.com"
export const WORLDPAY_HOST_CHARGE_PATH = "/api/Payments/Charge"


/* Gift card management */
export const GIFT_CARD_LIST = 'GIFT_CARD_LIST'
export const GIFT_CARD_LIST_ERROR = 'GIFT_CARD_LIST_ERROR'
export const ACTIVATE_GIFT_CARDS = 'ACTIVATE_GIFT_CARDS'
export const ACTIVATE_GIFT_CARDS_ERROR = 'ACTIVATE_GIFT_CARDS_ERROR'

/**Google map auto address fill API Credentials  */
export const GOOGLE_API = "https://maps.googleapis.com/maps/api/geocode/json"
export const GOOGLE_API_KEY = 'AIzaSyDER9UGqNwaA53veojfDPXo0ou3pO2WdOw'
/* Dashboard management*/
export const DASHBOARD_ORDER_LIST = 'DASHBOARD_ORDER_LIST'
export const DASHBOARD_ORDER_LIST_ERROR = 'DASHBOARD_ORDER_LIST_ERROR'
export const PENDING_ORDER_LIST = 'PENDING_ORDER_LIST'
export const PENDING_ORDER_LIST_ERROR = 'PENDING_ORDER_LIST_ERROR'
export const CANCELLED_ORDER_LIST = 'CANCELLED_ORDER_LIST'
export const CANCELLED_ORDER_LIST_ERROR = 'CANCELLED_ORDER_LIST_ERROR'
export const ACTIVATED_GIFTCARD_LIST = 'ACTIVATED_GIFTCARD_LIST'
export const ACTIVATED_GIFTCARD_LIST_ERROR = 'ACTIVATED_GIFTCARD_LIST_ERROR'
export const PENDING_GIFTCARD_LIST = 'PENDING_GIFTCARD_LIST'
export const PENDING_GIFTCARD_LIST_ERROR = 'PENDING_GIFTCARD_LIST_ERROR'

/* CIAM api */
export const SEARCH_USER = 'SEARCH_USER'
export const SEARCH_USER_ERROR = 'SEARCH_USER_ERROR'
export const SEND_OTP = 'SEND_OTP'
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR'
export const VERIFY_OTP = 'VERIFY_OTP'
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR'
export const OTP_VERIFY = 'OTP_VERIFY'
export const OTP_VERIFY_ERROR = 'OTP_VERIFY_ERROR'
export const UPDATE_ADDRESS_CIAM = "UPDATE_ADDRESS_CIAM"
export const UPDATE_ADDRESS_CIAM_ERROR = "UPDATE_ADDRESS_CIAM_ERROR"
export const SEARCH_USER_BY_ID = "SEARCH_USER_BY_ID"
export const SEARCH_USER_BY_ID_ERROR = "SEARCH_USER_BY_ID_ERROR"


 
/* Forgot password */
export const SEND_RESET_PWD_LINK = 'SEND_RESET_PWD_LINK'
export const SEND_RESET_PWD_LINK_ERROR = 'SEND_RESET_PWD_LINK_ERROR'

export const UPDATE_USER_PWD = 'UPDATE_USER_PWD'
export const UPDATE_USER_PWD_ERROR = 'UPDATE_USER_PWD_ERROR'

/* Home Setting */
export const VIEW_HOME_SETTING = 'VIEW_HOME_SETTING'
export const VIEW_HOME_SETTING_ERROR = 'VIEW_HOME_SETTING_ERROR'
export const UPDTATE_HOME_SETTING = 'UPDTATE_HOME_SETTING'
export const UPDTATE_HOME_SETTING_ERROR = 'UPDTATE_HOME_SETTING_ERROR'

/* Happy Holday card's typeId */
export const HAPPY_HOLIDAY_TYPE_ID = 517;

/*Dynamic domain url */
export const DYNAMIC_DOMAIN = "http://" + window.location.host;

/* Payment management*/
export const MAKING_PAYMENT = 'MAKING_PAYMENT';
export const MAKING_PAYMENT_ERROR = 'MAKING_PAYMENT_ERROR';
export const CAPTURE_PAYMENT = 'CAPTURE_PAYMENT';
export const CAPTURE_PAYMENT_ERROR = 'CAPTURE_PAYMENT_ERROR';
export const CANCEL_ORDER_PAYMENT = 'CANCEL_ORDER_PAYMENT';
export const CANCEL_ORDER_PAYMENT_ERROR = 'CANCEL_ORDER_PAYMENT_ERROR';
export const MAKING_CREDIT_CARD_PAYMENT = 'action=ns_quicksale_cc&acctid=WPQME&subid=90206&authonly=1&merchantpin=Fe84RR3eSFbgTBkAFFwyzqxe6PSz7mfp&merchantordernumber=';
export const ACKNOWLEDGE_ORDER_PAYMENT = 'action=ns_quicksale_cc&acctid=WPQME&subid=90206&authonly=1&merchantpin=Fe84RR3eSFbgTBkAFFwyzqxe6PSz7mfp&amount=';
export const CANCELE_PENDING_PAYMENT = 'action=ns_void&acctid=WPQME&subid=90206&merchantpin=Fe84RR3eSFbgTBkAFFwyzqxe6PSz7mfp&amount=';
export const MAKING_ACH_PAYMENT = "<?xml version='1.0'?><interface_driver><trans_catalog><transaction name='ach'><inputs><service>14</service><acctid>WPQME</acctid><subid>90206</subid><merchantpin>Fe84RR3eSFbgTBkAFFwyzqxe6PSz7mfp</merchantpin><ckname>";
export const CARD_PAYMENT_URL = "https://trans.merchantpartners.com/cgi-bin/process.cgi";
export const ACH_PAYMENT_URL = "https://trans.myprismpay.com/cgi-bin/ProcessXML.cgi";
export const PAYEEZY_BASE_URL = "https://api.payeezy.com";
export const PAYEEZY_CARD_PAYMENT_URL = PAYEEZY_BASE_URL + "/v1/transactions";
export const PAYEEZY_ACH_PAYMENT_URL = PAYEEZY_BASE_URL + "/v1/ach/transactions";


export const CARD_PAYMENT_ERROR_1102440001 = "Invalid Expiration Date"
export const CARD_PAYMENT_ERROR_1102350009 = "Invalid account format"
export const CARD_PAYMENT_ERROR_1102420002 = "BillAddress1: (Exceeds Length 64)"
export const CARD_PAYMENT_ERROR_1102410001 = "Amount too small"
export const CARD_PAYMENT_ERROR_3102460009 = "Duplicate timed out waiting for response"
export const CARD_PAYMENT_ERROR = "Invalid account number"

/*Address length Validation error messages */
export const STREET_LENGTH_ERROR = 'Street value should be 1-60 characters';
export const CITY_LENGTH_ERROR = 'City value should be 1-20 characters';
export const STATE_LENGTH_ERROR = 'State value should be 1-20 characters';
export const COUNTRY_LENGTH_ERROR = 'Country value should be 1-40 characters';

/* FAQ */
export const GET_FAQ_LIST = 'GET_FAQ_LIST';
export const GET_FAQ_LIST_ERROR = 'GET_FAQ_LIST_ERROR';
export const CREATE_FAQ = 'CREATE_FAQ';
export const CREATE_FAQ_ERROR = 'CREATE_FAQ_ERROR';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const UPDATE_FAQ_ERROR = 'UPDATE_FAQ_ERROR';
export const GET_FAQ_DETAILS = 'GET_FAQ_DETAILS';
export const GET_FAQ_DETAILS_ERROR = 'GET_FAQ_DETAILS_ERROR';
export const DELETE_FAQ = 'DELETE_FAQ';
export const DELETE_FAQ_ERROR = 'DELETE_FAQ_ERROR';
export const GET_FAQ_TOTAL_RECORDS = 'GET_FAQ_TOTAL_RECORDS';
export const GET_FAQ_TOTAL_RECORDS_ERROR = 'GET_FAQ_TOTAL_RECORDS_ERROR';

export const DEFAULT_DENOMINATION_PRICE_DATA = 5
export const ALL_FIELDS_ERROR_MESSAGE = "All fields are mandatory"
export const INVALID_PRICE = "Invalid price"

/**Update Number for OTP */
export const UPDATE_NUMBER_DATA = "UPDATE_NUMBER_DATA"
export const UPDATE_NUMBER_DATA_ERROR = "UPDATE_NUMBER_DATA_ERROR"

export const CIAM_SEARCH="CIAM_SEARCH"
export const CIAM_SEARCH_ERROR="CIAM_SEARCH_ERROR"
export const UPDATE_PRIMARY_PHONE_CIAM="UPDATE_PRIMARY_PHONE_CIAM"
export const UPDATE_PRIMARY_PHONE_CIAM_ERROR="UPDATE_PRIMARY_PHONE_CIAM_ERROR"


/*Function for Sorting dataList onClick */
export const SORT_BY = (dataList, Name, state) => {
  let response = { data: '', sortOrder: '' }
  let sortedResourceAllocationList = []
  if (state == 'asc') {
    sortedResourceAllocationList = HelperUtil._getSortedByKey(Name, dataList)
    sortedResourceAllocationList = sortedResourceAllocationList.reverse()
    response.data = sortedResourceAllocationList
    response.sortOrder = 'desc'
    return response
  } else {
    sortedResourceAllocationList = HelperUtil._getSortedByKey(Name, dataList)
    response.data = sortedResourceAllocationList
    response.sortOrder = 'asc'
    return response
  }
}
/**Fedex URL  */
export const trackingUrl = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=`
