import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getRolesAction, userAddAction, userUpdateAction } from '../../../Actions/Admin/userManagementAction';
import Modal from 'react-responsive-modal';
import * as constant from '../../../Constants/';
import { Alert } from 'reactstrap';
import InputMask from 'react-input-mask'
import ReactLoading from 'react-loading'

/** Class for add and edit User*/
class AddManagement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      flashMessageError: '',
      flashMessageSuccess: '',
      open: false,
      validObj: {
        id: '',
        name: '',
        email: '',
        password: '',
        roleId: '',
        roleName: ''
      },
      invalidObj: {
        name: '',
        email: '',
        password: '',
        role: ''
      },
      name: '',
      roles: [],
      Loading: false,
      checkPointUpdateState: false,
      isRole: true,
    }
  }

  /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
  componentWillMount() {

    this.props.getRolesAction();
  }

  /**
* React Life Cycle method 
* Invoke just after chenge in dom 
*
* @param {*} nextProps 
*/
  componentWillReceiveProps(nextProps) {
    try {
      let self = this

      if (nextProps && nextProps.updateUserDetails.data && nextProps.updateUserDetails.data.error === false) {
        if (nextProps.updateUserDetails.data.data.hasOwnProperty('status') && nextProps.updateUserDetails.data.data.status === true) {
          self.setState({ flashMessageSuccess: nextProps.updateUserDetails.data.data.message })
          if (self.state.checkPointUpdateState) {
            setTimeout(async function () {
              self.setState({
                Loading: false,
                checkPointUpdateState: false,
                flashMessageSuccess: ''
              })
              await nextProps.getUserList(constant.PARENT_ID)
              nextProps.modalClose();
              nextProps.locationProps.history.push("/ng-admin/usermanagement");

            }, 5000);
          } else {
            self.setState({
              Loading: false,
              checkPointUpdateState: false,
              flashMessageSuccess: '',
              flashMessageError: ''
            })
          }
        } else {
          this.setState({ flashMessageError: nextProps.updateUserDetails.data.data.message })
        }
      } else {
        self.setState({ checkPointUpdateState: false })
      }
      if (nextProps && nextProps.addUser.data && nextProps.addUser.data.error === false) {
        if (nextProps.addUser.data.data.hasOwnProperty('status') && (nextProps.addUser.data.data.status === true)) {
          this.setState({ flashMessageSuccess: nextProps.addUser.data.data.message })
          if (self.state.checkPointUpdateState) {
            setTimeout(async function () {
              self.setState({
                Loading: false,
                checkPointUpdateState: false,
                flashMessageSuccess: ''
              })
              await nextProps.getUserList(constant.PARENT_ID)
              nextProps.modalClose();
              nextProps.locationProps.history.push("/ng-admin/usermanagement");

            }, 5000);
          } else {
            self.setState({
              Loading: false,
              checkPointUpdateState: false,
              flashMessageSuccess: '',
              flashMessageError: '',
            })
          }
        } else {
          this.setState({ flashMessageError: nextProps.addUser.data.data.message })
          nextProps.addUser.data.data = null
          setTimeout(async function () {
            self.setState({
              Loading: false,
              flashMessageError: '',
              checkPointUpdateState: false,
            })
            // nextProps.modalClose();
          }, 5000)
        }
      } else {
        self.setState({ checkPointUpdateState: false, })
      }
      if (nextProps.userDetails) {
        this.setState({
          validObj: {
            name: nextProps.userDetails.name,
            email: nextProps.userDetails.email,
            roleName: nextProps.userDetails.role,
            id: nextProps.userDetails.id,
            roleId: nextProps.userDetails.roleId,
          }
        });
      }
      else {
        this.setState({
          validObj: {
            name: '',
            email: '',
            password: '',
            roleId: '',
            id: '',
          }
        })
      }
      if (this.props.checkModal == false) {
        this.setState({ open: true });
      }
      if (nextProps.addUser && nextProps.addUser.data) {
      } else {
      }
      if (nextProps.roles && nextProps.roles.data && this.state.isRole == true) {
        this.setState({
          roles: nextProps.roles.data,
          isRole: false,
        })
      }
    } catch{ }
  }


  /** Function to validate UserName*/
  validateUserName = (name) => {
    var re = /^[A-Za-z][A-Za-z0-9]+$/
    return re.test(String(name).toLowerCase());
  }

  /** Function to validate Email*/
  validateMail = (email) => {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  /** Function to validate Password*/
  validatePassword = (password) => {
    let pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/
    if (password.match(pattern)) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
     * Function for authenticate user is valid or not on submit
     * @param object event
     * @return object userParams
    */
  userSubmitHandler = async (event) => {
    let url = window.location.host;
    let header = "http://"
    let activationUrl = header + url + '/ng-admin';
    event.preventDefault();
    
    if (this.state.validObj.id == "") {
      let userParams = {
        name: this.state.validObj.name,
        password: this.state.validObj.password,
        email: this.state.validObj.email,
        role: parseInt(this.state.validObj.roleId),
        parentId: sessionStorage.userID,
        active: constant.ACTIVE,
        loggedInUser: sessionStorage.userID,
        adminUrl: activationUrl,
        language: "en"
      }
      if (userParams.name != '' && userParams.email != '' && userParams.password != '' && !(isNaN(userParams.role))) {
        if (!(this.validateUserName(userParams.name))) {
          this.setState({
            flashMessageError: 'Username must have atleast 1 aplhabet before the number'
          })
        }
        else if (!(this.validateMail(userParams.email))) {
          this.setState({
            flashMessageError: constant.INVALID_EMAIL,
          })
        }
        else if (!(this.validatePassword(userParams.password))) {
          this.setState({
            flashMessageError: 'Password must be 8-15 charcaters long and must include at least 1 numeric, 1 lower case and 1 upper case letter',
          })
        } else {
          this.setState({
            Loading: true,
            checkPointUpdateState: true,
            isRole: false,
          })
          await this.props.userAddAction(userParams)
          document.getElementById("Role Selection").selectedIndex = "0";
        }
      }
      else {
        this.setState({
          flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE,
        })
      }
    }
    else if (this.state.validObj.id != "") {
      let url = window.location.host;
      let activationUrl = url + '/ng-admin';
      let userParams = {
        name: this.state.validObj.name,
        password: this.state.validObj.password,
        email: this.state.validObj.email,
        role: parseInt(this.state.validObj.roleId),
        id: this.state.validObj.id,
        parentId: sessionStorage.userID,
        active: this.props.userDetails !== undefined ? this.props.userDetails.active === false ? 0: 1 : 1  ,
        adminUrl: activationUrl,
        loggedInUser: sessionStorage.userID,
        language: "en"
      }
      if (userParams.email != '' && !(isNaN(userParams.role))) {
        if (!(this.validateMail(userParams.email))) {
          this.setState({
            flashMessageError: constant.INVALID_EMAIL,
          })
        } else {
          this.setState({
            Loading: true,
            checkPointUpdateState: true,
          })
          await this.props.userUpdateAction(userParams)
        }
      }
      else {
        this.setState({
          flashMessageError: constant.ALL_FIELDS_ERROR_MESSAGE
        })
      }
    }
    else {
      let invalid = {}
      if (this.state.validObj.name == "") {
        invalid['name'] = "This Field is required"
      }
      if (this.state.validObj.password == "") {
        invalid['password'] = "This Field is required"
      }
      if (this.state.validObj.email == "") {
        invalid['email'] = "This Field is required"
      }
      if (this.state.validObj.role == "") {
        invalid['role'] = "This Field is required"
      }
      let inValidaData = Object.assign(this.state.invalidObj, invalid)
      this.setState({ invalidObj: inValidaData })
    }
  }

  /**
    * Function for render role list
    */
  getRoles() {
    if (this.props.roles && this.props.roles.data) {
      this.setState({
        roles: this.props.roles.data
      })
    }
  }

  /**
  * Function for set user details
  * @param object e
  * @return object state
  */
  setValue(e) {
    this.setState({
      flashMessageError: ''
    })
    let val = e.target.value;
    let key = e.target.name;
    let valid = {};
    let invalid = {};
    if (val === '') {
      invalid[key] = 'this field is required';
      valid[key] = '';
    } else {
      invalid[key] = '';
      valid[key] = val;
    }
    let inValidaData = Object.assign(this.state.invalidObj, invalid);
    let validaData = Object.assign(this.state.validObj, valid);
    this.setState({ invalidObj: inValidaData, validObj: validaData });
  }

  /** Function for close popup model*/
  onCloseModal = () => {
    this.setState({
      flashMessageSuccess: "",
      flashMessageError: '',
      checkPointUpdateState: false,
      validObj: {
        id: '',
        name: '',
        email: '',
        password: '',
        roleId: '',
        roleName: ''
      },
    })
    this.props.modalClose();
  }

  componentWillUnmount() {
    this.setState({
      validObj: {
        name: '',
        email: '',
        password: '',
        role: ''
      }
    })
  }

  render() {
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
    }
    let { disabledRole } = '';
    let { validObj } = this.state;
    let userData = {}
    if (this.props.userDetails && this.props.userDetails) {
      userData = this.props.userDetails
    }
    if (sessionStorage.userID == validObj.id) {
      disabledRole = 'readonly';
    } else {
      disabledRole = '';
    }
    return (
      <Modal open={this.props.checkModal} onClose={this.onCloseModal} center classNames={{ overlay: 'addUseroverlay', modal: 'AddUserModelform' }}
        closeOnOverlayClick={false}
      >
        <div className="modal-dialog">
          {
            this.state.Loading === true && <div className="loader-react">
              <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
            </div>
          }
          <div className="modal-content">
            <div className="modal-header giftcart_title_header">
              <h3 className="modal-title giftcart_all_header"> {(validObj.id !== '') ? 'Edit user' : 'Add user'}</h3>
            </div>
            <div className="modal-body">
              <form>
                {flashSuceessMessageSpan}
                {flashErrorMessageSpan}
                <div container className="form-group">
                  <label>Username</label>
                  <InputMask className="form-control" mask={'*************************'} disabled={(validObj.id) ? 'disabled' : ''} maskChar={null} ref={(e) => this.name = e} name='name' value={validObj.name} onChange={this.setValue.bind(this)} />
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <input type='text' className='form-control' name='email' value={validObj.email} ref={(e) => this.email = e} onChange={this.setValue.bind(this)} />
                </div>
                {(validObj.id !== '') ? (
                  '') : <div className="form-group">
                    <label>Password</label>
                    <input type='password' className='form-control' name='password' value={validObj.password} ref={(e) => this.password = e} onChange={this.setValue.bind(this)} /></div>}
                <div className="form-group">
                  <label>Role</label>
                  <select id="Role Selection" name='roleId' disabled={disabledRole} className='form-control select-dropdown' ref={(e) => this.role = e} onChange={this.setValue.bind(this)}>
                    <option>Please select role</option>
                    {
                      this.state.roles.data ? this.state.roles.data.data.map((role) => {
                        return <option value={role.id} selected={role.name == validObj.roleName}>{role.name}</option>
                      }) : ''
                    }
                  </select>
                </div>
                <div className="formbtn" align-right>
                  <button type="button" className='giftcard_button mgr10' onClick={this.userSubmitHandler.bind(this)}>Submit</button>
                  <button data-brackets-id="69" type="button" className="giftcard_button cardnone_button" onClick={this.onCloseModal.bind(this)}>Back</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.getRoles,
    addUser: state.addUser,
    updateUserDetails: state.updateUser
  }
}

export default connect(mapStateToProps, { getRolesAction, userAddAction, userUpdateAction })(AddManagement);
