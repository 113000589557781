import * as constant from '../../Constants/';
const CIAMJSON = (orderData,prosperId,pimId) =>({
   
        "source": "gift_card",
        "firstName": orderData.firstName,
        "lastName": orderData.lastName,
        "email":  orderData.email,
        "prosperaId": prosperId,
        "alternatePhone": {
          "customerPhone": [
          {
            "source": "gift_card",
            "phoneType": "cell",
            "dialCode": "+1",
            "phoneNo":orderData.mobile.replace(/[^a-zA-Z0-9]/g, '')
          }
          ]
        },
        "alternateEmail": {
          "customerEmail": [
          {
            "source": "gift_card",
            "emailType": "Home",
            "email": orderData.email
          }
          ]
        },
        "sourceMapping": {
          "customerSources": [
          {
            "firstName":  orderData.firstName,
            "lastName": orderData.lastName,
            "sourceId": pimId,
            "source": "gift_card",
            "isSource": false
          }
          ]
        },
        "address": {
          "customerAddress": [
            {
             "source": "gift_card",
             "title": 'Home' ,
             "street": orderData.shippingAddress.address1,
             "zip": orderData.shippingAddress.zip,
             "city":  orderData.shippingAddress.city,
             "state": orderData.shippingAddress.state,
             "country": orderData.shippingAddress.country,
             "isPrimary":  false,
             "isVerified": false
            }]
          },
        "isOptin": true,
        "optin": [
          {
          "optinCategoryId": constant.OPTION_CATEGORY_ID,
          "updatedsource": "gift_card",
          "subscribe": orderData.consent == 1 ? true : false 
          }
        ],
        "isUpdateAllKeys": true
        }
)
export default CIAMJSON;