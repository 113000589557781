import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import { userListAction, getRolesAction, userAddAction, updateUserStatusAction } from '../../../Actions/Admin/userManagementAction';
import { confirmAlert } from 'react-confirm-alert';
import AdddUserManagement from './addManagement';
import * as constant from '../../../Constants/';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pagination from "react-js-pagination";
import { Alert } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactLoading from 'react-loading'
import moment from "moment"
import * as HelperUtil from "../../../utils/common-helper";
import datetime from 'node-datetime';
import Helmet from 'react-helmet';


/** Class for displaying UserManagement details*/
class UserManagements extends Component {

  constructor() {
    super();
    this.editUser = this.editUser.bind(this);
    this.renderUserList = this.renderUserList.bind(this);
    this.editUser = this.editUser.bind(this);
    this.state = {
      flashMessageError: '',
      flashMessageSuccess: '',
      open: false,
      isActive: true,
      validObj: {
        name: '',
        email: '',
        password: '',
        role: ''
      },
      invalidObj: {
        name: '',
        email: '',
        password: '',
        role: ''
      },
      userDetails: '',
      userMode: '',
      pageactive: 1,
      filteredDataList: [],
      userList: [],
      listToFilter: [],
      filterBy: '',
      startDate: new Date(),
      endDate: new Date(),
      sDateToCompare: '',
      eDateToCompare: '',
      Loading: false,
      activeClassName: '',
      filterData: '',
      navigationClass: '',
      checkPointUpdateState: false,
      sortOrder: '',
      fliterStartDate: 'From',
      fliterEndDate: 'To',
      sortField: '',
      userName:''
    }
  };

  /**
    * Function for update state for close modal
    * 
  */
  status = () => {
    this.setState({
      open: false,
      filterBy: 'Select',
      filterData: '',
      filteredDataList: this.state.userList,
      startDate: new Date(),
      endDate: new Date()
    });
  }

  /**
   * Function for render user list
   * 
   */
  renderUserList = () => {
    if (this.props.managementList && this.props.managementList.data) {
      var userListStore = [];
      let managementList = this.props.managementList.data.data.data;
      managementList.map(function (key, item) {
        userListStore.push(<tr><td key={key.id} onClick={(e) => this.editUser(e)} >{key.name}</td><td>{key.email}</td><td>{key.role}</td><td><a href="#"><i className="fa fa-pencil" aria-hidden="true"></i></a><a href='javascript:;'  > <i className="fa fa-circle"></i></a></td></tr>)
      })
      return userListStore;
    }
  }

  /**
* React Life Cycle method 
* Invoke just after chenge in dom 
*
* @param {*} nextProps 
*/
  componentWillReceiveProps(nextProps) {
    try {
      let self = this
      
      if (nextProps && nextProps.updateUserStatus.data) {
        if (nextProps.updateUserStatus.data.data.hasOwnProperty('status') && (nextProps.updateUserStatus.data.data.status === true)) {
          if (self.state.checkPointUpdateState) {
            let message = nextProps.updateUserStatus.data.data.data.active?  `${this.state.userName} has been activated successfully.` :`${this.state.userName} has been deactivated successfully.`
            self.setState({ flashMessageSuccess: message })
            setTimeout(async function () {
              self.setState({
                Loading: false,
                checkPointUpdateState: false,
                flashMessageSuccess: '',
                flashMessageError: ''
              })
              await self.props.userListAction(constant.PARENT_ID)
            }, 5000);
          } else {
            self.setState({
              Loading: false,
              flashMessageSuccess: '',
              flashMessageError: ''
            })
          }

        } else {
          self.setState({ flashMessageError: nextProps.updateUserStatus.data.data.message })
        }
      } else {
        self.setState({ Loading: false, checkPointUpdateState: false })
      }

      if (nextProps.managementList && nextProps.managementList.data && nextProps.managementList.data.error === false) {
        self.setState({
          userList: nextProps.managementList.data.data.data,
          listToFilter: nextProps.managementList.data.data.data,
          filteredDataList: nextProps.managementList.data.data.data,
          Loading: false,
          checkPointUpdateState: false
        })

      } else {
        self.setState({ Loading: false, checkPointUpdateState: false })

      }
      var winHeight = window.innerHeight;
      document.getElementById('user-dashboard').style.height = window.innerHeight - 105 + 'px';
      let reso_height = document.getElementById('user-dashboard').style.height
      document.getElementsByClassName('res_height')[0].style.height = reso_height.slice(0, -2) - 240 + 'px';
      if (nextProps.navigationClass && nextProps.navigationClass.data) {
        self.setState({ navigationClass: nextProps.navigationClass.data });
      }
    } catch{ }
  }

  /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
  componentWillMount() {
    this.setState({ activeClassName: this.props.pathParam })
    this.setState({ Loading: true })
    this.props.userListAction(constant.PARENT_ID);
  }

  componentHandel(e) {
    e.preventDefault();
    this.props.componentID = '';
  }

  /** Function to open Add user modal*/
  onOpenModal = (event) => {
    let data = event.target
    if (localStorage.getItem('loggedIn') === null) {
      this.props.pageProps.history.push({
        pathname: "/ng-admin"
      });
    } else {
      this.setState({ open: true, userDetails: '' });
    }
  };

  /**
  * Function for modal confirm box
  */
  statusConfirm() {
    confirmAlert({
      title: 'Status update',
      message: 'Are you sure to update status.',
      buttons: [
        {
          label: 'Yes',
          onClick: function () {
          }
        },
        {
          label: 'No',
          onClick: () => function () {
          }
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) => <div className="heloo">Custom UI</div>
    })
  }

  /**
     * Function for edit user details
     *  @param{key}
    */
  editUser(key) {
    if (localStorage.getItem('loggedIn') === null) {
      this.props.pageProps.history.push({
        pathname: "/ng-admin"
      });
    } else {
      this.setState({ open: true, userDetails: key });
    }
  }

  /**
   * Function for active and inactive user status
   * @param {key}
   */
  updateStatusUser(key) {
    
    let data = { userId: key.id };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-popup AddUserModelform">
            <div className="modal-header giftcart_title_header"><h3 className="modal-title giftcart_all_header"> Are you sure?</h3></div>
            <div className="modal-body">
              <p>{key.active ? `You want to deactivate ${key.name}` : `You want to activate  ${key.name}`}</p>
              <div className="formbtn">
                <button type="button" className="giftcard_button mgr10"
                  onClick={async () => {
                    this.setState({
                      Loading: true,
                      checkPointUpdateState: true,
                      userName:key.name
                    })
                    await this.props.updateUserStatusAction(data)
                    onClose();
                  }}
                >
                  Confirm
              </button>
                <button type="button" onClick={onClose} className="giftcard_button cardnone_button">No</button></div>
            </div>
          </div>
        );
      }
    })
  }

  /** Function for handle navigation*/
  userRedirect = (data) => {
    let self = this
    if (data == 'sent') {
      window.location.reload();
    }
  }

  /** Function for change page active in pagination*/
  changeactive(page) {
    this.setState({
      pageactive: page
    })
  }

  /** Function for change page number in pagination*/
  handlePageChange = (pageNumber) => {
    this.setState({ pageactive: pageNumber })
  }

  /** Function for filter result on basis of user query input*/
  setFilterBy(e) {
    var val = e.target.value
    this.setState({
      filterBy: val,
      filterData: '',
      filteredDataList: this.state.listToFilter,
      startDate: moment(new Date()).format('MM/DD/YYYY'),
      endDate: moment(new Date()).format('MM/DD/YYYY'),
      fliterStartDate: 'From',
      fliterEndDate: 'To',
    })
  }

  /** Function for filter result on basis of user query input*/
  filterSearch(event) {
    let self = this
    var newDataList = []
    newDataList = this.state.listToFilter
    var val = event.target.value
    this.setState({
      filterData: val
    })
    var newArr = newDataList.filter(function (e) {
      if (self.state.filterBy == 'Role') {
        if (
          ((typeof e.role != "undefined" && e.role != null && e.role !== "") && e.role.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Email') {
        if (
          ((typeof e.email != "undefined" && e.email != null && e.email !== "") && e.email.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Username') {
        if (
          ((typeof e.name != "undefined" && e.name != null && e.name !== "") && e.name.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else {
        return e
      }
    })
    this.setState({
      filteredDataList: newArr,
      pageactive: 1,
    })
  }

  /** Function for set start date*/
  handleChangeStart(date) {
    this.setState({
      startDate: date,
      filterBy: '',
      filterData: '',
    })
  }

  /** Function for handle date*/
  handleChangeEnd(date) {
    this.setState({
      endDate: date,
      filterBy: '',
      filterData: '',
      // filteredDataList: this.state.listToFilter,
    })
  }

  /** Function for handle date*/
  handleChangeStartDate(date) {
    try { 
      let date_ = date.target !== undefined ? date.target.value :false
      let data = /^[0-9-/]*$/
      if( !data.test(date_) && date_!==false) { 
          
          date.target.value = ''
              
      } else {
        var pastDateTime = datetime.create(date);
        var modifedate = moment(pastDateTime.now()).format('YYYY-MM-DD HH:mm');
        let endDate = this.state.endDate != '' ? moment(this.state.endDate).format('YYYY-MM-DD HH:mm') : ''
        let {filteredDataList, filterBy, filterData, userList} = this.state
                    if(filterBy !== '' && filterData !== '' && userList.length > 0){
                    switch (filterBy) {
                        case "Username":
                            filteredDataList = userList.filter((data)=>  data.name.toLowerCase().includes(filterData.toLowerCase()))
                            break;
                        case "Email":
                            filteredDataList = userList.filter((data)=>  data.email.toLowerCase().includes(filterData.toLowerCase()))
                            break;
                        case "Role":
                            filteredDataList = userList.filter((data)=>  data.role.toLowerCase().includes(filterData.toLowerCase()))
                            break;
                        default:
                        filteredDataList = userList
                    }
                }
        const result = HelperUtil._getFilteredCustomerListByDate(moment(date).format('YYYY-MM-DD HH:mm'), filteredDataList, endDate)
        this.setState({
          startDate: date,
          sDateToCompare: modifedate,
          filteredDataList: result,
          fliterStartDate: moment(date).format('MM/DD/YYYY'),
          // filterBy: '',
          // filterData: '',
          // filteredDataList: this.state.listToFilter,
        });
      }
    } catch(e) {console.log("error in calender",e)}
    
   
  }

  /** Function for formatting date*/
  handleChangeEndDate(date) {
    try {
      let date_ = date.target !== undefined ? date.target.value :false
      let data = /^[0-9-/]*$/
      if( !data.test(date_) && date_!==false) { 
          
          date.target.value = ''
              
      } else {
        var pastDateTime = datetime.create(date);
        var modifedate = moment(pastDateTime.now());
        let {filteredDataList, filterBy, filterData, userList} = this.state
                    if(filterBy !== '' && filterData !== '' && userList.length > 0){
                    switch (filterBy) {
                        case "Username":
                            filteredDataList = userList.filter((data)=>  data.name.toLowerCase().includes(filterData.toLowerCase()))
                            break;
                        case "Email":
                            filteredDataList = userList.filter((data)=>  data.email.toLowerCase().includes(filterData.toLowerCase()))
                            break;
                        case "Role":
                            filteredDataList = userList.filter((data)=>  data.role.toLowerCase().includes(filterData.toLowerCase()))
                            break;
                        default:
                        filteredDataList = userList
                    }
                }
        const result = HelperUtil._getFilteredCustomerListByDate(moment(this.state.startDate).format('YYYY-MM-DD HH:mm'), filteredDataList, moment(date).format('YYYY-MM-DD HH:mm'))
        this.setState({
          endDate: date,
          eDateToCompare: modifedate,
          filteredDataList: result,
          fliterEndDate: moment(date).format('MM/DD/YYYY'),
          // filterBy: '',
          // filterData: '',
          // filteredDataList: this.state.listToFilter,
        });
      }
    } catch(e) {console.log("error in handle ChangeEnd date ",e)}
    
  }

  /** Function to reset the date*/
  resetList(e) {
    var val = e.target.value
    this.setState({
      filterBy: '',
      filterData: '',
      startDate: moment(new Date()).format('MM/DD/YYYY'),
      endDate: moment(new Date()).format('MM/DD/YYYY'),
      fliterStartDate: 'From',
      fliterEndDate: 'To',
      filteredDataList: this.state.listToFilter,
    })
  }

  /** Function for Creation data*/
  generateCreationDate(date) {
    var unixTime = date
    var momentDate = moment.unix(unixTime),
      momentDay = momentDate.format('DD'),
      momentMonth = momentDate.format('MM'),
      momentYear = momentDate.format('YYYY')
    let newDate = momentMonth + '/' + momentDay + '/' + momentYear
    return newDate
  }

  /** Function for sorting data onclick*/
  sortBy(dataList, Name) {
    this.setState({
      sortField: Name
    })

    let response = constant.SORT_BY(dataList, Name, this.state.sortOrder)
    this.setState({
      filteredDataList: response.data,
      sortOrder: response.sortOrder
    })
  }

  render() {
    let { filteredDataList } = this.state
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
    }
    var list = filteredDataList != undefined ? filteredDataList.slice((this.state.pageactive - 1) * 12, (this.state.pageactive) * 12) : ''
    return (
      <div className="admin_top">
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }

      <Helmet>
          <title>User Management</title>
      </Helmet>
        <div className="content-wrapper" className="d-flex flex-column">
          <Header />
          <div className="main">
            <Navigation activeClassName={this.state.activeClassName} />
            <div className={this.state.navigationClass == '' || this.state.navigationClass == 'abc' ? "dashboardContent" : "dashboardContent main_container"}>
              <div className="container-fluid">
                <div className="admin-header">
                  <div className="user-dashboard user-management clearfix" id="user-dashboard">
                    <div className="row mar0">
                      <div className="col-md-12">
                        {flashSuceessMessageSpan}
                        {flashErrorMessageSpan}
                        <h2>User Management</h2>
                        <div className="row">
                          <div className="pull-left col-md-9 pr-0 shipping-filter">
                            <div className="row">
                              <div className="form-group filter-input">
                                <div className="styled-select slate">
                                  <select className="select-dropdown" name="test" onChange={(e) => this.setFilterBy(e)} value={this.state.filterBy}>
                                    <option value="Select">Select</option>
                                    <option value="Username">Username</option>
                                    <option value="Email">Email Address</option>
                                    <option value="Role">Role</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group filter-input">
                                <div className="inner-addon search-input">
                                  <input type="text" className="form-control input-style search-icon" placeholder="Search" value={this.state.filterData} onChange={this.filterSearch.bind(this)} />
                                </div>
                              </div>
                              <div className="datePicker">
                                <div className="form-group filter-calendar datePickerReact">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <DatePicker 
                                        className='form-control calendar-icon' 
                                        placeholderText={this.state.fliterStartDate} 
                                        disabled={this.state.filter} 
                                        maxDate={new Date()} inputProps={{ readOnly: true }} 
                                        onChange={this.handleChangeStartDate.bind(this)} 
                                        onChangeRaw = {this.handleChangeStartDate.bind(this)} 
                                        />
                                    </div>
                                    <div className="col-sm-6 secondDatePikker">
                                      <DatePicker
                                        className='form-control calendar-icon' 
                                        placeholderText={this.state.fliterEndDate} 
                                        disabled={this.state.filter} maxDate={new Date()} 
                                        inputProps={{ readOnly: true }} 
                                        onChange={this.handleChangeEndDate.bind(this)} 
                                        onChangeRaw = {this.handleChangeEndDate.bind(this)} 


                                        />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="refresh-btn">
                                <i onClick={this.resetList.bind(this)} title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>


                          <div className="pull-right  col-md-3 pl-0 col-sm-4 text-center mbl-scn dashboard-user">
                            <button className="giftcard_button pull-right button-padding" onClick={this.onOpenModal}>ADD User</button>
                          </div>
                        </div>
                        <div className="table-responsive ">
                          <table id="example" className="table table-striped table-bordered" cellSpacing="0" width="100%">
                            <thead>
                              <tr>
                                <th className="customer-name-td  all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'name')} >
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'name' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Username</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'email')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'email' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Email Address</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'role')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'role' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Role</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'creationDate')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'creationDate' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Created Date</th>
                                <th className="action-td">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                list.length > 0 ? list.map((key, index) => {
                                  
                                  return <tr key={index}>
                                    <td className="customer-name-td"><span onClick={(e) => this.editUser(key)}>{key.name}</span></td>
                                    <td>{key.email}</td>
                                    <td>{key.role}</td>
                                    <td>{key.creationDate ? this.generateCreationDate(key.creationDate) : '-'}</td>
                                    <td className="action-td"><a onClick={(e) => this.editUser(key)} href="#"><i className="fa fa-pencil" aria-hidden="true"></i></a>
                                      {(sessionStorage.userID != key.id ?
                                        <a className={(key.active == true ? 'green' : 'red')} onClick={(e) => this.updateStatusUser(key)} href='javascript:;'><i className="fa fa-circle"></i></a>
                                        : '--')}
                                    </td>
                                  </tr>
                                }) :  <tr><td className="recordNotFound" colSpan="10">No record found</td></tr>
                              }
                            </tbody>
                          </table>
                        </div>
                        <ul className="pagination pull-right my-1">
                          <Pagination
                            hideDisabled
                            activePage={this.state.pageactive}
                            itemsCountPerPage={12}
                            totalItemsCount={this.state.filteredDataList.length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                            firstPageText='First'
                            lastPageText='Last'
                            prevPageText="Prev"
                            nextPageText="Next"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AdddUserManagement checkModal={this.state.open} modalClose={this.status} redirectPage={this.userRedirect} userDetails={this.state.userDetails}
                getUserList={this.props.userListAction}
                locationProps={this.props.componentID}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    managementList: state.userList,
    roles: state.getRoles,
    addUser: state.addUser,
    updateUserStatus: state.updateUserStatus,
    navigationClass: state.navigationToggleClass
  };
}

export default connect(mapStateToProps, { userListAction, getRolesAction, userAddAction, updateUserStatusAction })(UserManagements);