import { Link } from "react-router-dom";
import Login from '../../Login';
import Home from '../../Home';
import React, { Component } from 'react';
import UserManagements from '../../UserManagement';
import dashboardLogo from '../../../../public/img/dashboard-logo.png';
import dashboard from '../../../../public/img/dashboard.png';
import OrderManagement from '../../../../public/img/user-img.png';
import GiftCardManagement from '../../../../public/img/gift-management.png';
import CustomerManagement from '../../../../public/img/customer-management.png';
import UserManagement from '../../../../public/img/user-management.png';
import ShippingManagement from '../../../../public/img/shipping-anagement.png';
import Denomination from '../../../../public/img/denomination.png';
import DiscountManagement from '../../../../public/img/discount-management.png';
import { connect } from 'react-redux';
import { navigationSideBarAction } from '../../../../Actions/Admin/userManagementAction';

// Class for displaying naviagtions 
class Navigation extends React.Component {

  constructor() {
    super();
    this.state = {
      navigationClass: ''
    }
  }

  componentWillMount() {
    this.setState({
      navigationClass: this.state.props !== undefined ? this.state.props.pathParam : ''
    })
  }

  componentWillReceiveProps(nextpros) {
    this.setState({ navigationClass: nextpros.navigationClass.data });
  }

  /**
   * Method for change Navigation class
   */
  changeNavigationClass() {
    if (this.state.navigationClass == 'abc') {
      this.props.navigationSideBarAction('fliph');
    } else if (this.state.navigationClass == '') {
      this.props.navigationSideBarAction('fliph');
    }
    else {
      this.props.navigationSideBarAction('abc');
    }
  }

  //Mehtod to set the navigation
  navigation() {
    let toRender;
    if (sessionStorage.isSuperAdmin == 'true' && sessionStorage.loggedIn != '') {
      toRender = <ul className="list-sidebar ">
        <li className={`sprite_icon Dashboard ${this.props.activeClassName === "home" ? "addClass" : ''}`} >
          <Link to="/ng-admin/home"><span className="nav-label">Dashboard</span></Link>
        </li>
        <li className={`sprite_icon Card_Management ${this.props.activeClassName === "ordermanagement" ? 'addClass' : ''} `}>
          <Link to="/ng-admin/ordermanagement"><span className="nav-label">Order Management</span></Link></li>
        <li className={`sprite_icon Gift_Card ${this.props.activeClassName === "giftcardmanagement" ? 'addClass' : ''}`}>
          <Link to="/ng-admin/giftcardmanagement"><span className="nav-label">Gift Card Management</span>
          </Link>
        </li>
        <li className={`sprite_icon Gift_Customer ${this.props.activeClassName === "customermanagement" ? 'addClass' : ''}`}>
          <Link to="/ng-admin/customermanagement">
            <span className="nav-label">Customer Management</span>
          </Link>
        </li>
        <li className={`sprite_icon User_Management ${this.props.activeClassName === "usermanagement" ? 'addClass' : ''} `} >
          <Link to="/ng-admin/usermanagement"><span className="nav-label">User Management</span></Link></li>
        <li className={`sprite_icon Shipping_Management ${this.props.activeClassName === "shipmanagement" ? 'addClass' : ''} `} ><Link to="/ng-admin/shipmanagement"><span className="nav-label">Shipping Management</span></Link></li>
        <li className={`sprite_icon Denomination ${this.props.activeClassName === "denomination" ? 'addClass' : ''} `}><Link to="/ng-admin/denomination"><span className="nav-label">Gift card design</span></Link></li>
        <li className={`sprite_icon Discount_Management  ${this.props.activeClassName === "discountmanagement" ? 'addClass' : ''}`}><Link to="/ng-admin/discountmanagement"><span className="nav-label">Discount Management</span></Link></li>
        <li className={`sprite_icon homesettings ${this.props.activeClassName === "homesettings" ? 'addClass' : ''}`}><Link to="/ng-admin/homesettings"><span className="nav-label">Home Settings</span></Link></li>
        <li className={`sprite_icon faq ${this.props.activeClassName === "faq" ? 'addClass' : ''}`}><Link to="/ng-admin/faq"><span className="nav-label">FAQ</span></Link></li>
        {/* <li className={`sprite_icon faq ${this.props.activeClassName === "privacy-policy" ? 'addClass' : ''}`}><Link to="/ng-admin/privacy-policy"><span className="nav-label">Privacy Policy</span></Link></li>
        <li className={`sprite_icon faq ${this.props.activeClassName === "LandingPageManagement" ? 'addClass' : ''}`}><Link to="/ng-admin/LandingPageManagement"><span className="nav-label">Landing Page Management</span></Link></li> */}
      </ul>
    } else if (sessionStorage.isSuperAdmin == 'false' && sessionStorage.roleID == 3 && sessionStorage.isSuperAdmin != undefined && sessionStorage.loggedIn != '') {
      toRender = <ul className="list-sidebar">
        <li className="sprite_icon Dashboard"><Link to="/ng-admin/home"><span className="nav-label">Dashboard</span></Link></li>
        <li className="sprite_icon Card_Management"><Link to="/ng-admin/ordermanagement"><span className="nav-label">Order Management</span></Link></li>
        <li className="sprite_icon Gift_Card" ><Link to="/ng-admin/giftcardmanagement"><span className="nav-label">Gift Card Management</span></Link></li>
        <li className="sprite_icon Gift_Customer"><Link to="/ng-admin/customermanagement"><span className="nav-label">Customer Management</span></Link></li>
        <li className="sprite_icon Shipping_Management " ><Link to="/ng-admin/shipmanagement"><span className="nav-label">Shipping Management</span></Link></li>
        <li className="sprite_icon Denomination"><Link to="/ng-admin/denomination"><span className="nav-label">Gift card designs</span></Link></li>
        <li className="sprite_icon Discount_Management"><Link to="/ng-admin/discountmanagement"><span className="nav-label">Discount Management</span></Link></li>
        <li className={`sprite_icon homesettings ${this.props.activeClassName==="homesettings" ? 'addClass':''}`}><Link to="/ng-admin/homesettings"><span className="nav-label">Home Settings</span></Link></li>
        <li className={`sprite_icon faq ${this.props.activeClassName==="faq" ? 'addClass':''}`}><Link to="/ng-admin/faq"><span className="nav-label">FAQ</span></Link></li>
        {/* <li className={`sprite_icon faq ${this.props.activeClassName === "privacy-policy" ? 'addClass' : ''}`}><Link to="/ng-admin/privacy-policy"><span className="nav-label">Privacy Policy</span></Link></li>
        <li className={`sprite_icon faq ${this.props.activeClassName === "LandingPageManagement" ? 'addClass' : ''}`}><Link to="/ng-admin/LandingPageManagement"><span className="nav-label">Landing Page Management</span></Link></li> */}
      </ul>
    } else if (sessionStorage.isSuperAdmin == 'false' && sessionStorage.roleID == 4 && sessionStorage.loggedIn != '') {
      toRender = <ul className="list-sidebar"><li className="sprite_icon Dashboard"><Link to="/ng-admin/home"><span className="nav-label">Dashboard</span></Link></li>
        <li className="sprite_icon Card_Management"><Link to="/ng-admin/ordermanagement"><span className="nav-label">Order Management</span></Link></li>
        <li className="sprite_icon Gift_Card"><Link to="/ng-admin/giftcardmanagement"><span className="nav-label">Gift Card Management</span></Link></li>
      </ul>
    }
    return toRender;
  }

  render() {
    return (
      <aside>
        <div className={"sidebar left " + this.state.navigationClass} >
          <div className="navi">
            {this.navigation()}
          </div>
        </div>
      </aside>);
  }
}

const mapStateToProps = (state) => {
  return {
    navigationClass: state.navigationToggleClass
  }
}

export default connect(mapStateToProps, { navigationSideBarAction })(Navigation);
