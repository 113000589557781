import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import { customerListAction, customerResetPWDAction } from '../../../Actions/Admin/customerManagementAction';
import UnlockUser from './unlockUser';
import Pagination from "react-js-pagination";
import * as constants from '../../../Constants';
import { Alert } from 'reactstrap';
import base64 from 'base-64'
import ReactLoading from 'react-loading'
import moment from "moment"
import * as HelperUtil from "../../../utils/common-helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datetime from 'node-datetime';
import XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import PdfFormat from "./pdfFormat";
import Helmet from 'react-helmet';


/** Class for displaying Customer details  */
class CustomerManagements extends Component {
    constructor() {
        super();
        this.state = {
            flashMessageError: '',
            flashMessageSuccess: '',
            unlockModal: false,
            resetLinkModal: false,
            customerDetails: '',
            pageactive: 1,
            startDate: new Date(),
            endDate: new Date(),
            sDateToCompare: '',
            eDateToCompare: '',
            listToFilter: [],
            customerList: [],
            filteredDataList: [],
            filterBy: '',
            Loading: false,
            activeClassName: '',
            filterData: '',
            checkUpdateState: false,
            sortOrder: '',
            fliterStartDate: 'From',
            fliterEndDate: 'To',
            tableHeader: ["Customer Name", "Address", "Creation Date"],
            sortField: '',
        };
        this.sortBy.bind(this);
    }

 /***
 * React Built-in Method called just before DOM is about to mounted
 */
    componentWillMount() {
        try {
            if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn === '' || sessionStorage.length == 0) {
                this.props.componentID.history.push("/ng-admin");
            }
        } catch (err) {

        }
        this.setState({ activeClassName: this.props.pathParam })
        this.setState({ Loading: true })
        this.props.customerListAction(); //API call 
    }

    /** Method for assing empty string to ComponentID */
    componentHandel(e) {
        e.preventDefault();
        this.props.componentID = '';
    }

    /* Method to open the unlock user modal */
    onOpenModal = (customerData) => {
        this.setState({ unlockModal: true, customerDetails: customerData });
    };

    /*Method to open reset password modal */
    onOpenResetPWDModal = (customerData) => {
        this.setState({ resetLinkModal: true, customerDetails: customerData });
    };

    /*Method to close the unlock modal */
    unlockCloseModal = () => {
        this.setState({ unlockModal: false });
    };

    /* Method to close the reset password modal */
    resetPWDCloseModal = () => {
        this.setState({ resetLinkModal: false });
    };

    /**
     * Method for getting customer information 
     * @param {data}
     * @return {undefined}
     */
    getCustomerInfo(data) {
        let self = this
        self.props.componentID.history.push({
            pathname: "/ng-admin/customermanagement",
            state: { displayCustomerInfo: data }
        })
    }

    /** Method for navigation */
    userRedirect = (data) => {
        let self = this
        if (data == 'unlocked') {
            setInterval(function () {
                window.location.reload();
            }, 5000)
        }
    }

    /** Method for handle page number in pagination */
    handlePageChange = (pageNumber) => {
        this.setState({
            pageactive: pageNumber
        })
    }

    /**
     * Function for reset password link
     * @param {object}
     * @returns {undefined}
    */
    async  resetPWDLink(customerData) {
        let rem = (window.location.href).indexOf("ng-admin")
        let url = (window.location.href).slice(0, rem)
        let timeSamp = this.currentDateTime();
        let encodedData = base64.encode(customerData.email + "&&&" + timeSamp)
        url = url + "?forgetId=" + encodedData
        let userDetails = { url: url, email: customerData.email };
        this.setState({
            Loading: true,
            checkUpdateState: true,
        })
        await this.props.customerResetPWDAction(userDetails); //API Call
    }

/**
 * React Built-in Method invoke after compare between virtual DOM and Real DOM
 * @param {*} nextProps 
 */
    componentWillReceiveProps(nextProps) {
        let self = this
        if (nextProps && nextProps.resetCustomerPWDResponse.data) {
            if (nextProps.resetCustomerPWDResponse.data.hasOwnProperty('status') && (nextProps.resetCustomerPWDResponse.data.status === true)) {
                self.setState({ flashMessageSuccess: nextProps.resetCustomerPWDResponse.data.message })
                if (self.state.checkUpdateState) {
                    setTimeout(function () {
                        self.setState({
                            Loading: false,
                            checkUpdateState: false,
                            flashMessageSuccess: '',
                            flashMessageError: ''
                        })
                        self.props.componentID.history.push("/ng-admin/customermanagement")
                    }, 5000)
                } else {
                    self.setState({
                        Loading: false,
                        checkUpdateState: false,
                        flashMessageSuccess: '',
                        flashMessageError: ''
                    })
                }
            } else {
                self.setState({ flashMessageError: nextProps.resetCustomerPWDResponse.data.message })
                setInterval(function () {
                    self.setState({
                        Loading: false,
                        checkUpdateState: false,
                        flashMessageSuccess: '',
                        flashMessageError: ''
                    })
                    self.props.componentID.history.push("/ng-admin/customermanagement")
                }, 5000)
            }
        } else {
            self.setState({ Loading: false, checkUpdateState: false })
        }
        if (nextProps.customerList && nextProps.customerList.data && nextProps.customerList.data.error === false) {
            self.setState({
                customerList: nextProps.customerList.data.data.data,
                listToFilter: nextProps.customerList.data.data.data,
                filteredDataList: nextProps.customerList.data.data.data,
                Loading: false,
            })
        } else {
            self.setState({ Loading: false, checkUpdateState: false })
        }
        var winHeight = window.innerHeight;
        document.getElementById('user-dashboard').style.height = window.innerHeight - 155 + 'px';
        let reso_height = document.getElementById('user-dashboard').style.height
        // document.getElementsByClassName('res_height')[0].style.height = reso_height.slice(0, -2) - 240 + 'px';
    }

    /** Method for filter result for user */
    filterSearch(event) {
        let self = this
        var newDataList = []
        newDataList = this.state.listToFilter
        var val = event.target.value
        this.setState({
            filterData: val
        })
        var newArr = newDataList.filter(function (e) {
            if (self.state.filterBy == 'Customer Name') {
                if (
                    ((typeof e.firstName != "undefined" && e.firstName != null && e.firstName !== "") && e.firstName.toLowerCase().includes(val.toLowerCase())) ||
                    ((typeof e.lastName != "undefined" && e.lastName != null && e.lastName !== "") && e.lastName.toLowerCase().includes(val.toLowerCase()))
                ) {
                    return e
                }
            }
            else {
                return e
            }
        })
        this.setState({
            filteredDataList: newArr,
            pageactive: 1
        })
    }

    /** Method for setting parameter for filter search result */
    setFilterBy(e) {
        var val = e.target.value
        this.setState({
            filterBy: val,
            filterData: '',
            filteredDataList: this.state.listToFilter,
            startDate: moment(new Date()).format('MM/DD/YYYY'),
            endDate: moment(new Date()).format('MM/DD/YYYY'),
            fliterStartDate: 'From',
            fliterEndDate: 'To',
        })
    }

    /** Method for change start date */
    handleChangeStart(date) {
        this.setState({
            startDate: date,
            filterBy: '',
            filterData: '',
        })
    }


    /** Method for change end date */
    handleChangeEnd(date) {
        this.setState({
            endDate: date,
            filterBy: '',
            filterData: '',
        })
    }

    /** Method for handle date formate */
    handleChangeStartDate(date) {
        try {
            let date_ = date.target !== undefined ? date.target.value :false
            let data = /^[0-9-/]*$/
            if( !data.test(date_) && date_!==false) { 
                
                date.target.value = ''
                    
            } else {
                var pastDateTime = datetime.create(date);
                var modifedate = moment(pastDateTime.now()).format('YYYY-MM-DD HH:mm');
                let endDate = this.state.endDate != '' ? moment(this.state.endDate).format('YYYY-MM-DD HH:mm') : ''
                let {filteredDataList, filterBy, filterData, customerList} = this.state
                    if(filterBy !== '' && filterData !== '' && customerList.length > 0){
                    switch (filterBy) {
                        case "Customer Name":
                            filteredDataList = customerList.filter((data)=>  (data.firstName + data.lastName).includes(filterData))
                            break;
                        default:
                        filteredDataList = customerList
                    }
                }
                const result = HelperUtil._getFilteredCustomerListByDate(moment(date).format('YYYY-MM-DD HH:mm'), filteredDataList, endDate)
                this.setState({
                    startDate: date,
                    sDateToCompare: modifedate,
                    filteredDataList: result,
                    fliterStartDate: moment(date).format('MM/DD/YYYY'),
                    // filterBy: '',
                    // filterData: '',
                });
            }
        } catch(e) {console.log("error in calender ",e)}
       
    }

    /** Method for handle date format */
    handleChangeEndDate(date) {

        try {
            let date_ = date.target !== undefined ? date.target.value :false
            let data = /^[0-9-/]*$/
            if( !data.test(date_) && date_!==false) { 
                
                date.target.value = ''
                    
            } else {
                var pastDateTime = datetime.create(date);
                var modifedate = moment(pastDateTime.now());
                let {filteredDataList, filterBy, filterData, customerList} = this.state
                    if(filterBy !== '' && filterData !== '' && customerList.length > 0){
                    switch (filterBy) {
                        case "Customer Name":
                            filteredDataList = customerList.filter((data)=>  (data.firstName + data.lastName).includes(filterData))
                            break;
                        default:
                        filteredDataList = customerList
                    }
                }
                const result = HelperUtil._getFilteredCustomerListByDate(moment(this.state.startDate).format('YYYY-MM-DD HH:mm'), filteredDataList, moment(date).format('YYYY-MM-DD HH:mm'))
                this.setState({
                    endDate: date,
                    eDateToCompare: modifedate,
                    filteredDataList: result,
                    fliterEndDate: moment(date).format('MM/DD/YYYY'),
                    // filterBy: '',
                    // filterData: '',
                });
            }
        } catch(e) { console.log("eror in calender",e)}
       
    }

    /** Method to reset the date */
    resetList(e) {
        var val = e.target.value
        this.setState({
            filterBy: '',
            filterData: '',
            startDate: moment(new Date()).format('MM/DD/YYYY'),
            endDate: moment(new Date()).format('MM/DD/YYYY'),
            fliterStartDate: 'From',
            fliterEndDate: 'To',
            filteredDataList: this.state.listToFilter,
        })
    }

    /** Method for creating current date & time */
    currentDateTime() {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date + ' ' + time;
        return dateTime
    }

    /** Method for generateCreationDate */
    generateCreationDate(date) {
        var unixTime = date
        var momentDate = moment.unix(unixTime),
            momentDay = momentDate.format('DD'),
            momentMonth = momentDate.format('MM'),
            momentYear = momentDate.format('YYYY')
        let newDate = momentMonth + '/' + momentDay + '/' + momentYear
        return newDate
    }

    /** Method for sorting data onclick */
    sortBy(dataList, Name) {
        this.setState({
            sortField: Name
        })

        let response = constants.SORT_BY(dataList, Name, this.state.sortOrder)
        this.setState({
            filteredDataList: response.data,
            sortOrder: response.sortOrder
        })
    }

    /** Method to create excel file */
    createExcel(e, data) {
        try {
            // var workbook = XLSX.utils.book_new();
            let e = this.filterDataCSV(this.state.filteredDataList)

            var wb = XLSX.utils.book_new();
            var wscols = [
                { wch: 50 },
                { wch: 80 },
                { wch: 20 },
            ];
            var wsrows = [
                { hpt: 15 }, // "points"
                { hpx: 16 }, // "pixels"
            ];


            XLSX.utils.book_append_sheet(wb, ws, "WorksheetName");

            /* make the worksheet */
            var ws = XLSX.utils.json_to_sheet(e);
            ws['!cols'] = wscols;
            ws['!rows'] = wsrows;

            /* add to workbook */
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "People");

            /* generate an XLSX file */
            let dateTime = HelperUtil._getDateTimeForReport()
            let fileName = `Customer_Report_${dateTime}.xlsx`
            XLSX.writeFile(wb, fileName);
        }
        catch (e) { }
    }

    /**Method to generate format of pdf file*/
    generatePdf() {

        let data = []
        let self = this
        let pdf = new jsPDF('', 'pt', [800, 900])
        let pdfPages = Math.ceil(this.state.filteredDataList.length / 30)
        self.setState({ Loading: true })
        for (let i = 0; i < pdfPages; i++) { data.push(i) }
        data.map(async (data, i) => {
            var pdfId = document.getElementById(`pdfId_${i}`);


            await html2canvas(pdfId, {
                width: 800,
                dpi: 350,
                letterRendering: true,
                height: 1000,
                scale: "2"
            }).then((canvas) => {

                const imgData = canvas.toDataURL('image/jpeg', 1.0);
                pdf.addImage(imgData, 'JPEG', 30, 20, 800, 750);
                if (pdfPages - 1 !== i)
                    pdf.addPage()
                else
                    pdf.save(`Customer_Report_${new Date()}.pdf`);
                self.setState({ Loading: false })
            });

        })
    }

    /** Method to build pdf file from givenn data */
    createPdfFromHtml() {
        try {
            if (this.state.accountExist == false || this.state.projetExist == false) {
                var ready = window.confirm("Either Account or Projecct does not exist,Do you still want to generate PDF?")
                if (ready) {
                    this.generatePdf()
                }
            }
            else
                this.generatePdf()
        } catch (e) { console.log("error ", e) }

    }

    /** Method to create pdf file  */
    createPdf(data) {

        try {
            this.createPdfFromHtml()

        } catch (err) {console.log('error', err)}

    }

    /**
     * Method for fitered csv data 
     * @param {Object} data
     * @return  {data} filtered csv data 
     */
    filterDataCSV(data) {
        try {
            let tempData = []
            let json = {}
            let address = []
            data.map(data => {
                address = data.addresses.filter(address => address.status === true)
                json = {

                    "Customer Name": `${data.firstName} ${data.lastName}`,
                    "Address": address.length > 0 ? `${address[0].address1} ${address[0].addressType} ${address[0].city} ${address[0].state} ${address[0].country} ${address[0].zip}` : '',
                    "Creation Date": `${moment(data.creationDate * 1000).format('MM/DD/YYYY')}`,

                }
                tempData.push(json)
            })
            return tempData
        }
        catch (e) { }
    }

    render() {
        let { filteredDataList } = this.state
        if (sessionStorage.loggedIn === '' && sessionStorage.length == 0) {
            this.props.history.push("/ng-admin");
        }
        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
        }
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
        }
        var list = filteredDataList != undefined ? filteredDataList.slice((this.state.pageactive - 1) * 10, (this.state.pageactive) * 10) : ''

        let dataToCsv = this.filterDataCSV(this.state.filteredDataList)

        return (
            <div className="admin_top">
                {
                    this.state.Loading === true && <div className="loader-react">
                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                    </div>
                }
                <Helmet>
                    <title>Customer Management</title>
                </Helmet>
                <div id="content-wrapper" className="d-flex flex-column">
                    <Header />
                    <div className="main">
                        <Navigation activeClassName={this.state.activeClassName} />
                        <UnlockUser modalOpen={this.state.unlockModal} redirectPage={this.userRedirect} customerData={this.state.customerDetails} closeModal={this.unlockCloseModal} />
                        <div className="dashboardContent">
                            <div className="container-fluid">
                                <div className="admin-header">
                                    <div className="user-dashboard user-management clearfix paddingBottom" id="user-dashboard">
                                        <div className="row mar0">
                                            <div className="col-md-12">
                                                <h2>Customer Management</h2>
                                                {flashSuceessMessageSpan}
                                                {flashErrorMessageSpan}
                                                <div className="row">
                                                    <div className="pull-left col-md-10 shipping-filter">
                                                        <div className="row">
                                                            <div className="form-group filter-input">
                                                                <div className="styled-select slate">
                                                                    <select className="select-dropdown" name="test" onChange={(e) => this.setFilterBy(e)} value={this.state.filterBy}>
                                                                        <option>Select</option>
                                                                        <option value="Customer Name">Customer Name</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="form-group filter-input">
                                                                <div className="inner-addon search-input">
                                                                    <input type="text" className="form-control input-style search-icon" placeholder="Search" value={this.state.filterData} onChange={this.filterSearch.bind(this)} />
                                                                </div>
                                                            </div>
                                                            <div className="datePicker">
                                                                <div className="form-group filter-calendar datePickerReact">
                                                                    <div className="row">
                                                                        <div className="col-sm-6">
                                                                            <DatePicker 
                                                                                className='form-control calendar-icon' 
                                                                                placeholderText={this.state.fliterStartDate} 
                                                                                disabled={this.state.filter} maxDate={new Date()} 
                                                                                inputProps={{ readOnly: true }} 
                                                                                onChange={this.handleChangeStartDate.bind(this)} 
                                                                                onChangeRaw={this.handleChangeStartDate.bind(this)} 
                                                                            />
                                                                        </div>
                                                                        <div className="col-sm-6 secondDatePikker">
                                                                            <DatePicker 
                                                                                className='form-control 
                                                                                calendar-icon' 
                                                                                placeholderText={this.state.fliterEndDate} 
                                                                                disabled={this.state.filter} maxDate={new Date()} i
                                                                                nputProps={{ readOnly: true }} 
                                                                                onChange={this.handleChangeEndDate.bind(this)} 
                                                                                onChangeRaw = {this.handleChangeEndDate.bind(this)} 
                                                                                />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="refresh-btn">
                                                                <i onClick={this.resetList.bind(this)} title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="pull-right pr-2">
                                                            <button onClick={this.createPdf.bind(this, dataToCsv)} title="Download Pdf" className="pdf-downlode btn-pdf_xls mr-2"></button>
                                                            <button onClick={this.createExcel.bind(this, dataToCsv)} title="Download XLSX" className="xls-downlode btn-pdf_xls"></button>
                                                            {/* <CSVLink data={dataToCsv} className="xls-downlode btn-pdf_xls" filename={'Order_Management'+ ".csv"}> */}
                                                            {/* </CSVLink> */}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="table-responsive res_height">
                                                    <table id="example" className="table table-striped table-bordered" cellSpacing="0" width="100%">
                                                        <thead>
                                                            <tr>
                                                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'firstName')} width="15%">
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'firstName' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Customer Name</th>
                                                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'addresses[0].address1')} width="50%">
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'addresses[0].address1' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Address</th>
                                                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'creationDate')} width="26%">
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'creationDate' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Creation Date</th>
                                                                <th className="action-td">Actions</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                list.length > 0 ? list.map((key, index) => {
                                                                    var customerAddress = []
                                                                    key.addresses.length > 0 ? key.addresses.map((address) => {
                                                                        if (address.status == true)
                                                                            customerAddress = address
                                                                    }) : customerAddress = []
                                                                    return <tr key={index}>
                                                                        <td className="customer-name-td" width="15%"><span onClick={() => this.getCustomerInfo(key)}>{key.firstName + ' ' + key.lastName}</span></td>
                                                                        <td width="50%">{customerAddress != '' ? customerAddress.address1 + ', ' + customerAddress.city + ', ' + customerAddress.state + ', ' + customerAddress.country + ', ' + customerAddress.zip : ''}</td>
                                                                        <td width="26%">{key.creationDate ? this.generateCreationDate(key.creationDate) : '-'}</td>
                                                                        <td className="action-td"><a href="#" onClick={(e) => this.resetPWDLink(key)}><i className="fa fa-user" ></i></a>
                                                                            {(key.lock != '' ? <a href="#" onClick={(e) => this.onOpenModal(key)}><i className="fa fa-lock" style={{ "margin-left": '5px' }}></i></a>
                                                                                : '')}
                                                                        </td> </tr>
                                                                }) :  <tr><td className="recordNotFound" colSpan="10">No record found</td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <ul className="pagination pull-right my-1">
                                                    <Pagination
                                                        hideDisabled
                                                        activePage={this.state.pageactive}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={this.state.filteredDataList.length}
                                                        pageRangeDisplayed={5}
                                                        firstPageText='First'
                                                        lastPageText='Last'
                                                        prevPageText='Prev'
                                                        nextPageText='Next'
                                                        onChange={this.handlePageChange.bind(this)}
                                                    />
                                                </ul>
                                            </div>
                                            <div id="xop"> </div>
                                            <PdfFormat
                                                data={dataToCsv}
                                                tableHeader={this.state.tableHeader}

                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customerList: state.customerList,
        resetCustomerPWDResponse: state.resetCustomerPWD
    };
}

export default connect(mapStateToProps, { customerListAction, customerResetPWDAction })(CustomerManagements);
