import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";
import ReactLoading from 'react-loading'
import "react-datepicker/dist/react-datepicker.css";
// import { getFAQListAction, deleteFAQAction, getFAQTotalRecordsAction } from '../../../Actions/Admin/faqManagementAction'
import * as language from '../../../Constants/translations';
import _ from 'lodash'

/** Class for displaying FAQ list*/
class CustomerLandingPage extends Component {
   constructor() {
      super();
      this.state = {

      };
   }

   render() {
      return (
         <div className="admin_top">
            {
               this.state.Loading === true && <div className="loader-react">
                  <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
               </div>
            }
            <div id="content-wrapper" className="d-flex flex-column">
               <Header />
               <div className="main">
                  <Navigation activeClassName={this.state.activeClassName} />
                  <div className="dashboardContent">
                     <div className="container-fluid">
                        <div className="user-dashboard user-management" id="user-dashboard">
                           <div className="container-fluid">
                              <div className="admin-header">
                                 <div className="row mar0">
                                    <div className="col-md-12">
                                       <h2>Customer Landing Page</h2>
                                       <div className="row">
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

const mapStateToProps = (state) => {
   return {
   }
}

export default connect(mapStateToProps)(CustomerLandingPage);