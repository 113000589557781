import React, { Component } from 'react';
import PolicyList from './policyListPage';

// Main class for PrivacyPolicy 
class PrivacyPolicy extends Component {

  
  componentDidMount(){ 
    try{
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.history.push("/ng-admin");
    }
    }catch (err){

    }
  }
  
  render() {
    let pathParam = this.props.location.pathname.split("/").pop()

    try{
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.history.push("/ng-admin");
    } else if(sessionStorage.hasOwnProperty("adminLoggedIn") && sessionStorage.isSuperAdmin != 'true') {
      if(sessionStorage.roleID != 3) {
        this.props.history.push("/ng-admin/error");
      }
    }
    }catch (err){

    }

    var PolicyPage;
    if (this.props.location.state == undefined) {
      PolicyPage = <PolicyList componentID={this.props} pathParam={pathParam} />
    }
    return (PolicyPage);
  }
}
export default PrivacyPolicy;
