import * as constants from './../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';

/*
* Function to get shippment list
  @param NULL
  @return object object of api resoponse
*/
export function shippmentListAction(userId) {
    return (dispatch) => {
        axios.post(serverRoutes.SHIPPMENT_LIST, { userId })
            .then(function (response) {
                dispatch({
                    type: constants.SHIPPMENT_MANAGEMENT_DATA,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.SHIPPMENT_MANAGEMENT_DATA_ERROR,
                    payload: response.data
                })
            })
    }
}

/*
* Function to add shippment
  @param NULL
  @return object object of api resoponse
*/
export function addShippmentAction(data) {
    
    return (dispatch) => {
        var shippingTypeId = Number(data.shippingData[0].shippingTypeId)
        //when shippment type is other 
        if (isNaN(shippingTypeId)) {
            let createData = {
                language: "en",
                loggedInUser: data.loggedInUser,
                shippingData: [data.shippingData[0]]
            }

            console.log(createData);
            axios.post(serverRoutes.ADD_SHIPPMENT, createData)
                .then(function (createResponse) {
                    //other type is more than 1 start
                    if (data.shippingData.length > 1) {
                        var id = createResponse.data.data.data[0].shippingTypeId
                        var shippingData = data.shippingData.slice(1)
                        var updatedArray = []
                        let json = {}
                        shippingData.map((item) => {
                            json = {
                                "description": item.description,
                                "endRange": item.endRange,
                                "endTime": item.endTime,
                                "language": item.language,
                                "loggedInUser": item.loggedInUser,
                                "shippingRate": item.shippingRate,
                                "shippingTypeId": +id,
                                "startRange": item.startRange,
                                "startTime": item.startTime,
                                "status": item.status,
                                "userId": item.userId
                            }
                            updatedArray.push(json)

                        })

                        if (updatedArray.length) {
                            let restData = {
                                language: "en",
                                loggedInUser: data.loggedInUser,
                                shippingData: updatedArray
                            }

                            axios.post(serverRoutes.ADD_SHIPPMENT, restData)
                                .then(function (response) {
                                    dispatch({
                                        type: constants.ADD_SHIPPMENT,
                                        payload: response.data
                                    })
                                })
                                .catch(function (response) {
                                    dispatch({
                                        type: constants.ADD_SHIPPMENT_ERROR,
                                        payload: response.data
                                    })
                                })
                        }
                    }
                    //other type is more than 1 end
                    else {

                        dispatch({
                            type: constants.ADD_SHIPPMENT,
                            payload: createResponse.data
                        })

                    }

                })
        } else {
            axios.post(serverRoutes.ADD_SHIPPMENT, data)
                .then(function (response) {
                    dispatch({
                        type: constants.ADD_SHIPPMENT,
                        payload: response.data
                    })
                })
                .catch(function (response) {
                    dispatch({
                        type: constants.ADD_SHIPPMENT_ERROR,
                        payload: response.data
                    })
                })
        }
    }
}


export function removePropsAction() {
    return (dispatch) => {
        dispatch({
            type: constants.REMOVE_SHIPPMENT,
            payload:{}
        })
    }
}


/*
* Function to add shippment
  @param NULL
  @return object object of api resoponse
*/
export function editShippmentAction(data) {
    return (dispatch) => {
        axios.post(serverRoutes.EDIT_SHIPPMENT, data)
            .then(function (response) {
                dispatch({
                    type: constants.EDIT_SHIPPMENT,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.EDIT_SHIPPMENT_ERROR,
                    payload: response.data
                })
            })
    }
}


/*
* Function to delete shippment
  @param NULL
  @return object object of api resoponse
*/
export function deleteShippmentAction(data) {
    return (dispatch) => {
        axios.post(serverRoutes.DELETE_SHIPPMENT, data)
            .then(function (response) {
                dispatch({
                    type: constants.DELETE_SHIPPMENT,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.DELETE_SHIPPMENT_ERROR,
                    payload: response.data
                })
            })
    }
}

/*
* Function to get shippment type 
  @param NULL
  @return object object of api resoponse
*/
export function shippmentTypeAction(data) {
    return (dispatch) => {
        axios.post(serverRoutes.SHIPPMENT_TYPES, data)
            .then(function (response) {
                dispatch({
                    type: constants.SHIPPMENT_TYPE,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.SHIPPMENT_TYPE_ERROR,
                    payload: response.data
                })
            })
    }
}




