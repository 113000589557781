import * as constants from './../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';

/*
* Function for userManagementList and get users list
  @param NULL
  @return object object of api resoponse
*/
export function customerListAction(userId) {
    return (dispatch) => {
        axios.post(serverRoutes.GET_CUSTOMER_LIST, { userId })
            .then(function (response) {
                dispatch({
                    type: constants.CUSTOMER_MANAGEMENT_LIST_DATA,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.CUSTOMER_MANAGEMENT_LIST_DATA_ERROR,
                    payload: response.data
                })
            })
    }
}

/**
 * Function for unlock user account
 * @param {id} of user
 * @return {object} object of API response
 */
export function unLockAccountAction(userId) {
    return (dispatch) => {
        axios.post(serverRoutes.UNLOCK_USER_ACCOUNT, userId)
            .then(function (res) {
                dispatch({
                    type: constants.CUSTOMER_UNLOCK_ACCOUNT,
                    payload: res.data
                })
            })
            .catch(function (res) {
                dispatch({
                    type: constants.CUSTOMER_UNLOCK_ACCOUNT_ERROR,
                    payload: res.data
                })
            })
    }
}

/**
 * function for customer reset password
 * @param object customerData
 * @return object object of api response
*/
export function customerResetPWDAction(customerData) {
    return (dispatch) => {
        axios.post(serverRoutes.CUSTOMER_RESET_PASSWORD, customerData)
            .then(function (response) {
                dispatch({
                    type: constants.CUSTOMER_RESET_PASSWORD,
                    payload: response.data.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.CUSTOMER_RESET_PASSWORD_ERROR,
                    payload: response.data
                })
            });
    }
}

/**
 * function for update customer details
 * @param object customerData
 * @return object object of api response
*/
export function updateCustomerDetailsAction(customerData) {
    return (dispatch) => {
        axios.post(serverRoutes.UPDATE_CUSTOMER_DETAILS, customerData)
            .then(function (response) {
                dispatch({
                    type: constants.UPDATE_CUSTOMER_DETAILS,
                    payload: response.data.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.UPDATE_CUSTOMER_DETAILS_ERROR,
                    payload: response.data
                })
            });
    }
}
