import * as constants from '../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';
/**
 * function for userLog for manage action and pass into reducer
 * @param object userdata
 * @return object object of api response
*/
export function userLog(userdata) {
    return (dispatch) => {
        axios.post(serverRoutes.USER_LOGIN,{userdata})
        .then(function(response){ 
          dispatch({
            type: constants.LOGIN_DATA,
            payload: response.data.data
          })
        })
        .catch(function(response){
          dispatch({
            type: constants.LOGIN_DATA_ERROR,
            payload: response
          })
        });
    }
}
