import React, { Component } from 'react';
import { connect } from 'react-redux';
import { denominationListAction, getCardTypeAction } from '../../../Actions/Admin/denominationManagementAction';
import { customerListAction, customerResetPWDAction } from '../../../Actions/Admin/customerManagementAction';
import { customerRegisterAction, customerLoginAction, sendOTPAction, searchUserAction } from '../../../Actions/Customer/CustomerLoginAction';
import { discountListAction } from './../../../Actions/Admin/discountManagementAction'
import * as constant from '../../../Constants/index';
import _ from 'lodash';
import * as HelperUtil from "../../../utils/common-helper"
import noImage from '../../../public/img/noImage-denominationList.png';
import signout from '../../../public/img/signout.png';
import userIcon from '../../../public/img/user-icon.png';
import CustomerLogo from '../../../public/img/CustomerLogo.png';
import profile from '../../../public/img/profile.png';
import InputMask from 'react-input-mask';
import carticon from '../../../public/img/cart-icon.png';
import faqicon from '../../../public/img/faq.png';
import cart from '../../../public/img/acc-order-history-active.png';
import * as language from '../../../Constants/translations';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal'
import successimg from '../../../public/img/success.png';
import errorimg from '../../../public/img/error.png';
import base64 from 'base-64'
import * as constants from '../../../Constants';
import Discount from './../Discount'
import moment from 'moment'
import { NORTHGATE_URL } from './../../../Constants/index'
import Consent from '../Home/consent'
// Class for displaying card selection, price quantity, mini cart icon etc .
class OrderManagements extends Component {

    constructor() {
        super();
        this.state = {
            denominationList: '',
            sideBarPanelCartDetails: false,
            cartProductObjectList: [],
            cartProductListIndex: [],
            cardTypeList: [],
            dropdownGiftCard: '',
            allDenominationListDetails: '',
            active: 'all',
            giftCardDropdownName: 'data',
            flashMessageError: false,
            lang: sessionStorage.language !== undefined ? sessionStorage.language === 'spa' ?  'spa':   'en'  : 'en',
            productQuantity: 0,
            denominationGiftCard: [],
            fontSize: {
                header1: 72,
                header2: 24,
                giftCardButton: 21,
                loginSignup: 12,
                language: 10,
                a: 16,
                breadcrumb: 13,
                giftCardText: 15,
                tabs: 14,
                cardAmount: 20.8,
                sideBarText: 18,
                discountText:13,
            },
            increaseCounter: 0,
            decreaseCounter: 0,
            Loading: false,
            holidayOption: false,
            tempCartList: [],
            denominationPrice: [],
            price: [],
            productCartList: [],
            loginModalopen: false,
            regiModalOpen: false,

            modalIsOpen: false,
            signupModalIsOpen: false,
            regsFirstNameErrorSpan: "",
            regsLastNameErrorSpan: "",
            regsCompanyNameErrorSpan: "",
            regsEmailErrorSpan: "",
            regsPhoneErrorSpan: "",
            regsPasswordErrorSpan: "",
            regsConfirmPasswordErrorSpan: "",
            check: 1,
            validObj: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                companyName: "",
                phone: "",
                confirmPassword: ""
            },
            emailErrorSpan: "",
            passwordErrorSpan: "",
            forgetModalopen: false,
            loginStateUpdate: false,
            isFieldsEmpty: false,
            modalForget: false,
            policyModalopen: false,
            forgetvalidObj: {
                email: ""
            },
            forgetinvalidObj: {
                email: ""
            },
            nameInputMask: 'aaaaaaaaaaaaaaa',
            customerList: [],
            checkUpdateState: false,
            isInputFieldEmpty: false,
            newsLetterModalopen: false,
            newsLetterStatus: true,
            discountList: [],
            activeDiscount: [],
            currentDate: moment(new Date()).format('MM/DD/YYYY'),
            increasemove:4,
            decreasemove:4,
            maxQtyNote:false,

        }
        // this.breadCrumbCLick = this.breadCrumbCLick.bind(this);
        this.addTempCartProduct = this.addTempCartProduct.bind(this);
        this.isEnabledAddCart = this.isEnabledAddCart.bind(this);
        this.addToCart = this.addToCart.bind(this);

    };

    /**
     * Function for get card type details.
    */
    async componentWillMount() {
        try {
            this.setState({ Loading: true, holidayOption: true })
            await this.props.getCardTypeAction();
            await this.props.denominationListAction();
            await this.props.customerListAction(sessionStorage.userID)
            if (localStorage.getItem("productCardDetailsData") !== null) {
                this.setState({ cartProductObjectList: JSON.parse(localStorage.getItem("productCardDetailsData")), productCartList: JSON.parse(localStorage.getItem("productCardDetailsData")) })
            }
            if (localStorage.getItem("denominationPriceData") !== null) {
                var denominationList = []
                var local = localStorage.getItem("denominationPriceData")
                local = local.split(',')
                local.map((data) => {
                    if (data)
                        denominationList.push(data)
                    else
                        denominationList.push(constant.DEFAULT_DENOMINATION_PRICE_DATA)
                })
                this.setState({ denominationPrice: denominationList })
            }
            this.setState({lang:sessionStorage.language})
        
        } catch (e) { }
    }

    /**
     * Handle dropdown list 
     */
    handleDropdownList(event, flag) {
        try {
            let x = document.getElementsByClassName("Holidays")[0]
            x.style.disaplay = "none"
        } catch (err) { }
        if (event) {
            this.setState({
                holidayOption: flag
            })
        } else {
            this.setState({
                holidayOption: false
            })
        }
    }

    /**
    * Function for create gift card type radio button and dropdown
    * @param null
    * @return null
    */
    childGiftcard() {
        let hideOption = {
            description: "Holidays",
            id: undefined,
            subDenom: [],
            name: "Holidays"
        }
        let giftCardStorage = [];
        let cardTypeArray = this.state.cardTypeList;
        const { fontSize } = this.state
        cardTypeArray.map((result, i) => {
            giftCardStorage.push(
                (result.subDenom.length > 0) ?
                    <li className={this.state.active == 0 ? 'active' : ''}
                        key={`childGiftcard${i}`}
                    ><select title="name" style={{ fontSize: fontSize.tabs + 'px' }} name={this.state.giftCardDropdownName} id={result.id} className='form-check'
                        onMouseEnter={this.handleDropdownList.bind(this)}
                        onChange={this.setDenominationListByFilter.bind(this)}
                    >
                            {
                                result.subDenom[0].id !== undefined ? result.subDenom.unshift(hideOption) : void 0
                            }
                            {
                                this.state.holidayOption && this.state.active !== 0 ? <option style={{
                                    fontSize: fontSize.tabs + 'px'
                                }
                                }
                                >{result.name}</option> :
                                    result.subDenom.map((data, i) => {
                                        return <option
                                                key={`subDenom${i}`}
                                            className={data.id === undefined ? data.name : ""}

                                            style={{ fontSize: fontSize.tabs + 'px', display: data.id === undefined ? "none" : "block" }} value={data.id} selected={(sessionStorage.cardType == result.id) ? data.id == constant.HAPPY_HOLIDAY_TYPE_ID : ''}>
                                            {data.name}
                                        </option>
                                    })
                            }
                        </select></li> :
                    <li key={`childGiftcard${i}`}  style={{ fontSize: fontSize.tabs + 'px' }} className={this.state.active == result.id ? 'active' : ''} id={result.id} onClick={(e) => this.setDenominationListByFilter(result.id, e)}>{result.name}</li>)
        });
        return giftCardStorage;
    }

    /**
         * Function for set denomination list by filter of gift card type
         * @param {Integer} giftCardTypeId
         * @return null
         */
    async setDenominationListByFilter(giftCardTypeId, e) {
        try {
            document.getElementsByClassName("Holidays")[0].style.disaplay = "none"
        } catch (err) { }
        if (e !== undefined) {
            sessionStorage.setItem("cardType", "all")
            this.setState({ holidayOption: true })
        }
        let { cardTypeList } = this.state
        let denominationListData = [];
        denominationListData = this.state.allDenominationListDetails;
        let arrayStorage = [];
        if (typeof giftCardTypeId === 'object') {
            giftCardTypeId = giftCardTypeId.target.value;
            this.setState({ active: 0 })
        } else {
            cardTypeList.length ? cardTypeList.map((cards) => {
                if (cards.id == giftCardTypeId && cards.subDenom.length > 0) {
                    giftCardTypeId = constant.HAPPY_HOLIDAY_TYPE_ID;
                    this.setState({ active: 0 });
                } else {
                    this.setState({ active: giftCardTypeId });
                }
            }) : this.setState({ active: giftCardTypeId });
        }
        if (denominationListData.length > 0) {
            denominationListData.map(function (value, index) {
                if (value.typeId == giftCardTypeId) {
                    arrayStorage.push(value);
                }
            })
        }
        await this.setState({ denominationList: arrayStorage, giftCardDropdownName: '' });
    }

    /**
     * Function for set denomination list in all span
     * @param  null
     * @return null
    */
    setAllDenominationList() {
        sessionStorage.setItem('cardType', 'all')
        this.setState({ holidayOption: true })
        let denominationListData = [];
        denominationListData = this.state.allDenominationListDetails;
        this.setState({ denominationList: denominationListData, active: 'all', giftCardDropdownName: '' });
    }

    /**
    * Function for get denomination list
    */
    async componentDidMount() {
        try {
            if (this.props.searchAndCreateUser.data !== null && this.state.checkUpdateState === true) {
                this.props.searchAndCreateUser.data = null
            }
            // if (this.props.customerLoginInfo.data !== null) {
            //     this.props.customerLoginInfo.data = null
            // }
        } catch (e) { }
        await this.props.denominationListAction();
        if (sessionStorage.cardType == undefined) {
            this.setState({
                active: 'all'
            })
            sessionStorage.setItem('cardType', 'all')
        }
        else if (sessionStorage.cardType != undefined && sessionStorage.cardType != '' && sessionStorage.cardType != 55) {
            this.setState({
                active: sessionStorage.cardType
            })
        }
        else if (sessionStorage.cardType == 55) {
            this.setState({
                active: 0
            })
        }
        else if (sessionStorage.cardType == '') {
            this.setState({
                active: 'all'
            })
        }
        this.setDenominationListByFilter(sessionStorage.cardType)

    }
    componentWillReceiveProps(nextProps) {
        var lang = this.state.lang
        try {
            this.setState({ Loading: false })
            if ((nextProps) && nextProps.denominations) {
                var self = this
                if ((nextProps.denominations) && nextProps.denominations.data) {
                    var denominationList = nextProps.denominations.data.data.data;
                    denominationList.map(data => data.productQuantity = 0)

                    self.setState({
                        denominationList: denominationList,
                        allDenominationListDetails: denominationList,

                    });
                    self.state.denominationPrice.length = nextProps.denominations.data.data.data.length
                    if (sessionStorage.cardType != 55 && sessionStorage.cardType != 'all') {
                        self.setDenominationListByFilter(sessionStorage.cardType)
                    }
                    else if (sessionStorage.cardType == 55) {
                        self.setDenominationListByFilter(sessionStorage.cardType)

                    }
                    else if (sessionStorage.cardType == 'all') {

                        self.setState({ denominationList: nextProps.denominations.data.data.data, active: 'all', giftCardDropdownName: '' });
                    }
                    self.setState({ Loading: false })
                } else {
                    self.setState({ Loading: false })
                }
            }
            if ((nextProps) && (nextProps.cardTypeListData)) {
                if (nextProps.cardTypeListData.data && nextProps.cardTypeListData.data.data) {
                    this.setState({
                        cardTypeList: nextProps.cardTypeListData.data.data.data
                    })
                }
            }

            if (nextProps.customerLoginInfo && nextProps.customerLoginInfo.data) {
                if (nextProps.customerLoginInfo.data.status) {
                    localStorage.removeItem('guestUserDetails')
                    sessionStorage.loggedIn = true;
                    sessionStorage.customerName = nextProps.customerLoginInfo.data.data.firstName + " " + nextProps.customerLoginInfo.data.data.lastName;
                    sessionStorage.userID = nextProps.customerLoginInfo.data.data.id;
                    sessionStorage.emailID = nextProps.customerLoginInfo.data.data.email
                    if (nextProps.customerLoginInfo.data.data.addresses.length > 0) {
                        sessionStorage.cardType = 'all'
                        var Data = []
                        Data = JSON.parse(localStorage.getItem('productCardDetailsData'))

                        if (self.state.loginStateUpdate == true) {
                            self.closeModal()
                            setTimeout(async function () {
                                self.setState({
                                    loginStateUpdate: false
                                })
                                await self.setState({ Loading: false })

                            }, 5000)

                        }


                    }
                    else {
                        if (self.state.loginStateUpdate == true) {
                            self.setState({ loginStateUpdate: false })
                            self.props.orderManagementProps.history.push("/dashboard");
                        }
                        
                    }
                } else {
                    self.setState({
                        passwordErrorSpan: nextProps.customerLoginInfo.data.message,
                        Loading: false
                    })
                }
            }
            if (nextProps.resetCustomerPWDResponse && nextProps.resetCustomerPWDResponse.data) {
                if (nextProps.resetCustomerPWDResponse.data.hasOwnProperty('status') && (nextProps.resetCustomerPWDResponse.data.status === true)) {
                    self.setState({ flashMessageError: '', flashMessageSuccess: language.language.reset_password_link[0][this.state.lang] })
                    setInterval(function () {
                        let redirect = window.location.href.substring(window.location.href.lastIndexOf('?'), 0)
                        window.location = redirect;
                    }, 5000);
                } else {
                    self.setState({ flashMessageError: nextProps.resetCustomerPWDResponse.data.message })
                }
            }

            if (nextProps.customerList && nextProps.customerList.data) {
                if (nextProps.customerList.data.data && nextProps.customerList.data.data.data != null) {
                    self.setState({
                        customerList: nextProps.customerList.data.data.data,
                    })

                }
            }


            if (nextProps.searchAndCreateUser && nextProps.searchAndCreateUser.data) {
                try {
                    if (nextProps.searchAndCreateUser.data.hasOwnProperty('status') && nextProps.searchAndCreateUser.data.status === true) {
                        self.setState({
                            flashMessageError: '',
                            flashMessageSuccess: language.language.activation_link[0][lang],
                        })
                        if (this.state.checkUpdateState === true) {
                            setTimeout(async function () {
                                self.setState({
                                    Loading: false,
                                    flashMessageSuccess: '',
                                    flashMessageError: '',
                                    checkUpdateState: false
                                })
                                await self.closeSignUpModal()
                                // window.location.reload();
                                // self.props.modalClose();
                                // redirect to /home

                                try {
                                    if (nextProps.locationProps.history != undefined) {
                                        self.props.locationProps.history.push("/")
                                    }
                                    else if (nextProps.locationProps.orderManagementProps != undefined) {
                                        self.props.locationProps.orderManagementProps.history.push("/")
                                    } else if (nextProps.locationProps.locationProps != undefined) {
                                        if (nextProps.locationProps.locationProps.cardListProps != undefined) {
                                            self.props.locationProps.locationProps.cardListProps.history.push("/")
                                        }
                                        else if (nextProps.locationProps.locationProps.shipmentPageProps != undefined) {
                                            self.props.locationProps.locationProps.shipmentPageProps.history.push("/")
                                        } else if (nextProps.locationProps.locationProps.paymentPageProps != undefined) {
                                            self.props.locationProps.locationProps.paymentPageProps.history.push("/")
                                        }
                                    }
                                } catch (e) { }
                                // self.props.locationProps("/");

                            }, 5000);
                        } else {
                            self.setState({
                                Loading: false,
                                flashMessageSuccess: '',
                                flashMessageError: '',
                                checkUpdateState: false
                            })
                        }
                    } else {
                        self.setState({ flashMessageError: JSON.stringify(nextProps.searchAndCreateUser.data.message), Loading: false })
                    }
                } catch (err) {

                }

            }

            if (nextProps.hasOwnProperty('discountList') && nextProps.discountList.hasOwnProperty('data')) {
                if (nextProps.discountList.data.error !== true) {
                    let discountList = nextProps.discountList.data.data.data
                    let list = []
                    let activeDiscount = []

                    discountList.length ? discountList.map((discount) => {
                        let a, b, c;
                        if (discount != null && discount.validFrom != null && discount.validFrom != null) {
                            a = moment(discount.validFrom).format('MM/DD/YYYY')
                            b = moment(discount.validTill).format('MM/DD/YYYY')
                            c = moment(new Date()).format('MM/DD/YYYY')
                            if ((moment(c).isBetween(a,b) || c === a || c === b) && discount.status === true) {
                                list.push(discount)

                            }

                        }
                    }) : void 0

                    discountList = list
                    if (discountList != null && discountList != '') {
                        if (discountList.length) {
                            discountList.map((list) => {
                                if (list.status == true) {
                                    activeDiscount.push(list)
                                }
                            })
                        }
                    }
                    this.setState({

                        activeDiscount: discountList
                    })
                }
            }
        } catch (e) { }
    }


    //Metho to logout 
    logOut() {
        try {
            sessionStorage.userID = ''
            sessionStorage.loggedIn = ''
            sessionStorage.language = 'en'
            sessionStorage.removeItem('FromPaymentPage')
            sessionStorage.removeItem('emailID')
            sessionStorage.removeItem('cardType')
            sessionStorage.removeItem('customerName')
            sessionStorage.removeItem('productObjectSession')
            sessionStorage.userType = ''
            localStorage.removeItem('productCardDetailsLength');
            localStorage.removeItem('productCardDetailsData');
            localStorage.removeItem('denominationPriceData');
            localStorage.removeItem('UserOtherAddress')
            sessionStorage.removeItem('isVerifyAddress')
        } catch(e) {console.log("error",e)}
        
        try {
            window.location.href = "/"
        } catch (err) { }
    }

    /**
     * function for call create gift card dropdown
     * 
    */
    componentDidUpdate() {
        this.childGiftcard();
    }

    /**
    * Function for create array for add product in to cart
    * @param {object} productData product details
    * @return object
    */

    // Method to add the card to the list 
    addToCart(productData, index) {
        try {
            document.getElementById("productSideBar").style.width = "100%";
            if (productData.productQuantity) {
                var productCartList = this.state.productCartList;
                var denominationList = this.state.denominationList;
                var result = _.filter(productCartList, function (cart, index) {
                    if (cart.price == productData.price && cart.variantId == productData.variantId) {
                        cart.cartIndex = index;
                        return cart
                    }
                });
                var data = JSON.parse(JSON.stringify(productData));
                var totalQuantity = 0;
                var productQuantityPrice = 0;
                if (result.length) {

                    var alreadyAddedCart = result[0];
                    var cartIndex = alreadyAddedCart.cartIndex;
                    let maxQty = parseInt(data.productQuantity) + parseInt(alreadyAddedCart.productQuantity) 


                    if(maxQty>999) {
                        this.setState({maxQtyNote:true })
                        if(document.getElementById(`totalCardsideBar_${index}`)!==null) {
                             document.getElementById(`totalCardsideBar_${index}`).style = "border: red solid 1px";
                        }
                       
                    }
                    try {
                        setTimeout(()=>{
                            if(document.getElementById(`totalCardsideBar_${index}`) !== null) {
                                document.getElementById(`totalCardsideBar_${index}`).style = "border: #000 solid 0 px";
                            }
                            
                        },4000)
                    } catch(e) {console.log("shipping error",e)}
                    
            
                    totalQuantity =(parseInt(data.productQuantity) + parseInt(alreadyAddedCart.productQuantity)) <= 999 ? ( parseInt(data.productQuantity) + parseInt(alreadyAddedCart.productQuantity)) :999
                    productQuantityPrice = totalQuantity * productData.price;
                    productData.productQuantityPrice = productQuantityPrice;
                    productData.productQuantity = totalQuantity;
                    productCartList[cartIndex] = productData;
                
                } else {
                    productQuantityPrice = productData.price * productData.productQuantity;
                    productData.productQuantityPrice = productQuantityPrice;
                    productCartList.push(productData);
                }
                data.price = 5;
                data.productQuantity = 0;
                denominationList[index] = data;
                localStorage.setItem("productCardDetailsData", JSON.stringify(productCartList));
                this.setState({
                    productCartList: productCartList,
                    denominationList: denominationList,
                    sideBarPanelCartDetails: true
                })
            }
        } catch(e) {console.log("err",e)}
      
    }

    /**
    * Function for increment gift card product in cart
    * @param {object} productData object of product
    * @return object
    */
    incrementGiftCard(productData, index) {
        if (productData.productQuantity < 999) {
            var quantity = productData.productQuantity;
            quantity++;
            var productCartList = this.state.productCartList;
            var updatePrice = productData.price * quantity;
            productData.productQuantity = quantity;
            productData.productQuantityPrice = updatePrice;
            productCartList[index] = productData
            localStorage.setItem("productCardDetailsData", JSON.stringify(productCartList));
            this.setState({
                productCartList: productCartList
            })
        }
    }

    /**
     * Function for increment product quantity form gift card list
     * @param {*} event 
     * @param {*} productData 
     * @param {*} index 
     */
    incrementProductQuantity(productData, index) {
        var denominationList = this.state.denominationList;
        var quantity = productData.productQuantity;
        quantity++;
        productData.productQuantity = quantity;
        denominationList[index] = productData;

        this.setState({
            denominationList: denominationList
        })
    }

    //Method to add card range 
    addTempCartProduct(productData, price) {
        var tempCartList = this.state.tempCartList;
        var result = _.filter(tempCartList, function (o, index) {
            if (productData.variantId == o.variantId) {
                o.keyIndex = index;
                return o;
            }
        });
        if (result.length) {
            var productData = result[0];
            productData.productQuantity += 1;
            var keyIndex = productData.keyIndex;
            tempCartList[keyIndex] = productData;
            this.setState({
                tempCartList: tempCartList
            })
        }
        else {
            productData.productQuantity = 1;
            tempCartList.push(productData);
            this.setState({
                tempCartList: tempCartList
            })
        }
    }

    //Method to add gift card quantity 
    addTempCartProductEvent(productData, value) {
        var tempCartList = this.state.tempCartList;
        var result = _.filter(tempCartList, function (o, index) {
            if (productData.variantId == o.variantId) {
                o.keyIndex = index;
                return o;
            }
        });
        if (result.length) {
            var productData = result[0];
            if (value) {
                productData.productQuantity = value;
            }
            else {
                productData.productQuantity = 0;
            }
            let updateProductQuantityPrice = productData.price * productData.productQuantity;
            var insertProductQuantityPrice = { productQuantity: productData.productQuantity, productQuantityPrice: updateProductQuantityPrice };
            Object.assign(productData, insertProductQuantityPrice);
            var keyIndex = productData.keyIndex;
            tempCartList[keyIndex] = productData;
            this.setState({
                tempCartList: tempCartList
            })
        }
        else {
            if (value) {
                productData.productQuantity = value;
            }
            else {
                productData.productQuantity = 0;
            }
            let updateProductQuantityPrice = productData.price * productData.productQuantity;
            var insertProductQuantityPrice = { productQuantity: productData.productQuantity, productQuantityPrice: updateProductQuantityPrice };
            Object.assign(productData, insertProductQuantityPrice);
            tempCartList.push(productData);
            this.setState({
                tempCartList: tempCartList
            })
        }
    }

    //Method to enable Add button on gift card in gift card list 
    isEnabledAddCart(productData) {
        var tempCartList = this.state.tempCartList;
        var result = _.filter(tempCartList, function (o, index) {
            if (productData.variantId == o.variantId) {
                o.keyIndex = index;
                return o;
            }
        });
        if (result.length) {
            if (result[0].productQuantity != 0) {
                return "enable-cart-span";
            }
            else {
                return "disable-cart-span addInit";
            }
        }
        else {
            return "disable-cart-span addInit";
        }
    }

    /**
       * Function for decrement product quantity form gift card list
       * @param {*} event 
       * @param {*} productData 
       */
    decrementProductQuantity(productData) {
        var denominationList = this.state.denominationList;
        var quantity = productData.productQuantity;
        if (quantity) {
            quantity--;
        }
        productData.productQuantity = quantity;
        this.setState({
            denominationList: denominationList
        })
    }

    //Method used for decrement gift card range from gift card list 
    deleteTempCartProduct(productData) {
        var tempCartList = this.state.tempCartList;
        let cartProductData = this.state.cartProductObjectList;
        var result = _.filter(tempCartList, function (o, index) {
            if (productData.variantId == o.variantId) {
                o.keyIndex = index;
                return o;
            }
        });
        if (result.length) {
            var productData = result[0];
            if (productData.productQuantity <= 1) {
                productData.productQuantity = 0;
                let updateProductQuantityPrice = productData.price * productData.productQuantity;
                var insertProductQuantityPrice = { productQuantity: productData.productQuantity, productQuantityPrice: updateProductQuantityPrice };
                Object.assign(productData, insertProductQuantityPrice);
                var keyIndex = productData.keyIndex;
                tempCartList[keyIndex] = '';
                this.setState({
                    tempCartList: tempCartList
                })
            } else {
                productData.productQuantity -= 1;
                let updateProductQuantityPrice = productData.price * productData.productQuantity;
                var insertProductQuantityPrice = { productQuantity: productData.productQuantity, productQuantityPrice: updateProductQuantityPrice };
                Object.assign(productData, insertProductQuantityPrice);
                var keyIndex = productData.keyIndex;
                tempCartList[keyIndex] = productData;
                this.setState({
                    tempCartList: tempCartList
                })
            }
        }
        else {
            productData.productQuantity = 0;
            let updateProductQuantityPrice = productData.price * productData.productQuantity;
            var insertProductQuantityPrice = { productQuantity: productData.productQuantity, productQuantityPrice: updateProductQuantityPrice };
            Object.assign(productData, insertProductQuantityPrice);
            tempCartList.push(productData);
            this.setState({
                tempCartList: tempCartList
            })
        }
        localStorage.setItem("productCardDetailsData", JSON.stringify(cartProductData));
    }


    /**
    * Function for decrement and remove gift card product in cart
    * @param {object} productData, object of product, arrayIndex here array index use for * identity index of
    * array for remove in to product array.
    * @return object
    */
    decrementGiftCard(productData, arrayIndex) {
        try {
            var productCartList = this.state.productCartList;
            var sideBarPanelCartDetails = true;
            if (productData.productQuantity > 1) {
                var quantity = productData.productQuantity;
                quantity--;
                var updatePrice = productData.price * quantity;
                productData.productQuantity = quantity;
                productData.productQuantityPrice = updatePrice;
                productCartList[arrayIndex] = productData
                localStorage.setItem("productCardDetailsData", JSON.stringify(productCartList));
            } else {
                productCartList.splice(arrayIndex, 1);
                localStorage.setItem("productCardDetailsData", JSON.stringify(productCartList));
                if (productCartList.length == 0) {
                    if(  document.getElementById("productSideBar")!==null) {
                        document.getElementById("productSideBar").style.width = "0";
                    }
                    
                    sideBarPanelCartDetails = false;
                }
            }
            this.setState({
                productCartList: productCartList,
                sideBarPanelCartDetails: sideBarPanelCartDetails
            })
        } catch(e) { console.log("test decrementGiftCard")}
        
    }

    /**
     * Function for set isEnabledAddCart
     * @param {*} event isEnabledAddCart
     * @param {*} producisEnabledAddCart
     * @param {*} index isEnabledAddCart
     */
    setProductQuantity(event, productData, index) {
        var denominationList = this.state.denominationList;
        var quantity = event.target.value;
        productData.productQuantity = quantity;
        denominationList[index] = productData;
        this.setState({
            denominationList: denominationList
        })
    }

    //Method to set the gift card range manually in my cart 
    setQuantity(event, productData, index) {
        var denominationList = this.state.denominationList;
        var quantity = (event.target.value && parseInt(event.target.value)) ? event.target.value : 0;
        productData.productQuantity = quantity;
        denominationList[index] = productData;
        this.setState({
            denominationList: denominationList
        })
    }


    //Method to get the gift card range quantity 
    getProductQuantity(product) {
        var tempCartList = this.state.tempCartList;
        var result = _.filter(tempCartList, function (o, index) {
            if (product.variantId == o.variantId) {
                return o;
            }
        });
        if (result.length) {
            return result[0].productQuantity;
        } else {
            return 0;
        }
    }

    /**
     * Method to get the gift card price
     * @param {*} index 
     */
    getDenominationPrice(index) {
        var denominationPrice = this.state.denominationPrice
        var price = 5
        if (denominationPrice[index] !== undefined)
            return denominationPrice[index]
        else
            return price;
    }

    //Method to delete the gift card index from my cart 
    deleteCartProductIndex(product) {
        var variantId = product.variantId
        var cartProductListIndex = this.state.cartProductListIndex;
        var index = cartProductListIndex.indexOf(variantId);
        if (index > -1) {
            cartProductListIndex.splice(index, 1);
        }
    }

    /**
    * Function close side bar
    * @param
    * @return
    */
    sideBarClose() {
        try {
            if(  document.getElementById("productSideBar")!==null) {
                  document.getElementById("productSideBar").style.width = "0";
            }
           
            {
                this.setState({ sideBarPanelCartDetails: false });
            }
        } catch(e) {}
       
    }

    //Method to dispaly side bar 
    displaySidebar() {
        try {
            if(document.getElementById("productSideBar")!==null) {
                document.getElementById("productSideBar").style.width = "100%";
            }
                 

        } catch(e) {}
    }

    // Function to open cart list 
    showCart() {
        try {
            if (this.state.sideBarPanelCartDetails) {
                this.setState({
                    sideBarPanelCartDetails: false
                })
                if( document.getElementById("productSideBar")!==null) {
                    document.getElementById("productSideBar").style.width = "0%";
                }
               
            }
            else {
                this.setState({
                    sideBarPanelCartDetails: true
                })
                if(document.getElementById("productSideBar")!==null) {
                    document.getElementById("productSideBar").style.width = "100%";
                }
                
            }
        } catch(e) {}
      
    }

    /* function to redirect user on card selected list page */
    redirectToNextPage() {
        let cartProdeuctList = JSON.parse(localStorage.getItem("productCardDetailsData"))
        if (cartProdeuctList.length > 0) {
            this.props.orderManagementProps.history.push({
                pathname: "/ordermanagement", state: {
                    data: cartProdeuctList,
                    pageType: 'cartDetails'
                }
            })
        }
    }

    //Method to open my cart
    openCart() {
        this.props.orderManagementProps.history.push({
            pathname: "/orderDetailsManagements", state: {
                data: this.state.cartProductObjectList,
                pageType: 'cartDetails'
            }
        })
    }

    //Method to select the sapnish language 
    activateSpanishLanguage() {
        sessionStorage.language = 'spa'
        this.setState({
            lang: 'spa'
        })

    }

    //Method to select the english language 
    activateEnglishLanguage() {
        sessionStorage.language = 'en'
        this.setState({
            lang: 'en'
        })
    }

    // Method to set the size of the page items 
    changeSize(data) {
        if (data === 'increase') {
            let increaseCounter = this.state.increasemove
            let decreaseCounter = this.state.decreasemove
            if (increaseCounter >0) {
                decreaseCounter = decreaseCounter + 1
                increaseCounter = increaseCounter-1
                this.setState({
                    fontSize: {
                        header1: Number(this.state.fontSize.header1) + 1,
                        header2: Number(this.state.fontSize.header2) + 1,
                        giftCardButton: Number(this.state.fontSize.giftCardButton) + 1,
                        loginSignup: Number(this.state.fontSize.loginSignup) + 1,
                        language: Number(this.state.fontSize.language) + 1,
                        a: Number(this.state.fontSize.a) + 1,
                        breadcrumb: Number(this.state.fontSize.breadcrumb) + 1,
                        giftCardText: Number(this.state.fontSize.giftCardText) + 1,
                        giftCard: Number(this.state.fontSize.giftCard) + 1,
                        tabs: Number(this.state.fontSize.tabs) + 1,
                        addButtonText: Number(this.state.fontSize.addButtonText) + 1,
                        cardAmount: Number(this.state.fontSize.cardAmount) + 1,
                        sideBarText: Number(this.state.fontSize.sideBarText) + 1,
                        discountText: Number(this.state.fontSize.discountText) + 1,
                        
                    },
                    increasemove:increaseCounter,
                    decreasemove:decreaseCounter
                })
            }
        } 
        else if (data === 'decrease') {
            let increaseCounter = this.state.increasemove
            let decreaseCounter = this.state.decreasemove
            if (decreaseCounter > 0) {
                decreaseCounter = decreaseCounter - 1
                increaseCounter = increaseCounter+1
                this.setState({
                    fontSize: {
                        header1: Number(this.state.fontSize.header1) - 1,
                        header2: Number(this.state.fontSize.header2) - 1,
                        giftCardButton: Number(this.state.fontSize.giftCardButton) - 1,
                        loginSignup: Number(this.state.fontSize.loginSignup) - 1,
                        language: Number(this.state.fontSize.language) - 1,
                        a: Number(this.state.fontSize.a) - 1,
                        breadcrumb: Number(this.state.fontSize.breadcrumb) - 1,
                        giftCardText: Number(this.state.fontSize.giftCardText) - 1,
                        giftCard: Number(this.state.fontSize.giftCard) - 1,
                        tabs: Number(this.state.fontSize.tabs) - 1,
                        discountText: Number(this.state.fontSize.discountText) - 1,
                        addButtonText: Number(this.state.fontSize.addButtonText) - 1,
                        cardAmount: Number(this.state.fontSize.cardAmount) - 1,
                        sideBarText: Number(this.state.fontSize.sideBarText) - 1
                    },
                    increasemove:increaseCounter,
                    decreasemove:decreaseCounter
                })
            }
        }
        else if (data === 'default') {
            this.setState({
                fontSize: {
                    header1: 72,
                    header2: 24,
                    giftCardButton: 21,
                    loginSignup: 12,
                    language: 10,
                    a: 16,
                    breadcrumb: 13,
                    giftCardText: 15,
                    giftCard: 0,
                    tabs: 14,
                    addButtonText: 0,
                    cardAmount: 20.8,
                    sideBarText: 18,
                    discountText: 15,
                },
                increasemove: 4,
                decreasemove: 4
            })
        }
    }
    /***
     * Method for breadCrumbCLick
     * @param {event} 
     * @return {undefined}
     */
    breadCrumbCLick(event, bread = "") {
        if (bread !== "") {
            this.props.orderManagementProps.history.push("/")
        } else {

            window.location.href = constants.NORTHGATE_URL
        }


    }

    // Method to set the gift card price manually 
    setDenominationPrice(e, index, productData) {
        var priceInput = document.getElementsByClassName("price-input")
        for (let i = 0; i < priceInput.length; i++) {
            priceInput[i].addEventListener("keydown", (e) => {
                if ([46, 69, 110, 190, 187, 107, 109, 188, 189, 190].includes(e.keyCode)) {
                    e.preventDefault();
                }
            })
        }
        let value = +e.target.value
        try {
            let id = e.target.id
            if (id === "plus") {
                value += 5
                e.target.value = value
            }
            if (id === "minus" && value > 5) {
                value -= 5
                e.target.value = value
            }
        } catch (e) { }
        if (e.type == 'blur') {
            this.changePrice(value, index, productData)
        }
        else {
            if (value <= 500) {
                var denominationList = this.state.denominationList;
                productData.price = value
                denominationList[index] = productData;
                this.setState({
                    denominationList: denominationList
                })
            }
        }
    }

    /**
     * Method for Round Of number coloser to 5 interval
     * @param {} num 
     */
    roundOfNumberToFive(num) {
        let remainder = num % 10;
        if (0 <= num && num <= 5) {
            return 5
        }
        if (num % 5 === 0) {
            return num;
        }
        if (remainder < 5) {
            if (remainder <= 2.5) {
                return num - remainder;
            } else {
                num = num - remainder;
                return num = num + 5
            }
        } else {
            if (remainder <= 7) {
                num = num - remainder;
                return num = num + 5
            } else {
                num = num - remainder;
                return num = num + 10
            }
        }
    }

    // Method to change the gift card price 
    changePrice(value, index, productData) {
        var denominationList = this.state.denominationList;
        var roundValue = this.roundOfNumberToFive(value);
        productData.price = roundValue;
        denominationList[index] = productData;
        this.setState({
            denominationList: denominationList
        })
    }

    // Method to set the gift card range in the cart list 
    cartProductQuantity(event, productData, index) {
        var productCartList = this.state.productCartList;
        productData.productQuantity = event.target.value;
        productCartList[index] = productData;
        localStorage.setItem("productCardDetailsData", JSON.stringify(productCartList))
        this.setState({
            productCartList: productCartList
        })
    }

    //Method to go the FAQ page 
    redirectToFaq = (event) => {
        try {
            if (this.props.orderManagementProps !== undefined && this.props.orderManagementProps.history !== undefined) {
                this.props.orderManagementProps.history.push('/faq')
            }
        } catch (e) { }
    }

    // Method to set the gift card range manually inn cart list 
    setCartQuantity(event, productData, index) {
        try {
            var value = event.target.value;
            var productCartList = this.state.productCartList;
            var sideBarPanelCartDetails = true;
            if (value && parseInt(value)) {
                productData.productQuantity = event.target.value;
                productData.productQuantityPrice = parseInt(event.target.value) * productData.price;
                productCartList[index] = productData;
            } else {
                productCartList.splice(index, 1);
                if (productCartList.length == 0) {
                    document.getElementById("productSideBar").style.width = "0";
                    sideBarPanelCartDetails = false;
                }
            }
            localStorage.setItem("productCardDetailsData", JSON.stringify(productCartList))
            this.setState({
                productCartList: productCartList,
                sideBarPanelCartDetails: sideBarPanelCartDetails
            })
        } catch(e) { console.log("bug",e)}
       
    }


/**
  * function for open loginModal
  */
    openModal = () => {
        this.resetStates()
        this.setState({ modalIsOpen: true, signupModalIsOpen: false, isDicountopen: false, changeColorState: true, regchangeColorState: false });
    };


    /**
    * function for close loginModal
    */
    closeModal = () => {
        this.resetStates()
        this.setState({ modalIsOpen: false, changeColorState: false });
    };

    openSignUpModal = () => {
        this.resetStates()
        this.setState({ signupModalIsOpen: true, modalIsOpen: false, regchangeColorState: true, changeColorState: false });
    };

    closeSignUpModal = () => {
        this.resetStates()
        this.setState({ signupModalIsOpen: false, modalIsOpen: false, regchangeColorState: false });
    };

    /**
       * function for enable registration button
       */
    enableRegistrationButton = (e) => {
        if (e.target.checked) {
            this.setState({ check: 1 })
        } else {
            this.setState({ check: 0 })

        }
    }

    closeModalForget = () => {
        this.resetStates()
        // this.props.modalClose();
        this.setState({ modalForget: false });
    };

    openModalForget = () => {
        this.resetStates()
        // this.props.modalClose();
        this.setState({ modalForget: true, modalIsOpen: false, changeColorState: false })
    }
    /**
        * function to open policyModal
        */
    openModalPolicy = () => {
        this.setState({
            policyModalopen: true
        });
    };
    /**
  * function to close policyModal
  */
    closeModalPolicy = () => {
        this.setState({
            policyModalopen: false
        });
        if (document.getElementsByClassName("modalPolicy")[0]) {
            document.getElementsByClassName("modalPolicy")[0].classList.add("exit");
        }
    };

    /**
      * function for authenticate user is valid or not on submit of registration
      * @param object event
      * @return object userParams
     */
    authenticate = (status) => {
        let { newsLetterStatus } = this.state
        if (status !== undefined) {
            newsLetterStatus = false
        }
        let phoneRegx = /^(a1-z9){7,15}$/;

       
        this.setState({ newsLetterModalopen: false })
        let firstName = this.state.validObj.firstName
        var lang = this.state.lang
        if (firstName == '') {

            this.setState({
                regsFirstNameErrorSpan: language.language.enter_first_name[0][lang], isInputFieldEmpty: true
            })
        }
        if (this.state.validObj.lastName == '') {
            this.setState({
                regsLastNameErrorSpan: language.language.enter_last_name[0][lang], isInputFieldEmpty: true
            })
        }
        if (this.state.validObj.companyName == '') {
            this.setState({
                regsCompanyNameErrorSpan: language.language.enter_company_name[0][lang], isInputFieldEmpty: true
            })
        }
        if (this.state.validObj.email == '') {
            this.setState({
                regsEmailErrorSpan: language.language.enter_email[0][lang], isInputFieldEmpty: true
            })
        }
        if (this.state.validObj.phone == '') {
            this.setState({
                regsPhoneErrorSpan: "Please enter your Phone Number", isInputFieldEmpty: true
            })
        }
        if (this.state.validObj.password == '') {
            this.setState({
                regsPasswordErrorSpan: language.language.set_password_msg[0][lang], isInputFieldEmpty: true
            })
        }
        if (this.state.validObj.confirmPassword == '') {
            this.setState({
                regsConfirmPasswordErrorSpan: language.language.confirm_password_msg[0][lang], isInputFieldEmpty: true
            })
        }
        if(!this.state.validObj.password.match(phoneRegx) && this.state.validObj.confirmPassword === '') {
            this.setState({regsConfirmPasswordErrorSpan :language.language.Password_Validation[0][this.state.lang] })
            return 0;
        }
        if (this.state.validObj.password != this.state.validObj.confirmPassword) {
            Object.assign(this.state.validObj, { passwordMatchError: true })
            this.setState({
                regsConfirmPasswordErrorSpan: language.language.password_match_error[0][lang], isInputFieldEmpty: true
            })
        }
        if (this.state.validObj.email != '' && !(this.validateMail(this.state.validObj.email))) {
            Object.assign(this.state.validObj, { emailError: true })
            this.setState({
                regsEmailErrorSpan: language.language.Invalid_Email[0][this.state.lang], isInputFieldEmpty: true
            })
        } else { Object.assign(this.state.validObj, { emailError: false }) }
        if (this.state.validObj.password != '' && ((this.state.validObj.password).length < 8)) {
            Object.assign(this.state.validObj, { passwordLengthError: true })
            this.setState({
                regsPasswordErrorSpan: language.language.customer_password_length[0][lang]
            })
        } else { Object.assign(this.state.validObj, { passwordLengthError: false }) }
        if ((this.state.validObj.phone).length < 14) {
            this.setState({
                regsPhoneErrorSpan: language.language.invalid_phone[0][lang], isInputFieldEmpty: true
            })
            Object.assign(this.state.validObj, { phoneError: true })
        } else { Object.assign(this.state.validObj, { phoneError: false }) }
        if (this.state.validObj.firstName != "" && this.state.validObj.lastName != "" && this.state.validObj.companyName != "" && this.state.validObj.email != "" && this.state.validObj.phone !== "" && (this.state.validObj.password).length >= 8 && (this.state.validObj.password == this.state.validObj.confirmPassword) && this.validateMail(this.state.validObj.email) && (this.state.validObj.phone).length == 14) {
            let url = window.location.protocol + "//" + window.location.host + "?activate=";
            let userParams = {
                firstName: this.state.validObj.firstName,
                lastName: this.state.validObj.lastName,
                email: this.state.validObj.email,
                password: this.state.validObj.password,
                mobile: this.state.validObj.phone,
                companyName: this.state.validObj.companyName,
                activateUrl: url,
                consent: this.state.check
            }

            if (this.state.check === 1 || newsLetterStatus === false) {
                this.setState({ newsLetterModalopen: false, Loading: true, checkUpdateState: true })
                this.props.searchUserAction(userParams)
            } else if (this.state.check === 0 && newsLetterStatus === true) {
                this.setState({
                    newsLetterModalopen: true
                })
            }
        } else {
            let invalid = {}
            if (this.state.validObj.username == "") {
                invalid['username'] = language.language.required_field[0]
            }
            if (this.state.validObj.password == "") {
                invalid['password'] = language.language.required_field[0]
            }
            let inValidaData = Object.assign(this.state.invalidObj, invalid)
            this.setState({ invalidObj: inValidaData })
        }
    }

    /**
      * function for sending customer password reset link
     */
    resetPWDLink() {
        if (this.state.forgetvalidObj.email != "") {
            if (this.validateMail(this.state.forgetvalidObj.email)) {
                let userParams = {
                    email: this.state.forgetvalidObj.email,
                }

                var list = this.state.customerList
                let resList = _.filter(list, (v) => _.includes(userParams.email, v.email))
                if (resList != "") {
                    let timeSamp = HelperUtil._currentDateTime();
                    let encodedData = base64.encode(userParams.email + "&&&" + timeSamp)
                    let url = constants.CUSTOMER_PASSWORD_RESET + "?forgetId=" + encodedData
                    let userDetails = { url: url, email: userParams.email };
                    this.setState({ Loading: true })
                    this.props.customerResetPWDAction(userDetails);

                } else {

                    this.setState({ flashMessageSuccess: "", flashMessageError: constants.EMAIL_NOT_FOUND })
                }

            } else {
                this.setState({ flashMessageSuccess: "", flashMessageError: language.language.Invalid_Email[0][this.state.lang] })
            }

        }
        else {
            let invalid = {}

            this.setState({ flashMessageError: language.language.email_address_req[0][this.state.lang] })
            invalid['password'] = "This Field is required"
            let inValidaData = Object.assign(this.state.forgetinvalidObj, invalid)
            this.setState({ forgetinvalidObj: inValidaData })

        }
    }

    /**
      * function for forgetPassword Email input value
      * @param object event
      * @return object State
     */
    forgetEmail = (event) => {
        event.preventDefault();
        let key = event.target.name
        let value = event.target.value
        let valid = {}
        let invalid = {}
        if (value === "") {
            invalid[key] = "This Field is required"
            valid[key] = ""
        } else {
            invalid[key] = ""
            valid[key] = value
        }
        let inValidaData = Object.assign(this.state.forgetinvalidObj, invalid)
        let validaData = Object.assign(this.state.forgetvalidObj, valid)
        this.setState({ forgetinvalidObj: inValidaData, forgetvalidObj: validaData, flashMessageError: "" })
    }

    resetStates = () => {
        this.setState({
            flashMessageError: '',
            flashMessageSuccess: '',
            isInputFieldEmpty: false,
            isFieldsEmpty: false,
            validObj: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                companyName: "",
                phone: "",
                confirmPassword: ""
            },
            invalidObj: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                companyName: "",
                phone: "",
                confirmPassword: ""
            },
            emailErrorSpan: "",
            passwordErrorSpan: "",
            regsFirstNameErrorSpan: "",
            regsLastNameErrorSpan: "",
            regsCompanyNameErrorSpan: "",
            regsEmailErrorSpan: "",
            regsPhoneErrorSpan: "",
            regsPasswordErrorSpan: "",
            regsConfirmPasswordErrorSpan: "",
            check: 1,
            newsLetterStatus: true,
        })
    }

    /**
* function for authenticate user is valid or not on submit
* @param object event
* @return object userParams
*/
    authenticateLogin = async (event) => {
        let email = this.state.validObj.email;
        let password = this.state.validObj.password;
        if (email == "") {
            this.setState({
                emailErrorSpan: language.language.Empty_Email_Address[0][this.state.lang], 
                isFieldsEmpty: true
            })
        }
        else if (password == "") {
            this.setState({
                passwordErrorSpan: language.language.Empty_Password[0][this.state.lang], isFieldsEmpty: true
            })
        }
        else if (email != "" && password != "") {
            if (this.validateMail(email)) {
                let userParams = {
                    email: email,
                    password: password
                }
                this.setState({ Loading: true, loginStateUpdate: true })
                await this.props.customerLoginAction(userParams)
            } else {
                Object.assign(this.state.validObj, { emailError: true })
                this.setState({
                    emailErrorSpan: language.language.Invalid_Email[0][this.state.lang],
                    isFieldsEmpty: true

                })
            }
        } else {

            let invalid = {}
            if (this.state.validObj.username == "") {
                invalid['username'] = "This Field is required"
            }
            if (this.state.validObj.password == "") {
                invalid['password'] = "This Field is required"
            }
            let inValidaData = Object.assign(this.state.invalidObj, invalid)
            this.setState({
                invalidObj: inValidaData,
                flashMessageError: "Fields can't be null"
            })
        }
    }

    /**
     * function for validate user details
     * @param object event
     * @return object state
    */

    setValue = (event) => {

        this.setState({
            emailErrorSpan: "",
            flashMessageSuccess: '',
            flashMessageError: "",
            passwordErrorSpan: ""
        })
        let key = event.target.name
        let value = event.target.value
        let valid = {}
        let invalid = {}
        // check for first space 
        if(value!=="" &&  value[0].charCodeAt() === 32) {
                return 0;
        }
        if (value === "") {
            invalid[key] = language.language.required_field[0]
            valid[key] = ""
        } else {
            invalid[key] = ""
            valid[key] = value
        }
        let inValidaData = Object.assign(this.state.invalidObj, invalid)
        let validaData = Object.assign(this.state.validObj, valid)

        this.setState({
            invalidObj: inValidaData, validObj: validaData,
            emailErrorSpan: "",
            passwordErrorSpan: "",
            regsFirstNameErrorSpan: "",
            regsLastNameErrorSpan: "",
            regsCompanyNameErrorSpan: "",
            regsEmailErrorSpan: "",
            regsPhoneErrorSpan: "",
            regsPasswordErrorSpan: "",
            regsConfirmPasswordErrorSpan: ""
        })
    }

    /**
         * function to submit form on enter Keypress
         */
    submitOnEnter(event) {
        if (event.which == 13 || event.keyCode == 13) {
            //customer LogIn Form
            if (event.target.id == 'pwd') {
                this.authenticateLogin();
            }
            //customer SignUp Form
            else if (event.target.id == 'cnf-pwd-signup') {
                this.authenticate()
            }
            //customer forget Password
            else if (event.target.id == 'email-forget-password') {
                this.resetPWDLink()
            }
        }
    }
    /**
* function for validate Email
* * @return boolean
*/
    validateMail = (email) => {
        var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(email).toLowerCase());

    }

    closeModalNewsLetter = () => {
        this.setState({
            newsLetterModalopen: false
        })
    }

    /**Close Modal of notice */ 
    closeNote  = (e,i) => {
        try {
            document.getElementById('')
        } catch(e) { console.log("totalCardsideBar_",e)}
        this.setState({maxQtyNote:false })
    }

    render() {
        
        let open = this.props.open || this.state.modalIsOpen
        var regchangeColorClass = this.state.regchangeColorState ? 'yellowColor' : '';
        var changeColorClass = this.state.changeColorState ? 'yellowColor' : '';
        if (sessionStorage.userID != 0 && sessionStorage.loggedIn === '') {
            this.props.orderManagementProps.history.push({
                pathname: '/'
            });
        }
        let denominationsData = [];
        let productCardDetails = [];
        let productTotalPrice;
        if (productTotalPrice) {
            productTotalPrice = productTotalPrice;
        } else {
            productTotalPrice = 0;
        }
        if (this.state.denominationList != undefined && this.state.denominationList.length > 0) {
            if (JSON.parse(localStorage.getItem('denominationListData')) != null) {
                denominationsData = JSON.parse(localStorage.getItem('denominationListData'))
            } else {
                denominationsData = this.state.denominationList;
            }
        }
        productCardDetails = this.state.productCartList;
        var lang = this.state.lang !== undefined ? this.state.lang : 'en'
        const { fontSize } = this.state

        let flashSuceessMessageSpan = '';
        let flashErrorMessageSpan = '';
        if (this.state.flashMessageSuccess) {
            flashSuceessMessageSpan = <p >{this.state.flashMessageSuccess}</p>;
        }
        if (this.state.flashMessageError) {
            flashErrorMessageSpan = <p color='danger'>{this.state.flashMessageError}</p>;

        }
        //error messages for email validations
        let emailErrorSpan = "";
        if (this.state.emailErrorSpan) {
            emailErrorSpan = <span className="validMessage">{this.state.emailErrorSpan}</span>
        }
        let passwordErrorSpan = "";
        if (this.state.passwordErrorSpan) {
            passwordErrorSpan = <span className="validMessage">{this.state.passwordErrorSpan}</span>
        }
        let totalQty = 0
        this.state.productCartList.length > 0 ?
            this.state.productCartList.map(list => totalQty += +list.productQuantity) : void 0

        let regsFirstNameErrorSpan = <span className='validMessage'>{this.state.regsFirstNameErrorSpan}</span>
        let regsLastNameErrorSpan = <span className='validMessage'>{this.state.regsLastNameErrorSpan}</span>
        let regsCompanyNameErrorSpan = <span className='validMessage'>{this.state.regsCompanyNameErrorSpan}</span>
        let regsEmailErrorSpan = <span className='validMessage'>{this.state.regsEmailErrorSpan}</span>
        let regsPhoneErrorSpan = <span className='validMessage'>{this.state.regsPhoneErrorSpan}</span>
        let regsPasswordErrorSpan = <span className='validMessage'>{this.state.regsPasswordErrorSpan}</span>
        let regsConfirmPasswordErrorSpan = <span className='validMessage'>{this.state.regsConfirmPasswordErrorSpan}</span>
        var validObj = this.state.validObj

        let activeDiscount = this.state.activeDiscount
        let cardDisount = []
        let achDiscount = []

        activeDiscount.length && activeDiscount !== null ? activeDiscount.map((discount) => {
            discount.paymentType === "Card" ? cardDisount.push(discount) : achDiscount.push(discount)
        }) : void 0
        let cardSort
        if (cardDisount.length) {
            cardSort = _.sortBy(cardDisount, (discount) => {
                return discount.amountFrom
            })
        }
        let achSort
        if (achDiscount.length) {
            achSort = _.sortBy(achDiscount, (discount) => {
                return discount.amountFrom
            })
        }
        let discountString = sessionStorage.language === "spa" ? "o mas" : "and above"
        return (
            <div className="allProduictPage">
                <div className="wrapper page customerHome">
                    {
                        this.state.Loading === true && <div className="loader-react">
                            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                        </div>
                    }
                    {/* <Header /> */}
                    <header id="header">
                        <div className="container-fluid">
                            <div className="logo-div pull-left">
                                <a href={NORTHGATE_URL} tabindex="1" onClick={sessionStorage.emailID === '' || sessionStorage.emailID == undefined ? (e) => { this.breadCrumbCLick(e) } : ''} className="logo"><img src={CustomerLogo} alt="Northgate Giftcard logo" title="Northgate Giftcard logo" /></a>
                            </div>
                            <div className="header-search pull-left">

                                <div className="search-container">
                                    <form className="search-header">
                                        <fieldset>
                                        </fieldset>
                                    </form>
                                    <ul className="text-size-menu">
                                        <li tabindex="2" onClick={this.changeSize.bind(this, 'decrease')} ><a href="javascript:void(0)" style={{ fontSize: fontSize.a + 'px' }}>A-</a></li>
                                        <li tabindex="3" onClick={this.changeSize.bind(this, 'default')} ><a href="javascript:void(0)" style={{ fontSize: fontSize.a + 'px' }}>A</a></li>
                                        <li tabindex="4" onClick={this.changeSize.bind(this, 'increase')} ><a href="javascript:void(0)" style={{ fontSize: fontSize.a + 'px' }} >A+</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="header-login right_section pull-right">
                                <div className="faq-section">

                                    <ul className={`user-icon-header newcustome_head ${(sessionStorage.userID != 0 && sessionStorage.userID != undefined && sessionStorage.loggedIn !== '') ? 'newhome_login' : ''}`}>
                                        <li className="custom-faq">
                                            <div className="card_icon cardnone_button mgr10">
                                                {/* <a tabindex="5" href="javascript:void(0)" onClick={(e) => { this.redirectToFaq(e) }} title="Frequently Asked Questions">FAQ</a> */}
                                                <Link to="/faq" tabindex="5" onClick={(e) => { this.redirectToFaq(e) }} title={language.language.FAQ[0][lang]} style={{ fontSize: fontSize.loginSignup + 'px' }}>{language.language.FAQ[0][lang]}</Link>
                                            </div>
                                        </li>
                                        <li className="custom-bulk">
                                            <div className="card_icon cardnone_button mgr10">
                                                <Discount lang={this.state.lang} fontSize={this.state.fontSize}  />
                                            </div>
                                        </li>

                                       

                                    </ul>
                                   

                                    {(sessionStorage.userID != 0 && sessionStorage.userID != undefined && sessionStorage.loggedIn !== '') ?
                                        <ul className="user-icon-header">

                                            <li className="dropdown">
                                                <a className="dropbtn card_icon mgr10"><img src={userIcon} alt="userIcon" title="userIcon" /></a>
                                                <div className="dropdown-content" id="myDropdown">
                                                    <div className="dropdown-header">
                                                        <a href="javascript:void(0)">{sessionStorage.customerName} </a>
                                                    </div>
                                                    <a href="/dashboard" className="">
                                                        <img src={profile} alt="profile" title="profile" /> {language.language.my_profile[0][lang]}
                                                    </a>
                                                    <a href="/orderDetailsManagements" onClick={this.openCart.bind(this)} className={
                                                         JSON.parse(localStorage.getItem("productCardDetailsData")) === null || JSON.parse(localStorage.getItem("productCardDetailsData")).length==0?"disableEvent":""

                                                    }
                                        
                                                    >
                                                        <img src={cart} alt="cart" title="cart" /> {language.language.my_cart[0][lang]}
                                                    </a>

                                                    <div className="dropdown-footer"><a href="JavaScript:Void(0);"
                                                        onClick={this.logOut.bind(this)}><img src={signout} alt="signout" title="signout" /> {language.language.Logout[0][lang]}</a></div>
                                                </div>
                                            </li>

                                        </ul> :
                                        <ul className="login-menu-header">
                                            <li>
                                                <a tabindex="6" title={language.language.Login[0][lang]} href="JavaScript:Void(0);" className={changeColorClass} style={{ fontSize: fontSize.loginSignup + 'px' }} onClick={this.openModal}>{language.language.Login[0][lang]}</a></li>
                                            <li ><a tabindex="7" title={language.language.Signup[0][lang]} href="JavaScript:Void(0);" className={regchangeColorClass} style={{ fontSize: fontSize.loginSignup + 'px' }} onClick={this.openSignUpModal}>{language.language.Signup[0][lang]}</a></li>
                                        </ul>
                                    }
                                     <ul className={`card_add_icon ${sessionStorage.userID != 0 && sessionStorage.userID != undefined && sessionStorage.loggedIn !== '' ? 'loggedin':'geuest' }`}>
                                    

                                    {/* for cart */}
                                    <li className="cart-icon-padding">
                                        <div className={this.state.productCartList.length ? "card_icon cardnone_button" : "card_icon cardnone_button disableEvent"}><img alt="Cart" title={language.language.my_cart[0][lang]} src={carticon} onClick={e => this.showCart()} />
                                            {this.state.productCartList.length ? <span className="badge">{totalQty}</span> : ''}
                                        </div>
                                    </li>


                                </ul>

                                </div>
                                <div className={sessionStorage.userID == 0 ? 'language-menu-div' : 'language-menu-space'}>
                                    <form className="form">
                                        <fieldset>
                                            <div className="switch-field">
                                                <input title="switch_2" type="radio" id="switch_left" name="switch_2" value="yes" checked={lang == 'en' ? true : false} />
                                                <label tabindex="8" htmlFor="switch_left" title={language.language.english[0][lang]} onClick={this.activateEnglishLanguage.bind(this)} style={{ fontSize: fontSize.language + 'px' }}>{language.language.english[0][lang]}</label>
                                                <input title="switch_2" type="radio" id="switch_right" name="switch_2" value="no" checked={lang == 'spa' ? true : false} />
                                                <label tabindex="9" htmlFor="switch_right" title={language.language.spanish[0][lang]} onClick={this.activateSpanishLanguage.bind(this)} style={{ fontSize: fontSize.language + 'px' }}>{language.language.spanish[0][lang]}</label>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </header>



                    <section className="pageWidth">
                        {/*
            <!--breadcrumb start here-->*/}
                        <div className="container-fluid">
                            <div className="row">
                                <div className={this.state.sideBarPanelCartDetails ? 'col-md-9' : 'col-md-12'}>
                                    <ul className="breadcrumb all_breadcrumb_top">
                                        <li className="breadcrumb-item "><a
                                            onClick={sessionStorage.emailID === '' || sessionStorage.emailID == undefined ? (e) => { this.breadCrumbCLick(e, 'breadcrumb') } : ''}
                                            className={sessionStorage.emailID === '' || sessionStorage.emailID == undefined ? "active gift-card" : ''}
                                            href={sessionStorage.emailID === '' || sessionStorage.emailID == undefined ? "javascript:void(0)" : false}
                                            style={{ fontSize: fontSize.breadcrumb + 'px' }}>{language.language.home[0][lang]}</a></li>
                                        <li className="breadcrumb-item"><span style={{ fontSize: fontSize.breadcrumb + 'px' }} className="current-page">{language.language.gift_cards[0][lang]}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/*
            <!--breadcrumb end -->*/}
                        {/*--filter row start here--*/}
                        <div className="container-fluid">



                            {/*---filter row end here--*/}

                            <div className="product-section">
                                <div className="product-grid" id="productGridId">
                                    <div className="row">
                                        <div className={this.state.sideBarPanelCartDetails ? 'col-md-9' : 'col-md-12'}>
                                            <div className="row ">
                                                {  activeDiscount.length > 0 ? 
                                                    <> 
                                                        <div className="addcard-section">
                                                            <h2 className="productPageTitle discount_head" style={{ fontSize: fontSize.giftCardText + 'px'}}>{language.language.Gift_CARD_ToolTip_Discount[0][lang]}</h2> 
                                                        </div>
                                                        <div className="order-main-section boxshadow30 mar20_dis discount-sct">
                                                            {activeDiscount.length && activeDiscount != null && activeDiscount != '' ?

                                                                <div className="discount-pay discount_new addorderpage">

                                                                    <table className="table" width="100%">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ fontSize: fontSize.discountText + 'px'}}>{language.language.Payment_Type[0][lang]}</th>
                                                                                <th style={{ fontSize: fontSize.discountText + 'px'}}>{language.language.Purchase_Amount[0][lang]}</th>
                                                                                <th style={{ fontSize: fontSize.discountText + 'px'}}>{language.language.Gift_card_discount[0][lang]} <i className="fa fa-info-circle question_mark" title = {language.language.Gift_CARD_ToolTip_Discount[0][lang]}></i></th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            {cardSort != undefined && cardSort.length > 0 ? cardSort.map((discount, index) => {
                                                                                return (<tr key={`cardSort__${index}`}>
                                                                                    {index === 0 ? <td style={{ fontSize: fontSize.tabs + 'px'}}><span className="fa fa-credit-card"></span> <p className="ACH_Discount mb-0">{language.language.CARD_Discount[0][lang]}</p></td> : <td></td>}
                                                                                    <td style={{ fontSize: fontSize.tabs + 'px'}}>${HelperUtil._getAmountCommaSeparator(discount.amountFrom).toString()} {discount.amountTo === 0 ? discountString : "- $" + HelperUtil._getAmountCommaSeparator(discount.amountTo).toString()}</td>
                                                                                    <td style={{ fontSize: fontSize.tabs + 'px'}}>{discount.percent}%</td>
                                                                                </tr>)
                                                                            })
                                                                                : <tr></tr>}
                                                                            {cardDisount != undefined && cardDisount.length && achDiscount != undefined && achDiscount.length ?
                                                                                <tr >


                                                                                </tr> :
                                                                                <tr></tr>}
                                                                            {achSort != undefined && achSort.length > 0 ? achSort.map((discount, index) => {
                                                                                return (<tr className="" key={`achSort__${index}`}>
                                                                                    {index === 0 ? <td style={{ fontSize: fontSize.tabs + 'px'}}><span className="fa fa-university"></span> <p className="ACH_Discount">{language.language.ACH_Discount[0][lang]}</p></td> : <td></td>}
                                                                                    <td style={{ fontSize: fontSize.tabs + 'px'}}>${HelperUtil._getAmountCommaSeparator(discount.amountFrom).toString()} {discount.amountTo === 0 ? discountString : "- $" + HelperUtil._getAmountCommaSeparator(discount.amountTo).toString()}</td>
                                                                                    <td style={{ fontSize: fontSize.tabs + 'px'}}>{discount.percent}%</td>
                                                                                </tr>)
                                                                            })
                                                                                : <tr></tr>}

                                                                        </tbody>


                                                                    </table>

                                                                </div>
                                                                : ''}

                                                            </div>
 
                                                        </> : void 0
                                                    }

                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="addcard-section">
                                                            <h2 className="productPageTitle discount_head" style={{ fontSize: fontSize.giftCardText + 'px' }}>{language.language.gift_cards[0][lang]}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 text-center">
                                                        <div className="tabs">
                                                            <div className="tab-button-outer">
                                                                <ul id="tab-button">
                                                                    <li style={{ fontSize: fontSize.tabs + 'px' }} className={this.state.active == 'all' ? 'active' : ''} onClick={(e) => this.setAllDenominationList()}>All</li>
                                                                    {this.childGiftcard()}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="d-flex rowflex flex-wrap">
                                                    {
                                                        denominationsData.length > 0 ? denominationsData.map((key, index) => {
                                                            return <div className="p-2 singleProduct " key={`denominationsData_${index}`}>
                                                                <div className='product-area unselected'>
                                                                    <div className="productImg">
                                                                        <img alt={key.typeName} title={key.typeName} src={key.imagePath == "" ? noImage : constant.APIURL + key.imagePath + key.imageName} />
                                                                    </div>
                                                                    <div className="productContent">
                                                                        <p style={{ fontSize: fontSize.breadcrumb + 'px' }}>{key.typeName} </p> <div className="dollar-section"> <p className="doller-sign">$</p>
                                                                            <div className="price-input">
                                                                                <span className="card-Price">
                                                                                    <button className="up-icon" id="plus" value={key.price} onClick={(e) => this.setDenominationPrice(e, index, key)}></button>
                                                                                    <input 
                                                                                        title="Enter amount between $5 - $500 in increment of $5." 
                                                                                        id="card-add" value="0" step="5" min="5" max="500" value={key.price} 
                                                                                        onChange={(e) => this.setDenominationPrice(e, index, key)}
                                                                                        onBlur={(e) => this.setDenominationPrice(e, index, key)} 
                                                                                        style={{ fontSize: fontSize.tabs + 'px' }}
                                                                                        />
                                                                                    <button className="down-icon" id="minus" value={key.price} onClick={(e) => this.setDenominationPrice(e, index, key)}></button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="productPrice">
                                                                            <span className="incrementPrice order-details ">
                                                                                <button className={`minus-icon ${(key.productQuantity > 0) ? 'minusRed' : ''}`} onClick={(e) => this.decrementProductQuantity(key)} id="decrease" value="Decrease Value"></button>
                                                                                <InputMask
                                                                                    mask={'999'} 
                                                                                    maskChar={null} id="number" value={key.productQuantity} 
                                                                                    onChange={(e) => this.setProductQuantity(e, key, index)} 
                                                                                    onBlur={(e) => this.setQuantity(e, key, index)} 
                                                                                    style={{ fontSize: fontSize.tabs + 'px' }}
                                                                                    
                                                                                    
                                                                                    />
                                                                                <button onClick={(e) => this.incrementProductQuantity(key, index)} disabled={(key.productQuantity == undefined || key.productQuantity < 999) ? '' : 'disabled'} className={`pluse-icon pull-right ${(key.productQuantity < 999) ? '' : 'plusGrey'}`} id="increase" value="Increase Value"></button>
                                                                            </span>
                                                                            <span className={`add-card pull-right ` + (key.productQuantity ? 'enable-cart-span' : 'disable-cart-span addInit')} onClick={(e) => this.addToCart(key, index)} style={{ fontSize: fontSize.breadcrumb + 'px' }}>{language.language.ADD[0][lang]}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }) : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={this.state.sideBarPanelCartDetails ? 'col-md-3 padmobile sidebar_new' : 'col-md-0'}>
                                            {/* sidebar css for comes right*/}

                                            <div className={sessionStorage.emailID != undefined && sessionStorage.emailID != '' ? 'sideBg pull-right' : 'gust-user pull-right'} id="productSideBar">
                                                <div className="sideBar">
                                                    <div className="sidebarHeader">
                                                        <h3 style={{ fontSize: fontSize.sideBarText + 'px' }} className="pull-left">{language.language.gift_Card_details[0][lang]}</h3><span className="pull-right sideBarCross closesidebar" onClick={(e) => this.sideBarClose()} ></span>
                                                    </div>
                                                    <hr />
                                                    <div className="totalProductList all_scroll_section">
                                                        {
                                                            productCardDetails.length > 0 ? productCardDetails.map((key, index) => {

                                                                productTotalPrice = productTotalPrice + key.productQuantityPrice;
                                                                return <div className="totalCardsideBar" id={`totalCardsideBar_${index}`} key={`totalCardsideBar_${index}`}>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="totalPrice">
                                                                                <h2 style={{ fontSize: fontSize.loginSignup + 'px' }}>{key.typeName} - $ {key.price != undefined ? HelperUtil._getAmountCommaSeparator(key.price) : 5}.00 </h2>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="incrementPrice selectitem order-details">
                                                                                <button className={`minus-icon ${(key.productQuantity > 0) ? 'minusRed' : ''}`}  id="decrease" disabled={(key.productQuantity > 0) ? '' : 'disabled'} onClick={(e) => this.decrementGiftCard(key, index)} value="Decrease Value"></button>
                                                                                <InputMask id="number" mask="999"
                                                                                 maskChar={null} value={key.productQuantity}
                                                                                  onChange={(e) => this.cartProductQuantity(e, key, index)} 
                                                                                  onBlur={(e) => this.setCartQuantity(e, key, index)}
                                                                                  style={{ fontSize: fontSize.tabs + 'px' }}
                                                                                  
                                                                                  />
                                                                                <button className={`pluse-icon pull-right ${(key.productQuantity < 999) ? '' : 'plusGrey'}`} id="increase" disabled={(key.productQuantity < 999) ? '' : 'disabled'} onClick={(e) => { this.incrementGiftCard(key, index); }} value="Increase Value"></button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 cusmotenopad">
                                                                            <div className="totalPrice toplist">
                                                                                <p style={{ fontSize: fontSize.breadcrumb + 'px' }}>$ {key.productQuantityPrice ? HelperUtil._getAmountCommaSeparator(key.productQuantityPrice.toFixed(2)).toString() : 0}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }) : ''
                                                        }
                                                    </div>
                                                </div>

                                                <div className="sideBarFooter">
                                                    <div className="totalCalculation">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="cardSelection">
                                                                    <p style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.card_selection[0][lang]}</p>
                                                                    <h2 style={{ fontSize: fontSize.loginSignup + 'px' }}>{totalQty}</h2>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 nopadding10">
                                                                <div className="totalTotal text-right">
                                                                    <p style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.Total_Amount[0][lang]}</p>
                                                                    <h3 style={{ fontSize: fontSize.a + 'px' }}>$ {HelperUtil._getAmountCommaSeparator(productTotalPrice.toFixed(2)).toString()}</h3>
                                                                </div>
                                                            </div>
                                                            <div className="border-topnew"></div>
                                                            <div className="col-md-12 nopadding10">
                                                                {!sessionStorage.emailID ? (this.state.productCartList.length && productTotalPrice <= 3000)
                                                                    ?
                                                                    (
                                                                        <div className="form-group">
                                                                            <label className="noteLabel" style={{ fontSize: fontSize.loginSignup + 'px' }}>{language.language.Note[0][lang]}</label>
                                                                            <ul className="card-notes-menu">
                                                                                <li style={{ fontSize: fontSize.loginSignup + 'px' }}>{language.language.giift_card_price_limit[0][lang]}</li>
                                                                                <li style={{ fontSize: fontSize.loginSignup + 'px' }}>{language.language.gift_card_discount_info[0][lang]}</li>
                                                                            </ul>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    <div className="form-group">
                                                                            <label className="noteLabel">{language.language.Note[0][lang]}</label>
                                                                            <ul className="card-notes-menu">
                                                                                <li style={{color:'red',fontSize: fontSize.loginSignup + 'px' }}>{language.language.giift_card_price_limit[0][lang]}</li>
                                                                                <li  style={{fontSize: fontSize.loginSignup + 'px' }}>{language.language.gift_card_discount_info[0][lang]}</li>
                                                                            </ul>
                                                                    </div>
                                                                    : <label className="noteplus30 p-0 giftcard_note" style={{ fontSize: fontSize.loginSignup + 'px' }}><b>{language.language.Note[0][lang]}</b> {language.language.gift_card_discount_info[0][lang]}</label>
                                                                    }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sideBarButton text-center">
                                                    <button type="button" style={{ fontSize: fontSize.loginSignup + 'px' }} className={((this.state.productCartList.length) && ((!sessionStorage.emailID && productTotalPrice <= 3000) || (sessionStorage.emailID))) ? "giftcard_button cardnone_button mgr10" : "giftcard_button cardnone_button mgr10 disableEvent"} onClick={this.redirectToNextPage.bind(this)}>
                                                        {language.language.CONTINUE[0][lang]}</button>
                                                </div>
                                            </div>
                                            {/* sidebar css for comes right*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer>
                        <p>© 2019 Northgate Gonzalez Market®</p>
                    </footer>
                </div>
                {/* new modals */}
                {/* breadcrumb tabs */}
                <div className="modal-box" id=""> 
                    <ReactModal isOpen={open} onRequestClose={this.closeModal} closeTimeoutMS={500}
                        className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div className="modal-content landingcustomemodal">
                            <div className="modal-header giftcart_title_header">
                                <h4 className="modal-title giftcart_all_header"  style={{ fontSize: fontSize.breadcrumb + 'px' }}>{language != undefined ? language.language.Login[0][lang] : ''}</h4>
                                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModal}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <fieldset>
                                        <div className="form-group">
                                            {flashSuceessMessageSpan !== "" ?
                                                <div className="alert alert-success alert_succesfull clearfix" role="alert">
                                                    <div className="successbtn"><img src={successimg} alt="successimg" title="successimg" /></div>
                                                    <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                                                </div>

                                                : ''}
                                            {flashErrorMessageSpan ?
                                                <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                                                    <span><img src={errorimg} alt="errorimg" title="errorimg" /></span>
                                                    <p className="clearfix">{flashErrorMessageSpan}</p>
                                                </div>
                                                : ''}
                                            <label style={{ fontSize: fontSize.tabs + 'px' }} 
                                            htmlFor="email">{language != undefined ? language.language.email_id[0][lang] : ''}</label>
                                            <input title={language != undefined ? language.language.email_id[0][lang] : ''}
                                                type="email"
                                                className={`form-control ${(validObj.email === "" || validObj.emailError) && this.state.isFieldsEmpty ? "red" : ''}`}
                                                id="email"
                                                onFocus={(e) => e.target.placeholder = ""}
                                                onBlur={(e) => e.target.placeholder = language != undefined ? language.language.email_id[0][lang] : ''}
                                                onChange={(e) => this.setValue(e)}
                                                required name="email"
                                                value={validObj.email ? validObj.email : ''}
                                                ref={(e) => this.email = e}
                                                placeholder={language != undefined ? language.language.email_id[0][lang] : ''}
                                                style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                            />
                                            {emailErrorSpan ? emailErrorSpan : ""}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="pwd" style={{ fontSize: fontSize.tabs + 'px' }} >{language != undefined ? language.language.Password[0][lang] : ''}</label>
                                            <input title={language != undefined ? language.language.Password[0][lang] : ''}
                                                type="password"
                                                className={`form-control ${validObj.password === "" && this.state.isFieldsEmpty ? "red" : ''}`}
                                                id="pwd"
                                                onFocus={(e) => e.target.placeholder = ""}
                                                onBlur={(e) => e.target.placeholder = language != undefined ? language.language.Password[0][lang] : ''}
                                                onChange={(e) => this.setValue(e)}
                                                required name="password"
                                                value={validObj.password ? validObj.password : ''}
                                                ref={(e) => this.password = e} placeholder={language != undefined ? language.language.Password[0][lang] : ''}
                                                onKeyPress={(e) => this.submitOnEnter(e)}
                                                style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                            />
                                            {passwordErrorSpan ? passwordErrorSpan : ""}
                                        </div>
                                        <div className="buttons">
                                            <button type="button" className="giftcard_button mgr10" style={{ fontSize: fontSize.breadcrumb + 'px' }} onClick={(e) => this.authenticateLogin(e)}>{language != undefined ? language.language.Login[0][lang] : ''}</button>
                                            <button type="button" className="giftcard_button cardnone_button "  style={{ fontSize: fontSize.breadcrumb + 'px' }} onClick={this.openSignUpModal} data-toggle="modal" data-target="#registerModal">{language != undefined ? language.language.REGISTER[0][lang] : ''}</button>
                                        </div>
                                        <ul className="forgot-guest-links">
                                            <li><a style={{ fontSize: fontSize.breadcrumb + 'px' }} href="javascript:void(0)" onClick={this.openModalForget}>{language != undefined ? language.language.Forgot_Password[0][lang] : ''}</a></li>
                                            <li><a style={{ fontSize: fontSize.breadcrumb + 'px' }}  href="javascript:void(0)" onClick={this.closeModal.bind(this)}>{language != undefined ? language.language.Continue_as_a_guest[0][lang] : ''}</a></li>
                                        </ul>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </ReactModal>
                </div>

                {/* SignUp modal */}
                <div id="">
                    <ReactModal
                        closeTimeoutMS={500}
                        isOpen={this.state.signupModalIsOpen}
                        onRequestClose={this.closeSignUpModal}
                        className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div className="modal-dialog">

                            <div className="modal-content">

                                <div className="modal-header giftcart_title_header">
                                    <h4 className="modal-title giftcart_all_header" style={{ fontSize: fontSize.breadcrumb + 'px' }}>{language.language.REGISTER[0][lang]}</h4>
                                    <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeSignUpModal}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                                </div>
                                <div className="modal-body signUp_model">

                                    {flashSuceessMessageSpan !== "" ?
                                        <div className="alert alert-success alert_succesfull clearfix" role="alert">
                                            <div className="successbtn"><img src={successimg} alt="successimg" title="successimg" /></div>
                                            <p className="text-center clearfix red"> {flashSuceessMessageSpan} </p>
                                        </div>

                                        : ''}
                                    {flashErrorMessageSpan ?
                                        <div className="alert alert-success alert_succesfull adpadding30 clearfix" role="alert">
                                            <span><img src={errorimg} alt="errorimg" title="errorimg" /></span>
                                            <p className="clearfix">{flashErrorMessageSpan}</p>
                                        </div>
                                        : ''}

                                    <div className="row">
                                        <div className="col-md-12 form-group text-right">
                                            <p className="noteplus30 pb-0 m-0" style={{ fontSize: fontSize.breadcrumb + 'px' }}> {language.language.all_fields_mandatory[0][lang]}</p>
                                        </div>
                                    </div>
                                    <form>
                                        <fieldset>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="firstName" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.First_Name[0][lang]}</label>
                                                        <InputMask
                                                            id="firstName"
                                                            type="text"
                                                            className={`form-control ${validObj.firstName === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                                                            mask={this.state.nameInputMask}
                                                            onChange={(e) => this.setValue(e)}
                                                            maskChar={null}
                                                            name="firstName"
                                                            value={validObj.firstName}
                                                            style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                                        />
                                                        {regsFirstNameErrorSpan ? regsFirstNameErrorSpan : ''}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="lastName" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.Last_Name[0][lang]}</label>
                                                        <InputMask
                                                            id="lastName"
                                                            type="text"
                                                            className={`form-control ${validObj.lastName === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                                                            mask={this.state.nameInputMask}
                                                            onChange={(e) => this.setValue(e)}
                                                            maskChar={null}
                                                            name="lastName"
                                                            value={validObj.lastName}
                                                            style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                                        />
                                                        {regsLastNameErrorSpan ? regsLastNameErrorSpan : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="company-name" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.company_name[0][lang]}</label>
                                                <input title={language.language.company_name[0][lang]}
                                                    type="text"
                                                    className={`form-control ${validObj.companyName === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                                                    id="company-name"
                                                    onChange={(e) => this.setValue(e)}
                                                    name="companyName"
                                                    value={validObj.companyName}
                                                    ref={(e) => this.companyName = e}
                                                    maxLength={30}
                                                    style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                                />
                                                {regsCompanyNameErrorSpan ? regsCompanyNameErrorSpan : ''}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email-signup" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.Email_ID[0][lang]}</label>
                                                <input title={language.language.Email_ID[0][lang]}
                                                    type="email"
                                                    className={`form-control ${(validObj.email === "" || validObj.emailError) && this.state.isInputFieldEmpty ? 'red' : ''}`}
                                                    id="email-signup"
                                                    onChange={(e) => this.setValue(e)}
                                                    name="email" value={validObj.email}
                                                    ref={(e) => this.email = e}
                                                    maxLength={40}
                                                    style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                                />
                                                {regsEmailErrorSpan ? regsEmailErrorSpan : ''}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phone" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.mobile_number[0][lang]}</label>
                                                <InputMask
                                                    className={`form-control ${validObj.phone === "" && this.state.isInputFieldEmpty ? 'red' : ''}`}
                                                    id="phone"
                                                    onChange={(e) => this.setValue(e)}
                                                    mask="(999) 999-9999"
                                                    maskChar={null}
                                                    name="phone"
                                                    value={validObj.phone}
                                                    style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                                />
                                                {regsPhoneErrorSpan ? regsPhoneErrorSpan : ''}
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="pwd-signup" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.Password[0][lang]}</label>
                                                        <input title={language.language.Password[0][lang]}
                                                            type="password"
                                                            className={`form-control ${(validObj.password === "" || validObj.passwordMatchError || validObj.passwordLengthError) && this.state.isInputFieldEmpty ? 'red' : ''}`}
                                                            id="pwd-signup"
                                                            onChange={(e) => this.setValue(e)}
                                                            name="password" value={validObj.password}
                                                            ref={(e) => this.password = e}
                                                            maxLength={15}
                                                            style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                                        />
                                                        {regsPasswordErrorSpan ? regsPasswordErrorSpan : ''}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="cnf-pwd-signup" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.confirm_password[0][lang]}</label>
                                                        <input title={language.language.confirm_password[0][lang]}
                                                            type="password"
                                                            className={`form-control ${(validObj.confirmPassword === "" || validObj.passwordMatchError || validObj.passwordLengthError) && this.state.isInputFieldEmpty ? 'red' : ''}`}
                                                            id="cnf-pwd-signup"
                                                            onChange={(e) => this.setValue(e)}
                                                            name="confirmPassword"
                                                            value={validObj.confirmPassword}
                                                            ref={(e) => this.confirmPassword = e}
                                                            onKeyPress={(e) => this.submitOnEnter(e)}
                                                            style={{ fontSize: fontSize.breadcrumb + 'px' }}
                                                        />
                                                        {regsConfirmPasswordErrorSpan ? regsConfirmPasswordErrorSpan : ''}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group checkBoxWrapper">
                                                <input title={language.language.consent[0][lang]} type="checkbox" className="checkbox-btn" checked={this.state.check === 1 ? true : false} onClick={this.enableRegistrationButton.bind(this)} />
                                                <label className="checkbox-inline-policy label-text" style={{ fontSize: fontSize.breadcrumb + 'px' }}>
                                                    {language.language.consent[0][lang]} <span className="purple-color" onClick={(e) => this.openModalPolicy(e)}> {language.language.consent[1][lang]}</span>
                                                </label>
                                            </div>
                                            {/* <div className="form-group">
                                            <label className="noteplus30">{language.language.all_fields_mandatory[0][lang]}</label>
                                        </div> */}
                                            <div className="form-group buttonArea">
                                                <div className="buttons">
                                                    <button type="button" className="giftcard_button cardnone_button" style={{ fontSize: fontSize.breadcrumb + 'px' }} onClick={() => this.authenticate()}>{language.language.REGISTER[0][lang]}</button>


                                                </div>
                                            </div>

                                            <ul className="guest-links">
                                                <li style={{ fontSize: fontSize.breadcrumb + 'px' }} >{language.language.already_account[0][lang]}<a href="javascript:void(0)" data-toggle="modal" onClick={(e) => this.openModal(e)} data-target="#myModal" style={{ fontSize: fontSize.breadcrumb + 'px' }}> {language.language.login_here[0][lang]}</a></li>
                                            </ul>
                                        </fieldset>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </ReactModal>
                </div>
                {/* new modal end */}

                {/* ForgetPassword Modal */}
                <div className="modal-box" id="">
                    <ReactModal isOpen={this.state.modalForget} onRequestClose={this.closeModalForget} closeTimeoutMS={500}
                        className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel' }}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div className="modal-content landingcustomemodal">
                            <div className="modal-header giftcart_title_header">
                                <h4 className="modal-title giftcart_all_header" style={{ fontSize: fontSize.breadcrumb + 'px' }}>{language.language.Forgot_Password[0][lang]}</h4>
                                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModalForget}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <fieldset>
                                        {flashSuceessMessageSpan !== "" ?
                                            <div className="alert alert-success alert_succesfull clearfix " role="alert">
                                                <div className="successbtn"><img src={successimg} alt="successimg" title="successimg" /></div>
                                                <p className="text-center clearfix"> {flashSuceessMessageSpan} </p>
                                            </div>

                                            : ''}
                                        {flashErrorMessageSpan ?
                                            <div className="alert alert-success alert_succesfull adpadding30 clearfix " role="alert">
                                                <span><img src={errorimg} alt="errorimg" title="errorimg" /></span>
                                                <p className="clearfix">{flashErrorMessageSpan}</p>
                                            </div>
                                            : ''}
                                        <div className="form-group">
                                            <label htmlFor="email" style={{ fontSize: fontSize.tabs + 'px' }}>{language.language.email_id[0][lang]}</label>
                                            <input style={{ fontSize: fontSize.breadcrumb + 'px' }} title={language.language.email_id[0][lang]} type="email" className="form-control" id="email-forget-password" onChange={(e) => this.forgetEmail(e)} name="email" required onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = language.language.email_id[0][lang]} ref={(e) => this.email = e} placeholder={language.language.email_id[0][lang]} onKeyPress={(e) => this.submitOnEnter(e)} />
                                        </div>
                                        <div className="buttons">
                                            <button type="button" style={{ fontSize: fontSize.breadcrumb + 'px' }}  onClick={(e) => this.resetPWDLink()} className="btn btn-default login-btn" >{language.language.Resend_Password[0][lang]}</button>
                                            <button type="button" style={{ fontSize: fontSize.breadcrumb + 'px' }} className="btn btn-default register-btn" onClick={this.closeModalForget}>{language.language.CANCEL[0][lang]}</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </ReactModal>
                </div>

                {/**Policy modal open */}
                <div className="modal-box" id="">
                    <ReactModal closeTimeoutMS={500} isOpen={this.state.policyModalopen} onRequestClose={this.closeModalPolicy} className="modal-dialog-box fullModal" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel modalPolicy' }}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div className="modal-content">
                            <div className="modal-header polcy-header">
                                <h4 className="modal-title"></h4>
                                <button className="styles_closeButton__20ID4 closemodalbtn pol-close" onClick={this.closeModalPolicy}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                            </div>
                            <div className="modal-body newsseventall container privacy-body">
                                <form>
                                    <fieldset>
                                        
                                        <div className="row">
                                            {/* <p> Consent to receiving promotional and marketing emails from Northgate Gonzalez, LLC and its affiliates and agree to their privacy policy</p> */}
                                            <Consent lang = {lang} />
                                          
                                            <div className="formbtn pol-btn">
                                                <button type="button" className="giftcard_button mgr10 pull-right" onClick={this.closeModalPolicy}>{language.language.CLOSE[0][lang]}</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </ReactModal>
                </div>
                {/**Policy modal end */}


                {/**News Letter modal open */}
                <div className="modal-box" id="">
                    <ReactModal closeTimeoutMS={500} isOpen={this.state.newsLetterModalopen} onRequestClose={this.closeModalNewsLetter} className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel modalPolicy' }}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Confirm</h4>
                                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeModalNewsLetter}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                            </div>
                            <div className="modal-body News-letter">
                                <form>
                                    <fieldset>
                                        <div className="row">
                                            <div className="newsletter-text">
                                                <p>Are you sure, you do not want to hear from us?</p>
                                            </div>
                                        </div>

                                        <hr id="full-hr" />
                                        <div className="newsletter-button">
                                            <button type="button" className="giftcard_button mgr10" onClick={this.authenticate.bind(true)}>I Agree</button>
                                            <button type="button" className="giftcard_button mgr10" onClick={this.closeModalNewsLetter.bind(this)}>Cancel</button>

                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </ReactModal>
                </div>
                {/**News Letter modal end */}

                {/* Modal for note if quantity is greater than 999 */}
                <div className="modal-box" id="">
                    <ReactModal closeTimeoutMS={500} isOpen={this.state.maxQtyNote} onRequestClose={this.closeNote} className="modal-dialog-box" center classNames={{ overlay: 'LoginFormoverlay', modal: 'LoginFormModel modalPolicy' }}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Note</h4>
                                <button className="styles_closeButton__20ID4 closemodalbtn" onClick={this.closeNote}><svg className="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg></button>
                            </div>
                            <div className="modal-body News-letter">
                                <form>
                                    <fieldset>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <div className="newsletter-textnew">

                                                
                                                <p>In an order, the maximum allowed quantity for a gift card of a denomination is 999.</p>
                                            </div>
                                            </div>
                                        </div>

                                        <hr id="full-hr" />
                                        <div className="newsletter-button">
                                           
                                            <button type="button" className="giftcard_button mgr10" onClick={this.closeNote.bind(this)}>Ok</button>

                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </ReactModal>
                </div>
                {/* End modal  */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cardTypeListData: state.cardTypeList,
        denominations: state.denominationList,
        customerLoginInfo: state.customerLogin,
        customerRegisterInfo: state.customerRegister,
        sendOtp: state.sendOtp,
        resetCustomerPWDResponse: state.resetCustomerPWD,
        customerList: state.customerList,
        searchAndCreateUser: state.searchAndCreateUser,
        discountList: state.discountList,
       
    };
}

export default connect(mapStateToProps, { getCardTypeAction, denominationListAction, customerRegisterAction, customerLoginAction, sendOTPAction, customerResetPWDAction, customerListAction, searchUserAction, discountListAction })(OrderManagements);
