

const ciamAddressModal = (state)=> {
	try {
		let addresses;
		let pimAddress=[]
		let tempAddress = []
		if(state.addresses !== null && state.addresses.customerAddress.length > 0 && state.pimaddress.length===1) {
				 
			let title = state.pimaddress[0].addressType;
			let street = state.pimaddress[0].address1;
			let zip  = state.pimaddress[0].zip
			let tempAddress1 = state.addresses.customerAddress.filter(add=>(title===add.title && street === add.street && +zip === +add.zip))
			let tempAddress2 = state.addresses.customerAddress.filter(add =>(title !== add.title && street !== add.street && +zip !== +add.zip))
			let tempAddress3 = state.addresses.customerAddress.find(add=>{
				if(title === add.title && ( street !== add.street || +zip !== +add.zip) ) {
					return true
				}
				if( street === add.street && (  +zip !== +add.zip || title !== add.title) ) {
					return true
				}
				if( +zip === +add.zip && (  +zip !== +add.zip || title !== add.title) ) {
					return true
				}
			})
			
			if(tempAddress1.length  >= 1 ){
				tempAddress = []
			}
			if(tempAddress3===undefined && tempAddress2.length === state.addresses.customerAddress.length ) {
				let tempAdd ={
					"title":state.pimaddress[0].addressType,
					"street":state.pimaddress[0].address1,
					"city": state.pimaddress[0].city,
					"state": state.pimaddress[0].state,
					"zip": state.pimaddress[0].zip,
					"country": state.pimaddress[0].country,
					"isPrimary": state.pimaddress[0].status === true ? true : false,
					"isVerified": false,
					}
				pimAddress = [tempAdd]
			} else if(tempAddress3!==undefined)   {
				let tempAddedit ={
					"title":state.pimaddress[0].addressType,
					"street":state.pimaddress[0].address1,
					"city": state.pimaddress[0].city,
					"state": state.pimaddress[0].state,
					"zip": state.pimaddress[0].zip,
					"country": state.pimaddress[0].country,
					"isPrimary": state.pimaddress[0].status === true ? true : false,
					"isVerified": false,
					"fcId" : tempAddress3.fcId
					}
				pimAddress = [tempAddedit]
			}
		
		} else if (state.addresses === null ) {
			let tempAdd ={
				"title":state.pimaddress[0].addressType,
				"street":state.pimaddress[0].address1,
				"city": state.pimaddress[0].city,
				"state": state.pimaddress[0].state,
				"zip": state.pimaddress[0].zip,
				"country": state.pimaddress[0].country,
				"isPrimary": state.pimaddress[0].status === true ? true : false,
				"isVerified": false,
				}
			pimAddress = [tempAdd]
		}
	
		return ({
		"source": "gift_card",
		"prosperaId": state.ngId, 
		"userType": "Customer",
		"isAgreed": state.consent===1?true:false,
		"isNewsletters":state.consent===1?true:false,
		"isSMS": state.consent===1?true:false,
		"isEmail": state.consent===1?true:false,
		"isOptin": state.consent===1?true:false,
		"isActive": state.consent===1?true:false,
		"address": {
			"customerAddress": [...pimAddress]
		}
	})

	} catch(err) { console.log("error",err);}

}

export default ciamAddressModal ;


