import React, { Component } from 'react';
import OrderManagements from './orderManagements';
import OrderManagementDetails from './orderDetailsManagements';
import ShippmentPage from './shippmentPage'
import PaymentPage from './paymentPage'
//import Dashboard from './../Home/dashboard';

// Main calss for OrderManagement
class OrderManagement extends Component {
    componentDidMount() {
        sessionStorage.userType = 'customer'
    }

    render() {
        let OrderManagement = '';
        if (sessionStorage.userID == '') {
            // this.props.history.push('/');
            // window.location.reload();
            window.location.href = '/'
            return OrderManagement
        }
       
        if (this.props.location.state == undefined && sessionStorage.userID != '') {
         //   let customerInfo=this.props.location.state.customerLoginInfo;
            OrderManagement = <OrderManagements orderManagementProps={this.props} cardType={sessionStorage.cardType} />
        } else if (this.props.location.state.pageType != undefined && this.props.location.state.pageType === 'cartDetails' && sessionStorage.userID != '') {
            let cardList = this.props.location.state.data;
          let customerInfo=this.props.location.state.customerLoginInfo;
            OrderManagement = <OrderManagementDetails cardListProps={this.props} cardListDetails={cardList} customerInfo={customerInfo}/>
        } else if (this.props.location.state.pageType != undefined && this.props.location.state.pageType == 'shippmentPage' && sessionStorage.userID != '') {
            let cardList = this.props.location.state.data;
            let customerInfo=this.props.location.state.customerLoginInfo;
            OrderManagement = <ShippmentPage shipmentPageProps={this.props} cardListDetails={cardList} customerInfo={customerInfo}/>
        }
        else if (this.props.location.state.pageType != undefined && this.props.location.state.pageType == 'paymentPage' && sessionStorage.userID != '') {
            let cardList = this.props.location.state.data;
           // let customerInfo=this.props.location.state.customerLoginInfo;
            OrderManagement = <PaymentPage paymentPageProps={this.props} componentID={this.props} cardListDetails={cardList} />
        }
        else {
            OrderManagement = <div>this is index page</div>
        }
        return (OrderManagement);
    }
}
export default OrderManagement;
