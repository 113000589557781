import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import AddEditDiscount from './addEditDiscount'
import { discountListAction, deleteDiscountAction } from '../../../Actions/Admin/discountManagementAction';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";
import ReactLoading from 'react-loading'
import moment from "moment"
import * as HelperUtil from "../../../utils/common-helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datetime from 'node-datetime';
import * as constant from '../../../Constants/';
import Helmet from 'react-helmet';


/**
 * Class for DiscountPage for Admin
 */
class DiscountManagements extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            discountList: [],
            discountDetails: '',
            pageactive: 1,
            count: 0,
            startDate: new Date(),
            endDate: new Date(),
            sDateToCompare: '',
            eDateToCompare: '',
            filteredDataList: [],
            filterBy: '',
            Loading: false,
            activeClassName: '',
            filterData: '',
            checkPointUpdateState: false,
            sortOrder: '',
            fliterStartDate: 'From',
            fliterEndDate: 'To',
            sortField: '',
        };
    }

    /**
     * Method for opennig AddeditDiscount Modal
     * @param {event}
    */
    onOpenModal = (event) => {
        this.setState({ open: true, discountDetails: '' });
    };

    /**
     * Method for update state for close modal
    */
    status = () => {
        this.setState({
            open: false,
            filterBy: 'Select',
            filterData: '',
            filteredDataList: this.state.discountList,
            startDate: new Date(),
            endDate: new Date()
        });
    }

    /**
     * React Built in method 
     * invoked just before component about to mount 
     * 
     */
    componentWillMount() {
        this.setState({
            activeClassName: this.props.pathParam,
            Loading: true
        })
        this.props.discountListAction(sessionStorage.userID);
    }

    /**
    * React Built method 
    * keep waiting for change in props 
    * @param {*} nextProps 
    */
    async componentWillReceiveProps(nextProps) {
        try {
            if (nextProps.discountList && nextProps.discountList.data && nextProps.discountList.data.error === false) {
                this.setState({
                    discountList: nextProps.discountList.data.data.data,
                    filteredDataList: nextProps.discountList.data.data.data,
                    listToFilter: nextProps.discountList.data.data.data,
                    Loading: false,
                    checkPointUpdateState: false
                })
            } else {

                this.setState({ Loading: false, checkPointUpdateState: false })
            }
            if (nextProps.hasOwnProperty('deleteDiscount') && nextProps.deleteDiscount.data !== null) {
            }
            if (nextProps.deleteDiscount && nextProps.deleteDiscount.data) {
                if (this.state.checkPointUpdateState) {
                    await this.props.discountListAction(sessionStorage.userID)
                    this.props.componentID.history.push("/ng-admin/discountmanagement")
                }
            }
        } catch (e) { console.log("eerorr ", e) }
    }

    /**
     * Method to delete the discount
     * @param{event}
     */
    deleteDiscount(e) {
        let self = this
        var data = {
            "id": e.id,
            "loggedInUser": sessionStorage.userID,
            "language": "en"
        }

        /** Method to call the alerts*/
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm-popup AddUserModelform">
                        <div className="modal-header giftcart_title_header"><h3 className="modal-title giftcart_all_header"> Are you sure?</h3></div>
                        <div className="modal-body">
                            <p>You want to delete the discount</p>
                            <div className="formbtn">
                                <button type="button" className="giftcard_button mgr10"
                                    onClick={() => {

                                        try {
                                            let hmltCollection = document.getElementsByTagName('tbody')
                                            let hmltCollectionArray = [].slice.call(hmltCollection[0].children)
                                            if (hmltCollectionArray.length <= 1) {
                                                self.setState({
                                                    Loading: true,
                                                    checkPointUpdateState: true,
                                                    pageactive: 1,
                                                    count: 0
                                                })
                                                this.props.deleteDiscountAction(data)
                                                onClose()
                                            }
                                            else {
                                                self.setState({
                                                    Loading: true,
                                                    checkPointUpdateState: true
                                                })
                                                this.props.deleteDiscountAction(data)
                                                onClose();
                                            }
                                        } catch (e) { console.log("confirm Alert ", e) }
                                    }}
                                >
                                    Confirm
                                </button>
                                <button type="button" onClick={onClose} className="giftcard_button cardnone_button ">No</button></div>
                        </div>
                    </div>
                );
            }
        })
    }

    /**
    * Method for edit discount details
    * 
   */
    editDiscount(key) {
        this.setState({ open: true, discountDetails: key });
    }

    /**
     * Method to change the active page
     * @param {*} page 
     */
    handlePageChange = (pageNumber) => {



        var count = pageNumber == 1 ? 0 : (pageNumber - 1) * 10;
        this.setState({
            count: count,
            pageactive: pageNumber
        })
    }

    /**
     * Method for auto search 
     * @param {*} event 
     */
    filterSearch(event) {
        let self = this
        var newDataList = []
        newDataList = this.state.listToFilter
        var val = event.target.value
        this.setState({
            filterData: val
        })
        var newArr = newDataList.filter(function (e) {
            if (self.state.filterBy == 'Discount') {
                if (
                    ((typeof e.percent != "undefined" && e.percent != null && e.percent !== "") && e.percent.toString().includes(val))
                ) {
                    return e
                }
            }
            else if (self.state.filterBy == 'Payment Type') {
                if (
                    ((typeof e.paymentType != "undefined" && e.paymentType != null && e.paymentType !== "") && e.paymentType.toLowerCase().includes(val.toLowerCase()))
                ) {
                    return e
                }
            }
            else if (self.state.filterBy == 'Amount' && val !== '') {
                if ( e.amountFrom !== "" && e.amountTo !== "" ) {
                        return e.amountFrom <= Number(val) && e.amountTo >= Number(val) ? e : ''
                    }
            }
            else {
                return e
            }
        })
        this.setState({
            filteredDataList: newArr,
            pageactive: 1
        })
    }

    /**
     * Method to set the filter value
     */
    setFilterBy(e) {
        var val = e.target.value
        this.setState({
            filterBy: val,
            filterData: '',
            filteredDataList: this.state.listToFilter,
            startDate: moment(new Date()).format('MM/DD/YYYY'),
            endDate: moment(new Date()).format('MM/DD/YYYY'),
            fliterStartDate: 'From',
            fliterEndDate: 'To',
        })
    }

    /****
     *Method for formatting date  
     * @param{date}
     */
    handleChangeStartDate(date) {
        try {
            let date_ = date.target !== undefined ? date.target.value :false
            
            let data = /^[0-9-/]*$/
            if( !data.test(date_) && date_!==false) { 
              
              date.target.value = ''
                  
            } else { 
                var pastDateTime = datetime.create(date);
                var modifedate = moment(pastDateTime.now()).format('YYYY-MM-DD HH:mm');
                let endDate = this.state.endDate != '' ? moment(this.state.endDate).format('YYYY-MM-DD HH:mm') : ''
                let {filteredDataList, filterBy, filterData, discountList} = this.state
                    if(filterBy !== '' && filterData !== '' && discountList.length > 0){
                    switch (filterBy) {
                        case "Discount":
                            filteredDataList = discountList.filter((data)=>  data.percent.includes(filterData))
                            break;
                        case "Payment Type":
                            filteredDataList = discountList.filter((data)=>  data.paymentType.toLowerCase().includes(filterData.toLowerCase()))
                            break;
                        case "Amount":
                            filteredDataList = discountList.filter((data)=> data.amountFrom <= Number(filterData) && Number(filterData) <= data.amountTo )
                            break;
                        default:
                        filteredDataList = discountList
                    }
                }
                const result = HelperUtil._getFilteredCustomerListByDate(moment(date).format('YYYY-MM-DD HH:mm'), filteredDataList, endDate)
                this.setState({
                    startDate: date,
                    sDateToCompare: modifedate,
                    filteredDataList: result,
                    fliterStartDate: moment(date).format('MM/DD/YYYY'),
                    // filterBy: '',
                    // filterData: '',
                    // filteredDataList: this.state.listToFilter,
                });}
        } catch(e) {console.log('eror ',e)}
       
    }

    /****
     *Method for formatting date  
     * @param {date}
     */
    handleChangeEndDate(date) {
        try {
            let date_ = date.target !== undefined ? date.target.value :false
            
            let data = /^[0-9-/]*$/
            if( !data.test(date_) && date_!==false) { 
              
              date.target.value = ''
                  
            } else {
                var pastDateTime = datetime.create(date);
                var modifedate = moment(pastDateTime.now());
                let {filteredDataList, filterBy, filterData, discountList} = this.state
                    if(filterBy !== '' && filterData !== '' && discountList.length > 0){
                    switch (filterBy) {
                        case "Discount":
                            filteredDataList = discountList.filter((data)=>  data.percent.includes(filterData))
                            break;
                        case "Payment Type":
                            filteredDataList = discountList.filter((data)=>  data.paymentType.toLowerCase().includes(filterData.toLowerCase()))
                            break;
                        case "Amount":
                            filteredDataList = discountList.filter((data)=> data.amountFrom <= Number(filterData) && Number(filterData) <= data.amountTo )
                            break;
                        default:
                        filteredDataList = discountList
                    }
                }
                const result = HelperUtil._getFilteredCustomerListByDate(moment(this.state.startDate).format('YYYY-MM-DD HH:mm'), filteredDataList, moment(date).format('YYYY-MM-DD HH:mm'))
                this.setState({
                    endDate: date,
                    eDateToCompare: modifedate,
                    filteredDataList: result,
                    fliterEndDate: moment(date).format('MM/DD/YYYY'),
                    // filterBy: '',
                    // filterData: '',
                    // filteredDataList: this.state.listToFilter,
                });}
        } catch(e) {console.log("eror iin calender",e)}
        
    }

    /** Method to reset the date*/
    resetList(e) {
        var val = e.target.value
        this.setState({
            filterBy: '',
            filterData: '',
            startDate: moment(new Date()).format('MM/DD/YYYY'),
            endDate: moment(new Date()).format('MM/DD/YYYY'),
            fliterStartDate: 'From',
            fliterEndDate: 'To',
            filteredDataList: this.state.listToFilter,
        })
    }

    /****
     *Method for sorting data onclick
    *@param {dataList}
    *@param {Name}
    */
    sortBy(dataList, Name) {
        this.setState({
            sortField: Name
        })

        let response = constant.SORT_BY(dataList, Name, this.state.sortOrder)
        this.setState({
            filteredDataList: response.data,
            sortOrder: response.sortOrder
        })
    }

    render() {
    
        let { filteredDataList } = this.state
        var list = filteredDataList != undefined ? filteredDataList.slice((this.state.pageactive - 1) * 10, (this.state.pageactive) * 10) : ''
        return (
            <div className="admin_top">
                {
                    this.state.Loading === true && <div className="loader-react">
                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                    </div>
                }
                <Helmet>
                    <title>Discount Management</title>
                </Helmet>
                <div className="content-wrapper" className="d-flex flex-column">
                    <Header />
                    <div className="main">
                        <Navigation activeClassName={this.state.activeClassName} />
                        <div className="dashboardContent">
                            <div className="container-fluid">
                                <div className="admin-header">
                                    <div className="user-dashboard user-management paddingBottom" id="user-dashboard">
                                        <div className="row mar0">
                                            <div className="col-md-12">
                                                <h2>Discount Management</h2>
                                                <div className="row">
                                                    <div className="pull-left col-md-9 pr-0 shipping-filter">
                                                        <div className="row">
                                                            <div className="form-group filter-input">
                                                                <div className="styled-select slate">
                                                                    <select className="select-dropdown" value={this.state.filterBy} name="test" onChange={(e) => this.setFilterBy(e)}>
                                                                        <option value="Select">Select</option>
                                                                        <option value="Discount">Discount</option>
                                                                        <option value="Payment Type">Payment Type</option>
                                                                        <option value="Amount">Amount</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group filter-input">
                                                                <div className="inner-addon search-input">
                                                                    <input type="text" className="form-control input-style search-icon" placeholder="Search" value={this.state.filterData} onChange={this.filterSearch.bind(this)} />
                                                                </div>
                                                            </div>
                                                            <div className="datePicker">
                                                                <div className="form-group filter-calendar datePickerReact">
                                                                    <div className="row">
                                                                        <div className="col-sm-6">
                                                                            <DatePicker 
                                                                                className='form-control calendar-icon' 
                                                                                placeholderText={this.state.fliterStartDate} 
                                                                                maxDate={new Date()} 
                                                                                disabled={this.state.filter} 
                                                                                inputProps={{ readOnly: true }} 
                                                                                onChange={this.handleChangeStartDate.bind(this)} 
                                                                                onChangeRaw = {this.handleChangeStartDate.bind(this)} 
                                                                                />
                                                                        </div>
                                                                        <div className="col-sm-6 secondDatePikker">
                                                                            <DatePicker 
                                                                                className='form-control calendar-icon' 
                                                                                placeholderText={this.state.fliterEndDate} 
                                                                                disabled={this.state.filter} 
                                                                                maxDate={new Date()} 
                                                                                inputProps={{ readOnly: true }} 
                                                                                onChange={this.handleChangeEndDate.bind(this)}
                                                                                onChangeRaw = {this.handleChangeEndDate.bind(this)} 
                                                                                 />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="refresh-btn">
                                                                <i onClick={this.resetList.bind(this)} title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="pull-right col-md-3 pl-0 text-center mbl-scn">
                                                        <button className="giftcard_button pull-right button-padding" onClick={this.onOpenModal}>ADD DISCOUNT</button>
                                                    </div>

                                                </div>
                                                <div className="table-responsive">
                                                    <table id="example" className="table table-striped table-bordered" cellSpacing="0" width="100%">
                                                        <thead>
                                                            <tr>
                                                                <th className="customer-name-td">#</th>
                                                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'percent')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'percent' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Discount %</th>
                                                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'amountFrom')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'amountFrom' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Amount ($)</th>
                                                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'paymentType')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'paymentType' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Payment Type</th>
                                                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'validTill')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'validTill' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Valid From</th>
                                                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'validTill')}>
                                                                    {this.state.sortField !== '' ?
                                                                        this.state.sortField === 'validTill' ?
                                                                            <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                            : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                        : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                                    Valid Till</th>
                                                                <th className="customer-name-td">Status</th>
                                                                <th className="action-td">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                list.length > 0 ? list.map((key, index) => {
                                                                    return <tr key={index}>
                                                                        <td className="customer-name-td"><span onClick={(e) => this.editDiscount(key)}>{(index + 1) + this.state.count}</span></td>
                                                                        <td>{key.percent}</td>
                                                                        <td>{key.amountFrom + (key.amountTo == 0 ? ' and above' : ' - ' + key.amountTo)}</td>
                                                                        <td>{key.paymentType}</td>
                                                                        <td>{key.validFrom != null ? moment( new Date(key.validFrom)).format('MM/DD/YYYY') : ''}</td>
                                                                        <td>{moment(new Date(key.validTill)).format('MM/DD/YYYY')}</td>
                                                                        <td> <a className={(key.status === true ? 'green' : 'blue')} ><i className="fa fa-circle"></i></a>
                                                                        </td>
                                                                        <td className="action-td"><a onClick={(e) => this.editDiscount(key)} href="#" ><i className="fa fa-pencil" style={{ "margin-left": '5px' }}></i></a>
                                                                            <a onClick={(e) => this.deleteDiscount(key)} href="#" ><i className="fa fa-trash" style={{ "margin-left": '5px' }}></i></a>
                                                                        </td>
                                                                    </tr>
                                                                }) : <tr><td className="recordNotFound" colSpan="9">No record found</td></tr>
                                                            }
                                                        </tbody>
                                                    </table>


                                                    <ul className="pagination pull-right my-1">
                                                        <Pagination
                                                            hideDisabled
                                                            activePage={this.state.pageactive}
                                                            itemsCountPerPage={10}
                                                            totalItemsCount={this.state.filteredDataList.length}
                                                            pageRangeDisplayed={5}
                                                            onChange={this.handlePageChange}
                                                            firstPageText='First'
                                                            lastPageText='Last'
                                                            prevPageText="Prev"
                                                            nextPageText="Next"
                                                        />
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddEditDiscount checkModal={this.state.open} modalClose={this.status} redirectPage={this.userRedirect} discountDetails={this.state.discountDetails}
                    locationProps={this.props.componentID}
                />
            </div >)
    }
}

const mapStateToProps = (state) => {
    return {
        discountList: state.discountList,
        deleteDiscount: state.deleteDiscount
    };
}

export default connect(mapStateToProps, { discountListAction, deleteDiscountAction })(DiscountManagements);