import * as constant from '../../Constants/';
const CUSTOMERCIAMJSON = (userData,prosperId,consent) =>({
    "source": "gift_card",
    "firstName": userData.firstName,
    "lastName": userData.lastName,
    "email":  userData.email,
    "isActive": true,
    "dialCode": "+1",
    "primaryPhoneNumber": userData.phoneNumber,
    "isPhoneVerified": true,
    "prosperaId": prosperId,
    "alternatePhone": {
        "customerPhone": [
        {
            "source": "gift_card",
            "phoneType": "cell",
            "dialCode": "+1",
            "phoneNo": userData.phoneNumber
        }
        ]
    },
    "alternateEmail": {
        "customerEmail": [
        {
            "source": "gift_card",
            "emailType": "Home",
            "email": userData.email
        }
        ]
    },
    "sourceMapping": {
        "customerSources": [
            {
                "firstName":  userData.firstName,
                "lastName": userData.lastName,
                "sourceId": userData.pimId,
                "source": "gift_card",
                "isSource": true
            }
        ]
    },
    "isOptin": true,
    "optin": [
        {
        "optinCategoryId": constant.OPTION_CATEGORY_ID,
        "updatedsource": "gift_card",
        "subscribe": consent
        }
    ],
    "isUpdateAllKeys": true
  })

export default CUSTOMERCIAMJSON;