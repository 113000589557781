import * as constant from '../../../Constants/';
const profileData = (data)=> {

    try {
        return {
            "source": "gift_card",
            "prosperaId": data.ngId,
            "isUpdateAllKeys":true,
            "dialCode": "+1",
            "primaryPhoneNumber": data.primaryPhoneNumber.replace(/[^a-zA-Z0-9]/g, ''),
            "isPhoneVerified": true,
            "alternatePhone": {
                "customerPhone": [
                {
                    "source": "gift_card",
                    "phoneType": "cell",
                    "dialCode": "+1",
                    "phoneNo": data.secondaryPhone.replace(/[^a-zA-Z0-9]/g, '')
                }
                ]
            },
            "isOptin": true,
            "optin": [
                {
                "optinCategoryId": constant.OPTION_CATEGORY_ID,
                "updatedsource": "gift_card",
                "subscribe": data.consent===1 ? true : false
                }
            ]
              

        }
    } catch(err) {console.log("e",err)}
   
}
export default profileData;

