import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import totalorder from '../../../public/img/total-order.png';
import totpendingorderalorder from '../../../public/img/pending-order.png';
import cancelorder from '../../../public/img/cancel-order.png';
import giftcardactive from '../../../public/img/giftcardactive.png';
import PendingCard from '../../../public/img/PendingCard.png';
import { dashboardOrderListAction, pendingOrderListAction, cancelledOrderListAction, activatedGiftCardListAction, pendingGiftCardListAction } from '../../../Actions/Admin/dashboardManagementAction';
import { connect } from 'react-redux';
import moment from "moment";
import _ from 'lodash';
import ReactLoading from 'react-loading';
import * as HelperUtil from "../../../utils/common-helper";
import * as constant from "../../../Constants"
import Helmet from 'react-helmet';



/** Class for displaying all details of order list*/
class Home extends Component {

    constructor() {
        super();
        this.state = {
            dashboardOrderList: [],
            listToFilter: [],
            filteredDataList: [],
            pendingOrderList: [],
            cancelledOrderList: [],
            activatedGiftCardList: [],
            pendingGiftCardList: [],
            pageactive: 1,
            Loading: false,
            activeClassName: '',
            listDataList: [],
            sortOrder: '',
            sortField: '',
        }
    };

    /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
    componentWillMount() {
        let pathParam = this.props.location.pathname.split("/").pop()
        this.setState({ activeClassName: pathParam })
        this.setState({ Loading: true })
        this.props.dashboardOrderListAction(sessionStorage.userID);
        let pendingOrderListdata = { "loggedInUser": `${sessionStorage.userID}`, "language": "en", "trackingUrl": "", "pendingOrder": "true" }
        let cancelledOrderListJson = { "loggedInUser": `${sessionStorage.userID}`, "language": "en", "cancelledOrder": "true" }
        let activatedGiftcardListJson = { "loggedInUser": `${sessionStorage.userID}`, "language": "en", "trackingUrl": "true", "activatedGiftCard": "true" }
        let pendingGiftCardListJson = { "loggedInUser": `${sessionStorage.userID}`, "language": "en", "trackingUrl": "true", "pendingGiftCard": "true" }
        this.props.pendingOrderListAction(pendingOrderListdata);
        this.props.cancelledOrderListAction(cancelledOrderListJson);
        this.props.activatedGiftCardListAction(activatedGiftcardListJson);
        this.props.pendingGiftCardListAction(pendingGiftCardListJson);
    }

    /**
* React Built method 
* keep waiting for change in props 
* @param {*} nextProps 
*/
    componentWillReceiveProps(nextProps) {
        try {
            if (nextProps.dashboardOrderList != undefined && nextProps.dashboardOrderList.data) {
                this.setState({
                    dashboardOrderList: nextProps.dashboardOrderList.data.data.data,
                    listToFilter: nextProps.dashboardOrderList.data.data.data,
                    filteredDataList: nextProps.dashboardOrderList.data.data.data,
                    listDataList: nextProps.dashboardOrderList.data.data.data ? nextProps.dashboardOrderList.data.data.data.slice(0, 10) : '',
                    Loading: false
                })
            } else {
                this.setState({
                    Loading: false
                })
            }
            if (nextProps.pendingOrderList != undefined && nextProps.pendingOrderList.data) {
                this.setState({
                    pendingOrderList: nextProps.pendingOrderList.data.data.data
                })
            } else {
                this.setState({
                    Loading: false
                })
            }
            if (nextProps.cancelledOrderList != undefined && nextProps.cancelledOrderList.data) {
                this.setState({
                    cancelledOrderList: nextProps.cancelledOrderList.data.data.data
                })
            } else {
                this.setState({
                    Loading: false
                })
            }
            if (nextProps.activatedGiftCardList != undefined && nextProps.activatedGiftCardList.data) {
                this.setState({
                    activatedGiftCardList: nextProps.activatedGiftCardList.data.data.data
                })
            } else {
                this.setState({
                    Loading: false
                })
            }
            if (nextProps.pendingGiftCardList != undefined && nextProps.pendingGiftCardList.data) {
                this.setState({
                    pendingGiftCardList: nextProps.pendingGiftCardList.data.data.data
                })
            }
            else {
                this.setState({
                    Loading: false
                })
            }
        } catch (err) { }
    }

    //getting order inf
    getOrderInfo(data) {
        let self = this
        self.props.history.push({
            pathname: "/ng-admin/ordermanagement",
            state: { displayOrderInfo: data }
        })
        if (data.acknowledge == true && data.orderedProduct[0].productRange.length < 1 && data.trackingUrl == '') {
            self.props.history.push({
              pathname: "/ng-admin/ordermanagement",
              state: {
                pageType: 'rangePage',
                orderDetails: data,
                pin: data.pin,
                orderId: data.orderId
              }
            })
          }
    }

    /***
     * Method for handle navigation on click
     * @param(click event)
     */

    redirectURL = (e) => {
        let self = this
        let totalOrders = '';
        let totalGiftCard = '';
        if (e == 0) {
            totalOrders = self.state.dashboardOrderList
        }
        if (e == 1) {
            totalOrders = self.state.pendingOrderList
        }
        if (e == 2) {
            totalOrders = self.state.cancelledOrderList
        }
        if (e == 3) {
            totalGiftCard = self.state.activatedGiftCardList
        }
        if (e == 4) {
            totalGiftCard = self.state.pendingGiftCardList
        }
        if (totalOrders) {
            self.props.history.push({
                pathname: "/ng-admin/ordermanagement",
                state: {
                    totalOrders: totalOrders
                }
            })
        }
        if (totalGiftCard) {
            self.props.history.push({
                pathname: "/ng-admin/giftcardmanagement",
                state: {
                    totalGiftCard: totalGiftCard
                }
            })
        }
    }

    /** Method for sorting data onclick*/
    sortBy(dataList, Name) {
        this.setState({
            sortField: Name
        })

        let response = constant.SORT_BY(dataList, Name, this.state.sortOrder)
        this.setState({
            listDataList: response.data,
            sortOrder: response.sortOrder
        })
    }

    render() {
        let { dashboardOrderList } = this.state
        let { pendingOrderList } = this.state
        let { cancelledOrderList } = this.state
        let { activatedGiftCardList } = this.state
        let { pendingGiftCardList } = this.state
        if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.roleName == '' || sessionStorage.length == 0) {
            this.props.history.push("/ng-admin");
        }
        let { listDataList } = this.state
        var list = listDataList.length ? listDataList : ''
    
        return (
            <div className="admin_top">
                {
                    this.state.Loading === true && <div className="loader-react">
                        <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
                    </div>
                }
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
                <div className="content-wrapper" className="d-flex flex-column">
                    <Header />
                    <div className="main">
                        <Navigation activeClassName={this.state.activeClassName} />
                        <div className="dashboardContent">
                            <div className="container-fluid">
                                <div className="admin-header">
                                    <div className="main-heading">
                                        <h2>Dashboard</h2>
                                    </div>
                                    <div className="content-page mar20">
                                        <div className="row mar0 Dashboard_showlist">

                                            <div onClick={(e) => this.redirectURL(0)} className="col-md-3 deshbord-box">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img src={totalorder} alt="" />
                                                        <div className="wigdet-two-content text-center">
                                                            <p>Total Orders</p>
                                                            <h2>{dashboardOrderList.length ? dashboardOrderList.length : 0}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.redirectURL(1)} className="col-md-3 deshbord-box">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img src={totpendingorderalorder} alt="" />
                                                        <div className="wigdet-two-content pending-order text-center">
                                                            <p>Pending Orders</p>
                                                            <h2>{pendingOrderList.length ? pendingOrderList.length : 0}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.redirectURL(2)} className="col-md-3 deshbord-box">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img src={cancelorder} alt="" />
                                                        <div className="wigdet-two-content order-cancel text-center">
                                                            <p>Cancelled Orders</p>
                                                            <h2>{cancelledOrderList.length ? cancelledOrderList.length : 0}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.redirectURL(3)} className="col-md-3 deshbord-box">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img src={giftcardactive} alt="" />
                                                        <div className="wigdet-two-content giftcard-active text-center">
                                                            <p>Activated Gift Card </p>
                                                            <h2>{activatedGiftCardList.length ? activatedGiftCardList.length : 0}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.redirectURL(4)} className="col-md-3 deshbord-box">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img src={PendingCard} alt="" />
                                                        <div className="wigdet-two-content pending-activation text-center">
                                                            <p>Pending Activation</p>
                                                            <h2>{pendingGiftCardList.length ? pendingGiftCardList.length : 0}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-dashboard user-management" id="user-dashboard">
                                        <div className="col-md-12">
                                            <h2>Recent Orders</h2>
                                            <table id="example" className="table table-respons table-striped table-bordered " cellSpacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(listDataList, 'orderNumber')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'orderNumber' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                            Order Number</th>
                                                        <th className=" all_cursorpointer" onClick={(e) => this.sortBy(listDataList, 'orderDate')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'orderDate' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                            Order Date</th>
                                                        <th width="10%" className=" all_cursorpointer" onClick={(e) => this.sortBy(listDataList, 'customerType')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'customerType' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                            Customer Type</th>
                                                        <th className=" all_cursorpointer" onClick={(e) => this.sortBy(listDataList, 'customerName')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'customerName' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                            Customer Name</th>
                                                        <th className=" all_cursorpointer" onClick={(e) => this.sortBy(listDataList, 'billingAddress1')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'billingAddress1' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                            Address</th>
                                                        <th className=" all_cursorpointer" onClick={(e) => this.sortBy(listDataList, 'eta')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'eta' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                            Expected Delivery Date</th>
                                                        <th className=" all_cursorpointer" onClick={(e) => this.sortBy(listDataList, 'shippingType')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'shippingType' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                           Shipping Type</th>
                                                        <th className=" all_cursorpointer  customer-name-td" onClick={(e) => this.sortBy(listDataList, 'total')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'total' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                            Amount</th>
                                                        <th className=" all_cursorpointer" onClick={(e) => this.sortBy(listDataList, 'paymentType')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'paymentType' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                            Payment Type</th>
                                                        <th className=" all_cursorpointer" onClick={(e) => this.sortBy(listDataList, 'currentStatus')}>
                                                            {this.state.sortField !== '' ?
                                                                this.state.sortField === 'currentStatus' ?
                                                                    <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                                                : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                                            Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        list.length > 0 ? list.map((key, index) => {
                                                            return <tr key={index}>
                                                                <td><span onClick={() => this.getOrderInfo(key)}> {key.orderNumber}</span></td>
                                                                <td>{moment(new Date(key.orderDate)).format('MM/DD/YYYY')}</td>
                                                                <td width="10%">{key.customerType}</td>
                                                                <td>{key.customerName}</td>
                                                                <td className='wrap-tablecell'>{key.shippingAddress1 + ', ' + key.shippingCity + ', ' + key.shippingState + ', ' + key.shippingCountry + ', ' + key.shippingZip}</td>
                                                                <td>{moment(new Date(key.eta)).format('MM/DD/YYYY')}</td>
                                                                <td>{key.shippingType != '' ? key.shippingType : 'Normal'}</td>
                                                                <td>$ {HelperUtil._getAmountCommaSeparator(Number(key.total).toFixed(2)).toString()}</td>
                                                                <td className='nowrap-tablecell'>{key.paymentType}</td>
                                                                <td>{key.currentStatus}</td>
                                                            </tr>
                                                        }) : <tr><td className="recordNotFound" colSpan="10">No record found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dashboardOrderList: state.dashboardOrderList,
        pendingOrderList: state.pendingOrderList,
        cancelledOrderList: state.cancelledOrderList,
        activatedGiftCardList: state.activatedGiftCardList,
        pendingGiftCardList: state.pendingGiftCardList,
    };
}

export default connect(mapStateToProps, { dashboardOrderListAction, pendingOrderListAction, cancelledOrderListAction, activatedGiftCardListAction, pendingGiftCardListAction })(Home);
