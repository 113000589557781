import * as constants from './../../Constants';
import axios from 'axios';
import * as serverRoutes from '../../Routes/serverRoutes';

/*
* Function to get discount list
  @param NULL
  @return object object of api resoponse
*/
export function discountListAction(userId) {
    return (dispatch) => {
        axios.post(serverRoutes.DISCOUNT_LIST, { userId })
            .then(function (response) {
                dispatch({
                    type: constants.DISCOUNT_MANAGEMENT_DATA,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.DISCOUNT_MANAGEMENT_DATA_ERROR,
                    payload: response.data
                })
            })
    }
}

/*
* Function to add discount
  @param NULL
  @return object object of api resoponse
*/
export function addDiscountAction(data) {
    return (dispatch) => {
        axios.post(serverRoutes.ADD_DISCOUNT, data)
            .then(function (response) {
                dispatch({
                    type: constants.ADD_DISCOUNT,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.ADD_DISCOUNT_ERROR,
                    payload: response.data
                })
            })
    }
}

/*
* Function to add discount
  @param NULL
  @return object object of api resoponse
*/
export function editDiscountAction(data) {
    return (dispatch) => {
        axios.post(serverRoutes.EDIT_DISCOUNT, data)
            .then(function (response) {
                dispatch({
                    type: constants.EDIT_DISCOUNT,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.EDIT_DISCOUNT_ERROR,
                    payload: response.data
                })
            })
    }
}


/*
* Function to delete discount
  @param NULL
  @return object object of api resoponse
*/
export function deleteDiscountAction(data) {
    return (dispatch) => {
        axios.post(serverRoutes.DELETE_DISCOUNT, data)
            .then(function (response) {
                dispatch({
                    type: constants.DELETE_DISCOUNT,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.DELETE_DISCOUNT_ERROR,
                    payload: response.data
                })
            })
    }
}

/*
* Function to get payment type for discount
  @param NULL
  @return object object of api resoponse
*/
export function paymentTypeAction(data) {
    return (dispatch) => {
        axios.post(serverRoutes.PAYMENT_TYPES, { data })
            .then(function (response) {
                dispatch({
                    type: constants.PAYMENT_TYEPE_DISCOUNT,
                    payload: response.data
                })
            })
            .catch(function (response) {
                dispatch({
                    type: constants.PAYMENT_TYEPE_DISCOUNT_ERROR,
                    payload: response.data
                })
            })
    }
}


