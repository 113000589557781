import React, { Component } from 'react';
import ShipManagements from './shipManagements';
import * as constant from '../../../Constants'

/** Main class for ShipManagement*/
class ShipManagement extends Component {

  componentDidMount() {
    try {
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.history.push("/ng-admin");
      } else if (sessionStorage.roleID != constant.ADMIN_USER && sessionStorage.isSuperAdmin != 'true') {
        this.props.history.push("/ng-admin/error");
      }
    } catch (err) {
    }
  }

  render() {
    let pathParam = this.props.location.pathname.split("/").pop()
    var ShipManagement;
    if (this.props.location.state == undefined) {
      ShipManagement = <ShipManagements componentID={this.props}
        pathParam={pathParam}
      />
    } else if (this.props.location.state == 'add') {
      ShipManagement = <div>this is add page</div>
    } else if (this.props.location.state == 'edit') {
      ShipManagement = <div>this is Edit page</div>
    } else {
      ShipManagement = <div>this is index page</div>
    }
    return (ShipManagement);
  }
}

export default ShipManagement;
