import React from "react"
import Dropzone from "react-dropzone"
const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16
};
const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box'
};
const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
}

const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};
export default class ImageUpload extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			files: [],
			preview: '',
			checkPointImage: true
		};
	}

	/**
	 * React Built in Method 
	 * keep waiting for cahnes in props 
	 * @param {nextProps}  
	 */
	componentWillReceiveProps(nextProps) {
		if (nextProps.clearImage) {
			this.setState({
				files: []
			})
		}
	}

	/**
	 * Method for handle upload file 
	 * @param {files}  
	 */
	onDrop(files) {
		try {
			if (files[0].size / 1024 / 1000 <= 2) {
				this.props.clearImageHandler()
				this.props.uploadImages(files)
				this.setState({
					files: files.map(file => Object.assign(file, {
						preview: URL.createObjectURL(file)
					}))
				});
			} else {
				this.setState({
					files: []
				})
				this.props.clearImageHandler()
				this.props.erorMessage()
			}
		} catch (e) { }
	}

	/**
	 * React built in method 
	 * invoked just before component about to unmount 
	 */
	componentWillUnmount() {
		// Make sure to revoke the data uris to avoid memory leaks
		this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
	}

	/**
	 * Method for handle upload image on drop image file 
	 * @param {file } 
	 */
	handleDrop(files) {
		if (files["0"]) {
			this.setState({
				Preview: files["0"].preview,
				Display: false
			})
			this.props.uploadImages(files)
		} else
			this.setState({
				Display: true,
				preview: null
			});
	}

	render() {
		const { files } = this.state;
		let thumbs = files.map(file => (
			<div style={thumb} key={file.name}>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
					/>
				</div>
			</div>
		));
		return (
			<section>
				<Dropzone
					accept="image/*"
					onDrop={this.onDrop.bind(this)}
				>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps()}>
							<input {...getInputProps()} />
						</div>
					)}
				</Dropzone>
				{this.props.homeSetting === undefined ?
					<aside style={thumbsContainer} className="aside">
						{thumbs}
					</aside> : ''}
			</section>
		)
	}
}
