import React, { Component } from 'react';
import CustomerLandingPage from './landingPageManagement'

/** Main class for LandingPageManagement*/
class LandingPageManagement extends Component {

  componentDidMount() {
    try {
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.history.push("/ng-admin");
      }
    } catch (err) {

    }
  }

  render() {
    let pathParam = this.props.location.pathname.split("/").pop()
    try {
      if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.history.push("/ng-admin");
      } else if (sessionStorage.hasOwnProperty("adminLoggedIn") && sessionStorage.isSuperAdmin != 'true') {
        if (sessionStorage.roleID != 3) {
          this.props.history.push("/ng-admin/error");
        }
      }
    } catch (err) {

    }
    var LandingPage;
    if (this.props.location.state == undefined) {
      LandingPage = <CustomerLandingPage componentID={this.props} pathParam={pathParam} />
    }
    return (LandingPage);
  }
}
export default LandingPageManagement;
