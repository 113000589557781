import React, { Component } from 'react';
import HomeSetting from './homeSettingPage';


// Main class for Home settings 
class HomeSettings extends Component {

    componentDidMount() {
        try {
            if (!(sessionStorage.hasOwnProperty("adminLoggedIn")) || localStorage.loggedIn == '' || sessionStorage.length == 0) {
              this.props.history.push("/ng-admin");
          } else if(sessionStorage.isSuperAdmin != 'true') {
            if(sessionStorage.roleID != 3) {
                this.props.history.push("/ng-admin/error");
              }
          }
          }catch (err){
      
          }
    }

    render() {
        let pathParam = this.props.location.pathname.split("/").pop()
        if (sessionStorage.loggedIn === '') {
            this.props.history.push("/ng-admin");
        }
        let HomeSettings;
        if (this.props.location.state == undefined) {
            HomeSettings = <HomeSetting componentID={this.props}
                pathParam={pathParam}
            />
        }
        return HomeSettings;
    }
}
export default HomeSettings;
