import React, { Component } from 'react';
import Header from '../Layout/Header';
import Navigation from '../Layout/Navigation';
import { connect } from 'react-redux';
import { orderListAction } from '../../../Actions/Admin/orderManagementAction';
import * as constant from '../../../Constants';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pagination from "react-js-pagination";
import { Alert } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactLoading from 'react-loading'
import * as HelperUtil from "../../../utils/common-helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datetime from 'node-datetime';
import jsPDF from 'jspdf';
import XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import PdfFormat from "./pdfFormat"
import { compose } from 'redux';
import Helmet from 'react-helmet';


/** Class for displaying OrderManagement List*/
class OrderManagements extends Component {

  constructor() {
    super();
    this.state = {
      pageactive: 1,
      filteredDataList: [],
      orderList: [],
      listToFilter: [],
      filterBy: '',
      startDate: new Date(),
      endDate: new Date(),
      flashMessageSuccess: '',
      flashMessageError: '',
      Loading: false,
      activeClassName: "",
      filterData: '',
      sDateToCompare: '',
      eDateToCompare: '',
      sortOrder: '',
      fliterStartDate: 'From',
      fliterEndDate: 'To',
      tableHeader: [
        "Order Number", "Customer Name", "Address", "Order Date", "Expected Delivery Date", "Shipment Type"
        , "Amount", "Pyment Type", "Activation Status"
      ],
      sortField: '',
    }
  }

  /**
    * Function for update state for close modal
    * 
  */
  status = () => {
    this.setState({ open: false }, function () {
    });
  }

  /**
 * React Life Cycle method 
 * Invoke just after chenge in dom 
 *
 * @param {*} nextProps 
 */
  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.orderList && nextProps.orderList.data && nextProps.orderList.data.error === false) {
        this.setState({
          orderList: nextProps.orderList.data.data.data,
          listToFilter: nextProps.orderList.data.data.data,
          filteredDataList: nextProps.orderList.data.data.data,
          Loading: false,
        })

      } else {
        this.setState({ Loading: false })

      }
      var winHeight = window.innerHeight;
      document.getElementById('user-dashboard').style.height = window.innerHeight - 25 + 'px';
      let reso_height = document.getElementById('user-dashboard').style.height
      document.getElementsByClassName('res_height')[0].style.height = reso_height.slice(0, -2) - 240 + 'px';
    } catch (e) { }
  }

  /**
* React Built in method 
* invoked just before component about to mount 
* 
*/
  componentWillMount() {
    try {
      if (localStorage.loggedIn == '' || sessionStorage.length == 0) {
        this.props.componentID.history.push("/ng-admin");
      }
    } catch (err) {

    }
    this.setState({ activeClassName: this.props.pathParam })
    if (this.props.totalOrders) {
      this.setState({
        orderList: this.props.totalOrders,
        listToFilter: this.props.totalOrders,
        filteredDataList: this.props.totalOrders
      })
    } else {
      this.setState({ Loading: true })
      
      this.props.orderListAction(sessionStorage.userID);
    }
  }

  /** Function for change active page pagination*/
  changeactive(page) {
    this.setState({
      pageactive: page
    })
  }

  /** Function for change page number in pagination*/
  handlePageChange = (pageNumber) => {
    this.setState({
      pageactive: pageNumber
    })
  }

  /** Function for filter result*/
  setFilterBy(e) {
    var val = e.target.value
    this.setState({
      filterBy: val,
      filterData: '',
      filteredDataList: this.state.listToFilter,
      startDate: moment(new Date()).format('MM/DD/YYYY'),
      endDate: moment(new Date()).format('MM/DD/YYYY'),
      fliterStartDate: 'From',
      fliterEndDate: 'To',
    })
  }

  /** Function for auto search*/
  filterSearch(event) {
    let self = this
    var newDataList = []
    newDataList = this.state.listToFilter
    var val = event.target.value
    this.setState({
      filterData: val,
    })
    var newArr = newDataList.filter(function (e) {
      if (self.state.filterBy == 'Customer Name') {
        if (
          ((typeof e.customerName != "undefined" && e.customerName != null && e.customerName !== "") && e.customerName.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Order Number') {
        if (
          ((typeof e.orderNumber != "undefined" && e.orderNumber != null && e.orderNumber !== "") && e.orderNumber.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Customer Type') {
        if (
          ((typeof e.customerType != "undefined" && e.customerType != null && e.customerType !== "") && e.customerType.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Shipment Type') {
        if (
          ((typeof e.shippingType != "undefined" && e.shippingType != null && e.shippingType !== "") && e.shippingType.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Amount') {
        if (
          ((typeof e.total != "undefined" && e.total != null && e.total !== "") && e.total.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Payment Type') {
        if (
          ((typeof e.paymentType != "undefined" && e.paymentType != null && e.paymentType !== "") && e.paymentType.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else if (self.state.filterBy == 'Status') {
        var status = ''
        if (e.acknowledge == true && (e.orderedProduct[0].productRange.length > 0) && e.activationStatus == '') {
          status = 'Shipped'
        }
        else if (e.acknowledge == true && e.activationStatus) {
          status = 'Activated'
        }
        else if (e.acknowledge == true) {
          status = 'Acknowledged'
        }
        else if (e.cancelStatus == true) {
          status = 'Cancelled'
        }
        else
          status = 'Pending'

        if (
          ((typeof status != "undefined" && status != null && status !== "") && status.toLowerCase().includes(val.toLowerCase()))
        ) {
          return e
        }
      }
      else {
        return e
      }
    })
    this.setState({
      filteredDataList: newArr,
      pageactive: 1,
    })
  }

  /** Function for change start date*/
  handleChangeStart(date) {
    this.setState({
      startDate: date,
      filterBy: '',
      filterData: '',
    })
  }

  /** Function for end date*/
  handleChangeEnd(date) {
    this.setState({
      endDate: date,
      filterBy: '',
      filterData: '',
    })
  }

  /** Function for API call to get order information*/
  getOrderInfo(data) {
    let self = this
    if (data.currentStatus.toLowerCase() === "shipped") {
      self.props.componentID.history.push({
        pathname: "/ng-admin/ordermanagement",
        state: {
          pageType: 'rangePage',
          orderDetails: data,
          pin: data.pin,
          orderId: data.orderId,
          isEdit: true,
        }
      })
      return 0
    }
    if (data.acknowledge == true && data.orderedProduct[0].productRange.length < 1 && data.trackingUrl == '') {
      self.props.componentID.history.push({
        pathname: "/ng-admin/ordermanagement",
        state: {
          pageType: 'rangePage',
          orderDetails: data,
          pin: data.pin,
          orderId: data.orderId
        }
      })
    }
    else if (data.cancelStatus == true) {
      self.props.componentID.history.push({
        pathname: "/ng-admin/ordermanagement",
        state: { displayOrderInfo: data }
      })
    } else {
      self.props.componentID.history.push({
        pathname: "/ng-admin/ordermanagement",
        state: { displayOrderInfo: data }
      })
    }
  }

  /** Function for date manipulation*/
  handleChangeStartDate(date) {
    try {
        let date_ = date.target !== undefined ? date.target.value :false
        
        let data = /^[0-9-/]*$/
        if( !data.test(date_) && date_!==false) { 
          
          date.target.value = ''
              
        } else {
          let pastDateTime = datetime.create(date);
          let modifedate = moment(pastDateTime.now()).format('YYYY-MM-DD HH:mm');
          let endDate = this.state.endDate != '' ? moment(this.state.endDate).format('YYYY-MM-DD HH:mm') : ''
          let {filteredDataList, filterBy, filterData, orderList} = this.state
            if(filterBy !== '' && filterData !== '' && orderList.length > 0){
              switch (filterBy) {
                case "Order Number":
                  filteredDataList = orderList.filter((data)=>  data.orderNumber.includes(filterData))
                  break;
                case "Customer Name":
                  filteredDataList = orderList.filter((data)=> data.customerName.toLowerCase().includes(filterData.toLowerCase()))
                  break;
                case "Customer Type":
                  filteredDataList = orderList.filter((data)=>  data.customerType.toLowerCase().includes(filterData.toLowerCase()))
                  break;
                case "Shipment Type":
                  filteredDataList = orderList.filter((data)=>  data.shippingType.toLowerCase().includes(filterData.toLowerCase()))
                  break;
                case "Amount":
                  filteredDataList = orderList.filter((data)=>  data.total.includes(filterData))
                  break;
                case "Payment Type":
                    filteredDataList = orderList.filter((data)=>  data.paymentType.toLowerCase().includes(filterData.toLowerCase()))
                  break;
                case "Status":
                      filteredDataList = orderList.filter((data)=>  {
                        var status = ''
                        if (data.acknowledge == true && (data.orderedProduct[0].productRange.length > 0) && data.activationStatus == '') {
                          status = 'Shipped'
                        }
                        else if (data.acknowledge == true && data.activationStatus) {
                          status = 'Activated'
                        }
                        else if (data.acknowledge == true) {
                          status = 'Acknowledged'
                        }
                        else if (data.cancelStatus == true) {
                          status = 'Cancelled'
                        }
                        else
                          status = 'Pending'
                          return status.toLowerCase().includes(filterData.toLowerCase())
                      })
                    break;
                default:
                    filteredDataList = orderList
              }
           }
          const result = HelperUtil._getFilteredOrderListByDate(moment(date).format('YYYY-MM-DD HH:mm'), filteredDataList, endDate)
          this.setState({
            startDate: date,
            sDateToCompare: modifedate,
            filteredDataList: result,
            fliterStartDate: moment(date).format('MM/DD/YYYY'),
            // filterBy: '',
            // filterData: '',
          });
        }

    } catch (e) {console.log("erorr in handleChangeStartDate ",e)}
    
  }
  
    
  

  /** Function for date manipulation*/
  handleChangeEndDate(date) {
    try {
      let date_ = date.target !== undefined ? date.target.value :false
        
      let data = /^[0-9-/]*$/
      if( !data.test(date_) && date_!==false) { 
        
        date.target.value = ''
            
      } else { 
        var pastDateTime = datetime.create(date);
        var modifedate = moment(pastDateTime.now());
        let {filteredDataList, filterBy, filterData, orderList} = this.state
          if(filterBy !== '' && filterData !== '' && orderList.length > 0){
            switch (filterBy) {
              case "Order Number":
                filteredDataList = orderList.filter((data)=>  data.orderNumber.includes(filterData))
                break;
              case "Customer Name":
                filteredDataList = orderList.filter((data)=> data.customerName.toLowerCase().includes(filterData.toLowerCase()))
                break;
              case "Customer Type":
                filteredDataList = orderList.filter((data)=>  data.customerType.toLowerCase().includes(filterData.toLowerCase()))
                break;
              case "Shipment Type":
                filteredDataList = orderList.filter((data)=>  data.shippingType.toLowerCase().includes(filterData.toLowerCase()))
                break;
              case "Amount":
                filteredDataList = orderList.filter((data)=>  data.total.includes(filterData))
                break;
              case "Payment Type":
                  filteredDataList = orderList.filter((data)=>  data.paymentType.toLowerCase().includes(filterData.toLowerCase()))
                break;
              case "Status":
                  filteredDataList = orderList.filter((data)=>  {
                    var status = ''
                    if (data.acknowledge == true && (data.orderedProduct[0].productRange.length > 0) && data.activationStatus == '') {
                      status = 'Shipped'
                    }
                    else if (data.acknowledge == true && data.activationStatus) {
                      status = 'Activated'
                    }
                    else if (data.acknowledge == true) {
                      status = 'Acknowledged'
                    }
                    else if (data.cancelStatus == true) {
                      status = 'Cancelled'
                    }
                    else
                      status = 'Pending'
                      return status.toLowerCase().includes(filterData.toLowerCase())
                  })
                break;
              default:
                  filteredDataList = orderList
            }
          }
        const result = HelperUtil._getFilteredOrderListByDate(moment(this.state.startDate).format('YYYY-MM-DD HH:mm'), filteredDataList, moment(date).format('YYYY-MM-DD HH:mm'))
        this.setState({
          endDate: date,
          eDateToCompare: modifedate,
          filteredDataList: result,
          fliterEndDate: moment(date).format('MM/DD/YYYY'),
          // filterBy: '',
          // filterData: '',
        });
      }
    } catch (e){console.log("erorr in handleChangeStartDate ",e)}
    
  }

  /** Function to reset the date */
  resetList(e) {
    var val = e.target.value
    this.setState({
      filterBy: '',
      filterData: '',
      startDate: moment(new Date()).format('MM/DD/YYYY'),
      endDate: moment(new Date()).format('MM/DD/YYYY'),
      fliterStartDate: 'From',
      fliterEndDate: 'To',
      filteredDataList: this.state.listToFilter,
    })
  }

  /** Function for sorting data onclick*/
  sortBy(dataList, Name) {
    this.setState({
      sortField: Name
    })
    let response = constant.SORT_BY(dataList, Name, this.state.sortOrder)
    this.setState({
      filteredDataList: response.data,
      sortOrder: response.sortOrder
    })
  }

  /** Function to create excel file*/
  createExcel() {
    try {
      let e = []
      let rows = 0
      e = this.filterDataExel(this.state.filteredDataList)
      var wb = XLSX.utils.book_new();
      var wscols = [
        { wch: 15 },
        { wch: 25 },
        { wch: 80 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 30 }];
      var wsrows = [
        { hpt: 15 }, // "points"
        { hpx: 16 }, // "pixels"
      ];
      XLSX.utils.book_append_sheet(wb, ws, "WorksheetName");

      /* make the worksheet */
      var ws = XLSX.utils.json_to_sheet(e);
      ws['!cols'] = wscols;
      ws['!rows'] = wsrows;
    
      ws['A1'] ={v : 'Order Number' ,
        s: { alignment: {textRotation: 90 }, font: {sz: 14, bold: true, color: "#FF00FF" }}
      }
   
     for(let i=1;i<= e.length;i++) {
      ws[`J${i+1}`]= {f: `=(H${i+1}*I${i+1})`,t:'n'};
      ws[`J${i+1}`].z = "[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00";
     }
     for(let i=1;i<= e.length;i++) {
      ws[`L${i+1}`] = {f: `=(J${i+1}*K${i+1})`};
      ws[`L${i+1}`].z = "[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00";
     }
     e.map((pro,i)=>{
      ws[`N${i+2}`] = {f: `=J${i+2}-L${i+2}+M${i+2}`};
      ws[`N${i+1}`].z = "[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00";

     }) 
     for(let i=1;i<= e.length;i++) {
      ws[`M${i+1}`].z =  "[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00"
      ws[`K${i+1}`].z =  "0.00%"
      ws[`I${i+1}`].z =  "0"
      ws[`H${i+1}`].z =  "[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00"
  
     }
    
      /* add to workbook */
      var wb = XLSX.utils.book_new();
      
      XLSX.utils.book_append_sheet(wb, ws, "Orders");
      /* generate an XLSX file */
      let dateTime = HelperUtil._getDateTimeForReport()
      let fileName = `GiftCard_Order_Report_${dateTime}.xlsx`
      XLSX.writeFile(wb, fileName);

    }
    catch (e) { console.log("catch", e) }
  }

  /** Function for generating pdf file */
  generatePdf() {
    let self = this
    self.setState({ Loading: true })
    let data = []
    let pdfPages = Math.ceil(this.state.filteredDataList.length / 15)
    let pdf = new jsPDF('', 'pt', [800, 800])
    for (let i = 0; i < pdfPages; i++) { data.push(i) }
    data.map(async (data, i) => {
      var pdfId = document.getElementById(`pdfId_${i}`);
      await html2canvas(pdfId, {
        width: 780,
        dpi: 350,
        letterRendering: true,
        height: 1100,
        scale: "2"
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        pdf.addImage(imgData, 'JPEG', 30, 20, 800, 850);
        if (i !== pdfPages - 1) {
          pdf.addPage()
        }
        else {
          pdf.save(`Orders_Report_Page_${new Date().toLocaleString()}.pdf`);
          self.setState({ Loading: false })
        }
      });
    })
  }

  /** Function for creating pdf from the data  given */
  createPdfFromHtml() {
    try {
      this.generatePdf()

    } catch (e) { console.log("erorr ", e) }
  }

  createPdf(data) {

    try {
      this.createPdfFromHtml()

    } catch (err) {
      console.log('error', err)
    }

  }
  
  /***
   * Method for filter order data for xcel 
   * 
   * @parma {orderlist}
   * @return {filtred list}
   */
  filterDataExel(data) {
   
    let jsonArraay = []
    data.map(data => {
      let json2  = {}
      let shippingCharges = parseFloat(data.shippingRate/data.orderedProduct.length);
      data.orderedProduct.map(pro=>{
        let subTotal = pro.productPrice*pro.noOfCards
        let totalDiscount = (subTotal*data.discountAmount)/100;
        let totalAmt = data.subTotal - totalDiscount + shippingCharges;
        json2 = { 
        "Order Number": `${data.orderNumber}`,
        "Customer Name": data.customerName,
        "Address": `${data.shippingAddress1}, ${data.shippingCity}, ${data.shippingState}, ${data.shippingCountry}, ${data.shippingZip}`,
        "Order Date": moment(new Date(data.orderDate)).format('MM/DD/YYYY'),
        "Expected Delivery Date": moment(new Date(data.eta)).format('MM/DD/YYYY'),
        "Shipment Type": `${data.shippingType}`,
        "Card Variety" : `${pro.productType}` ,
        "Face Value" :+pro.productPrice,
        "Quantity" : +pro.noOfCards,
        "Subtotal" : `$${Number(parseFloat(Number(subTotal)).toFixed(2)).toLocaleString('en', {
          minimumFractionDigits: 2
        })}` ,
        "Discount %" : +data.percent/100,
        "Total Discount" :`$${Number(parseFloat(Number(totalDiscount)).toFixed(2)).toLocaleString('en', {
          minimumFractionDigits: 2
        })}`,
        "Shipping Charges" :+shippingCharges,
        "Total Amount" :`${Number(parseFloat(Number(totalAmt)).toFixed(2)).toLocaleString('en', {
          minimumFractionDigits: 2
        })}`,
        "Payment Type": `${data.paymentType}`,
        "Card Type" : `${data.cardType !== undefined && data.cardType !== null && data.cardType !== '' ? data.cardType : 'NA'}`,
        "Payment Date" : `${data.paymentDate !== undefined && data.paymentDate !== null ? moment(new Date(data.paymentDate)).format('MM/DD/YYYY') : 'NA'}`,
        "Activation Status": `${data.currentStatus.toLowerCase() === 'activated' ? 'Yes' : 'No'}`,
        "Gift Card Activation Date" : `${data.giftCardActivationDate !== undefined && data.giftCardActivationDate !== null ? moment(new Date(data.giftCardActivationDate)).format('MM/DD/YYYY') : 'NA'}`,
         
        }
        jsonArraay = [...jsonArraay,json2]
      })
      
    })
    return jsonArraay

  }

  /** Function for filtering data to be shown in the pdf file  */
  filterDataCSV(data) {
    let tempData = []
    let json = {}
    console.log("date===",data);
    data.map(data => {
      json = {

        "Order Number": `${data.orderNumber}`,
        "Customer Name": data.customerName,
        "Address": `${data.shippingAddress1}, ${data.shippingCity}, ${data.shippingState}, ${data.shippingCountry}, ${data.shippingZip}`,
        "Order Date": moment(new Date(data.orderDate)).format('MM/DD/YYYY'),
        "Expected Delivery Date": moment(new Date(data)).format('MM/DD/YYYY'),
        "Shipment Type": `${data.shippingType}`,

        "Amount": `$${Number(parseFloat(Number(data.total)).toFixed(2)).toLocaleString('en', {
          minimumFractionDigits: 2
        })}`,
        "Payment Type": `${data.paymentType}`,
        "Activation Status": `${data.currentStatus.toLowerCase() === 'activated' ? 'Yes' : 'No'}`

      }
      tempData.push(json)
    })
    return tempData

  }

  render() {
    let { filteredDataList } = this.state
    let flashSuceessMessageSpan = '';
    let flashErrorMessageSpan = '';
    if (this.state.flashMessageSuccess) {
      flashSuceessMessageSpan = <Alert className='alertFont'>{this.state.flashMessageSuccess}</Alert>;
    }
    if (this.state.flashMessageError) {
      flashErrorMessageSpan = <Alert className='alertFont' color='danger'>{this.state.flashMessageError}</Alert>;
    }
    var list = filteredDataList ? filteredDataList.slice((this.state.pageactive - 1) * 10, (this.state.pageactive) * 10) : ''
    let dataToCsv = this.filterDataCSV(this.state.filteredDataList)
    
    return (
      <div className="admin_top">
        {
          this.state.Loading === true && <div className="loader-react">
            <ReactLoading type={'spinningBubbles'} color={'green'} className="reactLoader" />
          </div>
        }
        <Helmet>
                    <title>Order Management</title>
                </Helmet>
        <div className="content-wrapper" className="d-flex flex-column">
          <Header />
          <div className="main">
            <Navigation activeClassName={this.state.activeClassName} />
            <div className="dashboardContent">
              <div className="container-fluid">
                <div className="admin-header">
                  <div className="user-dashboard user-management clearfix" id="user-dashboard">
                    <div className="row mar0">
                      <div className="col-md-12">
                        {flashSuceessMessageSpan}
                        {flashErrorMessageSpan}
                        <h2>Order Management</h2>
                        <div className="row">
                          <div className="pull-left col-md-10 shipping-filter">
                            <div className="row">
                              <div className="form-group filter-input">
                                <div className="styled-select slate">
                                  <select className="select-dropdown" name="test" onChange={(e) => this.setFilterBy(e)} value={this.state.filterBy}>
                                    <option>Filter</option>
                                    <option value="Order Number">Order Number</option>
                                    <option value="Customer Type">Customer Type</option>
                                    <option value="Customer Name">Customer Name</option>
                                    <option value="Shipment Type">Shipping Type</option>
                                    <option value="Amount">Amount</option>
                                    <option value="Payment Type">Payment Type</option>
                                    <option value="Status">Status</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group filter-input">
                                <div className="inner-addon search-input">
                                  <input type="text" className="form-control input-style search-icon" placeholder="Search" value={this.state.filterData} onChange={this.filterSearch.bind(this)} />
                                </div>
                              </div>
                              <div className="datePicker">
                                <div className="form-group filter-calendar datePickerReact">
                                  <div className="row ">
                                    <div className="col-sm-6">
                                      <DatePicker className='form-control calendar-icon'
                                        placeholderText={this.state.fliterStartDate} 
                                        maxDate={new Date()} disabled={this.state.filter} 
                                        onChange={this.handleChangeStartDate.bind(this)} 
                                        onChangeRaw ={this.handleChangeStartDate.bind(this)}   
                                        />
                                    </div>
                                    <div className="col-sm-6 secondDatePikker">
                                      <DatePicker className='form-control calendar-icon' 
                                        placeholderText={this.state.fliterEndDate} 
                                        maxDate={new Date()} disabled={this.state.filter} 
                                        onChange ={this.handleChangeEndDate.bind(this)}  
                                        onChangeRaw={this.handleChangeEndDate.bind(this)} 
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="refresh-btn">
                                <i onClick={this.resetList.bind(this)} className="fa fa-refresh" title="Refresh" aria-hidden="true"></i>
                              </div>

                            </div>
                          </div>




                          <div className="col-md-2">
                            <div className="pull-right pr-2">
                              <button onClick={this.createPdf.bind(this, dataToCsv)} title="Download Pdf" className="pdf-downlode btn-pdf_xls mr-2"></button>
                              <button onClick={this.createExcel.bind(this)} title="Download XLSX" className="xls-downlode btn-pdf_xls"></button>

                            </div>
                          </div>
                        </div>

                        <div className="table-responsive">
                          <table id="example" className="table table-striped table-bordered" cellSpacing="0" width="100%">
                            <thead>
                              <tr>
                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'orderNumber')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'orderNumber' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Order Number</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'orderDate')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'orderDate' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Order Date</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'customerType')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'customerType' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Customer Type</th>
                                <th className="customer-name-td all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'customerName')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'customerName' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Customer Name</th>
                                <th className=" all_cursorpointer address_col" onClick={(e) => this.sortBy(filteredDataList, 'billingAddress1')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'billingAddress1' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Address</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'eta')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'eta' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Expected Delivery Date</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'shippingType')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'shippingType' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Shipping Type</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'total')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'total' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Amount</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'paymentType')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'paymentType' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Payment Type</th>
                                <th className=" all_cursorpointer" onClick={(e) => this.sortBy(filteredDataList, 'currentStatus')}>
                                  {this.state.sortField !== '' ?
                                    this.state.sortField === 'currentStatus' ?
                                      <span id={this.state.sortOrder === 'asc' ? "desc1" : "asc1"} className={this.state.sortOrder === 'asc' ? "fa fa-sort-asc pull-right" : "fa fa-sort-desc pull-right"} aria-hidden="true"></span>
                                      : <span className="fa fa-sort pull-right" aria-hidden="true"></span>
                                    : <span className="fa fa-sort pull-right" aria-hidden="true"></span>}
                                  Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                list.length > 0 ? list.map((key, index) => {
                                  return <tr key={index}>
                                    <td ><span onClick={() => this.getOrderInfo(key)}> {key.orderNumber}</span></td>
                                    <td>{moment( new Date(key.orderDate)).format('MM/DD/YYYY')}</td>
                                    <td>{key.customerType}</td>
                                    <td>{key.customerName}</td>
                                    <td className='wrap-tablecel-l'>{key.shippingAddress1 + ', ' + key.shippingCity + ', ' + key.shippingState + ', ' + key.shippingCountry + ', ' + key.shippingZip}</td>
                                    <td>{moment(new Date(key.eta)).format('MM/DD/YYYY')}</td>
                                    <td>{key.shippingType !== "" ? key.shippingType : 'Normal'}</td>
                                    <td className='nowrap-tablecel-l'>${HelperUtil._getAmountCommaSeparator(Number(key.total).toFixed(2)).toString()}</td>
                                    <td>{key.paymentType}</td>
                                    <td>{key.currentStatus}</td>
                                  </tr>
                                }) : <tr><td className="recordNotFound" colSpan="10">No record found</td></tr>
                              }
                            </tbody>
                          </table>
                        </div>
                        <ul className="pagination pull-right my-1">
                          <Pagination
                            hideDisabled
                            activePage={this.state.pageactive}
                            itemsCountPerPage={10}
                            totalItemsCount={filteredDataList.length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                            firstPageText='First'
                            lastPageText='Last'
                            prevPageText="Prev"
                            nextPageText="Next"
                          />
                        </ul>

                      </div>
                      <div id="xop"> </div>
                      <PdfFormat
                        data={dataToCsv}
                        tableHeader={this.state.tableHeader}

                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    orderList: state.orderList,
  };
}

export default connect(mapStateToProps, { orderListAction })(OrderManagements);