import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
  isSubmitting: false,
  data: null,
  login: false
}

/**
 * Function for get discount list
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function discountList(state = data, action) {
  switch (action.type) {
    case constants.DISCOUNT_MANAGEMENT_DATA:
      return { ...state, data: action.payload };
    case constants.DISCOUNT_MANAGEMENT_DATA_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for add discount
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function addDiscount(state = data, action) {
  switch (action.type) {
    case constants.ADD_DISCOUNT:
      return { ...state, data: action.payload };
    case constants.ADD_DISCOUNT_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for edit discount
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function editDiscount(state = data, action) {
  switch (action.type) {
    case constants.EDIT_DISCOUNT:
      return { ...state, data: action.payload };
    case constants.EDIT_DISCOUNT_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for delete discount
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function deleteDiscount(state = data, action) {
  switch (action.type) {
    case constants.DELETE_DISCOUNT:
      return { ...state, data: action.payload };
    case constants.DELETE_DISCOUNT_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for get payment types for ddiscount
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function paymentTypes(state = data, action) {
  switch (action.type) {
    case constants.PAYMENT_TYEPE_DISCOUNT:
      return { ...state, data: action.payload };
    case constants.PAYMENT_TYEPE_DISCOUNT_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

