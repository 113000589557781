import * as constants from '../../Constants';

/**
 * Object set for intial state
 */
let data = {
    isSubmitting: false,
    data: null,
    login: false
  }

  /**
   * Function to get FAQ list
   * @param {*} state 
   * @param {*} action 
   */
export function getFAQList(state = data, action) {
    switch (action.type) {
      case constants.GET_FAQ_LIST:
        return { ...state, data: action.payload };
      case constants.GET_FAQ_LIST_ERROR:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  }

/**
 * Function to create FAQ
 * @param {*} state 
 * @param {*} action 
 */
  export function createFAQ(state = data, action) {
    switch (action.type) {
      case constants.CREATE_FAQ:
        return { ...state, data: action.payload };
      case constants.CREATE_FAQ_ERROR:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  }

  /**
   * Function to update FAQ
   * @param {*} state 
   * @param {*} action 
   */
  export function updateFAQ(state = data, action) {
    switch (action.type) {
      case constants.UPDATE_FAQ:
        return { ...state, data: action.payload };
      case constants.UPDATE_FAQ_ERROR:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  }

  /**
   * Function to get FAQ details
   * @param {*} state 
   * @param {*} action 
   */
  export function getFAQDetails(state = data, action) {
    switch (action.type) {
      case constants.GET_FAQ_DETAILS:
        return { ...state, data: action.payload };
      case constants.GET_FAQ_DETAILS_ERROR:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  }

/**
 * Function to delete FAQ 
 * @param {*} state 
 * @param {*} action 
 */
  export function deleteFAQ(state = data, action) {
    switch (action.type) {
      case constants.DELETE_FAQ:
        return { ...state, data: action.payload };
      case constants.DELETE_FAQ_ERROR:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  }

  /**
 * Function to get FAQ total records
 * @param {*} state 
 * @param {*} action 
 */
export function getFAQTotalRecords(state = data, action) {
  switch (action.type) {
    case constants.GET_FAQ_TOTAL_RECORDS:
      return { ...state, data: action.payload };
    case constants.GET_FAQ_TOTAL_RECORDS_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
  