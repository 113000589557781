import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
  isSubmitting: false,
  data: null,
  login: false
}

/**
 * Function for get users list
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function orderList(state = data, action) {
  switch (action.type) {
    case constants.ORDER_LIST:
      return { ...state, data: action.payload };
    case constants.ORDER_LIST_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to get the order details
 * @param {*} state 
 * @param {*} action 
 */
export function orderDetails(state = data, action) {
  switch (action.type) {
    case constants.ORDER_DETAILS:
      return { ...state, data: action.payload };
    case constants.ORDER_DETAILS_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
* Function to acknowledge the order
* @param {*} state 
* @param {*} action 
*/
export function acknowledgeOrder(state = data, action) {
  switch (action.type) {
    case constants.ACKNOWLEDGE_ORDER:
      return { ...state, data: action.payload };
    case constants.ACKNOWLEDGE_ORDER_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
* Function to send shipping details
* @param {*} state 
* @param {*} action 
*/
export function sendShippingDetails(state = data, action) {
  switch (action.type) {
    case constants.SEND_SHIPPING_DETAILS:
      return { ...state, data: action.payload };
    case constants.SEND_SHIPPING_DETAILS_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
* Function to get the order details
* @param {*} state 
* @param {*} action 
*/
export function getCustomerOrderList(state = data, action) {
  switch (action.type) {
    case constants.CUSTOMER_ORDER_LIST_DATA:
      return { ...state, data: action.payload };
    case constants.CUSTOMER_ORDER_LIST_DATA_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to cancel the order
 * @param {*} state 
 * @param {*} action 
 */
export function cancelOrder(state = data, action) {
  switch (action.type) {
    case constants.CANCEL_ORDER:
      return { ...state, data: action.payload };
    case constants.CANCEL_ORDER_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}