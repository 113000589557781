const ciamAddressStateModal = (state)=> {
	try {
		let addresses;
		let pimAddress=[]
		if(state.addresses===null ) {
			addresses = []
			if(state.pimaddress[0].addressId !== undefined) {
				
				let tempAdd ={
					"title":state.pimaddress[0].addressType,
					"street":state.pimaddress[0].address1,
					"city": state.pimaddress[0].city,
					"state": state.pimaddress[0].state,
					"zip": state.pimaddress[0].zip,
					"country": state.pimaddress[0].country,
					"isPrimary": state.pimaddress[0].status ,
					"isVerified": false,
					"source": "gift_card",
				}
				pimAddress = [tempAdd]
			}
		} else {
			addresses = state.addresses.customerAddress;
			state.pimaddress.map((address,i)=>{
				if(address.addressId !==undefined) {
					pimAddress.push({
						"title":address.addressType,
						"street":state.pimaddress[i].address1,
						"city": address.city,
						"state": address.state,
						"zip": address.zip,
						"country": address.country,
						"isPrimary": address.status ,
						"isVerified": false,
						"source": "gift_card",
						});
				} else if(address.address1 !== "" && address.city !== ""  && address.state !== "" && address.zip !== "" ){
					let tempAddress = {
						"title":address.addressType,
						"street":state.pimaddress[i].address1,
						"city": address.city,
						"state": address.state,
						"zip": address.zip,
						"country": address.country,
						"isPrimary": address.status ,
						"isVerified": false,
						"source": "gift_card",
						}
					pimAddress.push(tempAddress)
				}
				
			})
		}
		return ({
		"source": "gift_card",
		"prosperaId": state.ngId,
		"address": {
			"customerAddress": [...pimAddress]
		}
	})
	} catch(err) { console.log("error",err);}
	
}

export default ciamAddressStateModal ;
