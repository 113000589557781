import * as constants from '../../Constants';
/**
 * Object set for intial state
 */
let data = {
  isSubmitting: false,
  data: null,
  login: false
}

/**
 * Function for get users list
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function userList(state = data, action) {
  switch (action.type) {
    case constants.USER_MANAGEMENT_LIST_DATA:
      return { ...state, data: action.payload };
    case constants.USER_MANAGEMENT_LIST_DATA_ERROR:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for get roles
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function getRoles(state = data, action) {
  switch (action.type) {
    case constants.USER_ROLES:
      return { ...state, login: true, data: action.payload };
    case constants.USER_ROLES_ERROR:
      return { ...state, login: true, data: action.payload }
    default:
      return state;
  }
}

/**
 * Function for add new user
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function addUser(state = data, action) {
  switch (action.type) {
    case constants.USER_MANAGEMENT_ADD_RESPONSE:
      return { ...state, login: true, data: action.payload };
    case constants.USER_MANAGEMENT_ADD_RESPONSE_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function for edit new user
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function updateUserStatus(state = data, action) {
  switch (action.type) {
    case constants.USER_MANAGEMENT_USER_STATUS_DATA_RESPONSE:
      return { ...state, login: true, data: action.payload };
    case constants.USER_MANAGEMENT_USER_STATUS_DATA_RESPONSE_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
* Function for update user
* @param {object} state set initial state in default case
* @param {object} action 
*/
export function updateUser(state = data, action) {
  switch (action.type) {
    case constants.USER_MANAGEMENT_UPDATE_RESPONSE:
      return { ...state, login: true, data: action.payload };
    case constants.USER_MANAGEMENT_UPDATE_RESPONSE_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/**
 * Function to change admin/fulfillment user password
 * @param {object} state set initial state in default case
 * @param {object} action 
 */
export function changePassword(state = data, action) {
  switch (action.type) {
    case constants.ADMIN_CHANGE_PASSWORD:
      return { ...state, login: true, data: action.payload };
    case constants.ADMIN_CHANGE_PASSWORD_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

export function navigationToggleClass(state = data, action) {
  switch (action.type) {
    case 'navigation':
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

/* Reducer to send link for forgot password for admin */
export function sendResetPwdLink(state = data, action) {
  switch (action.type) {
    case constants.SEND_RESET_PWD_LINK:
      return { ...state, login: true, data: action.payload };
    case constants.SEND_RESET_PWD_LINK_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/* Reducer to reset user password */
export function updateUserPwd(state = data, action) {
  switch (action.type) {
    case constants.UPDATE_USER_PWD:
      return { ...state, login: true, data: action.payload };
    case constants.UPDATE_USER_PWD_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

/*Function to update home setting */
export function updateHomeSetting(state = data, action) {
  switch (action.type) {
    case constants.UPDTATE_HOME_SETTING:
      return { ...state, login: true, data: action.payload };
    case constants.UPDTATE_HOME_SETTING_ERROR:
      return { ...state, login: true, data: action.payload };
    default:
      return state;
  }
}

